import { takeLatest, put, fork } from "@redux-saga/core/effects";
const FileDownload = require("js-file-download");
import axios from "axios";
import { cashBookActions, serverActions } from "../../../actions";

function* getCashBook({ params }) {
  try {
    const response = yield axios({ url: "/finance/cash-book", params });

    yield put({ type: cashBookActions.GET_CASH_BOOK_SUCCESS, data: response });
  } catch (error) {
    yield put({
      type: cashBookActions.GET_CASH_BOOK_ERROR,
      error: error.data,
    });
  }
}

function* watchGetCashBook() {
  yield takeLatest(cashBookActions.GET_CASH_BOOK_REQUEST, getCashBook);
}

function* getMetaData() {
  try {
    const response = yield axios({ url: "/finance/cash-book/meta-data" });

    yield put({ type: cashBookActions.GET_META_DATA_SUCCESS, data: response });
  } catch (error) {
    yield put({ type: cashBookActions.GET_META_DATA_SUCCESS, data: {} });
  }
}

function* watchGetMetaData() {
  yield takeLatest(cashBookActions.GET_META_DATA_REQUEST, getMetaData);
}

function* downloadCashBookExcel({ params }) {
  try {
    const response = yield axios({
      url: "/finance/cash-book/download-excel",
      params,
      responseType: "blob",
    });

    FileDownload(response.data, "CASH BOOK EXCEL DOWNLOAD.xlsx");

    yield put({
      type: cashBookActions.GENERATE_EXCEL_SUCCESS,
      data: response,
    });

    yield put({
      type: serverActions.SERVER_SUCCESS,
      data: {
        server: { status: true, message: "Successfully downloaded excel" },
        message: "Successfully Downloaded cash book",
      },
    });
  } catch (error) {
    yield put({
      type: cashBookActions.GENERATE_EXCEL_ERROR,
      error: error.data,
    });
  }
}

function* watchDownloadCashBookExcel() {
  yield takeLatest(
    cashBookActions.GENERATE_EXCEL_REQUEST,
    downloadCashBookExcel
  );
}

export default [
  fork(watchGetCashBook),
  fork(watchGetMetaData),
  fork(watchDownloadCashBookExcel),
];
