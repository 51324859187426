import { takeLatest, takeEvery, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { appUiActions, bursaryActions } from "../../../actions";

function* getBursaries() {
  try {
    const response = yield axios({ url: "/administration/bursaries" });

    yield put({
      type: bursaryActions.GET_BURSARIES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: bursaryActions.GET_BURSARIES_ERROR,
      error: error.data,
    });
  }
}

function* watchGetBursaries() {
  yield takeLatest(bursaryActions.GET_BURSARIES_REQUEST, getBursaries);
}

function* getBursaryDetails({ id }) {
  try {
    const response = yield axios({
      url: `/administration/bursaries/${id}`,
    });

    yield put({
      type: bursaryActions.GET_BURSARY_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: bursaryActions.GET_BURSARY_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetBursaryDetails() {
  yield takeLatest(
    bursaryActions.GET_BURSARY_DETAILS_REQUEST,
    getBursaryDetails
  );
}

function* addBursary({ data }) {
  try {
    const response = yield axios({
      url: "administration/bursaries",
      method: "POST",
      data,
    });

    yield put({
      type: bursaryActions.ADD_BURSARY_SUCCESS,
      data: response,
    });

    yield put(bursaryActions.getBursaries());
  } catch (error) {
    yield put({
      type: bursaryActions.ADD_BURSARY_ERROR,
      error: error.data,
    });
  }
}

function* watchAddBursary() {
  yield takeLatest(bursaryActions.ADD_BURSARY_REQUEST, addBursary);
}

function* updateBursary({ id, data }) {
  try {
    const response = yield axios({
      url: `administration/bursaries/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: bursaryActions.UPDATE_BURSARY_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleUpdateBursariesModal(false));
  } catch (error) {
    yield put({
      type: bursaryActions.UPDATE_BURSARY_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateBursary() {
  yield takeLatest(bursaryActions.UPDATE_BURSARY_REQUEST, updateBursary);
}

function* deleteBursary({ id }) {
  try {
    const response = yield axios({
      url: `administration/bursaries/${id}`,
      method: "DELETE",
    });

    yield put({
      type: bursaryActions.DELETE_BURSARY_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: bursaryActions.DELETE_BURSARY_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteBursary() {
  yield takeEvery(bursaryActions.DELETE_BURSARY_REQUEST, deleteBursary);
}

function* getMetaData() {
  try {
    const response = yield axios({
      url: "/administration/bursaries/meta-data",
    });

    yield put({
      type: bursaryActions.GET_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: bursaryActions.GET_META_DATA_SUCCESS,
      error: error.data,
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(bursaryActions.GET_META_DATA_REQUEST, getMetaData);
}

function* updateBursaryCosts({ data, id }) {
  try {
    const response = yield axios({
      url: `/administration/bursaries/costs/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: bursaryActions.UPDATE_BURSARY_COST_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleUpdateBursaryCostModal(false));
  } catch (error) {
    yield put({
      type: bursaryActions.UPDATE_BURSARY_COST_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateBursaryCosts() {
  yield takeLatest(
    bursaryActions.UPDATE_BURSARY_COST_REQUEST,
    updateBursaryCosts
  );
}

function* deleteBursaryCosts({ id, bursaryId }) {
  try {
    const response = yield axios({
      url: `/administration/bursaries/costs/${id}`,
      method: "DELETE",
    });

    yield put({
      type: bursaryActions.DELETE_BURSARY_COST_SUCCESS,
      data: response,
    });

    yield put(bursaryActions.getBursaryDetails(bursaryId));
  } catch (error) {
    yield put({
      type: bursaryActions.DELETE_BURSARY_COST_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteBursaryCosts() {
  yield takeEvery(
    bursaryActions.DELETE_BURSARY_COST_REQUEST,
    deleteBursaryCosts
  );
}

function* checkBursary({ id }) {
  try {
    const response = yield axios({
      url: `/administration/bursaries/check/${id}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({
      type: bursaryActions.CHECK_BURSARY_SUCCESS,
      data: response,
    });
    yield put(bursaryActions.getBursaryDetails(id));
  } catch (error) {
    yield put({
      type: bursaryActions.CHECK_BURSARY_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckBursary() {
  yield takeLatest(bursaryActions.CHECK_BURSARY_REQUEST, checkBursary);
}

function* approveBursary({ id }) {
  try {
    const response = yield axios({
      url: `/administration/bursaries/approve/${id}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: bursaryActions.APPROVE_BURSARY_SUCCESS,
      data: response,
    });
    yield put(bursaryActions.getBursaryDetails(id));
  } catch (error) {
    yield put({
      type: bursaryActions.APPROVE_BURSARY_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveBursary() {
  yield takeLatest(bursaryActions.APPROVE_BURSARY_REQUEST, approveBursary);
}

function* getBursaryCostDetails({ id }) {
  try {
    const response = yield axios({
      url: `/administration/bursaries/costs/${id}`,
    });

    yield put({
      type: bursaryActions.GET_BURSARY_COST_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: bursaryActions.GET_BURSARY_COST_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetBursaryCostDetails() {
  yield takeLatest(
    bursaryActions.GET_BURSARY_COST_DETAILS_REQUEST,
    getBursaryCostDetails
  );
}

export default [
  fork(watchGetBursaries),
  fork(watchGetBursaryDetails),
  fork(watchAddBursary),
  fork(watchUpdateBursary),
  fork(watchDeleteBursary),
  fork(watchGetMetaData),
  fork(watchUpdateBursaryCosts),
  fork(watchDeleteBursaryCosts),
  fork(watchCheckBursary),
  fork(watchApproveBursary),
  fork(watchGetBursaryCostDetails),
];
