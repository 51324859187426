import { Form, Alert, notification } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineLock, AiOutlineUser } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { authActions } from "../../../config/actions";
import usePrevious from "../../../components/hooks/usePrevious";
import { isEmpty } from "lodash";
import { appUiActions } from "../../../config/actions";
import ForgotPassword from "../forgotPassword/forgotPassword";
import AppInput from "../../../components/common/AppInput";
import AppButton from "../../../components/common/AppButton";

const Login = () => {
  // state management and effects declarations
  const { loggingIn, loginError, loginSuccess } = useSelector(
    (state) => state.auth
  );
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prevState = usePrevious({ loginSuccess, loginError });

  const openModal = () => {
    dispatch(appUiActions.toggleForgotPasswordModal(true));
  };

  // all functions
  const onFinish = (values) => {
    dispatch(authActions.loginUser(values));
  };

  //useEffects
  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      loginError &&
      prevState.loginError !== loginError
    ) {
      setError(loginError?.message || loginError?.server?.message);
    }
  }, [loginError]);

  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      !isEmpty(loginSuccess) &&
      prevState.loginSuccess !== loginSuccess
    ) {
      if (loginSuccess.server?.status === true) {
        navigate("/");
        notification.success({ message: loginSuccess?.server?.message });
      }
    }
  }, [loginSuccess]);
  return (
    <div className="">
      <Form
        onFinish={onFinish}
        name="Login Page"
        autoComplete="off"
        layout="vertical"
      >
        {error && <Alert type="error" message={error} showIcon />}
        <AppInput
          label="Username"
          name="email"
          rules={[{ required: true, message: "Please input your username!" }]}
          inputAttributes={{
            prefix: <AiOutlineUser className="text-secondary" />,
            type: "email",
          }}
        />

        <AppInput
          label="Password"
          name="password"
          type="password"
          rules={[
            { required: true, message: "Please input your password!" },
            {
              min: 8,
              message: "Your password should be greater than 8 characters",
            },
          ]}
          inputAttributes={{
            prefix: <AiOutlineLock className="text-secondary" />,
          }}
        />
        <div className="d-flex justify-content-end">
          <span
            onClick={openModal}
            className="cursor-pointer text-primary text-center mb-3"
          >
            Forgot your password ?
          </span>
          <ForgotPassword />
        </div>
        <div className="w-100">
          <AppButton
            className="w-100"
            text="Login"
            loading={loggingIn}
            type="submit"
            loadingText="Logging in..."
          />
        </div>
      </Form>
    </div>
  );
};

export default Login;
