import { optionalSubjectsActions } from "../../../actions";
import optionalSubjectsInitialState from "../../../initialState/academics/optionalSubjects/optionalSubjects.initialState";

const optionalSubjects = (state = optionalSubjectsInitialState, action) => {
  switch (action.type) {
    case optionalSubjectsActions.GET_OPTIONAL_SUBJECTS_REQUEST:
      return {
        ...state,
        optionalSubjectsLoading: true,
      };
    case optionalSubjectsActions.GET_OPTIONAL_SUBJECTS_SUCCESS:
      return {
        ...state,
        optionalSubjectsSuccess: action.data,
        optionalSubjectsError: {},
        optionalSubjectsLoading: false,
      };
    case optionalSubjectsActions.GET_OPTIONAL_SUBJECTS_ERROR:
      return {
        ...state,
        optionalSubjectsError: action.error,
        optionalSubjectsLoading: false,
      };
    case optionalSubjectsActions.GET_OPTIONAL_SUBJECT_DETAILS_REQUEST:
      return {
        ...state,
        getOptionalSubjectDetailsLoading: true,
      };
    case optionalSubjectsActions.GET_OPTIONAL_SUBJECT_DETAILS_SUCCESS:
      return {
        ...state,
        getOptionalSubjectDetailsSuccess: action.data,
        getOptionalSubjectDetailsError: {},
        getOptionalSubjectDetailsLoading: false,
      };
    case optionalSubjectsActions.GET_OPTIONAL_SUBJECT_DETAILS_ERROR:
      return {
        ...state,
        getOptionalSubjectDetailsError: action.error,
        getOptionalSubjectDetailsLoading: false,
      };
    case optionalSubjectsActions.GET_OPTIONAL_SUBJECT_META_DATA_REQUEST:
      return {
        ...state,
        getOptionalSubjectsMetaDataLoading: true,
      };
    case optionalSubjectsActions.GET_OPTIONAL_SUBJECT_META_DATA_SUCCESS:
      return {
        ...state,
        getOptionalSubjectsMetaDataSuccess: action.data,
        getOptionalSubjectsMetaDataError: {},
        getOptionalSubjectsMetaDataLoading: false,
      };
    case optionalSubjectsActions.GET_OPTIONAL_SUBJECT_META_DATA_ERROR:
      return {
        ...state,
        getOptionalSubjectsMetaDataError: action.error,
        getOptionalSubjectsMetaDataLoading: false,
      };
    case optionalSubjectsActions.ADD_OPTIONAL_SUBJECT_REQUEST:
      return {
        ...state,
        addOptionalSubjectLoading: true,
      };
    case optionalSubjectsActions.ADD_OPTIONAL_SUBJECT_SUCCESS:
      return {
        ...state,
        addOptionalSubjectSuccess: action.data,
        addOptionalSubjectError: {},
        addOptionalSubjectLoading: false,
      };
    case optionalSubjectsActions.ADD_OPTIONAL_SUBJECT_ERROR:
      return {
        ...state,
        addOptionalSubjectError: action.error,
        addOptionalSubjectLoading: false,
      };
    case optionalSubjectsActions.ADD_OPTIONAL_SUBJECT_STUDENTS_REQUEST:
      return {
        ...state,
        addOptionalSubjectStudentsLoading: true,
      };
    case optionalSubjectsActions.ADD_OPTIONAL_SUBJECT_STUDENTS_SUCCESS:
      return {
        ...state,
        addOptionalSubjectStudentsSuccess: action.data,
        addOptionalSubjectStudentsError: {},
        addOptionalSubjectStudentsLoading: false,
      };
    case optionalSubjectsActions.ADD_OPTIONAL_SUBJECT_STUDENTS_ERROR:
      return {
        ...state,
        addOptionalSubjectStudentsError: action.error,
        addOptionalSubjectStudentsLoading: false,
      };
    case optionalSubjectsActions.DELETE_OPTIONAL_SUBJECT_REQUEST:
      return {
        ...state,
        deleteOptionalSubjectLoading: true,
      };
    case optionalSubjectsActions.DELETE_OPTIONAL_SUBJECT_SUCCESS:
      return {
        ...state,
        deleteoptionalSubjectuccess: action.data,
        deleteOptionalSubjectError: {},
        deleteOptionalSubjectLoading: false,
      };
    case optionalSubjectsActions.DELETE_OPTIONAL_SUBJECT_ERROR:
      return {
        ...state,
        deleteOptionalSubjectError: action.error,
        deleteOptionalSubjectLoading: false,
      };
    case optionalSubjectsActions.DELETE_OPTIONAL_SUBJECT_STUDENT_REQUEST:
      return {
        ...state,
        deleteOptionalSubjectStudentLoading: true,
      };
    case optionalSubjectsActions.DELETE_OPTIONAL_SUBJECT_STUDENT_SUCCESS:
      return {
        ...state,
        deleteOptionalSubjectStudentSuccess: action.data,
        deleteOptionalSubjectStudentError: {},
        deleteOptionalSubjectStudentLoading: false,
      };
    case optionalSubjectsActions.DELETE_OPTIONAL_SUBJECT_STUDENT_ERROR:
      return {
        ...state,
        deleteOptionalSubjectStudentError: action.error,
        deleteOptionalSubjectStudentLoading: false,
      };
    case optionalSubjectsActions.DOWNLOAD_OPTIONAL_SUBJECT_STUDENTS_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadOptionalSubjectStudentsTemplateLoading: true,
      };
    case optionalSubjectsActions.DOWNLOAD_OPTIONAL_SUBJECT_STUDENTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadOptionalSubjectStudentsTemplateSuccess: action.data,
        downloadOptionalSubjectStudentsTemplateError: {},
        downloadOptionalSubjectStudentsTemplateLoading: false,
      };
    case optionalSubjectsActions.DOWNLOAD_OPTIONAL_SUBJECT_STUDENTS_TEMPLATE_ERROR:
      return {
        ...state,
        downloadOptionalSubjectStudentsTemplateError: action.error,
        downloadOptionalSubjectStudentsTemplateLoading: false,
      };
    case optionalSubjectsActions.UPLOAD_OPTIONAL_SUBJECT_STUDENTS_REQUEST:
      return {
        ...state,
        uploadOptionalSubjectStudentsLoading: true,
      };
    case optionalSubjectsActions.UPLOAD_OPTIONAL_SUBJECT_STUDENTS_SUCCESS:
      return {
        ...state,
        uploadOptionalSubjectStudentsSuccess: action.data,
        uploadOptionalSubjectStudentsError: {},
        uploadOptionalSubjectStudentsLoading: false,
      };
    case optionalSubjectsActions.UPLOAD_OPTIONAL_SUBJECT_STUDENTS_ERROR:
      return {
        ...state,
        uploadOptionalSubjectStudentsError: action.error,
        uploadOptionalSubjectStudentsLoading: false,
      };
    default:
      return state;
  }
};

export default optionalSubjects;
