import { takeLatest, put, fork } from "redux-saga/effects";
import axios from "axios";
import { optionalSubjectsActions, serverActions } from "../../../actions";
const FileDownload = require("js-file-download");

function* getOptionalSubjects() {
  try {
    const response = yield axios({
      url: "/academics/optional-subjects",
      method: "GET",
    });
    yield put({
      type: optionalSubjectsActions.GET_OPTIONAL_SUBJECTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: optionalSubjectsActions.GET_OPTIONAL_SUBJECTS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetOptionalSubjects() {
  yield takeLatest(
    optionalSubjectsActions.GET_OPTIONAL_SUBJECTS_REQUEST,
    getOptionalSubjects
  );
}

function* getOptionalSubjectsMetaData() {
  try {
    const response = yield axios({
      url: "/academics/optional-subjects/meta-data",
      method: "GET",
    });
    yield put({
      type: optionalSubjectsActions.GET_OPTIONAL_SUBJECT_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: optionalSubjectsActions.GET_OPTIONAL_SUBJECT_META_DATA_ERROR,
      error: error.data,
    });
  }
}
function* watchGetOptionalSubjectsMetaData() {
  yield takeLatest(
    optionalSubjectsActions.GET_OPTIONAL_SUBJECT_META_DATA_REQUEST,
    getOptionalSubjectsMetaData
  );
}

function* getOptionalSubjectDetails({ id }) {
  try {
    const response = yield axios({
      url: `/academics/optional-subjects/${id}`,
      method: "GET",
    });
    yield put({
      type: optionalSubjectsActions.GET_OPTIONAL_SUBJECT_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: optionalSubjectsActions.GET_OPTIONAL_SUBJECT_DETAILS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetOptionalSubjectDetails() {
  yield takeLatest(
    optionalSubjectsActions.GET_OPTIONAL_SUBJECT_DETAILS_REQUEST,
    getOptionalSubjectDetails
  );
}

function* addOptionalSubject({ data }) {
  try {
    const response = yield axios({
      url: "/academics/optional-subjects",
      method: "POST",
      data: data,
    });
    yield put({
      type: optionalSubjectsActions.ADD_OPTIONAL_SUBJECT_SUCCESS,
      data: response,
    });
    yield put(optionalSubjectsActions.getOptionalSubjects());
  } catch (error) {
    yield put({
      type: optionalSubjectsActions.ADD_OPTIONAL_SUBJECT_ERROR,
      error: error.data,
    });
  }
}
function* watchAddOptionalSubject() {
  yield takeLatest(
    optionalSubjectsActions.ADD_OPTIONAL_SUBJECT_REQUEST,
    addOptionalSubject
  );
}

function* addOptionalSubjectStudents({ data }) {
  try {
    const response = yield axios({
      url: "/academics/optional-subjects/students",
      method: "POST",
      data: data,
    });
    yield put({
      type: optionalSubjectsActions.ADD_OPTIONAL_SUBJECT_STUDENTS_SUCCESS,
      data: response,
    });
    yield put(optionalSubjectsActions.getOptionalSubjects());
  } catch (error) {
    yield put({
      type: optionalSubjectsActions.ADD_OPTIONAL_SUBJECT_STUDENTS_ERROR,
      error: error.data,
    });
  }
}
function* watchAddOptionalSubjectStudents() {
  yield takeLatest(
    optionalSubjectsActions.ADD_OPTIONAL_SUBJECT_STUDENTS_REQUEST,
    addOptionalSubjectStudents
  );
}

function* deleteOptionalSubject({ id }) {
  try {
    const response = yield axios({
      url: `/academics/optional-subjects/${id}`,
      method: "DELETE",
    });
    yield put({
      type: optionalSubjectsActions.DELETE_OPTIONAL_SUBJECT_SUCCESS,
      data: response,
    });
    yield put(optionalSubjectsActions.getOptionalSubjects());
  } catch (error) {
    yield put({
      type: optionalSubjectsActions.DELETE_OPTIONAL_SUBJECT_ERROR,
      error: error.data,
    });
  }
}
function* watchDeleteOptionalSubject() {
  yield takeLatest(
    optionalSubjectsActions.DELETE_OPTIONAL_SUBJECT_REQUEST,
    deleteOptionalSubject
  );
}
function* deleteOptionalSubjectStudents({ id }) {
  try {
    const response = yield axios({
      url: `/academics/optional-subjects/student/${id}`,
      method: "DELETE",
    });
    yield put({
      type: optionalSubjectsActions.DELETE_OPTIONAL_SUBJECT_STUDENT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: optionalSubjectsActions.DELETE_OPTIONAL_SUBJECT_STUDENT_ERROR,
      error: error.data,
    });
  }
}
function* watchDeleteOptionalSubjectStudents() {
  yield takeLatest(
    optionalSubjectsActions.DELETE_OPTIONAL_SUBJECT_STUDENT_REQUEST,
    deleteOptionalSubjectStudents
  );
}

function* downloadOptionalSubjectStudentsTemplate({ data }) {
  try {
    const response = yield axios({
      url: "/academics/optional-subjects/download-template",
      method: "POST",
      data,
      responseType: "blob",
    });

    FileDownload(response?.data, `OPTIONAL SUBJECTS STUDENTS TEMPLATE.xlsx`);

    yield put({
      type: optionalSubjectsActions.DOWNLOAD_OPTIONAL_SUBJECT_STUDENTS_TEMPLATE_SUCCESS,
      response,
    });

    yield put({
      type: serverActions.SERVER_SUCCESS,
      data: {
        server: { status: true, message: "Successfully downloaded template" },
        message: "Successfully downloaded template",
      },
    });
  } catch (error) {
    yield put({
      type: serverActions.SERVER_ERROR,
      data: {
        server: { status: false, message: "Unable to download template" },
        message: "Unable to download template",
      },
    });

    yield put({
      type: optionalSubjectsActions.DOWNLOAD_OPTIONAL_SUBJECT_STUDENTS_TEMPLATE_ERROR,
      error: error?.data,
    });
  }
}

function* watchDownloadOptionalSubjectStudentsTemplate() {
  yield takeLatest(
    optionalSubjectsActions.DOWNLOAD_OPTIONAL_SUBJECT_STUDENTS_TEMPLATE_REQUEST,
    downloadOptionalSubjectStudentsTemplate
  );
}

function* uploadOptionalSubjectStudents({ data }) {
  try {
    const response = yield axios({
      url: "/academics/optional-subjects/upload",
      method: "POST",
      data: data,
    });
    yield put({
      type: optionalSubjectsActions.UPLOAD_OPTIONAL_SUBJECT_STUDENTS_SUCCESS,
      data: response,
    });
    yield put(optionalSubjectsActions.getOptionalSubjects());
  } catch (error) {
    yield put({
      type: optionalSubjectsActions.UPLOAD_OPTIONAL_SUBJECT_STUDENTS_ERROR,
      error: error.data,
    });
  }
}
function* watchUploadOptionalSubjectStudents() {
  yield takeLatest(
    optionalSubjectsActions.UPLOAD_OPTIONAL_SUBJECT_STUDENTS_REQUEST,
    uploadOptionalSubjectStudents
  );
}

export default [
  fork(watchGetOptionalSubjects),
  fork(watchGetOptionalSubjectsMetaData),
  fork(watchGetOptionalSubjectDetails),
  fork(watchAddOptionalSubject),
  fork(watchAddOptionalSubjectStudents),
  fork(watchDeleteOptionalSubject),
  fork(watchDeleteOptionalSubjectStudents),
  fork(watchDownloadOptionalSubjectStudentsTemplate),
  fork(watchUploadOptionalSubjectStudents),
];
