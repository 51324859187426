const paymentStructuresInitialState = {
  paymentStructures: {
    paymentStructuresLoading: false,
    paymentStructuresSuccess: {},
    paymentStructuresError: {},

    metaDataLoading: false,
    metaData: {},

    addPaymentStructuresSuccess: {},
    addPaymentStructuresError: {},
    addPaymentStructuresLoading: false,

    updatePaymentStructuresLoading: false,
    updatePaymentStructuresSuccess: {},
    updatePaymentStructuresError: {},
    updatePaymentStructureData: {},

    deletePaymentStructuresLoading: false,
    deletePaymentStructuresSuccess: {},
    deletePaymentStructuresError: {},

    paymentStructureDetailsLoading: false,
    paymentStructureDetailsSuccess: {},
    paymentStructureDetailsError: {},

    costDetailsLoading: false,
    costDetailsSuccess: {},
    costDetailsError: {},

    updateCostLoading: false,
    updateCostSuccess: {},
    updateCostError: {},
    updateCostData: {},

    deleteCostLoading: false,
    deleteCostSuccess: {},
    deleteCostError: {},

    checkPaymentStructureSuccess: {},
    checkPaymentStructureLoading: false,
    checkPaymentStructureError: {},

    approvePaymentStructureLoading: false,
    approvePaymentStructureSuccess: {},
    approvePaymentStructureError: {},

    setPaymentStructureCostStatusLoading: false,
    setPaymentStructureCostStatusSuccess: {},
    setPaymentStructureCostStatusError: {},
  },
};

export default paymentStructuresInitialState;
