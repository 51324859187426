import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { appUiActions, requestForQuotationsActions } from "../../../actions/";

function* getRequestForQuotations() {
  try {
    const data = yield axios({
      url: "procurement/request-for-quotations/",
    });

    yield put({
      type: requestForQuotationsActions.GET_REQUEST_FOR_QUOTATIONS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: requestForQuotationsActions.GET_REQUEST_FOR_QUOTATIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetRequestForQuotations() {
  yield takeLatest(
    requestForQuotationsActions.GET_REQUEST_FOR_QUOTATIONS_REQUEST,
    getRequestForQuotations
  );
}

function* getMetaData() {
  try {
    const response = yield axios({
      url: "procurement/request-for-quotations/meta-data",
    });

    yield put({
      type: requestForQuotationsActions.GET_REQUEST_FOR_QUOTATIONS_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: requestForQuotationsActions.GET_REQUEST_FOR_QUOTATIONS_META_DATA_SUCCESS,
      data: {},
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(
    requestForQuotationsActions.GET_REQUEST_FOR_QUOTATIONS_META_DATA_REQUEST,
    getMetaData
  );
}

function* addRequestForQuotation({ data }) {
  try {
    const response = yield axios({
      url: "procurement/request-for-quotations/",
      method: "POST",
      data,
    });

    yield put({
      type: requestForQuotationsActions.ADD_REQUEST_FOR_QUOTATION_SUCCESS,
      data: response,
    });

    yield put({
      type: requestForQuotationsActions.GET_REQUEST_FOR_QUOTATIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: requestForQuotationsActions.ADD_REQUEST_FOR_QUOTATION_ERROR,
      error: error.data,
    });
  }
}

function* watchAddRequestForQuotation() {
  yield takeLatest(
    requestForQuotationsActions.ADD_REQUEST_FOR_QUOTATION_REQUEST,
    addRequestForQuotation
  );
}

function* deleteRequestForQuotation({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `procurement/request-for-quotations/${data}`,
      method: "DELETE",
    });

    yield put({
      type: requestForQuotationsActions.DELETE_REQUEST_FOR_QUOTATION_SUCCESS,
      data: response,
    });

    yield put({
      type: requestForQuotationsActions.GET_REQUEST_FOR_QUOTATIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: requestForQuotationsActions.DELETE_REQUEST_FOR_QUOTATION_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteRequestForQuotation() {
  yield takeLatest(
    requestForQuotationsActions.DELETE_REQUEST_FOR_QUOTATION_REQUEST,
    deleteRequestForQuotation
  );
}

function* updateRequestForQuotation({ data, id }) {
  // id in url
  try {
    const response = yield axios({
      url: `procurement/request-for-quotations/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: requestForQuotationsActions.UPDATE_REQUEST_FOR_QUOTATION_SUCCESS,
      data: response,
    });

    yield put({
      type: appUiActions.TOGGLE_UPDATE_REQUEST_FOR_QUOTATION_MODAL,
      data: false,
    });
  } catch (error) {
    yield put({
      type: requestForQuotationsActions.UPDATE_REQUEST_FOR_QUOTATION_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateRequestForQuotation() {
  yield takeLatest(
    requestForQuotationsActions.UPDATE_REQUEST_FOR_QUOTATION_REQUEST,
    updateRequestForQuotation
  );
}

function* getRequestForQuotationDetails({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `procurement/request-for-quotations/details/${data}`,
    });

    yield put({
      type: requestForQuotationsActions.GET_REQUEST_FOR_QUOTATION_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: requestForQuotationsActions.GET_REQUEST_FOR_QUOTATION_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetRequestForQuotationDetails() {
  yield takeLatest(
    requestForQuotationsActions.GET_REQUEST_FOR_QUOTATION_DETAILS_REQUEST,
    getRequestForQuotationDetails
  );
}

function* checkRequestForQuotation({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `procurement/request-for-quotations/check/${data}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({
      type: requestForQuotationsActions.CHECK_REQUEST_FOR_QUOTATION_SUCCESS,
      data: response,
    });

    yield put({
      type: requestForQuotationsActions.GET_REQUEST_FOR_QUOTATION_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: requestForQuotationsActions.CHECK_REQUEST_FOR_QUOTATION_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckRequestForQuotation() {
  yield takeLatest(
    requestForQuotationsActions.CHECK_REQUEST_FOR_QUOTATION_REQUEST,
    checkRequestForQuotation
  );
}

function* approveRequestForQuotation({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `procurement/request-for-quotations/approve/${data}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: requestForQuotationsActions.APPROVE_REQUEST_FOR_QUOTATION_SUCCESS,
      data: response,
    });

    yield put({
      type: requestForQuotationsActions.GET_REQUEST_FOR_QUOTATION_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: requestForQuotationsActions.APPROVE_REQUEST_FOR_QUOTATION_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveRequestForQuotation() {
  yield takeLatest(
    requestForQuotationsActions.APPROVE_REQUEST_FOR_QUOTATION_REQUEST,
    approveRequestForQuotation
  );
}

export default [
  fork(watchGetRequestForQuotations),
  fork(watchGetMetaData),
  fork(watchAddRequestForQuotation),
  fork(watchDeleteRequestForQuotation),
  fork(watchUpdateRequestForQuotation),
  fork(watchGetRequestForQuotationDetails),
  fork(watchCheckRequestForQuotation),
  fork(watchApproveRequestForQuotation),
];
