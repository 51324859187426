const banks = {
  GET_BANKS_REQUEST: "GET_BANKS_REQUEST",
  GET_BANKS_SUCCESS: "GET_BANKS_SUCCESS",
  GET_BANKS_ERROR: "GET_BANKS_ERROR",

  ADD_BANKS_REQUEST: "ADD_BANKS_REQUEST",
  ADD_BANKS_SUCCESS: "ADD_BANKS_SUCCESS",
  ADD_BANKS_ERROR: "ADD_BANKS_ERROR",

  DELETE_BANK_REQUEST: "DELETE_BANK_REQUEST",
  DELETE_BANK_SUCCESS: "DELETE_BANK_SUCCESS",
  DELETE_BANK_ERROR: "DELETE_BANK_ERROR",

  UPDATE_BANKS_REQUEST: "UPDATE_BANKS_REQUEST",
  UPDATE_BANKS_SUCCESS: "UPDATE_BANKS_SUCCESS",
  UPDATE_BANKS_ERROR: "UPDATE_BANKS_ERROR",
  SET_UPDATE_BANKS_DATA: "SET_UPDATE_BANKS_DATA",

  GET_BANK_DETAILS_REQUEST: "GET_BANK_DETAILS_REQUEST",
  GET_BANK_DETAILS_SUCCESS: "GET_BANK_DETAILS_SUCCESS",
  GET_BANK_DETAILS_ERROR: "GET_BANK_DETAILS_ERROR",

  CHECK_BANK_REQUEST: "CHECK_BANK_REQUEST",
  CHECK_BANK_SUCCESS: "CHECK_BANK_SUCCESS",
  CHECK_BANK_ERROR: "CHECK_BANK_ERROR",

  APPROVE_BANK_REQUEST: "APPROVE_BANK_REQUEST",
  APPROVE_BANK_SUCCESS: "APPROVE_BANK_SUCCESS",
  APPROVE_BANK_ERROR: "APPROVE_BANK_ERROR",

  GET_META_DATA_SUCCESS: "GET_META_DATA_BANKS_SUCCESS",
  GET_META_DATA_REQUEST: "GET_META_DATA_BANKS_REQUEST",
  GET_META_DATA_ERROR: "GET_META_DATA_ERROR",

  DELETE_SUB_ACCOUNTS_REQUEST: "DELETE_SUB_ACCOUNTS_REQUEST",
  DELETE_SUB_ACCOUNTS_SUCCESS: "DELETE_SUB_ACCOUNTS_SUCCESS",
  DELETE_SUB_ACCOUNTS_ERROR: "DELETE_SUB_ACCOUNTS_ERROR",

  TERMLY_BANK_STATEMENT_REQUEST: "TERMLY_BANK_STATEMENT_REQUEST",
  TERMLY_BANK_STATEMENT_SUCCESS: "TERMLY_BANK_STATEMENT_SUCCESS",
  TERMLY_BANK_STATEMENT_ERROR: "TERMLY_BANK_STATEMENT_ERROR",

  BANK_STATEMENT_REQUEST: "BANK_STATEMENT_REQUEST",
  BANK_STATEMENT_SUCCESS: "BANK_STATEMENT_SUCCESS",
  BANK_STATEMENT_ERROR: "BANK_STATEMENT_ERROR",

  YEARLY_BANK_STATEMENT_REQUEST: "YEARLY_BANK_STATEMENT_REQUEST",
  YEARLY_BANK_STATEMENT_SUCCESS: "YEARLY_BANK_STATEMENT_SUCCESS",
  YEARLY_BANK_STATEMENT_ERROR: "YEARLY_BANK_STATEMENT_ERROR",

  SET_BANK_STATEMENT_DATA: "SET_BANK_STATEMENT_DATA",
  SET_PERIOD_ACCOUNT_STATEMENT: "SET_PERIOD_ACCOUNT_STATEMENT",

  setPeriodAccountStatement: (data) => ({
    type: banks.SET_PERIOD_ACCOUNT_STATEMENT,
    data,
  }),
  getBanks: () => ({
    type: banks.GET_BANKS_REQUEST,
  }),
  getMetaData: () => ({
    type: banks.GET_META_DATA_REQUEST,
  }),
  deleteSubAccount: (id, bankId) => ({
    type: banks.DELETE_SUB_ACCOUNTS_REQUEST,
    id,
    bankId,
  }),
  addBanks: (data) => ({
    type: banks.ADD_BANKS_REQUEST,
    data,
  }),
  deleteBank: (data, banksArray) => ({
    type: banks.DELETE_BANK_REQUEST,
    data,
    banks: banksArray,
  }),
  setUpdateBanksData: (data) => ({
    type: banks.SET_UPDATE_BANKS_DATA,
    data,
  }),
  updateBank: (data, banksArray, id) => ({
    type: banks.UPDATE_BANKS_REQUEST,
    data,
    banks: banksArray,
    id,
  }),
  getBankDetails: (data) => ({
    type: banks.GET_BANK_DETAILS_REQUEST,
    data,
  }),
  checkBank: (data) => ({
    type: banks.CHECK_BANK_REQUEST,
    data,
  }),
  approveBank: (data) => ({
    type: banks.APPROVE_BANK_REQUEST,
    data,
  }),
  getTermlyBankStatement: (bank_id, year_id) => ({
    type: banks.TERMLY_BANK_STATEMENT_REQUEST,
    bank_id,
    year_id,
  }),
  getBankStatement: (bank_id) => ({
    type: banks.BANK_STATEMENT_REQUEST,
    bank_id,
  }),
  getYearlyBankStatement: (bank_id, year_id) => ({
    type: banks.YEARLY_BANK_STATEMENT_REQUEST,
    bank_id,
    year_id,
  }),
  setBankStatementData: (data) => ({
    type: banks.SET_BANK_STATEMENT_DATA,
    data,
  }),
};

export default banks;
