const requisitionMemos = {
  GET_REQUISITION_MEMOS_REQUEST: "GET_REQUISITION_MEMOS_REQUEST",
  GET_REQUISITION_MEMOS_SUCCESS: "GET_REQUISITION_MEMOS_SUCCESS",
  GET_REQUISITION_MEMOS_ERROR: "GET_REQUISITION_MEMOS_ERROR",

  GET_META_DATA_REQUEST: "GET_REQUISITION_MEMOS_META_DATA_REQUEST",
  GET_META_DATA_SUCCESS: "GET_REQUISITION_MEMOS_META_DATA_SUCCESS",

  ADD_REQUISITION_MEMO_REQUEST: "ADD_REQUISITION_MEMO_REQUEST",
  ADD_REQUISITION_MEMO_SUCCESS: "ADD_REQUISITION_MEMO_SUCCESS",
  ADD_REQUISITION_MEMO_ERROR: "ADD_REQUISITION_MEMO_ERROR",

  SEARCH_REQUISITION_MEMOS_REQUEST: "SEARCH_REQUISITION_MEMOS_REQUEST",
  SEARCH_REQUISITION_MEMOS_SUCCESS: "SEARCH_REQUISITION_MEMOS_SUCCESS",
  SEARCH_REQUISITION_MEMOS_ERROR: "SEARCH_REQUISITION_MEMOS_ERROR",

  DELETE_REQUISITION_MEMO_REQUEST: "DELETE_REQUISITION_MEMO_REQUEST",
  DELETE_REQUISITION_MEMO_SUCCESS: "DELETE_REQUISITION_MEMO_SUCCESS",
  DELETE_REQUISITION_MEMO_ERROR: "DELETE_REQUISITION_MEMO_ERROR",

  UPDATE_REQUISITION_MEMO_REQUEST: "UPDATE_REQUISITION_MEMO_REQUEST",
  UPDATE_REQUISITION_MEMO_SUCCESS: "UPDATE_REQUISITION_MEMO_SUCCESS",
  UPDATE_REQUISITION_MEMO_ERROR: "UPDATE_REQUISITION_MEMO_ERROR",
  SET_UPDATE_REQUISITION_MEMO_DATA: "UPDATE_REQUISITION_MEMO_DATA",

  GET_REQUISITION_MEMO_DETAILS_REQUEST: "GET_REQUISITION_MEMO_DETAILS_REQUEST",
  GET_REQUISITION_MEMO_DETAILS_SUCCESS: "GET_REQUISITION_MEMO_DETAILS_SUCCESS",
  GET_REQUISITION_MEMO_DETAILS_ERROR: "GET_REQUISITION_MEMO_DETAILS_ERROR",

  SET_UPDATE_REQUISITION_MEMO_COST_DATA:
    "SET_UPDATE_REQUISITION_MEMO_COST_DATA",
  UPDATE_REQUISITION_MEMO_COST_REQUEST: "UPDATE_REQUISITION_MEMO_COST_REQUEST",
  UPDATE_REQUISITION_MEMO_COST_SUCCESS: "UPDATE_REQUISITION_MEMO_COST_SUCCESS",
  UPDATE_REQUISITION_MEMO_COST_ERROR: "UPDATE_REQUISITION_MEMO_COST_ERROR",

  GET_REQUISITION_MEMO_COST_DETAILS_REQUEST:
    "GET_REQUISITION_MEMO_COST_DETAILS_REQUEST",
  GET_REQUISITION_MEMO_COST_DETAILS_SUCCESS:
    "GET_REQUISITION_MEMO_COST_DETAILS_SUCCESS",
  GET_REQUISITION_MEMO_COST_DETAILS_ERROR:
    "GET_REQUISITION_MEMO_COST_DETAILS_ERROR",

  ADD_REQUISITION_MEMO_COST_REQUEST: "ADD_REQUISITION_MEMO_COST_REQUEST",
  ADD_REQUISITION_MEMO_COST_SUCCESS: "ADD_REQUISITION_MEMO_COST_SUCCESS",
  ADD_REQUISITION_MEMO_COST_ERROR: "ADD_REQUISITION_MEMO_COST_ERROR",

  DELETE_REQUISITION_MEMO_COST_REQUEST: "DELETE_REQUISITION_MEMO_COST_REQUEST",
  DELETE_REQUISITION_MEMO_COST_ERROR: "DELETE_REQUISITION_MEMO_COST_ERROR",
  DELETE_REQUISITION_MEMO_COST_SUCCESS: "DELETE_REQUISITION_MEMO_COST_SUCCESS",

  SUBMIT_REQUISITION_MEMO_REQUEST: "SUBMIT_REQUISITION_MEMO_REQUEST",
  SUBMIT_REQUISITION_MEMO_SUCCESS: "SUBMIT_REQUISITION_MEMO_SUCCESS",
  SUBMIT_REQUISITION_MEMO_ERROR: "SUBMIT_REQUISITION_MEMO_ERROR",

  CHECK_REQUISITION_MEMO_REQUEST: "CHECK_REQUISITION_MEMO_REQUEST",
  CHECK_REQUISITION_MEMO_SUCCESS: "CHECK_REQUISITION_MEMO_SUCCESS",
  CHECK_REQUISITION_MEMO_ERROR: "CHECK_REQUISITION_MEMO_ERROR",

  APPROVE_REQUISITION_MEMO_REQUEST: "APPROVE_REQUISITION_MEMO_REQUEST",
  APPROVE_REQUISITION_MEMO_SUCCESS: "APPROVE_REQUISITION_MEMO_SUCCESS",
  APPROVE_REQUISITION_MEMO_ERROR: "APPROVE_REQUISITION_MEMO_ERROR",

  SORT_REQUISITION_MEMOS_REQUEST: "SORT_REQUISITION_MEMOS_REQUEST",
  SORT_REQUISITION_MEMOS_SUCCESS: "SORT_REQUISITION_MEMOS_SUCCESS",
  SORT_REQUISITION_MEMOS_ERROR: "SORT_REQUISITION_MEMOS_ERROR",

  DOWNLOAD_REQUISITION_MEMOS_REQUEST: "DOWNLOAD_REQUISITION_MEMOS_REQUEST",
  DOWNLOAD_REQUISITION_MEMOS_SUCCESS: "DOWNLOAD_REQUISITION_MEMOS_SUCCESS",
  DOWNLOAD_REQUISITION_MEMOS_ERROR: "DOWNLOAD_REQUISITION_MEMOS_ERROR",

  SET_DOWNLOAD_QUERRY: "SET_DOWNLOAD_QUERRY",

  getRequisitionMemos: () => ({
    type: requisitionMemos.GET_REQUISITION_MEMOS_REQUEST,
  }),
  getMetaData: (data) => ({
    type: requisitionMemos.GET_META_DATA_REQUEST,
    data,
  }),
  addRequisitionMemo: (data) => ({
    type: requisitionMemos.ADD_REQUISITION_MEMO_REQUEST,
    data,
  }),
  search: (data) => ({
    type: requisitionMemos.SEARCH_REQUISITION_MEMOS_REQUEST,
    data,
  }),
  deleteRequisitionMemo: (data, newArray) => ({
    type: requisitionMemos.DELETE_REQUISITION_MEMO_REQUEST,
    data,
    requisitionMemos: newArray,
  }),
  setUpdateRequisitionMemoData: (data) => ({
    type: requisitionMemos.SET_UPDATE_REQUISITION_MEMO_DATA,
    data,
  }),
  updateRequisitionMemo: (data, id) => ({
    type: requisitionMemos.UPDATE_REQUISITION_MEMO_REQUEST,
    data,
    id,
  }),
  getRequisitionMemoDetails: (data) => ({
    type: requisitionMemos.GET_REQUISITION_MEMO_DETAILS_REQUEST,
    data,
  }),
  addRequisitionMemoCost: (data) => ({
    type: requisitionMemos.ADD_REQUISITION_MEMO_COST_REQUEST,
    data,
  }),
  deleteRequisitionMemoCost: (data, params) => ({
    type: requisitionMemos.DELETE_REQUISITION_MEMO_COST_REQUEST,
    data,
    params,
  }),
  setUpdateRequisitionMemoCostData: (data) => ({
    type: requisitionMemos.SET_UPDATE_REQUISITION_MEMO_COST_DATA,
    data,
  }),
  updateRequisitionMemoCost: (data, id) => ({
    type: requisitionMemos.UPDATE_REQUISITION_MEMO_COST_REQUEST,
    data,
    id,
  }),
  getRequisitionMemoCostDetails: (data) => ({
    type: requisitionMemos.GET_REQUISITION_MEMO_COST_DETAILS_REQUEST,
    data,
  }),
  submitRequisitionMemo: (data) => ({
    type: requisitionMemos.SUBMIT_REQUISITION_MEMO_REQUEST,
    data,
  }),
  checkRequisitionMemo: (data) => ({
    type: requisitionMemos.CHECK_REQUISITION_MEMO_REQUEST,
    data,
  }),
  approveRequisitionMemo: (data) => ({
    type: requisitionMemos.APPROVE_REQUISITION_MEMO_REQUEST,
    data,
  }),
  sortRequisitionMemos: (data) => ({
    type: requisitionMemos.SORT_REQUISITION_MEMOS_REQUEST,
    data,
  }),
  downloadRequisitionMemos: (data) => ({
    type: requisitionMemos.DOWNLOAD_REQUISITION_MEMOS_REQUEST,
    data,
  }),
  setDownloadQuerry: (data) => ({
    type: requisitionMemos.SET_DOWNLOAD_QUERRY,
    data,
  }),
};

export default requisitionMemos;
