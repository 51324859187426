import { receivablesActions } from "../../../actions";
import initialState from "../../../initialState";

const receivables = (state = initialState.receivables, actions) => {
  switch (actions.type) {
    case receivablesActions.GET_RECEIVABLES_REQUEST:
      return {
        ...state,
        receivablesError: {},
        receivablesLoading: true,
      };
    case receivablesActions.GET_RECEIVABLES_SUCCESS:
      return {
        ...state,
        receivables: actions.data,
        receivablesLoading: false,
      };
    case receivablesActions.GET_RECEIVABLES_ERROR:
      return {
        ...state,
        receivablesError: actions.data,
        receivablesLoading: false,
      };

    case receivablesActions.SET_SELECTED_RECEIVABLE:
      return {
        ...state,
        selectedReceivable: actions.data,
      };
    default:
      return state;
  }
};

export default receivables;
