import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { staffPayRollActions } from "../../../actions";

function* getStaffPayRoll() {
  try {
    const response = yield axios({
      url: "/human-resource/payroll/",
    });

    yield put({
      type: staffPayRollActions.GET_STAFF_PAYROLL_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: staffPayRollActions.GET_STAFF_PAYROLL_ERROR,
      error: error.data,
    });
  }
}

function* watchGetStaffPayRoll() {
  yield takeLatest(
    staffPayRollActions.GET_STAFF_PAYROLL_REQUEST,
    getStaffPayRoll
  );
}

function* getStaffPayRollMetaData() {
  try {
    const response = yield axios({
      url: "/human-resource/payroll/meta-data",
    });

    yield put({
      type: staffPayRollActions.GET_STAFF_PAYROLL_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: staffPayRollActions.GET_STAFF_PAYROLL_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetStaffPayRollMetaData() {
  yield takeLatest(
    staffPayRollActions.GET_STAFF_PAYROLL_META_DATA_REQUEST,
    getStaffPayRollMetaData
  );
}

function* getStaffPayRollDetails({ id }) {
  try {
    const response = yield axios({
      url: `/human-resource/payroll/${id}`,
    });

    yield put({
      type: staffPayRollActions.GET_STAFF_PAYROLL_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: staffPayRollActions.GET_STAFF_PAYROLL_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetStaffPayRollDetails() {
  yield takeLatest(
    staffPayRollActions.GET_STAFF_PAYROLL_DETAILS_REQUEST,
    getStaffPayRollDetails
  );
}

function* addStaffPayRoll({ data }) {
  try {
    const response = yield axios({
      url: "/human-resource/payroll",
      method: "POST",
      data,
    });

    yield put({
      type: staffPayRollActions.ADD_STAFF_PAYROLL_SUCCESS,
      data: response,
    });

    yield put(staffPayRollActions.getStaffPayRoll());
  } catch (error) {
    yield put({
      type: staffPayRollActions.ADD_STAFF_PAYROLL_ERROR,
      error: error.data,
    });
  }
}

function* watchAddStaffPayRoll() {
  yield takeLatest(
    staffPayRollActions.ADD_STAFF_PAYROLL_REQUEST,
    addStaffPayRoll
  );
}

function* updateStaffPayRoll({ id, data }) {
  try {
    const response = yield axios({
      url: `/human-resource/payroll/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: staffPayRollActions.UPDATE_STAFF_PAYROLL_SUCCESS,
      data: {
        ...response,
        id,
        data,
      },
    });

    yield put(staffPayRollActions.getStaffPayRoll());
  } catch (error) {
    yield put({
      type: staffPayRollActions.UPDATE_STAFF_PAYROLL_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateStaffPayRoll() {
  yield takeLatest(
    staffPayRollActions.UPDATE_STAFF_PAYROLL_REQUEST,
    updateStaffPayRoll
  );
}

function* deleteStaffPayRoll({ id }) {
  try {
    const response = yield axios({
      url: `/human-resource/payroll/${id}`,
      method: "DELETE",
    });

    yield put({
      type: staffPayRollActions.DELETE_STAFF_PAYROLL_SUCCESS,
      data: {
        ...response,
        id,
      },
    });
  } catch (error) {
    yield put({
      type: staffPayRollActions.DELETE_STAFF_PAYROLL_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteStaffPayRoll() {
  yield takeLatest(
    staffPayRollActions.DELETE_STAFF_PAYROLL_REQUEST,
    deleteStaffPayRoll
  );
}

function* deleteStaffFromPayRoll({ id, payroll_id }) {
  try {
    const response = yield axios({
      url: `/human-resource/payroll/staff/${id}?payroll_id=${payroll_id}`,
      method: "DELETE",
    });

    yield put({
      type: staffPayRollActions.DELETE_STAFF_FROM_PAYROLL_SUCCESS,
      data: {
        ...response,
        id,
        payroll_id,
      },
    });
    yield put(staffPayRollActions.getStaffPayRollDetails(payroll_id));
  } catch (error) {
    yield put({
      type: staffPayRollActions.DELETE_STAFF_FROM_PAYROLL_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteStaffFromPayRoll() {
  yield takeLatest(
    staffPayRollActions.DELETE_STAFF_FROM_PAYROLL_REQUEST,
    deleteStaffFromPayRoll
  );
}

function* checkStaffPayRoll({ id }) {
  try {
    const response = yield axios({
      url: `/human-resource/payroll/check/${id}`,
      method: "PATCH",
    });

    yield put({
      type: staffPayRollActions.CHECK_STAFF_PAYROLL_SUCCESS,
      data: {
        ...response,
        id,
      },
    });

    yield put(staffPayRollActions.getStaffPayRollDetails(id));
  } catch (error) {
    yield put({
      type: staffPayRollActions.CHECK_STAFF_PAYROLL_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckStaffPayRoll() {
  yield takeLatest(
    staffPayRollActions.CHECK_STAFF_PAYROLL_REQUEST,
    checkStaffPayRoll
  );
}

function* approveStaffPayRoll({ id }) {
  try {
    const response = yield axios({
      url: `/human-resource/payroll/approve/${id}`,
      method: "PATCH",
    });

    yield put({
      type: staffPayRollActions.APPROVE_STAFF_PAYROLL_SUCCESS,
      data: {
        ...response,
        id,
      },
    });

    yield put(staffPayRollActions.getStaffPayRollDetails(id));
  } catch (error) {
    yield put({
      type: staffPayRollActions.APPROVE_STAFF_PAYROLL_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveStaffPayRoll() {
  yield takeLatest(
    staffPayRollActions.APPROVE_STAFF_PAYROLL_REQUEST,
    approveStaffPayRoll
  );
}

export default [
  fork(watchGetStaffPayRoll),
  fork(watchGetStaffPayRollMetaData),
  fork(watchGetStaffPayRollDetails),
  fork(watchAddStaffPayRoll),
  fork(watchUpdateStaffPayRoll),
  fork(watchDeleteStaffPayRoll),
  fork(watchDeleteStaffFromPayRoll),
  fork(watchCheckStaffPayRoll),
  fork(watchApproveStaffPayRoll),
];
