import { userProfileActions } from "../../../actions";
import initialState from "../../../initialState";

const userProfile = (state = initialState.userProfile, actions) => {
  switch (actions.type) {
    case userProfileActions.GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        userProfileLoading: true,
      };
    case userProfileActions.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfileLoading: false,
        userProfileSuccess: actions.data,
        userProfileError: {},
      };
    case userProfileActions.GET_USER_PROFILE_ERROR:
      return {
        ...state,
        userProfileLoading: false,
        userProfileError: actions.error,
      };
    case userProfileActions.UPDATE_USER_PROFILE_REQUEST:
      return {
        ...state,
        updateUserProfileLoading: true,
      };
    case userProfileActions.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        updateUserProfileLoading: false,
        userProfileSuccess: actions.data,
        userProfileError: {},
      };
    case userProfileActions.UPDATE_USER_PROFILE_ERROR:
      return {
        ...state,
        updateUserProfileLoading: false,
        userProfileError: actions.error,
      };
    case userProfileActions.UPLOAD_USER_PROFILE_AVATAR_REQUEST:
      return {
        ...state,
        uploadUserProfileAvatarLoading: true,
      };
    case userProfileActions.UPLOAD_USER_PROFILE_AVATAR_SUCCESS:
      return {
        ...state,
        uploadUserProfileAvatarLoading: false,
        uploadUserProfileAvatarSuccess: actions.data,
        uploadUserProfileAvatarError: {},
      };
    case userProfileActions.UPLOAD_USER_PROFILE_AVATAR_ERROR:
      return {
        ...state,
        uploadUserProfileAvatarLoading: false,
        uploadUserProfileAvatarError: actions.error,
      };
    case userProfileActions.UPLOAD_USER_SIGNATURE_REQUEST:
      return {
        ...state,
        uploadUserSignatureLoading: true,
      };
    case userProfileActions.UPLOAD_USER_SIGNATURE_SUCCESS:
      return {
        ...state,
        uploadUserSignatureLoading: false,
        uploadUserSignatureSuccess: actions.data,
        uploadUserSignatureError: {},
      };
    case userProfileActions.UPLOAD_USER_SIGNATURE_ERROR:
      return {
        ...state,
        uploadUserSignatureLoading: false,
        uploadUserSignatureError: actions.error,
      };
    case userProfileActions.CHANGE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        changeUserPasswordLoading: true,
      };
    case userProfileActions.CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        changeUserPasswordLoading: false,
        changeUserPasswordSuccess: actions.data,
        changeUserPasswordError: {},
      };
    case userProfileActions.CHANGE_USER_PASSWORD_ERROR:
      return {
        ...state,
        changeUserPasswordLoading: false,
        changeUserPasswordError: actions.error,
      };
    case userProfileActions.UPDATE_USER_EMAIL_REQUEST:
      return {
        ...state,
        updateUserEmailLoading: true,
      };
    case userProfileActions.UPDATE_USER_EMAIL_SUCCESS:
      return {
        ...state,
        updateUserEmailLoading: false,
        updateUserEmailSuccess: actions.data,
        updateUserEmailError: {},
      };
    case userProfileActions.UPDATE_USER_EMAIL_ERROR:
      return {
        ...state,
        updateUserEmailLoading: false,
        updateUserEmailError: actions.error,
      };
    case userProfileActions.SET_UPDATE_USER_EMAIL_DATA:
      return {
        ...state,
        updateUserEmailData: actions.data,
      };
    default:
      return state;
  }
};

export default userProfile;
