import { banksActions } from "../../../actions";
import initialState from "../../../initialState";

const banks = (state = initialState.banks, actions) => {
  switch (actions.type) {
    case banksActions.DELETE_SUB_ACCOUNTS_REQUEST:
      return {
        ...state,
        deleteSubAccountLoading: true,
        deleteSubAccountError: {},
      };
    case banksActions.DELETE_SUB_ACCOUNTS_SUCCESS:
      return {
        ...state,
        deleteSubAccountSuccess: actions.data,
        deleteSubAccountLoading: false,
      };
    case banksActions.DELETE_SUB_ACCOUNTS_ERROR:
      return {
        ...state,
        deleteSubAccountLoading: false,
        deleteSubAccountError: actions.error,
      };
    case banksActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
        metaDataError: {},
      };
    case banksActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: actions.data,
        metaDataLoading: false,
      };
    case banksActions.GET_META_DATA_ERROR:
      return {
        ...state,
        metaDataError: actions.error,
        metaDataLoading: false,
      };
    case banksActions.GET_BANKS_REQUEST:
      return {
        ...state,
        banksLoading: true,
        banksError: {},
      };
    case banksActions.GET_BANKS_SUCCESS:
      return {
        ...state,
        banksSuccess: actions.data,
        banksLoading: false,
      };
    case banksActions.GET_BANKS_ERROR:
      return {
        ...state,
        banksLoading: false,
        banksError: actions.error,
      };
    case banksActions.ADD_BANKS_REQUEST:
      return {
        ...state,
        addBanksLoading: true,
        addBanksError: {},
      };
    case banksActions.ADD_BANKS_SUCCESS:
      return {
        ...state,
        addBanksLoading: false,
        addBanksSuccess: actions.data,
        banksSuccess: {
          ...state.banksSuccess,
          banks: [actions.data, ...state.banksSuccess.banks],
        },
      };
    case banksActions.ADD_BANKS_ERROR:
      return {
        ...state,
        addBanksLoading: false,
        addBanksError: actions.error,
      };
    case banksActions.DELETE_BANK_REQUEST:
      return {
        ...state,
        deleteBankLoading: true,
        deleteBankError: {},
      };
    case banksActions.DELETE_BANK_SUCCESS:
      return {
        ...state,
        deleteBankLoading: false,
        deleteBankSuccess: actions.data,
        banksSuccess: {
          ...state.banksSuccess,
          banks: actions.banks,
        },
      };
    case banksActions.DELETE_BANK_ERROR:
      return {
        ...state,
        deleteBankError: actions.error,
        deleteBankLoading: false,
      };
    case banksActions.SET_UPDATE_BANKS_DATA:
      return {
        ...state,
        updateBankData: actions.data,
      };
    case banksActions.UPDATE_BANKS_REQUEST:
      return {
        ...state,
        updateBankLoading: true,
        updateBankError: {},
      };
    case banksActions.UPDATE_BANKS_SUCCESS:
      return {
        ...state,
        updateBankSuccess: actions.data,
        updateBankLoading: false,
        banksSuccess: {
          ...state.banksSuccess,
          banks: actions.banks,
        },
      };
    case banksActions.UPDATE_BANKS_ERROR:
      return {
        ...state,
        updateBankError: actions.error,
        updateBankLoading: false,
      };
    case banksActions.GET_BANK_DETAILS_REQUEST:
      return {
        ...state,
        bankDetailsLoading: true,
        bankDetailsError: {},
      };
    case banksActions.GET_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        bankDetailsSuccess: actions.data,
        bankDetailsLoading: false,
      };
    case banksActions.GET_BANK_DETAILS_ERROR:
      return {
        ...state,
        bankDetailsError: actions.error,
        bankDetailsLoading: false,
      };
    case banksActions.CHECK_BANK_REQUEST:
      return {
        ...state,
        checkBankLoading: true,
        checkBankError: {},
      };
    case banksActions.CHECK_BANK_SUCCESS:
      return {
        ...state,
        checkBankSuccess: actions.data,
        checkBankLoading: false,
      };
    case banksActions.CHECK_BANK_ERROR:
      return {
        ...state,
        checkBankError: actions.error,
        checkBankLoading: false,
      };
    case banksActions.APPROVE_BANK_REQUEST:
      return {
        ...state,
        approveBankLoading: true,
        approveBankError: actions.error,
      };
    case banksActions.APPROVE_BANK_SUCCESS:
      return {
        ...state,
        approveBankSuccess: actions.data,
        approveBankLoading: false,
      };
    case banksActions.APPROVE_BANK_ERROR:
      return {
        ...state,
        approveBankError: actions.error,
        approveBankLoading: false,
      };
    case banksActions.TERMLY_BANK_STATEMENT_REQUEST:
      return {
        ...state,
        termlyBankStatementLoading: true,
        termlyBankStatementError: {},
      };
    case banksActions.TERMLY_BANK_STATEMENT_SUCCESS:
      return {
        ...state,
        termlyBankStatementSuccess: actions.data,
        termlyBankStatementLoading: false,
      };
    case banksActions.TERMLY_BANK_STATEMENT_ERROR:
      return {
        ...state,
        termlyBankStatementError: actions.error,
        termlyBankStatementLoading: false,
      };
    case banksActions.BANK_STATEMENT_REQUEST:
      return {
        ...state,
        bankStatementLoading: true,
        bankStatementError: {},
      };
    case banksActions.BANK_STATEMENT_SUCCESS:
      return {
        ...state,
        bankStatementSuccess: actions.data,
        bankStatementLoading: false,
      };
    case banksActions.BANK_STATEMENT_ERROR:
      return {
        ...state,
        bankStatementError: actions.error,
        bankStatementLoading: false,
      };
    case banksActions.YEARLY_BANK_STATEMENT_REQUEST:
      return {
        ...state,
        yearlyBankStatementLoading: true,
        yearlyBankStatementError: {},
      };
    case banksActions.YEARLY_BANK_STATEMENT_SUCCESS:
      return {
        ...state,
        yearlyBankStatementSuccess: actions.data,
        yearlyBankStatementLoading: false,
      };
    case banksActions.YEARLY_BANK_STATEMENT_ERROR:
      return {
        ...state,
        yearlyBankStatementError: actions.error,
        yearlyBankStatementLoading: false,
      };
    case banksActions.SET_BANK_STATEMENT_DATA:
      return {
        ...state,
        bankStatementData: actions.data,
      };
    case banksActions.SET_PERIOD_ACCOUNT_STATEMENT:
      return {
        ...state,
        periodBankStatement: actions.data,
      };
    default:
      return state;
  }
};

export default banks;
