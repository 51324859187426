import { learnerBillingActions } from "../../../actions";
import learnerBillingInitialState from "../../../initialState/administration/learnerBilling/learnerBilling.initialState";

const learnerBilling = (state = learnerBillingInitialState, action) => {
  switch (action.type) {
    case learnerBillingActions.LEARNER_BILLING_REQUEST:
      return {
        ...state,
        learnerBillingLoading: true,
      };
    case learnerBillingActions.LEARNER_BILLING_SUCCESS:
      return {
        ...state,
        learnerBillingSuccess: action.data,
        learnerBillingError: {},
        learnerBillingLoading: false,
      };
    case learnerBillingActions.LEARNER_BILLING_ERROR:
      return {
        ...state,
        learnerBillingError: action.error,
        learnerBillingLoading: false,
      };
    case learnerBillingActions.META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case learnerBillingActions.META_DATA_SUCCESS:
      return {
        ...state,
        metaDataSuccess: action.data,
        metaDataError: {},
        metaDataLoading: false,
      };
    case learnerBillingActions.META_DATA_ERROR:
      return {
        ...state,
        metaDataError: action.error,
        metaDataLoading: false,
      };
    case learnerBillingActions.BILL_LEARNERS_REQUEST:
      return {
        ...state,
        billLearnersLoading: true,
      };
    case learnerBillingActions.BILL_LEARNERS_SUCCESS:
      return {
        ...state,
        billLearnersSuccess: action.data,
        billLearnersError: {},
        billLearnersLoading: false,
      };
    case learnerBillingActions.BILL_LEARNERS_ERROR:
      return {
        ...state,
        billLearnersError: action.error,
        billLearnersLoading: false,
      };
    case learnerBillingActions.DELETE_LEARNER_BILL_REQUEST:
      return {
        ...state,
        deleteLearnerBillLoading: true,
      };
    case learnerBillingActions.DELETE_LEARNER_BILL_SUCCESS:
      return {
        ...state,
        deleteLearnerBillSuccess: action.data,
        deleteLearnerBillError: {},
        deleteLearnerBillLoading: false,
      };
    case learnerBillingActions.DELETE_LEARNER_BILL_ERROR:
      return {
        ...state,
        deleteLearnerBillError: action.error,
        deleteLearnerBillLoading: false,
      };
    case learnerBillingActions.SET_LEARNER_BILLING_DATA:
      return {
        ...state,
        learnerBillingData: action.data,
      };
    default:
      return state;
  }
};

export default learnerBilling;
