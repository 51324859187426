const teacherAssignment = {
  GET_TEACHER_ASSIGNMENT_REQUEST: "GET_TEACHER_ASSIGNMENT_REQUEST",
  GET_TEACHER_ASSIGNMENT_SUCCESS: "GET_TEACHER_ASSIGNMENT_SUCCESS",
  GET_TEACHER_ASSIGNMENT_ERROR: "GET_TEACHER_ASSIGNMENT_ERROR",

  GET_TEACHER_ASSIGNMENT_DETAILS_REQUEST:
    "GET_TEACHER_ASSIGNMENT_DETAILS_REQUEST",
  GET_TEACHER_ASSIGNMENT_DETAILS_SUCCESS:
    "GET_TEACHER_ASSIGNMENT_DETAILS_SUCCESS",
  GET_TEACHER_ASSIGNMENT_DETAILS_ERROR: "GET_TEACHER_ASSIGNMENT_DETAILS_ERROR",

  GET_META_DATA_REQUEST: "GET_TEACHER_ASSIGNMENT_META_DATA_REQUEST",
  GET_META_DATA_SUCCESS: "GET_TEACHER_ASSIGNMENT_META_DATA_SUCCESS",

  ADD_TEACHER_ASSIGNMENT_REQUEST: "ADD_TEACHER_ASSIGNMENT_REQUEST",
  ADD_TEACHER_ASSIGNMENT_SUCCESS: "ADD_TEACHER_ASSIGNMENT_SUCCESS",
  ADD_TEACHER_ASSIGNMENT_ERROR: "ADD_TEACHER_ASSIGNMENT_ERROR",

  UPDATE_TEACHER_ASSIGNMENT_REQUEST: "UPDATE_TEACHER_ASSIGNMENT_REQUEST",
  UPDATE_TEACHER_ASSIGNMENT_SUCCESS: "UPDATE_TEACHER_ASSIGNMENT_SUCCESS",
  UPDATE_TEACHER_ASSIGNMENT_ERROR: "UPDATE_TEACHER_ASSIGNMENT_ERROR",
  UPDATE_TEACHER_ASSIGNMENT_DATA: "UPDATE_TEACHER_ASSIGNMENT_DATA",

  DELETE_TEACHER_ASSIGNMENT_REQUEST: "DELETE_TEACHER_ASSIGNMENT_REQUEST",
  DELETE_TEACHER_ASSIGNMENT_SUCCESS: "DELETE_TEACHER_ASSIGNMENT_SUCCESS",
  DELETE_TEACHER_ASSIGNMENT_ERROR: "DELETE_TEACHER_ASSIGNMENT_ERROR",

  DELETE_TEACHER_EXAM_PAPER_REQUEST: "DELETE_TEACHER_EXAM_PAPER_REQUEST",
  DELETE_TEACHER_EXAM_PAPER_SUCCESS: "DELETE_TEACHER_EXAM_PAPER_SUCCESS",
  DELETE_TEACHER_EXAM_PAPER_ERROR: "DELETE_TEACHER_EXAM_PAPER_ERROR",

  REMOVE_TEACHER_ASSIGNMENT_REQUEST: "REMOVE_TEACHER_ASSIGNMENT_REQUEST",
  REMOVE_TEACHER_ASSIGNMENT_SUCCESS: "REMOVE_TEACHER_ASSIGNMENT_SUCCESS",
  REMOVE_TEACHER_ASSIGNMENT_ERROR: "REMOVE_TEACHER_ASSIGNMENT_ERROR",

  updateTeacherAssignment: (data, id) => ({
    data,
    id,
    type: teacherAssignment.UPDATE_TEACHER_ASSIGNMENT_REQUEST,
  }),
  removeTeacherAssignment: (data, teacherAssignmentId) => ({
    data,
    teacherAssignmentId,
    type: teacherAssignment.REMOVE_TEACHER_ASSIGNMENT_REQUEST,
  }),
  updateTeacherAssignmentData: (data) => ({
    type: teacherAssignment.UPDATE_TEACHER_ASSIGNMENT_DATA,
    data,
  }),
  getTeacherAssignments: () => ({
    type: teacherAssignment.GET_TEACHER_ASSIGNMENT_REQUEST,
  }),
  getMetaData: () => ({
    type: teacherAssignment.GET_META_DATA_REQUEST,
  }),
  getTeacherAssignmentDetails: (id) => ({
    type: teacherAssignment.GET_TEACHER_ASSIGNMENT_DETAILS_REQUEST,
    id,
  }),
  addTeacherAssignment: (data) => ({
    type: teacherAssignment.ADD_TEACHER_ASSIGNMENT_REQUEST,
    data,
  }),
  deleteTeacherAssignment: (id) => ({
    type: teacherAssignment.DELETE_TEACHER_ASSIGNMENT_REQUEST,
    id,
  }),
  deleteTeacherExamPaper: (id) => ({
    type: teacherAssignment.DELETE_TEACHER_EXAM_PAPER_REQUEST,
    id,
  }),
};

export default teacherAssignment;
