const learnerBilling = {
  LEARNER_BILLING_REQUEST: "LEARNER_BILLING_REQUEST",
  LEARNER_BILLING_SUCCESS: "LEARNER_BILLING_SUCCESS",
  LEARNER_BILLING_ERROR: "LEARNER_BILLING_ERROR",

  BILL_LEARNERS_REQUEST: "BILL_LEARNERS_REQUEST",
  BILL_LEARNERS_SUCCESS: "BILL_LEARNERS_SUCCESS",
  BILL_LEARNERS_ERROR: "BILL_LEARNERS_ERROR",

  DELETE_LEARNER_BILL_REQUEST: "DELETE_LEARNER_BILL_REQUEST",
  DELETE_LEARNER_BILL_SUCCESS: "DELETE_LEARNER_BILL_SUCCESS",
  DELETE_LEARNER_BILL_ERROR: "DELETE_LEARNER_BILL_ERROR",

  META_DATA_REQUEST: "META_DATA_REQUEST",
  META_DATA_SUCCESS: "META_DATA_SUCCESS",
  META_DATA_ERROR: "META_DATA_ERROR",

  SET_LEARNER_BILLING_DATA: "SET_LEARNER_BILLING_DATA",

  getLearnerBilling: (data) => ({
    type: learnerBilling.LEARNER_BILLING_REQUEST,
    data,
  }),
  billLearners: (data, reloadData) => ({
    type: learnerBilling.BILL_LEARNERS_REQUEST,
    data,
    reloadData,
  }),
  deleteLearnerBill: (id, data) => ({
    type: learnerBilling.DELETE_LEARNER_BILL_REQUEST,
    id,
    data,
  }),
  getMetaData: (data) => ({
    type: learnerBilling.META_DATA_REQUEST,
    data,
  }),
  setLearnerBillingData: (data) => ({
    type: learnerBilling.SET_LEARNER_BILLING_DATA,
    data,
  }),
};

export default learnerBilling;
