import { schoolGatePassActions } from "../../../actions";
import initialState from "../../../initialState";

const schoolGatePass = (state = initialState.schoolGatePass, action) => {
  switch (action.type) {
    case schoolGatePassActions.GET_SCHOOL_GATE_PASS_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case schoolGatePassActions.GET_SCHOOL_GATE_PASS_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: action.data,
        metaDataLoading: false,
      };
    case schoolGatePassActions.GET_SCHOOL_GATE_PASS_META_DATA_ERROR:
      return {
        ...state,
        metaDataLoading: false,
        metaDataError: action.error,
      };
    case schoolGatePassActions.GET_SCHOOL_GATE_PASS_REQUEST:
      return {
        ...state,
        schoolGatePassLoading: true,
        schoolGatePassError: {},
      };
    case schoolGatePassActions.GET_SCHOOL_GATE_PASS_SUCCESS:
      return {
        ...state,
        schoolGatePassSuccess: action.data,
        schoolGatePassLoading: false,
      };
    case schoolGatePassActions.GET_SCHOOL_GATE_PASS_ERROR:
      return {
        ...state,
        schoolGatePassLoading: false,
        schoolGatePassError: action.error,
      };
    case schoolGatePassActions.GET_SCHOOL_GATE_PASS_DETAILS_REQUEST:
      return {
        ...state,
        schoolGatePassDetailsLoading: true,
        schoolGatePassDetailsError: {},
      };
    case schoolGatePassActions.GET_SCHOOL_GATE_PASS_DETAILS_SUCCESS:
      return {
        ...state,
        schoolGatePassDetailsSuccess: action.data,
        schoolGatePassDetailsLoading: false,
      };
    case schoolGatePassActions.GET_SCHOOL_GATE_PASS_DETAILS_ERROR:
      return {
        ...state,
        schoolGatePassDetailsLoading: false,
        schoolGatePassDetailsError: action.error,
      };
    case schoolGatePassActions.ADD_SCHOOL_GATE_PASS_REQUEST:
      return {
        ...state,
        addSchoolGatePassLoading: true,
        addSchoolGatePassError: {},
      };
    case schoolGatePassActions.ADD_SCHOOL_GATE_PASS_SUCCESS:
      return {
        ...state,
        addSchoolGatePassSuccess: action.data,
        addSchoolGatePassLoading: false,
      };
    case schoolGatePassActions.ADD_SCHOOL_GATE_PASS_ERROR:
      return {
        ...state,
        addSchoolGatePassLoading: false,
        addSchoolGatePassError: action.error,
      };
    case schoolGatePassActions.UPDATE_SCHOOL_GATE_PASS_REQUEST:
      return {
        ...state,
        updateSchoolGatePassLoading: true,
        updateSchoolGatePassError: {},
      };
    case schoolGatePassActions.UPDATE_SCHOOL_GATE_PASS_SUCCESS:
      return {
        ...state,
        updateSchoolGatePassSuccess: action.data,
        updateSchoolGatePassLoading: false,
        // pass back into state of schoolGatePassSuccess and update the item the server returns the id of the updated item as action.data.id
        schoolGatePassSuccess: {
          ...state.schoolGatePassSuccess,
          schoolGatePasses: state.schoolGatePassSuccess?.schoolGatePasses?.map(
            (item) => {
              if (item.id === action.data.id) {
                const updatedItem = {
                  ...item,
                  ...action.data.data,
                };
                return updatedItem;
              } else {
                return item;
              }
            }
          ),
        },
      };
    case schoolGatePassActions.UPDATE_SCHOOL_GATE_PASS_ERROR:
      return {
        ...state,
        updateSchoolGatePassLoading: false,
        updateSchoolGatePassError: action.error,
      };
    case schoolGatePassActions.DELETE_SCHOOL_GATE_PASS_REQUEST:
      return {
        ...state,
        deleteSchoolGatePassLoading: true,
        deleteSchoolGatePassError: {},
      };
    case schoolGatePassActions.DELETE_SCHOOL_GATE_PASS_SUCCESS:
      return {
        ...state,
        deleteSchoolGatePassSuccess: action.data,
        deleteSchoolGatePassLoading: false,
        // pass back into state of schoolGatePassSuccess and remove the deleted item the server returns the id of the deleted item as action.data.id
        schoolGatePassSuccess: {
          ...state.schoolGatePassSuccess,
          schoolGatePasses:
            state.schoolGatePassSuccess?.schoolGatePasses?.filter(
              (item) => item.id !== action.data.id
            ),
        },
      };
    case schoolGatePassActions.DELETE_SCHOOL_GATE_PASS_ERROR:
      return {
        ...state,
        deleteSchoolGatePassLoading: false,
        deleteSchoolGatePassError: action.error,
      };
    case schoolGatePassActions.CHECK_SCHOOL_GATE_PASS_REQUEST:
      return {
        ...state,
        checkSchoolGatePassLoading: true,
        checkSchoolGatePassError: {},
      };
    case schoolGatePassActions.CHECK_SCHOOL_GATE_PASS_SUCCESS:
      return {
        ...state,
        checkSchoolGatePassSuccess: action.data,
        checkSchoolGatePassLoading: false,
        // pass back into state of schoolGatePassSuccess and change the status of the item the server returns the id of the item as action.data.id to "Checked"
        schoolGatePassSuccess: {
          ...state.schoolGatePassSuccess,
          schoolGatePasses: state.schoolGatePassSuccess?.schoolGatePasses?.map(
            (item) => {
              if (item.id === action.data.id) {
                const updatedItem = {
                  ...item,
                  status: "Checked",
                };
                return updatedItem;
              } else {
                return item;
              }
            }
          ),
        },
      };
    case schoolGatePassActions.CHECK_SCHOOL_GATE_PASS_ERROR:
      return {
        ...state,
        checkSchoolGatePassLoading: false,
        checkSchoolGatePassError: action.error,
      };
    case schoolGatePassActions.APPROVE_SCHOOL_GATE_PASS_REQUEST:
      return {
        ...state,
        approveSchoolGatePassLoading: true,
        approveSchoolGatePassError: {},
      };
    case schoolGatePassActions.APPROVE_SCHOOL_GATE_PASS_SUCCESS:
      return {
        ...state,
        approveSchoolGatePassSuccess: action.data,
        approveSchoolGatePassLoading: false,
        // pass back into state of schoolGatePassSuccess and change the status of the item the server returns the id of the item as action.data.id to "Approved"
        schoolGatePassSuccess: {
          ...state.schoolGatePassSuccess,
          schoolGatePasses: state.schoolGatePassSuccess?.schoolGatePasses?.map(
            (item) => {
              if (item.id === action.data.id) {
                const updatedItem = {
                  ...item,
                  status: "Approved",
                };
                return updatedItem;
              } else {
                return item;
              }
            }
          ),
        },
      };
    case schoolGatePassActions.APPROVE_SCHOOL_GATE_PASS_ERROR:
      return {
        ...state,
        approveSchoolGatePassLoading: false,
        approveSchoolGatePassError: action.error,
      };
    case schoolGatePassActions.SET_UPDATE_SCHOOL_GATE_PASS_DATA:
      return {
        ...state,
        setUpdateSchoolGatePassData: action.data,
      };
    default:
      return state;
  }
};

export default schoolGatePass;
