const userProfile = {
  GET_USER_PROFILE_REQUEST: "GET_USER_PROFILE_REQUEST",
  GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
  GET_USER_PROFILE_ERROR: "GET_USER_PROFILE_ERROR",

  UPDATE_USER_PROFILE_REQUEST: "UPDATE_USER_PROFILE_REQUEST",
  UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
  UPDATE_USER_PROFILE_ERROR: "UPDATE_USER_PROFILE_ERROR",

  UPLOAD_USER_PROFILE_AVATAR_REQUEST: "UPLOAD_USER_PROFILE_AVATAR_REQUEST",
  UPLOAD_USER_PROFILE_AVATAR_SUCCESS: "UPLOAD_USER_PROFILE_AVATAR_SUCCESS",
  UPLOAD_USER_PROFILE_AVATAR_ERROR: "UPLOAD_USER_PROFILE_AVATAR_ERROR",

  UPLOAD_USER_SIGNATURE_REQUEST: "UPLOAD_USER_SIGNATURE_REQUEST",
  UPLOAD_USER_SIGNATURE_SUCCESS: "UPLOAD_USER_SIGNATURE_SUCCESS",
  UPLOAD_USER_SIGNATURE_ERROR: "UPLOAD_USER_SIGNATURE_ERROR",

  CHANGE_USER_PASSWORD_REQUEST: "CHANGE_USER_PASSWORD_REQUEST",
  CHANGE_USER_PASSWORD_SUCCESS: "CHANGE_USER_PASSWORD_SUCCESS",
  CHANGE_USER_PASSWORD_ERROR: "CHANGE_USER_PASSWORD_ERROR",

  UPDATE_USER_EMAIL_REQUEST: "UPDATE_USER_EMAIL_REQUEST",
  UPDATE_USER_EMAIL_SUCCESS: "UPDATE_USER_EMAIL_SUCCESS",
  UPDATE_USER_EMAIL_ERROR: "UPDATE_USER_EMAIL_ERROR",
  SET_UPDATE_USER_EMAIL_DATA: "SET_UPDATE_USER_EMAIL_DATA",

  getUserProfile: () => ({
    type: userProfile.GET_USER_PROFILE_REQUEST,
  }),
  updateUserProfile: (data) => ({
    type: userProfile.UPDATE_USER_PROFILE_REQUEST,
    data,
  }),
  uploadUserProfileAvatar: (data, id) => ({
    type: userProfile.UPLOAD_USER_PROFILE_AVATAR_REQUEST,
    data,
    id,
  }),
  uploadUserSignature: (data, id) => ({
    type: userProfile.UPLOAD_USER_SIGNATURE_REQUEST,
    data,
    id,
  }),
  changeUserPassword: (data) => ({
    type: userProfile.CHANGE_USER_PASSWORD_REQUEST,
    data,
  }),
  updateUserEmail: (data) => ({
    type: userProfile.UPDATE_USER_EMAIL_REQUEST,
    data,
  }),
  setUpdateUserEmailData: (data) => ({
    type: userProfile.SET_UPDATE_USER_EMAIL_DATA,
    data,
  }), 
};

export default userProfile;
