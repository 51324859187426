const projects = {
  GET_PROJECTS_REQUEST: "GET_PROJECTS_REQUEST",
  GET_PROJECTS_SUCCESS: "GET_PROJECTS_SUCCESS",
  GET_PROJECTS_ERROR: "GET_PROJECTS_ERROR",

  GET_PROJECTS_META_DATA_REQUEST: "GET_PROJECTS_META_DATA_REQUEST",
  GET_PROJECTS_META_DATA_SUCCESS: "GET_PROJECTS_META_DATA_SUCCESS",

  SET_ADD_PROJECT_DATA: "SET_ADD_PROJECT_DATA",
  ADD_PROJECT_REQUEST: "ADD_PROJECT_REQUEST",
  ADD_PROJECT_SUCCESS: "ADD_PROJECT_SUCCESS",
  ADD_PROJECT_ERROR: "ADD_PROJECT_ERROR",

  DELETE_PROJECT_REQUEST: "DELETE_PROJECT_REQUEST",
  DELETE_PROJECT_SUCCESS: "DELETE_PROJECT_SUCCESS",
  DELETE_PROJECT_ERROR: "DELETE_PROJECT_ERROR",

  SET_UPDATE_PROJECT_UPDATE_DATA: "SET_UPDATE_PROJECT_UPDATE_DATA",
  UPDATE_PROJECT_REQUEST: "UPDATE_PROJECT_REQUEST",
  UPDATE_PROJECT_SUCCESS: "UPDATE_PROJECT_SUCCESS",
  UPDATE_PROJECT_ERROR: "UPDATE_PROJECT_ERROR",

  GET_PROJECT_DETAILS_REQUEST: "GET_PROJECT_DETAILS_REQUEST",
  GET_PROJECT_DETAILS_SUCCESS: "GET_PROJECT_DETAILS_SUCCESS",
  GET_PROJECT_DETAILS_ERROR: "GET_PROJECT_DETAILS_ERROR",

  APPROVE_PROJECT_REQUEST: "APPROVE_PROJECT_REQUEST",
  APPROVE_PROJECT_SUCCESS: "APPROVE_PROJECT_SUCCESS",
  APPROVE_PROJECT_ERROR: "APPROVE_PROJECT_ERROR",

  CHECK_PROJECT_REQUEST: "CHECK_PROJECT_REQUEST",
  CHECK_PROJECT_SUCCESS: "CHECK_PROJECT_SUCCESS",
  CHECK_PROJECT_ERROR: "CHECK_PROJECT_ERROR",

  getProjects: () => ({
    type: projects.GET_PROJECTS_REQUEST,
  }),
  getMetaData: () => ({
    type: projects.GET_PROJECTS_META_DATA_REQUEST,
  }),
  setAddProjectData: (data) => ({
    type: projects.SET_ADD_PROJECT_DATA,
    data,
  }),
  addProject: (data) => ({
    type: projects.ADD_PROJECT_REQUEST,
    data,
  }),
  deleteProject: (data) => ({
    type: projects.DELETE_PROJECT_REQUEST,
    data,
  }),
  updateProject: (data, id) => ({
    type: projects.UPDATE_PROJECT_REQUEST,
    data,
    id,
  }),
  setUpdateProjectData: (data) => ({
    type: projects.SET_UPDATE_PROJECT_UPDATE_DATA,
    data,
  }),
  getProjectDetails: (data) => ({
    type: projects.GET_PROJECT_DETAILS_REQUEST,
    data,
  }),
  checkProject: (data) => ({
    type: projects.CHECK_PROJECT_REQUEST,
    data,
  }),
  approveProject: (data) => ({
    type: projects.APPROVE_PROJECT_REQUEST,
    data,
  }),
};

export default projects;
