import { takeLatest, put, fork } from "@redux-saga/core/effects";
import { appUiActions, timetableActions } from "../../../actions";
import axios from "axios";

function* getTimetables() {
  try {
    const response = yield axios({
      url: `/academics/timetable`,
    });

    yield put({
      type: timetableActions.GET_TIMETABLES_SUCCESS,
      data: response,
    });
    // yield put(appUiActions.toggleTimetable(true));
  } catch (error) {
    yield put({
      type: timetableActions.GET_TIMETABLES_ERROR,
      error: error.data,
    });
  }
}

function* watchGetTimetables() {
  yield takeLatest(timetableActions.GET_TIMETABLES_REQUEST, getTimetables);
}

function* getMetaData() {
  try {
    const response = yield axios({
      url: `/academics/timetable/meta-data`,
    });

    yield put({
      type: timetableActions.GET_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: timetableActions.GET_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(timetableActions.GET_META_DATA_REQUEST, getMetaData);
}

function* addTimetable({ data }) {
  try {
    const response = yield axios({
      url: `/academics/timetable`,
      method: "POST",
      data: data,
    });

    yield put({
      type: timetableActions.ADD_TIMETABLE_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleTimetableDetails(true));
    yield put(appUiActions.toggleAddTimetableModal(false));
    yield put(timetableActions.getTimetables());
    yield put(timetableActions.getTimetableDetails(response.id));
  } catch (error) {
    yield put({
      type: timetableActions.ADD_TIMETABLE_ERROR,
      error: error.data,
    });
  }
}

function* watchAddTimetable() {
  yield takeLatest(timetableActions.ADD_TIMETABLE_REQUEST, addTimetable);
}

function* updateTimetable({ data, id }) {
  try {
    const response = yield axios({
      url: `/academics/timetable/${id}`,
      method: "PUT",
      data: data,
    });

    yield put({
      type: timetableActions.UPDATE_TIMETABLE_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleUpdateTimetableModal(false));
    yield put(timetableActions.getTimetables());
  } catch (error) {
    yield put({
      type: timetableActions.UPDATE_TIMETABLE_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateTimetable() {
  yield takeLatest(timetableActions.UPDATE_TIMETABLE_REQUEST, updateTimetable);
}

function* deleteTimetable({ id }) {
  try {
    const response = yield axios({
      url: `/academics/timetable/${id}`,
      method: "DELETE",
    });

    yield put({
      type: timetableActions.DELETE_TIMETABLE_SUCCESS,
      data: response,
    });
    yield put(timetableActions.getTimetables());
    yield put(appUiActions.toggleTimetableDetails(false));
  } catch (error) {
    yield put({
      type: timetableActions.DELETE_TIMETABLE_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteTimetable() {
  yield takeLatest(timetableActions.DELETE_TIMETABLE_REQUEST, deleteTimetable);
}

function* addTimetableActivity({ data }) {
  try {
    const response = yield axios({
      url: `/academics/timetable/activities`,
      method: "POST",
      data: data,
    });

    yield put({
      type: timetableActions.ADD_TIMETABLE_ACTIVITY_SUCCESS,
      data: response,
    });
    yield put(timetableActions.getTimetableDetails(data.timetable_id));
  } catch (error) {
    yield put({
      type: timetableActions.ADD_TIMETABLE_ACTIVITY_ERROR,
      error: error.data,
    });
  }
}

function* watchAddTimetableActivity() {
  yield takeLatest(
    timetableActions.ADD_TIMETABLE_ACTIVITY_REQUEST,
    addTimetableActivity
  );
}

function* updateTimetableActivity({ data, id }) {
  try {
    const response = yield axios({
      url: `/academics/timetable/activities/${id}`,
      method: "PUT",
      data: data,
    });

    yield put({
      type: timetableActions.UPDATE_TIMETABLE_ACTIVITY_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleUpdateSchoolTimeTableActivityModal(false));
    yield put(timetableActions.getTimetableDetails(data.timetable_id));
  } catch (error) {
    yield put({
      type: timetableActions.UPDATE_TIMETABLE_ACTIVITY_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateTimetableActivity() {
  yield takeLatest(
    timetableActions.UPDATE_TIMETABLE_ACTIVITY_REQUEST,
    updateTimetableActivity
  );
}

function* deleteTimetableActivity({ id, timetableId }) {
  try {
    const response = yield axios({
      url: `/academics/timetable/activities/${id}`,
      method: "DELETE",
    });

    yield put({
      type: timetableActions.DELETE_TIMETABLE_ACTIVITY_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleUpdateSchoolTimeTableActivityModal(false));
    yield put(timetableActions.getTimetableDetails(timetableId));
  } catch (error) {
    yield put({
      type: timetableActions.DELETE_TIMETABLE_ACTIVITY_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteTimetableActivity() {
  yield takeLatest(
    timetableActions.DELETE_TIMETABLE_ACTIVITY_REQUEST,
    deleteTimetableActivity
  );
}

function* getTimetableDetails({ id }) {
  try {
    const response = yield axios({
      url: `/academics/timetable/${id}`,
    });

    yield put({
      type: timetableActions.GET_TIMETABLE_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: timetableActions.GET_TIMETABLE_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetTimetableDetails() {
  yield takeLatest(
    timetableActions.GET_TIMETABLE_DETAILS_REQUEST,
    getTimetableDetails
  );
}

export default [
  fork(watchGetTimetables),
  fork(watchGetMetaData),
  fork(watchAddTimetable),
  fork(watchUpdateTimetable),
  fork(watchDeleteTimetable),
  fork(watchAddTimetableActivity),
  fork(watchUpdateTimetableActivity),
  fork(watchDeleteTimetableActivity),
  fork(watchGetTimetableDetails),
];
