import { takeLatest, put, fork } from "redux-saga/effects";
import axios from "axios";
import { moesDocumentsActions } from "../../../actions";

function* getMoesDocuments() {
  try {
    const response = yield axios({
      url: "/administration/moes-documents",
      method: "GET",
    });

    yield put({
      type: moesDocumentsActions.GET_MOES_DOCUMENTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: moesDocumentsActions.GET_MOES_DOCUMENTS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetMoesDocuments() {
  yield takeLatest(
    moesDocumentsActions.GET_MOES_DOCUMENTS_REQUEST,
    getMoesDocuments
  );
}

export default [fork(watchGetMoesDocuments)];
