import { takeLatest, put, fork } from "redux-saga/effects";
import axios from "axios";

import { appUiActions, schemeOfWorkActions } from "../../../actions";

function* getSchemeOfWork() {
  try {
    const response = yield axios({
      url: "/academics/scheme-of-work",
      method: "GET",
    });
    yield put({
      type: schemeOfWorkActions.GET_SCHEMES_OF_WORK_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: schemeOfWorkActions.GET_SCHEMES_OF_WORK_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSchemeOfWork() {
  yield takeLatest(
    schemeOfWorkActions.GET_SCHEMES_OF_WORK_REQUEST,
    getSchemeOfWork
  );
}

function* getSchemeOfWorkDetails({ id }) {
  try {
    const response = yield axios({
      url: `/academics/scheme-of-work/${id}`,
      method: "GET",
    });
    yield put({
      type: schemeOfWorkActions.GET_SCHEME_OF_WORK_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: schemeOfWorkActions.GET_SCHEME_OF_WORK_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSchemeOfWorkDetails() {
  yield takeLatest(
    schemeOfWorkActions.GET_SCHEME_OF_WORK_DETAILS_REQUEST,
    getSchemeOfWorkDetails
  );
}

function* getSchemeOfWorkMetaData() {
  try {
    const response = yield axios({
      url: "/academics/scheme-of-work/meta-data",
      method: "GET",
    });
    yield put({
      type: schemeOfWorkActions.GET_SCHEME_OF_WORK_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: schemeOfWorkActions.GET_SCHEME_OF_WORK_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSchemeOfWorkMetaData() {
  yield takeLatest(
    schemeOfWorkActions.GET_SCHEME_OF_WORK_META_DATA_REQUEST,
    getSchemeOfWorkMetaData
  );
}

function* addSchemeOfWork({ data }) {
  try {
    const response = yield axios({
      url: "/academics/scheme-of-work",
      method: "POST",
      data,
    });
    yield put({
      type: schemeOfWorkActions.ADD_SCHEME_OF_WORK_SUCCESS,
      data: response,
    });
    yield put(schemeOfWorkActions.getSchemeOfWork());
    yield put(appUiActions.toggleAddSchemeOfWork(false));

    yield put(appUiActions.toggleSchemeOfWorkDetails(true));
    yield put(schemeOfWorkActions.getSchemeOfWorkDetails(response.id));
  } catch (error) {
    yield put({
      type: schemeOfWorkActions.ADD_SCHEME_OF_WORK_ERROR,
      error: error.data,
    });
  }
}

function* watchAddSchemeOfWork() {
  yield takeLatest(
    schemeOfWorkActions.ADD_SCHEME_OF_WORK_REQUEST,
    addSchemeOfWork
  );
}

function* updateSchemeOfWork({ data, id }) {
  try {
    const response = yield axios({
      url: `/academics/scheme-of-work/${id}`,
      method: "PUT",
      data,
    });
    yield put({
      type: schemeOfWorkActions.UPDATE_SCHEME_OF_WORK_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: schemeOfWorkActions.UPDATE_SCHEME_OF_WORK_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateSchemeOfWork() {
  yield takeLatest(
    schemeOfWorkActions.UPDATE_SCHEME_OF_WORK_REQUEST,
    updateSchemeOfWork
  );
}

function* deleteSchemeOfWork({ id }) {
  try {
    const response = yield axios({
      url: `/academics/scheme-of-work/${id}`,
      method: "DELETE",
    });
    yield put({
      type: schemeOfWorkActions.DELETE_SCHEME_OF_WORK_SUCCESS,
      data: response,
    });
    yield put(schemeOfWorkActions.getSchemeOfWork());
  } catch (error) {
    yield put({
      type: schemeOfWorkActions.DELETE_SCHEME_OF_WORK_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteSchemeOfWork() {
  yield takeLatest(
    schemeOfWorkActions.DELETE_SCHEME_OF_WORK_REQUEST,
    deleteSchemeOfWork
  );
}

function* addSchemeOfWorkItem({ data }) {
  try {
    const response = yield axios({
      url: "/academics/scheme-of-work/matrix",
      method: "POST",
      data,
    });
    yield put({
      type: schemeOfWorkActions.ADD_SCHEME_OF_WORK_ITEM_SUCCESS,
      data: response,
    });
    yield put(
      schemeOfWorkActions.getSchemeOfWorkDetails(data.scheme_of_work_id)
    );
  } catch (error) {
    yield put({
      type: schemeOfWorkActions.ADD_SCHEME_OF_WORK_ITEM_ERROR,
      error: error.data,
    });
  }
}

function* watchAddSchemeOfWorkItem() {
  yield takeLatest(
    schemeOfWorkActions.ADD_SCHEME_OF_WORK_ITEM_REQUEST,
    addSchemeOfWorkItem
  );
}

function* updateSchemeOfWorkItem({ data, id }) {
  try {
    const response = yield axios({
      url: `/academics/scheme-of-work/matrix/${id}`,
      method: "PUT",
      data,
    });
    yield put({
      type: schemeOfWorkActions.UPDATE_SCHEME_OF_WORK_ITEM_SUCCESS,
      data: response,
    });
    yield put(
      schemeOfWorkActions.getSchemeOfWorkDetails(data.scheme_of_work_id)
    );
    yield put(appUiActions.toggleUpdateSchemeOfWorkItem(false));
  } catch (error) {
    yield put({
      type: schemeOfWorkActions.UPDATE_SCHEME_OF_WORK_ITEM_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateSchemeOfWorkItem() {
  yield takeLatest(
    schemeOfWorkActions.UPDATE_SCHEME_OF_WORK_ITEM_REQUEST,
    updateSchemeOfWorkItem
  );
}

function* deleteSchemeOfWorkItem({ id, schemeOfWorkId }) {
  try {
    const response = yield axios({
      url: `/academics/scheme-of-work/matrix/${id}`,
      method: "DELETE",
    });
    yield put({
      type: schemeOfWorkActions.DELETE_SCHEME_OF_WORK_ITEM_SUCCESS,
      data: response,
    });
    yield put(schemeOfWorkActions.getSchemeOfWorkDetails(schemeOfWorkId));
  } catch (error) {
    yield put({
      type: schemeOfWorkActions.DELETE_SCHEME_OF_WORK_ITEM_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteSchemeOfWorkItem() {
  yield takeLatest(
    schemeOfWorkActions.DELETE_SCHEME_OF_WORK_ITEM_REQUEST,
    deleteSchemeOfWorkItem
  );
}

function* checkSchemeOfWork({ id }) {
  try {
    const response = yield axios({
      url: `/academics/scheme-of-work/check/${id}`,
      method: "PATCH",
      data: {
        checked_at: new Date(),
      },
    });
    yield put({
      type: schemeOfWorkActions.CHECK_SCHEME_OF_WORK_SUCCESS,
      data: response,
    });
    yield put(schemeOfWorkActions.getSchemeOfWorkDetails(id));
  } catch (error) {
    yield put({
      type: schemeOfWorkActions.CHECK_SCHEME_OF_WORK_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckSchemeOfWork() {
  yield takeLatest(
    schemeOfWorkActions.CHECK_SCHEME_OF_WORK_REQUEST,
    checkSchemeOfWork
  );
}

function* approveSchemeOfWork({ id }) {
  try {
    const response = yield axios({
      url: `/academics/scheme-of-work/approve/${id}`,
      method: "PATCH",
      data: {
        approved_at: new Date(),
      },
    });
    yield put({
      type: schemeOfWorkActions.APPROVE_SCHEME_OF_WORK_SUCCESS,
      data: response,
    });
    yield put(schemeOfWorkActions.getSchemeOfWorkDetails(id));
  } catch (error) {
    yield put({
      type: schemeOfWorkActions.APPROVE_SCHEME_OF_WORK_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveSchemeOfWork() {
  yield takeLatest(
    schemeOfWorkActions.APPROVE_SCHEME_OF_WORK_REQUEST,
    approveSchemeOfWork
  );
}

export default [
  fork(watchGetSchemeOfWork),
  fork(watchGetSchemeOfWorkDetails),
  fork(watchGetSchemeOfWorkMetaData),
  fork(watchAddSchemeOfWork),
  fork(watchUpdateSchemeOfWork),
  fork(watchDeleteSchemeOfWork),
  fork(watchAddSchemeOfWorkItem),
  fork(watchUpdateSchemeOfWorkItem),
  fork(watchDeleteSchemeOfWorkItem),
  fork(watchCheckSchemeOfWork),
  fork(watchApproveSchemeOfWork),
];
