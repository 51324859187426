import assetTypesActions from "../../../actions/assetsAndInventory/assetRegister/assetTypes.actions";
import assetTypesInitialState from "../../../initialState/assetsAndInventory/assetRegister/assetTypes.initialState";

const assetTypes = (state = assetTypesInitialState, actions) => {
  switch (actions.type) {
    case assetTypesActions.GET_ASSET_TYPES_REQUEST:
      return {
        ...state,
        getAssetTypesLoading: true,
        getAssetTypesSuccess: {},
        getAssetTypesError: {},
      }
    case assetTypesActions.GET_ASSET_TYPES_SUCCESS:
      return {
        ...state,
        getAssetTypesLoading: false,
        getAssetTypesSuccess: actions.data,
        getAssetTypesError: {},
      }
    case assetTypesActions.GET_ASSET_TYPES_ERROR:
      return {
        ...state,
        getAssetTypesLoading: false,
        getAssetTypesSuccess: {},
        getAssetTypesError: actions.error,
      }
    case assetTypesActions.ADD_ASSET_TYPE_REQUEST:
      return {
        ...state,
        addAssetTypeLoading: true,
        addAssetTypeSuccess: {},
        addAssetTypeError: {},
      };
    case assetTypesActions.ADD_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        addAssetTypeLoading: false,
        addAssetTypeSuccess: actions.data,
        addAssetTypeError: {},
      };
    case assetTypesActions.ADD_ASSET_TYPE_ERROR:
      return {
        ...state,
        addAssetTypeError: actions.error,
        addAssetTypeLoading: false,
      };
    case assetTypesActions.SET_ADD_ASSET_TYPE_DATA:
      return {
        ...state,
        addAssetTypeData: actions.data,
      };
    case assetTypesActions.DELETE_ASSET_TYPE_REQUEST:
      return {
        ...state,
        deleteAssetTypeLoading: true,
        deleteAssetTypeSuccess: {},
        deleteAssetTypeError: {},
      };
    case assetTypesActions.DELETE_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        deleteAssetTypeSuccess: actions.data,
        deleteAssetTypeLoading: false,
      };
    case assetTypesActions.DELETE_ASSET_TYPE_ERROR:
      return {
        ...state,
        deleteAssetTypeError: actions.error,
        deleteAssetTypeLoading: false,
      };
    case assetTypesActions.SET_UPDATE_ASSET_TYPE_UPDATE_DATA:
      return {
        ...state,
        updateAssetTypeData: actions.data,
      };
    case assetTypesActions.UPDATE_ASSET_TYPE_REQUEST:
      return {
        ...state,
        updateAssetTypeLoading: true,
      };
    case assetTypesActions.UPDATE_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        updateAssetTypeSuccess: actions.data,
        updateAssetTypeLoading: false,
        updateAssetTypeError: {},
      };
    case assetTypesActions.UPDATE_ASSET_TYPE_ERROR:
      return {
        ...state,
        updateAssetTypeError: actions.error,
        updateAssetTypeLoading: false,
      };
    case assetTypesActions.GET_ASSET_TYPE_DETAILS_REQUEST:
      return {
        ...state,
        assetTypeDetailsLoading: true,
      };
    case assetTypesActions.GET_ASSET_TYPE_DETAILS_SUCCESS:
      return {
        ...state,
        assetTypeDetailsSuccess: actions.data,
        assetTypeDetailsError: {},
        assetTypeDetailsLoading: false,
      };
    case assetTypesActions.GET_ASSET_TYPE_DETAILS_ERROR:
      return {
        ...state,
        assetTypeDetailsLoading: false,
        assetTypeDetailsError: actions.error,
      };
    case assetTypesActions.CHECK_ASSET_TYPE_REQUEST:
      return {
        ...state,
        checkAssetTypeLoading: true,
        checkAssetTypeError: {},
        checkAssetTypeSuccess: {},
      };
    case assetTypesActions.CHECK_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        checkAssetTypeLoading: false,
        checkAssetTypeSuccess: actions.data,
      };
    case assetTypesActions.CHECK_ASSET_TYPE_ERROR:
      return {
        ...state,
        checkAssetTypeError: actions.error,
        checkAssetTypeLoading: false,
      };
    case assetTypesActions.APPROVE_ASSET_TYPE_REQUEST:
      return {
        ...state,
        approveAssetTypeLoading: true,
        approveAssetTypeError: {},
        approveAssetTypeSuccess: {},
      };
    case assetTypesActions.APPROVE_ASSET_TYPE_SUCCESS:
      return {
        ...state,
        approveAssetTypeLoading: false,
        approveAssetTypeSuccess: actions.data,
      };
    case assetTypesActions.APPROVE_ASSET_TYPE_ERROR:
      return {
        ...state,
        approveAssetTypeLoading: false,
        approveAssetTypeError: actions.error,
      };
    default:
      return state;
  }
};

export default assetTypes;
