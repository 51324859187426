import { takeLatest, put, fork, takeEvery } from "@redux-saga/core/effects";
import axios from "axios";
import { appUiActions, ledgersActions } from "../../../actions";

function* getMetaData() {
  try {
    const response = yield axios({ url: "/finance/ledgers/meta-data" });

    yield put({ type: ledgersActions.GET_META_DATA_SUCCESS, data: response });
  } catch (error) {
    yield put({ type: ledgersActions.GET_META_DATA_SUCCESS, data: {} });
  }
}

function* watchGetMetaData() {
  yield takeLatest(ledgersActions.GET_META_DATA_REQUEST, getMetaData);
}

function* getLedgers() {
  try {
    const response = yield axios({ url: "/finance/ledgers" });

    yield put({ type: ledgersActions.GET_LEDGERS_SUCCESS, data: response });
  } catch (error) {
    yield put({ type: ledgersActions.GET_LEDGERS_ERROR, error: error.data });
  }
}

function* watchGetLedgers() {
  yield takeLatest(ledgersActions.GET_LEDGERS_REQUEST, getLedgers);
}

function* addLedger({ data }) {
  try {
    const response = yield axios({
      url: `/finance/ledgers`,
      method: "POST",
      data,
    });

    yield put({ type: ledgersActions.ADD_LEDGERS_SUCCESS, data: response });
    yield put(ledgersActions.getLedgers());
  } catch (error) {
    yield put({ type: ledgersActions.ADD_LEDGERS_ERROR, error: error.data });
  }
}

function* watchAddLedger() {
  yield takeLatest(ledgersActions.ADD_LEDGERS_REQUEST, addLedger);
}

function* updateLedger({ data, id }) {
  try {
    const response = yield axios({
      url: `/finance/ledgers/${id}`,
      method: "PUT",
      data,
    });

    yield put({ type: ledgersActions.UPDATE_LEDGERS_SUCCESS, data: response });
    yield put(appUiActions.toggleUpdateLedgerModal(false));
  } catch (error) {
    yield put({ type: ledgersActions.UPDATE_LEDGERS_ERROR, error: error.data });
  }
}

function* watchUpdateLedger() {
  yield takeLatest(ledgersActions.UPDATE_LEDGERS_REQUEST, updateLedger);
}

function* deleteLedger({ id }) {
  try {
    const response = yield axios({
      url: `/finance/ledgers/${id}`,
      method: "DELETE",
    });

    yield put({ type: ledgersActions.DELETE_LEDGERS_SUCCESS, data: response });
    yield put(ledgersActions.getLedgers());
  } catch (error) {
    yield put({ type: ledgersActions.DELETE_LEDGERS_ERROR, error: error.data });
  }
}

function* watchDeleteLedger() {
  yield takeEvery(ledgersActions.DELETE_LEDGERS_REQUEST, deleteLedger);
}

function* deleteLedgerAccount({ data }) {
  try {
    const response = yield axios({
      url: `/finance/ledgers/accounts/${data.id}`,
      method: "DELETE",
      params: { ledgerId: data.ledgerId },
    });

    yield put({
      type: ledgersActions.DELETE_LEDGER_ACCOUNT_SUCCESS,
      data: response,
    });
    yield put(ledgersActions.getLedgerDetails(data.ledgerId));
  } catch (error) {
    yield put({
      type: ledgersActions.DELETE_LEDGER_ACCOUNT_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteLedgerAccount() {
  yield takeEvery(
    ledgersActions.DELETE_LEDGER_ACCOUNT_REQUEST,
    deleteLedgerAccount
  );
}

function* getLedgerDetails({ id }) {
  try {
    const response = yield axios({ url: `/finance/ledgers/${id}` });

    yield put({
      type: ledgersActions.GET_LEDGER_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: ledgersActions.GET_LEDGER_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetLedgerDetails() {
  yield takeLatest(ledgersActions.GET_LEDGER_DETAILS_REQUEST, getLedgerDetails);
}

function* getLedgerAccountDetails({ id }) {
  try {
    const response = yield axios({ url: `/finance/ledgers/accounts/${id}` });

    yield put({
      type: ledgersActions.GET_LEDGER_ACCOUNT_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: ledgersActions.GET_LEDGER_ACCOUNT_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetLedgerAccountDetails() {
  yield takeLatest(
    ledgersActions.GET_LEDGER_ACCOUNT_DETAILS_REQUEST,
    getLedgerAccountDetails
  );
}

function* checkLedger({ id }) {
  try {
    const response = yield axios({
      url: `/finance/ledgers/check/${id}`,
      data: {
        checked_at: new Date(),
      },
      method: "PATCH",
    });

    yield put({ type: ledgersActions.CHECK_LEDGER_SUCCESS, data: response });
    yield put(ledgersActions.getLedgerDetails(id));
  } catch (error) {
    yield put({ type: ledgersActions.CHECK_LEDGER_ERROR, error: error.data });
  }
}

function* watchCheckLedger() {
  yield takeLatest(ledgersActions.CHECK_LEDGER_REQUEST, checkLedger);
}

function* approveLedger({ id }) {
  try {
    const response = yield axios({
      url: `/finance/ledgers/approve/${id}`,
      data: {
        approved_at: new Date(),
      },
      method: "PATCH",
    });

    yield put({ type: ledgersActions.APPROVE_LEDGER_SUCCESS, data: response });
    yield put(ledgersActions.getLedgerDetails(id));
  } catch (error) {
    yield put({ type: ledgersActions.APPROVE_LEDGER_ERROR, error: error.data });
  }
}

function* watchApproveLedger() {
  yield takeLatest(ledgersActions.APPROVE_LEDGER_REQUEST, approveLedger);
}

export default [
  fork(watchGetMetaData),
  fork(watchGetLedgers),
  fork(watchAddLedger),
  fork(watchUpdateLedger),
  fork(watchDeleteLedger),
  fork(watchGetLedgerDetails),
  fork(watchGetLedgerAccountDetails),
  fork(watchCheckLedger),
  fork(watchApproveLedger),
  fork(watchDeleteLedgerAccount),
];
