import { staffPayRollActions } from "../../../actions";
import initialState from "../../../initialState";

const staffPayRoll = (state = initialState.staffPayRoll, action) => {
  switch (action.type) {
    case staffPayRollActions.GET_STAFF_PAYROLL_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case staffPayRollActions.GET_STAFF_PAYROLL_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: action.data,
        metaDataLoading: false,
      };
    case staffPayRollActions.GET_STAFF_PAYROLL_META_DATA_ERROR:
      return {
        ...state,
        metaDataLoading: false,
        metaDataError: action.error,
      };
    case staffPayRollActions.GET_STAFF_PAYROLL_REQUEST:
      return {
        ...state,
        staffPayRollLoading: true,
        staffPayRollError: {},
      };
    case staffPayRollActions.GET_STAFF_PAYROLL_SUCCESS:
      return {
        ...state,
        staffPayRoll: action.data,
        staffPayRollLoading: false,
      };
    case staffPayRollActions.GET_STAFF_PAYROLL_ERROR:
      return {
        ...state,
        staffPayRollLoading: false,
        staffPayRollError: action.error,
      };
    case staffPayRollActions.GET_STAFF_PAYROLL_DETAILS_REQUEST:
      return {
        ...state,
        staffPayRollDetailsLoading: true,
        staffPayRollDetailsError: {},
      };
    case staffPayRollActions.GET_STAFF_PAYROLL_DETAILS_SUCCESS:
      return {
        ...state,
        staffPayRollDetails: action.data,
        staffPayRollDetailsLoading: false,
      };
    case staffPayRollActions.GET_STAFF_PAYROLL_DETAILS_ERROR:
      return {
        ...state,
        staffPayRollDetailsLoading: false,
        staffPayRollDetailsError: action.error,
      };
    case staffPayRollActions.ADD_STAFF_PAYROLL_REQUEST:
      return {
        ...state,
        addStaffPayRollLoading: true,
        addStaffPayRollError: {},
      };
    case staffPayRollActions.ADD_STAFF_PAYROLL_SUCCESS:
      return {
        ...state,
        addStaffPayRoll: action.data,
        addStaffPayRollLoading: false,
      };
    case staffPayRollActions.ADD_STAFF_PAYROLL_ERROR:
      return {
        ...state,
        addStaffPayRollLoading: false,
        addStaffPayRollError: action.error,
      };
    case staffPayRollActions.UPDATE_STAFF_PAYROLL_REQUEST:
      return {
        ...state,
        updateStaffPayRollLoading: true,
        updateStaffPayRollError: {},
      };
    case staffPayRollActions.UPDATE_STAFF_PAYROLL_SUCCESS:
      return {
        ...state,
        updateStaffPayRoll: action.data,
        updateStaffPayRollLoading: false,
      };
    case staffPayRollActions.UPDATE_STAFF_PAYROLL_ERROR:
      return {
        ...state,
        updateStaffPayRollLoading: false,
        updateStaffPayRollError: action.error,
      };
    case staffPayRollActions.DELETE_STAFF_PAYROLL_REQUEST:
      return {
        ...state,
        deleteStaffPayRollLoading: true,
        deleteStaffPayRollError: {},
      };
    case staffPayRollActions.DELETE_STAFF_PAYROLL_SUCCESS:
      return {
        ...state,
        deleteStaffPayRoll: action.data,
        deleteStaffPayRollLoading: false,
        // pass back into state for staffPayRoll and delete the staff from the staffPayRoll
        staffPayRoll: {
          ...state.staffPayRoll,
          staffPayroll: state.staffPayRoll.staffPayroll.filter(
            (item) => item.id !== action.data.id
          ),
        },
      };
    case staffPayRollActions.DELETE_STAFF_PAYROLL_ERROR:
      return {
        ...state,
        deleteStaffPayRollLoading: false,
        deleteStaffPayRollError: action.error,
      };
    case staffPayRollActions.SET_UPDATE_STAFF_PAYROLL_DATA:
      return {
        ...state,
        setUpdateStaffPayRollData: action.data,
      };
    case staffPayRollActions.DELETE_STAFF_FROM_PAYROLL_REQUEST:
      return {
        ...state,
        deleteStaffFromPayRollLoading: true,
        deleteStaffFromPayRollError: {},
      };
    case staffPayRollActions.DELETE_STAFF_FROM_PAYROLL_SUCCESS:
      return {
        ...state,
        deleteStaffFromPayRoll: action.data,
        deleteStaffFromPayRollLoading: false,
        // pass back into state for staffPayRoll and delete the staff from the staffPayRoll
        staffPayRoll: {
          ...state.staffPayRoll,
          // first find the payroll then filter the staff from the payroll with the id
          staffPayroll: state.staffPayRoll.staffPayroll.map((item) =>
            item.id === action.data.payroll_id
              ? {
                  ...item,
                  payments: item.payments.filter(
                    (staff) => staff.id !== action.data.id
                  ),
                }
              : { ...item }
          ),
        },
      };
    case staffPayRollActions.DELETE_STAFF_FROM_PAYROLL_ERROR:
      return {
        ...state,
        deleteStaffFromPayRollLoading: false,
        deleteStaffFromPayRollError: action.error,
      };
    case staffPayRollActions.CHECK_STAFF_PAYROLL_REQUEST:
      return {
        ...state,
        checkStaffPayRollLoading: true,
        checkStaffPayRollError: {},
      };
    case staffPayRollActions.CHECK_STAFF_PAYROLL_SUCCESS:
      return {
        ...state,
        checkStaffPayRoll: action.data,
        checkStaffPayRollLoading: false,
        // pass back into state for staffPayRoll and make the status for the payroll id Checked
        staffPayRoll: {
          ...state.staffPayRoll,
          staffPayroll: state.staffPayRoll.staffPayroll.map((item) =>
            item.id === action.data.id
              ? { ...item, status: "Checked" }
              : { ...item }
          ),
        },
      };
    case staffPayRollActions.CHECK_STAFF_PAYROLL_ERROR:
      return {
        ...state,
        checkStaffPayRollLoading: false,
        checkStaffPayRollError: action.error,
      };
    case staffPayRollActions.APPROVE_STAFF_PAYROLL_REQUEST:
      return {
        ...state,
        approveStaffPayRollLoading: true,
        approveStaffPayRollError: {},
      };
    case staffPayRollActions.APPROVE_STAFF_PAYROLL_SUCCESS:
      return {
        ...state,
        approveStaffPayRoll: action.data,
        approveStaffPayRollLoading: false,
        // pass back into state for staffPayRoll and make the status for the payroll id Approved
        staffPayRoll: {
          ...state.staffPayRoll,
          staffPayroll: state.staffPayRoll.staffPayroll.map((item) =>
            item.id === action.data.id
              ? { ...item, status: "Approved" }
              : { ...item }
          ),
        },
      };
    case staffPayRollActions.APPROVE_STAFF_PAYROLL_ERROR:
      return {
        ...state,
        approveStaffPayRollLoading: false,
        approveStaffPayRollError: action.error,
      };
    default:
      return state;
  }
};

export default staffPayRoll;
