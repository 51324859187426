import { takeLatest, fork, put } from "redux-saga/effects";
import axios from "axios";
import { appUiActions, paymentVouchersActions } from "../../../actions";
const FileDownload = require("js-file-download");

function* getPaymentVouchers() {
  try {
    const response = yield axios({
      url: "/finance/payment-vouchers/",
      method: "GET",
    });

    yield put({
      type: paymentVouchersActions.GET_PAYMENT_VOUCHERS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: paymentVouchersActions.GET_PAYMENT_VOUCHERS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetPaymentVouchers() {
  yield takeLatest(
    paymentVouchersActions.GET_PAYMENT_VOUCHERS_REQUEST,
    getPaymentVouchers
  );
}

function* addPaymentVoucher({ data }) {
  try {
    const response = yield axios({
      url: "/finance/payment-vouchers/",
      method: "POST",
      data: data,
    });

    yield put({
      type: paymentVouchersActions.ADD_PAYMENT_VOUCHER_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleAddPaymentVoucherModal(false));
  } catch (error) {
    yield put({
      type: paymentVouchersActions.ADD_PAYMENT_VOUCHER_ERROR,
      error: error.data,
    });
  }
}
function* watchAddPaymentVoucher() {
  yield takeLatest(
    paymentVouchersActions.ADD_PAYMENT_VOUCHER_REQUEST,
    addPaymentVoucher
  );
}

function* getPaymentVoucherDetails({ id }) {
  try {
    const response = yield axios({
      url: `/finance/payment-vouchers/${id}`,
      method: "GET",
    });

    yield put({
      type: paymentVouchersActions.GET_PAYMENT_VOUCHER_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: paymentVouchersActions.GET_PAYMENT_VOUCHER_DETAILS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetPaymentVoucherDetails() {
  yield takeLatest(
    paymentVouchersActions.GET_PAYMENT_VOUCHER_DETAILS_REQUEST,
    getPaymentVoucherDetails
  );
}

function* updatePaymentVoucher({ id, data, paymentVouchers }) {
  try {
    const response = yield axios({
      url: `/finance/payment-vouchers/${id}`,
      method: "PUT",
      data: data,
    });

    yield put({
      type: paymentVouchersActions.UPDATE_PAYMENT_VOUCHER_SUCCESS,
      data: response,
      paymentVouchers,
    });
  } catch (error) {
    yield put({
      type: paymentVouchersActions.UPDATE_PAYMENT_VOUCHER_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdatePaymentVoucher() {
  yield takeLatest(
    paymentVouchersActions.UPDATE_PAYMENT_VOUCHER_REQUEST,
    updatePaymentVoucher
  );
}

function* deletePaymentVoucher({ id, paymentVouchers }) {
  try {
    const response = yield axios({
      url: `/finance/payment-vouchers/${id}`,
      method: "DELETE",
    });

    yield put({
      type: paymentVouchersActions.DELETE_PAYMENT_VOUCHER_SUCCESS,
      data: response,
      paymentVouchers,
    });
  } catch (error) {
    yield put({
      type: paymentVouchersActions.DELETE_PAYMENT_VOUCHER_ERROR,
      error: error.data,
    });
  }
}
function* watchDeletePaymentVoucher() {
  yield takeLatest(
    paymentVouchersActions.DELETE_PAYMENT_VOUCHER_REQUEST,
    deletePaymentVoucher
  );
}
function* getPaymentVouchersMetaData() {
  try {
    const response = yield axios({
      url: "/finance/payment-vouchers/meta-data",
      method: "GET",
    });

    yield put({
      type: paymentVouchersActions.GET_PAYMENT_VOUCHERS_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: paymentVouchersActions.GET_PAYMENT_VOUCHERS_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetPaymentVouchersMetaData() {
  yield takeLatest(
    paymentVouchersActions.GET_PAYMENT_VOUCHERS_META_DATA_REQUEST,
    getPaymentVouchersMetaData
  );
}

function* searchPaymentVouchers({ data }) {
  try {
    const response = yield axios({
      url: "/finance/payment-vouchers/search",
      method: "POST",
      data: data,
    });

    yield put({
      type: paymentVouchersActions.SEARCH_PAYMENT_VOUCHERS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: paymentVouchersActions.SEARCH_PAYMENT_VOUCHERS_ERROR,
      error: error.data,
    });
  }
}
function* watchSearchPaymentVouchers() {
  yield takeLatest(
    paymentVouchersActions.SEARCH_PAYMENT_VOUCHERS_REQUEST,
    searchPaymentVouchers
  );
}

function* checkPaymentVoucher({ id }) {
  try {
    const response = yield axios({
      url: `/finance/payment-vouchers/check/${id}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({
      type: paymentVouchersActions.CHECK_PAYMENT_VOUCHER_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: paymentVouchersActions.CHECK_PAYMENT_VOUCHER_ERROR,
      error: error.data,
    });
  }
}
function* watchCheckPaymentVoucher() {
  yield takeLatest(
    paymentVouchersActions.CHECK_PAYMENT_VOUCHER_REQUEST,
    checkPaymentVoucher
  );
}

function* approvePaymentVoucher({ id }) {
  try {
    const response = yield axios({
      url: `/finance/payment-vouchers/approve/${id}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: paymentVouchersActions.APPROVE_PAYMENT_VOUCHER_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: paymentVouchersActions.APPROVE_PAYMENT_VOUCHER_ERROR,
      error: error.data,
    });
  }
}
function* watchApprovePaymentVoucher() {
  yield takeLatest(
    paymentVouchersActions.APPROVE_PAYMENT_VOUCHER_REQUEST,
    approvePaymentVoucher
  );
}

function* sortPaymentVouchers({ data }) {
  try {
    const response = yield axios({
      url: "/finance/advance-accountability-forms/sort",
      method: "GET",
      params: data,
    });

    yield put({
      type: paymentVouchersActions.SORT_PAYMENT_VOUCHERS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: paymentVouchersActions.SORT_PAYMENT_VOUCHERS_ERROR,
      error: error.data,
    });
  }
}

function* watchSortPaymentVouchers() {
  yield takeLatest(
    paymentVouchersActions.SORT_PAYMENT_VOUCHERS_REQUEST,
    sortPaymentVouchers
  );
}

function* downloadPaymentVouchers({ data }) {
  try {
    const response = yield axios({
      url: "/finance/advance-accountability-forms/excel-report",
      method: "GET",
      params: data,
      responseType: "blob",
    });

    FileDownload(response.data, "Payment-Vouchers.xlsx");

    yield put({
      type: paymentVouchersActions.DOWNLOAD_PAYMENT_VOUCHERS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: paymentVouchersActions.DOWNLOAD_PAYMENT_VOUCHERS_ERROR,
      error: error.data,
    });
  }
}

function* watchDownloadPaymentVouchers() {
  yield takeLatest(
    paymentVouchersActions.DOWNLOAD_PAYMENT_VOUCHERS_REQUEST,
    downloadPaymentVouchers
  );
}

export default [
  fork(watchGetPaymentVouchers),
  fork(watchAddPaymentVoucher),
  fork(watchGetPaymentVoucherDetails),
  fork(watchUpdatePaymentVoucher),
  fork(watchDeletePaymentVoucher),
  fork(watchGetPaymentVouchersMetaData),
  fork(watchSearchPaymentVouchers),
  fork(watchCheckPaymentVoucher),
  fork(watchApprovePaymentVoucher),
  fork(watchSortPaymentVouchers),
  fork(watchDownloadPaymentVouchers),
];
