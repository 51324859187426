import { ucedActions } from "../../actions";
import initialState from "../../initialState";

const uced = (state = initialState.uced, actions) => {
  switch (actions.type) {
    case ucedActions.GET_DIOCESES_REQUEST:
      return {
        ...state,
        diocesesLoading: true,
      };
    case ucedActions.GET_DIOCESES_SUCCESS:
      return {
        ...state,
        diocesesLoading: false,
        diocesesSuccess: actions.data,
        diocesesError: {},
      };
    case ucedActions.GET_DIOCESES_ERROR:
      return {
        ...state,
        diocesesLoading: false,
        diocesesError: actions.error,
      };
    case ucedActions.GET_RELIGIONS_REQUEST:
      return {
        ...state,
        religionsLoading: true,
      };
    case ucedActions.GET_RELIGIONS_SUCCESS:
      return {
        ...state,
        religionsLoading: false,
        religionsSuccess: actions.data,
        religionsError: {},
      };
    case ucedActions.GET_RELIGIONS_ERROR:
      return {
        ...state,
        religionsLoading: false,
        religionsError: actions.error,
      };
    default:
      return state;
  }
};

export default uced;
