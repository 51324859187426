import { performanceAnalysisActions } from "../../actions";

const { default: initialState } = require("../../initialState");

const performanceAnalysis = (
  state = initialState.performanceAnalysis,
  actions
) => {
  switch (actions.type) {
    case performanceAnalysisActions.GET_CLASS_PERFORMANCE_ANALYSIS_REQUEST:
      return {
        ...state,
        classPerformanceAnalysisLoading: true,
        classPerformanceAnalysisError: {},
      };
    case performanceAnalysisActions.GET_CLASS_PERFORMANCE_ANALYSIS_SUCCESS:
      return {
        ...state,
        classPerformanceAnalysisLoading: false,
        classPerformanceAnalysis: actions.data,
      };
    case performanceAnalysisActions.GET_CLASS_PERFORMANCE_ANALYSIS_ERROR:
      return {
        ...state,
        classPerformanceAnalysisLoading: false,
        classPerformanceAnalysisError: actions.data,
      };
    case performanceAnalysisActions.GET_SUBJECT_PERFORMANCE_ANALYSIS_REQUEST:
      return {
        ...state,
        subjectPerformanceAnalysisLoading: true,
        subjectPerformanceAnalysisError: {},
      };
    case performanceAnalysisActions.GET_SUBJECT_PERFORMANCE_ANALYSIS_SUCCESS:
      return {
        ...state,
        subjectPerformanceAnalysisLoading: false,
        subjectPerformanceAnalysis: actions.data,
      };
    case performanceAnalysisActions.GET_SUBJECT_PERFORMANCE_ANALYSIS_ERROR:
      return {
        ...state,
        subjectPerformanceAnalysisLoading: false,
        subjectPerformanceAnalysisError: actions.data,
      };
    case performanceAnalysisActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
        metaDataError: {},
      };
    case performanceAnalysisActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataLoading: false,
        metaData: actions.data,
      };
    case performanceAnalysisActions.GET_META_DATA_ERROR:
      return {
        ...state,
        metaDataLoading: false,
        metaDataError: actions.data,
      };

    default:
      return state;
  }
};

export default performanceAnalysis;
