const timetable = {
  GET_TIMETABLES_REQUEST: "GET_TIMETABLES_REQUEST",
  GET_TIMETABLES_SUCCESS: "GET_TIMETABLES_SUCCESS",
  GET_TIMETABLES_ERROR: "GET_TIMETABLES_ERROR",

  ADD_TIMETABLE_REQUEST: "ADD_TIMETABLE_REQUEST",
  ADD_TIMETABLE_SUCCESS: "ADD_TIMETABLE_SUCCESS",
  ADD_TIMETABLE_ERROR: "ADD_TIMETABLE_ERROR",

  UPDATE_TIMETABLE_REQUEST: "UPDATE_TIMETABLE_REQUEST",
  UPDATE_TIMETABLE_SUCCESS: "UPDATE_TIMETABLE_SUCCESS",
  UPDATE_TIMETABLE_ERROR: "UPDATE_TIMETABLE_ERROR",
  UPDATE_TIMETABLE_DATA: "UPDATE_TIMETABLE_DATA",

  DELETE_TIMETABLE_REQUEST: "DELETE_TIMETABLE_REQUEST",
  DELETE_TIMETABLE_SUCCESS: "DELETE_TIMETABLE_SUCCESS",
  DELETE_TIMETABLE_ERROR: "DELETE_TIMETABLE_ERROR",

  GET_TIMETABLE_DETAILS_REQUEST: "GET_TIMETABLE_DETAILS_REQUEST",
  GET_TIMETABLE_DETAILS_SUCCESS: "GET_TIMETABLE_DETAILS_SUCCESS",
  GET_TIMETABLE_DETAILS_ERROR: "GET_TIMETABLE_DETAILS_ERROR",

  ADD_TIMETABLE_ACTIVITY_REQUEST: "ADD_TIMETABLE_ACTIVITY_REQUEST",
  ADD_TIMETABLE_ACTIVITY_SUCCESS: "ADD_TIMETABLE_ACTIVITY_SUCCESS",
  ADD_TIMETABLE_ACTIVITY_ERROR: "ADD_TIMETABLE_ACTIVITY_ERROR",

  UPDATE_TIMETABLE_ACTIVITY_REQUEST: "UPDATE_TIMETABLE_ACTIVITY_REQUEST",
  UPDATE_TIMETABLE_ACTIVITY_SUCCESS: "UPDATE_TIMETABLE_ACTIVITY_SUCCESS",
  UPDATE_TIMETABLE_ACTIVITY_ERROR: "UPDATE_TIMETABLE_ACTIVITY_ERROR",
  UPDATE_TIMETABLE_ACTIVITY_DATA: "UPDATE_TIMETABLE_ACTIVITY_DATA",

  GET_META_DATA_REQUEST: "GET_TIMETABLE_META_DATA_REQUEST",
  GET_META_DATA_SUCCESS: "GET_TIMETABLE_META_DATA_SUCCESS",
  GET_META_DATA_ERROR: "GET_TIMETABLE_META_DATA_ERROR",

  DELETE_TIMETABLE_ACTIVITY_REQUEST: "DELETE_TIMETABLE_ACTIVITY_REQUEST",
  DELETE_TIMETABLE_ACTIVITY_SUCCESS: "DELETE_TIMETABLE_ACTIVITY_SUCCESS",
  DELETE_TIMETABLE_ACTIVITY_ERROR: "DELETE_TIMETABLE_ACTIVITY_ERROR",

  getMetaData: () => ({
    type: timetable.GET_META_DATA_REQUEST,
  }),
  getTimetables: () => ({
    type: timetable.GET_TIMETABLES_REQUEST,
  }),
  addTimetable: (data) => ({
    type: timetable.ADD_TIMETABLE_REQUEST,
    data,
  }),
  updateTimetable: (id, data) => ({
    type: timetable.UPDATE_TIMETABLE_REQUEST,
    id,
    data,
  }),
  setUpdateTimetableData: (data) => ({
    type: timetable.UPDATE_TIMETABLE_DATA,
    data,
  }),
  deleteTimetable: (id) => ({
    type: timetable.DELETE_TIMETABLE_REQUEST,
    id,
  }),
  getTimetableDetails: (id) => ({
    type: timetable.GET_TIMETABLE_DETAILS_REQUEST,
    id,
  }),
  addTimetableActivity: (data) => ({
    type: timetable.ADD_TIMETABLE_ACTIVITY_REQUEST,
    data,
  }),
  updateTimetableActivity: (id, data) => ({
    type: timetable.UPDATE_TIMETABLE_ACTIVITY_REQUEST,
    id,
    data,
  }),
  setUpdateTimetableActivityData: (data) => ({
    type: timetable.UPDATE_TIMETABLE_ACTIVITY_DATA,
    data,
  }),
  deleteTimetableActivity: (id, timetableId) => ({
    type: timetable.DELETE_TIMETABLE_ACTIVITY_REQUEST,
    id,
    timetableId,
  }),
};

export default timetable;
