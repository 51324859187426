const storesInitialState = {
  storeItemsLoading: false,
  storeItemsSuccess: {},
  storeItemsError: {},

  addStoreItemLoading: false,
  addStoreItemSuccess: {},
  addStoreItemError: {},

  getStoreItemDetailsLoading: false,
  getStoreItemDetailsSuccess: {},
  getStoreItemDetailsError: {},

  deleteStoreItemLoading: false,
  deleteStoreItemSuccess: {},
  deleteStoreItemError: {},

  updateStoreItemDetailsLoading: false,
  updateStoreItemDetailsSuccess: {},
  updateStoreItemDetailsError: {},
  updateStoreItemDetailsData: {},

  checkStoreItemLoading: false,
  checkStoreItemSuccess: {},
  checkStoreItemError: {},

  approveStoreItemLoading: false,
  approveStoreItemSuccess: {},
  approveStoreItemError: {},

  getStoreItemsMetaDataLoading: false,
  getStoreItemsMetaDataSuccess: {},
  getStoreItemsMetaDataError: {},

  storeIssuesLoading: false,
  storeIssuesSuccess: {},
  storeIssuesError: {},

  addStoreIssueLoading: false,
  addStoreIssueSuccess: {},
  addStoreIssueError: {},
  addStoreIssueData: {},

  getStoreIssueDetailsLoading: false,
  getStoreIssueDetailsSuccess: {},
  getStoreIssueDetailsError: {},

  deleteStoreIssueLoading: false,
  deleteStoreIssueSuccess: {},
  deleteStoreIssueError: {},

  updateStoreIssueDetailsLoading: false,
  updateStoreIssueDetailsSuccess: {},
  updateStoreIssueDetailsError: {},

  checkStoreIssueLoading: false,
  checkStoreIssueSuccess: {},
  checkStoreIssueError: {},

  approveStoreIssueLoading: false,
  approveStoreIssueSuccess: {},
  approveStoreIssueError: {},

  getStoreIssuesMetaDataLoading: false,
  getStoreIssuesMetaDataSuccess: {},
  getStoreIssuesMetaDataError: {},
};

export default storesInitialState;
