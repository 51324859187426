import React from "react";
import { ConfigProvider, theme } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import configureStore from "./config/store";
import rootSaga from "./config/sagas";
import httpService from "./config/services/http.service";

const store = configureStore();

store.runSaga(rootSaga);

httpService.setUpInterceptors(store);
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#1890ff",
          colorWarning: "#faad14",
          algorithm: theme.darkAlgorithm,
          fontFamily: "Roboto",
        },
      }}
    >
      <App />
    </ConfigProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
