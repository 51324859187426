import { requisitionMemoActions } from "../../../actions";
import initialState from "../../../initialState";

const requisitionMemos = (state = initialState.requisitionMemos, actions) => {
  switch (actions.type) {
    case requisitionMemoActions.GET_REQUISITION_MEMOS_REQUEST:
      return {
        ...state,
        requisitionMemosLoading: true,
      };
    case requisitionMemoActions.GET_REQUISITION_MEMOS_SUCCESS:
      return {
        ...state,
        requisitionMemosSuccess: actions.data,
        requisitionMemosLoading: false,
      };
    case requisitionMemoActions.GET_REQUISITION_MEMOS_ERROR:
      return {
        ...state,
        requisitionMemosError: actions.error,
        requisitionMemosLoading: false,
      };

    case requisitionMemoActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case requisitionMemoActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: actions.data,
        metaDataLoading: false,
      };

    case requisitionMemoActions.ADD_REQUISITION_MEMO_REQUEST:
      return {
        ...state,
        addRequisitionMemoLoading: true,
        addRequisitionMemoError: {},
      };
    case requisitionMemoActions.ADD_REQUISITION_MEMO_SUCCESS:
      return {
        ...state,
        addRequisitionMemoSuccess: actions.data,
        addRequisitionMemoLoading: false,
      };
    case requisitionMemoActions.ADD_REQUISITION_MEMO_ERROR:
      return {
        ...state,
        addRequisitionMemoError: actions.error,
        addRequisitionMemoLoading: false,
      };

    case requisitionMemoActions.SEARCH_REQUISITION_MEMOS_REQUEST:
      return {
        ...state,
        searchRequisitionMemoLoading: true,
      };
    case requisitionMemoActions.SEARCH_REQUISITION_MEMOS_SUCCESS:
      return {
        ...state,
        searchRequisitionMemoSuccess: actions.data,
        searchRequisitionMemoLoading: false,
      };
    case requisitionMemoActions.SEARCH_REQUISITION_MEMOS_ERROR:
      return {
        ...state,
        searchRequisitionMemoError: actions.error,
        searchRequisitionMemoLoading: false,
      };
    case requisitionMemoActions.DELETE_REQUISITION_MEMO_REQUEST:
      return {
        ...state,
        deleteRequisitionMemoLoading: true,
      };
    case requisitionMemoActions.DELETE_REQUISITION_MEMO_SUCCESS:
      return {
        ...state,
        deleteRequisitionMemoSuccess: actions.data,
        deleteRequisitionMemoLoading: false,
        requisitionMemosSuccess: {
          requisitionMemos: actions.requisitionMemos,
        },
      };
    case requisitionMemoActions.DELETE_REQUISITION_MEMO_ERROR:
      return {
        ...state,
        deleteRequisitionMemoError: actions.error,
        deleteRequisitionMemoLoading: false,
      };
    case requisitionMemoActions.SET_UPDATE_REQUISITION_MEMO_DATA:
      return {
        ...state,
        updateRequisitionMemoData: actions.data,
      };
    case requisitionMemoActions.UPDATE_REQUISITION_MEMO_REQUEST:
      return {
        ...state,
        updateRequisitionMemoLoading: true,
        updateRequisitionMemoError: {},
      };
    case requisitionMemoActions.UPDATE_REQUISITION_MEMO_SUCCESS:
      return {
        ...state,
        updateRequisitionMemoSuccess: actions.data,
        updateRequisitionMemoLoading: false,
      };
    case requisitionMemoActions.UPDATE_REQUISITION_MEMO_ERROR:
      return {
        ...state,
        updateRequisitionMemoError: actions.error,
        updateRequisitionMemoLoading: false,
      };
    case requisitionMemoActions.GET_REQUISITION_MEMO_DETAILS_REQUEST:
      return {
        ...state,
        requisitionMemoDetailsLoading: true,
        requisitionMemoDetailsError: {},
      };
    case requisitionMemoActions.GET_REQUISITION_MEMO_DETAILS_SUCCESS:
      return {
        ...state,
        requisitionMemoDetailsSuccess: actions.data,
        requisitionMemoDetailsLoading: false,
      };
    case requisitionMemoActions.GET_REQUISITION_MEMO_DETAILS_ERROR:
      return {
        ...state,
        requisitionMemoDetailsError: actions.error,
        requisitionMemoDetailsLoading: false,
      };
    case requisitionMemoActions.ADD_REQUISITION_MEMO_COST_REQUEST:
      return {
        ...state,
        addRequisitionMemoCostLoading: true,
        addRequisitionMemoCostError: {},
      };
    case requisitionMemoActions.ADD_REQUISITION_MEMO_COST_SUCCESS:
      return {
        ...state,
        addRequisitionMemoCostSuccess: actions.data,
        addRequisitionMemoCostLoading: false,
      };
    case requisitionMemoActions.ADD_REQUISITION_MEMO_COST_ERROR:
      return {
        ...state,
        addRequisitionMemoCostError: actions.error,
        addRequisitionMemoCostLoading: false,
      };
    case requisitionMemoActions.SET_UPDATE_REQUISITION_MEMO_COST_DATA:
      return {
        ...state,
        updateRequisitionMemoCostData: actions.data,
      };
    case requisitionMemoActions.UPDATE_REQUISITION_MEMO_COST_REQUEST:
      return {
        ...state,
        updateRequisitionMemoCostLoading: true,
        updateRequisitionMemoCostError: {},
      };
    case requisitionMemoActions.UPDATE_REQUISITION_MEMO_COST_SUCCESS:
      return {
        ...state,
        updateRequisitionMemoCostSuccess: actions.data,
        updateRequisitionMemoCostLoading: false,
      };
    case requisitionMemoActions.UPDATE_REQUISITION_MEMO_COST_ERROR:
      return {
        ...state,
        updateRequisitionMemoCostError: actions.error,
        updateRequisitionMemoCostLoading: false,
      };
    case requisitionMemoActions.DELETE_REQUISITION_MEMO_COST_REQUEST:
      return {
        ...state,
        deleteRequisitionMemoCostLoading: true,
        deleteRequisitionMemoCostError: {},
      };
    case requisitionMemoActions.DELETE_REQUISITION_MEMO_COST_SUCCESS:
      return {
        ...state,
        deleteRequisitionMemoCostSuccess: actions.data,
        deleteRequisitionMemoCostLoading: false,
      };
    case requisitionMemoActions.DELETE_REQUISITION_MEMO_COST_ERROR:
      return {
        ...state,
        deleteRequisitionMemoCostError: actions.error,
        deleteRequisitionMemoCostLoading: false,
      };
    case requisitionMemoActions.GET_REQUISITION_MEMO_COST_DETAILS_REQUEST:
      return {
        ...state,
        requisitionMemoCostDetailsLoading: true,
        requisitionMemoCostDetailsError: {},
      };
    case requisitionMemoActions.GET_REQUISITION_MEMO_COST_DETAILS_SUCCESS:
      return {
        ...state,
        requisitionMemoCostDetailsSuccess: actions.data,
        requisitionMemoCostDetailsLoading: false,
      };
    case requisitionMemoActions.GET_REQUISITION_MEMO_COST_DETAILS_ERROR:
      return {
        ...state,
        requisitionMemoCostDetailsLoading: false,
        requisitionMemoCostDetailsError: actions.error,
      };
    case requisitionMemoActions.SUBMIT_REQUISITION_MEMO_REQUEST:
      return {
        ...state,
        submitRequisitionMemoLoading: true,
        submitRequisitionMemoError: {},
      };
    case requisitionMemoActions.SUBMIT_REQUISITION_MEMO_SUCCESS:
      return {
        ...state,
        submitRequisitionMemoSuccess: actions.data,
        submitRequisitionMemoLoading: false,
      };
    case requisitionMemoActions.SUBMIT_REQUISITION_MEMO_ERROR:
      return {
        ...state,
        submitRequisitionMemoError: actions.error,
        submitRequisitionMemoLoading: false,
      };
    case requisitionMemoActions.CHECK_REQUISITION_MEMO_REQUEST:
      return {
        ...state,
        checkRequisitionMemoLoading: true,
        checkRequisitionMemoError: {},
      };
    case requisitionMemoActions.CHECK_REQUISITION_MEMO_SUCCESS:
      return {
        ...state,
        checkRequisitionMemoSuccess: actions.data,
        checkRequisitionMemoLoading: false,
      };
    case requisitionMemoActions.CHECK_REQUISITION_MEMO_ERROR:
      return {
        ...state,
        checkRequisitionMemoError: actions.error,
        checkRequisitionMemoLoading: false,
      };
    case requisitionMemoActions.APPROVE_REQUISITION_MEMO_REQUEST:
      return {
        ...state,
        approveRequisitionMemoLoading: true,
        approveRequisitionMemoError: {},
      };
    case requisitionMemoActions.APPROVE_REQUISITION_MEMO_SUCCESS:
      return {
        ...state,
        approveRequisitionMemoSuccess: actions.data,
        approveRequisitionMemoLoading: false,
      };
    case requisitionMemoActions.APPROVE_REQUISITION_MEMO_ERROR:
      return {
        ...state,
        approveRequisitionMemoLoading: false,
        approveRequisitionMemoError: actions.error,
      };
    case requisitionMemoActions.SORT_REQUISITION_MEMOS_REQUEST:
      return {
        ...state,
        sortRequisitionMemosLoading: true,
        sortRequisitionMemosError: {},
      };
    case requisitionMemoActions.SORT_REQUISITION_MEMOS_SUCCESS:
      return {
        ...state,
        sortRequisitionMemosSuccess: actions.data,
        sortRequisitionMemosLoading: false,
      };
    case requisitionMemoActions.SORT_REQUISITION_MEMOS_ERROR:
      return {
        ...state,
        sortRequisitionMemosError: actions.error,
        sortRequisitionMemosLoading: false,
      };
    case requisitionMemoActions.DOWNLOAD_REQUISITION_MEMOS_REQUEST:
      return {
        ...state,
        downloadRequisitionMemosLoading: true,
        downloadRequisitionMemosError: {},
        downloadRequisitionMemosSuccess: {},
      };
    case requisitionMemoActions.DOWNLOAD_REQUISITION_MEMOS_SUCCESS:
      return {
        ...state,
        downloadRequisitionMemosSuccess: actions.data,
        downloadRequisitionMemosLoading: false,
      };
    case requisitionMemoActions.DOWNLOAD_REQUISITION_MEMOS_ERROR:
      return {
        ...state,
        downloadRequisitionMemosError: actions.error,
        downloadRequisitionMemosLoading: false,
      };
    case requisitionMemoActions.SET_DOWNLOAD_QUERRY:
      return {
        ...state,
        downloadQuerry: actions.data,
      };
    default:
      return state;
  }
};

export default requisitionMemos;
