import { budgetActions } from "../../../actions";
import initialState from "../../../initialState";

const budgets = (state = initialState.budgets, actions) => {
  switch (actions.type) {
    case budgetActions.GET_BUDGETS_REQUEST:
      return {
        ...state,
        budgetsLoading: true,
        budgetsError: {},
      };
    case budgetActions.GET_BUDGETS_SUCCESS:
      return {
        ...state,
        budgetsSuccess: actions.data,
        budgetsLoading: false,
      };
    case budgetActions.GET_BUDGETS_ERROR:
      return {
        ...state,
        budgetsError: actions.error,
        budgetsLoading: false,
      };
    case budgetActions.COPY_BUDGET_REQUEST:
      return {
        ...state,
        copyBudgetLoading: true,
        copyBudgetError: {},
      };
    case budgetActions.COPY_BUDGET_SUCCESS:
      return {
        ...state,
        copyBudgetSuccess: actions.data,
        copyBudgetLoading: false,
      };
    case budgetActions.COPY_BUDGET_ERROR:
      return {
        ...state,
        copyBudgetError: actions.error,
        copyBudgetLoading: false,
      };
    case budgetActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case budgetActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataLoading: false,
        metaData: actions.data,
      };
    case budgetActions.ADD_BUDGET_REQUEST:
      return {
        ...state,
        addBudgetsLoading: true,
        addBudgetsError: {},
      };
    case budgetActions.ADD_BUDGET_SUCCESS:
      return {
        ...state,
        addBudgetsSuccess: actions.data,
        addBudgetsLoading: false,
      };
    case budgetActions.ADD_BUDGET_ERROR:
      return {
        ...state,
        addBudgetsError: actions.error,
        addBudgetsLoading: false,
      };
    case budgetActions.DELETE_BUDGET_REQUEST:
      return {
        ...state,
        deleteBudgetsLoading: true,
        deleteBudgetsError: {},
      };
    case budgetActions.DELETE_BUDGET_SUCCESS:
      return {
        ...state,
        deleteBudgetsSuccess: actions.data,
        deleteBudgetsLoading: false,
      };
    case budgetActions.DELETE_BUDGET_ERROR:
      return {
        ...state,
        deleteBudgetsLoading: false,
        deleteBudgetsError: actions.error,
      };
    case budgetActions.UPDATE_BUDGET_REQUEST:
      return {
        ...state,
        updateBudgetLoading: true,
        updateBudgetError: {},
      };
    case budgetActions.SET_UPDATE_BUDGET_DATA:
      return {
        ...state,
        updateBudgetData: actions.data,
      };
    case budgetActions.UPDATE_BUDGET_SUCCESS:
      return {
        ...state,
        updateBudgetLoading: false,
        updateBudgetSuccess: actions.data,
      };
    case budgetActions.UPDATE_BUDGET_ERROR:
      return {
        ...state,
        updateBudgetLoading: false,
        updateBudgetError: actions.error,
      };
    case budgetActions.GET_BUDGET_DETAILS_REQUEST:
      return {
        ...state,
        budgetDetailsLoading: true,
        budgetDetailsError: {},
      };
    case budgetActions.GET_BUDGET_DETAILS_SUCCESS:
      return {
        ...state,
        budgetDetailsSuccess: actions.data,
        budgetDetailsLoading: false,
      };
    case budgetActions.GET_BUDGET_DETAILS_ERROR:
      return {
        ...state,
        budgetDetailsLoading: false,
        budgetDetailsError: actions.error,
      };
    case budgetActions.GET_BUDGET_STATEMENTS_REQUEST:
      return {
        ...state,
        budgetStatementsLoading: true,
        budgetStatementsError: {},
      };
    case budgetActions.GET_BUDGET_STATEMENTS_SUCCESS:
      return {
        ...state,
        budgetStatementsSuccess: actions.data,
        budgetStatementsLoading: false,
      };
    case budgetActions.GET_BUDGET_STATEMENTS_ERROR:
      return {
        ...state,
        budgetStatementsLoading: false,
        budgetStatementsError: actions.error,
      };
    case budgetActions.SET_ADD_BUDGET_LINE_DATA:
      return {
        ...state,
        addBudgetLineData: actions.data,
      };
    case budgetActions.ADD_BUDGET_LINE_REQUEST:
      return {
        ...state,
        addBudgetLineLoading: true,
        addBudgetLineError: {},
      };
    case budgetActions.ADD_BUDGET_LINE_SUCCESS:
      return {
        ...state,
        addBudgetLineSuccess: actions.data,
        addBudgetLineLoading: false,
      };
    case budgetActions.ADD_BUDGET_LINE_ERROR:
      return {
        ...state,
        addBudgetLineError: actions.error,
        addBudgetLineLoading: false,
      };
    case budgetActions.DELETE_BUDGET_LINE_REQUEST:
      return {
        ...state,
        deleteBudgetLineLoading: true,
        deleteBudgetLineError: {},
      };
    case budgetActions.DELETE_BUDGET_LINE_SUCCESS:
      return {
        ...state,
        deleteBudgetLineSuccess: actions.data,
        deleteBudgetLineLoading: false,
        budgetEstimateSuccess: {
          budget: actions.budget,
        },
      };
    case budgetActions.DELETE_BUDGET_LINE_ERROR:
      return {
        ...state,
        deleteBudgetLineError: actions.error,
        deleteBudgetLineLoading: false,
      };
    case budgetActions.SET_UPDATE_BUDGET_LINE_DATA:
      return {
        ...state,
        updateBudgetLineData: actions.data,
      };
    case budgetActions.UPDATE_BUDGET_LINE_REQUEST:
      return {
        ...state,
        updateBudgetLineLoading: true,
        updateBudgetLineError: {},
      };
    case budgetActions.UPDATE_BUDGET_LINE_SUCCESS:
      return {
        ...state,
        updateBudgetLineSuccess: actions.data,
        updateBudgetLineLoading: false,
      };
    case budgetActions.UPDATE_BUDGET_LINE_ERROR:
      return {
        ...state,
        updateBudgetLineLoading: false,
        updateBudgetLineError: actions.error,
      };
    case budgetActions.GET_BUDGET_LINE_DETAILS_REQUEST:
      return {
        ...state,
        budgetLineDetailsLoading: true,
        budgetLineDetailsError: {},
      };
    case budgetActions.GET_BUDGET_LINE_DETAILS_SUCCESS:
      return {
        ...state,
        budgetLineDetailsSuccess: actions.data,
        budgetLineDetailsLoading: false,
      };
    case budgetActions.GET_BUDGET_LINE_DETAILS_ERROR:
      return {
        ...state,
        budgetLineDetailsLoading: false,
        budgetLineDetailsError: actions.error,
      };
    case budgetActions.ADD_BUDGET_STREAM_REQUEST:
      return {
        ...state,
        addBudgetStreamsLoading: true,
        addBudgetStreamsError: {},
      };
    case budgetActions.ADD_BUDGET_STREAM_SUCCESS:
      return {
        ...state,
        addBudgetStreamsSuccess: actions.data,
        addBudgetStreamsLoading: false,
      };
    case budgetActions.ADD_BUDGET_STREAM_ERROR:
      return {
        ...state,
        addBudgetStreamsLoading: false,
        addBudgetStreamsError: actions.error,
      };
    case budgetActions.DELETE_BUDGET_STREAM_REQUEST:
      return {
        ...state,
        deleteBudgetStreamLoading: true,
        deleteBudgetStreamError: {},
      };
    case budgetActions.DELETE_BUDGET_STREAM_SUCCESS:
      return {
        ...state,
        deleteBudgetStreamSuccess: actions.data,
        deleteBudgetStreamLoading: false,
      };
    case budgetActions.DELETE_BUDGET_STREAM_ERROR:
      return {
        ...state,
        deleteBudgetStreamError: actions.error,
        deleteBudgetStreamLoading: false,
      };
    case budgetActions.SET_UPDATE_BUDGET_STREAM_DATA:
      return {
        ...state,
        updateBudgetStreamData: actions.data,
      };
    case budgetActions.UPDATE_BUDGET_STREAM_REQUEST:
      return {
        ...state,
        updateBudgetStreamLoading: true,
        updateBudgetStreamError: {},
      };
    case budgetActions.UPDATE_BUDGET_STREAM_SUCCESS:
      return {
        ...state,
        updateBudgetStreamLoading: false,
        updateBudgetStreamSuccess: actions.data,
      };
    case budgetActions.UPDATE_BUDGET_STREAM_ERROR:
      return {
        ...state,
        updateBudgetStreamError: actions.error,
        updateBudgetStreamLoading: false,
      };
    case budgetActions.GET_BUDGET_STREAM_DETAILS_REQUEST:
      return {
        ...state,
        budgetStreamDetailsLoading: true,
        budgetStreamDetailsError: {},
      };
    case budgetActions.GET_BUDGET_STREAM_DETAILS_SUCCESS:
      return {
        ...state,
        budgetStreamDetailsSuccess: actions.data,
        budgetStreamDetailsLoading: false,
      };
    case budgetActions.GET_BUDGET_STREAM_DETAILS_ERROR:
      return {
        ...state,
        budgetStreamDetailsLoading: false,
        budgetStreamDetailsError: actions.error,
      };
    case budgetActions.SUBMIT_BUDGET_REQUEST:
      return {
        ...state,
        submitBudgetLoading: true,
        submitBudgetError: {},
      };
    case budgetActions.SUBMIT_BUDGET_SUCCESS:
      return {
        ...state,
        submitBudgetSuccess: actions.data,
        submitBudgetLoading: false,
      };
    case budgetActions.SUBMIT_BUDGET_ERROR:
      return {
        ...state,
        submitBudgetLoading: false,
        submitBudgetError: actions.error,
      };
    case budgetActions.CHECK_BUDGET_REQUEST:
      return {
        ...state,
        checkBudgetLoading: true,
        checkBudgetError: {},
      };
    case budgetActions.CHECK_BUDGET_SUCCESS:
      return {
        ...state,
        checkBudgetSuccess: actions.data,
        checkBudgetLoading: false,
      };
    case budgetActions.CHECK_BUDGET_ERROR:
      return {
        ...state,
        checkBudgetError: actions.error,
        checkBudgetLoading: false,
      };
    case budgetActions.APPROVE_BUDGET_REQUEST:
      return {
        ...state,
        approveBudgetLoading: true,
        approveBudgetError: {},
      };
    case budgetActions.APPROVE_BUDGET_SUCCESS:
      return {
        ...state,
        approveBudgetSuccess: actions.data,
        approveBudgetLoading: false,
      };
    case budgetActions.APPROVE_BUDGET_ERROR:
      return {
        ...state,
        approveBudgetLoading: false,
        approveBudgetError: actions.error,
      };
    default:
      return state;
  }
};

export default budgets;
