const budgets = {
  GET_BUDGETS_REQUEST: "GET_BUDGETS_REQUEST",
  GET_BUDGETS_SUCCESS: "GET_BUDGETS_SUCCESS",
  GET_BUDGETS_ERROR: "GET_BUDGETS_ERROR",

  GET_META_DATA_REQUEST: "GET_BUDGETS_META_DATA_REQUEST",
  GET_META_DATA_SUCCESS: "GET_BUDGETS_META_DATA_SUCCESS",

  ADD_BUDGET_REQUEST: "ADD_BUDGET_REQUEST",
  ADD_BUDGET_SUCCESS: "ADD_BUDGET_SUCCESS",
  ADD_BUDGET_ERROR: "ADD_BUDGET_ERROR",

  DELETE_BUDGET_REQUEST: "DELETE_BUDGET_REQUEST",
  DELETE_BUDGET_SUCCESS: "DELETE_BUDGET_SUCCESS",
  DELETE_BUDGET_ERROR: "DELETE_BUDGET_ERROR",

  UPDATE_BUDGET_REQUEST: "UPDATE_BUDGET_REQUEST",
  UPDATE_BUDGET_SUCCESS: "UPDATE_BUDGET_SUCCESS",
  UPDATE_BUDGET_ERROR: "UPDATE_BUDGET_ERROR",
  SET_UPDATE_BUDGET_DATA: "SET_UPDATE_BUDGET_DATA",

  GET_BUDGET_DETAILS_REQUEST: "GET_BUDGET_DETAILS_REQUEST",
  GET_BUDGET_DETAILS_SUCCESS: "GET_BUDGET_DETAILS_SUCCESS",
  GET_BUDGET_DETAILS_ERROR: "GET_BUDGET_DETAILS_ERROR",

  GET_BUDGET_STATEMENTS_REQUEST: "GET_BUDGET_STATEMENTS_REQUEST",
  GET_BUDGET_STATEMENTS_SUCCESS: "GET_BUDGET_STATEMENTS_SUCCESS",
  GET_BUDGET_STATEMENTS_ERROR: "GET_BUDGET_STATEMENTS_ERROR",

  ADD_BUDGET_LINE_REQUEST: "ADD_BUDGET_LINE_REQUEST",
  ADD_BUDGET_LINE_SUCCESS: "ADD_BUDGET_LINE_SUCCESS",
  ADD_BUDGET_LINE_ERROR: "ADD_BUDGET_LINE_ERROR",
  SET_ADD_BUDGET_LINE_DATA: "SET_ADD_BUDGET_LINE_DATA",

  DELETE_BUDGET_LINE_REQUEST: "DELETE_BUDGET_LINE_REQUEST",
  DELETE_BUDGET_LINE_SUCCESS: "DELETE_BUDGET_LINE_SUCCESS",
  DELETE_BUDGET_LINE_ERROR: "DELETE_BUDGET_LINE_ERROR",

  UPDATE_BUDGET_LINE_REQUEST: "UPDATE_BUDGET_LINE_REQUEST",
  UPDATE_BUDGET_LINE_SUCCESS: "UPDATE_BUDGET_LINE_SUCCESS",
  UPDATE_BUDGET_LINE_ERROR: "UPDATE_BUDGET_LINE_ERROR",
  SET_UPDATE_BUDGET_LINE_DATA: "SET_UPDATE_BUDGET_LINE_DATA",

  GET_BUDGET_LINE_DETAILS_REQUEST: "GET_BUDGET_LINE_DETAILS_REQUEST",
  GET_BUDGET_LINE_DETAILS_SUCCESS: "GET_BUDGET_LINE_DETAILS_SUCCESS",
  GET_BUDGET_LINE_DETAILS_ERROR: "GET_BUDGET_LINE_DETAILS_ERROR",

  ADD_BUDGET_STREAM_REQUEST: "ADD_BUDGET_STREAM_REQUEST",
  ADD_BUDGET_STREAM_SUCCESS: "ADD_BUDGET_STREAM_SUCCESS",
  ADD_BUDGET_STREAM_ERROR: "ADD_BUDGET_STREAM_ERROR",

  DELETE_BUDGET_STREAM_REQUEST: "DELETE_BUDGET_STREAM_REQUEST",
  DELETE_BUDGET_STREAM_SUCCESS: "DELETE_BUDGET_STREAM_SUCCESS",
  DELETE_BUDGET_STREAM_ERROR: "DELETE_BUDGET_STREAM_ERROR",

  UPDATE_BUDGET_STREAM_REQUEST: "UPDATE_BUDGET_STREAM_REQUEST",
  UPDATE_BUDGET_STREAM_SUCCESS: "UPDATE_BUDGET_STREAM_SUCCESS",
  UPDATE_BUDGET_STREAM_ERROR: "UPDATE_BUDGET_STREAM_ERROR",
  SET_UPDATE_BUDGET_STREAM_DATA: "SET_UPDATE_BUDGET_STREAM_DATA",

  GET_BUDGET_STREAM_DETAILS_REQUEST: "GET_BUDGET_STREAM_DETAILS_REQUEST",
  GET_BUDGET_STREAM_DETAILS_ERROR: "GET_BUDGET_STREAM_DETAILS_ERROR",
  GET_BUDGET_STREAM_DETAILS_SUCCESS: "GET_BUDGET_STREAM_DETAILS_SUCCESS",

  SUBMIT_BUDGET_REQUEST: "SUBMIT_BUDGET_REQUEST",
  SUBMIT_BUDGET_ERROR: "SUBMIT_BUDGET_ERROR",
  SUBMIT_BUDGET_SUCCESS: "SUBMIT_BUDGET_SUCCESS",

  CHECK_BUDGET_REQUEST: "CHECK_BUDGET_REQUEST",
  CHECK_BUDGET_SUCCESS: "CHECK_BUDGET_SUCCESS",
  CHECK_BUDGET_ERROR: "CHECK_BUDGET_ERROR",

  APPROVE_BUDGET_REQUEST: "APPROVE_BUDGET_REQUEST",
  APPROVE_BUDGET_SUCCESS: "APPROVE_BUDGET_SUCCESS",
  APPROVE_BUDGET_ERROR: "APPROVE_BUDGET_ERROR",

  COPY_BUDGET_REQUEST: "COPY_BUDGET_REQUEST",
  COPY_BUDGET_SUCCESS: "COPY_BUDGET_SUCCESS",
  COPY_BUDGET_ERROR: "COPY_BUDGET_ERROR",

  getBudgets: () => ({
    type: budgets.GET_BUDGETS_REQUEST,
  }),
  copyBudget: (data) => ({
    type: budgets.COPY_BUDGET_REQUEST,
    data,
  }),
  addBudgets: (data) => ({
    type: budgets.ADD_BUDGET_REQUEST,
    data,
  }),
  getMetaData: () => ({
    type: budgets.GET_META_DATA_REQUEST,
  }),
  deleteBudget: (data, budgetsArray) => ({
    type: budgets.DELETE_BUDGET_REQUEST,
    data,
    budgets: budgetsArray,
  }),
  setUpdateBudgetData: (data) => ({
    type: budgets.SET_UPDATE_BUDGET_DATA,
    data,
  }),
  updateBudget: (data, budgetsArray, id) => ({
    data,
    budgets: budgetsArray,
    id,
    type: budgets.UPDATE_BUDGET_REQUEST,
  }),
  getBudgetDetails: (data) => ({
    type: budgets.GET_BUDGET_DETAILS_REQUEST,
    data,
  }),
  getBudgetStatements: (data) => ({
    type: budgets.GET_BUDGET_STATEMENTS_REQUEST,
    data,
  }),
  setAddBudgetLineData: (data) => ({
    type: budgets.SET_ADD_BUDGET_LINE_DATA,
    data,
  }),
  addBudgetLine: (data) => ({
    type: budgets.ADD_BUDGET_LINE_REQUEST,
    data,
  }),
  deleteBudgetLine: (data, budgetId) => ({
    type: budgets.DELETE_BUDGET_LINE_REQUEST,
    budgetId,
    data,
  }),
  setUpdateBudgetLineData: (data) => ({
    type: budgets.SET_UPDATE_BUDGET_LINE_DATA,
    data,
  }),
  updateBudgetLine: (data, id) => ({
    type: budgets.UPDATE_BUDGET_LINE_REQUEST,
    data,
    id,
  }),
  getBudgetLineDetails: (data) => ({
    type: budgets.GET_BUDGET_LINE_DETAILS_REQUEST,
    data,
  }),
  addBudgetStream: (data, path) => ({
    type: budgets.ADD_BUDGET_STREAM_REQUEST,
    data,
    path,
  }),
  deleteBudgetStream: (id, budget_id, line_id) => ({
    type: budgets.DELETE_BUDGET_STREAM_REQUEST,
    line_id,
    budget_id,
    id,
  }),
  updateBudgetStream: (data, path) => ({
    type: budgets.UPDATE_BUDGET_STREAM_REQUEST,
    data,
    path,
  }),
  setUpdateBudgetStreamData: (data) => ({
    type: budgets.SET_UPDATE_BUDGET_STREAM_DATA,
    data,
  }),
  getBudgetStreamDetails: (data, path) => ({
    type: budgets.GET_BUDGET_STREAM_DETAILS_REQUEST,
    data,
    path,
  }),
  submitBudget: (data) => ({
    type: budgets.SUBMIT_BUDGET_REQUEST,
    data,
  }),
  checkBudget: (data) => ({
    type: budgets.CHECK_BUDGET_REQUEST,
    data,
  }),
  approveBudget: (data) => ({
    type: budgets.APPROVE_BUDGET_REQUEST,
    data,
  }),
};

export default budgets;
