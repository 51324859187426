const feesCollections = {
  GET_META_DATA_REQUEST: "GET_FEES_COLLECTIONS_META_DATA_REQUEST",
  GET_META_DATA_SUCCESS: "GET_FESS_COLLECTIONS_META_DATA_SUCCESS",

  GET_FEES_COLLECTIONS_REQUEST: "GET_FEES_COLLECTIONS_REQUEST",
  GET_FEES_COLLECTIONS_SUCCESS: "GET_FEES_COLLECTIONS_SUCCESS",
  GET_FEES_COLLECTIONS_ERROR: "GET_FEES_COLLECTIONS_ERROR",

  GET_FEES_DEFAULTERS_REQUEST: "GET_FEES_DEFAULTERS_REQUEST",
  GET_FEES_DEFAULTERS_SUCCESS: "GET_FEES_DEFAULTERS_SUCCESS",
  GET_FEES_DEFAULTERS_ERROR: "GET_FEES_DEFAULTERS_ERROR",
  SET_FEES_DEFAULTERS_DATA: "SET_FEES_DEFAULTERS_DATA",
  SET_FEES_DEFAULTERS_MESSAGE_CLASS: "SET_FEES_DEFAULTERS_MESSAGE_CLASS",

  GET_INSTALLMENT_PAYMENT_REPORT_REQUEST:
    "GET_INSTALLMENT_PAYMENT_REPORT_REQUEST",
  GET_INSTALLMENT_PAYMENT_REPORT_SUCCESS:
    "GET_INSTALLMENT_PAYMENT_REPORT_SUCCESS",
  GET_INSTALLMENT_PAYMENT_REPORT_ERROR: "GET_INSTALLMENT_PAYMENT_REPORT_ERROR",

  DOWNLOAD_INSTALLMENT_PAYMENT_REPORT_EXCEL_REQUEST:
    "DOWNLOAD_INSTALLMENT_PAYMENT_REPORT_EXCEL_REQUEST",
  DOWNLOAD_INSTALLMENT_PAYMENT_REPORT_EXCEL_SUCCESS:
    "DOWNLOAD_INSTALLMENT_PAYMENT_REPORT_EXCEL_SUCCESS",
  DOWNLOAD_INSTALLMENT_PAYMENT_REPORT_EXCEL_ERROR:
    "DOWNLOAD_INSTALLMENT_PAYMENT_REPORT_EXCEL_ERROR",

  getMetaData: () => ({
    type: feesCollections.GET_META_DATA_REQUEST,
  }),
  getInstallmentsPaymentsReport: (data) => ({
    type: feesCollections.GET_INSTALLMENT_PAYMENT_REPORT_REQUEST,
    data,
  }),
  downloadInstallmentPaymentReportExcel: (data) => ({
    type: feesCollections.DOWNLOAD_INSTALLMENT_PAYMENT_REPORT_EXCEL_REQUEST,
    data,
  }),
  getFeesCollections: (data) => ({
    type: feesCollections.GET_FEES_COLLECTIONS_REQUEST,
    data,
  }),
  getFeesDefaulters: () => ({
    type: feesCollections.GET_FEES_DEFAULTERS_REQUEST,
  }),
  setFeesDefaultersData: (data) => ({
    type: feesCollections.SET_FEES_DEFAULTERS_DATA,
    data,
  }),
  setFeesDefaultersMessageClass: (data) => ({
    type: feesCollections.SET_FEES_DEFAULTERS_MESSAGE_CLASS,
    data,
  }),
};

export default feesCollections;
