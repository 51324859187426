const requestForQuotationsInitialState = {
  requestForQuotations: {
    requestForQuotationsLoading: false,
    requestForQuotationsSuccess: {},
    requestForQuotationsError: {},

    metaDataLoading: false,
    metaDataSuccess: {},

    addRequestForQuotationData: {},
    addRequestForQuotationLoading: false,
    addRequestForQuotationSuccess: {},
    addRequestForQuotationError: {},

    deleteRequestForQuotationLoading: false,
    deleteRequestForQuotationSuccess: {},
    deleteRequestForQuotationError: {},

    updateRequestForQuotationData: {},
    updateRequestForQuotationLoading: false,
    updateRequestForQuotationSuccess: {},
    updateRequestForQuotationError: {},

    requestForQuotationDetailsLoading: false,
    requestForQuotationDetailsSuccess: {},
    requestForQuotationDetailsError: {},

    checkRequestForQuotationLoading: false,
    checkRequestForQuotationSuccess: {},
    checkRequestForQuotationError: {},

    approveRequestForQuotationLoading: false,
    approveRequestForQuotationSuccess: {},
    approveRequestForQuotationError: {},
  },
};

export default requestForQuotationsInitialState;
