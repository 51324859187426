import React from "react";
import { Row, Col } from "antd";
import { MdEmail } from "react-icons/md";
import { BsFacebook, BsInstagram, BsTelephone } from "react-icons/bs";

const items = [
  {
    key: "1",
    icon: <BsTelephone />,
    title: <a href="tel:256771403535">Telephone</a>,
    content: "Call us on Telephone: +(256) 771403535",
  },
  {
    key: "2",
    icon: <BsFacebook />,
    title: <a href="https://www.facebook.com/famis.finance/">Facebook</a>,
    content: (
      <p>
        Visit our{" "}
        <a href="https://www.facebook.com/famis.finance/">Facebook Page</a> to
        stay updated with latest news and updates on Famis
      </p>
    ),
  },
  {
    key: "3",
    icon: <MdEmail />,
    title: <a href="mailto: info.famissystem@gmail.com">Email</a>,
    content: (
      <p>
        Send an email to:{" "}
        <a href="mailto: info.famissystem@gmail.com">
          info.famissytem@gmail.com
        </a>
      </p>
    ),
  },
  {
    key: "4",
    icon: <BsInstagram />,
    title: (
      <a href="https://instagram.com/famis.finance?igshid=YmMyMTA2M2Y=">
        Instagram
      </a>
    ),
    content: (
      <p>
        Visit our{" "}
        <a href="https://instagram.com/famis.finance?igshid=YmMyMTA2M2Y=">
          Instagram
        </a>{" "}
        to stay updated with latest news and updates on Famis
      </p>
    ),
  },
];

function AppContact() {
  return (
    <div id="contact" className="block aboutBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>Contact us</h2>
          <p>
            Contact us for any questions or inquiries. We are here to help you
            out.
          </p>
        </div>
        <Row gutter={[24, 16]} justify="space-around">
          {items.map((item) => {
            return (
              <Col md={{ span: 6 }} key={item.key}>
                <div className="content">
                  <div className="icon">{item.icon}</div>
                  <h3>{item.title}</h3>
                  <p>{item.content}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
}

export default AppContact;
