const recordOfBidders = {
  GET_RECORD_OF_BIDDERS_REQUEST: "GET_RECORD_OF_BIDDERS_REQUEST",
  GET_RECORD_OF_BIDDERS_SUCCESS: "GET_RECORD_OF_BIDDERS_SUCCESS",
  GET_RECORD_OF_BIDDERS_ERROR: "GET_RECORD_OF_BIDDERS_ERROR",

  ADD_RECORD_OF_BIDDERS_REQUEST: "ADD_RECORD_OF_BIDDERS_REQUEST",
  ADD_RECORD_OF_BIDDERS_SUCCESS: "ADD_RECORD_OF_BIDDERS_SUCCESS",
  ADD_RECORD_OF_BIDDERS_ERROR: "ADD_RECORD_OF_BIDDERS_ERROR",

  GET_RECORD_OF_BIDDERS_DETAILS_REQUEST:
    "GET_RECORD_OF_BIDDERS_DETAILS_REQUEST",
  GET_RECORD_OF_BIDDERS_DETAILS_SUCCESS:
    "GET_RECORD_OF_BIDDERS_DETAILS_SUCCESS",
  GET_RECORD_OF_BIDDERS_DETAILS_ERROR: "GET_RECORD_OF_BIDDERS_DETAILS_ERROR",

  GET_RECORD_OF_BIDDERS_META_DATA_REQUEST:
    "GET_RECORD_OF_BIDDERS_META_DATA_REQUEST",
  GET_RECORD_OF_BIDDERS_META_DATA_SUCCESS:
    "GET_RECORD_OF_BIDDERS_META_DATA_SUCCESS",
  GET_RECORD_OF_BIDDERS_META_DATA_ERROR:
    "GET_RECORD_OF_BIDDERS_META_DATA_ERROR",

  DELETE_RECORD_OF_BIDDERS_REQUEST: "DELETE_RECORD_OF_BIDDERS_REQUEST",
  DELETE_RECORD_OF_BIDDERS_SUCCESS: "DELETE_RECORD_OF_BIDDERS_SUCCESS",
  DELETE_RECORD_OF_BIDDERS_ERROR: "DELETE_RECORD_OF_BIDDERS_ERROR",

  UPDATE_RECORD_OF_BIDDERS_DETAILS_REQUEST:
    "UPDATE_RECORD_OF_BIDDERS_DETAILS_REQUEST",
  UPDATE_RECORD_OF_BIDDERS_DETAILS_SUCCESS:
    "UPDATE_RECORD_OF_BIDDERS_DETAILS_SUCCESS",
  UPDATE_RECORD_OF_BIDDERS_DETAILS_ERROR:
    "UPDATE_RECORD_OF_BIDDERS_DETAILS_ERROR",

  SET_UPDATE_RECORD_OF_BIDDERS_DETAILS_DATA:
    "SET_UPDATE_RECORD_OF_BIDDERS_DETAILS_DATA",

  CHECK_RECORD_OF_BIDDERS_REQUEST: "CHECK_RECORD_OF_BIDDERS_REQUEST",
  CHECK_RECORD_OF_BIDDERS_SUCCESS: "CHECK_RECORD_OF_BIDDERS_SUCCESS",
  CHECK_RECORD_OF_BIDDERS_ERROR: "CHECH_RECORD_OF_BIDDERS_ERROR",

  APPROVE_RECORD_OF_BIDDERS_REQUEST: "APPROVE_RECORD_OF_BIDDERS_REQUEST",
  APPROVE_RECORD_OF_BIDDERS_SUCCESS: "APPROVE_RECORD_OF_BIDDERS_SUCCES",
  APPROVE_RECORD_OF_BIDDERS_ERROR: "APPROVE_RECORD_OF_BIDDERS_ERROR",

  //Bidders
  GET_BIDDERS_REQUEST: "GET_BIDDERS_REQUEST",
  GET_BIDDERS_SUCCESS: "GET_BIDDERS_SUCCESS",
  GET_BIDDERS_ERROR: "GET_BIDDERS_ERROR",

  ADD_BIDDER_REQUEST: "ADD_BIDDER_REQUEST",
  ADD_BIDDER_SUCCESS: "ADD_BIDDER_SUCCESS",
  ADD_BIDDER_ERROR: "ADD_BIDDER_ERROR",

  GET_BIDDER_DETAILS_REQUEST: "GET_BIDDER_DETAILS_REQUEST",
  GET_BIDDER_DETAILS_SUCCESS: "GET_BIDDER_DETAILS_SUCCESS",
  GET_BIDDER_DETAILS_ERROR: "GET_BIDDER_DETAILS_ERROR",

  GET_BIDDERS_META_DATA_REQUEST: "GET_BIDDERS_META_DATA_REQUEST",
  GET_BIDDERS_META_DATA_SUCCESS: "GET_BIDDERS_META_DATA_SUCCESS",
  GET_BIDDERS_META_DATA_ERROR: "GET_BIDDERS_META_DATA_ERROR",

  DELETE_BIDDER_REQUEST: "DELETE_BIDDER_REQUEST",
  DELETE_BIDDER_SUCCESS: "DELETE_BIDDER_SUCCESS",
  DELETE_BIDDER_ERROR: "DELETE_BIDDER_ERROR",

  UPDATE_BIDDER_DETAILS_REQUEST: "UPDATE_BIDDER_DETAILS_REQUEST",
  UPDATE_BIDDER_DETAILS_SUCCESS: "UPDATE_BIDDER_DETAILS_SUCCESS",
  UPDATE_BIDDER_DETAILS_ERROR: "UPDATE_BIDDER_DETAILS_ERROR",

  SET_UPDATE_BIDDER_DETAILS_DATA: "SET_UPDATE_BIDDER_DETAILS_DATA",

  getRecordOfBidders: () => ({
    type: recordOfBidders.GET_RECORD_OF_BIDDERS_REQUEST,
  }),
  addRecordOfBidders: (data) => ({
    type: recordOfBidders.ADD_RECORD_OF_BIDDERS_REQUEST,
    data,
  }),
  getRecordOfBiddersDetails: (id) => ({
    type: recordOfBidders.GET_RECORD_OF_BIDDERS_DETAILS_REQUEST,
    id,
  }),
  getRecordOfBiddersMetaData: () => ({
    type: recordOfBidders.GET_RECORD_OF_BIDDERS_META_DATA_REQUEST,
  }),
  deleteRecordOfBidders: (id, recordOfBiddersArrary) => ({
    type: recordOfBidders.DELETE_RECORD_OF_BIDDERS_REQUEST,
    id,
    recordOfBidders: recordOfBiddersArrary,
  }),
  updateRecordOfBidders: (id, data) => ({
    type: recordOfBidders.UPDATE_RECORD_OF_BIDDERS_DETAILS_REQUEST,
    id,
    data,
  }),
  checkRecordOfBidders: (id) => ({
    type: recordOfBidders.CHECK_RECORD_OF_BIDDERS_REQUEST,
    id,
  }),
  approveRecordOfBidders: (id) => ({
    type: recordOfBidders.APPROVE_RECORD_OF_BIDDERS_REQUEST,
    id,
  }),
  setUpdateRecordOfBiddersDetailsData: (data) => ({
    type: recordOfBidders.SET_UPDATE_RECORD_OF_BIDDERS_DETAILS_DATA,
    data,
  }),

  //Bidders
  getBidders: () => ({
    type: recordOfBidders.GET_BIDDERS_REQUEST,
  }),
  getBiddersMetaData: () => ({
    type: recordOfBidders.GET_BIDDERS_META_DATA_REQUEST,
  }),
  getBidderDetails: (id) => ({
    type: recordOfBidders.GET_BIDDER_DETAILS_REQUEST,
    id,
  }),
  addBidder: (data) => ({
    type: recordOfBidders.ADD_BIDDER_REQUEST,
    data,
  }),
  deleteBidder: (id) => ({
    type: recordOfBidders.DELETE_BIDDER_REQUEST,
    id,
  }),
  updateBidderDetails: (id, data) => ({
    type: recordOfBidders.UPDATE_BIDDER_DETAILS_REQUEST,
    id,
    data,
  }),
  setUpdateBidderDetailsData: (data) => ({
    type: recordOfBidders.SET_UPDATE_BIDDER_DETAILS_DATA,
    data,
  }),
};
export default recordOfBidders;
