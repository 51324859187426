import { serverActions } from "../../actions";
import initialState from "../../initialState";

const server = (state = initialState.server, actions) => {
  switch (actions.type) {
    case serverActions.SERVER_REQUEST:
      return {
        ...state,
        serverError: {},
      };

    case serverActions.SERVER_ERROR:
      return {
        ...state,
        serverSuccess: {},
        serverError: actions.error.data,
      };

    case serverActions.SERVER_SUCCESS:
      return {
        ...state,
        serverSuccess: actions.data,
        serverError: {},
      };
    case serverActions.DOWNLOAD_EXCEL_REQUEST:
      return {
        ...state,
        downloadExcelLoading: true,
        downloadExcelError: {},
      };
    case serverActions.DOWNLOAD_EXCEL_SUCCESS:
      return {
        ...state,
        downloadExcelSuccess: actions.data,
        downloadExcelLoading: false,
      };
    case serverActions.DOWNLOAD_EXCEL_ERROR:
      return {
        ...state,
        downloadExcelError: actions.error,
        downloadExcelLoading: false,
      };

    default:
      return state;
  }
};

export default server;
