const loansRepaymentScheduleInititalState = {
  loansRepaymentSchedule: {
    loansRepaymentSchedules: {},
    loansRepaymentSchedulesLoading: false,
    loansRepaymentSchedulesError: {},

    addLoansScheduleRepayment: {},
    addLoansScheduleRepaymentLoading: false,
    addLoansScheduleRepaymentError: {},

    editLoansScheduleRepayment: {},
    editLoansScheduleRepaymentLoading: false,
    editLoansScheduleRepaymentError: {},

    deleteLoansScheduleRepayment: {},
    deleteLoansScheduleRepaymentLoading: false,
    deleteLoansScheduleRepaymentError: {},

    checkLoanScheduleRepayment: {},
    checkLoanScheduleRepaymentLoading: false,
    checkLoanScheduleRepaymentError: {},

    approveLoanScheduleRepayment: {},
    approveLoanScheduleRepaymentLoading: false,
    approveLoanScheduleRepaymentError: {},

    addLoanPayment: {},
    addLoanPaymentLoading: false,
    addLoanPaymentError: {},

    editLoanPayment: {},
    editLoanPaymentLoading: false,
    editLoanPaymentError: {},

    deleteLoanPayment: {},
    deleteLoanPaymentLoading: false,
    deleteLoanPaymentError: {},

    loanScheduleDetails: {},
    loanScheduleDetailsLoading: false,
    loanScheduleDetailsError: {},

    metaData: {},
    metaDataLoading: false,
    metaDataError: {},
  },
};

export default loansRepaymentScheduleInititalState;
