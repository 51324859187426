import { advanceAccountabilityFormActions } from "../../../actions";
import initialState from "../../../initialState";

const advanceAccountabilityForms = (
  state = initialState.advanceAccountabilityForms,
  actions
) => {
  switch (actions.type) {
    case advanceAccountabilityFormActions.GET_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST:
      return {
        ...state,
        advanceAccountabilityFormsLoading: true,
      };
    case advanceAccountabilityFormActions.GET_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
      return {
        ...state,
        advanceAccountabilityFormsSuccess: actions.data,
        advanceAccountabilityFormsLoading: false,
      };
    case advanceAccountabilityFormActions.GET_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
      return {
        ...state,
        advanceAccountabilityFormsError: actions.error,
        advanceAccountabilityFormsLoading: false,
      };
    case advanceAccountabilityFormActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case advanceAccountabilityFormActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataLoading: false,
        metaData: actions.data,
      };
    case advanceAccountabilityFormActions.ADD_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST:
      return {
        ...state,
        addAdvanceAccountabilityFormsLoading: true,
        addAdvanceAccountabilityFormsError: {},
      };
    case advanceAccountabilityFormActions.ADD_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
      return {
        ...state,
        addAdvanceAccountabilityFormsSuccess: actions.data,
        addAdvanceAccountabilityFormsLoading: false,
      };
    case advanceAccountabilityFormActions.ADD_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
      return {
        ...state,
        addAdvanceAccountabilityFormsError: actions.error,
        addAdvanceAccountabilityFormsLoading: false,
      };
    case advanceAccountabilityFormActions.SEARCH_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST:
      return {
        ...state,
        searchAdvanceAccountabilityFormsLoading: true,
        searchAdvanceAccountabilityFormsError: {},
      };
    case advanceAccountabilityFormActions.SEARCH_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
      return {
        ...state,
        searchAdvanceAccountabilityFormsSuccess: actions.data,
        searchAdvanceAccountabilityFormsLoading: false,
      };
    case advanceAccountabilityFormActions.SEARCH_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
      return {
        ...state,
        addAdvanceAccountabilityFormsError: actions.error,
        addAdvanceAccountabilityFormsLoading: false,
      };
    case advanceAccountabilityFormActions.UPDATE_ADVANCE_ACCOUNTABILIT_FORMS_DATA:
      return {
        ...state,
        updateAdvanceAccountabilityFormsData: actions.data,
      };
    case advanceAccountabilityFormActions.UPDATE_ADVANCE_ACCOUNTABILIT_FORMS_REQUEST:
      return {
        ...state,
        updateAdvanceAccountabilityFormsLoading: actions.data,
        updateAdvanceAccountabilityFormsError: {},
      };
    case advanceAccountabilityFormActions.UPDATE_ADVANCE_ACCOUNTABILIT_FORMS_SUCCESS:
      return {
        ...state,
        updateAdvanceAccountabilityFormsSuccess: actions.data,
        updateAdvanceAccountabilityFormsLoading: false,
      };
    case advanceAccountabilityFormActions.UPDATE_ADVANCE_ACCOUNTABILIT_FORMS_ERROR:
      return {
        ...state,
        updateAdvanceAccountabilityFormsError: actions.error,
        updateAdvanceAccountabilityFormsLoading: false,
      };
    case advanceAccountabilityFormActions.GET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_REQUEST:
      return {
        ...state,
        advanceAccountabilityFormDetailsLoading: true,
        advanceAccountabilityFormDetailsError: {},
      };
    case advanceAccountabilityFormActions.GET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_SUCCESS:
      return {
        ...state,
        advanceAccountabilityFormDetailsSuccess: actions.data,
        advanceAccountabilityFormDetailsLoading: false,
      };
    case advanceAccountabilityFormActions.GET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_ERROR:
      return {
        ...state,
        advanceAccountabilityFormDetailsError: actions.error,
        advanceAccountabilityFormDetailsLoading: false,
      };
    case advanceAccountabilityFormActions.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST:
      return {
        ...state,
        deleteAdvanceAccountabilityFormsLoading: true,
        deleteAdvanceAccountabilityFormsError: {},
      };
    case advanceAccountabilityFormActions.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
      return {
        ...state,
        deleteAdvanceAccountabilityFormsSuccess: actions.data,
        deleteAdvanceAccountabilityFormsLoading: false,
      };
    case advanceAccountabilityFormActions.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
      return {
        ...state,
        deleteAdvanceAccountabilityFormsLoading: false,
        deleteAdvanceAccountabilityFormsError: actions.data,
      };
    case advanceAccountabilityFormActions.SUBMIT_ADVANCE_ACCOUNTABILITY_FORM_REQUEST:
      return {
        ...state,
        submitAdvanceAccountabilityFormLoading: true,
        submitAdvanceAccountabilityFormError: {},
      };
    case advanceAccountabilityFormActions.SUBMIT_ADVANCE_ACCOUNTABILITY_FORM_SUCCESS:
      return {
        ...state,
        submitAdvanceAccountabilityFormSuccess: actions.data,
        submitAdvanceAccountabilityFormLoading: false,
      };
    case advanceAccountabilityFormActions.SUBMIT_ADVANCE_ACCOUNTABILITY_FORM_ERROR:
      return {
        ...state,
        submitAdvanceAccountabilityFormError: actions.error,
        submitAdvanceAccountabilityFormLoading: false,
      };
    case advanceAccountabilityFormActions.CHECK_ADVANCE_ACCOUNTABILITY_FORM_REQUEST:
      return {
        ...state,
        checkAdvanceAccountabilityFormLoading: true,
        checkAdvanceAccountabilityFormError: false,
      };
    case advanceAccountabilityFormActions.CHECK_ADVANCE_ACCOUNTABILITY_FORM_SUCCESS:
      return {
        ...state,
        checkAdvanceAccountabilityFormSuccess: actions.data,
        checkAdvanceAccountabilityFormLoading: false,
      };
    case advanceAccountabilityFormActions.CHECK_ADVANCE_ACCOUNTABILITY_FORM_ERROR:
      return {
        ...state,
        checkAdvanceAccountabilityFormError: actions.data,
        checkAdvanceAccountabilityFormLoading: false,
      };
    case advanceAccountabilityFormActions.APPROVE_ADVANCE_ACCOUNTABILITY_FORM_REQUEST:
      return {
        ...state,
        approveAdvanceAccountabilityFormLoading: true,
        approveAdvanceAccountabilityFormError: {},
      };
    case advanceAccountabilityFormActions.APPROVE_ADVANCE_ACCOUNTABILITY_FORM_SUCCESS:
      return {
        ...state,
        approveAdvanceAccountabilityFormSuccess: actions.data,
        approveAdvanceAccountabilityFormLoading: false,
      };
    case advanceAccountabilityFormActions.APPROVE_ADVANCE_ACCOUNTABILITY_FORM_ERROR:
      return {
        ...state,
        approveAdvanceAccountabilityFormError: actions.error,
        approveAdvanceAccountabilityFormLoading: false,
      };
    case advanceAccountabilityFormActions.ADD_ACTUAL_EXPENDITURE_REQUEST:
      return {
        ...state,
        addActualExpenditureLoading: true,
        addActualExpenditureError: {},
      };
    case advanceAccountabilityFormActions.ADD_ACTUAL_EXPENDITURE_SUCCESS:
      return {
        ...state,
        addActualExpenditureSuccess: actions.data,
        addActualExpenditureLoading: false,
      };
    case advanceAccountabilityFormActions.ADD_ACTUAL_EXPENDITURE_ERROR:
      return {
        ...state,
        addActualExpenditureError: actions.error,
        addActualExpenditureLoading: false,
      };
    case advanceAccountabilityFormActions.UPDATE_ACTUAL_EXPENDITURE_DATA:
      return {
        ...state,
        updateActualExpenditureData: actions.data,
      };
    case advanceAccountabilityFormActions.UPDATE_ACTUAL_EXPENDITURE_REQUEST:
      return {
        ...state,
        updateActualExpenditureLoading: true,
        updateActualExpenditureError: actions.error,
      };
    case advanceAccountabilityFormActions.UPDATE_ACTUAL_EXPENDITURE_SUCCESS:
      return {
        ...state,
        updateActualExpenditureSuccess: actions.data,
        updateActualExpenditureLoading: false,
      };
    case advanceAccountabilityFormActions.UPDATE_ACTUAL_EXPENDITURE_ERROR:
      return {
        ...state,
        updateActualExpenditureError: actions.error,
        updateActualExpenditureLoading: false,
      };
    case advanceAccountabilityFormActions.GET_ACTUAL_EXPENDITURE_DETAILS_REQUEST:
      return {
        ...state,
        actualExpenditureDetailsLoading: true,
        actualExpenditureDetailsError: actions.error,
      };
    case advanceAccountabilityFormActions.GET_ACTUAL_EXPENDITURE_DETAILS_SUCCESS:
      return {
        ...state,
        actualExpenditureDetailsSuccess: actions.data,
        actualExpenditureDetailsLoading: false,
      };
    case advanceAccountabilityFormActions.GET_ACTUAL_EXPENDITURE_DETAILS_ERROR:
      return {
        ...state,
        actualExpenditureDetailsError: actions.error,
        actualExpenditureDetailsLoading: false,
      };
    case advanceAccountabilityFormActions.DELETE_ACTUAL_EXPENDITURE_REQUEST:
      return {
        ...state,
        deleteActualExpenditureLoading: true,
        deleteActualExpenditureError: {},
      };
    case advanceAccountabilityFormActions.DELETE_ACTUAL_EXPENDITURE_SUCCESS:
      return {
        ...state,
        deleteActualExpenditureSuccess: actions.data,
        deleteActualExpenditureLoading: false,
      };
    case advanceAccountabilityFormActions.DELETE_ACTUAL_EXPENDITURE_ERROR:
      return {
        ...state,
        deleteActualExpenditureError: actions.error,
        deleteActualExpenditureSuccess: false,
      };
    case advanceAccountabilityFormActions.UPLOAD_ADVANCE_ACCOUNTABILITY_DOCUMENTS_REQUEST:
      return {
        ...state,
        uploadAdvanceAccountabilityDocumentsLoading: true,
        uploadAdvanceAccountabilityDocumentsError: {},
      };
    case advanceAccountabilityFormActions.UPLOAD_ADVANCE_ACCOUNTABILITY_DOCUMENTS_SUCCESS:
      return {
        ...state,
        uploadAdvanceAccountabilityDocumentsSuccess: actions.data,
        uploadAdvanceAccountabilityDocumentsLoading: false,
      };
    case advanceAccountabilityFormActions.UPLOAD_ADVANCE_ACCOUNTABILITY_DOCUMENTS_ERROR:
      return {
        ...state,
        uploadAdvanceAccountabilityDocumentsError: actions.error,
        uploadAdvanceAccountabilityDocumentsLoading: false,
      };
    case advanceAccountabilityFormActions.SORT_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST:
      return {
        ...state,
        sortAdvanceAccountabilityFormsLoading: true,
        sortAdvanceAccountabilityFormsError: {},
      };
    case advanceAccountabilityFormActions.SORT_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
      return {
        ...state,
        sortAdvanceAccountabilityFormsSuccess: actions.data,
        sortAdvanceAccountabilityFormsLoading: false,
      };
    case advanceAccountabilityFormActions.SORT_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
      return {
        ...state,
        sortAdvanceAccountabilityFormsError: actions.error,
        sortAdvanceAccountabilityFormsLoading: false,
      };
    case advanceAccountabilityFormActions.DOWNLOAD_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST:
      return {
        ...state,
        downloadAdvanceAccountabilityFormsLoading: true,
        downloadAdvanceAccountabilityFormsError: {},
      };
    case advanceAccountabilityFormActions.DOWNLOAD_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
      return {
        ...state,
        downloadAdvanceAccountabilityFormsSuccess: actions.data,
        downloadAdvanceAccountabilityFormsLoading: false,
      };
    case advanceAccountabilityFormActions.DOWNLOAD_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
      return {
        ...state,
        downloadAdvanceAccountabilityFormsError: actions.error,
        downloadAdvanceAccountabilityFormsLoading: false,
      };
    case advanceAccountabilityFormActions.SET_SORT_QUERY:
      return {
        ...state,
        sortQuery: actions.data,
      };
    default:
      return state;
  }
};

export default advanceAccountabilityForms;
