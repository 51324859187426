const marksUpload = {
  GET_MARKS_UPLOAD_SUCCESS: "GET_MARKS_UPLOAD_SUCCESS",
  GET_MARKS_UPLOAD_REQUEST: "GET_MARKS_UPLOAD_REQUEST",
  GET_MARKS_UPLOAD_ERROR: "GET_MARKS_UPLOAD_ERROR",

  GET_MARKS_UPLOAD_DETAILS_SUCCESS: "GET_MARKS_UPLOAD_DETAILS_SUCCESS",
  GET_MARKS_UPLOAD_DETAILS_REQUEST: "GET_MARKS_UPLOAD_DETAILS_REQUEST",
  GET_MARKS_UPLOAD_DETAILS_ERROR: "GET_MARKS_UPLOAD_DETAILS_ERROR",

  ADD_MARKS_UPLOAD_SUCCESS: "ADD_MARKS_UPLOAD_SUCCESS",
  ADD_MARKS_UPLOAD_REQUEST: "ADD_MARKS_UPLOAD_REQUEST",
  ADD_MARKS_UPLOAD_ERROR: "ADD_MARKS_UPLOAD_ERROR",

  UPDATE_MARKS_UPLOAD_SUCCESS: "UPDATE_MARKS_UPLOAD_SUCCESS",
  UPDATE_MARKS_UPLOAD_REQUEST: "UPDATE_MARKS_UPLOAD_REQUEST",
  UPDATE_MARKS_UPLOAD_ERROR: "UPDATE_MARKS_UPLOAD_ERROR",
  UPDATE_MARKS_UPLOAD_DATA: "UPDATE_MARKS_UPLOAD_DATA",

  DELETE_MARKS_UPLOAD_SUCCESS: "DELETE_MARKS_UPLOAD_SUCCESS",
  DELETE_MARKS_UPLOAD_REQUEST: "DELETE_MARKS_UPLOAD_REQUEST",
  DELETE_MARKS_UPLOAD_ERROR: "DELETE_MARKS_UPLOAD_ERROR",

  UPLOAD_STUDENT_MARKS_SUCCESS: "UPLOAD_STUDENT_MARKS_SUCCESS",
  UPLOAD_STUDENT_MARKS_REQUEST: "UPLOAD_STUDENT_MARKS_REQUEST",
  UPLOAD_STUDENT_MARKS_ERROR: "UPLOAD_STUDENT_MARKS_ERROR",

  GET_META_DATA_SUCCESS: "GET_MARKS_UPLOAD_META_DATA_SUCCESS",
  GET_META_DATA_REQUEST: "GET_MARKS_UPLOAD_META_DATA_REQUEST",
  GET_META_DATA_ERROR: "GET_MARKS_UPLOAD_META_DATA_ERROR",

  SUBMIT_MARKS_UPLOAD_SUCCESS: "SUBMIT_MARKS_UPLOAD_SUCCESS",
  SUBMIT_MARKS_UPLOAD_REQUEST: "SUBMIT_MARKS_UPLOAD_REQUEST",
  SUBMIT_MARKS_UPLOAD_ERROR: "SUBMIT_MARKS_UPLOAD_ERROR",

  CHECK_MARKS_UPLOAD_SUCCESS: "CHECK_MARKS_UPLOAD_SUCCESS",
  CHECK_MARKS_UPLOAD_REQUEST: "CHECK_MARKS_UPLOAD_REQUEST",
  CHECK_MARKS_UPLOAD_ERROR: "CHECK_MARKS_UPLOAD_ERROR",

  APPROVE_MARKS_UPLOAD_SUCCESS: "APPROVE_MARKS_UPLOAD_SUCCESS",
  APPROVE_MARKS_UPLOAD_REQUEST: "APPROVE_MARKS_UPLOAD_REQUEST",
  APPROVE_MARKS_UPLOAD_ERROR: "APPROVE_MARKS_UPLOAD_ERROR",

  UPDATE_STUDENT_MARKS_SUCCESS: "UPDATE_STUDENT_MARKS_SUCCESS",
  UPDATE_STUDENT_MARKS_REQUEST: "UPDATE_STUDENT_MARKS_REQUEST",
  UPDATE_STUDENT_MARKS_ERROR: "UPDATE_STUDENT_MARKS_ERROR",
  UPDATE_STUDENT_MARKS_DATA: "UPDATE_STUDENT_MARKS_DATA",

  ADD_STUDENT_MARKS_SUCCESS: "ADD_STUDENT_MARKS_SUCCESS",
  ADD_STUDENT_MARKS_REQUEST: "ADD_STUDENT_MARKS_REQUEST",
  ADD_STUDENT_MARKS_ERROR: "ADD_STUDENT_MARKS_ERROR",

  DOWNLOAD_STUDENT_MARKS_EXCEL_SUCCESS: "DOWNLOAD_STUDENT_MARKS_EXCEL_SUCCESS",
  DOWNLOAD_STUDENT_MARKS_EXCEL_REQUEST: "DOWNLOAD_STUDENT_MARKS_EXCEL_REQUEST",
  DOWNLOAD_STUDENT_MARKS_EXCEL_ERROR: "DOWNLOAD_STUDENT_MARKS_EXCEL_ERROR",

  UPLOAD_STUDENT_MARKS_EXCEL_SUCCESS: "UPLOAD_STUDENT_MARKS_EXCEL_SUCCESS",
  UPLOAD_STUDENT_MARKS_EXCEL_REQUEST: "UPLOAD_STUDENT_MARKS_EXCEL_REQUEST",
  UPLOAD_STUDENT_MARKS_EXCEL_ERROR: "UPLOAD_STUDENT_MARKS_EXCEL_ERROR",

  MARKS_EXCEL_UPLOADED: "MARKS_EXCEL_UPLOADED",

  getMarksUpload: (data) => ({
    type: marksUpload.GET_MARKS_UPLOAD_REQUEST,
    data,
  }),
  getMarksUploadDetails: (data) => ({
    type: marksUpload.GET_MARKS_UPLOAD_DETAILS_REQUEST,
    data,
  }),
  addMarksUpload: (data) => ({
    type: marksUpload.ADD_MARKS_UPLOAD_REQUEST,
    data,
  }),
  updateMarksUpload: (data, id) => ({
    type: marksUpload.UPDATE_MARKS_UPLOAD_REQUEST,
    data,
    id,
  }),
  updateMarksUploadData: (data) => ({
    type: marksUpload.UPDATE_MARKS_UPLOAD_DATA,
    data,
  }),
  deleteMarksUpload: (id) => ({
    type: marksUpload.DELETE_MARKS_UPLOAD_REQUEST,
    id,
  }),
  uploadStudentMarks: (data, context) => ({
    type: marksUpload.UPLOAD_STUDENT_MARKS_REQUEST,
    data,
    context,
  }),
  getMetaData: (data) => ({
    type: marksUpload.GET_META_DATA_REQUEST,
    data,
  }),
  submitMarksUpload: (id) => ({
    type: marksUpload.SUBMIT_MARKS_UPLOAD_REQUEST,
    id,
  }),
  checkMarksUpload: (id) => ({
    type: marksUpload.CHECK_MARKS_UPLOAD_REQUEST,
    id,
  }),
  approveMarksUpload: (id) => ({
    type: marksUpload.APPROVE_MARKS_UPLOAD_REQUEST,
    id,
  }),
  updateStudentMarks: (data) => ({
    type: marksUpload.UPDATE_STUDENT_MARKS_REQUEST,
    data,
  }),
  updateStudentMarksData: (data) => ({
    type: marksUpload.UPDATE_STUDENT_MARKS_DATA,
    data,
  }),
  addStudentMarks: (data) => ({
    type: marksUpload.ADD_STUDENT_MARKS_REQUEST,
    data,
  }),
  downloadStudentMarksExcel: (data) => ({
    type: marksUpload.DOWNLOAD_STUDENT_MARKS_EXCEL_REQUEST,
    data,
  }),
  uploadStudentMarksExcel: (data, params) => ({
    type: marksUpload.UPLOAD_STUDENT_MARKS_EXCEL_REQUEST,
    data,
    params,
  }),
  marksExcelUploaded: (data) => ({
    type: marksUpload.MARKS_EXCEL_UPLOADED,
    data,
  }),
};

export default marksUpload;
