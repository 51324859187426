const financeStreams = {
  GET_FINANCE_STREAMS_REQUEST: "GET_FINANCE_STREAMS_REQUEST",
  GET_FINANCE_STREAMS_SUCCESS: "GET_FINANCE_STREAMS_SUCCESS",
  GET_FINANCE_STREAMS_ERROR: "GET_FINANCE_STREAMS_ERROR",

  GET_FINANCE_STREAMS_META_DATA_REQUEST:
    "GET_FINANCE_STREAMS_META_DATA_REQUEST",
  GET_FINANCE_STREAMS_META_DATA_SUCCESS:
    "GET_FINANCE_STREAMS_META_DATA_SUCCESS",

  ADD_FINANCE_STREAM_REQUEST: "ADD_FINANCE_STREAM_REQUEST",
  ADD_FINANCE_STREAM_SUCCESS: "ADD_FINANCE_STREAM_SUCCESS",
  ADD_FINANCE_STREAM_ERROR: "ADD_FINANCE_STREAM_ERROR",

  DELETE_FINANCE_STREAM_REQUEST: "DELETE_FINANCE_STREAM_REQUEST",
  DELETE_FINANCE_STREAM_SUCCESS: "DELETE_FINANCE_STREAM_SUCCESS",
  DELETE_FINANCE_STREAM_ERROR: "DELETE_FINANCE_STREAM_ERROR",

  UPDATE_FINANCE_STREAM_REQUEST: "UPDATE_FINANCE_STREAM_REQUEST",
  UPDATE_FINANCE_STREAM_SUCCESS: "UPDATE_FINANCE_STREAM_SUCCESS",
  UPDATE_FINANCE_STREAM_ERROR: "UPDATE_FINANCE_STREAM_ERROR",
  SET_UPDATE_FINANCE_STREAM_DATA: "SET_UPDATE_FINANCE_STREAM_DATA",

  GET_FINANCE_STREAM_DETAILS_REQUEST: "GET_FINANCE_STREAM_DETAILS_REQUEST",
  GET_FINANCE_STREAM_DETAILS_SUCCESS: "GET_FINANCE_STREAM_DETAILS_SUCCESS",
  GET_FINANCE_STREAM_DETAILS_ERROR: "GET_FINANCE_STREAM_DETAILS_ERROR",

  CHECK_FINANCE_STREAM_REQUEST: "CHECK_FINANCE_STREAM_REQUEST",
  CHECK_FINANCE_STREAM_SUCCESS: "CHECK_FINANCE_STREAM_SUCCESS",
  CHECK_FINANCE_STREAM_ERROR: "CHECK_FINANCE_STREAM_ERROR",

  APPROVE_FINANCE_STREAM_REQUEST: "APPROVE_FINANCE_STREAM_REQUEST",
  APPROVE_FINANCE_STREAM_SUCCESS: "APPROVE_FINANCE_STREAM_SUCCESS",
  APPROVE_FINANCE_STREAM_ERROR: "APPROVE_FINANCE_STREAM_ERROR",

  getFinanceStreams: () => ({
    type: financeStreams.GET_FINANCE_STREAMS_REQUEST,
  }),
  getMetaData: () => ({
    type: financeStreams.GET_FINANCE_STREAMS_META_DATA_REQUEST,
  }),
  addFinanceStream: (data) => ({
    type: financeStreams.ADD_FINANCE_STREAM_REQUEST,
    data,
  }),
  deleteFinanceStream: (data) => ({
    type: financeStreams.DELETE_FINANCE_STREAM_REQUEST,
    data,
  }),
  updateFinanceStream: (data, streams, id) => ({
    type: financeStreams.UPDATE_FINANCE_STREAM_REQUEST,
    streams,
    data,
    id,
  }),
  setUpdateFinanceStreamData: (data) => ({
    data,
    type: financeStreams.SET_UPDATE_FINANCE_STREAM_DATA,
  }),
  getFinanceStreamDetails: (data) => ({
    type: financeStreams.GET_FINANCE_STREAM_DETAILS_REQUEST,
    data,
  }),
  checkFinaceStream: (data) => ({
    type: financeStreams.CHECK_FINANCE_STREAM_REQUEST,
    data,
  }),
  approveFinanceStream: (data) => ({
    type: financeStreams.APPROVE_FINANCE_STREAM_REQUEST,
    data,
  }),
};

export default financeStreams;
