const localPurchaseOrders = {
  GET_LOCAL_PURCHASE_ORDERS_REQUEST: "GET_LOCAL_PURCHASE_ORDERS_REQUEST",
  GET_LOCAL_PURCHASE_ORDERS_SUCCESS: "GET_LOCAL_PURCHASE_ORDERS_SUCCESS",
  GET_LOCAL_PURCHASE_ORDERS_ERROR: "GET_LOCAL_PURCHASE_ORDERS_ERROR",
  GET_LOCAL_PURCHASE_ORDER_ITEMS_REQUEST:
    "GET_LOCAL_PURCHASE_ORDER_ITEMS_REQUEST",
  GET_LOCAL_PURCHASE_ORDER_ITEMS_SUCCESS:
    "GET_LOCAL_PURCHASE_ORDER_ITEMS_SUCCESS",
  GET_LOCAL_PURCHASE_ORDER_ITEMS_ERROR: "GET_LOCAL_PURCHASE_ORDER_ITEMS_ERROR",

  GET_LOCAL_PURCHASE_ORDERS_META_DATA_REQUEST:
    "GET_LOCAL_PURCHASE_ORDERS_META_DATA_REQUEST",
  GET_LOCAL_PURCHASE_ORDERS_META_DATA_SUCCESS:
    "GET_LOCAL_PURCHASE_ORDERS_META_DATA_SUCCESS",
  GET_LOCAL_PURCHASE_ORDER_ITEMS_META_DATA_REQUEST:
    "GET_LOCAL_PURCHASE_ORDER_ITEMS_META_DATA_REQUEST",
  GET_LOCAL_PURCHASE_ORDER_ITEMS_META_DATA_SUCCESS:
    "GET_LOCAL_PURCHASE_ORDER_ITEMS_META_DATA_SUCCESS",

  SET_ADD_LOCAL_PURCHASE_ORDER_DATA: "SET_ADD_LOCAL_PURCHASE_ORDER_DATA",
  ADD_LOCAL_PURCHASE_ORDER_REQUEST: "ADD_LOCAL_PURCHASE_ORDER_REQUEST",
  ADD_LOCAL_PURCHASE_ORDER_SUCCESS: "ADD_LOCAL_PURCHASE_ORDER_SUCCESS",
  ADD_LOCAL_PURCHASE_ORDER_ERROR: "ADD_LOCAL_PURCHASE_ORDER_ERROR",
  SET_ADD_LOCAL_PURCHASE_ORDER_ITEM_DATA: "SET_ADD_LOCAL_PURCHASE_ORDER_DATA",
  ADD_LOCAL_PURCHASE_ORDER_ITEM_REQUEST:
    "ADD_LOCAL_PURCHASE_ORDER_ITEM_REQUEST",
  ADD_LOCAL_PURCHASE_ORDER_ITEM_SUCCESS:
    "ADD_LOCAL_PURCHASE_ORDER_ITEM_SUCCESS",
  ADD_LOCAL_PURCHASE_ORDER_ITEM_ERROR: "ADD_LOCAL_PURCHASE_ORDER_ITEM_ERROR",

  DELETE_LOCAL_PURCHASE_ORDER_REQUEST: "DELETE_LOCAL_PURCHASE_ORDER_REQUEST",
  DELETE_LOCAL_PURCHASE_ORDER_SUCCESS: "DELETE_LOCAL_PURCHASE_ORDER_SUCCESS",
  DELETE_LOCAL_PURCHASE_ORDER_ERROR: "DELETE_LOCAL_PURCHASE_ORDER_ERROR",
  DELETE_LOCAL_PURCHASE_ORDER_ITEM_REQUEST:
    "DELETE_LOCAL_PURCHASE_ORDER_ITEM_REQUEST",
  DELETE_LOCAL_PURCHASE_ORDER_ITEM_SUCCESS:
    "DELETE_LOCAL_PURCHASE_ORDER_ITEM_SUCCESS",
  DELETE_LOCAL_PURCHASE_ORDER_ITEM_ERROR:
    "DELETE_LOCAL_PURCHASE_ORDER_ITEM_ERROR",

  SET_UPDATE_LOCAL_PURCHASE_ORDER_DATA: "SET_UPDATE_LOCAL_PURCHASE_ORDER_DATA",
  UPDATE_LOCAL_PURCHASE_ORDER_REQUEST: "UPDATE_LOCAL_PURCHASE_ORDER_REQUEST",
  UPDATE_LOCAL_PURCHASE_ORDER_SUCCESS: "UPDATE_LOCAL_PURCHASE_ORDER_SUCCESS",
  UPDATE_LOCAL_PURCHASE_ORDER_ERROR: "UPDATE_LOCAL_PURCHASE_ORDER_ERROR",
  SET_UPDATE_LOCAL_PURCHASE_ORDER_ITEM_UPDATE_DATA:
    "SET_UPDATE_LOCAL_PURCHASE_ORDER_ITEM_UPDATE_DATA",
  UPDATE_LOCAL_PURCHASE_ORDER_ITEM_REQUEST:
    "UPDATE_LOCAL_PURCHASE_ORDER_ITEM_REQUEST",
  UPDATE_LOCAL_PURCHASE_ORDER_ITEM_SUCCESS:
    "UPDATE_LOCAL_PURCHASE_ORDER_ITEM_SUCCESS",
  UPDATE_LOCAL_PURCHASE_ORDER_ITEM_ERROR:
    "UPDATE_LOCAL_PURCHASE_ORDER_ITEM_ERROR",

  GET_LOCAL_PURCHASE_ORDER_DETAILS_REQUEST:
    "GET_LOCAL_PURCHASE_ORDER_DETAILS_REQUEST",
  GET_LOCAL_PURCHASE_ORDER_DETAILS_SUCCESS:
    "GET_LOCAL_PURCHASE_ORDER_DETAILS_SUCCESS",
  GET_LOCAL_PURCHASE_ORDER_DETAILS_ERROR:
    "GET_LOCAL_PURCHASE_ORDER_DETAILS_ERROR",
  GET_LOCAL_PURCHASE_ORDER_ITEM_DETAILS_REQUEST:
    "GET_LOCAL_PURCHASE_ORDER_ITEM_DETAILS_REQUEST",
  GET_LOCAL_PURCHASE_ORDER_ITEM_DETAILS_SUCCESS:
    "GET_LOCAL_PURCHASE_ORDER_ITEM_DETAILS_SUCCESS",
  GET_LOCAL_PURCHASE_ORDER_ITEM_DETAILS_ERROR:
    "GET_LOCAL_PURCHASE_ORDER_ITEM_DETAILS_ERROR",

  APPROVE_LOCAL_PURCHASE_ORDER_REQUEST: "APPROVE_LOCAL_PURCHASE_ORDER_REQUEST",
  APPROVE_LOCAL_PURCHASE_ORDER_SUCCESS: "APPROVE_LOCAL_PURCHASE_ORDER_SUCCESS",
  APPROVE_LOCAL_PURCHASE_ORDER_ERROR: "APPROVE_LOCAL_PURCHASE_ORDER_ERROR",

  CHECK_LOCAL_PURCHASE_ORDER_REQUEST: "CHECK_LOCAL_PURCHASE_ORDER_REQUEST",
  CHECK_LOCAL_PURCHASE_ORDER_SUCCESS: "CHECK_LOCAL_PURCHASE_ORDER_SUCCESS",
  CHECK_LOCAL_PURCHASE_ORDER_ERROR: "CHECK_LOCAL_PURCHASE_ORDER_ERROR",

  getLocalPurchaseOrders: () => ({
    type: localPurchaseOrders.GET_LOCAL_PURCHASE_ORDERS_REQUEST,
  }),
  getLocalPurchaseOrderItems: (id) => ({
    type: localPurchaseOrders.GET_LOCAL_PURCHASE_ORDER_ITEMS_REQUEST,
    id,
  }),
  getLocalPurchaseOrdersMetaData: () => ({
    type: localPurchaseOrders.GET_LOCAL_PURCHASE_ORDERS_META_DATA_REQUEST,
  }),
  getLocalPurchaseOrderItemsMetaData: () => ({
    type: localPurchaseOrders.GET_LOCAL_PURCHASE_ORDER_ITEMS_META_DATA_REQUEST,
  }),
  setAddLocalPurchaseOrderData: (data) => ({
    type: localPurchaseOrders.SET_ADD_LOCAL_PURCHASE_ORDER_DATA,
    data,
  }),
  setAddLocalPurchaseOrderItemData: (data) => ({
    type: localPurchaseOrders.SET_ADD_LOCAL_PURCHASE_ORDER_ITEM_DATA,
    data,
  }),
  addLocalPurchaseOrder: (data) => ({
    type: localPurchaseOrders.ADD_LOCAL_PURCHASE_ORDER_REQUEST,
    data,
  }),
  addLocalPurchaseOrderItem: (data) => ({
    type: localPurchaseOrders.ADD_LOCAL_PURCHASE_ORDER_ITEM_REQUEST,
    data,
  }),
  deleteLocalPurchaseOrder: (data) => ({
    type: localPurchaseOrders.DELETE_LOCAL_PURCHASE_ORDER_REQUEST,
    data,
  }),
  deleteLocalPurchaseOrderItem: (id, lpo_id) => ({
    type: localPurchaseOrders.DELETE_LOCAL_PURCHASE_ORDER_ITEM_REQUEST,
    id,
    lpo_id,
  }),
  updateLocalPurchaseOrder: (data, id) => ({
    type: localPurchaseOrders.UPDATE_LOCAL_PURCHASE_ORDER_REQUEST,
    data,
    id,
  }),
  updateLocalPurchaseOrderItem: (data, id) => ({
    type: localPurchaseOrders.UPDATE_LOCAL_PURCHASE_ORDER_ITEM_REQUEST,
    data,
    id,
  }),
  setUpdateLocalPurchaseOrderData: (data) => ({
    type: localPurchaseOrders.SET_UPDATE_LOCAL_PURCHASE_ORDER_DATA,
    data,
  }),
  setUpdateLocalPurchaseOrderItemData: (data) => ({
    type: localPurchaseOrders.SET_UPDATE_LOCAL_PURCHASE_ORDER_ITEM_UPDATE_DATA,
    data,
  }),
  getLocalPurchaseOrderDetails: (data) => ({
    type: localPurchaseOrders.GET_LOCAL_PURCHASE_ORDER_DETAILS_REQUEST,
    data,
  }),
  getLocalPurchaseOrderItemDetails: (data) => ({
    type: localPurchaseOrders.GET_LOCAL_PURCHASE_ORDER_ITEM_DETAILS_REQUEST,
    data,
  }),
  checkLocalPurchaseOrder: (data) => ({
    type: localPurchaseOrders.CHECK_LOCAL_PURCHASE_ORDER_REQUEST,
    data,
  }),
  approveLocalPurchaseOrder: (data) => ({
    type: localPurchaseOrders.APPROVE_LOCAL_PURCHASE_ORDER_REQUEST,
    data,
  }),
};

export default localPurchaseOrders;
