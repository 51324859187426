const partners = {
  GET_PARTNERS_REQUEST: "GET_PARTNERS_REQUEST",
  GET_PARTNERS_SUCCESS: "GET_PARTNERS_SUCCESS",
  GET_PARTNERS_ERROR: "GET_PARTNERS_ERROR",

  ADD_PARTNER_REQUEST: "ADD_PARTNER_REQUEST",
  ADD_PARTNER_SUCCESS: "ADD_PARTNER_SUCCESS",
  ADD_PARTNER_ERROR: "ADD_PARTNER_ERROR",

  GET_PARTNER_DETAILS_REQUEST: "GET_PARTNER_DETAILS_REQUEST",
  GET_PARTNER_DETAILS_SUCCESS: "GET_PARTNER_DETAILS_SUCCESS",
  GET_PARTNER_DETAILS_ERROR: "GET_PARTNER_DETAILS_ERROR",

  DELETE_PARTNER_REQUEST: "DELETE_PARTNER_REQUEST",
  DELETE_PARTNER_SUCCESS: "DELETE_PARTNER_SUCCESS",
  DELETE_PARTNER_ERROR: "DELETE_PARTNER_ERROR",

  UPDATE_PARTNER_DETAILS_REQUEST: "UPDATE_PARTNER_DETAILS_REQUEST",
  UPDATE_PARTNER_DETAILS_SUCCESS: "UPDATE_PARTNER_DETAILS_SUCCESS",
  UPDATE_PARTNER_DETAILS_ERROR: "UPDATE_PARTNER_DETAILS_ERROR",
  SET_UPDATE_PARTNER_DETAILS_DATA: "SET_UPDATE_PARTNER_DETAILS",

  CHECK_PARTNER_REQUEST: "CHECK_PARTNER_REQUEST",
  CHECK_PARTNER_SUCCESS: "CHECK_PARTNER_SUCCESS",
  CHECK_PARTNER_ERROR: "CHECK_PARTNER_ERROR",

  APPROVE_PARTNER_REQUEST: "APPROVE_PARTNER_REQUEST",
  APPROVE_PARTNER_SUCCESS: "APPROVE_PARTNER_SUCCESS",
  APPROVE_PARTNER_ERROR: "APPROVE_PARTNER_ERROR",

  getPartners: () => ({
    type: partners.GET_PARTNERS_REQUEST,
  }),
  addPartner: (data) => ({
    type: partners.ADD_PARTNER_REQUEST,
    data,
  }),
  getPartnerDetails: (id) => ({
    type: partners.GET_PARTNER_DETAILS_REQUEST,
    id,
  }),
  deletePartner: (id, partnersArray) => ({
    type: partners.DELETE_PARTNER_REQUEST,
    id,
    partners: partnersArray,
  }),
  updatePartnerDetails: (data, id, partnersArray) => ({
    type: partners.UPDATE_PARTNER_DETAILS_REQUEST,
    id,
    data,
    partners: partnersArray,
  }),
  setUpdatePartnerDetailsData: (data) => ({
    type: partners.SET_UPDATE_PARTNER_DETAILS_DATA,
    data,
  }),
  checkPartner: (id) => ({
    type: partners.CHECK_PARTNER_REQUEST,
    id,
  }),
  approvePartner: (id) => ({
    type: partners.APPROVE_PARTNER_REQUEST,
    id,
  }),
};

export default partners;
