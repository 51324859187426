import { takeLatest, fork, put } from "redux-saga/effects";
import axios from "axios";
import { storesActions, supplierPaymentsActions } from "../../../actions";

function* getStoreItems() {
  try {
    const response = yield axios({
      url: "/stores-register/items/",
      method: "GET",
    });

    yield put({
      type: storesActions.GET_STORE_ITEMS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: storesActions.GET_STORE_ITEMS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetStoreItems() {
  yield takeLatest(storesActions.GET_STORE_ITEMS_REQUEST, getStoreItems);
}

function* addStoreItem({ data }) {
  try {
    const response = yield axios({
      url: "/stores-register/items/",
      method: "POST",
      data: data,
    });

    yield put({
      type: storesActions.ADD_STORE_ITEM_SUCCESS,
      data: response,
    });

    yield put(supplierPaymentsActions.getSupplierPaymentsScheduleMetaData()); // get the supplier payments schedule meta data that will be used in the supplier payments schedule page with the new data
  } catch (error) {
    yield put({
      type: storesActions.ADD_STORE_ITEM_ERROR,
      error: error.data,
    });
  }
}
function* watchAddStoreItem() {
  yield takeLatest(storesActions.ADD_STORE_ITEM_REQUEST, addStoreItem);
}

function* getStoreItemDetails({ id }) {
  try {
    const response = yield axios({
      url: `/stores-register/items/details/${id}`,
      method: "GET",
    });

    yield put({
      type: storesActions.GET_STORE_ITEM_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: storesActions.GET_STORE_ITEM_DETAILS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetStoreItemDetails() {
  yield takeLatest(
    storesActions.GET_STORE_ITEM_DETAILS_REQUEST,
    getStoreItemDetails
  );
}

function* updateStoreItem({ id, data }) {
  try {
    const response = yield axios({
      url: `stores-register/items/${id}`,
      method: "PUT",
      data: data,
    });

    yield put({
      type: storesActions.UPDATE_STORE_ITEM_SUCCESS,
      data: response,
    });

    yield put(supplierPaymentsActions.getSupplierPaymentsScheduleMetaData());
  } catch (error) {
    yield put({
      type: storesActions.UPDATE_STORE_ITEM_ERROR,
      error: error.data,
    });
  }
}
function* watchUpdateStoreItem() {
  yield takeLatest(storesActions.UPDATE_STORE_ITEM_REQUEST, updateStoreItem);
}

function* deleteStoreItem({ id, items }) {
  try {
    const response = yield axios({
      url: `/stores-register/items/${id}`,
      method: "DELETE",
    });

    yield put({
      type: storesActions.DELETE_STORE_ITEM_SUCCESS,
      data: response,
      items,
    });

    yield put(supplierPaymentsActions.getSupplierPaymentsScheduleMetaData());
  } catch (error) {
    yield put({
      type: storesActions.DELETE_STORE_ITEM_ERROR,
      error: error.data,
    });
  }
}
function* watchDeleteStoreItem() {
  yield takeLatest(storesActions.DELETE_STORE_ITEM_REQUEST, deleteStoreItem);
}

function* checkStoreItem({ id }) {
  try {
    const response = yield axios({
      url: `/stores-register/items/check/${id}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({
      type: storesActions.CHECK_STORE_ITEM_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: storesActions.CHECK_STORE_ITEM_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckStoreItem() {
  yield takeLatest(storesActions.CHECK_STORE_ITEM_REQUEST, checkStoreItem);
}

function* approveStoreItem({ id }) {
  try {
    const response = yield axios({
      url: `/stores-register/items/approve/${id}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: storesActions.APPROVE_STORE_ITEM_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: storesActions.APPROVE_STORE_ITEM_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveStoreItem() {
  yield takeLatest(storesActions.APPROVE_STORE_ITEM_REQUEST, approveStoreItem);
}

function* getStoreItemsMetaData() {
  try {
    const response = yield axios({
      url: "/stores-register/items/meta-data",
      method: "GET",
    });

    yield put({
      type: storesActions.GET_STORE_ITEMS_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: storesActions.GET_STORE_ITEMS_META_DATA_ERROR,
      error: error.data,
    });
  }
}
function* watchGetStoreItemsMetaData() {
  yield takeLatest(
    storesActions.GET_STORE_ITEMS_META_DATA_REQUEST,
    getStoreItemsMetaData
  );
}

function* getStoreIssueDetails({ id }) {
  try {
    const response = yield axios({
      url: `/stores-register/issues/details/${id}`,
      method: "GET",
    });

    yield put({
      type: storesActions.GET_STORE_ISSUE_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: storesActions.GET_STORE_ISSUE_DETAILS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetStoreIssueDetails() {
  yield takeLatest(
    storesActions.GET_STORE_ISSUE_DETAILS_REQUEST,
    getStoreIssueDetails
  );
}

function* addStoreIssue({ data }) {
  try {
    const response = yield axios({
      url: "/stores-register/issues/",
      method: "POST",
      data: data,
    });

    yield put({
      type: storesActions.ADD_STORE_ISSUE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: storesActions.ADD_STORE_ISSUE_ERROR,
      error: error.data,
    });
  }
}
function* watchAddStoreIssue() {
  yield takeLatest(storesActions.ADD_STORE_ISSUE_REQUEST, addStoreIssue);
}

function* updateStoreIssue({ id, data }) {
  try {
    const response = yield axios({
      url: `/stores-register/issues/${id}`,
      method: "PUT",
      data: data,
    });

    yield put({
      type: storesActions.UPDATE_STORE_ISSUE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: storesActions.UPDATE_STORE_ISSUE_ERROR,
      error: error.data,
    });
  }
}
function* watchUpdateStoreIssue() {
  yield takeLatest(storesActions.UPDATE_STORE_ISSUE_REQUEST, updateStoreIssue);
}

function* deleteStoreIssue({ id }) {
  try {
    const response = yield axios({
      url: `/stores-register/issues/${id}`,
      method: "DELETE",
    });

    yield put({
      type: storesActions.DELETE_STORE_ISSUE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: storesActions.DELETE_STORE_ISSUE_ERROR,
      error: error.data,
    });
  }
}
function* watchDeleteStoreIssue() {
  yield takeLatest(storesActions.DELETE_STORE_ISSUE_REQUEST, deleteStoreIssue);
}

function* checkStoreIssue({ id }) {
  try {
    const response = yield axios({
      url: `/stores-register/issues/check/${id}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({
      type: storesActions.CHECK_STORE_ISSUE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: storesActions.CHECK_STORE_ISSUE_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckStoreIssue() {
  yield takeLatest(storesActions.CHECK_STORE_ISSUE_REQUEST, checkStoreIssue);
}

function* approveStoreIssue({ id }) {
  try {
    const response = yield axios({
      url: `/stores-register/issues/approve/${id}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: storesActions.APPROVE_STORE_ISSUE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: storesActions.APPROVE_STORE_ISSUE_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveStoreIssue() {
  yield takeLatest(
    storesActions.APPROVE_STORE_ISSUE_REQUEST,
    approveStoreIssue
  );
}

function* getStoreIssuesMetaData() {
  try {
    const response = yield axios({
      url: "/stores-register/issues/meta-data",
      method: "GET",
    });

    yield put({
      type: storesActions.GET_STORE_ISSUES_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: storesActions.GET_STORE_ISSUES_META_DATA_ERROR,
      error: error.data,
    });
  }
}
function* watchGetStoreIssuesMetaData() {
  yield takeLatest(
    storesActions.GET_STORE_ISSUES_META_DATA_REQUEST,
    getStoreIssuesMetaData
  );
}

export default [
  fork(watchGetStoreItems),
  fork(watchAddStoreItem),
  fork(watchGetStoreItemDetails),
  fork(watchUpdateStoreItem),
  fork(watchDeleteStoreItem),
  fork(watchCheckStoreItem),
  fork(watchApproveStoreItem),
  fork(watchGetStoreItemsMetaData),
  fork(watchGetStoreIssueDetails),
  fork(watchAddStoreIssue),
  fork(watchUpdateStoreIssue),
  fork(watchDeleteStoreIssue),
  fork(watchCheckStoreIssue),
  fork(watchApproveStoreIssue),
  fork(watchGetStoreIssuesMetaData),
];
// Language: javascript
