const noticesOfBestBidders = {
  GET_NOTICES_OF_BEST_BIDDERS_REQUEST: "GET_NOTICES_OF_BEST_BIDDERS_REQUEST",
  GET_NOTICES_OF_BEST_BIDDERS_SUCCESS: "GET_NOTICES_OF_BEST_BIDDERS_SUCCESS",
  GET_NOTICES_OF_BEST_BIDDERS_ERROR: "GET_NOTICES_OF_BEST_BIDDERS_ERROR",

  GET_NOTICES_OF_BEST_BIDDERS_META_DATA_REQUEST:
    "GET_NOTICES_OF_BEST_BIDDERS_META_DATA_REQUEST",
  GET_NOTICES_OF_BEST_BIDDERS_META_DATA_SUCCESS:
    "GET_NOTICES_OF_BEST_BIDDERS_META_DATA_SUCCESS",

  SET_ADD_NOTICE_OF_BEST_BIDDER_DATA: "SET_ADD_NOTICE_OF_BEST_BIDDER_DATA",
  ADD_NOTICE_OF_BEST_BIDDER_REQUEST: "ADD_NOTICE_OF_BEST_BIDDER_REQUEST",
  ADD_NOTICE_OF_BEST_BIDDER_SUCCESS: "ADD_NOTICE_OF_BEST_BIDDER_SUCCESS",
  ADD_NOTICE_OF_BEST_BIDDER_ERROR: "ADD_NOTICE_OF_BEST_BIDDER_ERROR",

  DELETE_NOTICE_OF_BEST_BIDDER_REQUEST: "DELETE_NOTICE_OF_BEST_BIDDER_REQUEST",
  DELETE_NOTICE_OF_BEST_BIDDER_SUCCESS: "DELETE_NOTICE_OF_BEST_BIDDER_SUCCESS",
  DELETE_NOTICE_OF_BEST_BIDDER_ERROR: "DELETE_NOTICE_OF_BEST_BIDDER_ERROR",

  SET_UPDATE_NOTICE_OF_BEST_BIDDER_UPDATE_DATA:
    "SET_UPDATE_NOTICE_OF_BEST_BIDDER_UPDATE_DATA",
  UPDATE_NOTICE_OF_BEST_BIDDER_REQUEST: "UPDATE_NOTICE_OF_BEST_BIDDER_REQUEST",
  UPDATE_NOTICE_OF_BEST_BIDDER_SUCCESS: "UPDATE_NOTICE_OF_BEST_BIDDER_SUCCESS",
  UPDATE_NOTICE_OF_BEST_BIDDER_ERROR: "UPDATE_NOTICE_OF_BEST_BIDDER_ERROR",

  GET_NOTICE_OF_BEST_BIDDER_DETAILS_REQUEST:
    "GET_NOTICE_OF_BEST_BIDDER_DETAILS_REQUEST",
  GET_NOTICE_OF_BEST_BIDDER_DETAILS_SUCCESS:
    "GET_NOTICE_OF_BEST_BIDDER_DETAILS_SUCCESS",
  GET_NOTICE_OF_BEST_BIDDER_DETAILS_ERROR:
    "GET_NOTICE_OF_BEST_BIDDER_DETAILS_ERROR",

  APPROVE_NOTICE_OF_BEST_BIDDER_REQUEST:
    "APPROVE_NOTICE_OF_BEST_BIDDER_REQUEST",
  APPROVE_NOTICE_OF_BEST_BIDDER_SUCCESS:
    "APPROVE_NOTICE_OF_BEST_BIDDER_SUCCESS",
  APPROVE_NOTICE_OF_BEST_BIDDER_ERROR: "APPROVE_NOTICE_OF_BEST_BIDDER_ERROR",

  CHECK_NOTICE_OF_BEST_BIDDER_REQUEST: "CHECK_NOTICE_OF_BEST_BIDDER_REQUEST",
  CHECK_NOTICE_OF_BEST_BIDDER_SUCCESS: "CHECK_NOTICE_OF_BEST_BIDDER_SUCCESS",
  CHECK_NOTICE_OF_BEST_BIDDER_ERROR: "CHECK_NOTICE_OF_BEST_BIDDER_ERROR",

  getNoticesOfBestBidders: () => ({
    type: noticesOfBestBidders.GET_NOTICES_OF_BEST_BIDDERS_REQUEST,
  }),
  getMetaData: () => ({
    type: noticesOfBestBidders.GET_NOTICES_OF_BEST_BIDDERS_META_DATA_REQUEST,
  }),
  setAddNoticeOfBestBidderData: (data) => ({
    type: noticesOfBestBidders.SET_ADD_NOTICE_OF_BEST_BIDDER_DATA,
    data,
  }),
  addNoticeOfBestBidder: (data) => ({
    type: noticesOfBestBidders.ADD_NOTICE_OF_BEST_BIDDER_REQUEST,
    data,
  }),
  deleteNoticeOfBestBidder: (data) => ({
    type: noticesOfBestBidders.DELETE_NOTICE_OF_BEST_BIDDER_REQUEST,
    data,
  }),
  updateNoticeOfBestBidder: (data, id) => ({
    type: noticesOfBestBidders.UPDATE_NOTICE_OF_BEST_BIDDER_REQUEST,
    data,
    id,
  }),
  setUpdateNoticeOfBestBidderData: (data) => ({
    type: noticesOfBestBidders.SET_UPDATE_NOTICE_OF_BEST_BIDDER_UPDATE_DATA,
    data,
  }),
  getNoticeOfBestBidderDetails: (data) => ({
    type: noticesOfBestBidders.GET_NOTICE_OF_BEST_BIDDER_DETAILS_REQUEST,
    data,
  }),
  checkNoticeOfBestBidder: (data) => ({
    type: noticesOfBestBidders.CHECK_NOTICE_OF_BEST_BIDDER_REQUEST,
    data,
  }),
  approveNoticeOfBestBidder: (data) => ({
    type: noticesOfBestBidders.APPROVE_NOTICE_OF_BEST_BIDDER_REQUEST,
    data,
  }),
};

export default noticesOfBestBidders;
