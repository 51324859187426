const schoolCommunicationsInitialState = {
  schoolCommunications: {
    schoolCommunications: {},
    schoolCommunicationsLoading: false,
    schoolCommunicationsError: false,

    addSchoolCommunication: {},
    addSchoolCommunicationLoading: false,
    addGeneralSchoolCommunication: false,

    metaData: {},
    metaDataLoading: false,
    metaDataError: {},

    addGeneralCommunication: {},
    addGeneralCommunicationLoading: false,
    addGeneralCommunicationError: {},

    schoolCommunicationDetails: {},
    schoolCommunicationDetailsLoading: false,
    schoolCommunicationDetailsError: {},

    sendDefaulterCommunication: {},
    sendDefaulterCommunicationLoading: false,
    sendDefaulterCommunicationError: {},
  },
};

export default schoolCommunicationsInitialState;
