const organisations = {
  GET_ORGANISATIONS_REQUEST: "GET_ORGANISATIONS_REQUEST",
  GET_ORGANISATIONS_SUCCESS: "GET_ORGANISATIONS_SUCCESS",
  GET_ORGANISATIONS_ERROR: "GET_ORGANISATIONS_ERROR",

  ADD_ORGANISATION_REQUEST: "ADD_ORGANISATION_REQUEST",
  ADD_ORGANISATION_SUCCESS: "ADD_ORGANISATION_SUCCESS",
  ADD_ORGANISATION_ERROR: "ADD_ORGANISATION_ERROR",

  UPDATE_ORGANISATION_NAME_REQUEST: "UPDATE_ORGANISATION_NAME_REQUEST",
  UPDATE_ORGANISATION_NAME_SUCCESS: "UPDATE_ORGANISATION_NAME_SUCCESS",
  UPDATE_ORGANISATION_NAME_ERROR: "UPDATE_ORGANISATION_NAME_ERROR",
  SET_UPDATE_ORGANISATION_NAME_DATA: "SET_UPDATE_ORGANISATION_NAME_DATA",

  getOrganisationsRequest: () => ({
    type: organisations.GET_ORGANISATIONS_REQUEST,
  }),
  addOrganisationRequest: (data) => ({
    type: organisations.ADD_ORGANISATION_REQUEST,
    data,
  }),
  updateOrganisationName: (data, id) => ({
    type: organisations.UPDATE_ORGANISATION_NAME_REQUEST,
    data,
    id,
  }),
  setUpdateOrganisationNameData: (data) => ({
    type: organisations.SET_UPDATE_ORGANISATION_NAME_DATA,
    data,
  }),
};

export default organisations;
