const enrollment = {
  GET_ENROLLMENTS_REQUEST: "GET_ENROLLMENTS_REQUEST",
  GET_ENROLLMENTS_SUCCESS: "GET_ENROLLMENTS_SUCCESS",
  GET_ENROLLMENTS_ERROR: "GET_ENROLLMENTS_ERROR",

  GET_ENROLLMENT_DETAILS_REQUEST: "GET_ENROLLMENT_DETAILS_REQUEST",
  GET_ENROLLMENT_DETAILS_SUCCESS: "GET_ENROLLMENT_DETAILS_SUCCESS",
  GET_ENROLLMENT_DETAILS_ERROR: "GET_ENROLLMENT_DETAILS_ERROR",

  GET_ENROLLMENT_META_DATA_REQUEST: "GET_ENROLLMENT_META_DATA_REQUEST",
  GET_ENROLLMENT_META_DATA_SUCCESS: "GET_ENROLLMENT_META_DATA_SUCCESS",
  GET_ENROLLMENT_META_DATA_ERROR: "GET_ENROLLMENT_META_DATA_ERROR",

  ADD_ENROLLMENT_REQUEST: "ADD_ENROLLMENT_REQUEST",
  ADD_ENROLLMENT_SUCCESS: "ADD_ENROLLMENT_SUCCESS",
  ADD_ENROLLMENT_ERROR: "ADD_ENROLLMENT_ERROR",

  ENROLL_STUDENTS_REQUEST: "ENROLL_STUDENTS_REQUEST",
  ENROLL_STUDENTS_SUCCESS: "ENROLL_STUDENTS_SUCCESS",
  ENROLL_STUDENTS_ERROR: "ENROLL_STUDENTS_ERROR",

  DELETE_ENROLLMENT_REQUEST: "DELETE_ENROLLMENT_REQUEST",
  DELETE_ENROLLMENT_SUCCESS: "DELETE_ENROLLMENT_SUCCESS",
  DELETE_ENROLLMENT_ERROR: "DELETE_ENROLLMENT_ERROR",

  UNENROLL_STUDENT_REQUEST: "UNENROLL_STUDENT_REQUEST",
  UNENROLL_STUDENT_SUCCESS: "UNENROLL_STUDENT_SUCCESS",
  UNENROLL_STUDENT_ERROR: "UNENROLL_STUDENT_ERROR",

  UPLOAD_ENROLLMENT_DOCUMENT_REQUEST: "UPLOAD_ENROLLMENT_DOCUMENT_REQUEST",
  UPLOAD_ENROLLMENT_DOCUMENT_SUCCESS: "UPLOAD_ENROLLMENT_DOCUMENT_SUCCESS",
  UPLOAD_ENROLLMENT_DOCUMENT_ERROR: "UPLOAD_ENROLLMENT_DOCUMENT_ERROR",

  CREATE_STUDENT_BILLING_REQUEST: "CREATE_STUDENT_BILLING_REQUEST",
  CREATE_STUDENT_BILLING_SUCCESS: "CREATE_STUDENT_BILLING_SUCCESS",
  CREATE_STUDENT_BILLING_ERROR: "CREATE_STUDENT_BILLING_ERROR",

  DELETE_STUDENT_BILLING_REQUEST: "DELETE_STUDENT_BILLING_REQUEST",
  DELETE_STUDENT_BILLING_SUCCESS: "DELETE_STUDENT_BILLING_SUCCESS",
  DELETE_STUDENT_BILLING_ERROR: "DELETE_STUDENT_BILLING_ERROR",

  UPDATE_ENROLLMENT_REQUEST: "UPDATE_ENROLLMENT_REQUEST",
  UPDATE_ENROLLMENT_SUCCESS: "UPDATE_ENROLLMENT_SUCCESS",
  UPDATE_ENROLLMENT_ERROR: "UPDATE_ENROLLMENT_ERROR",

  SET_UPDATE_ENROLLMENT_DATA: "SET_UPDATE_ENROLLMENT_DATA",

  ENROLL_SINGLE_STUDENT_REQUEST: "ENROLL_SINGLE_STUDENT_REQUEST",
  ENROLL_SINGLE_STUDENT_SUCCESS: "ENROLL_SINGLE_STUDENT_SUCCESS",
  ENROLL_SINGLE_STUDENT_ERROR: "ENROLL_SINGLE_STUDENT_ERROR",

  SEARCH_STUDENTS_REQUEST: "SEARCH_STUDENTS_REQUEST",
  SEARCH_STUDENTS_SUCCESS: "SEARCH_STUDENTS_SUCCESS",
  SEARCH_STUDENTS_ERROR: "SEARCH_STUDENTS_ERROR",

  DE_ENROLL_LEARNER_REQUEST: "DE_ENROLL_LEARNER_REQUEST",
  DE_ENROLL_LEARNER_SUCCESS: "DE_ENROLL_LEARNER_SUCCESS",
  DE_ENROLL_LEARNER_ERROR: "DE_ENROLL_LEARNER_ERROR",

  DOWNLOAD_LEARNER_DETAILS_EXCEL_REQUEST:
    "DOWNLOAD_LEARNER_DETAILS_EXCEL_REQUEST",
  DOWNLOAD_LEARNER_DETAILS_EXCEL_SUCCESS:
    "DOWNLOAD_LEARNER_DETAILS_EXCEL_SUCCESS",
  DOWNLOAD_LEARNER_DETAILS_EXCEL_ERROR: "DOWNLOAD_LEARNER_DETAILS_EXCEL_ERROR",

  JOIN_CLASSES_REQUEST: "JOIN_CLASSES_REQUEST",
  JOIN_CLASSES_SUCCESS: "JOIN_CLASSES_SUCCESS",
  JOIN_CLASSES_ERROR: "JOIN_CLASSES_ERROR",

  SET_ENROLLMENT_ACADEMIC_YEAR: "SET_ENROLLMENT_ACADEMIC_YEAR",

  GET_LEARNER_PROFILE_REQUEST: "GET_LEARNER_PROFILE_REQUEST",
  GET_LEARNER_PROFILE_SUCCESS: "GET_LEARNER_PROFILE_SUCCESS",
  GET_LEARNER_PROFILE_ERROR: "GET_LEARNER_PROFILE_ERROR",

  getEnrollments: () => ({
    type: enrollment.GET_ENROLLMENTS_REQUEST,
  }),
  joinClasses: (data) => ({
    type: enrollment.JOIN_CLASSES_REQUEST,
    data,
  }),
  setEnrollmentAcademicYear: (academicYear) => ({
    type: enrollment.SET_ENROLLMENT_ACADEMIC_YEAR,
    academicYear,
  }),
  downloadLearnerDetailsExcel: (enrollmentDetails) => ({
    type: enrollment.DOWNLOAD_LEARNER_DETAILS_EXCEL_REQUEST,
    enrollment: enrollmentDetails,
  }),
  deEnrollLearner: (id, data, enrollmentId) => ({
    type: enrollment.DE_ENROLL_LEARNER_REQUEST,
    id,
    enrollmentId,
    data,
  }),
  createStudentBilling: (data) => ({
    type: enrollment.CREATE_STUDENT_BILLING_REQUEST,
    data,
  }),
  deleteStudentBilling: (id) => ({
    type: enrollment.DELETE_STUDENT_BILLING_REQUEST,
    id,
  }),
  addEnrollmet: (data) => ({
    type: enrollment.ADD_ENROLLMENT_REQUEST,
    data,
  }),
  enrollStudents: (data) => ({
    type: enrollment.ENROLL_STUDENTS_REQUEST,
    data,
  }),
  getEnrollmentDetails: (id) => ({
    type: enrollment.GET_ENROLLMENT_DETAILS_REQUEST,
    id,
  }),
  getEnrollmentMetaData: () => ({
    type: enrollment.GET_ENROLLMENT_META_DATA_REQUEST,
  }),
  deleteEnrollment: (id, enrollmentsArray) => ({
    type: enrollment.DELETE_ENROLLMENT_REQUEST,
    id,
    enrollment: enrollmentsArray,
  }),
  unenrollStudent: (id) => ({
    type: enrollment.UNENROLL_STUDENT_REQUEST,
    id,
  }),
  uploadEnrollmentDocument: (data) => ({
    type: enrollment.UPLOAD_ENROLLMENT_DOCUMENT_REQUEST,
    data,
  }),
  updateEnrollment: (id, data) => ({
    type: enrollment.UPDATE_ENROLLMENT_REQUEST,
    data,
    id,
  }),
  setUpdateEnrollmentData: (data) => ({
    type: enrollment.SET_UPDATE_ENROLLMENT_DATA,
    data,
  }),
  enrollSingleStudent: (data) => ({
    type: enrollment.ENROLL_SINGLE_STUDENT_REQUEST,
    data,
  }),
  searchStudents: (data) => ({
    type: enrollment.SEARCH_STUDENTS_REQUEST,
    data,
  }),
  getLearnerProfile: (id) => ({
    type: enrollment.GET_LEARNER_PROFILE_REQUEST,
    id,
  }),
};

export default enrollment;
