const organisationDetailsInitialState = {
  organisationDetails: {
    organisationDetailsLoading: false,
    organisationDetailsSuccess: {},
    organisationDetailsError: {},

    addOrganisationDetailsLoading: false,
    addOrganisationDetailsSuccess: {},
    addOrganisationDetailsError: {},

    updateOrganisationDetailsLoading: false,
    updateOrganisationDetailsSuccess: {},
    updateOrganisationDetailsError: {},

    uploadOrganisationLogoLoading: false,
    uploadOrganisationLogoSuccess: {},
    uploadOrganisationLogoError: {},
  },
};

export default organisationDetailsInitialState;
