const studentReportsInitialState = {
  studentReports: {
    metaData: {},
    metaDataLoading: false,
    metaDataError: {},

    reportData: {},

    studentReportsLoading: false,
    studentReportsSuccess: {},
    studentReportsError: {},

    addStudentReportsSuccess: {},
    addStudentReportsError: {},
    addStudentReportsLoading: false,

    updateStudentReportsSuccess: {},
    updateStudentReportsError: {},
    updateStudentReportsLoading: false,
    updateStudentReportsData: {},

    deleteStudentReportsSuccess: {},
    deleteStudentReportsError: {},
    deleteStudentReportsLoading: false,

    deleteReportExamSuccess: {},
    deleteReportExamLoading: false,
    deleteReportExamError: {},

    studentReportDetailsSuccess: {},
    studentReportDetailsError: {},
    studentReportDetailsLoading: false,

    promoteLearners: {},
    promoteLearnersLoading: false,
    promoteLearnersError: {},

    commentType: "Automated",

    addReportCommentsSuccess: {},
    addReportCommentsError: {},
    addReportCommentsLoading: false,

    updateReportCommentsSuccess: {},
    updateReportCommentsError: {},
    updateReportCommentsLoading: false,

    deleteReportCommentsSuccess: {},
    deleteReportCommentsError: {},
    deleteReportCommentsLoading: false,

    reportComments: {},

    learnerBillingMetaDataLoading: false,
    learnerBillingMetaData: {},
    learnerBillingMetaDataError: {},

    learnerBillingLoading: false,
    learnerBillingSuccess: {},
    learnerBillingError: {},

    createReportExceptionSuccess: {},
    createReportExceptionError: {},
    createReportExceptionLoading: false,

    reportExceptionsSuccess: {},
    reportExceptionsError: {},
    reportExceptionsLoading: false,

    updateReportExceptionSuccess: {},
    updateReportExceptionError: {},
    updateReportExceptionLoading: false,
    setReportExceptionData: {},

    deleteReportExceptionSuccess: {},
    deleteReportExceptionError: {},
    deleteReportExceptionLoading: false,
  },
};

export default studentReportsInitialState;
