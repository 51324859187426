import { takeLatest, put, fork, takeEvery } from "@redux-saga/core/effects";
import axios from "axios";
import { appUiActions, rolesActions } from "../../../actions";
import { getLocalStorageItem } from "../../../services/storage.service";

function* getRoles() {
  try {
    const organisation = getLocalStorageItem("organisation");

    const response = yield axios({
      url: `/organisations/roles/organsation-roles?organisation=${organisation}`,
      method: "GET",
    });

    yield put({ type: rolesActions.GET_ROLES_SUCCESS, data: response });
  } catch (error) {
    yield put({
      type: rolesActions.GET_ROLES_ERROR,
      error: error.data,
    });
  }
}

function* watchGetRoles() {
  yield takeLatest(rolesActions.GET_ROLES_REQUEST, getRoles);
}

function* addRole(actions) {
  try {
    const response = yield axios({
      url: "/organisations/roles",
      data: actions.data,
      method: "POST",
    });

    yield put({
      type: rolesActions.ADD_ROLES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: rolesActions.ADD_ROLES_ERROR,
      error: error.data,
    });
  }
}

function* watchAddRole() {
  yield takeLatest(rolesActions.ADD_ROLES_REQUEST, addRole);
}

function* deleteRole({ data }) {
  try {
    const response = yield axios({
      url: `/organisations/roles/${data.id}`,
      method: "DELETE",
    });

    yield put({
      type: rolesActions.DELETE_ROLES_SUCCESS,
      roles: data.roles,
      data: response,
    });
  } catch (error) {
    yield put({
      type: rolesActions.DELETE_ROLES_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteRole() {
  yield takeEvery(rolesActions.DELETE_ROLES_REQUEST, deleteRole);
}

function* updateRole({ data, rolesArray, id }) {
  try {
    const response = yield axios({
      url: `/organisations/roles/${id}`,
      method: "PUT",
      data,
    });
    yield put({
      type: rolesActions.UPDATE_ROLE_SUCCESS,
      roles: rolesArray,
      data: response,
    });
    yield put({
      type: appUiActions.TOGGLE_UPDATE_ROLES_MODAL,
      data: false,
    });
  } catch (error) {
    yield put({
      type: rolesActions.UPDATE_ROLE_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateRole() {
  yield takeLatest(rolesActions.UPDATE_ROLE_REQUEST, updateRole);
}

function* getRoleDetails({ data }) {
  try {
    const response = yield axios({ url: `/organisations/roles/${data}` });

    yield put({ type: rolesActions.GET_ROLES_DETAILS_SUCCESS, data: response });
  } catch (error) {
    yield put({
      type: rolesActions.GET_ROLES_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetRoleDetails() {
  yield takeLatest(rolesActions.GET_ROLES_DETAILS_REQUEST, getRoleDetails);
}

export default [
  fork(watchGetRoles),
  fork(watchAddRole),
  fork(watchDeleteRole),
  fork(watchUpdateRole),
  fork(watchGetRoleDetails),
];
