import { learnersActions } from "../../../actions";
import learnersInitialState from "../../../initialState/administration/learners/learners.initialState";

const learners = (state = learnersInitialState, actions) => {
  switch (actions.type) {
    case learnersActions.GET_LEARNERS_REQUEST:
      return {
        ...state,
        learnersLoading: true,
      };
    case learnersActions.GET_LEARNERS_SUCCESS:
      return {
        ...state,
        learnersSuccess: actions.data,
        learnersError: {},
        learnersLoading: false,
      };
    case learnersActions.GET_LEARNERS_ERROR:
      return {
        ...state,
        learnersLoading: false,
        learnersSuccess: actions.error,
      };
    case learnersActions.ADD_LEARNER_REQUEST:
      return {
        ...state,
        addLearnerLoading: true,
      };
    case learnersActions.ADD_LEARNER_SUCCESS:
      return {
        ...state,
        addlearnerSuccess: actions.data,
        addLearnerError: {},
        addLearnerLoading: false,
      };
    case learnersActions.ADD_LEARNER_ERROR:
      return {
        ...state,
        addLearnerLoading: false,
        addlearnerSuccess: actions.error,
      };
    case learnersActions.GET_LEARNER_DETAILS_REQUEST:
      return {
        ...state,
        getLearnerDetailsLoading: true,
      };
    case learnersActions.GET_LEARNER_DETAILS_SUCCESS:
      return {
        ...state,
        getLearnerDetailsSuccess: actions.data,
        getLearnerDetailsError: {},
        getLearnerDetailsLoading: false,
      };
    case learnersActions.GET_LEARNER_DETAILS_ERROR:
      return {
        ...state,
        getLearnerDetailsLoading: false,
        getLearnerDetailsSuccess: actions.error,
      };
    case learnersActions.DELETE_LEARNER_REQUEST:
      return {
        ...state,
        deleteLearnerLoading: true,
        deleteLearnerError: {},
      };
    case learnersActions.DELETE_LEARNER_SUCCESS:
      return {
        ...state,
        deleteLearneruccess: actions.data,
        deleteLearnerLoading: false,
      };
    case learnersActions.DELETE_LEARNER_ERROR:
      return {
        ...state,
        deleteLearnerLoading: false,
        deletelearnersuccess: actions.error,
      };
    case learnersActions.UPDATE_LEARNER_DETAILS_REQUEST:
      return {
        ...state,
        updateLearnerDetailsLoading: true,
      };
    case learnersActions.UPDATE_LEARNER_DETAILS_SUCCESS:
      return {
        ...state,
        updateLearnerDetailsSuccess: actions.data,
        updateLearnerDetailsError: {},
        updateLearnerDetailsLoading: false,
      };
    case learnersActions.UPDATE_LEARNER_DETAILS_ERROR:
      return {
        ...state,
        updateLearnerDetailsLoading: false,
        updateLearnerDetailsSuccess: actions.error,
      };
    case learnersActions.SET_UPDATE_LEARNER_DETAILS_DATA:
      return {
        ...state,
        updateLearnerDetailsData: actions.data,
      };
    case learnersActions.GET_LEARNERS_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case learnersActions.GET_LEARNERS_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: actions.data,
        metaDataLoading: false,
      };
    case learnersActions.ADD_LEARNER_PROFILE_REQUEST:
      return {
        ...state,
        addLearnerProfileLoading: true,
      };
    case learnersActions.ADD_LEARNER_PROFILE_SUCCESS:
      return {
        ...state,
        addLearnerProfileSuccess: actions.data,
        addLearnerProfileError: {},
        addLearnerProfileLoading: false,
      };
    case learnersActions.ADD_LEARNER_PROFILE_ERROR:
      return {
        ...state,
        addLearnerProfileLoading: false,
        addLearnerProfileSuccess: actions.error,
      };
    case learnersActions.UPDATE_LEARNER_PROFILE_REQUEST:
      return {
        ...state,
        updateLearnerProfileLoading: true,
      };
    case learnersActions.UPDATE_LEARNER_PROFILE_SUCCESS:
      return {
        ...state,
        updateLearnerProfileSuccess: actions.data,
        updateLearnerProfileError: {},
        updateLearnerProfileLoading: false,
      };
    case learnersActions.UPDATE_LEARNER_PROFILE_ERROR:
      return {
        ...state,
        updateLearnerProfileLoading: false,
        updateLearnerProfileSuccess: actions.error,
      };
    case learnersActions.LEARNER_PROFILE_DATA:
      return {
        ...state,
        learnerProfileData: actions.data,
      };
    case learnersActions.UPLOAD_LEARNER_DOCUMENTS_REQUEST:
      return {
        ...state,
        uploadLearnerDocumentsLoading: true,
      };
    case learnersActions.UPLOAD_LEARNER_DOCUMENTS_SUCCESS:
      return {
        ...state,
        uploadLearnerDocumentsSuccess: actions.data,
        uploadLearnerDocumentsError: {},
        uploadLearnerDocumentsLoading: false,
      };
    case learnersActions.UPLOAD_LEARNER_DOCUMENTS_ERROR:
      return {
        ...state,
        uploadLearnerDocumentsLoading: false,
        uploadLearnerDocumentsSuccess: actions.error,
      };
    case learnersActions.UPLOAD_LEARNER_IMAGE_REQUEST:
      return {
        ...state,
        uploadLearnerImageLoading: true,
      };
    case learnersActions.UPLOAD_LEARNER_IMAGE_SUCCESS:
      return {
        ...state,
        uploadLearnerImageSuccess: actions.data,
        uploadLearnerImageError: {},
        uploadLearnerImageLoading: false,
      };
    case learnersActions.UPLOAD_LEARNER_IMAGE_ERROR:
      return {
        ...state,
        uploadLearnerImageLoading: false,
        uploadLearnerImageSuccess: actions.error,
      };
    case learnersActions.DELETE_LEARNER_DOCUMENT_REQUEST:
      return {
        ...state,
        deleteLearnerDocumentLoading: true,
      };
    case learnersActions.DELETE_LEARNER_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleteLearnerDocumentSuccess: actions.data,
        deleteLearnerDocumentError: {},
        deleteLearnerDocumentLoading: false,
      };
    case learnersActions.DELETE_LEARNER_DOCUMENT_ERROR:
      return {
        ...state,
        deleteLearnerDocumentLoading: false,
        deleteLearnerDocumentSuccess: actions.error,
      };
    default:
      return state;
  }
};
export default learners;
