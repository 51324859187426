const paymentVouchers = {
  GET_PAYMENT_VOUCHERS_REQUEST: "GET_PAYMENT_VOUCHERS_REQUEST",
  GET_PAYMENT_VOUCHERS_SUCCESS: "GET_PAYMENT_VOUCHERS_SUCCESS",
  GET_PAYMENT_VOUCHERS_ERROR: "GET_PAYMENT_VOUCHERS_ERROR",

  ADD_PAYMENT_VOUCHER_REQUEST: "ADD_PAYMENT_VOUCHER_REQUEST",
  ADD_PAYMENT_VOUCHER_SUCCESS: "ADD_PAYMENT_VOUCHER_SUCCESS",
  ADD_PAYMENT_VOUCHER_ERROR: "ADD_PAYMENT_VOUCHER_ERROR",

  GET_PAYMENT_VOUCHER_DETAILS_REQUEST: "GET_PAYMENT_VOUCHER_DETAILS_REQUEST",
  GET_PAYMENT_VOUCHER_DETAILS_SUCCESS: "GET_PAYMENT_VOUCHER_DETAILS_SUCCESS",
  GET_PAYMENT_VOUCHER_DETAILS_ERROR: "GET_PAYMENT_VOUCHER_DETAILS_ERROR",

  DELETE_PAYMENT_VOUCHER_REQUEST: "DELETE_PAYMENT_VOUCHER_REQUEST",
  DELETE_PAYMENT_VOUCHER_SUCCESS: "DELETE_PAYMENT_VOUCHER_SUCCESS",
  DELETE_PAYMENT_VOUCHER_ERROR: "DELETE_PAYMENT_VOUCHER_ERROR",

  UPDATE_PAYMENT_VOUCHER_REQUEST: "UPDATE_PAYMENT_VOUCHER_REQUEST",
  UPDATE_PAYMENT_VOUCHER_SUCCESS: "UPDATE_PAYMENT_VOUCHER_SUCCESS",
  UPDATE_PAYMENT_VOUCHER_ERROR: "UPDATE_PAYMENT_VOUCHER_ERROR",

  SET_UPDATE_PAYMENT_VOUCHER_DETAILS: "SET_UPDATE_PAYMENT_VOUCHER_DETAILS",

  CHECK_PAYMENT_VOUCHER_REQUEST: "CHECK_PAYMENT_VOUCHER_REQUEST",
  CHECK_PAYMENT_VOUCHER_SUCCESS: "CHECK_PAYMENT_VOUCHER_SUCCESS",
  CHECK_PAYMENT_VOUCHER_ERROR: "CHECK_PAYMENT_VOUCHER_ERROR",

  APPROVE_PAYMENT_VOUCHER_REQUEST: "APPROVE_PAYMENT_VOUCHER_REQUEST",
  APPROVE_PAYMENT_VOUCHER_SUCCESS: "APPROVE_PAYMENT_VOUCHER_SUCCESS",
  APPROVE_PAYMENT_VOUCHER_ERROR: "APPROVE_PAYMENT_VOUCHER_ERROR",

  GET_PAYMENT_VOUCHERS_META_DATA_REQUEST:
    "GET_PAYMENT_VOUCHERS_META_DATA_REQUEST",
  GET_PAYMENT_VOUCHERS_META_DATA_SUCCESS:
    "GET_PAYMENT_VOUCHERS_META_DATA_SUCCESS",
  GET_PAYMENT_VOUCHERS_META_DATA_ERROR: "GET_PAYMENT_VOUCHERS_META_DATA_ERROR",

  SEARCH_PAYMENT_VOUCHERS_REQUEST: "SEARCH_PAYMENT_VOUCHERS_REQUEST",
  SEARCH_PAYMENT_VOUCHERS_SUCCESS: "SEARCH_PAYMENT_VOUCHERS_SUCCESS",
  SEARCH_PAYMENT_VOUCHERS_ERROR: "SEARCH_PAYMENT_VOUCHERS_ERROR",

  SORT_PAYMENT_VOUCHERS_REQUEST: "SORT_PAYMENT_VOUCHERS_REQUEST",
  SORT_PAYMENT_VOUCHERS_SUCCESS: "SORT_PAYMENT_VOUCHERS_SUCCESS",
  SORT_PAYMENT_VOUCHERS_ERROR: "SORT_PAYMENT_VOUCHERS_ERROR",

  DOWNLOAD_PAYMENT_VOUCHERS_REQUEST: "DOWNLOAD_PAYMENT_VOUCHERS_REQUEST",
  DOWNLOAD_PAYMENT_VOUCHERS_SUCCESS: "DOWNLOAD_PAYMENT_VOUCHERS_SUCCESS",
  DOWNLOAD_PAYMENT_VOUCHERS_ERROR: "DOWNLOAD_PAYMENT_VOUCHERS_ERROR",

  SET_SORT_QUERY: "SET_SORT_QUERY",

  getPaymentVouchers: () => ({
    type: paymentVouchers.GET_PAYMENT_VOUCHERS_REQUEST,
  }),

  addPaymentVoucher: (data) => ({
    type: paymentVouchers.ADD_PAYMENT_VOUCHER_REQUEST,
    data,
  }),

  getPaymentVoucherDetails: (id) => ({
    type: paymentVouchers.GET_PAYMENT_VOUCHER_DETAILS_REQUEST,
    id,
  }),

  deletePaymentVoucher: (id, paymentVouchersArray) => ({
    type: paymentVouchers.DELETE_PAYMENT_VOUCHER_REQUEST,
    id,
    paymentVouchers: paymentVouchersArray,
  }),

  updatePaymentVoucher: (id, data, paymentVouchersArray) => ({
    type: paymentVouchers.UPDATE_PAYMENT_VOUCHER_REQUEST,
    id,
    data,
    paymentVouchers: paymentVouchersArray,
  }),

  setUpdatePaymentVoucherDetails: (data) => ({
    type: paymentVouchers.SET_UPDATE_PAYMENT_VOUCHER_DETAILS,
    data,
  }),

  checkPaymentVoucher: (id) => ({
    type: paymentVouchers.CHECK_PAYMENT_VOUCHER_REQUEST,
    id,
  }),

  approvePaymentVoucher: (id) => ({
    type: paymentVouchers.APPROVE_PAYMENT_VOUCHER_REQUEST,
    id,
  }),

  getPaymentVouchersMetaData: () => ({
    type: paymentVouchers.GET_PAYMENT_VOUCHERS_META_DATA_REQUEST,
  }),

  searchPaymentVouchers: (data) => ({
    type: paymentVouchers.SEARCH_PAYMENT_VOUCHERS_REQUEST,
    data,
  }),
  sortPaymentVouchers: (data) => ({
    type: paymentVouchers.SORT_PAYMENT_VOUCHERS_REQUEST,
    data,
  }),
  downloadPaymentVouchers: (data) => ({
    type: paymentVouchers.DOWNLOAD_PAYMENT_VOUCHERS_REQUEST,
    data,
  }),
  setSortQuery: (data) => ({
    type: paymentVouchers.SET_SORT_QUERY,
    data,
  }),
};

export default paymentVouchers;
