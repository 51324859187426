import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { rejectionsActions } from "../../actions";

function* addRejection({ data }) {
  try {
    const response = yield axios({ url: "/rejections", method: "POST", data });
    yield put({
      type: rejectionsActions.ADD_REJECTIONS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: rejectionsActions.ADD_REJECTIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchAddRejection() {
  yield takeLatest(rejectionsActions.ADD_REJECTIONS_REQUEST, addRejection);
}

export default [fork(watchAddRejection)];
