const receivablesInitialState = {
  receivables: {
    receivables: {},
    receivablesLoading: false,
    receivablesError: {},

    selectedReceivable: {},
  },
};

export default receivablesInitialState;
