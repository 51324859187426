const subscriptions = {
  GET_SUBSCRIPTION_PLANS_REQUEST: "GET_SUBSCRIPTION_PLAN_REQUEST",
  GET_SUBSCRIPTION_PLAN_SUCCESS: "GET_SUBSCRIPTION_PLAN_SUCCESS",
  GET_SUBSCRIPTION_PLAN_ERROR: "GET_SUBSCRIPTION_PLAN_ERROR",

  ADD_SUBSCRIPTION_REQUEST: "ADD_SUBSCRIPTION_REQUEST",
  ADD_SUBSCRIPTION_SUCCESS: "ADD_SUBSCRIPTION_SUCCESS",
  ADD_SUBSCRIPTION_ERROR: "ADD_SUBSCRIPTION_ERROR",

  ADD_SUBSCRIPTION_PAYMENT_REQUEST: "ADD_SUBSCRIPTION_PAYMENT_REQUEST",
  ADD_SUBSCRIPTION_PAYMENT_SUCCESS: "ADD_SUBSCRIPTION_PAYMENT_SUCCESS",
  ADD_SUBSCRIPTION_PAYMENT_ERROR: "ADD_SUBSCRIPTION_PAYMENT_ERROR",

  GET_SUBSCRIPTIONS_REQUEST: "GET_SUBSCRIPTIONS_REQUEST",
  GET_SUBSCRIPTIONS_SUCCESS: "GET_SUBSCRIPTIONS_SUCCESS",
  GET_SUBSCRIPTIONS_ERROR: "GET_SUBSCRIPTIONS_ERROR",

  GET_LATEST_SUBSCRIPTIONS_REQUEST: "GET_LATEST_SUBSCRIPTIONS_REQUEST",
  GET_LATEST_SUBSCRIPTIONS_SUCCESS: "GET_LATEST_SUBSCRIPTIONS_SUCCESS",
  GET_LATEST_SUBSCRIPTIONS_ERROR: "GET_LATEST_SUBSCRIPTIONS_ERROR",

  CANCEL_PENDING_SUBSCRIPTION_REQUEST: "CAN_PENDING_SUBSCRIPTION_REQUEST",
  CANCEL_PENDING_SUBSCRIPTION_SUCCESS: "CAN_PENDING_SUBSCRIPTION_SUCCESS",
  CANCEL_PENDING_SUBSCRIPTION_ERROR: "CAN_PENDING_SUBSCRIPTION_ERROR",

  SET_SUBSCRIPTION_PAYMENT_DETAILS: "SET_SUBSCRIPTION_PAYMENT_DETAILS",

  getSubscriptionPlanRequest: () => ({
    type: subscriptions.GET_SUBSCRIPTION_PLANS_REQUEST,
  }),
  addSubscriptionRequest: (data) => ({
    type: subscriptions.ADD_SUBSCRIPTION_REQUEST,
    data,
  }),
  getSubscriptionsRequest: (data) => ({
    type: subscriptions.GET_SUBSCRIPTIONS_REQUEST,
    data,
  }),
  setSubscriptionPaymentDetails: (data) => ({
    type: subscriptions.SET_SUBSCRIPTION_PAYMENT_DETAILS,
    data,
  }),
  addSubscriptionsPayment: (data) => ({
    type: subscriptions.ADD_SUBSCRIPTION_PAYMENT_REQUEST,
    data,
  }),
  getLatestSubscription: (data) => ({
    type: subscriptions.GET_LATEST_SUBSCRIPTIONS_REQUEST,
    data,
  }),
  cancelPendingSubcription: (data) => ({
    type: subscriptions.CANCEL_PENDING_SUBSCRIPTION_REQUEST,
    data,
  }),
};
export default subscriptions;
