import React from "react";
import AppHero from "./hero";
import AppAbout from "./about";
import AppWorks from "./works";
import AppFeature from "./features";
import AppContact from "./contact";
import AppPricing from "./pricing";
import AppFaq from "./faq";

function AppHome() {
  return (
    <div className="main">
      <AppHero />
      <AppAbout />
      <AppFeature />
      <AppWorks />
      <AppFaq />
      <AppPricing />
      <AppContact />
    </div>
  );
}

export default AppHome;
