import { ledgersActions } from "../../../actions";
import initialState from "../../../initialState";

const ledgers = (state = initialState.ledgers, actions) => {
  switch (actions.type) {
    case ledgersActions.GET_LEDGERS_REQUEST:
      return {
        ...state,
        ledgersLoading: true,
        ledgersError: {},
      };
    case ledgersActions.GET_LEDGERS_SUCCESS:
      return {
        ...state,
        ledgersSuccess: actions.data,
        ledgersLoading: false,
      };
    case ledgersActions.GET_LEDGERS_ERROR:
      return {
        ...state,
        ledgersError: actions.error,
        ledgersLoading: false,
      };

    case ledgersActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case ledgersActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataLoading: false,
        metaData: actions.data,
      };
    case ledgersActions.ADD_LEDGERS_REQUEST:
      return {
        ...state,
        addLedgersLoading: true,
        addLedgersError: {},
      };
    case ledgersActions.ADD_LEDGERS_SUCCESS:
      return {
        ...state,
        addLedgersLoading: false,
        addLedgersSuccess: actions.data,
      };
    case ledgersActions.ADD_LEDGERS_ERROR:
      return {
        ...state,
        addLedgersLoading: false,
        addLedgersError: actions.error,
      };
    case ledgersActions.UPDATE_LEDGERS_REQUEST:
      return {
        ...state,
        updateLedgersLoading: true,
        updateLedgersError: {},
      };
    case ledgersActions.UPDATE_LEDGERS_SUCCESS:
      return {
        ...state,
        updateLedgersLoading: false,
        updateLedgersSuccess: actions.data,
      };
    case ledgersActions.UPDATE_LEDGERS_ERROR:
      return {
        ...state,
        updateLedgersLoading: false,
        updateLedgersError: actions.error,
      };
    case ledgersActions.UPDATE_LEDGER_DATA:
      return {
        ...state,
        updateLedgerData: actions.data,
      };

    case ledgersActions.DELETE_LEDGERS_REQUEST:
      return {
        ...state,
        deleteLedgersLoading: true,
        deleteLedgersError: {},
      };
    case ledgersActions.DELETE_LEDGERS_SUCCESS:
      return {
        ...state,
        deleteLedgersLoading: false,
        deleteLedgersSuccess: actions.data,
      };
    case ledgersActions.DELETE_LEDGERS_ERROR:
      return {
        ...state,
        deleteLedgersLoading: false,
        deleteLedgersError: actions.error,
      };
    case ledgersActions.GET_LEDGER_DETAILS_REQUEST:
      return {
        ...state,
        ledgerDetailsLoading: true,
        ledgerDetailsError: {},
      };
    case ledgersActions.GET_LEDGER_DETAILS_SUCCESS:
      return {
        ...state,
        ledgerDetailsLoading: false,
        ledgerDetailsSuccess: actions.data,
      };
    case ledgersActions.GET_LEDGER_DETAILS_ERROR:
      return {
        ...state,
        ledgerDetailsLoading: false,
        ledgerDetailsError: actions.error,
      };
    case ledgersActions.GET_LEDGER_ACCOUNT_DETAILS_REQUEST:
      return {
        ...state,
        ledgerAccountDetailsLoading: true,
        ledgerAccountDetailsError: {},
      };
    case ledgersActions.GET_LEDGER_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        ledgerAccountDetailsLoading: false,
        ledgerAccountDetailsSuccess: actions.data,
      };
    case ledgersActions.GET_LEDGER_ACCOUNT_DETAILS_ERROR:
      return {
        ...state,
        ledgerAccountDetailsLoading: false,
        ledgerAccountDetailsError: actions.error,
      };
    case ledgersActions.DELETE_LEDGER_ACCOUNT_REQUEST:
      return {
        ...state,
        deleteLedgerAccountLoading: true,
        deleteLedgerAccountError: {},
      };
    case ledgersActions.DELETE_LEDGER_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteLedgerAccountLoading: false,
        deleteLedgerAccountSuccess: actions.data,
      };
    case ledgersActions.DELETE_LEDGER_ACCOUNT_ERROR:
      return {
        ...state,
        deleteLedgerAccountLoading: false,
        deleteLedgerAccountError: actions.error,
      };
    case ledgersActions.CHECK_LEDGER_REQUEST:
      return {
        ...state,
        checkLedgerLoading: true,
        checkLedgerError: {},
      };
    case ledgersActions.CHECK_LEDGER_SUCCESS:
      return {
        ...state,
        checkLedgerLoading: false,
        checkLedgerSuccess: actions.data,
      };
    case ledgersActions.CHECK_LEDGER_ERROR:
      return {
        ...state,
        checkLedgerLoading: false,
        checkLedgerError: actions.error,
      };
    case ledgersActions.APPROVE_LEDGER_REQUEST:
      return {
        ...state,
        approveLedgerLoading: true,
        approveLedgerError: {},
      };
    case ledgersActions.APPROVE_LEDGER_SUCCESS:
      return {
        ...state,
        approveLedgerLoading: false,
        approveLedgerSuccess: actions.data,
      };
    case ledgersActions.APPROVE_LEDGER_ERROR:
      return {
        ...state,
        approveLedgerLoading: false,
        approveLedgerError: actions.error,
      };
    default:
      return state;
  }
};

export default ledgers;
