import { appUiActions } from "../../actions";
import initialState from "../../initialState";

const appUi = (state = initialState.appUi, actions) => {
  switch (actions.type) {
    case appUiActions.SET_OPEN_APPS: {
      return {
        ...state,
        openApps: actions.data,
      };
    }
    case appUiActions.TOGGLE_ADD_SCHEME_OF_WORK_MODAL:
      return {
        ...state,
        addSchemeOfWorkModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_SCHEME_OF_WORK_MODAL:
      return {
        ...state,
        updateSchemeOfWorkModal: actions.data,
      };
    case appUiActions.TOGGLE_SCHEME_OF_WORK_DETAILS_MODAL:
      return {
        ...state,
        schemeOfWorkDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_SCHEME_OF_WORK_ITEM_MODAL:
      return {
        ...state,
        addSchemeOfWorkItemModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_SCHEME_OF_WORK_ITEM_MODAL:
      return {
        ...state,
        updateSchemeOfWorkItemModal: actions.data,
      };
    case appUiActions.TOGGLE_SCHEME_OF_WORK_ITEM_DETAILS_MODAL:
      return {
        ...state,
        schemeOfWorkItemDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_TIMETABLE_MODAL:
      return {
        ...state,
        addTimetableModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_TIMETABLE_MODAL:
      return {
        ...state,
        updateTimetableModal: actions.data,
      };
    case appUiActions.TOGGLE_TIMETABLE_DETAILS_MODAL:
      return {
        ...state,
        schoolTimeTableDetails: actions.data,
      };
    case appUiActions.TOGGLE_LEARNER_RECEIVABLES_LEDGER_MODAL:
      return {
        ...state,
        learnerReceivablesLedgerModal: actions.data,
      };
    case appUiActions.TOGGLE_RECEIVABLES_LEDGER_MODAL:
      return {
        ...state,
        receivablesLedgerModal: actions.data,
      };
    case appUiActions.TOGGLE_COPY_BUDGET_MODAL:
      return {
        ...state,
        copyBudgetModal: actions.data,
      };
    case appUiActions.TOGGLE_LEARNER_PREPAYMENTS_LEDGER_MODAL:
      return {
        ...state,
        learnerPrepaymentsLedgerModal: actions.data,
      };
    case appUiActions.TOGGLE_PREPAYMENTS_LEDGER_MODAL:
      return {
        ...state,
        prepaymentsLedgerModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_BRMS_COMPLIANCE_MODAL:
      return {
        ...state,
        addBrmsComplianceModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_BRMS_COMPLIANCE_MODAL:
      return {
        ...state,
        updateBrmsComplianceModal: actions.data,
      };
    case appUiActions.TOGGLE_BRMS_COMPLIANCE_DETAILS_MODAL:
      return {
        ...state,
        brmsComplianceDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_GENERAL_SCHOOL_COMMUNICATION_MODAL:
      return {
        ...state,
        addGeneralSchoolCommunicationModal: actions.data,
      };
    case appUiActions.SCHOOL_COMMUNICATIONS_DETAILS_MODAL:
      return {
        ...state,
        schoolCommunicationDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_PARENT_CHART_OF_ACCOUNTS_MODAL:
      return {
        ...state,
        addParentChartOfAccountsModal: actions.data,
      };
    case appUiActions.SEND_DEFUALTER_COMMUNICATION_MODAL:
      return {
        ...state,
        sendDefaulterCommunicationModal: actions.data,
      };
    case appUiActions.SYNC_SCHOOL_PAY_TRANSACTIONS_MODAL:
      return {
        ...state,
        syncSchoolPayTransactionsModal: actions.data,
      };
    case appUiActions.TOGGLE_CUSTOM_BILLING_AMOUNT_MODAL:
      return {
        ...state,
        customBillingAmountModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_LOAN_SCHEDULE_MODAL:
      return {
        ...state,
        addLoanRepaymentScheduleModal: actions.data,
      };
    case appUiActions.TOGGLE_LOAN_REPAYMENT_SCHEDULE_DETAILS_MODAL:
      return {
        ...state,
        loanRepaymentScheduleDetails: actions.data,
      };
    case appUiActions.TOGGLE_ADD_LOAN_PAYMENT_MODAL:
      return {
        ...state,
        addLoanRepaymentModal: actions.data,
      };
    case appUiActions.TOGGLE_LEARNING_AREAS_MODAL:
      return {
        ...state,
        learningAreasModal: actions.data,
      };
    case appUiActions.TOGGLE_SUPPLEMENTARIES_MODAL:
      return {
        ...state,
        supplementariesModal: actions.data,
      };
    case appUiActions.TOGGLE_REPORTS_MODAL:
      return {
        ...state,
        reportModal: actions.data,
      };
    case appUiActions.TOGGLE_SET_CLASS_PERFORMANCE_ANALYSIS_DETAILS:
      return {
        ...state,
        classPerformanceAnalysisDetails: actions.data,
      };
    case appUiActions.TOGGLE_SET_SUBJECT_PERFORMANCE_ANALYSIS_DETAILS:
      return {
        ...state,
        subjectPerformanceAnalysisDetails: actions.data,
      };
    case appUiActions.TOGGLE_BULK_RESULTS_MODAL:
      return {
        ...state,
        bulkPrintStudentResults: actions.data,
      };
    case appUiActions.TOGGLE_STUDENT_BILLING_FORM_MODAL:
      return {
        ...state,
        studentBillingFormModal: actions.data,
      };
    case appUiActions.ADD_NEW_APP:
      return {
        ...state,
        openApps: [...state.openApps, actions.data],
      };
    case appUiActions.REMOVE_APP:
      return {
        ...state,
        openApps: state?.openApps.filter((app) => app !== actions.data),
      };
    case appUiActions.TOGGLE_SUB_APP_MENU:
      return {
        ...state,
        subAppMenu: actions.data,
      };
    case appUiActions.SET_PREV_SUB_APP:
      return {
        ...state,
        prevOpenSubApp: actions.data,
      };
    case appUiActions.SET_ACTIVE_APP:
      return {
        ...state,
        activeApp: actions.data,
      };
    case appUiActions.TOGGLE_SINGLE_BILLING_MODAL:
      return {
        ...state,
        singleBillingModal: actions.data,
      };
    case appUiActions.TOGGLE_UPLOAD_INCOME_RECEIPT_EXCEL_MODAL:
      return {
        ...state,
        uploadIncomeReceiptModal: actions.data,
      };
    case appUiActions.TOGGLE_DOWNLOAD_INCOME_RECEIPT_EXCEL_MODAL:
      return {
        ...state,
        downloadIncomeReceiptModal: actions.data,
      };
    case appUiActions.SET_ACTIVE_SUB_APP:
      return {
        ...state,
        activeSubApp: actions.data,
      };
    case appUiActions.TOGGLE_APP_DRAWER:
      return {
        ...state,
        appDrawer: actions.data,
      };
    case appUiActions.TOGGLE_SUBSCRIPTION_PAYMENTS_MODEL:
      return {
        ...state,
        subscriptionsPaymentModel: actions.data,
      };
    case appUiActions.TOGGLE_ADD_LEDGER_MODAL:
      return {
        ...state,
        addLedgerModal: actions.data,
      };
    case appUiActions.TOGGLE_PRINT_MODAL:
      return {
        ...state,
        printModal: actions.data,
      };
    case appUiActions.TOGGLE_PRINT_TABLE_MODAL:
      return {
        ...state,
        printTableModal: actions.data,
      };
    case appUiActions.SET_PRINT_DATA:
      return {
        ...state,
        printData: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_LEDGER_MODAL:
      return {
        ...state,
        updateLedgerModal: actions.data,
      };
    case appUiActions.TOGGLE_LEDGER_DETAILS_MODAL:
      return {
        ...state,
        ledgerDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_LEDGER_ACCOUNT_DETAILS_MODAL:
      return {
        ...state,
        ledgerAccountDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_TRANSFER_LEARNERS_MODAL:
      return {
        ...state,
        transferLearnersModal: actions.data,
      };
    case appUiActions.TOGGLE_PROMOTE_LEARNERS_MODAL:
      return {
        ...state,
        promoteLearnersModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_MARKS_UPLOAD_MODAL:
      return {
        ...state,
        addMarksUploadModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_MARKS_UPLOAD_MODAL:
      return {
        ...state,
        updateMarksUploadModal: actions.data,
      };
    case appUiActions.TOGGLE_MARKS_UPLOAD_DETAILS_MODAL:
      return {
        ...state,
        marksUploadDetails: actions.data,
      };
    case appUiActions.TOGGLE_MARKS_UPLOAD_TABLE:
      return {
        ...state,
        studentMarksTable: actions.data,
      };
    case appUiActions.TOGGLE_ADD_STUDENT_MARKS_MODAL:
      return {
        ...state,
        addStudentMarksModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_ALEVEL_GRADING_MODAL:
      return {
        ...state,
        addAlevelGradingModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_ALEVEL_GRADING_MODAL:
      return {
        ...state,
        updateAlevelGradingModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_STUDENT_MARKS_MODAL:
      return {
        ...state,
        updateStudentMarksModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_ROLE_MODAL:
      return {
        ...state,
        addRoleModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_ROLES_MODAL:
      return {
        ...state,
        updateRolesModal: actions.data,
      };
    case appUiActions.TOGGLE_ROLES_DETAILS_MODELS:
      return {
        ...state,
        roleDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_PERMISSIONS_MODAL:
      return {
        ...state,
        addPermissionsModal: actions.data,
      };
    case appUiActions.TOGGLE_ORGANISATION_LOGO_MODAL:
      return {
        ...state,
        organisationLogoModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_MANUALS_AND_POLICIES_MODAL:
      return {
        ...state,
        addManualsAndPoliciesModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_MANUALS_AND_POLICIES_MODAL:
      return {
        ...state,
        updateManualsAndPoliciesModal: actions.data,
      };
    case appUiActions.TOGGLE_MANUALS_AND_POLICIES_DETAILS_MODAL:
      return {
        ...state,
        manualsAndPoliciesDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_REJECTIONS_MODAL:
      return {
        ...state,
        rejectionsModal: actions.data,
      };
    case appUiActions.TOGGLE_FORGOTPASSWORD_MODAL:
      return {
        ...state,
        forgotPasswordModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_ORGANISATION_USER_MODAL:
      return {
        ...state,
        addOrganisationUserModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_ORGANISATION_USERS_MODAL:
      return {
        ...state,
        updateOrganisationUserModal: actions.data,
      };
    case appUiActions.TOGGLE_ORGANISATION_USER_DETAILS_MODAL:
      return {
        ...state,
        organisationUserDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_STUDENT_REPORTS_MODAL:
      return {
        ...state,
        addStudentReportsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_STUDENT_REPORTS_MODAL:
      return {
        ...state,
        updateStudentReportsModal: actions.data,
      };
    case appUiActions.TOGGLE_STUDENT_REPORTS_DETAILS_MODAL:
      return {
        ...state,
        studentReportsDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_ACCOUNT_TYPES_MODAL:
      return {
        ...state,
        addAccountTypeModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_ACCOUNT_TYPES_MODAL:
      return {
        ...state,
        updateAccountTypeModal: actions.data,
      };
    case appUiActions.TOGGLE_ACCOUNT_TYPE_DETAILS_MODAL:
      return {
        ...state,
        accountTypeDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_ACCOUNT_MODAL:
      return {
        ...state,
        addAccountModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_ACCOUNT_MODAL:
      return {
        ...state,
        updateAccountModal: actions.data,
      };
    case appUiActions.TOGGLE_ACCOUNT_DETAILS_MODAL:
      return {
        ...state,
        accountDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_ASSET_MODAL:
      return {
        ...state,
        addAssetModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_ASSET_MODAL:
      return {
        ...state,
        updateAssetModal: actions.data,
      };
    case appUiActions.TOGGLE_ASSET_DETAILS_MODAL:
      return {
        ...state,
        assetDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_ASSET_TYPE_MODAL:
      return {
        ...state,
        addAssetTypeModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_ASSET_TYPE_MODAL:
      return {
        ...state,
        updateAssetTypeModal: actions.data,
      };
    case appUiActions.TOGGLE_ASSET_TYPE_DETAILS_MODAL:
      return {
        ...state,
        assetTypeDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPLOAD_USER_PROFILE_AVATAR_MODAL:
      return {
        ...state,
        uploadUserProfileAvatarModal: actions.data,
      };
    case appUiActions.TOGGLE_UPLOAD_USER_SIGNATURE_MODAL:
      return {
        ...state,
        uploadUserSignatureModal: actions.data,
      };
    case appUiActions.TOGGLE_CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        changePasswordModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_FINANCE_STREAM_MODAL:
      return {
        ...state,
        addFinanceStreamModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_FINANCE_STREAM_MODAL:
      return {
        ...state,
        updateFinanceStreamModal: actions.data,
      };
    case appUiActions.TOGGLE_FINANCE_STREAM_DETAILS_MODAL:
      return {
        ...state,
        financeStreamDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_STUDENT_MODAL:
      return {
        ...state,
        addStudentModal: actions.data,
      };
    case appUiActions.TOGGLE_GET_STUDENT_DETAILS_MODAL:
      return {
        ...state,
        getStudentDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_STUDENT_DETAILS_MODAL:
      return {
        ...state,
        updateStudentDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_BANKS_MODAL:
      return {
        ...state,
        addBanksModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_BANK_MODAL:
      return {
        ...state,
        updateBankModal: actions.data,
      };
    case appUiActions.TOGGLE_BANK_DETAILS_MODAL:
      return {
        ...state,
        bankDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_PARTNER_MODAL:
      return {
        ...state,
        addPartnerModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_PARTNER_MODAL:
      return {
        ...state,
        updatePartnerModal: actions.data,
      };
    case appUiActions.TOGGLE_PARTNER_DETAILS_MODAL:
      return {
        ...state,
        partnerDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_BUDGETS_MODAL:
      return {
        ...state,
        addBudgetsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_BUDGETS_MODAL:
      return {
        ...state,
        updateBudgetModal: actions.data,
      };
    case appUiActions.TOGGLE_BUDGET_DETAILS_PAGE:
      return {
        ...state,
        budgetDetailsPage: actions.data,
      };
    case appUiActions.TOGGLE_ADD_BUDGET_LINE_MODAL:
      return {
        ...state,
        addBudgetLineModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_BUDGET_LINE_MODAL:
      return {
        ...state,
        updateBudgetLineModal: actions.data,
      };
    case appUiActions.TOGGLE_BUDGET_LINE_DETAILS_MODAL:
      return {
        ...state,
        budgetLineDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_BUDGET_STREAM_MODAL:
      return {
        ...state,
        updateBudgetStreamModal: actions.data,
      };
    case appUiActions.TOGGLE_BUDGET_STREAM_DETAILS_MODAL:
      return {
        ...state,
        budgetStreamDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_INCOME_RECEIPT_DETAILS_MODAL:
      return {
        ...state,
        incomeReceiptDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_INCOME_RECEIPT_MODAL:
      return {
        ...state,
        addIncomeReceiptModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_INCOME_RECEIPT_MODAL:
      return {
        ...state,
        updateIncomeReceiptModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_INCOME_RECEIPT_STREAM_MODAL:
      return {
        ...state,
        addIncomeReceiptStreamModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_INCOME_RECEIPT_STREAM_MODAL:
      return {
        ...state,
        updateIncomeReceiptStreamModal: actions.data,
      };
    case appUiActions.TOGGLE_INCOME_RECEIPT_STREAM_DETAILS_MODAL:
      return {
        ...state,
        incomeReceiptStreamDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_INCOME_RECEIPT_DOCUMENT_MODAL:
      return {
        ...state,
        addIncomeReceiptDocumentModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_STORE_ITEM_MODAL:
      return {
        ...state,
        addStoreItemModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_STORE_ITEM_MODAL:
      return {
        ...state,
        updateStoreItemModal: actions.data,
      };
    case appUiActions.TOGGLE_STORE_ITEM_DETAILS_MODAL:
      return {
        ...state,
        storeItemDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_STORE_ISSUE_MODAL:
      return {
        ...state,
        addStoreIssueModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_STORE_ISSUE_MODAL:
      return {
        ...state,
        updateStoreIssueModal: actions.data,
      };
    case appUiActions.TOGGLE_STORE_ISSUE_DETAILS_MODAL:
      return {
        ...state,
        storeIssueDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_REQUISITION_MEMO_MODAL:
      return {
        ...state,
        addRequisitionMemoModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_REQUISITION_MEMO_MODAL:
      return {
        ...state,
        updateRequisitionMemoModal: actions.data,
      };
    case appUiActions.TOGGLE_REQUISITION_MEMO_DETAILS_MODAL:
      return {
        ...state,
        requisitionMemoDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_REQUISITION_MEMO_COST_MODAL:
      return {
        ...state,
        addRequisitionCostMemoModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_REQUISITION_MEMO_COST_MODAL:
      return {
        ...state,
        updateRequisitionMemoCostModal: actions.data,
      };
    case appUiActions.TOGGLE_REQUISITION_MEMO_COST_DETAILS_MODAL:
      return {
        ...state,
        requisitionMemoCostDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_PAYMENT_VOUCHER_MODAL:
      return {
        ...state,
        addPaymentVoucherModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_PAYMENT_VOUCHER_MODAL:
      return {
        ...state,
        updatePaymentVoucherModal: actions.data,
      };
    case appUiActions.TOGGLE_PAYMENT_VOUCHER_DETAILS_MODAL:
      return {
        ...state,
        paymentVoucherDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_PROJECT_MODAL:
      return {
        ...state,
        addProjectModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_PROJECT_MODAL:
      return {
        ...state,
        updateProjectModal: actions.data,
      };
    case appUiActions.TOGGLE_PROJECT_DETAILS_MODAL:
      return {
        ...state,
        projectDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_REQUEST_FOR_QUOTATION_MODAL:
      return {
        ...state,
        addRequestForQuotationModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_REQUEST_FOR_QUOTATION_MODAL:
      return {
        ...state,
        updateRequestForQuotationModal: actions.data,
      };
    case appUiActions.TOGGLE_REQUEST_FOR_QUOTATION_DETAILS_MODAL:
      return {
        ...state,
        requestForQuotationDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_CLASS_MODAL:
      return {
        ...state,
        addClassModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_CLASS_MODAL:
      return {
        ...state,
        updateClassModal: actions.data,
      };
    case appUiActions.TOGGLE_CLASS_DETAILS_MODAL:
      return {
        ...state,
        classDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_BID_EVALUATION_MODAL:
      return {
        ...state,
        addBidEvaluationModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_BID_EVALUATION_MODAL:
      return {
        ...state,
        updateBidEvaluationModal: actions.data,
      };
    case appUiActions.TOGGLE_BID_EVALUATION_DETAILS_MODAL:
      return {
        ...state,
        bidEvaluationDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_ELIGIBILITY_EVALUATION_MODAL:
      return {
        ...state,
        addEligibilityEvaluationModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_ELIGIBILITY_EVALUATION_MODAL:
      return {
        ...state,
        updateEligibilityEvaluationModal: actions.data,
      };
    case appUiActions.TOGGLE_ELIGIBILITY_EVALUATION_DETAILS_MODAL:
      return {
        ...state,
        eligibilityEvaluationDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_INCOME_STATEMENT:
      return {
        ...state,
        incomeStatementDetails: actions.data,
      };
    case appUiActions.TOGGLE_ADD_TECHNICAL_EVALUATION_MODAL:
      return {
        ...state,
        technicalBidEvaluationModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_TECHNICAL_EVALUATION_MODAL:
      return {
        ...state,
        updateTechnicalEvaluationModal: actions.data,
      };
    case appUiActions.TOGGLE_TECHNICAL_EVALUATION_DETAILS_MODAL:
      return {
        ...state,
        technicalEvaluationDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_FINANCIAL_EVALUATION_MODAL:
      return {
        ...state,
        addFinancialEvaluationModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_FINANCIAL_EVALUATION_MODAL:
      return {
        ...state,
        updateFinancialEvaluationModal: actions.data,
      };
    case appUiActions.TOGGLE_FINANCIAL_EVALUATION_DETAILS_MODAL:
      return {
        ...state,
        financialEvaluationDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_NOTICE_OF_BEST_BIDDER_MODAL:
      return {
        ...state,
        addNoticeOfBestBidderModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_NOTICE_OF_BEST_BIDDER_MODAL:
      return {
        ...state,
        updateNoticeOfBestBidderModal: actions.data,
      };
    case appUiActions.TOGGLE_NOTICE_OF_BEST_BIDDER_DETAILS_MODAL:
      return {
        ...state,
        noticeOfBestBidderDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_LOCAL_PURCHASE_ORDER_MODAL:
      return {
        ...state,
        addLocalPurchaseOrderModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_LOCAL_PURCHASE_ORDER_ITEM_MODAL:
      return {
        ...state,
        addLocalPurchaseOrderItemModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_LOCAL_PURCHASE_ORDER_MODAL:
      return {
        ...state,
        updateLocalPurchaseOrderModal: actions.data,
      };
    case appUiActions.TOGGLE_LOCAL_PURCHASE_ORDER_DETAILS_MODAL:
      return {
        ...state,
        localPurchaseOrderDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_LOCAL_PURCHASE_ORDER_ITEM_MODAL:
      return {
        ...state,
        updateLocalPurchaseOrderItemModal: actions.data,
      };
    case appUiActions.TOGGLE_LOCAL_PURCHASE_ORDER_ITEM_DETAILS_MODAL:
      return {
        ...state,
        localPurchaseOrderItemDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_ACADEMIC_YEAR_MODAL:
      return {
        ...state,
        addAcademicYearsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_ACADEMIC_YEAR_MODAL:
      return {
        ...state,
        updateAcademicYearsModal: actions.data,
      };
    case appUiActions.TOGGLE_ACADEMIC_YEARS_DETAILS_MODAL:
      return {
        ...state,
        academicYearsDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_TERM_DETAILS_MODAL:
      return {
        ...state,
        termDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_PAYMENT_STRUCTURES_MODAL:
      return {
        ...state,
        addPaymentStructureModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_PAYMENT_STRUCTURE_MODAL:
      return {
        ...state,
        updatePaymentStructureModal: actions.data,
      };
    case appUiActions.TOGGLE_PAYMENT_STRUCTURE_DETAILS_MODAL:
      return {
        ...state,
        paymentStructureDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_PAYMENT_STRUCTURE_COST_MODAL:
      return {
        ...state,
        updatePaymentStructureCostModal: actions.data,
      };
    case appUiActions.TOGGLE_PAYMENT_STRUCTURE_COST_DETAILS_MODAL:
      return {
        ...state,
        paymentStructureCostDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_MODAL:
      return {
        ...state,
        advanceAccountabilityFormsDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_ADVANCE_ACCOUNTABILITY_FORMS_MODAL:
      return {
        ...state,
        addAdvanceAccountabilityFormsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_ADVANCE_ACCOUNTABILITY_FORMS_MODAL:
      return {
        ...state,
        updateAdvanceAccountabilityFormsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_ACTUAL_EXPENDITURE_MODAL:
      return {
        ...state,
        addActualExpenditureModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_ACTUAL_EXPENDITURE_MODAL:
      return {
        ...state,
        updateActualExpenditureModal: actions.data,
      };
    case appUiActions.TOGGLE_ACTUAL_EXPENDITURE_DETAILS_MODAL:
      return {
        ...state,
        actualExpenditureDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_ENROLLMENT_MODAL:
      return {
        ...state,
        addEnrollmentModal: actions.data,
      };
    case appUiActions.TOGGLE_ENROLL_STUDENTS_MODAL:
      return {
        ...state,
        enrollStudentsModal: actions.data,
      };
    case appUiActions.TOGGLE_ENROLLMENT_DETAILS_MODAL:
      return {
        ...state,
        enrollmentDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_SUBJECT_CLASS_MODAL:
      return {
        ...state,
        addSubjectClassModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_SUBJECT_CLASS_MODAL:
      return {
        ...state,
        updateSubjectClassModal: actions.data,
      };
    case appUiActions.TOGGLE_SUBJECT_CLASS_DETAILS_MODAL:
      return {
        ...state,
        subjectClassDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_TEACHER_ASSIGNMENT_MODAL:
      return {
        ...state,
        addTeacherAssignmentModal: actions.data,
      };
    case appUiActions.TOGGLE_TEACHER_ASSIGNMENT_DETAILS_MODAL:
      return {
        ...state,
        teacherAssignmentDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_TEACHER_ASSIGNMENT_MODAL:
      return {
        ...state,
        updateTeacherAssignmentModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_EXAMINATIONS_MODAL:
      return {
        ...state,
        addExaminationModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_EXAMINATIONS_MODAL:
      return {
        ...state,
        updateExaminationModal: actions.data,
      };
    case appUiActions.TOGGLE_EXAMINATIONS_DETAILS_MODAL:
      return {
        ...state,
        examinationDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_OPTIONAL_SUBJECT_MODAL:
      return {
        ...state,
        addOptionalSubjectModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_OPTIONAL_SUBJECT_STUDENTS_MODAL:
      return {
        ...state,
        addOptionalSubjectStudentsModal: actions.data,
      };
    case appUiActions.TOGGLE_OPTIONAL_SUBJECT_DETAILS_MODAL:
      return {
        ...state,
        optionalSubjectDetailsModal: actions.data,
      };

    case appUiActions.TOGGLE_ADD_SUBJECT_MODAL:
      return {
        ...state,
        addSubjectModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_SUBJECT_MODAL:
      return {
        ...state,
        updateSubjectModal: actions.data,
      };
    case appUiActions.TOGGLE_SUBJECT_DETAILS_MODAL:
      return {
        ...state,
        subjectDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_RECORD_OF_BIDDERS_MODAL:
      return {
        ...state,
        addRecordOfBiddersModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_RECORD_OF_BIDDERS_MODAL:
      return {
        ...state,
        updateRecordOfBiddersModal: actions.data,
      };
    case appUiActions.TOGGLE_RECORD_OF_BIDDERS_DETAILS_MODAL:
      return {
        ...state,
        recordOfBiddersDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_BIDDER_MODAL:
      return {
        ...state,
        addBidderModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_BIDDER_MODAL:
      return {
        ...state,
        updateBidderModal: actions.data,
      };
    case appUiActions.TOGGLE_BIDDER_DETAILS_MODAL:
      return {
        ...state,
        bidderDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_GRADING_MODAL:
      return {
        ...state,
        addGradingModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_GRADING_MODAL:
      return {
        ...state,
        updateGradingModal: actions.data,
      };
    case appUiActions.TOGGLE_GRADING_DETAILS_MODAL:
      return {
        ...state,
        gradingDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_STREAM_DETAILS_MODAL:
      return {
        ...state,
        streamsDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_STREAMS_MODAL:
      return {
        ...state,
        addStreamsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_STREAM_MODAL:
      return {
        ...state,
        updateStreamsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPLOAD_STUDENT_DOCUMENTS_MODAL:
      return {
        ...state,
        uploadStudentDocumentsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_BURSARIES_MODAL:
      return {
        ...state,
        addBursariesModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_BURSARIES_MODAL:
      return {
        ...state,
        updateBursariesModal: actions.data,
      };
    case appUiActions.TOGGLE_BURSARIES_DETAILS_MODAL:
      return {
        ...state,
        bursariesDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_BURSARY_COST_MODAL:
      return {
        ...state,
        updateBursaryCostModal: actions.data,
      };
    case appUiActions.TOGGLE_BURSARY_COST_DETAILS_MODAL:
      return {
        ...state,
        bursaryCostDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_PRINT_SUBJECTS_TABLE_MODAL:
      return {
        ...state,
        printSubjectsTableModal: actions.data,
      };
    case appUiActions.TOGGLE_PRINT_STUDENTS_TABLE_MODAL:
      return {
        ...state,
        printStudentsTableModal: actions.data,
      };
    case appUiActions.TOGGLE_PRINT_OPTIONAL_SUBJECTS_TABLE_MODAL:
      return {
        ...state,
        printOptionalSubjectsTableModal: actions.data,
      };
    case appUiActions.TOGGLE_PRINT_ENROLLMENT_TABLE_MODAL:
      return {
        ...state,
        printEnrollmentTableModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_STAFF_DEPENDANTS_MODAL:
      return {
        ...state,
        addStaffDependantsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_STAFF_DEPENDANTS_MODAL:
      return {
        ...state,
        updateStaffDependantsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPLOAD_STAFF_DOCUMENTS_MODAL:
      return {
        ...state,
        uploadStaffDocumentsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_STAFF_DOCUMENTS_MODAL:
      return {
        ...state,
        updateStaffDocumentsModal: actions.data,
      };
    case appUiActions.TOGGLE_DOWNLOAD_OPTIONAL_SUBJECT_STUDENTS_MODAL:
      return {
        ...state,
        downloadOptionalSubjectStudentsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPLOAD_OPTIONAL_SUBJECT_STUDENTS_MODAL:
      return {
        ...state,
        uploadOptionalSubjectStudentsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_ENROLLMENT_MODAL:
      return {
        ...state,
        updateEnrollmentModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_STAFF_QUALIFICATION_MODAL:
      return {
        ...state,
        addStaffQualificationModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_STAFF_QUALIFICATION_MODAL:
      return {
        ...state,
        updateStaffQualificationModal: actions.data,
      };
    case appUiActions.TOGGLE_STAFF_QUALIFICATION_DETAILS_MODAL:
      return {
        ...state,
        staffQualificationDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_BANK_STATEMENT_MODAL:
      return {
        ...state,
        bankStatementModal: actions.data,
      };
    case appUiActions.TOGGLE_SEARCH_STUDENTS_MODAL:
      return {
        ...state,
        searchStudentsModal: actions.data,
      };
    case appUiActions.TOGGLE_SORT_INCOME_RECEIPTS_MODAL:
      return {
        ...state,
        sortIncomeReceiptsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_USER_EMAIL_MODAL:
      return {
        ...state,
        updateUserEmailModal: actions.data,
      };
    case appUiActions.TOGGLE_UPLOAD_ADVANCE_ACCOUNTABILITY_DOCUMENTS_MODAL:
      return {
        ...state,
        uploadAdvanceAccountabilityDocumentsModal: actions.data,
      };
    case appUiActions.TOGGLE_SORT_REQUISITION_MEMOS_MODAL:
      return {
        ...state,
        sortRequisitionMemosModal: actions.data,
      };
    case appUiActions.TOGGLE_SORT_ADVANCE_ACCOUNTABILITY_MODAL:
      return {
        ...state,
        sortAdvanceAccountabilityModal: actions.data,
      };
    case appUiActions.TOGGLE_SORT_MODAL:
      return {
        ...state,
        sortModal: actions.data,
      };
    case appUiActions.TOGGLE_SORT_FROM_TABLE_MODAL:
      return {
        ...state,
        sortFromTableModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_DIVISIONS_MODAL:
      return {
        ...state,
        addDivisionsModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_DIVISION_MODAL:
      return {
        ...state,
        updateDivisionModal: actions.data,
      };
    case appUiActions.TOGGLE_FEES_DEFAULTER_DETAILS_MODAL:
      return {
        ...state,
        feesDefaulterDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_REPORT_COMMENTS_MODAL:
      return {
        ...state,
        reportCommentsModal: actions.data,
      };
    case appUiActions.TOGGLE_MANDATORY_BILLING_MODAL:
      return {
        ...state,
        mandatoryBillingModal: actions.data,
      };
    case appUiActions.TOGGLE_BILL_LEARNERS_MODAL:
      return {
        ...state,
        billLearnersModal: actions.data,
      };
    case appUiActions.TOGGLE_CREATE_REPORT_EXCEPTION_MODAL:
      return {
        ...state,
        createReportExceptionModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_REPORT_EXCEPTION_MODAL:
      return {
        ...state,
        updateReportExceptionModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_SINGLE_REPORT_EXCEPTION_MODAL:
      return {
        ...state,
        addSingleReportExceptionModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_SUPPLIER_PAYMENT_SCHEDULE_MODAL:
      return {
        ...state,
        addSupplierPaymentScheduleModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_SUPPLIER_PAYMENT_SCHEDULE_MODAL:
      return {
        ...state,
        updateSupplierPaymentScheduleModal: actions.data,
      };
    case appUiActions.TOGGLE_SUPPLIER_PAYMENT_SCHEDULE_DETAILS_MODAL:
      return {
        ...state,
        supplierPaymentScheduleDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_SUPPLIER_PAYMENT_MODAL:
      return {
        ...state,
        addSupplierPaymentModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_SUPPLIER_PAYMENT_MODAL:
      return {
        ...state,
        updateSupplierPaymentModal: actions.data,
      };
    case appUiActions.TOGGLE_SUPPLIER_PAYMENT_DETAILS_MODAL:
      return {
        ...state,
        supplierPaymentDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_LEARNER_ATTENDANCE_MODAL:
      return {
        ...state,
        addLearnerAttendanceModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_LEARNER_ATTENDANCE_MODAL:
      return {
        ...state,
        updateLearnerAttendanceModal: actions.data,
      };
    case appUiActions.TOGGLE_LEARNER_ATTENDANCE_DETAILS_MODAL:
      return {
        ...state,
        learnerAttendanceDetailsModal: actions.data,
      };

    case appUiActions.TOGGLE_ADD_STAFF_ATTENDANCE_MODAL:
      return {
        ...state,
        addStaffAttendanceModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_STAFF_ATTENDANCE_MODAL:
      return {
        ...state,
        updateStaffAttendanceModal: actions.data,
      };
    case appUiActions.TOGGLE_STAFF_ATTENDANCE_DETAILS_MODAL:
      return {
        ...state,
        staffAttendanceDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_SUPPLIER_PAYMENT_INVOICE_MODAL:
      return {
        ...state,
        addSupplierPaymentInvoiceModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_SUPPLIER_PAYMENT_INVOICE_MODAL:
      return {
        ...state,
        updateSupplierPaymentInvoiceModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_GENERAL_SUPPLIER_PAYMENT_INVOICE_MODAL:
      return {
        ...state,
        addGeneralSupplierPaymentInvoiceModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_SCHOOL_DEVELOPMENT_PLAN_MODAL:
      return {
        ...state,
        addSchoolDevelopmentPlanModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_SCHOOL_DEVELOPMENT_PLAN_MODAL:
      return {
        ...state,
        updateSchoolDevelopmentPlanModal: actions.data,
      };
    case appUiActions.TOGGLE_SCHOOL_DEVELOPMENT_PLAN_DETAILS_MODAL:
      return {
        ...state,
        schoolDevelopmentPlanDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_MODAL:
      return {
        ...state,
        addSchoolDevelopmentPlanExpenditureModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_MODAL:
      return {
        ...state,
        updateSchoolDevelopmentPlanExpenditureModal: actions.data,
      };
    case appUiActions.TOGGLE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_DETAILS_MODAL:
      return {
        ...state,
        schoolDevelopmentPlanExpenditureDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_STAFF_PAYROLL_MODAL:
      return {
        ...state,
        addStaffPayRollModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_STAFF_PAYROLL_MODAL:
      return {
        ...state,
        updateStaffPayRollModal: actions.data,
      };
    case appUiActions.TOGGLE_STAFF_PAYROLL_DETAILS_MODAL:
      return {
        ...state,
        staffPayRollDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_CLIENT_INVOICE_MODAL:
      return {
        ...state,
        addClientInvoiceModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_CLIENT_INVOICE_MODAL:
      return {
        ...state,
        updateClientInvoiceModal: actions.data,
      };
    case appUiActions.TOGGLE_CLIENT_INVOICE_DETAILS_MODAL:
      return {
        ...state,
        clientInvoiceDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_INCOME_STATEMENT_ACCOUNT_DETAILS_MODAL:
      return {
        ...state,
        incomeStatementAccountDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_SCHOOL_GATE_PASS_MODAL:
      return {
        ...state,
        addSchoolGatePassModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_SCHOOL_GATE_PASS_MODAL:
      return {
        ...state,
        updateSchoolGatePassModal: actions.data,
      };
    case appUiActions.TOGGLE_SCHOOL_GATE_PASS_DETAILS_MODAL:
      return {
        ...state,
        schoolGatePassDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_SCHOOL_TIME_TABLE_MODAL:
      return {
        ...state,
        addSchoolTimeTableModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_SCHOOL_TIME_TABLE_MODAL:
      return {
        ...state,
        updateSchoolTimeTableModal: actions.data,
      };
    case appUiActions.TOGGLE_SCHOOL_TIME_TABLE_DETAILS_MODAL:
      return {
        ...state,
        schoolTimeTableDetails: actions.data,
      };
    case appUiActions.TOGGLE_ADD_SCHOOL_TIME_TABLE_ACTIVITY_MODAL:
      return {
        ...state,
        addSchoolTimeTableActivityModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_SCHOOL_TIME_TABLE_ACTIVITY_MODAL:
      return {
        ...state,
        updateSchoolTimeTableActivityModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_LESSON_PLAN_MODAL:
      return {
        ...state,
        addLessonPlanModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_LESSON_PLAN_MODAL:
      return {
        ...state,
        updateLessonPlanModal: actions.data,
      };
    case appUiActions.TOGGLE_LESSON_PLAN_DETAILS_MODAL:
      return {
        ...state,
        lessonPlanDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_LESSON_PLAN_PROCEDURE_STAGE_MODAL:
      return {
        ...state,
        addLessonPlanProcedureStageModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_LESSON_PLAN_PROCEDURE_STAGE_MODAL:
      return {
        ...state,
        updateLessonPlanProcedureStageModal: actions.data,
      };
    case appUiActions.TOGGLE_IMPORT_SCHOOL_PAY_EXCEL_MODAL:
      return {
        ...state,
        importSchoolPayExcelModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_INDISCIPLINE_CASE_MODAL:
      return {
        ...state,
        addIndisciplineCaseModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_INDISCIPLINE_CASE_MODAL:
      return {
        ...state,
        updateIndisciplineCaseModal: actions.data,
      };
    case appUiActions.TOGGLE_INDISCIPLINE_CASE_DETAILS_MODAL:
      return {
        ...state,
        indisciplineCaseDetailsModal: actions.data,
      };
    case appUiActions.TOGGLE_JOIN_CLASSESS_MODAL:
      return {
        ...state,
        joinClassesModal: actions.data,
      };
    case appUiActions.TOGGLE_ADD_COMPETENCY_GRADING_SCALE_MODAL:
      return {
        ...state,
        addCompetencyGradingScaleModal: actions.data,
      };
    case appUiActions.TOGGLE_UPDATE_COMPETENCY_GRADING_SCALE_MODAL:
      return {
        ...state,
        updateCompetencyGradingScaleModal: actions.data,
      };
    case appUiActions.TOGGLE_DOWNLOAD_STAFF_UPLOAD_TEMPLATE_MODAL:
      return {
        ...state,
        downloadStaffTemplateModal: actions.show,
      };

    default:
      return state;
  }
};

export default appUi;
