const learnerAttendance = {
  GET_LEARNER_ATTENDANCE_REQUEST: "GET_LEARNER_ATTENDANCE_REQUEST",
  GET_LEARNER_ATTENDANCE_SUCCESS: "GET_LEARNER_ATTENDANCE_SUCCESS",
  GET_LEARNER_ATTENDANCE_ERROR: "GET_LEARNER_ATTENDANCE_ERROR",

  GET_LEARNER_ATTENDANCE_META_DATA_REQUEST:
    "GET_LEARNER_ATTENDANCE_META_DATA_REQUEST",
  GET_LEARNER_ATTENDANCE_META_DATA_SUCCESS:
    "GET_LEARNER_ATTENDANCE_META_DATA_SUCCESS",
  GET_LEARNER_ATTENDANCE_META_DATA_ERROR:
    "GET_LEARNER_ATTENDANCE_META_DATA_ERROR",

  GET_LEARNER_ATTENDANCE_DETAILS_REQUEST:
    "GET_LEARNER_ATTENDANCE_DETAILS_REQUEST",
  GET_LEARNER_ATTENDANCE_DETAILS_SUCCESS:
    "GET_LEARNER_ATTENDANCE_DETAILS_SUCCESS",
  GET_LEARNER_ATTENDANCE_DETAILS_ERROR: "GET_LEARNER_ATTENDANCE_DETAILS_ERROR",

  ADD_LEARNER_ATTENDANCE_REQUEST: "ADD_LEARNER_ATTENDANCE_REQUEST",
  ADD_LEARNER_ATTENDANCE_SUCCESS: "ADD_LEARNER_ATTENDANCE_SUCCESS",
  ADD_LEARNER_ATTENDANCE_ERROR: "ADD_LEARNER_ATTENDANCE_ERROR",

  UPDATE_LEARNER_ATTENDANCE_REQUEST: "UPDATE_LEARNER_ATTENDANCE_REQUEST",
  UPDATE_LEARNER_ATTENDANCE_SUCCESS: "UPDATE_LEARNER_ATTENDANCE_SUCCESS",
  UPDATE_LEARNER_ATTENDANCE_ERROR: "UPDATE_LEARNER_ATTENDANCE_ERROR",

  DELETE_LEARNER_ATTENDANCE_REQUEST: "DELETE_LEARNER_ATTENDANCE_REQUEST",
  DELETE_LEARNER_ATTENDANCE_SUCCESS: "DELETE_LEARNER_ATTENDANCE_SUCCESS",
  DELETE_LEARNER_ATTENDANCE_ERROR: "DELETE_LEARNER_ATTENDANCE_ERROR",

  CHECK_LEARNER_ATTENDANCE_REQUEST: "CHECK_LEARNER_ATTENDANCE_REQUEST",
  CHECK_LEARNER_ATTENDANCE_SUCCESS: "CHECK_LEARNER_ATTENDANCE_SUCCESS",
  CHECK_LEARNER_ATTENDANCE_ERROR: "CHECK_LEARNER_ATTENDANCE_ERROR",

  APPROVE_LEARNER_ATTENDANCE_REQUEST: "APPROVE_LEARNER_ATTENDANCE_REQUEST",
  APPROVE_LEARNER_ATTENDANCE_SUCCESS: "APPROVE_LEARNER_ATTENDANCE_SUCCESS",
  APPROVE_LEARNER_ATTENDANCE_ERROR: "APPROVE_LEARNER_ATTENDANCE_ERROR",

  SET_LEARNER_ROLL_CALL_DATA: "SET_LEARNER_ROLL_CALL_DATA",
  SET_UPDATE_LEARNER_ROLL_CALL_DATA: "SET_UPDATE_LEARNER_ROLL_CALL_DATA",

  getLearnerAttendance: () => ({
    type: learnerAttendance.GET_LEARNER_ATTENDANCE_REQUEST,
  }),

  getLearnerAttendanceMetaData: () => ({
    type: learnerAttendance.GET_LEARNER_ATTENDANCE_META_DATA_REQUEST,
  }),

  getLearnerAttendanceDetails: (id) => ({
    type: learnerAttendance.GET_LEARNER_ATTENDANCE_DETAILS_REQUEST,
    id,
  }),

  addLearnerAttendance: (data) => ({
    type: learnerAttendance.ADD_LEARNER_ATTENDANCE_REQUEST,
    data,
  }),

  updateLearnerAttendance: (data, id) => ({
    type: learnerAttendance.UPDATE_LEARNER_ATTENDANCE_REQUEST,
    data,
    id,
  }),

  deleteLearnerAttendance: (id) => ({
    type: learnerAttendance.DELETE_LEARNER_ATTENDANCE_REQUEST,
    id,
  }),

  checkLearnerAttendance: (id) => ({
    type: learnerAttendance.CHECK_LEARNER_ATTENDANCE_REQUEST,
    id,
  }),

  approveLearnerAttendance: (id) => ({
    type: learnerAttendance.APPROVE_LEARNER_ATTENDANCE_REQUEST,
    id,
  }),

  setRollCallData: (data) => ({
    type: learnerAttendance.SET_LEARNER_ROLL_CALL_DATA,
    data,
  }),

  setUpdateRollCallData: (data) => ({
    type: learnerAttendance.SET_UPDATE_LEARNER_ROLL_CALL_DATA,
    data,
  }),
};

export default learnerAttendance;
