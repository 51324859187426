const examinations = {
  GET_EXAMINATIONS_REQUEST: "GET_EXAMINATIONS_REQUEST",
  GET_EXAMINATIONS_SUCCESS: "GET_EXAMINATIONS_SUCCESS",
  GET_EXAMINATIONS_ERROR: "GET_EXAMINATIONS_ERROR",

  GET_EXAMINATIONS_DETAILS_REQUEST: "GET_EXAMINATIONS_DETAILS_REQUEST",
  GET_EXAMINATIONS_DETAILS_SUCCESS: "GET_EXAMINATIONS_DETAILS_SUCCESS",
  GET_EXAMINATIONS_DETAILS_ERROR: "GET_EXAMINATIONS_DETAILS_ERROR",

  ADD_EXAMINATIONS_REQUEST: "ADD_EXAMINATIONS_REQUEST",
  ADD_EXAMINATIONS_SUCCESS: "ADD_EXAMINATIONS_SUCCESS",
  ADD_EXAMINATIONS_ERROR: "ADD_EXAMINATIONS_ERROR",

  UPDATE_EXAMINATION_REQUEST: "UPDATE_EXAMINATION_REQUEST",
  UPDATE_EXAMINATION_DATA: "UPDATE_EXAMINATION_DATA",
  UPDATE_EXAMINATION_SUCCESS: "UPDATE_EXAMINATION_SUCCESS",
  UPDATE_EXAMINATION_ERROR: "UPDATE_EXAMINATION_ERROR",

  DELETE_EXAMINATIONS_REQUEST: "DELETE_EXAMINATIONS_REQUEST",
  DELETE_EXAMINATIONS_SUCCESS: "DELETE_EXAMINATIONS_SUCCESS",
  DELETE_EXAMINATIONS_ERROR: "DELETE_EXAMINATIONS_ERROR",

  GET_META_DATA_REQUEST: "GET_EXAMINATIONS_META_DATA_REQUEST",
  GET_META_DATA_SUCCESS: "GET_EXAMINATIONS_META_DATA_SUCCESS",

  CHECK_EXAMINATIONS_REQUEST: "CHECK_EXAMINATIONS_REQUEST",
  CHECK_EXAMINATIONS_SUCCESS: "CHECK_EXAMINATIONS_SUCCESS",
  CHECK_EXAMINATIONS_ERROR: "CHECK_EXAMINATIONS_ERROR",

  APPROVE_EXAMINATIONS_REQUEST: "APPROVE_EXAMINATIONS_REQUEST",
  APPROVE_EXAMINATIONS_SUCCESS: "APPROVE_EXAMINATIONS_SUCCESS",
  APPROVE_EXAMINATIONS_ERROR: "APPROVE_EXAMINATIONS_ERROR",

  SET_EXAM_TYPE: "SET_EXAM_TYPE",

  getExaminations: () => ({
    type: examinations.GET_EXAMINATIONS_REQUEST,
  }),
  setExamType: (data) => ({
    type: examinations.SET_EXAM_TYPE,
    data,
  }),
  getExaminationDetails: (id) => ({
    type: examinations.GET_EXAMINATIONS_DETAILS_REQUEST,
    id,
  }),
  getMetaData: () => ({
    type: examinations.GET_META_DATA_REQUEST,
  }),
  addExaminations: (data) => ({
    type: examinations.ADD_EXAMINATIONS_REQUEST,
    data,
  }),
  updateExaminations: (data, id) => ({
    type: examinations.UPDATE_EXAMINATION_REQUEST,
    data,
    id,
  }),
  deleteExaminations: (id) => ({
    type: examinations.DELETE_EXAMINATIONS_REQUEST,
    id,
  }),
  checkExaminations: (id) => ({
    type: examinations.CHECK_EXAMINATIONS_REQUEST,
    id,
  }),
  approveExaminations: (id) => ({
    type: examinations.APPROVE_EXAMINATIONS_REQUEST,
    id,
  }),
  setUpdateExaminationsData: (data) => ({
    type: examinations.UPDATE_EXAMINATION_DATA,
    data,
  }),
};

export default examinations;
