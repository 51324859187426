const optionalSubjectsInitialState = {
  optionalSubjectsLoading: false,
  optionalSubjectsSuccess: {},
  optionalSubjectsError: {},

  getOptionalSubjectsMetaDataLoading: false,
  getOptionalSubjectsMetaDataSuccess: {},
  getOptionalSubjectsMetaDataError: {},

  getOptionalSubjectDetailsLoading: false,
  getOptionalSubjectDetailsSuccess: {},
  getOptionalSubjectDetailsError: {},

  addOptionalSubjectLoading: false,
  addOptionalSubjectSuccess: {},
  addOptionalSubjectError: {},

  addOptionalSubjectStudentsLoading: false,
  addOptionalSubjectStudentsSuccess: {},
  addOptionalSubjectStudentsError: {},

  deleteOptionalSubjectLoading: false,
  deleteoptionalSubjectuccess: {},
  deleteOptionalSubjectError: {},

  deleteOptionalSubjectStudentLoading: false,
  deleteOptionalSubjectStudentSuccess: {},
  deleteOptionalSubjectStudentError: {},

  downloadOptionalSubjectStudentsTemplateLoading: false,
  downloadOptionalSubjectStudentsTemplateSuccess: {},
  downloadOptionalSubjectStudentsTemplateError: {},

  uploadOptionalSubjectStudentsLoading: false,
  uploadOptionalSubjectStudentsSuccess: {},
  uploadOptionalSubjectStudentsError: {},
};

export default optionalSubjectsInitialState;
