const prepaymentsInitialState = {
  prepayments: {
    prepayments: {},
    prepatmentsLoading: false,
    prepaymentsError: {},

    selectedPrepayment: {},
  },
};

export default prepaymentsInitialState;
