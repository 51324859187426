const bidEvaluations = {
  GET_BID_EVALUATIONS_REQUEST: "GET_BID_EVALUATIONS_REQUEST",
  GET_BID_EVALUATIONS_SUCCESS: "GET_BID_EVALUATIONS_SUCCESS",
  GET_BID_EVALUATIONS_ERROR: "GET_BID_EVALUATIONS_ERROR",

  GET_ELIGIBILITY_EVALUATIONS_REQUEST: "GET_ELIGIBILITY_EVALUATIONS_REQUEST",
  GET_ELIGIBILITY_EVALUATIONS_SUCCESS: "GET_ELIGIBILITY_EVALUATIONS_SUCCESS",
  GET_ELIGIBILITY_EVALUATIONS_ERROR: "GET_ELIGIBILITY_EVALUATIONS_ERROR",

  GET_TECHNICAL_EVALUATIONS_REQUEST: "GET_TECHNICAL_EVALUATIONS_REQUEST",
  GET_TECHNICAL_EVALUATIONS_SUCCESS: "GET_TECHNICAL_EVALUATIONS_SUCCESS",
  GET_TECHNICAL_EVALUATIONS_ERROR: "GET_TECHNICAL_EVALUATIONS_ERROR",

  GET_FINANCIAL_EVALUATIONS_REQUEST: "GET_FINANCIAL_EVALUATIONS_REQUEST",
  GET_FINANCIAL_EVALUATIONS_SUCCESS: "GET_FINANCIAL_EVALUATIONS_SUCCESS",
  GET_FINANCIAL_EVALUATIONS_ERROR: "GET_FINANCIAL_EVALUATIONS_ERROR",

  GET_BID_EVALUATIONS_META_DATA_REQUEST:
    "GET_BID_EVALUATIONS_META_DATA_REQUEST",
  GET_BID_EVALUATIONS_META_DATA_SUCCESS:
    "GET_BID_EVALUATIONS_META_DATA_SUCCESS",

  GET_ELIGIBILITY_EVALUATIONS_META_DATA_REQUEST:
    "GET_ELIGIBILITY_EVALUATIONS_META_DATA_REQUEST",
  GET_ELIGIBILITY_EVALUATIONS_META_DATA_SUCCESS:
    "GET_ELIGIBILITY_EVALUATIONS_META_DATA_SUCCESS",

  GET_TECHNICAL_EVALUATIONS_META_DATA_REQUEST:
    "GET_TECHNICAL_EVALUATIONS_META_DATA_REQUEST",
  GET_TECHNICAL_EVALUATIONS_META_DATA_SUCCESS:
    "GET_TECHNICAL_EVALUATIONS_META_DATA_SUCCESS",

  GET_FINANCIAL_EVALUATIONS_META_DATA_REQUEST:
    "GET_FINANCIAL_EVALUATIONS_META_DATA_REQUEST",
  GET_FINANCIAL_EVALUATIONS_META_DATA_SUCCESS:
    "GET_FINANCIAL_EVALUATIONS_META_DATA_SUCCESS",

  GET_BID_EVALUATION_DETAILS_REQUEST: "GET_BID_EVALUATION_DETAILS_REQUEST",
  GET_BID_EVALUATION_DETAILS_SUCCESS: "GET_BID_EVALUATION_DETAILS_SUCCESS",
  GET_BID_EVALUATION_DETAILS_ERROR: "GET_BID_EVALUATION_DETAILS_ERROR",

  GET_ELIGIBILITY_EVALUATION_DETAILS_SUCCESS:
    "GET_ELIGIBILITY_EVALUATION_DETAILS_SUCCESS",
  GET_ELIGIBILITY_EVALUATION_DETAILS_REQUEST:
    "GET_ELIGIBILITY_EVALUATION_DETAILS_REQUEST",
  GET_ELIGIBILITY_EVALUATION_DETAILS_ERROR:
    "GET_ELIGIBILITY_EVALUATION_DETAILS_ERROR",

  GET_TECHNICAL_EVALUATION_DETAILS_REQUEST:
    "GET_TECHNICAL_EVALUATION_DETAILS_REQUEST",
  GET_TECHNICAL_EVALUATION_DETAILS_SUCCESS: "GET_TECHNICAL_DETAILS_SUCCESS",
  GET_TECHNICAL_EVALUATION_DETAILS_ERROR:
    "GET_TECHNICAL_EVALUATION_DETAILS_ERROR",

  GET_FINANCIAL_EVALUATION_DETAILS_REQUEST:
    "GET_FINANCIAL_EVALUATION_DETAILS_REQUEST",
  GET_FINANCIAL_EVALUATION_DETAILS_SUCCESS:
    "GET_FINANCIAL_EVALUATION_DETAILS_SUCCESS",
  GET_FINANCIAL_EVALUATION_DETAILS_ERROR:
    "GET_FINANCIAL_EVALUATION_DETAILS_ERROR",

  SET_ADD_BID_EVALUATION_DATA: "SET_ADD_BID_EVALUATION_DATA",
  ADD_BID_EVALUATION_REQUEST: "ADD_BID_EVALUATION_REQUEST",
  ADD_BID_EVALUATION_SUCCESS: "ADD_BID_EVALUATION_SUCCESS",
  ADD_BID_EVALUATION_ERROR: "ADD_BID_EVALUATION_ERROR",

  SET_ADD_ELIGIBILITY_EVALUATION_DATA: "SET_ADD_ELIGIBILITY_EVALUATION_DATA",
  ADD_ELIGIBILITY_EVALUATION_REQUEST: "ADD_ELIGIBILITY_EVALUATION_REQUEST",
  ADD_ELIGIBILITY_EVALUATION_SUCCESS: "ADD_ELIGIBILITY_EVALUATION_SUCCESS",
  ADD_ELIGIBILITY_EVALUATION_ERROR: "ADD_ELIGIBILITY_EVALUATION_ERROR",

  SET_ADD_TECHNICAL_EVALUATION_DATA: "SET_TECHNICAL_BID_EVALUATION_DATA",
  ADD_TECHNICAL_EVALUATION_REQUEST: "ADD_TECHNICAL_EVALUATION_REQUEST",
  ADD_TECHNICAL_EVALUATION_SUCCESS: "ADD_TECHNICAL_EVALUATION_SUCCESS",
  ADD_TECHNICAL_EVALUATION_ERROR: "ADD_TECHNICAL_EVALUATION_ERROR",

  SET_ADD_FINANCIAL_EVALUATION_DATA: "SET_ADD_FINANCIAL_EVALUATION_DATA",
  ADD_FINANCIAL_EVALUATION_REQUEST: "ADD_FINANCIAL_EVALUATION_REQUEST",
  ADD_FINANCIAL_EVALUATION_SUCCESS: "ADD_FINANCIAL_EVALUATION_SUCCESS",
  ADD_FINANCIAL_EVALUATION_ERROR: "ADD_FINANCIAL_EVALUATION_ERROR",

  DELETE_BID_EVALUATION_REQUEST: "DELETE_BID_EVALUATION_REQUEST",
  DELETE_BID_EVALUATION_SUCCESS: "DELETE_BID_EVALUATION_SUCCESS",
  DELETE_BID_EVALUATION_ERROR: "DELETE_BID_EVALUATION_ERROR",

  DELETE_ELIGIBILITY_EVALUATION_REQUEST:
    "DELETE_ELIGIBILITY_EVALUATION_REQUEST",
  DELETE_ELIGIBILITY_EVALUATION_SUCCESS:
    "DELETE_ELIGIBILITY_EVALUATION_SUCCESS",
  DELETE_ELIGIBILITY_EVALUATION_ERROR: "DELETE_ELIGIBILITY_EVALUATION_ERROR",

  DELETE_TECHNICAL_EVALUATION_REQUEST: "DELETE_TECHNICAL_EVALUATION_REQUEST",
  DELETE_TECHNICAL_EVALUATION_SUCCESS: "DELETE_TECHNICAL_EVALUATION_SUCCESS",
  DELETE_TECHNICAL_EVALUATION_ERROR: "DELETE_TECHNICAL_EVALUATION_ERROR",

  DELETE_FINANCIAL_EVALUATION_REQUEST: "DELETE_FINANCIAL_EVALUATION_REQUEST",
  DELETE_FINANCIAL_EVALUATION_SUCCESS: "DELETE_FINANCIAL_EVALUATION_SUCCESS",
  DELETE_FINANCIAL_EVALUATION_ERROR: "DELETE_FINANCIAL_EVALUATION_ERROR",

  SET_UPDATE_BID_EVALUATION_UPDATE_DATA:
    "SET_UPDATE_BID_EVALUATION_UPDATE_DATA",
  UPDATE_BID_EVALUATION_REQUEST: "UPDATE_BID_EVALUATION_REQUEST",
  UPDATE_BID_EVALUATION_SUCCESS: "UPDATE_BID_EVALUATION_SUCCESS",
  UPDATE_BID_EVALUATION_ERROR: "UPDATE_BID_EVALUATION_ERROR",

  SET_UPDATE_ELIGIBILITY_EVALUATION_UPDATE_DATA:
    "SET_UPDATE_ELIGIBILITY_EVALUATION_UPDATE_DATA",
  UPDATE_ELIGIBILITY_EVALUATION_REQUEST:
    "UPDATE_ELIGIBILITY_EVALUATION_REQUEST",
  UPDATE_ELIGIBILITY_EVALUATION_SUCCESS:
    "UPDATE_ELIGIBILITY_EVALUATION_SUCCESS",
  UPDATE_ELIGIBILITY_EVALUATION_ERROR: "UPDATE_ELIGIBILITY_EVALUATION_ERROR",

  SET_UPDATE_TECHNICAL_EVALUATION_UPDATE_DATA:
    "SET_UPDATE_TECHNICAL_EVALUATION_UPDATE_DATA",
  UPDATE_TECHNICAL_EVALUATION_REQUEST: "UPDATE_TECHNICAL_EVALUATION_REQUEST",
  UPDATE_TECHNICAL_EVALUATION_SUCCESS: "UPDATE_TECHNICAL_EVALUATION_SUCCESS",
  UPDATE_TECHNICAL_EVALUATION_ERROR: "UPDATE_TECHNICAL_EVALUATION_ERROR",

  SET_UPDATE_FINANCIAL_EVALUATION_UPDATE_DATA:
    "SET_UPDATE_FINANCIAL_EVALUATION_UPDATE_DATA",
  UPDATE_FINANCIAL_EVALUATION_REQUEST: "UPDATE_FINANCIAL_EVALUATION_REQUEST",
  UPDATE_FINANCIAL_EVALUATION_SUCCESS: "UPDATE_FINANCIAL_EVALUATION_SUCCESS",
  UPDATE_FINANCIAL_EVALUATION_ERROR: "UPDATE_FINANCIAL_EVALUATION_ERROR",

  CHECK_BID_EVALUATION_REQUEST: "CHECK_BID_EVALUATION_REQUEST",
  CHECK_BID_EVALUATION_SUCCESS: "CHECK_BID_EVALUATION_SUCCESS",
  CHECK_BID_EVALUATION_ERROR: "CHECK_BID_EVALUATION_ERROR",

  APPROVE_BID_EVALUATION_REQUEST: "APPROVE_BID_EVALUATION_REQUEST",
  APPROVE_BID_EVALUATION_SUCCESS: "APPROVE_BID_EVALUATION_SUCCESS",
  APPROVE_BID_EVALUATION_ERROR: "APPROVE_BID_EVALUATION_ERROR",

  getBidEvaluations: () => ({
    type: bidEvaluations.GET_BID_EVALUATIONS_REQUEST,
  }),
  getEligibilityEvaluations: (id) => ({
    type: bidEvaluations.GET_ELIGIBILITY_EVALUATIONS_REQUEST,
    id,
  }),
  getTechnicalEvaluations: (id) => ({
    type: bidEvaluations.GET_TECHNICAL_EVALUATIONS_REQUEST,
    id,
  }),
  getFinancialEvaluations: (id) => ({
    type: bidEvaluations.GET_FINANCIAL_EVALUATIONS_REQUEST,
    id,
  }),
  getBidEvaluationsMetaData: () => ({
    type: bidEvaluations.GET_BID_EVALUATIONS_META_DATA_REQUEST,
  }),
  getEligibilityEvaluationsMetaData: () => ({
    type: bidEvaluations.GET_ELIGIBILITY_EVALUATIONS_META_DATA_REQUEST,
  }),
  getTechnicalEvaluationsMetaData: (data) => ({
    type: bidEvaluations.GET_TECHNICAL_EVALUATIONS_META_DATA_REQUEST,
    data,
  }),
  getFinancialEvaluationsMetaData: (data) => ({
    type: bidEvaluations.GET_FINANCIAL_EVALUATIONS_META_DATA_REQUEST,
    data,
  }),
  setAddBidEvaluationData: (data) => ({
    type: bidEvaluations.SET_ADD_BID_EVALUATION_DATA,
    data,
  }),
  setAddEligibilityEvaluationData: (data) => ({
    type: bidEvaluations.SET_ADD_ELIGIBILITY_EVALUATION_DATA,
    data,
  }),
  setAddTechnicalEvaluationData: (data) => ({
    type: bidEvaluations.SET_ADD_TECHNICAL_EVALUATION_DATA,
    data,
  }),
  setAddFinancialEvaluationData: (data) => ({
    type: bidEvaluations.SET_ADD_FINANCIAL_EVALUATION_DATA,
    data,
  }),
  addBidEvaluation: (data) => ({
    type: bidEvaluations.ADD_BID_EVALUATION_REQUEST,
    data,
  }),
  addEligibilityEvaluation: (data) => ({
    type: bidEvaluations.ADD_ELIGIBILITY_EVALUATION_REQUEST,
    data,
  }),
  addTechnicalEvaluation: (data) => ({
    type: bidEvaluations.ADD_TECHNICAL_EVALUATION_REQUEST,
    data,
  }),
  addFinancialEvaluation: (data) => ({
    type: bidEvaluations.ADD_FINANCIAL_EVALUATION_REQUEST,
    data,
  }),
  deleteBidEvaluation: (data) => ({
    type: bidEvaluations.DELETE_BID_EVALUATION_REQUEST,
    data,
  }),
  deleteEligibilityEvaluation: (bidId, id) => ({
    type: bidEvaluations.DELETE_ELIGIBILITY_EVALUATION_REQUEST,
    bidId,
    id,
  }),
  deleteTechnicalEvaluation: (bidId, id) => ({
    type: bidEvaluations.DELETE_TECHNICAL_EVALUATION_REQUEST,
    bidId,
    id,
  }),
  deleteFinancialEvaluation: (bidId, id) => ({
    type: bidEvaluations.DELETE_FINANCIAL_EVALUATION_REQUEST,
    bidId,
    id,
  }),
  updateBidEvaluation: (data, id) => ({
    type: bidEvaluations.UPDATE_BID_EVALUATION_REQUEST,
    data,
    id,
  }),
  updateEligibilityEvaluation: (data, id) => ({
    type: bidEvaluations.UPDATE_ELIGIBILITY_EVALUATION_REQUEST,
    data,
    id,
  }),
  updateTechnicalEvaluation: (data, id) => ({
    type: bidEvaluations.UPDATE_TECHNICAL_EVALUATION_REQUEST,
    data,
    id,
  }),
  updateFinancialEvaluation: (data, id) => ({
    type: bidEvaluations.UPDATE_FINANCIAL_EVALUATION_REQUEST,
    data,
    id,
  }),
  setUpdateBidEvaluationData: (data) => ({
    type: bidEvaluations.SET_UPDATE_BID_EVALUATION_UPDATE_DATA,
    data,
  }),
  setUpdateEligibilityEvaluationData: (data) => ({
    type: bidEvaluations.SET_UPDATE_ELIGIBILITY_EVALUATION_UPDATE_DATA,
    data,
  }),
  setUpdateTechnicalEvaluationData: (data) => ({
    type: bidEvaluations.SET_UPDATE_TECHNICAL_EVALUATION_UPDATE_DATA,
    data,
  }),
  setUpdateFinancialEvaluationData: (data) => ({
    type: bidEvaluations.SET_UPDATE_FINANCIAL_EVALUATION_UPDATE_DATA,
    data,
  }),
  getBidEvaluationDetails: (data) => ({
    type: bidEvaluations.GET_BID_EVALUATION_DETAILS_REQUEST,
    data,
  }),
  getEligibilityEvaluationDetails: (data) => ({
    type: bidEvaluations.GET_ELIGIBILITY_EVALUATION_DETAILS_REQUEST,
    data,
  }),
  getTechnicalEvaluationDetails: (data) => ({
    type: bidEvaluations.GET_TECHNICAL_EVALUATION_DETAILS_REQUEST,
    data,
  }),
  getFinancialEvaluationDetails: (data) => ({
    type: bidEvaluations.GET_FINANCIAL_EVALUATION_DETAILS_REQUEST,
    data,
  }),
  checkBidEvaluation: (data) => ({
    type: bidEvaluations.CHECK_BID_EVALUATION_REQUEST,
    data,
  }),
  approveBidEvaluation: (data) => ({
    type: bidEvaluations.APPROVE_BID_EVALUATION_REQUEST,
    data,
  }),
};

export default bidEvaluations;
