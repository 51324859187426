import { incomeReceiptActions } from "../../../actions";
import initialState from "../../../initialState";

const incomeReceipts = (state = initialState.incomeReceipts, actions) => {
  switch (actions.type) {
    case incomeReceiptActions.SYNC_SCHOOL_PAY_TRANSACTIONS_REQUEST:
      return {
        ...state,
        syncSchoolPayTransactionsLoading: true,
        syncSchoolPayTransactionsError: {},
      };
    case incomeReceiptActions.SYNC_SCHOOL_PAY_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        syncSchoolPayTransactionsSuccess: actions.data,
        syncSchoolPayTransactionsLoading: false,
      };
    case incomeReceiptActions.SYNC_SCHOOL_PAY_TRANSACTIONS_ERROR:
      return {
        ...state,
        syncSchoolPayTransactionsError: actions.error,
        syncSchoolPayTransactionsLoading: false,
      };
    case incomeReceiptActions.DOWNLOAD_EXCEL_REQUEST:
      return {
        ...state,
        downloadExcelLoading: true,
        downloadExcelError: {},
      };
    case incomeReceiptActions.DOWNLOAD_EXCEL_SUCCESS:
      return {
        ...state,
        downloadExcelLoading: false,
        downloadExcelSuccess: {},
      };
    case incomeReceiptActions.DOWNLOAD_EXCEL_ERROR:
      return {
        ...state,
        downloadExcelError: actions.error,
        downloadExcelLoading: false,
      };
    case incomeReceiptActions.UPLOAD_EXCEL_REQUEST:
      return {
        ...state,
        uploadExcelLoading: true,
        uploadExcelError: {},
      };
    case incomeReceiptActions.UPLOAD_EXCEL_SUCCESS:
      return {
        ...state,
        uploadExcelSuccess: actions.data,
        uploadExcelLoading: false,
      };
    case incomeReceiptActions.UPLOAD_EXCEL_ERROR:
      return {
        ...state,
        uploadExcelLoading: false,
        uploadExcelError: actions.error,
      };
    case incomeReceiptActions.GET_INCOME_RECEIPTS_REQUEST:
      return {
        ...state,
        incomeReceiptsLoading: true,
        incomeReceiptsError: {},
      };
    case incomeReceiptActions.GET_INCOME_RECEIPTS_SUCCESS:
      return {
        ...state,
        incomeReceiptsLoading: false,
        incomeReceiptsSuccess: actions.data,
      };
    case incomeReceiptActions.GET_INCOME_RECEIPTS_ERROR:
      return {
        ...state,
        incomeReceiptsLoading: false,
        incomeReceiptsError: actions.error,
      };
    case incomeReceiptActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case incomeReceiptActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: actions.data,
        metaDataLoading: false,
      };
    case incomeReceiptActions.ADD_INCOME_RECEIPTS_REQUEST:
      return {
        ...state,
        addIncomeReceiptLoading: true,
        addIncomeReceiptError: {},
      };
    case incomeReceiptActions.ADD_INCOME_RECEIPTS_SUCCESS:
      return {
        ...state,
        addIncomeReceiptSuccess: actions.data,
        addIncomeReceiptLoading: false,
        incomeReceiptsSuccess: {
          incomeReceipts: [
            actions.data,
            ...state.incomeReceiptsSuccess.incomeReceipts,
          ],
        },
      };
    case incomeReceiptActions.ADD_INCOME_RECEIPTS_ERROR:
      return {
        ...state,
        addIncomeReceiptError: actions.error,
        addIncomeReceiptLoading: false,
      };
    case incomeReceiptActions.SEARCH_INCOME_RECEIPT_REQUEST:
      return {
        ...state,
        searchIncomeReceiptsLoading: true,
        searchIncomeReceiptsError: {},
      };
    case incomeReceiptActions.SEARCH_INCOME_RECEIPT_SUCCESS:
      return {
        ...state,
        searchIncomeReceiptsSuccess: actions.data,
        searchIncomeReceiptsLoading: false,
      };
    case incomeReceiptActions.SEARCH_INCOME_RECEIPT_ERROR:
      return {
        ...state,
        searchIncomeReceiptsLoading: false,
        searchIncomeReceiptsError: actions.error,
      };
    case incomeReceiptActions.SET_UPDATE_INCOME_RECEIPT_DATA:
      return {
        ...state,
        updateIncomeReceiptData: actions.data,
      };
    case incomeReceiptActions.UPDATE_INCOME_RECEIPT_REQUEST:
      return {
        ...state,
        updateIncomeReceiptLoading: true,
        updateIncomeReceiptError: {},
      };
    case incomeReceiptActions.UPDATE_INCOME_RECEIPT_SUCCESS:
      return {
        ...state,
        updateIncomeReceiptSuccess: actions.data,
        updateIncomeReceiptLoading: false,
      };
    case incomeReceiptActions.UPDATE_INCOME_RECEIPT_ERROR:
      return {
        ...state,
        updateIncomeReceiptLoading: false,
        updateIncomeReceiptError: actions.data,
      };
    case incomeReceiptActions.DELETE_INCOME_RECEIPT_REQUEST:
      return {
        ...state,
        deleteIncomeReceiptLoading: true,
        deleteIncomeReceiptError: {},
      };
    case incomeReceiptActions.DELETE_INCOME_RECEIPT_SUCCESS:
      return {
        ...state,
        deleteIncomeReceiptSuccess: actions.data,
        deleteIncomeReceiptLoading: false,
      };
    case incomeReceiptActions.DELETE_INCOME_RECEIPT_ERROR:
      return {
        ...state,
        deleteIncomeReceiptError: actions.error,
        deleteIncomeReceiptLoading: false,
      };
    case incomeReceiptActions.GET_INCOME_RECEIPT_DETAILS_REQUEST:
      return {
        ...state,
        getIncomeReceiptDetailsLoading: true,
        getIncomeReceiptDetailsError: {},
      };
    case incomeReceiptActions.GET_INCOME_RECEIPT_DETAILS_SUCCESS:
      return {
        ...state,
        getIncomeReceiptDetailsSuccess: actions.data,
        getIncomeReceiptDetailsLoading: false,
      };
    case incomeReceiptActions.GET_INCOME_RECEIPT_DETAILS_ERROR:
      return {
        ...state,
        getIncomeReceiptDetailsError: actions.error,
        getIncomeReceiptDetailsLoading: false,
      };
    case incomeReceiptActions.GET_INCOME_RECEIPT_STREAMS_REQUEST:
      return {
        ...state,
        getIncomeReceiptStreamsLoading: true,
        getIncomeReceiptStreamsError: {},
      };
    case incomeReceiptActions.GET_INCOME_RECEIPT_STREAMS_SUCCESS:
      return {
        ...state,
        getIncomeReceiptStreamsSuccess: actions.data,
        getIncomeReceiptStreamsLoading: false,
      };
    case incomeReceiptActions.GET_INCOME_RECEIPT_STREAMS_ERROR:
      return {
        ...state,
        getIncomeReceiptStreamsError: actions.error,
        getIncomeReceiptStreamsLoading: false,
      };
    case incomeReceiptActions.ADD_INCOME_RECEIPT_STREAM_REQUEST:
      return {
        ...state,
        addIncomeReceiptStreamLoading: true,
        addIncomeReceiptStreamError: {},
      };
    case incomeReceiptActions.ADD_INCOME_RECEIPT_STREAM_SUCCESS:
      return {
        ...state,
        addIncomeReceiptStreamSuccess: actions.data,
        addIncomeReceiptStreamLoading: false,
      };
    case incomeReceiptActions.ADD_INCOME_RECEIPT_STREAM_ERROR:
      return {
        ...state,
        addIncomeReceiptStreamError: actions.error,
        addIncomeReceiptStreamLoading: false,
      };
    case incomeReceiptActions.SET_UPDATE_INCOME_RECEIPT_STREAM_DATA:
      return {
        ...state,
        updateIncomeReceiptStreamData: actions.data,
      };
    case incomeReceiptActions.UPDATE_INCOME_RECEIPT_STREAM_REQUEST:
      return {
        ...state,
        updateIncomeReceiptStreamLoading: true,
        updateIncomeReceiptStreamError: {},
      };
    case incomeReceiptActions.UPDATE_INCOME_RECEIPT_STREAM_SUCCESS:
      return {
        ...state,
        updateIncomeReceiptStreamSuccess: actions.data,
        updateIncomeReceiptStreamLoading: false,
      };
    case incomeReceiptActions.UPDATE_INCOME_RECEIPT_STREAM_ERROR:
      return {
        ...state,
        updateIncomeReceiptStreamError: actions.error,
        updateIncomeReceiptStreamLoading: false,
      };
    case incomeReceiptActions.DELETE_INCOME_RECEIPT_STREAM_REQUEST:
      return {
        ...state,
        deleteIncomeReceiptStreamLoading: true,
        deleteIncomeReceiptStreamError: {},
      };
    case incomeReceiptActions.DELETE_INCOME_RECEIPT_STREAM_SUCCESS:
      return {
        ...state,
        deleteIncomeReceiptStreamSuccess: actions.data,
        deleteIncomeReceiptStreamLoading: false,
        getIncomeReceiptStreamsSuccess: {
          incomeReceiptStreams: actions.incomeReceiptStreams,
        },
      };
    case incomeReceiptActions.DELETE_INCOME_RECEIPT_STREAM_ERROR:
      return {
        ...state,
        deleteIncomeReceiptStreamError: actions.error,
        deleteIncomeReceiptStreamLoading: false,
      };
    case incomeReceiptActions.GET_INCOME_RECEIPT_STREAM_DETAILS_REQUEST:
      return {
        ...state,
        getIncomeReceiptStreamDetailsLoading: true,
        getIncomeReceiptStreamDetailsError: {},
      };
    case incomeReceiptActions.GET_INCOME_RECEIPT_STREAM_DETAILS_SUCCESS:
      return {
        ...state,
        getIncomeReceiptStreamDetailsSuccess: actions.data,
        getIncomeReceiptStreamDetailsLoading: false,
      };
    case incomeReceiptActions.GET_INCOME_RECEIPT_STREAM_DETAILS_ERROR:
      return {
        ...state,
        getIncomeReceiptStreamDetailsError: actions.error,
        getIncomeReceiptStreamDetailsLoading: false,
      };
    case incomeReceiptActions.ADD_INCOME_RECEIPT_DOCUMENT_REQUEST:
      return {
        ...state,
        addIncomeReceiptDocumentLoading: true,
        addIncomeReceiptDocumentError: {},
      };
    case incomeReceiptActions.ADD_INCOME_RECEIPT_DOCUMENT_ERROR:
      return {
        ...state,
        addIncomeReceiptDocumentError: actions.error,
        addIncomeReceiptDocumentLoading: false,
      };
    case incomeReceiptActions.ADD_INCOME_RECEIPT_DOCUMENT_SUCCESS:
      return {
        ...state,
        addIncomeReceiptDocumentSuccess: actions.data,
        addIncomeReceiptDocumentLoading: false,
      };
    case incomeReceiptActions.DELETE_INCOME_RECEIPT_DOCUMENT_REQUEST:
      return {
        ...state,
        deleteIncomeReceiptDocumentLoading: true,
        deleteIncomeReceiptDocumentError: {},
      };
    case incomeReceiptActions.DELETE_INCOME_RECEIPT_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleteIncomeReceiptDocumentSuccess: actions.data,
        deleteIncomeReceiptDocumentLoading: false,
      };
    case incomeReceiptActions.DELETE_INCOME_RECEIPT_DOCUMENT_ERROR:
      return {
        ...state,
        deleteIncomeReceiptDocumentError: actions.error,
        deleteIncomeReceiptDocumentLoading: false,
      };
    case incomeReceiptActions.SORT_INCOME_RECEIPTS_REQUEST:
      return {
        ...state,
        sortIncomeReceiptsLoading: true,
        sortIncomeReceiptsError: {},
      };
    case incomeReceiptActions.SORT_INCOME_RECEIPTS_SUCCESS:
      return {
        ...state,
        sortIncomeReceiptsSuccess: actions.data,
        sortIncomeReceiptsLoading: false,
      };
    case incomeReceiptActions.SORT_INCOME_RECEIPTS_ERROR:
      return {
        ...state,
        sortIncomeReceiptsError: actions.error,
        sortIncomeReceiptsLoading: false,
      };
    case incomeReceiptActions.DOWNLOAD_INCOME_RECEIPTS_REQUEST:
      return {
        ...state,
        downloadIncomeReceiptsLoading: true,
        downloadIncomeReceiptsError: {},
      };
    case incomeReceiptActions.DOWNLOAD_INCOME_RECEIPTS_SUCCESS:
      return {
        ...state,
        downloadIncomeReceiptsSuccess: actions.data,
        downloadIncomeReceiptsLoading: false,
      };
    case incomeReceiptActions.DOWNLOAD_INCOME_RECEIPTS_ERROR:
      return {
        ...state,
        downloadIncomeReceiptsError: actions.error,
        downloadIncomeReceiptsLoading: false,
      };
    case incomeReceiptActions.SET_DOWNLOAD_QUERY_DATA:
      return {
        ...state,
        setDownloadQueryData: actions.data,
      };
    case incomeReceiptActions.IMPORT_SCHOOL_PAY_EXCEL_REQUEST:
      return {
        ...state,
        importSchoolPayReceiptsLoading: true,
        importSchoolPayReceiptsError: {},
      };
    case incomeReceiptActions.IMPORT_SCHOOL_PAY_EXCEL_SUCCESS:
      return {
        ...state,
        importSchoolPayReceiptsSuccess: actions.data,
        importSchoolPayReceiptsLoading: false,
      };
    case incomeReceiptActions.IMPORT_SCHOOL_PAY_EXCEL_ERROR:
      return {
        ...state,
        importSchoolPayReceiptsError: actions.error,
        importSchoolPayReceiptsLoading: false,
      };
    default:
      return state;
  }
};

export default incomeReceipts;
