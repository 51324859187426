const subjects = {
  GET_SUBJECTS_REQUEST: "GET_SUBJECTS_REQUEST",
  GET_SUBJECTS_SUCCESS: "GET_SUBJECTS_SUCCESS",
  GET_SUBJECTS_ERROR: "GET_SUBJECTS_ERROR",

  ADD_SUBJECT_REQUEST: "ADD_SUBJECT_REQUEST",
  ADD_SUBJECT_SUCCESS: "ADD_SUBJECT_SUCCESS",
  ADD_SUBJECT_ERROR: "ADD_SUBJECT_ERROR",

  GET_SUBJECT_DETAILS_REQUEST: "GET_SUBJECT_DETAILS_REQUEST",
  GET_SUBJECT_DETAILS_SUCCESS: "GET_SUBJECT_DETAILS_SUCCESS",
  GET_SUBJECT_DETAILS_ERROR: "GET_SUBJECT_DETAILS_ERROR",

  GET_SUBJECTS_META_DATA_REQUEST: "GET_SUBJECTS_META_DATA_REQUEST",
  GET_SUBJECTS_META_DATA_SUCCESS: "GET_SUBJECTS_META_DATA_SUCCESS",
  GET_SUBJECTS_META_DATA_ERROR: "GET_SUBJECTS_META_DATA_ERROR",

  DELETE_SUBJECT_REQUEST: "DELETE_SUBJECT_REQUEST",
  DELETE_SUBJECT_SUCCESS: "DELETE_SUBJECT_SUCCESS",
  DELETE_SUBJECT_ERROR: "DELETE_SUBJECT_ERROR",

  UPDATE_SUBJECT_DETAILS_REQUEST: "UPDATE_SUBJECT_DETAILS_REQUEST",
  UPDATE_SUBJECT_DETAILS_SUCCESS: "UPDATE_SUBJECT_DETAILS_SUCCESS",
  UPDATE_SUBJECT_DETAILS_ERROR: "UPDATE_SUBJECT_DETAILS_ERROR",

  SET_UPDATE_SUBJECT_DETAILS_DATA: "SET_UPDATE_SUBJECT_DETAILS_DATA",

  CHECK_SUBJECT_REQUEST: "CHECK_SUBJECT_REQUEST",
  CHECK_SUBJECT_SUCCESS: "CHECK_SUBJECT_SUCCESS",
  CHECK_SUBJECT_ERROR: "CHECK_SUBJECT_ERROR",

  APPROVE_SUBJECT_REQUEST: "APPROVE_SUBJECT_REQUEST",
  APPROVE_SUBJECT_SUCCESS: "APPROVE_SUBJECT_SUCCESS",
  APPROVE_SUBJECT_ERROR: "APPROVE_SUBJECT_ERROR",

  CREATE_SUBJECTS_REQUEST: "CREATE_SUBJECTS_REQUEST",
  CREATE_SUBJECTS_SUCCESS: "CREATE_SUBJECTS_SUCCESS",
  CREATE_SUBJECTS_ERROR: "CREATE_SUBJECTS_ERROR",

  getSubjects: () => ({
    type: subjects.GET_SUBJECTS_REQUEST,
  }),

  createSubjects: () => ({
    type: subjects.CREATE_SUBJECTS_REQUEST,
  }),

  addSubject: (data) => ({
    type: subjects.ADD_SUBJECT_REQUEST,
    data,
  }),

  getSubjectDetails: (id) => ({
    type: subjects.GET_SUBJECT_DETAILS_REQUEST,
    id,
  }),

  getSubjectsMetaData: () => ({
    type: subjects.GET_SUBJECTS_META_DATA_REQUEST,
  }),

  deleteSubject: (id, SubjectsArray) => ({
    type: subjects.DELETE_SUBJECT_REQUEST,
    id,
    subjects: SubjectsArray,
  }),

  updateSubject: (id, data) => ({
    type: subjects.UPDATE_SUBJECT_DETAILS_REQUEST,
    id,
    data,
  }),

  checkSubject: (id) => ({
    type: subjects.CHECK_SUBJECT_REQUEST,
    id,
  }),

  approveSubject: (id) => ({
    type: subjects.APPROVE_SUBJECT_REQUEST,
    id,
  }),

  setUpdateSubjectDetailsData: (data) => ({
    type: subjects.SET_UPDATE_SUBJECT_DETAILS_DATA,
    data,
  }),
};

export default subjects;
