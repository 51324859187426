const financeStreamsInitialState = {
  financeStreams: {
    financeStreamsLoading: false,
    financeStreamsSuccess: {},
    financeStreamsError: {},

    metaDataLoading: false,
    metaDataSuccess: {},

    addFinanceStreamsLoading: false,
    addFinanceStreamsSuccess: {},
    addFinanceStreamsError: {},

    deleteFinanceStreamLoading: false,
    deleteFinanceStreamSuccess: {},
    deleteFinanceStreamError: {},

    updateFinanceStreamLoading: false,
    updateFinanceStreamData: {},
    updateFinanceStreamSuccess: {},
    updateFinanceStreamError: {},

    financeStreamDetailsLoading: false,
    financeStreamDetailsSuccess: {},
    financeStreamDetailsError: {},

    checkFinanceStreamLoading: false,
    checkFinanceStreamSuccess: {},
    checkFinanceStreamError: {},

    approveFinanceStreamSuccess: {},
    approveFinanceStreamLoading: false,
    approveFinanceStreamError: {},
  },
};

export default financeStreamsInitialState;
