import { takeLatest, put, fork, takeEvery } from "@redux-saga/core/effects";
import axios from "axios";
import { appUiActions, loanRepaymentScheduleActions } from "../../actions";

function* getLoanRepaymentSchedules() {
  try {
    const response = yield axios({
      url: "/finance/loans-payment-schedule",
    });

    yield put({
      type: loanRepaymentScheduleActions.GET_LOAN_REPAYMENT_SCHEDULE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: loanRepaymentScheduleActions.GET_LOAN_REPAYMENT_SCHEDULE_ERROR,
      data: error.data,
    });
  }
}

function* watchGetLoanRepaymentSchedules() {
  yield takeLatest(
    loanRepaymentScheduleActions.GET_LOAN_REPAYMENT_SCHEDULE_REQUEST,
    getLoanRepaymentSchedules
  );
}

function* getLoanRepaymentScheduleDetails({ data }) {
  try {
    const response = yield axios({
      url: `/finance/loans-payment-schedule/${data}`,
    });

    yield put({
      type: loanRepaymentScheduleActions.GET_LOAN_REPAYMENT_SCHEDULE_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: loanRepaymentScheduleActions.GET_LOAN_REPAYMENT_SCHEDULE_DETAILS_ERROR,
      data: error.data,
    });
  }
}

function* watchGetLoanRepaymentScheduleDetails() {
  yield takeLatest(
    loanRepaymentScheduleActions.GET_LOAN_REPAYMENT_SCHEDULE_DETAILS_REQUEST,
    getLoanRepaymentScheduleDetails
  );
}

function* getMetaData() {
  try {
    const response = yield axios({
      url: `/finance/loans-payment-schedule/meta-data`,
    });

    yield put({
      type: loanRepaymentScheduleActions.GET_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: loanRepaymentScheduleActions.GET_META_DATA_ERROR,
      data: error.data,
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(
    loanRepaymentScheduleActions.GET_META_DATA_REQUEST,
    getMetaData
  );
}

function* addLoanRepaymentSchedule({ data }) {
  try {
    const response = yield axios({
      method: "POST",
      url: "/finance/loans-payment-schedule",
      data,
    });

    yield put({
      type: loanRepaymentScheduleActions.ADD_LOAN_REPAYMENT_SCHEDULE_SUCCESS,
      data: response,
    });
    yield put(
      loanRepaymentScheduleActions.getLoanRepaymentScheduleDetails(response?.id)
    );
    yield put(loanRepaymentScheduleActions.getLoanRepaymentSchedules());
    yield put(appUiActions.toggleLoanScheduleDetailsModal(true));
    yield put(appUiActions.toggleAddLoanPaymentModal(false));
  } catch (error) {
    yield put({
      type: loanRepaymentScheduleActions.ADD_LOAN_REPAYMENT_SCHEDULE_ERROR,
      data: error.data,
    });
  }
}

function* watchAddLoanRepaymentSchedule() {
  yield takeEvery(
    loanRepaymentScheduleActions.ADD_LOAN_REPAYMENT_SCHEDULE_REQUEST,
    addLoanRepaymentSchedule
  );
}

function* deleteLoanRepaymentSchedule({ data }) {
  try {
    const response = yield axios({
      method: "DELETE",
      url: `/finance/loans-payment-schedule/${data}`,
    });

    yield put({
      type: loanRepaymentScheduleActions.DELETE_LOAN_REPAYMENT_SCHEDULE_SUCCESS,
      data: response,
    });
    yield put(loanRepaymentScheduleActions.getLoanRepaymentSchedules());
  } catch (error) {
    yield put({
      type: loanRepaymentScheduleActions.DELETE_LOAN_REPAYMENT_SCHEDULE_ERROR,
      data: error.data,
    });
  }
}

function* watchDeleteLoanRepaymentSchedule() {
  yield takeEvery(
    loanRepaymentScheduleActions.DELETE_LOAN_REPAYMENT_SCHEDULE_REQUEST,
    deleteLoanRepaymentSchedule
  );
}

function* addLoanPayment({ data }) {
  try {
    const response = yield axios({
      method: "POST",
      url: `/finance/loans-payment-schedule/payments`,
      data,
    });

    yield put({
      type: loanRepaymentScheduleActions.ADD_LOAN_PAYMENT_SUCCESS,
      data: response,
    });
    yield put(loanRepaymentScheduleActions.getLoanRepaymentSchedules());
  } catch (error) {
    yield put({
      type: loanRepaymentScheduleActions.ADD_LOAN_PAYMENT_ERROR,
      data: error.data,
    });
  }
}

function* watchAddLoanPayment() {
  yield takeEvery(
    loanRepaymentScheduleActions.ADD_LOAN_PAYMENT_REQUEST,
    addLoanPayment
  );
}

function* deleteLoanPayment({ data }) {
  try {
    const response = yield axios({
      method: "DELETE",
      url: `/finance/loans-payment-schedule/payments/${data}`,
    });

    yield put({
      type: loanRepaymentScheduleActions.DELETE_LOAN_PAYMENT_SUCCESS,
      data: response,
    });
    yield put(loanRepaymentScheduleActions.getLoanRepaymentSchedules());
  } catch (error) {
    yield put({
      type: loanRepaymentScheduleActions.DELETE_LOAN_PAYMENT_ERROR,
      data: error.data,
    });
  }
}

function* watchDeleteLoanPayment() {
  yield takeEvery(
    loanRepaymentScheduleActions.DELETE_LOAN_PAYMENT_REQUEST,
    deleteLoanPayment
  );
}

export default [
  fork(watchGetLoanRepaymentSchedules),
  fork(watchGetLoanRepaymentScheduleDetails),
  fork(watchAddLoanRepaymentSchedule),
  fork(watchDeleteLoanRepaymentSchedule),
  fork(watchAddLoanPayment),
  fork(watchDeleteLoanPayment),
  fork(watchGetMetaData),
];
