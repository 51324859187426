import { takeLatest, put, fork, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  enrollmentActions,
  schoolCommunicationsActions,
} from "../../../actions";
const FileDownload = require("js-file-download");

function* getEnrollments() {
  try {
    const response = yield axios({
      url: "/administration/enrollment",
      method: "GET",
    });

    yield put({
      type: enrollmentActions.GET_ENROLLMENTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: enrollmentActions.GET_ENROLLMENTS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetEnrollments() {
  yield takeLatest(enrollmentActions.GET_ENROLLMENTS_REQUEST, getEnrollments);
}

function* deEnrollLearner({ id, data }) {
  try {
    const response = yield axios({
      url: `/administration/enrollment/de-enroll-learner/${id}`,
      method: "PATCH",
      data,
    });

    yield put({
      type: enrollmentActions.DE_ENROLL_LEARNER_SUCCESS,
      data: response,
      studentId: id,
      updatedData: data,
    });
  } catch (error) {
    yield put({
      type: enrollmentActions.DE_ENROLL_LEARNER_ERROR,
      error: error.data,
    });
  }
}

function* downloadLearnerDetailsExcel({ enrollment }) {
  try {
    const response = yield axios({
      url: `/administration/enrollment/download-learner-details-excel/${enrollment?.id}`,
      method: "GET",
      responseType: "blob",
    });

    FileDownload(
      response?.data,
      `${enrollment?.academic_year?.name} ${enrollment?.school_class?.name} ${
        enrollment?.stream?.name || ""
      } LEARNER DETAILS-${Date.now()}.xlsx`
    );

    yield put({
      type: enrollmentActions.DOWNLOAD_LEARNER_DETAILS_EXCEL_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: enrollmentActions.DOWNLOAD_LEARNER_DETAILS_EXCEL_ERROR,
      error: error?.data,
    });
  }
}

function* watchDownloadLearnerDetailsExcel() {
  yield takeLatest(
    enrollmentActions.DOWNLOAD_LEARNER_DETAILS_EXCEL_REQUEST,
    downloadLearnerDetailsExcel
  );
}

function* watchDeEnrollLearner() {
  yield takeLatest(
    enrollmentActions.DE_ENROLL_LEARNER_REQUEST,
    deEnrollLearner
  );
}

function* getEnrollmentDetails({ id }) {
  try {
    const response = yield axios({
      url: `/administration/enrollment/${id}`,
      method: "GET",
    });

    yield put({
      type: enrollmentActions.GET_ENROLLMENT_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: enrollmentActions.GET_ENROLLMENT_DETAILS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetEnrollmentDetails() {
  yield takeLatest(
    enrollmentActions.GET_ENROLLMENT_DETAILS_REQUEST,
    getEnrollmentDetails
  );
}

function* getEnrollmentMetaData() {
  try {
    const response = yield axios({
      url: "/administration/enrollment/meta-data",
      method: "GET",
    });

    yield put({
      type: enrollmentActions.GET_ENROLLMENT_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: enrollmentActions.GET_ENROLLMENT_META_DATA_ERROR,
      error: error.data,
    });
  }
}
function* watchGetEnrollmentMetaData() {
  yield takeLatest(
    enrollmentActions.GET_ENROLLMENT_META_DATA_REQUEST,
    getEnrollmentMetaData
  );
}

function* addEnrollment({ data }) {
  try {
    const response = yield axios({
      url: "/administration/enrollment",
      method: "POST",
      data: data,
    });

    yield put({
      type: enrollmentActions.ADD_ENROLLMENT_SUCCESS,
      data: response,
    });
    yield put(enrollmentActions.getEnrollments());
    yield put(schoolCommunicationsActions.getMetaData());
  } catch (error) {
    yield put({
      type: enrollmentActions.ADD_ENROLLMENT_ERROR,
      error: error.data,
    });
  }
}
function* watchAddEnrollment() {
  yield takeLatest(enrollmentActions.ADD_ENROLLMENT_REQUEST, addEnrollment);
}

function* enrollStudents({ data }) {
  try {
    const response = yield axios({
      url: "/administration/enrollment/students",
      method: "POST",
      data: data,
    });
    yield put({
      type: enrollmentActions.ENROLL_STUDENTS_SUCCESS,
      data: response,
    });
    yield put(enrollmentActions.getEnrollments());
  } catch (error) {
    yield put({
      type: enrollmentActions.ENROLL_STUDENTS_ERROR,
      error: error.data,
    });
  }
}
function* watchEnrollStudents() {
  yield takeLatest(enrollmentActions.ENROLL_STUDENTS_REQUEST, enrollStudents);
}

function* deleteEnrollment({ id }) {
  try {
    const response = yield axios({
      url: `/administration/enrollment/${id}`,
      method: "DELETE",
    });
    yield put({
      type: enrollmentActions.DELETE_ENROLLMENT_SUCCESS,
      data: response,
    });
    yield put(enrollmentActions.getEnrollments());
  } catch (error) {
    yield put({
      type: enrollmentActions.DELETE_ENROLLMENT_ERROR,
      error: error.data,
    });
  }
}
function* watchDeleteEnrollment() {
  yield takeLatest(
    enrollmentActions.DELETE_ENROLLMENT_REQUEST,
    deleteEnrollment
  );
}

function* unenrollStudent({ id }) {
  try {
    const response = yield axios({
      url: `/administration/enrollment/student/${id}`,
      method: "DELETE",
    });
    yield put({
      type: enrollmentActions.UNENROLL_STUDENT_SUCCESS,
      data: response,
    });
    yield put(enrollmentActions.getEnrollments());
  } catch (error) {
    yield put({
      type: enrollmentActions.UNENROLL_STUDENT_ERROR,
      error: error.data,
    });
  }
}
function* watchUnenrollStudent() {
  yield takeLatest(enrollmentActions.UNENROLL_STUDENT_REQUEST, unenrollStudent);
}

function* uploadEnrollmentDocument({ data }) {
  try {
    const response = yield axios({
      url: "/administration/enrollment/upload",
      method: "POST",
      data: data,
    });
    yield put({
      type: enrollmentActions.UPLOAD_ENROLLMENT_DOCUMENT_SUCCESS,
      data: response,
    });
    yield put(enrollmentActions.getEnrollments());
  } catch (error) {
    yield put({
      type: enrollmentActions.UPLOAD_ENROLLMENT_DOCUMENT_ERROR,
      error: error.data,
    });
  }
}
function* watchUploadEnrollmentDocument() {
  yield takeLatest(
    enrollmentActions.UPLOAD_ENROLLMENT_DOCUMENT_REQUEST,
    uploadEnrollmentDocument
  );
}

function* createStudentBilling({ data }) {
  try {
    const response = yield axios({
      url: "/administration/enrollment/billing",
      method: "POST",
      data,
    });

    yield put({
      type: enrollmentActions.CREATE_STUDENT_BILLING_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: enrollmentActions.CREATE_STUDENT_BILLING_ERROR,
    });
  }
}
function* updateEnrollment({ id, data }) {
  try {
    const response = yield axios({
      url: `/administration/enrollment/${id}`,
      method: "PUT",
      data: data,
    });
    yield put({
      type: enrollmentActions.UPDATE_ENROLLMENT_SUCCESS,
      data: response,
    });
    yield put(enrollmentActions.getEnrollments());
  } catch (error) {
    yield put({
      type: enrollmentActions.UPDATE_ENROLLMENT_ERROR,
      error: error.data,
    });
  }
}

function* watchCreateStudentBilling() {
  yield takeLatest(
    enrollmentActions.CREATE_STUDENT_BILLING_REQUEST,
    createStudentBilling
  );
}

function* deleteStudentBilling({ id }) {
  try {
    const response = yield axios({
      url: `/administration/enrollment/billing/${id}`,
      method: "DELETE",
    });

    yield put({
      type: enrollmentActions.DELETE_STUDENT_BILLING_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: enrollmentActions.DELETE_STUDENT_BILLING_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteBilling() {
  yield takeEvery(
    enrollmentActions.DELETE_STUDENT_BILLING_REQUEST,
    deleteStudentBilling
  );
}

function* watchUpdateEnrollment() {
  yield takeLatest(
    enrollmentActions.UPDATE_ENROLLMENT_REQUEST,
    updateEnrollment
  );
}

function* enrollSingleStudent({ data }) {
  try {
    const response = yield axios({
      url: "/administration/enrollment/single",
      method: "POST",
      data: data,
    });
    yield put({
      type: enrollmentActions.ENROLL_SINGLE_STUDENT_SUCCESS,
      data: response,
    });
    yield put(enrollmentActions.getEnrollments());
  } catch (error) {
    yield put({
      type: enrollmentActions.ENROLL_SINGLE_STUDENT_ERROR,
      error: error.data,
    });
  }
}

function* watchEnrollSingleStudent() {
  yield takeLatest(
    enrollmentActions.ENROLL_SINGLE_STUDENT_REQUEST,
    enrollSingleStudent
  );
}

function* searchStudents({ data }) {
  try {
    const response = yield axios({
      url: `/administration/enrollment/search?${data}`,
      method: "GET",
    });
    yield put({
      type: enrollmentActions.SEARCH_STUDENTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: enrollmentActions.SEARCH_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* watchSearchStudents() {
  yield takeLatest(enrollmentActions.SEARCH_STUDENTS_REQUEST, searchStudents);
}

function* joinClasses({ data }) {
  try {
    const response = yield axios({
      url: "/administration/enrollment/join-streams",
      data,
      method: "PATCH",
    });

    yield put({
      type: enrollmentActions.JOIN_CLASSES_SUCCESS,
      data: response,
    });
    yield put(enrollmentActions.getEnrollments());
  } catch (error) {
    yield put({
      type: enrollmentActions.JOIN_CLASSES_ERROR,
      error: error.data,
    });
  }
}

function* watchJoinClasses() {
  yield takeLatest(enrollmentActions.JOIN_CLASSES_REQUEST, joinClasses);
}

function* getLearnerProfile({ id }) {
  try {
    const response = yield axios({
      url: `/administration/enrollment/learner-profile`,
      params: {
        id: id,
      },
      method: "GET",
    });

    yield put({
      type: enrollmentActions.GET_LEARNER_PROFILE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: enrollmentActions.GET_LEARNER_PROFILE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetLearnerProfile() {
  yield takeLatest(
    enrollmentActions.GET_LEARNER_PROFILE_REQUEST,
    getLearnerProfile
  );
}

export default [
  fork(watchGetEnrollments),
  fork(watchGetEnrollmentDetails),
  fork(watchGetEnrollmentMetaData),
  fork(watchAddEnrollment),
  fork(watchEnrollStudents),
  fork(watchDeleteEnrollment),
  fork(watchUnenrollStudent),
  fork(watchUploadEnrollmentDocument),
  fork(watchDeleteBilling),
  fork(watchCreateStudentBilling),
  fork(watchUpdateEnrollment),
  fork(watchEnrollSingleStudent),
  fork(watchSearchStudents),
  fork(watchDeEnrollLearner),
  fork(watchDownloadLearnerDetailsExcel),
  fork(watchJoinClasses),
  fork(watchGetLearnerProfile),
];
