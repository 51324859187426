const organisationUsers = {
  GET_ORGANISATION_USERS_REQUEST: "GET_ORGANISATION_USERS_REQUEST",
  GET_ORGANISATION_USERS_SUCCESS: "GET_ORGANISATION_USERS_SUCCESS",
  GET_ORGANISATION_USERS_ERROR: "GET_ORGANISATION_USERS_ERROR",

  GET_META_DATA_REQUEST: "GET_META_DATA_REQUEST",
  GET_META_DATA_SUCCESS: "GET_META_DATA_SUCCESS",

  ADD_ORGANISATION_USERS_REQUEST: "ADD_ORGANISATION_USERS_REQUEST",
  ADD_ORGANISATION_USERS_SUCCESS: "ADD_ORGANISATION_USERS_SUCCESS",
  ADD_ORGANISATION_USERS_ERROR: "ADD_ORGANISATION_USERS_ERROR",

  SET_UPDATE_ORGANISATION_USERS_DATA: "SET_UPDATE_ORGANISATION_USERS_DATA",
  UPDATE_ORGANISATION_USERS_REQUEST: "UPDATE_ORGANISATION_USERS_REQUEST",
  UPDATE_ORGANISATION_USERS_SUCCESS: "UPDATE_ORGANISATION_USERS_SUCCESS",
  UPDATE_ORGANISATION_USERS_ERROR: "UPDATE_ORGANISATION_USERS_ERROR",

  GET_ORGANISATION_USER_DETAILS_REQUEST:
    "GET_ORGANISATION_USER_DETAILS_REQUEST",
  GET_ORGANISATION_USER_DETAILS_SUCCESS:
    "GET_ORGANISATION_USER_DETAILS_SUCCESS",
  GET_ORGANISATION_USER_DETAILS_ERROR: "GET_ORGANISATION_USER_DETAILS_ERROR",

  ACTIVATE_ACCOUNT_REQUEST: "ACTIVATE_ACCOUNT_REQUEST",
  ACTIVATE_ACCOUNT_SUCCESS: "ACTIVATE_ACCOUNT_SUCCESS",
  ACTIVATE_ACCOUNT_ERROR: "ACTIVATE_ACCOUNT_ERROR",

  DEACTIVATE_ACCOUNT_REQUEST: "DEACTIVATE_ACCOUNT_REQUEST",
  DEACTIVATE_ACCOUNT_SUCCESS: "DEACTIVATE_ACCOUNT_SUCCESS",
  DEACTIVATE_ACCOUNT_ERROR: "DEACTIVATE_ACCOUNT_ERROR",

  CREATE_STAFF_DETAILS_REQUEST: "CREATE_STAFF_DETAILS_REQUEST",
  CREATE_STAFF_DETAILS_SUCCESS: "CREATE_STAFF_DETAILS_SUCCESS",
  CREATE_STAFF_DETAILS_ERROR: "CREATE_STAFF_DETAILS_ERROR",

  UPDATE_STAFF_DETAILS_REQUEST: "UPDATE_STAFF_DETAILS_REQUEST",
  UPDATE_STAFF_DETAILS_SUCCESS: "UPDATE_STAFF_DETAILS_SUCCESS",
  UPDATE_STAFF_DETAILS_ERROR: "UPDATE_STAFF_DETAILS_ERROR",

  DELETE_STAFF_DETAILS_REQUEST: "DELETE_STAFF_DETAILS_REQUEST",
  DELETE_STAFF_DETAILS_SUCCESS: "DELETE_STAFF_DETAILS_SUCCESS",
  DELETE_STAFF_DETAILS_ERROR: "DELETE_STAFF_DETAILS_ERROR",

  CREATE_STAFF_DEPENDANTS_REQUEST: "CREATE_STAFF_DEPENDANTS_REQUEST",
  CREATE_STAFF_DEPENDANTS_SUCCESS: "CREATE_STAFF_DEPENDANTS_SUCCESS",
  CREATE_STAFF_DEPENDANTS_ERROR: "CREATE_STAFF_DEPENDANTS_ERROR",

  UPDATE_STAFF_DEPENDANTS_REQUEST: "UPDATE_STAFF_DEPENDANTS_REQUEST",
  UPDATE_STAFF_DEPENDANTS_SUCCESS: "UPDATE_STAFF_DEPENDANTS_SUCCESS",
  UPDATE_STAFF_DEPENDANTS_ERROR: "UPDATE_STAFF_DEPENDANTS_ERROR",

  DELETE_STAFF_DEPENDANTS_REQUEST: "DELETE_STAFF_DEPENDANTS_REQUEST",
  DELETE_STAFF_DEPENDANTS_SUCCESS: "DELETE_STAFF_DEPENDANTS_SUCCESS",
  DELETE_STAFF_DEPENDANTS_ERROR: "DELETE_STAFF_DEPENDANTS_ERROR",

  UPLOAD_STAFF_DOCUMENTS_REQUEST: "UPLOAD_STAFF_DOCUMENTS_REQUEST",
  UPLOAD_STAFF_DOCUMENTS_SUCCESS: "UPLOAD_STAFF_DOCUMENTS_SUCCESS",
  UPLOAD_STAFF_DOCUMENTS_ERROR: "UPLOAD_STAFF_DOCUMENTS_ERROR",

  DELETE_STAFF_DOCUMENTS_REQUEST: "DELETE_STAFF_DOCUMENTS_REQUEST",
  DELETE_STAFF_DOCUMENTS_SUCCESS: "DELETE_STAFF_DOCUMENTS_SUCCESS",
  DELETE_STAFF_DOCUMENTS_ERROR: "DELETE_STAFF_DOCUMENTS_ERROR",

  UPDATE_STAFF_DOCUMENTS_REQUEST: "UPDATE_STAFF_DOCUMENTS_REQUEST",
  UPDATE_STAFF_DOCUMENTS_SUCCESS: "UPDATE_STAFF_DOCUMENTS_SUCCESS",
  UPDATE_STAFF_DOCUMENTS_ERROR: "UPDATE_STAFF_DOCUMENTS_ERROR",

  SET_UPDATE_STAFF_DETAILS_DATA: "SET_UPDATE_STAFF_DETAILS_DATA",
  SET_UPDATE_STAFF_DEPENDANTS_DATA: "SET_UPDATE_STAFF_DEPENDANTS_DATA",
  SET_UPDATE_STAFF_DOCUMENTS_DATA: "SET_UPDATE_STAFF_DOCUMENTS_DATA",

  STAFF_QUALIFICATION_DETAILS_REQUEST: "STAFF_QUALIFICATION_DETAILS_REQUEST",
  STAFF_QUALIFICATION_DETAILS_SUCCESS: "STAFF_QUALIFICATION_DETAILS_SUCCESS",
  STAFF_QUALIFICATION_DETAILS_ERROR: "STAFF_QUALIFICATION_DETAILS_ERROR",

  ADD_STAFF_QUALIFICATION_REQUEST: "ADD_STAFF_QUALIFICATION_REQUEST",
  ADD_STAFF_QUALIFICATION_SUCCESS: "ADD_STAFF_QUALIFICATION_SUCCESS",
  ADD_STAFF_QUALIFICATION_ERROR: "ADD_STAFF_QUALIFICATION_ERROR",

  UPDATE_STAFF_QUALIFICATION_REQUEST: "UPDATE_STAFF_QUALIFICATION_REQUEST",
  UPDATE_STAFF_QUALIFICATION_SUCCESS: "UPDATE_STAFF_QUALIFICATION_SUCCESS",
  UPDATE_STAFF_QUALIFICATION_ERROR: "UPDATE_STAFF_QUALIFICATION_ERROR",

  DELETE_STAFF_QUALIFICATION_REQUEST: "DELETE_STAFF_QUALIFICATION_REQUEST",
  DELETE_STAFF_QUALIFICATION_SUCCESS: "DELETE_STAFF_QUALIFICATION_SUCCESS",
  DELETE_STAFF_QUALIFICATION_ERROR: "DELETE_STAFF_QUALIFICATION_ERROR",

  SET_UPDATE_STAFF_QUALIFICATION_DATA: "SET_UPDATE_STAFF_QUALIFICATION_DATA",

  GENERATE_NEW_CONFIRMATION_TOKEN_REQUEST:
    "GENERATE_NEW_CONFIRMATION_TOKEN_REQUEST",
  GENERATE_NEW_CONFIRMATION_TOKEN_SUCCESS:
    "GENERATE_NEW_CONFIRMATION_TOKEN_SUCCESS",
  GENERATE_NEW_CONFIRMATION_TOKEN_ERROR:
    "GENERATE_NEW_CONFIRMATION_TOKEN_ERROR",

  UPDATE_STAFF_PROFILE_REQUEST: "UPDATE_STAFF_PROFILE_REQUEST",
  UPDATE_STAFF_PROFILE_SUCCESS: "UPDATE_STAFF_PROFILE_SUCCESS",
  UPDATE_STAFF_PROFILE_ERROR: "UPDATE_STAFF_PROFILE_ERROR",

  DOWNLOAD_STAFF_UPLOAD_TEMPLATE_REQUEST:
    "DOWNLOAD_STAFF_UPLOAD_TEMPLATE_REQUEST",
  DOWNLOAD_STAFF_UPLOAD_TEMPLATE_SUCCESS:
    "DOWNLOAD_STAFF_UPLOAD_TEMPLATE_SUCCESS",
  DOWNLOAD_STAFF_UPLOAD_TEMPLATE_ERROR: "DOWNLOAD_STAFF_UPLOAD_TEMPLATE_ERROR",

  getOrganisationUsers: () => ({
    type: organisationUsers.GET_ORGANISATION_USERS_REQUEST,
  }),
  downloadStaffUploadTemplate: () => ({
    type: organisationUsers.DOWNLOAD_STAFF_UPLOAD_TEMPLATE_REQUEST,
  }),
  getMetaData: () => ({
    type: organisationUsers.GET_META_DATA_REQUEST,
  }),
  addOrganisationUser: (data, role) => ({
    type: organisationUsers.ADD_ORGANISATION_USERS_REQUEST,
    data,
    role,
  }),
  setUpdateOrgansationUserData: (data) => ({
    type: organisationUsers.SET_UPDATE_ORGANISATION_USERS_DATA,
    data,
  }),
  updateOrganisationUser: (data, organisationUserId) => ({
    type: organisationUsers.UPDATE_ORGANISATION_USERS_REQUEST,
    data,
    organisationUserId,
  }),
  getOrganisationUserDetails: (data) => ({
    type: organisationUsers.GET_ORGANISATION_USER_DETAILS_REQUEST,
    data,
  }),
  activateAccount: (data) => ({
    type: organisationUsers.ACTIVATE_ACCOUNT_REQUEST,
    data,
  }),
  deactivateAccount: (data) => ({
    type: organisationUsers.DEACTIVATE_ACCOUNT_REQUEST,
    data,
  }),
  createStaffDetails: (data) => ({
    type: organisationUsers.CREATE_STAFF_DETAILS_REQUEST,
    data,
  }),
  updateStaffDetails: (data, id) => ({
    type: organisationUsers.UPDATE_STAFF_DETAILS_REQUEST,
    data,
    id,
  }),
  deleteStaffDetails: (id) => ({
    type: organisationUsers.DELETE_STAFF_DETAILS_REQUEST,
    id,
  }),
  createStaffDependants: (data) => ({
    type: organisationUsers.CREATE_STAFF_DEPENDANTS_REQUEST,
    data,
  }),
  updateStaffDependants: (data, id) => ({
    type: organisationUsers.UPDATE_STAFF_DEPENDANTS_REQUEST,
    data,
    id,
  }),
  deleteStaffDependants: (id, user_id) => ({
    type: organisationUsers.DELETE_STAFF_DEPENDANTS_REQUEST,
    id,
    user_id,
  }),
  uploadStaffDocuments: (data, id) => ({
    type: organisationUsers.UPLOAD_STAFF_DOCUMENTS_REQUEST,
    data,
    id,
  }),
  deleteStaffDocuments: (id, user_id) => ({
    type: organisationUsers.DELETE_STAFF_DOCUMENTS_REQUEST,
    id,
    user_id,
  }),
  updateStaffDocuments: (data, id) => ({
    type: organisationUsers.UPDATE_STAFF_DOCUMENTS_REQUEST,
    data,
    id,
  }),
  setUpdateStaffDetailsData: (data) => ({
    type: organisationUsers.SET_UPDATE_STAFF_DETAILS_DATA,
    data,
  }),
  setUpdateStaffDependantsData: (data) => ({
    type: organisationUsers.SET_UPDATE_STAFF_DEPENDANTS_DATA,
    data,
  }),
  setUpdateStaffDocumentsData: (data) => ({
    type: organisationUsers.SET_UPDATE_STAFF_DOCUMENTS_DATA,
    data,
  }),
  getStaffQualificationDetails: (id) => ({
    type: organisationUsers.STAFF_QUALIFICATION_DETAILS_REQUEST,
    id,
  }),
  addStaffQualification: (data) => ({
    type: organisationUsers.ADD_STAFF_QUALIFICATION_REQUEST,
    data,
  }),
  updateStaffQualification: (id, data) => ({
    type: organisationUsers.UPDATE_STAFF_QUALIFICATION_REQUEST,
    data,
    id,
  }),
  deleteStaffQualification: (id, user_id) => ({
    type: organisationUsers.DELETE_STAFF_QUALIFICATION_REQUEST,
    id,
    user_id,
  }),
  setUpdateStaffQualificationData: (data) => ({
    type: organisationUsers.SET_UPDATE_STAFF_QUALIFICATION_DATA,
    data,
  }),
  generateNewConfirmationToken: (id) => ({
    type: organisationUsers.GENERATE_NEW_CONFIRMATION_TOKEN_REQUEST,
    id,
  }),
  updateStaffProfile: (data, id) => ({
    type: organisationUsers.UPDATE_STAFF_PROFILE_REQUEST,
    data,
    id,
  }),
};

export default organisationUsers;
