import { cashBookActions } from "../../../actions";
import initialState from "../../../initialState";

const cashBook = (state = initialState.cashBook, actions) => {
  switch (actions.type) {
    case cashBookActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };

    case cashBookActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataLoading: false,
        metaData: actions.data,
      };
    case cashBookActions.GENERATE_EXCEL_REQUEST:
      return {
        ...state,
        generateExcelLoading: true,
        generateExcelError: {},
      };
    case cashBookActions.GENERATE_EXCEL_SUCCESS:
      return {
        ...state,
        generateExcelLoading: false,
        generateExcelSuccess: actions.data,
      };
    case cashBookActions.GENERATE_EXCEL_ERROR:
      return {
        ...state,
        generateExcelLoading: false,
        generateExcelError: actions.error,
      };
    case cashBookActions.GET_CASH_BOOK_REQUEST:
      return {
        ...state,
        cashBookLoading: true,
        cashBookError: {},
      };
    case cashBookActions.GET_CASH_BOOK_SUCCESS:
      return {
        ...state,
        cashBookSuccess: actions.data,
        cashBookLoading: false,
      };
    case cashBookActions.GET_CASH_BOOK_ERROR:
      return {
        ...state,
        cashBookError: actions.error,
        cashBookLoading: false,
      };
    default:
      return state;
  }
};

export default cashBook;
