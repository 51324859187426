import { localPurchaseOrdersActions } from "../../../actions";
import initialState from "../../../initialState";

const localPurchaseOrders = (
  state = initialState.localPurchaseOrders,
  actions
) => {
  switch (actions.type) {
    case localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDERS_REQUEST:
      return {
        ...state,
        localPurchaseOrdersLoading: true,
      };
    case localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        localPurchaseOrdersLoading: false,
        localPurchaseOrdersSuccess: actions.data,
      };
    case localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDERS_ERROR:
      return {
        ...state,
        localPurchaseOrdersLoading: false,
        localPurchaseOrdersError: actions.error,
      };
    case localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_ITEMS_REQUEST:
      return {
        ...state,
        localPurchaseOrderItemsLoading: true,
      };
    case localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_ITEMS_SUCCESS:
      return {
        ...state,
        localPurchaseOrderItemsLoading: false,
        localPurchaseOrderItemsSuccess: actions.data,
      };
    case localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_ITEMS_ERROR:
      return {
        ...state,
        localPurchaseOrderItemsLoading: false,
        localPurchaseOrderItemsError: actions.error,
      };
    case localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDERS_META_DATA_REQUEST:
      return {
        ...state,
        localPurchaseOrdersMetaDataLoading: true,
      };
    case localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDERS_META_DATA_SUCCESS:
      return {
        ...state,
        localPurchaseOrdersMetaDataLoading: false,
        localPurchaseOrdersMetaDataSuccess: actions.data,
      };
    case localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_ITEMS_META_DATA_REQUEST:
      return {
        ...state,
        localPurchaseOrderItemsMetaDataLoading: true,
      };
    case localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_ITEMS_META_DATA_SUCCESS:
      return {
        ...state,
        localPurchaseOrderItemsMetaDataLoading: false,
        localPurchaseOrderItemsMetaDataSuccess: actions.data,
      };
    case localPurchaseOrdersActions.ADD_LOCAL_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        addLocalPurchaseOrderLoading: true,
      };
    case localPurchaseOrdersActions.ADD_LOCAL_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        addLocalPurchaseOrderLoading: false,
        addLocalPurchaseOrderSuccess: actions.data,
      };
    case localPurchaseOrdersActions.ADD_LOCAL_PURCHASE_ORDER_ERROR:
      return {
        ...state,
        addLocalPurchaseOrderLoading: false,
        addLocalPurchaseOrderError: actions.error,
      };
    case localPurchaseOrdersActions.SET_ADD_LOCAL_PURCHASE_ORDER_DATA:
      return {
        ...state,
        addLocalPurchaseOrderData: actions.data,
      };
    case localPurchaseOrdersActions.ADD_LOCAL_PURCHASE_ORDER_ITEM_REQUEST:
      return {
        ...state,
        addLocalPurchaseOrderItemLoading: true,
      };
    case localPurchaseOrdersActions.ADD_LOCAL_PURCHASE_ORDER_ITEM_SUCCESS:
      return {
        ...state,
        addLocalPurchaseOrderItemLoading: false,
        addLocalPurchaseOrderItemSuccess: actions.data,
      };
    case localPurchaseOrdersActions.ADD_LOCAL_PURCHASE_ORDER_ITEM_ERROR:
      return {
        ...state,
        addLocalPurchaseOrderItemLoading: false,
        addLocalPurchaseOrderItemError: actions.error,
      };
    case localPurchaseOrdersActions.SET_ADD_LOCAL_PURCHASE_ORDER_ITEM_DATA:
      return {
        ...state,
        addLocalPurchaseOrderItemData: actions.data,
      };
    case localPurchaseOrdersActions.DELETE_LOCAL_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        deleteLocalPurchaseOrderLoading: true,
      };
    case localPurchaseOrdersActions.DELETE_LOCAL_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        deleteLocalPurchaseOrderLoading: false,
        deleteLocalPurchaseOrderSuccess: actions.data,
      };
    case localPurchaseOrdersActions.DELETE_LOCAL_PURCHASE_ORDER_ERROR:
      return {
        ...state,
        deleteLocalPurchaseOrderLoading: false,
        deleteLocalPurchaseOrderError: actions.error,
      };
    case localPurchaseOrdersActions.DELETE_LOCAL_PURCHASE_ORDER_ITEM_REQUEST:
      return {
        ...state,
        deleteLocalPurchaseOrderItemLoading: true,
      };
    case localPurchaseOrdersActions.DELETE_LOCAL_PURCHASE_ORDER_ITEM_SUCCESS:
      return {
        ...state,
        deleteLocalPurchaseOrderItemLoading: false,
        deleteLocalPurchaseOrderItemSuccess: actions.data,
      };
    case localPurchaseOrdersActions.DELETE_LOCAL_PURCHASE_ORDER_ITEM_ERROR:
      return {
        ...state,
        deleteLocalPurchaseOrderItemLoading: false,
        deleteLocalPurchaseOrderItemError: actions.error,
      };
    case localPurchaseOrdersActions.SET_UPDATE_LOCAL_PURCHASE_ORDER_ITEM_UPDATE_DATA:
      return {
        ...state,
        updateLocalPurchaseOrderItemData: actions.data,
      };
    case localPurchaseOrdersActions.UPDATE_LOCAL_PURCHASE_ORDER_ITEM_REQUEST:
      return {
        ...state,
        updateLocalPurchaseOrderItemLoading: true,
      };
    case localPurchaseOrdersActions.UPDATE_LOCAL_PURCHASE_ORDER_ITEM_SUCCESS:
      return {
        ...state,
        updateLocalPurchaseOrderItemLoading: false,
        updateLocalPurchaseOrderItemSuccess: actions.data,
      };
    case localPurchaseOrdersActions.UPDATE_LOCAL_PURCHASE_ORDER_ITEM_ERROR:
      return {
        ...state,
        updateLocalPurchaseOrderItemLoading: false,
        updateLocalPurchaseOrderItemError: actions.error,
      };
    case localPurchaseOrdersActions.SET_UPDATE_LOCAL_PURCHASE_ORDER_DATA:
      return {
        ...state,
        updateLocalPurchaseOrderData: actions.data,
      };
    case localPurchaseOrdersActions.UPDATE_LOCAL_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        updateLocalPurchaseOrderLoading: true,
      };
    case localPurchaseOrdersActions.UPDATE_LOCAL_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        updateLocalPurchaseOrderLoading: false,
        updateLocalPurchaseOrderSuccess: actions.data,
      };
    case localPurchaseOrdersActions.UPDATE_LOCAL_PURCHASE_ORDER_ERROR:
      return {
        ...state,
        updateLocalPurchaseOrderLoading: false,
        updateLocalPurchaseOrderError: actions.error,
      };
    case localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        localPurchaseOrderDetailsLoading: true,
        localPurchaseOrderDetailsError: {},
      };
    case localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        localPurchaseOrderDetailsLoading: false,
        localPurchaseOrderDetailsSuccess: actions.data,
      };
    case localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_DETAILS_ERROR:
      return {
        ...state,
        localPurchaseOrderDetailsLoading: false,
        localPurchaseOrderDetailsError: actions.error,
      };
    case localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_ITEM_DETAILS_REQUEST:
      return {
        ...state,
        localPurchaseOrderItemDetailsLoading: true,
      };
    case localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_ITEM_DETAILS_SUCCESS:
      return {
        ...state,
        localPurchaseOrderItemDetailsLoading: false,
        localPurchaseOrderItemDetailsSuccess: actions.data,
      };
    case localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_ITEM_DETAILS_ERROR:
      return {
        ...state,
        localPurchaseOrderItemDetailsLoading: false,
        localPurchaseOrderItemDetailsError: actions.error,
      };
    case localPurchaseOrdersActions.CHECK_LOCAL_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        checkLocalPurchaseOrderLoading: true,
      };
    case localPurchaseOrdersActions.CHECK_LOCAL_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        checkLocalPurchaseOrderLoading: false,
        checkLocalPurchaseOrderSuccess: actions.data,
      };
    case localPurchaseOrdersActions.CHECK_LOCAL_PURCHASE_ORDER_ERROR:
      return {
        ...state,
        checkLocalPurchaseOrderLoading: false,
        checkLocalPurchaseOrderError: actions.error,
      };
    case localPurchaseOrdersActions.APPROVE_LOCAL_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        approveLocalPurchaseOrderLoading: true,
      };
    case localPurchaseOrdersActions.APPROVE_LOCAL_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        approveLocalPurchaseOrderLoading: false,
        approveLocalPurchaseOrderSuccess: actions.data,
      };
    case localPurchaseOrdersActions.APPROVE_LOCAL_PURCHASE_ORDER_ERROR:
      return {
        ...state,
        approveLocalPurchaseOrderLoading: false,
        approveLocalPurchaseOrderError: actions.error,
      };
    default:
      return state;
  }
};

export default localPurchaseOrders;
