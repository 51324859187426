import { rejectionsActions } from "../../actions";
import initialState from "../../initialState";

const rejections = (state = initialState.rejections, actions) => {
  switch (actions.type) {
    case rejectionsActions.ADD_REJECTIONS_REQUEST:
      return {
        ...state,
        addRejectionsLoading: true,
        addRejectionsSuccess: {},
        addRejectionsError: {},
      };
    case rejectionsActions.ADD_REJECTIONS_SUCCESS:
      return {
        ...state,
        addRejectionsLoading: false,
        addRejectionsSuccess: actions.data,
        addRejectionsError: {},
      };
    case rejectionsActions.ADD_REJECTIONS_ERROR:
      return {
        ...state,
        addRejectionsLoading: false,
        addRejectionsError: actions.error,
      };
    case rejectionsActions.SET_REJECTIONS_DATA:
      return {
        ...state,
        rejectionsData: actions.data,
      };
    default:
      return state;
  }
};

export default rejections;
