const schoolCommunications = {
  GET_SCHOOL_COMMUNICATIONS_REQUEST: "GET_SCHOOL_COMMUNICATIONS_REQUEST",
  GET_SCHOOL_COMMUNICATIONS_SUCCESS: "GET_SCHOOL_COMMUNICATIONS_SUCCESS",
  GET_SCHOOL_COMMUNICATIONS_ERROR: "GET_SCHOOL_COMMUNICATIONS_ERROR",

  GET_SCHOOL_COMMUNICATION_DETAILS_REQUEST:
    "GET_SCHOOL_COMMUNICATION_DETAILS_REQUEST",
  GET_SCHOOL_COMMUNICATION_DETAILS_SUCCESS:
    "GET_SCHOOL_COMMUNICATION_DETAILS_SUCCESS",
  GET_SCHOOL_COMMUNICATION_DETAILS_ERROR:
    "GET_SCHOOL_COMMUNICATION_DETAILS_ERROR",

  ADD_GENERAL_SCHOOL_COMMUNICATION_REQUEST:
    "ADD_GENERAL_SCHOOL_COMMUNICATION_REQUEST",
  ADD_GENERAL_SCHOOL_COMMUNICATION_SUCCESS:
    "ADD_GENERAL_SCHOOL_COMMUNICATION_SUCCESS",
  ADD_GENERAL_SCHOOL_COMMUNICATION_ERROR:
    "ADD_GENERAL_SCHOOL_COMMUNICATION_ERROR",

  GET_META_DATA_REQUEST: "GET_SCHOOL_COMMUNICATIONS_META_DATA_REQUEST",
  GET_META_DATA_SUCCESS: "GET_SCHOOL_COMMUNICATIONS_META_DATA_SUCCESS",
  GET_META_DATA_ERROR: "GET_SCHOOL_COMMUNICATIONS_META_DATA_ERROR",

  SEND_DEFAULTER_COMMUNICATION_REQUEST: "SEND_DEFAULTER_COMMUNICATION_REQUEST",
  SEND_DEFAULTER_COMMUNICATION_SUCCESS: "SEND_DEFAULTER_COMMUNICATION_SUCCESS",
  SEND_DEFAULTER_COMMUNICATION_ERROR: "SEND_DEFAULTER_COMMUNICATION_ERROR",

  getSchoolCommunications: () => ({
    type: schoolCommunications.GET_SCHOOL_COMMUNICATIONS_REQUEST,
  }),
  sendDefaulterCommunication: (data) => ({
    type: schoolCommunications.SEND_DEFAULTER_COMMUNICATION_REQUEST,
    data,
  }),
  getMetaData: () => ({
    type: schoolCommunications.GET_META_DATA_REQUEST,
  }),
  getSchoolCommunicationDetails: (id) => ({
    type: schoolCommunications.GET_SCHOOL_COMMUNICATION_DETAILS_REQUEST,
    id,
  }),
  addGeneralSchoolCommunication: (data) => ({
    type: schoolCommunications.ADD_GENERAL_SCHOOL_COMMUNICATION_REQUEST,
    data,
  }),
};

export default schoolCommunications;
