import { timetableActions } from "../../../actions";
import initialState from "../../../initialState";

const timetable = (state = initialState.timetable, actions) => {
  switch (actions.type) {
    case timetableActions.GET_TIMETABLES_REQUEST:
      return {
        ...state,
        timetableLoading: true,
        timetableError: {},
      };
    case timetableActions.GET_TIMETABLES_SUCCESS:
      return {
        ...state,
        timetables: actions.data,
        timetableLoading: false,
      };
    case timetableActions.GET_TIMETABLES_ERROR:
      return {
        ...state,
        timetableError: actions.error,
        timetableLoading: false,
      };
    case timetableActions.ADD_TIMETABLE_REQUEST:
      return {
        ...state,
        addTimetableLoading: true,
      };
    case timetableActions.ADD_TIMETABLE_SUCCESS:
      return {
        ...state,
        addTimetableSuccess: actions.data,
        addTimetableLoading: false,
      };
    case timetableActions.ADD_TIMETABLE_ERROR:
      return {
        ...state,
        addTimetableError: actions.error,
        addTimetableLoading: false,
      };
    case timetableActions.UPDATE_TIMETABLE_REQUEST:
      return {
        ...state,
        updateTimetableLoading: true,
        updateTimetableError: {},
      };
    case timetableActions.UPDATE_TIMETABLE_SUCCESS:
      return {
        ...state,
        updateTimetableSuccess: actions.data,
        updateTimetableLoading: false,
      };
    case timetableActions.UPDATE_TIMETABLE_ERROR:
      return {
        ...state,
        updateTimetableError: actions.error,
        updateTimetableLoading: false,
      };
    case timetableActions.UPDATE_TIMETABLE_DATA:
      return {
        ...state,
        updateTimetableData: actions.data,
      };
    case timetableActions.DELETE_TIMETABLE_REQUEST:
      return {
        ...state,
        deleteTimetableLoading: true,
      };
    case timetableActions.DELETE_TIMETABLE_SUCCESS:
      return {
        ...state,
        deleteTimetableSuccess: actions.data,
        deleteTimetableLoading: false,
      };
    case timetableActions.DELETE_TIMETABLE_ERROR:
      return {
        ...state,
        deleteTimetableError: actions.error,
        deleteTimetableLoading: false,
      };
    case timetableActions.GET_TIMETABLE_DETAILS_REQUEST:
      return {
        ...state,
        timetableDetailsLoading: true,
      };
    case timetableActions.GET_TIMETABLE_DETAILS_SUCCESS:
      return {
        ...state,
        timetableDetails: actions.data,
        timetableDetailsLoading: false,
      };
    case timetableActions.GET_TIMETABLE_DETAILS_ERROR:
      return {
        ...state,
        timetableDetailsError: actions.error,
        timetableDetailsLoading: false,
      };
    case timetableActions.ADD_TIMETABLE_ACTIVITY_REQUEST:
      return {
        ...state,
        addTimetableActivityLoading: true,
        addTimetableActivityError: {},
      };
    case timetableActions.ADD_TIMETABLE_ACTIVITY_SUCCESS:
      return {
        ...state,
        addTimetableActivitySuccess: actions.data,
        addTimetableActivityLoading: false,
      };
    case timetableActions.ADD_TIMETABLE_ACTIVITY_ERROR:
      return {
        ...state,
        addTimetableActivityError: actions.error,
        addTimetableActivityLoading: false,
      };
    case timetableActions.UPDATE_TIMETABLE_ACTIVITY_REQUEST:
      return {
        ...state,
        updateTimetableActivityLoading: true,
        updateTimetableActivityError: {},
      };
    case timetableActions.UPDATE_TIMETABLE_ACTIVITY_SUCCESS:
      return {
        ...state,
        updateTimetableActivitySuccess: actions.data,
        updateTimetableActivityLoading: false,
      };
    case timetableActions.UPDATE_TIMETABLE_ACTIVITY_ERROR:
      return {
        ...state,
        updateTimetableActivityError: actions.error,
        updateTimetableActivityLoading: false,
      };
    case timetableActions.UPDATE_TIMETABLE_ACTIVITY_DATA:
      return {
        ...state,
        updateTimetableActivityData: actions.data,
      };
    case timetableActions.DELETE_TIMETABLE_ACTIVITY_REQUEST:
      return {
        ...state,
        deleteTimetableActivityLoading: true,
      };
    case timetableActions.DELETE_TIMETABLE_ACTIVITY_SUCCESS:
      return {
        ...state,
        deleteTimetableActivitySuccess: actions.data,
        deleteTimetableActivityLoading: false,
      };
    case timetableActions.DELETE_TIMETABLE_ACTIVITY_ERROR:
      return {
        ...state,
        deleteTimetableActivityError: actions.error,
        deleteTimetableActivityLoading: false,
      };
    case timetableActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
        metaDataError: {},
      };
    case timetableActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: actions.data,
        metaDataLoading: false,
      };
    case timetableActions.GET_META_DATA_ERROR:
      return {
        ...state,
        metaDataLoading: false,
        metaDataError: actions.error,
      };

    default:
      return state;
  }
};

export default timetable;
