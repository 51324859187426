const assetTypes = {
  GET_ASSET_TYPES_REQUEST: "GET_ASSET_TYPES_REQUEST",
  GET_ASSET_TYPES_SUCCESS: "GET_ASSET_TYPES_SUCCESS",
  GET_ASSET_TYPES_ERROR: "GET_ASSET_TYPES_ERROR",

  ADD_ASSET_TYPE_REQUEST: "ADD_ASSET_TYPE_REQUEST",
  ADD_ASSET_TYPE_SUCCESS: "ADD_ASSET_TYPE_SUCCESS",
  ADD_ASSET_TYPE_ERROR: "ADD_ASSET_TYPE_ERROR",

  DELETE_ASSET_TYPE_REQUEST: "DELETE_ASSET_TYPE_REQUEST",
  DELETE_ASSET_TYPE_SUCCESS: "DELETE_ASSET_TYPE_SUCCESS",
  DELETE_ASSET_TYPE_ERROR: "DELETE_ASSET_TYPE_ERROR",

  SET_UPDATE_ASSET_TYPE_UPDATE_DATA: "SET_UPDATE_ASSET_TYPE_UPDATE_DATA",
  UPDATE_ASSET_TYPE_REQUEST: "UPDATE_ASSET_TYPE_REQUEST",
  UPDATE_ASSET_TYPE_SUCCESS: "UPDATE_ASSET_TYPE_SUCCESS",
  UPDATE_ASSET_TYPE_ERROR: "UPDATE_ASSET_TYPE_ERROR",

  GET_ASSET_TYPE_DETAILS_REQUEST: "GET_ASSET_TYPE_DETAILS_REQUEST",
  GET_ASSET_TYPE_DETAILS_SUCCESS: "GET_ASSET_TYPE_DETAILS_SUCCESS",
  GET_ASSET_TYPE_DETAILS_ERROR: "GET_ASSET_TYPE_DETAILS_ERROR",

  CHECK_ASSET_TYPE_REQUEST: "CHECK_ASSET_TYPE_REQUEST",
  CHECK_ASSET_TYPE_SUCCESS: "CHECK_ASSET_TYPE_SUCCESS",
  CHECK_ASSET_TYPE_ERROR: "CHECK_ASSET_TYPE_ERROR",

  APPROVE_ASSET_TYPE_REQUEST: "APPROVE_ASSET_TYPE_REQUEST",
  APPROVE_ASSET_TYPE_SUCCESS: "APPROVE_ASSET_TYPE_SUCCESS",
  APPROVE_ASSET_TYPE_ERROR: "APPROVE_ASSET_TYPE_ERROR",

  getAssetTypes: () => ({
    type: assetTypes.GET_ASSET_TYPES_REQUEST,
  }),
  addAssetType: (data) => ({
    type: assetTypes.ADD_ASSET_TYPE_REQUEST,
    data,
  }),
  deleteAssetType: (data) => ({
    type: assetTypes.DELETE_ASSET_TYPE_REQUEST,
    data,
  }),
  updateAssetType: (data, id) => ({
    type: assetTypes.UPDATE_ASSET_TYPE_REQUEST,
    data,
    id,
  }),
  setUpdateAssetTypeData: (data) => ({
    type: assetTypes.SET_UPDATE_ASSET_TYPE_UPDATE_DATA,
    data,
  }),
  getAssetTypeDetails: (data) => ({
    type: assetTypes.GET_ASSET_TYPE_DETAILS_REQUEST,
    data,
  }),
  checkAssetType: (data) => ({
    type: assetTypes.CHECK_ASSET_TYPE_REQUEST,
    data,
  }),
  approveAssetType: (data) => ({
    type: assetTypes.APPROVE_ASSET_TYPE_REQUEST,
    data,
  }),
};

export default assetTypes;
