const subjectClassInitialState = {
  subjectClass: {
    subjectClassLoading: false,
    subjectClassSuccess: {},
    subjectClassError: {},

    addSubjectClassLoading: false,
    addSubjectClassSuccess: {},
    addSubjectClassError: {},

    updateSubjectClassData: {},
    updateSubjectClassLoading: false,
    updateSubjectClassSuccess: {},
    updateSubjectClassError: {},

    subjectClassDetailsLoading: false,
    subjectClassDetailsSuccess: {},
    subjectClassDetailsError: {},

    deleteSubjectClassLoading: false,
    deleteSubjectClassSuccess: {},
    deleteSubjectClassError: {},

    deleteExamPaperLoading: false,
    deleteExamPaperSuccess: {},
    deleteExamPaperError: {},

    metaData: {},
    metaDataLoading: false,
  },
};

export default subjectClassInitialState;
