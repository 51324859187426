const supplierPayments = {
  GET_SUPPLIER_PAYMENTS_SCHEDULE_REQUEST:
    "GET_SUPPLIER_PAYMENTS_SCHEDULE_REQUEST",
  GET_SUPPLIER_PAYMENTS_SCHEDULE_SUCCESS:
    "GET_SUPPLIER_PAYMENTS_SCHEDULE_SUCCESS",
  GET_SUPPLIER_PAYMENTS_SCHEDULE_ERROR: "GET_SUPPLIER_PAYMENTS_SCHEDULE_ERROR",

  GET_SUPPLIER_PAYMENTS_META_DATA_REQUEST:
    "GET_SUPPLIER_PAYMENTS_META_DATA_REQUEST",
  GET_SUPPLIER_PAYMENTS_META_DATA_SUCCESS:
    "GET_SUPPLIER_PAYMENTS_META_DATA_SUCCESS",
  GET_SUPPLIER_PAYMENTS_META_DATA_ERROR:
    "GET_SUPPLIER_PAYMENTS_META_DATA_ERROR",

  ADD_SUPPLIER_PAYMENT_SCHEDULE_REQUEST:
    "ADD_SUPPLIER_PAYMENT_SCHEDULE_REQUEST",
  ADD_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS:
    "ADD_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS",
  ADD_SUPPLIER_PAYMENT_SCHEDULE_ERROR: "ADD_SUPPLIER_PAYMENT_SCHEDULE_ERROR",

  DELETE_SUPPLIER_PAYMENT_SCHEDULE_REQUEST:
    "DELETE_SUPPLIER_PAYMENT_SCHEDULE_REQUEST",
  DELETE_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS:
    "DELETE_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS",
  DELETE_SUPPLIER_PAYMENT_SCHEDULE_ERROR:
    "DELETE_SUPPLIER_PAYMENT_SCHEDULE_ERROR",

  UPDATE_SUPPLIER_PAYMENT_SCHEDULE_REQUEST:
    "UPDATE_SUPPLIER_PAYMENT_SCHEDULE_REQUEST",
  UPDATE_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS:
    "UPDATE_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS",
  UPDATE_SUPPLIER_PAYMENT_SCHEDULE_ERROR:
    "UPDATE_SUPPLIER_PAYMENT_SCHEDULE_ERROR",

  GET_SUPPLIER_PAYMENT_SCHEDULE_DETAILS_REQUEST:
    "GET_SUPPLIER_PAYMENT_SCHEDULE_DETAILS_REQUEST",
  GET_SUPPLIER_PAYMENT_SCHEDULE_DETAILS_SUCCESS:
    "GET_SUPPLIER_PAYMENT_SCHEDULE_DETAILS_SUCCESS",
  GET_SUPPLIER_PAYMENT_SCHEDULE_DETAILS_ERROR:
    "GET_SUPPLIER_PAYMENT_SCHEDULE_DETAILS_ERROR",

  ADD_SUPPLIER_PAYMENTS_REQUEST: "ADD_SUPPLIER_PAYMENTS_REQUEST",
  ADD_SUPPLIER_PAYMENTS_SUCCESS: "ADD_SUPPLIER_PAYMENTS_SUCCESS",
  ADD_SUPPLIER_PAYMENTS_ERROR: "ADD_SUPPLIER_PAYMENTS_ERROR",

  DELETE_SUPPLIER_PAYMENT_REQUEST: "DELETE_SUPPLIER_PAYMENT_REQUEST",
  DELETE_SUPPLIER_PAYMENT_SUCCESS: "DELETE_SUPPLIER_PAYMENT_SUCCESS",
  DELETE_SUPPLIER_PAYMENT_ERROR: "DELETE_SUPPLIER_PAYMENT_ERROR",

  UPDATE_SUPPLIER_PAYMENT_REQUEST: "UPDATE_SUPPLIER_PAYMENT_REQUEST",
  UPDATE_SUPPLIER_PAYMENT_SUCCESS: "UPDATE_SUPPLIER_PAYMENT_SUCCESS",
  UPDATE_SUPPLIER_PAYMENT_ERROR: "UPDATE_SUPPLIER_PAYMENT_ERROR",

  GET_SUPPLIER_PAYMENT_DETAILS_REQUEST: "GET_SUPPLIER_PAYMENT_DETAILS_REQUEST",
  GET_SUPPLIER_PAYMENT_DETAILS_SUCCESS: "GET_SUPPLIER_PAYMENT_DETAILS_SUCCESS",
  GET_SUPPLIER_PAYMENT_DETAILS_ERROR: "GET_SUPPLIER_PAYMENT_DETAILS_ERROR",

  CHECK_SUPPLIER_PAYMENT_SCHEDULE_REQUEST:
    "CHECK_SUPPLIER_PAYMENT_SCHEDULE_REQUEST",
  CHECK_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS:
    "CHECK_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS",
  CHECK_SUPPLIER_PAYMENT_SCHEDULE_ERROR:
    "CHECK_SUPPLIER_PAYMENT_SCHEDULE_ERROR",

  APPROVE_SUPPLIER_PAYMENT_SCHEDULE_REQUEST:
    "APPROVE_SUPPLIER_PAYMENT_SCHEDULE_REQUEST",
  APPROVE_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS:
    "APPROVE_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS",
  APPROVE_SUPPLIER_PAYMENT_SCHEDULE_ERROR:
    "APPROVE_SUPPLIER_PAYMENT_SCHEDULE_ERROR",

  SET_UPDATE_SUPPLIER_PAYMENT_SCHEDULE_DATA:
    "SET_UPDATE_SUPPLIER_PAYMENT_SCHEDULE_DATA",

  ADD_SUPPLIER_PAYMENT_INVOICE_REQUEST: "ADD_SUPPLIER_PAYMENT_INVOICE_REQUEST",
  ADD_SUPPLIER_PAYMENT_INVOICE_SUCCESS: "ADD_SUPPLIER_PAYMENT_INVOICE_SUCCESS",
  ADD_SUPPLIER_PAYMENT_INVOICE_ERROR: "ADD_SUPPLIER_PAYMENT_INVOICE_ERROR",

  DELETE_SUPPLIER_PAYMENT_INVOICE_REQUEST:
    "DELETE_SUPPLIER_PAYMENT_INVOICE_REQUEST",
  DELETE_SUPPLIER_PAYMENT_INVOICE_SUCCESS:
    "DELETE_SUPPLIER_PAYMENT_INVOICE_SUCCESS",
  DELETE_SUPPLIER_PAYMENT_INVOICE_ERROR:
    "DELETE_SUPPLIER_PAYMENT_INVOICE_ERROR",

  UPDATE_SUPPLIER_PAYMENT_INVOICE_REQUEST:
    "UPDATE_SUPPLIER_PAYMENT_INVOICE_REQUEST",
  UPDATE_SUPPLIER_PAYMENT_INVOICE_SUCCESS:
    "UPDATE_SUPPLIER_PAYMENT_INVOICE_SUCCESS",
  UPDATE_SUPPLIER_PAYMENT_INVOICE_ERROR:
    "UPDATE_SUPPLIER_PAYMENT_INVOICE_ERROR",

  SET_UPDATE_SUPPLIER_PAYMENT_INVOICE_DATA:
    "SET_UPDATE_SUPPLIER_PAYMENT_INVOICE_DATA",

  getSupplierPaymentsSchedule: () => ({
    type: supplierPayments.GET_SUPPLIER_PAYMENTS_SCHEDULE_REQUEST,
  }),

  getSupplierPaymentsScheduleMetaData: () => ({
    type: supplierPayments.GET_SUPPLIER_PAYMENTS_META_DATA_REQUEST,
  }),

  addSupplierPaymentSchedule: (data) => ({
    type: supplierPayments.ADD_SUPPLIER_PAYMENT_SCHEDULE_REQUEST,
    data,
  }),

  deleteSupplierPaymentSchedule: (id) => ({
    type: supplierPayments.DELETE_SUPPLIER_PAYMENT_SCHEDULE_REQUEST,
    id,
  }),

  updateSupplierPaymentSchedule: (id, data) => ({
    type: supplierPayments.UPDATE_SUPPLIER_PAYMENT_SCHEDULE_REQUEST,
    id,
    data,
  }),

  getSupplierPaymentScheduleDetails: (id) => ({
    type: supplierPayments.GET_SUPPLIER_PAYMENT_SCHEDULE_DETAILS_REQUEST,
    id,
  }),

  addSupplierPayments: (data) => ({
    type: supplierPayments.ADD_SUPPLIER_PAYMENTS_REQUEST,
    data,
  }),

  deleteSupplierPayment: (id, supplier_id) => ({
    type: supplierPayments.DELETE_SUPPLIER_PAYMENT_REQUEST,
    id,
    supplier_id,
  }),

  updateSupplierPayment: (id, data) => ({
    type: supplierPayments.UPDATE_SUPPLIER_PAYMENT_REQUEST,
    id,
    data,
  }),

  getSupplierPaymentDetails: (id) => ({
    type: supplierPayments.GET_SUPPLIER_PAYMENT_DETAILS_REQUEST,
    id,
  }),

  checkSupplierPaymentSchedule: (id) => ({
    type: supplierPayments.CHECK_SUPPLIER_PAYMENT_SCHEDULE_REQUEST,
    id,
  }),

  approveSupplierPaymentSchedule: (id) => ({
    type: supplierPayments.APPROVE_SUPPLIER_PAYMENT_SCHEDULE_REQUEST,
    id,
  }),

  setUpdateSupplierPaymentScheduleData: (data) => ({
    type: supplierPayments.SET_UPDATE_SUPPLIER_PAYMENT_SCHEDULE_DATA,
    data,
  }),

  addSupplierPaymentInvoice: (data) => ({
    type: supplierPayments.ADD_SUPPLIER_PAYMENT_INVOICE_REQUEST,
    data,
  }),

  deleteSupplierPaymentInvoice: (id, supplier_id) => ({
    type: supplierPayments.DELETE_SUPPLIER_PAYMENT_INVOICE_REQUEST,
    id,
    supplier_id,
  }),

  updateSupplierPaymentInvoice: (id, data) => ({
    type: supplierPayments.UPDATE_SUPPLIER_PAYMENT_INVOICE_REQUEST,
    id,
    data,
  }),

  setUpdateSupplierPaymentInvoiceData: (data) => ({
    type: supplierPayments.SET_UPDATE_SUPPLIER_PAYMENT_INVOICE_DATA,
    data,
  }),
};

export default supplierPayments;
