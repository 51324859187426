import { singleLearnerLedgerActions } from "../../../actions";
import singleLearnerLedgerInitialState from "../../../initialState/administration/singleLearnerLedger/singleLearnerLedger.initialState";

const singleLearnerLedger = (state = singleLearnerLedgerInitialState, actions) => {
    switch (actions.type) {
        case singleLearnerLedgerActions.GET_SINGLE_LEARNER_LEDGER:
        return {
            ...state,
            singleLearnerLedgerLoading: true,
            singleLearnerLedgerError: {},
            singleLearnerLedgerSuccess: {},
        };
        case singleLearnerLedgerActions.GET_SINGLE_LEARNER_LEDGER_SUCCESS:
        return {
            ...state,
            singleLearnerLedgerSuccess: actions.data,
            singleLearnerLedgerLoading: false,
        };
        case singleLearnerLedgerActions.GET_SINGLE_LEARNER_LEDGER_ERROR:
        return {
            ...state,
            singleLearnerLedgerLoading: false,
            singleLearnerLedgerError: actions.error,
        };
        case singleLearnerLedgerActions.GET_SINGLE_LEARNER_LEDGER_META_DATA:
        return {
            ...state,
            singleLearnerLedgerMetaDataLoading: true,
            singleLearnerLedgerMetaDataError: {},
            singleLearnerLedgerMetaDataSuccess: {},
        };
        case singleLearnerLedgerActions.GET_SINGLE_LEARNER_LEDGER_META_DATA_SUCCESS:    
        return {
            ...state,
            singleLearnerLedgerMetaDataSuccess: actions.data,
            singleLearnerLedgerMetaDataLoading: false,
        };
        case singleLearnerLedgerActions.GET_SINGLE_LEARNER_LEDGER_META_DATA_ERROR:
        return {
            ...state,
            singleLearnerLedgerMetaDataLoading: false,
            singleLearnerLedgerMetaDataError: actions.error,
        };
        case singleLearnerLedgerActions.SET_SINGLE_LEARNER_LEDGER_DATA:
        return {
            ...state,
            singleLearnerLedgerData: actions.data,
        };
        default:
        return state;
    }
    }

export default singleLearnerLedger;