import { takeLatest, fork, put } from "redux-saga/effects";
import axios from "axios";
import { appUiActions, classesActions } from "../../../actions";

function* getClasses() {
  try {
    const response = yield axios({
      url: "administration/classes",
    });

    yield put({
      type: classesActions.GET_CLASSES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: classesActions.GET_CLASSES_ERROR,
      error: error.data,
    });
  }
}
function* watchGetClasses() {
  yield takeLatest(classesActions.GET_CLASSES_REQUEST, getClasses);
}

function* getClassesMetaData() {
  try {
    const response = yield axios({
      url: "administration/classes/meta-data",
    });

    yield put({
      type: classesActions.GET_CLASSES_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: classesActions.GET_CLASSES_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetClassesMetaData() {
  yield takeLatest(
    classesActions.GET_CLASSES_META_DATA_REQUEST,
    getClassesMetaData
  );
}

function* addClass({ data }) {
  try {
    const response = yield axios({
      url: "administration/classes/",
      method: "POST",
      data: data,
    });

    yield put({
      type: classesActions.ADD_CLASS_SUCCESS,
      data: response,
    });
    yield put(classesActions.getClasses());
  } catch (error) {
    yield put({
      type: classesActions.ADD_CLASS_ERROR,
      error: error.data,
    });
  }
}
function* watchAddClass() {
  yield takeLatest(classesActions.ADD_CLASS_REQUEST, addClass);
}

function* deleteClass({ id }) {
  try {
    const response = yield axios({
      url: `/administration/classes/${id}`,
      method: "DELETE",
    });

    yield put({
      type: classesActions.DELETE_CLASS_SUCCESS,
      data: response,
    });
    yield put(classesActions.getClasses());
  } catch (error) {
    yield put({
      type: classesActions.DELETE_CLASS_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteClass() {
  yield takeLatest(classesActions.DELETE_CLASS_REQUEST, deleteClass);
}

function* updateClass({ id, data }) {
  try {
    const response = yield axios({
      url: `/administration/classes/${id}`,
      method: "PUT",
      data: data,
    });

    yield put({
      type: classesActions.UPDATE_CLASS_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: classesActions.UPDATE_CLASS_DETAILS_ERROR,
      error: error.data,
    });
  }
}
function* watchUpdateClass() {
  yield takeLatest(classesActions.UPDATE_CLASS_DETAILS_REQUEST, updateClass);
}
function* getClassDetails({ id }) {
  try {
    const response = yield axios({
      url: `/administration/classes/details/${id}`,
    });

    yield put({
      type: classesActions.GET_CLASS_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: classesActions.GET_CLASS_DETAILS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetClassDetails() {
  yield takeLatest(classesActions.GET_CLASS_DETAILS_REQUEST, getClassDetails);
}
function* checkClass({ id }) {
  try {
    const response = yield axios({
      url: `/administration/classes/check/${id}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({
      type: classesActions.CHECK_CLASS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: classesActions.CHECK_CLASS_ERROR,
      error: error.data,
    });
  }
}
function* watchCheckClass() {
  yield takeLatest(classesActions.CHECK_CLASS_REQUEST, checkClass);
}
function* approveClass({ id }) {
  try {
    const response = yield axios({
      url: `administration/classes/approve/${id}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: classesActions.APPROVE_CLASS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: classesActions.APPROVE_CLASS_ERROR,
      error: error.data,
    });
  }
}
function* watchApproveClass() {
  yield takeLatest(classesActions.APPROVE_CLASS_REQUEST, approveClass);
}

function* getStreamDetails({ id }) {
  try {
    const response = yield axios({
      url: `/administration/classes/streams/${id}`,
      method: "GET",
    });

    yield put({
      type: classesActions.GET_STREAMS_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: classesActions.GET_STREAMS_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetStreamDetails() {
  yield takeLatest(
    classesActions.GET_STREAMS_DETAILS_REQUEST,
    getStreamDetails
  );
}

function* addStream({ data }) {
  try {
    const response = yield axios({
      url: "/administration/classes/streams",
      method: "POST",
      data,
    });

    yield put(classesActions.getClassDetails(data?.class_id));
    yield put({
      type: classesActions.ADD_STREAMS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: classesActions.ADD_STREAMS_ERROR,
      error: error.data,
    });
  }
}

function* watchAddStream() {
  yield takeLatest(classesActions.ADD_STREAMS_REQUEST, addStream);
}

function* updateStream({ id, data }) {
  try {
    const response = yield axios({
      url: `/administration/classes/streams/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: classesActions.UPDATE_STREAMS_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleUpdateStreamsModal(false));
  } catch (error) {
    yield put({
      type: classesActions.UPDATE_STREAMS_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateStream() {
  yield takeLatest(classesActions.UPDATE_STREAMS_REQUEST, updateStream);
}

function* deleteStream({ id }) {
  try {
    const response = yield axios({
      url: `/administration/classes/streams/${id}`,
    });

    yield put({
      type: classesActions.DELETE_STREAMS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: classesActions.DELETE_STREAMS_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteStream() {
  yield takeLatest(classesActions.DELETE_STREAMS_REQUEST, deleteStream);
}

export default [
  fork(watchGetClasses),
  fork(watchGetClassesMetaData),
  fork(watchAddClass),
  fork(watchDeleteClass),
  fork(watchUpdateClass),
  fork(watchGetClassDetails),
  fork(watchCheckClass),
  fork(watchApproveClass),
  fork(watchGetStreamDetails),
  fork(watchAddStream),
  fork(watchUpdateStream),
  fork(watchDeleteStream),
];
