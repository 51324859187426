const incomeStatementInitialState = {
  incomeStatement: {
    incomeStatementSuccess: {},
    incomeStatementLoading: false,
    incomeStatementError: {},

    metaDataSuccess: {},
    metaDataError: {},
    metaDataLoading: false,

    balanceSheet: {},
    balanceSheetLoading: false,
    balanceSheetError: {},

    setIncomeStatementAccountData: {},
    setGetByData: {},
  },
};

export default incomeStatementInitialState;
