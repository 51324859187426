import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { learnerAttendanceActions, appUiActions } from "../../../actions";

function* getLearnerAttendance() {
  try {
    const response = yield axios({
      url: "/administration/learner-attendance",
      method: "GET",
    });

    yield put({
      type: learnerAttendanceActions.GET_LEARNER_ATTENDANCE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: learnerAttendanceActions.GET_LEARNER_ATTENDANCE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetLearnerAttendance() {
  yield takeLatest(
    learnerAttendanceActions.GET_LEARNER_ATTENDANCE_REQUEST,
    getLearnerAttendance
  );
}

function* getLearnerAttendanceMetaData() {
  try {
    const response = yield axios({
      url: "/administration/learner-attendance/meta-data",
    });

    yield put({
      type: learnerAttendanceActions.GET_LEARNER_ATTENDANCE_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: learnerAttendanceActions.GET_LEARNER_ATTENDANCE_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetLearnerAttendanceMetaData() {
  yield takeLatest(
    learnerAttendanceActions.GET_LEARNER_ATTENDANCE_META_DATA_REQUEST,
    getLearnerAttendanceMetaData
  );
}

function* getLearnerAttendanceDetails({ id }) {
  try {
    const response = yield axios({
      url: `/administration/learner-attendance/${id}`,
    });

    yield put({
      type: learnerAttendanceActions.GET_LEARNER_ATTENDANCE_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: learnerAttendanceActions.GET_LEARNER_ATTENDANCE_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetLearnerAttendanceDetails() {
  yield takeLatest(
    learnerAttendanceActions.GET_LEARNER_ATTENDANCE_DETAILS_REQUEST,
    getLearnerAttendanceDetails
  );
}

function* addLearnerAttendance({ data }) {
  try {
    const response = yield axios({
      url: "/administration/learner-attendance",
      method: "POST",
      data,
    });

    yield put({
      type: learnerAttendanceActions.ADD_LEARNER_ATTENDANCE_SUCCESS,
      data: response,
    });
    // get the learner attendance
    yield put(learnerAttendanceActions.getLearnerAttendance());
    yield put(appUiActions.toggleAddLearnerAttendanceModal(false));
  } catch (error) {
    yield put({
      type: learnerAttendanceActions.ADD_LEARNER_ATTENDANCE_ERROR,
      error: error.data,
    });
  }
}

function* watchAddLearnerAttendance() {
  yield takeLatest(
    learnerAttendanceActions.ADD_LEARNER_ATTENDANCE_REQUEST,
    addLearnerAttendance
  );
}

function* updateLearnerAttendance({ data, id }) {
  try {
    const response = yield axios({
      url: `/administration/learner-attendance/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: learnerAttendanceActions.UPDATE_LEARNER_ATTENDANCE_SUCCESS,
      data: response,
    });

    yield put(learnerAttendanceActions.getLearnerAttendance());
  } catch (error) {
    yield put({
      type: learnerAttendanceActions.UPDATE_LEARNER_ATTENDANCE_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateLearnerAttendance() {
  yield takeLatest(
    learnerAttendanceActions.UPDATE_LEARNER_ATTENDANCE_REQUEST,
    updateLearnerAttendance
  );
}

function* deleteLearnerAttendance({ id }) {
  try {
    const response = yield axios({
      url: `/administration/learner-attendance/${id}`,
      method: "DELETE",
    });

    yield put({
      type: learnerAttendanceActions.DELETE_LEARNER_ATTENDANCE_SUCCESS,
      data: response,
    });

    yield put(learnerAttendanceActions.getLearnerAttendance());
  } catch (error) {
    yield put({
      type: learnerAttendanceActions.DELETE_LEARNER_ATTENDANCE_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteLearnerAttendance() {
  yield takeLatest(
    learnerAttendanceActions.DELETE_LEARNER_ATTENDANCE_REQUEST,
    deleteLearnerAttendance
  );
}

function* checkLearnerAttendance({ id }) {
  try {
    const response = yield axios({
      url: `/administration/learner-attendance/check/${id}`,
      method: "PATCH",
    });

    yield put({
      type: learnerAttendanceActions.CHECK_LEARNER_ATTENDANCE_SUCCESS,
      data: response,
    });
    yield put(learnerAttendanceActions.getLearnerAttendanceDetails(id));
  } catch (error) {
    yield put({
      type: learnerAttendanceActions.CHECK_LEARNER_ATTENDANCE_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckLearnerAttendance() {
  yield takeLatest(
    learnerAttendanceActions.CHECK_LEARNER_ATTENDANCE_REQUEST,
    checkLearnerAttendance
  );
}

function* approveLearnerAttendance({ id }) {
  try {
    const response = yield axios({
      url: `/administration/learner-attendance/approve/${id}`,
      method: "PATCH",
    });

    yield put({
      type: learnerAttendanceActions.APPROVE_LEARNER_ATTENDANCE_SUCCESS,
      data: response,
    });
    yield put(learnerAttendanceActions.getLearnerAttendanceDetails(id));
  } catch (error) {
    yield put({
      type: learnerAttendanceActions.APPROVE_LEARNER_ATTENDANCE_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveLearnerAttendance() {
  yield takeLatest(
    learnerAttendanceActions.APPROVE_LEARNER_ATTENDANCE_REQUEST,
    approveLearnerAttendance
  );
}

export default [
  fork(watchGetLearnerAttendance),
  fork(watchGetLearnerAttendanceMetaData),
  fork(watchGetLearnerAttendanceDetails),
  fork(watchAddLearnerAttendance),
  fork(watchUpdateLearnerAttendance),
  fork(watchDeleteLearnerAttendance),
  fork(watchCheckLearnerAttendance),
  fork(watchApproveLearnerAttendance),
];
