const projectsInitialState = {
  projects: {
    projectsLoading: false,
    projectsSuccess: {},
    projectsError: {},

    metaDataLoading: false,
    metaDataSuccess: {},

    addProjectData: {},
    addProjectLoading: false,
    addProjectSuccess: {},
    addProjectError: {},

    deleteProjectLoading: false,
    deleteProjectSuccess: {},
    deleteProjectError: {},

    updateProjectData: {},
    updateProjectLoading: false,
    updateProjectSuccess: {},
    updateProjectError: {},

    projectDetailsLoading: false,
    projectDetailsSuccess: {},
    projectDetailsError: {},

    checkProjectLoading: false,
    checkProjectSuccess: {},
    checkProjectError: {},

    approveProjectLoading: false,
    approveProjectSuccess: {},
    approveProjectError: {},
  },
};

export default projectsInitialState;
