const organisationUsersInitialState = {
  organisationUsers: {
    organisationUsersLoading: false,
    organisationUsersSuccess: {},
    organisationUsersError: {},

    metaDataLoading: false,
    metaDataSuccess: {},

    addOrganisationUsersLoading: false,
    addOrganisationUsersSuccess: {},
    addOrganisationUsersError: {},

    updateOrganisationUserData: {},
    updateOrganisationUsersLoading: false,
    updateOrganisationUsersError: {},
    updateOrganisationUsersSuccess: {},

    organisationUserDetailsLoading: false,
    organisationUserDetailsSuccess: {},
    organisationUserDetailsError: {},

    activateAccountLoading: false,
    activateAccountSuccess: {},
    activateAccountError: {},

    deactivateAccountLoading: false,
    deactivateAccountSuccess: {},
    deactivateAccountError: {},

    createStaffDetailsLoading: false,
    createStaffDetailsSuccess: {},
    createStaffDetailsError: {},

    updateStaffDetailsLoading: false,
    updateStaffDetailsSuccess: {},
    updateStaffDetailsError: {},

    deleteStaffDetailsLoading: false,
    deleteStaffDetailsSuccess: {},
    deleteStaffDetailsError: {},

    createStaffDependantsLoading: false,
    createStaffDependantsSuccess: {},
    createStaffDependantsError: {},

    updateStaffDependantsLoading: false,
    updateStaffDependantsSuccess: {},
    updateStaffDependantsError: {},

    deleteStaffDependantsLoading: false,
    deleteStaffDependantsSuccess: {},
    deleteStaffDependantsError: {},

    uploadStaffDocumentsLoading: false,
    uploadStaffDocumentsSuccess: {},
    uploadStaffDocumentsError: {},

    deleteStaffDocumentsLoading: false,
    deleteStaffDocumentsSuccess: {},
    deleteStaffDocumentsError: {},

    updateStaffDocumentsLoading: false,
    updateStaffDocumentSuccess: {},
    updateStaffDocumentError: {},

    setUpdateStaffDetailsData: {},
    setUpdateStaffDependantsData: {},
    setUpdateStaffDocumentsData: {},

    staffQualificationDetailsLoading: false,
    staffQualificationDetailsSuccess: {},
    staffQualificationDetailsError: {},

    addStaffQualificationLoading: false,
    addStaffQualificationSuccess: {},
    addStaffQualificationError: {},

    updateStaffQualificationLoading: false,
    updateStaffQualificationSuccess: {},
    updateStaffQualificationError: {},

    deleteStaffQualificationLoading: false,
    deleteStaffQualificationSuccess: {},
    deleteStaffQualificationError: {},

    updateStaffQualificationsData: {},

    generateNewConfirmationTokenLoading: false,
    generateNewConfirmationTokenSuccess: {},
    generateNewConfirmationTokenError: {},

    updateStaffProfileLoading: false,
    updateStaffProfileSuccess: {},
    updateStaffProfileError: {},

    downloadingStaffUploadTemplate: false,
    downloadStaffTemplateSuccess: {},
    downloadStaffTemplateError: {},
  },
};

export default organisationUsersInitialState;
