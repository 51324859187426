const receivables = {
  GET_RECEIVABLES_REQUEST: "GET_RECEIVABLES_REQUEST",
  GET_RECEIVABLES_SUCCESS: "GET_RECEIVABLES_SUCCESS",
  GET_RECEIVABLES_ERROR: "GET_RECEIVABLES_ERROR",

  SET_SELECTED_RECEIVABLE: "SET_SELECTED_RECEIVABLE",

  getReceivables: (data) => ({
    type: receivables.GET_RECEIVABLES_REQUEST,
    data,
  }),
  setSelectedReceivable: (data) => ({
    type: receivables.SET_SELECTED_RECEIVABLE,
    data,
  }),
};

export default receivables;
