const clientInvoicesInitialState = {
  clientInvoices: {
    clientInvoices: {},
    clientInvoicesError: {},
    clientInvoicesLoading: false,

    clientInvoiceDetails: {},
    clientInvoiceDetailsError: {},
    clientInvoiceDetailsLoading: false,

    addClientInvoice: {},
    addClientInvoiceError: {},
    addClientInvoiceLoading: false,

    updateClientInvoice: {},
    updateClientInvoiceError: {},
    updateClientInvoiceLoading: false,
    setUpdateClientInvoiceData: {},

    deleteClientInvoice: {},
    deleteClientInvoiceError: {},
    deleteClientInvoiceLoading: false,

    metaData: {},
    metaDataError: {},
    metaDataLoading: false,
  },
};

export default clientInvoicesInitialState;
