import { permissionsActions } from "../../../actions";
import initialState from "../../../initialState";

const permissions = (state = initialState.permissions, actions) => {
  switch (actions.type) {
    case permissionsActions.GET_ROLE_PERMISSIONS_REQUEST:
      return {
        ...state,
        rolePermissionsLoading: true,
      };
    case permissionsActions.GET_ROLE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        rolePermissionsSuccess: actions.data,
        rolePermissionsLoading: false,
        rolePermissionsError: {},
      };
    case permissionsActions.GET_ROLE_PERMISSIONS_ERROR:
      return {
        ...state,
        rolePermissionsSuccess: {},
        rolePermissionsLoading: false,
        rolePermissionsError: actions.error,
      };
    case permissionsActions.GET_PERMISSIONS_META_DATA_LOADING:
      return {
        ...state,
        permissionsMetaDataLoading: true,
      };
    case permissionsActions.GET_PERMISSIONS_META_DATA_SUCCESS:
      return {
        ...state,
        permissionsMetaDataLoading: false,
        permissionsMetaDataSuccess: actions.data,
      };
    case permissionsActions.ADD_ROLE_PERMISSIONS_REQUEST:
      return {
        ...state,
        addRolePermissionsLoading: true,
        addRolePermissionsSuccess: {},
        addRolePermissionsError: {},
      };
    case permissionsActions.ADD_ROLE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        addRolePermissionsLoading: false,
        addRolePermissionsError: {},
        addRolePermissionsSuccess: actions.data,
      };
    case permissionsActions.ADD_ROLE_PERMISSIONS_ERROR:
      return {
        ...state,
        addRolePermissionsError: actions.error,
        addRolePermissionsLoading: false,
      };
    case permissionsActions.DELETE_PERMISSIONS_REQUEST:
      return {
        ...state,
        deletePermissionsLoading: true,
        deletePermissionsSuccess: {},
        deletePermissionsError: {},
      };

    case permissionsActions.DELETE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        deletePermissionsLoading: false,
        deletePermissionsSuccess: actions.data,
        deletePermissionsError: {},
      };
    case permissionsActions.DELETE_PERMISSIONS_ERROR:
      return {
        ...state,
        deletePermissionsLoading: false,
        deletePermissionsSuccess: {},
        deletePermissionsError: actions.error,
      };
    default:
      return state;
  }
};

export default permissions;
