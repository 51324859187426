const lessonPlans = {
  GET_LESSON_PLANS_REQUEST: "GET_LESSON_PLANS_REQUEST",
  GET_LESSON_PLANS_SUCCESS: "GET_LESSON_PLANS_SUCCESS",
  GET_LESSON_PLANS_ERROR: "GET_LESSON_PLANS_ERROR",

  GET_LESSON_PLAN_DETAILS_REQUEST: "GET_LESSON_PLAN_DETAILS_REQUEST",
  GET_LESSON_PLAN_DETAILS_SUCCESS: "GET_LESSON_PLAN_DETAILS_SUCCESS",
  GET_LESSON_PLAN_DETAILS_ERROR: "GET_LESSON_PLAN_DETAILS_ERROR",

  ADD_LESSON_PLAN_REQUEST: "ADD_LESSON_PLAN_REQUEST",
  ADD_LESSON_PLAN_SUCCESS: "ADD_LESSON_PLAN_SUCCESS",
  ADD_LESSON_PLAN_ERROR: "ADD_LESSON_PLAN_ERROR",

  UPDATE_LESSON_PLAN_REQUEST: "UPDATE_LESSON_PLAN_REQUEST",
  UPDATE_LESSON_PLAN_SUCCESS: "UPDATE_LESSON_PLAN_SUCCESS",
  UPDATE_LESSON_PLAN_ERROR: "UPDATE_LESSON_PLAN_ERROR",

  DELETE_LESSON_PLAN_REQUEST: "DELETE_LESSON_PLAN_REQUEST",
  DELETE_LESSON_PLAN_SUCCESS: "DELETE_LESSON_PLAN_SUCCESS",
  DELETE_LESSON_PLAN_ERROR: "DELETE_LESSON_PLAN_ERROR",

  GET_LESSON_PLAN_META_DATA_REQUEST: "GET_LESSON_PLAN_META_DATA_REQUEST",
  GET_LESSON_PLAN_META_DATA_SUCCESS: "GET_LESSON_PLAN_META_DATA_SUCCESS",
  GET_LESSON_PLAN_META_DATA_ERROR: "GET_LESSON_PLAN_META_DATA_ERROR",

  CHECK_LESSON_PLAN_REQUEST: "CHECK_LESSON_PLAN_REQUEST",
  CHECK_LESSON_PLAN_SUCCESS: "CHECK_LESSON_PLAN_SUCCESS",
  CHECK_LESSON_PLAN_ERROR: "CHECK_LESSON_PLAN_ERROR",

  APPROVE_LESSON_PLAN_REQUEST: "APPROVE_LESSON_PLAN_REQUEST",
  APPROVE_LESSON_PLAN_SUCCESS: "APPROVE_LESSON_PLAN_SUCCESS",
  APPROVE_LESSON_PLAN_ERROR: "APPROVE_LESSON_PLAN_ERROR",

  GET_LESSON_PLAN_PROCEDURE_STAGES_REQUEST:
    "GET_LESSON_PLAN_PROCEDURE_STAGES_REQUEST",
  GET_LESSON_PLAN_PROCEDURE_STAGES_SUCCESS:
    "GET_LESSON_PLAN_PROCEDURE_STAGES_SUCCESS",
  GET_LESSON_PLAN_PROCEDURE_STAGES_ERROR:
    "GET_LESSON_PLAN_PROCEDURE_STAGES_ERROR",

  ADD_LESSON_PLAN_PROCEDURE_STAGE_REQUEST:
    "ADD_LESSON_PLAN_PROCEDURE_STAGE_REQUEST",
  ADD_LESSON_PLAN_PROCEDURE_STAGE_SUCCESS:
    "ADD_LESSON_PLAN_PROCEDURE_STAGE_SUCCESS",
  ADD_LESSON_PLAN_PROCEDURE_STAGE_ERROR:
    "ADD_LESSON_PLAN_PROCEDURE_STAGE_ERROR",

  UPDATE_LESSON_PLAN_PROCEDURE_STAGE_REQUEST:
    "UPDATE_LESSON_PLAN_PROCEDURE_STAGE_REQUEST",
  UPDATE_LESSON_PLAN_PROCEDURE_STAGE_SUCCESS:
    "UPDATE_LESSON_PLAN_PROCEDURE_STAGE_SUCCESS",
  UPDATE_LESSON_PLAN_PROCEDURE_STAGE_ERROR:
    "UPDATE_LESSON_PLAN_PROCEDURE_STAGE_ERROR",

  DELETE_LESSON_PLAN_PROCEDURE_STAGE_REQUEST:
    "DELETE_LESSON_PLAN_PROCEDURE_STAGE_REQUEST",
  DELETE_LESSON_PLAN_PROCEDURE_STAGE_SUCCESS:
    "DELETE_LESSON_PLAN_PROCEDURE_STAGE_SUCCESS",
  DELETE_LESSON_PLAN_PROCEDURE_STAGE_ERROR:
    "DELETE_LESSON_PLAN_PROCEDURE_STAGE_ERROR",

  SET_UPDATE_LESSON_PLAN_DATA: "SET_UPDATE_LESSON_PLAN_DATA",
  SET_UPDATE_LESSON_PLAN_PROCEDURE_STAGE_DATA:
    "SET_UPDATE_LESSON_PLAN_PROCEDURE_STAGE_DATA",

  getLessonPlans: () => ({
    type: lessonPlans.GET_LESSON_PLANS_REQUEST,
  }),

  getLessonPlanDetails: (id) => ({
    type: lessonPlans.GET_LESSON_PLAN_DETAILS_REQUEST,
    id,
  }),

  addLessonPlan: (data) => ({
    type: lessonPlans.ADD_LESSON_PLAN_REQUEST,
    data,
  }),

  updateLessonPlan: (data, id) => ({
    type: lessonPlans.UPDATE_LESSON_PLAN_REQUEST,
    data,
    id,
  }),

  deleteLessonPlan: (id) => ({
    type: lessonPlans.DELETE_LESSON_PLAN_REQUEST,
    id,
  }),

  getLessonPlanMetaData: () => ({
    type: lessonPlans.GET_LESSON_PLAN_META_DATA_REQUEST,
  }),

  checkLessonPlan: (id) => ({
    type: lessonPlans.CHECK_LESSON_PLAN_REQUEST,
    id,
  }),

  approveLessonPlan: (id) => ({
    type: lessonPlans.APPROVE_LESSON_PLAN_REQUEST,
    id,
  }),

  getLessonPlanProcedureStages: (id) => ({
    type: lessonPlans.GET_LESSON_PLAN_PROCEDURE_STAGES_REQUEST,
    id,
  }),

  addLessonPlanProcedureStage: (data) => ({
    type: lessonPlans.ADD_LESSON_PLAN_PROCEDURE_STAGE_REQUEST,
    data,
  }),

  updateLessonPlanProcedureStage: (data, id) => ({
    type: lessonPlans.UPDATE_LESSON_PLAN_PROCEDURE_STAGE_REQUEST,
    data,
    id,
  }),

  deleteLessonPlanProcedureStage: (id, planId) => ({
    type: lessonPlans.DELETE_LESSON_PLAN_PROCEDURE_STAGE_REQUEST,
    id,
    planId,
  }),

  setUpdateLessonPlanData: (data) => ({
    type: lessonPlans.SET_UPDATE_LESSON_PLAN_DATA,
    data,
  }),

  setUpdateLessonPlanProcedureStageData: (data) => ({
    type: lessonPlans.SET_UPDATE_LESSON_PLAN_PROCEDURE_STAGE_DATA,
    data,
  }),
};

export default lessonPlans;
