const schoolDevelopmentPlanInitialState = {
  schoolDevelopmentPlan: {
    schoolDevelopmentPlan: {},
    schoolDevelopmentPlanLoading: false,
    schoolDevelopmentPlanError: false,

    addSchoolDevelopmentPlan: {},
    addSchoolDevelopmentPlanLoading: false,
    addSchoolDevelopmentPlanError: {},

    metaData: {},
    metaDataLoading: false,
    metaDataError: {},

    updateSchoolDevelopmentPlan: {},
    updateSchoolDevelopmentPlanLoading: false,
    updateSchoolDevelopmentPlanError: {},
    setUpdateSchoolDevelopmentPlanData: {},

    deleteSchoolDevelopmentPlan: {},
    deleteSchoolDevelopmentPlanLoading: false,
    deleteSchoolDevelopmentPlanError: {},

    schoolDevelopmentPlanDetails: {},
    schoolDevelopmentPlanDetailsLoading: false,
    schoolDevelopmentPlanDetailsError: {},

    addSchoolDevelopmentPlanExpenditure: {},
    addSchoolDevelopmentPlanExpenditureLoading: false,
    addSchoolDevelopmentPlanExpenditureError: {},

    updateSchoolDevelopmentPlanExpenditure: {},
    updateSchoolDevelopmentPlanExpenditureLoading: false,
    updateSchoolDevelopmentPlanExpenditureError: {},
    setUpdateSchoolDevelopmentPlanExpenditureData: {},

    deleteSchoolDevelopmentPlanExpenditure: {},
    deleteSchoolDevelopmentPlanExpenditureLoading: false,
    deleteSchoolDevelopmentPlanExpenditureError: {},

    checkSchoolDevelopmentPlan: {},
    checkSchoolDevelopmentPlanLoading: false,
    checkSchoolDevelopmentPlanError: {},

    approveSchoolDevelopmentPlan: {},
    approveSchoolDevelopmentPlanLoading: false,
    approveSchoolDevelopmentPlanError: {},
  },
};

export default schoolDevelopmentPlanInitialState;
