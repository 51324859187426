import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { ucedActions } from "../../actions";

function* getDioceses() {
  try {
    const response = yield axios({
      url: "/uced/dioceses",
    });

    yield put({
      type: ucedActions.GET_DIOCESES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: ucedActions.GET_DIOCESES_ERROR,
      error: error.data,
    });
  }
}

function* watchGetDioceses() {
  yield takeLatest(ucedActions.GET_DIOCESES_REQUEST, getDioceses);
}

function* getReligions() {
  try {
    const response = yield axios({
      url: "/uced/religions",
    });

    yield put({
      type: ucedActions.GET_RELIGIONS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: ucedActions.GET_RELIGIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetReligions() {
  yield takeLatest(ucedActions.GET_RELIGIONS_REQUEST, getReligions);
}

export default [fork(watchGetDioceses), fork(watchGetReligions)];
