const studentReports = {
  GET_STUDENT_REPORTS_REQUEST: "GET_STUDENT_REPORTS_REQUEST",
  GET_STUDENT_REPORTS_SUCCESS: "GET_STUDENT_REPORTS_SUCCESS",
  GET_STUDENT_REPORTS_ERROR: "GET_STUDENT_REPORTS_ERROR",

  ADD_STUDENT_REPORTS_REQUEST: "ADD_STUDENT_REPORTS_REQUEST",
  ADD_STUDENT_REPORTS_SUCCESS: "ADD_STUDENT_REPORTS_SUCCESS",
  ADD_STUDENT_REPORTS_ERROR: "ADD_STUDENT_REPORTS_ERROR",

  UPDATE_STUDENT_REPORTS_REQUEST: "UPDATE_STUDENT_REPORTS_REQUEST",
  UPDATE_STUDENT_REPORTS_SUCCESS: "UPDATE_STUDENT_REPORTS_SUCCESS",
  UPDATE_STUDENT_REPORTS_ERROR: "UPDATE_STUDENT_REPORTS_ERROR",
  UPDATE_STUDENT_REPORTS_DATA: "UPDATE_STUDENT_REPORTS_DATA",

  DELETE_STUDENT_REPORTS_REQUEST: "DELETE_STUDENT_REPORTS_REQUEST",
  DELETE_STUDENT_REPORTS_SUCCESS: "DELETE_STUDENT_REPORTS_SUCCESS",
  DELETE_STUDENT_REPORTS_ERROR: "DELETE_STUDENT_REPORTS_ERROR",

  PROMOTE_LEARNERS_REQUEST: "PROMOTE_LEARNERS_REQUEST",
  PROMOTE_LEARNERS_SUCCESS: "PROMOTE_LEARNERS_SUCCESS",
  PROMOTE_LEARNERS_ERROR: "PROMOTE_LEARNERS_ERROR",

  GET_STUDENT_REPORTS_DETAILS_REQUEST: "GET_STUDENT_REPORTS_DETAILS_REQUEST",
  GET_STUDENT_REPORTS_DETAILS_SUCCESS: "GET_STUDENT_REPORTS_DETAILS_SUCCESS",
  GET_STUDENT_REPORTS_DETAILS_ERROR: "GET_STUDENT_REPORTS_DETAILS_ERROR",

  GET_META_DATA_REQUEST: "GET_STUDENT_REPORTS_META_DATA_REQUEST",
  GET_META_DATA_SUCCESS: "GET_STUDENT_REPORTS_META_DATA_SUCCESS",
  GET_META_DATA_ERROR: "GET_STUDENT_REPORTS_META_DATA_ERROR",

  DELETE_REPORT_EXAM_REQUEST: "DELETE_REPORT_EXAM_REQUEST",
  DELETE_REPORT_EXAM_SUCCESS: "DELETE_REPORT_EXAM_SUCCESS",
  DELETE_REPORT_EXAM_ERROR: "DELETE_REPORT_EXAM_ERROR",

  SET_REPORT_DATA: "SET_REPORT_DATA",

  ADD_REPORT_COMMENTS_REQUEST: "ADD_REPORT_COMMENTS_REQUEST",
  ADD_REPORT_COMMENTS_SUCCESS: "ADD_REPORT_COMMENTS_SUCCESS",
  ADD_REPORT_COMMENTS_ERROR: "ADD_REPORT_COMMENTS_ERROR",

  UPDATE_REPORT_COMMENTS_REQUEST: "UPDATE_REPORT_COMMENTS_REQUEST",
  UPDATE_REPORT_COMMENTS_SUCCESS: "UPDATE_REPORT_COMMENTS_SUCCESS",
  UPDATE_REPORT_COMMENTS_ERROR: "UPDATE_REPORT_COMMENTS_ERROR",

  DELETE_REPORT_COMMENTS_REQUEST: "DELETE_REPORT_COMMENTS_REQUEST",
  DELETE_REPORT_COMMENTS_SUCCESS: "DELETE_REPORT_COMMENTS_SUCCESS",
  DELETE_REPORT_COMMENTS_ERROR: "DELETE_REPORT_COMMENTS_ERROR",

  SET_REPORT_COMMENTS_DATA: "SET_REPORT_COMMENTS_DATA",

  GET_LEARNER_BILLING_REQUEST: "GET_LEARNER_BILLING_REQUEST",
  GET_LEARNER_BILLING_SUCCESS: "GET_LEARNER_BILLING_SUCCESS",
  GET_LEARNER_BILLING_ERROR: "GET_LEARNER_BILLING_ERROR",

  GET_LEARNER_BILLING_META_DATA_REQUEST:
    "GET_LEARNER_BILLING_META_DATA_REQUEST",
  GET_LEARNER_BILLING_META_DATA_SUCCESS:
    "GET_LEARNER_BILLING_META_DATA_SUCCESS",
  GET_LEARNER_BILLING_META_DATA_ERROR: "GET_LEARNER_BILLING_META_DATA_ERROR",

  CREATE_REPORT_EXCEPTION_REQUEST: "CREATE_REPORT_EXCEPTION_REQUEST",
  CREATE_REPORT_EXCEPTION_SUCCESS: "CREATE_REPORT_EXCEPTION_SUCCESS",
  CREATE_REPORT_EXCEPTION_ERROR: "CREATE_REPORT_EXCEPTION_ERROR",

  GET_REPORT_EXCEPTIONS_REQUEST: "GET_REPORT_EXCEPTIONS_REQUEST",
  GET_REPORT_EXCEPTIONS_SUCCESS: "GET_REPORT_EXCEPTIONS_SUCCESS",
  GET_REPORT_EXCEPTIONS_ERROR: "GET_REPORT_EXCEPTIONS_ERROR",

  UPDATE_REPORT_EXCEPTION_REQUEST: "UPDATE_REPORT_EXCEPTION_REQUEST",
  UPDATE_REPORT_EXCEPTION_SUCCESS: "UPDATE_REPORT_EXCEPTION_SUCCESS",
  UPDATE_REPORT_EXCEPTION_ERROR: "UPDATE_REPORT_EXCEPTION_ERROR",

  SET_REPORT_EXCEPTION_DATA: "SET_REPORT_EXCEPTION_DATA",

  DELETE_REPORT_EXCEPTION_REQUEST: "DELETE_REPORT_EXCEPTION_REQUEST",
  DELETE_REPORT_EXCEPTION_SUCCESS: "DELETE_REPORT_EXCEPTION_SUCCESS",
  DELETE_REPORT_EXCEPTION_ERROR: "DELETE_REPORT_EXCEPTION_ERROR",

  SET_REPORT_COMMENT_TYPE: "SET_REPORT_COMMENT_TYPE",

  setReportData: (data) => ({
    type: studentReports.SET_REPORT_DATA,
    data,
  }),
  setReportCommentType: (data) => ({
    type: studentReports.SET_REPORT_COMMENT_TYPE,
    data,
  }),
  getStudentReports: (data) => ({
    type: studentReports.GET_STUDENT_REPORTS_REQUEST,
    data,
  }),
  deleteReportExam: (id) => ({
    type: studentReports.DELETE_REPORT_EXAM_REQUEST,
    id,
  }),
  getMetaData: (data) => ({
    type: studentReports.GET_META_DATA_REQUEST,
    data,
  }),
  getStudentReportDetails: (id) => ({
    type: studentReports.GET_STUDENT_REPORTS_DETAILS_REQUEST,
    id,
  }),
  addStudentReports: (data) => ({
    type: studentReports.ADD_STUDENT_REPORTS_REQUEST,
    data,
  }),
  updateStudentReports: (data, id) => ({
    type: studentReports.UPDATE_STUDENT_REPORTS_REQUEST,
    data,
    id,
  }),
  updateStudentReportsData: (data) => ({
    type: studentReports.UPDATE_STUDENT_REPORTS_DATA,
    data,
  }),
  deleteStudentReports: (id) => ({
    type: studentReports.DELETE_STUDENT_REPORTS_REQUEST,
    id,
  }),
  addReportComments: (data) => ({
    type: studentReports.ADD_REPORT_COMMENTS_REQUEST,
    data,
  }),
  updateReportComments: (data, id) => ({
    type: studentReports.UPDATE_REPORT_COMMENTS_REQUEST,
    data,
    id,
  }),
  deleteReportComments: (id) => ({
    type: studentReports.DELETE_REPORT_COMMENTS_REQUEST,
    id,
  }),
  setReportCommentsData: (data) => ({
    type: studentReports.SET_REPORT_COMMENTS_DATA,
    data,
  }),
  getLearnerBilling: (data) => ({
    type: studentReports.GET_LEARNER_BILLING_REQUEST,
    data,
  }),
  getLearnerBillingMetaData: () => ({
    type: studentReports.GET_LEARNER_BILLING_META_DATA_REQUEST,
  }),
  createReportException: (data) => ({
    type: studentReports.CREATE_REPORT_EXCEPTION_REQUEST,
    data,
  }),
  getReportExceptions: (data) => ({
    type: studentReports.GET_REPORT_EXCEPTIONS_REQUEST,
    data,
  }),
  updateReportException: (data, id) => ({
    type: studentReports.UPDATE_REPORT_EXCEPTION_REQUEST,
    data,
    id,
  }),
  deleteReportException: (id, reportId) => ({
    type: studentReports.DELETE_REPORT_EXCEPTION_REQUEST,
    id,
    reportId,
  }),
  setReportExceptionData: (data) => ({
    type: studentReports.SET_REPORT_EXCEPTION_DATA,
    data,
  }),
  promoteLearners: (data, reportId) => ({
    type: studentReports.PROMOTE_LEARNERS_REQUEST,
    data,
    reportId,
  }),
};

export default studentReports;
