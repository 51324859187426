import React, { useState } from "react";
import { Avatar, Anchor, Drawer, Button } from "antd";
import { FiMenu } from "react-icons/fi";
import routePaths from "../../config/routes/routePaths.routes";
import Logo from "../../assets/images/Logo.png";
const { Link } = Anchor;

function AppHeader() {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  return (
    <div className="container-fluid">
      <div className="header">
        <div className="logo">
          <Avatar size={64} src={Logo} />
          <a href="#hero"> FAMIS</a>
        </div>
        <div className="mobileHidden">
          <Anchor targetOffset="65">
            <Link href="#hero" title="Home" />
            <Link href="#about" title="About" />
            <Link href="#feature" title="Features" />
            <Link href="#works" title="How it works" />
            <Link href="#faq" title="FAQ" />
            <Link href="#pricing" title="Pricing" />
            <Link href="#contact" title="Contact" />
            <Link href={routePaths.auth.path} title="Sign in | Sign up" />
          </Anchor>
        </div>
        <div className="mobileVisible">
          <Button type="primary" onClick={showDrawer}>
            <FiMenu />
          </Button>
          <Drawer
            placement="right"
            width={200}
            closable={false}
            onClose={onClose}
            open={visible}
          >
            <Anchor onClick={onClose} targetOffset="65">
              <Link href="#hero" title="Home" />
              <Link href="#about" title="About" />
              <Link href="#feature" title="Features" />
              <Link href="#works" title="How it works" />
              <Link href="#faq" title="FAQ" />
              <Link href="#pricing" title="Pricing" />
              <Link href="#contact" title="Contact" />
              <Link href={routePaths.auth.path} title="Sign in | Sign up" />
            </Anchor>
          </Drawer>
        </div>
      </div>
    </div>
  );
}

export default AppHeader;
