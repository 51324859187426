import { takeLatest, put, fork, takeEvery } from "@redux-saga/core/effects";
import axios from "axios";
import {
  appUiActions,
  assetsActions,
  assetTypesActions,
} from "../../../actions";

function* getAssetTypes() {
  try {
    const data = yield axios({
      url: "/assets-register/types/",
    });

    yield put({
      type: assetTypesActions.GET_ASSET_TYPES_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: assetTypesActions.GET_ASSET_TYPES_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAssetTypes() {
  yield takeLatest(assetTypesActions.GET_ASSET_TYPES_REQUEST, getAssetTypes);
}

function* addAssetType({ data }) {
  try {
    const response = yield axios({
      url: "/assets-register/types/",
      method: "POST",
      data,
    });
    yield put({
      type: assetTypesActions.ADD_ASSET_TYPE_SUCCESS,
      data: response,
    });
    yield put(assetTypesActions.getAssetTypes());
    yield put(assetsActions.getMetaData());
  } catch (error) {
    yield put({
      type: assetTypesActions.ADD_ASSET_TYPE_ERROR,
      error: error.data,
    });
  }
}

function* watchAddAssetType() {
  yield takeLatest(assetTypesActions.ADD_ASSET_TYPE_REQUEST, addAssetType);
}

function* deleteAssetType({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `/assets-register/types/${data}`,
      method: "DELETE",
    });
    yield put({
      type: assetTypesActions.DELETE_ASSET_TYPE_SUCCESS,
      data: response,
    });

    yield put({ type: assetTypesActions.GET_ASSET_TYPES_REQUEST });
  } catch (error) {
    yield put({
      type: assetTypesActions.DELETE_ASSET_TYPE_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteAssetType() {
  yield takeEvery(assetTypesActions.DELETE_ASSET_TYPE_REQUEST, deleteAssetType);
}

function* updateAssetType({ data, id }) {
  // id in url
  try {
    const response = yield axios({
      url: `/assets-register/types/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: assetTypesActions.UPDATE_ASSET_TYPE_SUCCESS,
      data: response,
    });
    yield put({ type: assetTypesActions.GET_ASSET_TYPES_REQUEST });
    yield put({
      type: appUiActions.TOGGLE_UPDATE_ASSET_TYPE_MODAL,
      data: false,
    });
  } catch (error) {
    yield put({
      type: assetTypesActions.UPDATE_ASSET_TYPE_REQUEST,
      error: error.data,
    });
  }
}

function* watchUpdateAssetType() {
  yield takeLatest(
    assetTypesActions.UPDATE_ASSET_TYPE_REQUEST,
    updateAssetType
  );
}

function* getAssetTypeDetails({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `/assets-register/types/details/${data}`,
    });

    yield put({
      type: assetTypesActions.GET_ASSET_TYPE_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: assetTypesActions.GET_ASSET_TYPE_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAssetTypeDetails() {
  yield takeLatest(
    assetTypesActions.GET_ASSET_TYPE_DETAILS_REQUEST,
    getAssetTypeDetails
  );
}

function* checkAssetType({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `/assets-register/types/check/${data}`,
      data: { checked_at: new Date() },
      method: "PATCH",
    });

    yield put({
      type: assetTypesActions.CHECK_ASSET_TYPE_SUCCESS,
      data: response,
    });

    yield put({
      type: assetTypesActions.GET_ASSET_TYPE_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: assetTypesActions.CHECK_ASSET_TYPE_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckAssetType() {
  yield takeLatest(assetTypesActions.CHECK_ASSET_TYPE_REQUEST, checkAssetType);
}

function* approveAssetType({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `/assets-register/types/approve/${data}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: assetTypesActions.APPROVE_ASSET_TYPE_SUCCESS,
      data: response,
    });

    yield put({
      type: assetTypesActions.GET_ASSET_TYPE_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: assetTypesActions.APPROVE_ASSET_TYPE_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveAssetType() {
  yield takeLatest(
    assetTypesActions.APPROVE_ASSET_TYPE_REQUEST,
    approveAssetType
  );
}

function* getAssets() {
  try {
    const data = yield axios({
      url: "/assets-register/assets/",
    });

    yield put({
      type: assetsActions.GET_ASSETS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: assetsActions.GET_ASSETS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAssets() {
  yield takeLatest(assetsActions.GET_ASSETS_REQUEST, getAssets);
}

function* getMetaData() {
  try {
    const response = yield axios({
      url: "/assets-register/types/meta-data",
    });

    yield put({
      type: assetsActions.GET_ASSETS_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: assetsActions.GET_ASSETS_META_DATA_SUCCESS,
      data: {},
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(assetsActions.GET_ASSETS_META_DATA_REQUEST, getMetaData);
}

function* addAsset({ data }) {
  try {
    const response = yield axios({
      url: "/assets-register/assets/",
      method: "POST",
      data,
    });

    yield put({
      type: assetsActions.ADD_ASSET_SUCCESS,
      data: response,
    });

    yield put({ type: assetsActions.GET_ASSETS_REQUEST });
  } catch (error) {
    yield put({
      type: assetsActions.ADD_ASSET_ERROR,
      error: error.data,
    });
  }
}

function* watchAddAsset() {
  yield takeLatest(assetsActions.ADD_ASSET_REQUEST, addAsset);
}

function* deleteAsset({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `/assets-register/assets/${data}`,
      method: "DELETE",
    });

    yield put({
      type: assetsActions.DELETE_ASSET_SUCCESS,
      data: response,
    });

    yield put({ type: assetsActions.GET_ASSETS_REQUEST });
  } catch (error) {
    yield put({
      type: assetsActions.DELETE_ASSET_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteAsset() {
  yield takeLatest(assetsActions.DELETE_ASSET_REQUEST, deleteAsset);
}

function* updateAsset({ data, id }) {
  // id in url
  try {
    const response = yield axios({
      url: `/assets-register/assets/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: assetsActions.UPDATE_ASSET_SUCCESS,
      data: response,
    });

    yield put({ type: appUiActions.TOGGLE_UPDATE_ASSET_MODAL, data: false });
  } catch (error) {
    yield put({
      type: assetsActions.UPDATE_ASSET_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateAsset() {
  yield takeLatest(assetsActions.UPDATE_ASSET_REQUEST, updateAsset);
}

function* getAssetDetails({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `/assets-register/assets/details/${data}`,
    });

    yield put({
      type: assetsActions.GET_ASSET_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: assetsActions.GET_ASSET_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAssetDetails() {
  yield takeLatest(assetsActions.GET_ASSET_DETAILS_REQUEST, getAssetDetails);
}

function* checkAsset({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `/assets-register/assets/check/${data}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({
      type: assetsActions.CHECK_ASSET_SUCCESS,
      data: response,
    });

    yield put({
      type: assetsActions.GET_ASSET_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: assetsActions.CHECK_ASSET_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckAsset() {
  yield takeLatest(assetsActions.CHECK_ASSET_REQUEST, checkAsset);
}

function* approveAsset({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `/assets-register/assets/approve/${data}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: assetsActions.APPROVE_ASSET_SUCCESS,
      data: response,
    });

    yield put({
      type: assetsActions.GET_ASSET_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: assetsActions.APPROVE_ASSET_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveAsset() {
  yield takeLatest(assetsActions.APPROVE_ASSET_REQUEST, approveAsset);
}

export default [
  fork(watchGetAssetTypes),
  fork(watchGetMetaData),
  fork(watchAddAssetType),
  fork(watchDeleteAssetType),
  fork(watchUpdateAssetType),
  fork(watchGetAssetTypeDetails),
  fork(watchCheckAssetType),
  fork(watchApproveAssetType),
  fork(watchGetAssets),
  fork(watchAddAsset),
  fork(watchDeleteAsset),
  fork(watchUpdateAsset),
  fork(watchGetAssetDetails),
  fork(watchCheckAsset),
  fork(watchApproveAsset),
];
