const learnerBillingInitialState = {
  learnerBillingLoading: false,
  learnerBillingSuccess: {},
  learnerBillingError: {},

  metaDataLoading: false,
  metaDataSuccess: {},
  metaDataError: {},

  billLearnersLoading: false,
  billLearnersSuccess: {},
  billLearnersError: {},

  deleteLearnerBillLoading: false,
  deleteLearnerBillSuccess: {},
  deleteLearnerBillError: {},

  learnerBillingData: {},
};

export default learnerBillingInitialState;
