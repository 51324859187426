const moesDocuments = {
  GET_MOES_DOCUMENTS_REQUEST: "GET_MOES_DOCUMENTS_REQUEST",
  GET_MOES_DOCUMENTS_SUCCESS: "GET_MOES_DOCUMENTS_SUCCESS",
  GET_MOES_DOCUMENTS_ERROR: "GET_MOES_DOCUMENTS_ERROR",

  getMoesDocuments: () => ({
    type: moesDocuments.GET_MOES_DOCUMENTS_REQUEST,
  }),
};

export default moesDocuments;
