const brmsComplianceInitialState = {
  brmsCompliance: {
    brmsCompliance: [],
    brmsComplianceLoading: false,
    brmsComplianceError: {},

    addBrmsCompliance: {},
    addBrmsComplianceLoading: false,
    addBrmsComplianceError: {},

    updateBrmsCompliance: {},
    updateBrmsComplianceLoading: false,
    updateBrmsComplianceError: {},
    updateBrmsComplianceData: {},

    deleteBrmsCompliance: {},
    deleteBrmsComplianceLoading: false,
    deleteBrmsComplianceError: {},

    brmsComplianceDetails: {},
    brmsComplianceDetailsLoading: false,
    brmsComplianceDetailsError: {},

    metaData: {},
    metaDataLoading: false,

    checkBrmsCompliance: {},
    checkBrmsComplianceLoading: false,
    checkBrmsComplianceError: {},

    approveBrmsCompliance: {},
    approveBrmsComplianceLoading: false,
    approveBrmsComplianceError: {},
  },
};

export default brmsComplianceInitialState;
