import { takeLatest, put, fork, takeEvery } from "@redux-saga/core/effects";
import axios from "axios";
import { appUiActions, manualsAndPoliciesActions } from "../../../actions";

function* getManualsAndPolicies() {
  try {
    const response = yield axios({
      url: "/administration/manuals-and-policies",
    });

    yield put({
      type: manualsAndPoliciesActions.GET_MANUALS_AND_POLICIES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: manualsAndPoliciesActions.GET_MANUALS_AND_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* watchGetManualsAndPolicies() {
  yield takeLatest(
    manualsAndPoliciesActions.GET_MANUALS_AND_POLICIES_REQUEST,
    getManualsAndPolicies
  );
}

function* addManualsAndPolicies({ data }) {
  try {
    const response = yield axios({
      url: "/administration/manuals-and-policies",
      method: "POST",
      data,
    });
    yield put({
      type: manualsAndPoliciesActions.ADD_MANUALS_AND_POLICIES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: manualsAndPoliciesActions.ADD_MANUALS_AND_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* watchAddManualsAndPolicies() {
  yield takeLatest(
    manualsAndPoliciesActions.ADD_MANUALS_AND_POLICIES_REQUEST,
    addManualsAndPolicies
  );
}

function* deleteManualsAndPolicies({ data, records }) {
  try {
    const response = yield axios({
      url: `/administration/manuals-and-policies/${data}`,
      method: "DELETE",
    });
    yield put({
      type: manualsAndPoliciesActions.DELETE_MANUALS_AND_POLICIES_SUCCESS,
      data: response,
      records,
    });
  } catch (error) {
    yield put({
      type: manualsAndPoliciesActions.DELETE_MANUALS_AND_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteManualsAndPolicies() {
  yield takeEvery(
    manualsAndPoliciesActions.DELETE_MANUALS_AND_POLICIES_REQUEST,
    deleteManualsAndPolicies
  );
}

function* updateManualsAndPolicies({ data, id, records }) {
  try {
    const response = yield axios({
      url: `/administration/manuals-and-policies/${id}`,
      method: "PUT",
      data,
    });
    yield put({
      type: manualsAndPoliciesActions.UPDATE_MANUALS_AND_POLICIES_SUCCESS,
      data: response,
      records,
    });

    yield put({
      type: appUiActions.TOGGLE_UPDATE_MANUALS_AND_POLICIES_MODAL,
      data: false,
    });
  } catch (error) {
    yield put({
      type: manualsAndPoliciesActions.UPDATE_MANUALS_AND_POLICIES_REQUEST,
      error: error.data,
    });
  }
}

function* watchUpdateManualsAndPolicies() {
  yield takeLatest(
    manualsAndPoliciesActions.UPDATE_MANUALS_AND_POLICIES_REQUEST,
    updateManualsAndPolicies
  );
}

function* getManualsAndPoliciesDetails({ data }) {
  try {
    const response = yield axios({
      url: `/administration/manuals-and-policies/${data}`,
    });
    yield put({
      type: manualsAndPoliciesActions.GET_MANUALS_AND_POLICIES_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: manualsAndPoliciesActions.GET_MANUALS_AND_POLICIES_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetManualsAndPoliciesDetails() {
  yield takeLatest(
    manualsAndPoliciesActions.GET_MANUALS_AND_POLICIES_DETAILS_REQUEST,
    getManualsAndPoliciesDetails
  );
}

function* checkManualsAndPolicies({ data }) {
  try {
    const response = yield axios({
      url: `/administration/manuals-and-policies/check/${data}`,
      method: "PATCH",
    });
    yield put({
      type: manualsAndPoliciesActions.CHECK_MANUALS_AND_POLICIES_SUCCESS,
      data: response,
    });
    yield put({
      type: manualsAndPoliciesActions.GET_MANUALS_AND_POLICIES_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: manualsAndPoliciesActions.CHECK_MANUALS_AND_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckManualsAndPolicies() {
  yield takeLatest(
    manualsAndPoliciesActions.CHECK_MANUALS_AND_POLICIES_REQUEST,
    checkManualsAndPolicies
  );
}

function* approveManualsAndPolicies({ data }) {
  try {
    const response = yield axios({
      url: `/administration/manuals-and-policies/approve/${data}`,
      method: "PATCH",
    });
    yield put({
      type: manualsAndPoliciesActions.APPROVE_MANUALS_AND_POLICIES_SUCCESS,
      data: response,
    });
    yield put({
      type: manualsAndPoliciesActions.GET_MANUALS_AND_POLICIES_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: manualsAndPoliciesActions.APPROVE_MANUALS_AND_POLICIES_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveManualsAndPolicies() {
  yield takeLatest(
    manualsAndPoliciesActions.APPROVE_MANUALS_AND_POLICIES_REQUEST,
    approveManualsAndPolicies
  );
}

export default [
  fork(watchGetManualsAndPolicies),
  fork(watchAddManualsAndPolicies),
  fork(watchDeleteManualsAndPolicies),
  fork(watchUpdateManualsAndPolicies),
  fork(watchGetManualsAndPoliciesDetails),
  fork(watchCheckManualsAndPolicies),
  fork(watchApproveManualsAndPolicies),
];
