import { moesDocumentsActions } from "../../../actions";
import initialState from "../../../initialState";

const moesDocuments = (state = initialState.moesDocuments, actions) => {
  switch (actions.type) {
    case moesDocumentsActions.GET_MOES_DOCUMENTS_REQUEST:
      return {
        ...state,
        moesDocumentsLoading: true,
        moesDocumentsError: {},
      };
    case moesDocumentsActions.GET_MOES_DOCUMENTS_SUCCESS:
      return {
        ...state,
        moesDocumentsLoading: false,
        moesDocuments: actions.data,
      };
    case moesDocumentsActions.GET_MOES_DOCUMENTS_ERROR:
      return {
        ...state,
        moesDocumentsLoading: false,
        moesDocumentsError: actions.error,
      };

    default:
      return state;
  }
};

export default moesDocuments;
