import { rolesActions } from "../../../actions";
import initialState from "../../../initialState";

const roles = (state = initialState.roles, actions) => {
  switch (actions.type) {
    case rolesActions.GET_ROLES_REQUEST:
      return {
        ...state,
        rolesLoading: true,
      };
    case rolesActions.GET_ROLES_SUCCESS:
      return {
        ...state,
        rolesSuccess: actions.data,
        rolesError: {},
        rolesLoading: false,
      };
    case rolesActions.GET_ROLES_ERROR:
      return {
        ...state,
        rolesError: actions.error,
        rolesLoading: false,
      };
    case rolesActions.ADD_ROLES_REQUEST:
      return {
        ...state,
        addRoleLoading: true,
        addRolesSuccess: {},
        addRoleError: {},
      };
    case rolesActions.ADD_ROLES_SUCCESS:
      return {
        ...state,
        addRolesSuccess: actions.data,
        addRoleLoading: false,
        addRoleError: {},
        rolesSuccess: {
          ...state.rolesSuccess,
          organisationRoles: [
            ...state.rolesSuccess.organisationRoles,
            actions.data.organisationRole,
          ],
        },
      };
    case rolesActions.ADD_ROLES_ERROR:
      return {
        ...state,
        addRoleLoading: false,
        addRoleError: actions.error,
      };

    case rolesActions.DELETE_ROLES_REQUEST:
      return {
        ...state,
        deleteRolesLoading: true,
      };
    case rolesActions.DELETE_ROLES_SUCCESS:
      return {
        ...state,
        deleteRolesLoading: false,
        deleteRolesSuccess: actions.data,
        deleteRolesError: {},
        rolesSuccess: {
          ...state.rolesSuccess,
          organisationRoles: actions.roles,
        },
      };
    case rolesActions.DELETE_ROLES_ERROR:
      return {
        ...state,
        deleteRolesLoading: false,
        deleteRolesError: actions.error,
      };
    case rolesActions.SET_UPDATE_ROLE_DATA:
      return {
        ...state,
        updateRolesData: actions.data,
      };
    case rolesActions.UPDATE_ROLE_REQUEST:
      return {
        ...state,
        updateRolesLoading: false,
        updateRolesSuccess: {},
        updateRolesError: {},
      };
    case rolesActions.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        updateRolesLoading: false,
        updateRolesSuccess: actions.data,
        updateRolesError: {},
        rolesSuccess: {
          ...state.rolesSuccess,
          organisationRoles: actions.roles,
        },
      };
    case rolesActions.UPDATE_ROLE_ERROR:
      return {
        ...state,
        updateRolesLoading: false,
        updateRolesError: actions.error,
      };
    case rolesActions.GET_ROLES_DETAILS_REQUEST:
      return {
        ...state,
        rolesDetailsLoading: true,
      };
    case rolesActions.GET_ROLES_DETAILS_SUCCESS:
      return {
        ...state,
        rolesDetailsSuccess: actions.data,
        rolesDetailsLoading: false,
        rolesDetailsError: {},
      };
    case rolesActions.GET_ROLES_DETAILS_ERROR:
      return {
        ...state,
        rolesDetailsLoading: false,
        rolesDetailsError: actions.error,
      };

    default:
      return state;
  }
};

export default roles;
