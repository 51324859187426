const rejectionsInitialState = {
  rejections: {
    addRejectionsLoading: false,
    addRejectionsSuccess: {},
    addRejectionsError: {},
    rejectionsData: {},
  },
};

export default rejectionsInitialState;
