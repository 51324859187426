const ucedInitialState = {
  uced: {
    diocesesLoading: false,
    diocesesSuccess: {},
    diocesesError: {},

    religionsLoading: false,
    religionsSuccess: {},
    religionsError: {},
  },
};

export default ucedInitialState;
