const marksUploadInitialState = {
  marksUpload: {
    marksUploadSuccess: {},
    marksUploadError: {},
    marksUploadLoading: false,

    addMarksUploadSuccess: {},
    addMarksUploadError: {},
    addMarksUploadLoading: false,

    updateMarksUploadSuccess: {},
    updateMarksUploadError: {},
    updateMarksUploadLoading: false,
    updateMarksUploadData: {},

    uploadStudentMarksSuccess: {},
    uploadStudentMarksError: {},
    uploadStudentMarksLoading: false,
    uploadStudentMarksData: {},

    deleteMarksUploadSuccess: {},
    deleteMarksUploadError: {},
    deleteMarksUploadLoading: false,

    metaDataSuccess: {},
    metaDataError: {},
    metaDataLoading: false,

    submitMarksUploadSuccess: {},
    submitMarksUploadError: {},
    submitMarksUploadLoading: false,

    checkMarksUploadSuccess: {},
    checkMarksUploadError: {},
    checkMarksUploadLoading: false,

    approveMarksUploadSuccess: {},
    approveMarksUploadError: {},
    approveMarksUploadLoading: false,

    marksUploadDetailsSuccess: {},
    marksUploadDetailsError: {},
    marksUploadDetailsLoading: false,

    updateStudentMarksSuccess: {},
    updateStudentMarksError: {},
    updateStudentMarksLoading: false,
    updateStudentMarksData: {},

    addStudentMarksSuccess: {},
    addStudentMarksError: {},
    addStudentMarksLoading: false,

    downloadStudentMarksExcelSuccess: {},
    downloadStudentMarksExcelError: {},
    downloadStudentMarksExcelLoading: false,

    uploadStudentMarksExcelSuccess: {},
    uploadStudentMarksExcelError: {},
    uploadStudentMarksExcelLoading: false,

    marksExcelUploaded: false,
  },
};

export default marksUploadInitialState;
