import { schoolDevelopmentPlanActions } from "../../../actions";
import initialState from "../../../initialState";

const schoolDevelopmentPlanReducer = (
  state = initialState.schoolDevelopmentPlan,
  action
) => {
  switch (action.type) {
    case schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_REQUEST:
      return {
        ...state,
        schoolDevelopmentPlanLoading: true,
        schoolDevelopmentPlanError: false,
      };
    case schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_SUCCESS:
      return {
        ...state,
        schoolDevelopmentPlan: action.data,
        schoolDevelopmentPlanLoading: false,
        schoolDevelopmentPlanError: false,
      };
    case schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_ERROR:
      return {
        ...state,
        schoolDevelopmentPlanLoading: false,
        schoolDevelopmentPlanError: action.error,
      };
    case schoolDevelopmentPlanActions.ADD_SCHOOL_DEVELOPMENT_PLAN_REQUEST:
      return {
        ...state,
        addSchoolDevelopmentPlanLoading: true,
        addSchoolDevelopmentPlanError: {},
      };
    case schoolDevelopmentPlanActions.ADD_SCHOOL_DEVELOPMENT_PLAN_SUCCESS:
      return {
        ...state,
        addSchoolDevelopmentPlan: action.data,
        addSchoolDevelopmentPlanLoading: false,
        addSchoolDevelopmentPlanError: {},
      };
    case schoolDevelopmentPlanActions.ADD_SCHOOL_DEVELOPMENT_PLAN_ERROR:
      return {
        ...state,
        addSchoolDevelopmentPlanLoading: false,
        addSchoolDevelopmentPlanError: action.error,
      };
    case schoolDevelopmentPlanActions.UPDATE_SCHOOL_DEVELOPMENT_PLAN_REQUEST:
      return {
        ...state,
        updateSchoolDevelopmentPlanLoading: true,
        updateSchoolDevelopmentPlanError: {},
      };
    case schoolDevelopmentPlanActions.UPDATE_SCHOOL_DEVELOPMENT_PLAN_SUCCESS:
      return {
        ...state,
        updateSchoolDevelopmentPlan: action.data,
        updateSchoolDevelopmentPlanLoading: false,
        updateSchoolDevelopmentPlanError: {},
        // pass back the updated data to the schoolDevelopmentPlan object
        schoolDevelopmentPlan: {
          ...state.schoolDevelopmentPlan,
          schoolDevelopmentPlans:
            state.schoolDevelopmentPlan.schoolDevelopmentPlans.map(
              (schoolDevelopmentPlan) => {
                if (schoolDevelopmentPlan.id === action.data.data.id) {
                  return action.data.data;
                }
                return schoolDevelopmentPlan;
              }
            ),
        },
      };
    case schoolDevelopmentPlanActions.UPDATE_SCHOOL_DEVELOPMENT_PLAN_ERROR:
      return {
        ...state,
        updateSchoolDevelopmentPlanLoading: false,
        updateSchoolDevelopmentPlanError: action.error,
      };
    case schoolDevelopmentPlanActions.DELETE_SCHOOL_DEVELOPMENT_PLAN_REQUEST:
      return {
        ...state,
        deleteSchoolDevelopmentPlanLoading: true,
        deleteSchoolDevelopmentPlanError: {},
      };
    case schoolDevelopmentPlanActions.DELETE_SCHOOL_DEVELOPMENT_PLAN_SUCCESS:
      return {
        ...state,
        deleteSchoolDevelopmentPlan: action.data,
        deleteSchoolDevelopmentPlanLoading: false,
        deleteSchoolDevelopmentPlanError: {},
        // pass back the updated data to the schoolDevelopmentPlan object
        schoolDevelopmentPlan: {
          ...state.schoolDevelopmentPlan,
          schoolDevelopmentPlans:
            state.schoolDevelopmentPlan.schoolDevelopmentPlans.filter(
              (schoolDevelopmentPlan) =>
                schoolDevelopmentPlan.id !== action.data.id
            ),
        },
      };
    case schoolDevelopmentPlanActions.DELETE_SCHOOL_DEVELOPMENT_PLAN_ERROR:
      return {
        ...state,
        deleteSchoolDevelopmentPlanLoading: false,
        deleteSchoolDevelopmentPlanError: action.error,
      };
    case schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_DETAILS_REQUEST:
      return {
        ...state,
        schoolDevelopmentPlanDetailsLoading: true,
        schoolDevelopmentPlanDetailsError: {},
      };
    case schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_DETAILS_SUCCESS:
      return {
        ...state,
        schoolDevelopmentPlanDetails: action.data,
        schoolDevelopmentPlanDetailsLoading: false,
        schoolDevelopmentPlanDetailsError: {},
      };
    case schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_DETAILS_ERROR:
      return {
        ...state,
        schoolDevelopmentPlanDetailsLoading: false,
        schoolDevelopmentPlanDetailsError: action.error,
      };
    case schoolDevelopmentPlanActions.ADD_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_REQUEST:
      return {
        ...state,
        addSchoolDevelopmentPlanExpenditureLoading: true,
        addSchoolDevelopmentPlanExpenditureError: {},
      };
    case schoolDevelopmentPlanActions.ADD_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_SUCCESS:
      return {
        ...state,
        addSchoolDevelopmentPlanExpenditure: action.data,
        addSchoolDevelopmentPlanExpenditureLoading: false,
        addSchoolDevelopmentPlanExpenditureError: {},
        // pass back the updated data to the schoolDevelopmentPlan object
        schoolDevelopmentPlan: {
          ...state.schoolDevelopmentPlan,
          schoolDevelopmentPlans:
            state.schoolDevelopmentPlan.schoolDevelopmentPlans.map(
              (schoolDevelopmentPlan) => {
                if (schoolDevelopmentPlan.id === action.data.id) {
                  //   create a new array with the updated expenditure
                  const newExpenditure = [
                    ...schoolDevelopmentPlan.schoolDevelopmentPlanExpenditures,
                    ...action.data.data,
                  ];
                  // return the updated schoolDevelopmentPlan object
                  return {
                    ...schoolDevelopmentPlan,
                    schoolDevelopmentPlanExpenditures: newExpenditure,
                  };
                }
                return schoolDevelopmentPlan;
              }
            ),
        },
      };
    case schoolDevelopmentPlanActions.ADD_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_ERROR:
      return {
        ...state,
        addSchoolDevelopmentPlanExpenditureLoading: false,
        addSchoolDevelopmentPlanExpenditureError: action.error,
      };
    case schoolDevelopmentPlanActions.UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_REQUEST:
      return {
        ...state,
        updateSchoolDevelopmentPlanExpenditureLoading: true,
        updateSchoolDevelopmentPlanExpenditureError: {},
      };
    case schoolDevelopmentPlanActions.UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_SUCCESS:
      return {
        ...state,
        updateSchoolDevelopmentPlanExpenditure: action.data,
        updateSchoolDevelopmentPlanExpenditureLoading: false,
        updateSchoolDevelopmentPlanExpenditureError: {},
        // pass back the updated data to the schoolDevelopmentPlan object
        schoolDevelopmentPlan: {
          ...state.schoolDevelopmentPlan,
          schoolDevelopmentPlans:
            state.schoolDevelopmentPlan.schoolDevelopmentPlans.map(
              (schoolDevelopmentPlan) => {
                if (schoolDevelopmentPlan.id === action.data.data.details_id) {
                  //   create a new array with the updated expenditure
                  const updatedExpenditures = [
                    ...schoolDevelopmentPlan.schoolDevelopmentPlanExpenditures.filter(
                      (expenditure) => expenditure.id !== action.data.id
                    ),
                    action.data.data,
                  ];
                  // return the updated schoolDevelopmentPlan object
                  return {
                    ...schoolDevelopmentPlan,
                    schoolDevelopmentPlanExpenditures: updatedExpenditures,
                  };
                }
                return schoolDevelopmentPlan;
              }
            ),
        },
      };
    case schoolDevelopmentPlanActions.UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_ERROR:
      return {
        ...state,
        updateSchoolDevelopmentPlanExpenditureLoading: false,
        updateSchoolDevelopmentPlanExpenditureError: action.error,
      };
    case schoolDevelopmentPlanActions.DELETE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_REQUEST:
      return {
        ...state,
        deleteSchoolDevelopmentPlanExpenditureLoading: true,
        deleteSchoolDevelopmentPlanExpenditureError: {},
      };
    case schoolDevelopmentPlanActions.DELETE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_SUCCESS:
      return {
        ...state,
        deleteSchoolDevelopmentPlanExpenditure: action.data,
        deleteSchoolDevelopmentPlanExpenditureLoading: false,
        deleteSchoolDevelopmentPlanExpenditureError: {},
        // pass back the updated data to the schoolDevelopmentPlan object
        schoolDevelopmentPlan: {
          ...state.schoolDevelopmentPlan,
          schoolDevelopmentPlans:
            state.schoolDevelopmentPlan.schoolDevelopmentPlans.map(
              (schoolDevelopmentPlan) => {
                if (schoolDevelopmentPlan.id === action.data.plan_id) {
                  //   create a new array with the updated expenditure
                  const updatedExpenditures = [
                    ...schoolDevelopmentPlan.schoolDevelopmentPlanExpenditures.filter(
                      (expenditure) => expenditure.id !== action.data.id
                    ),
                  ];
                  // return the updated schoolDevelopmentPlan object
                  return {
                    ...schoolDevelopmentPlan,
                    schoolDevelopmentPlanExpenditures: updatedExpenditures,
                  };
                }
                return schoolDevelopmentPlan;
              }
            ),
        },
      };
    case schoolDevelopmentPlanActions.DELETE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_ERROR:
      return {
        ...state,
        deleteSchoolDevelopmentPlanExpenditureLoading: false,
        deleteSchoolDevelopmentPlanExpenditureError: action.error,
      };
    case schoolDevelopmentPlanActions.CHECK_SCHOOL_DEVELOPMENT_PLAN_REQUEST:
      return {
        ...state,
        checkSchoolDevelopmentPlanLoading: true,
        checkSchoolDevelopmentPlanError: {},
      };
    case schoolDevelopmentPlanActions.CHECK_SCHOOL_DEVELOPMENT_PLAN_SUCCESS:
      return {
        ...state,
        checkSchoolDevelopmentPlan: action.data,
        checkSchoolDevelopmentPlanLoading: false,
        checkSchoolDevelopmentPlanError: {},
        // pass back the updated data to the schoolDevelopmentPlan object and change the status to checked
        schoolDevelopmentPlan: {
          ...state.schoolDevelopmentPlan,
          schoolDevelopmentPlans:
            state.schoolDevelopmentPlan.schoolDevelopmentPlans.map(
              (schoolDevelopmentPlan) => {
                if (schoolDevelopmentPlan.id === action.data.id) {
                  return {
                    ...schoolDevelopmentPlan,
                    status: "Checked",
                  };
                }
                return schoolDevelopmentPlan;
              }
            ),
        },
      };
    case schoolDevelopmentPlanActions.CHECK_SCHOOL_DEVELOPMENT_PLAN_ERROR:
      return {
        ...state,
        checkSchoolDevelopmentPlanLoading: false,
        checkSchoolDevelopmentPlanError: action.error,
      };
    case schoolDevelopmentPlanActions.APPROVE_SCHOOL_DEVELOPMENT_PLAN_REQUEST:
      return {
        ...state,
        approveSchoolDevelopmentPlanLoading: true,
        approveSchoolDevelopmentPlanError: {},
      };
    case schoolDevelopmentPlanActions.APPROVE_SCHOOL_DEVELOPMENT_PLAN_SUCCESS:
      return {
        ...state,
        approveSchoolDevelopmentPlan: action.data,
        approveSchoolDevelopmentPlanLoading: false,
        approveSchoolDevelopmentPlanError: {},
        // pass back the updated data to the schoolDevelopmentPlan object
        schoolDevelopmentPlan: {
          ...state.schoolDevelopmentPlan,
          schoolDevelopmentPlans:
            state.schoolDevelopmentPlan.schoolDevelopmentPlans.map(
              (schoolDevelopmentPlan) => {
                if (schoolDevelopmentPlan.id === action.data.id) {
                  return {
                    ...schoolDevelopmentPlan,
                    status: "Approved",
                  };
                }
                return schoolDevelopmentPlan;
              }
            ),
        },
      };
    case schoolDevelopmentPlanActions.APPROVE_SCHOOL_DEVELOPMENT_PLAN_ERROR:
      return {
        ...state,
        approveSchoolDevelopmentPlanLoading: false,
        approveSchoolDevelopmentPlanError: action.error,
      };
    case schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
        metaDataError: {},
      };
    case schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: action.data,
        metaDataLoading: false,
        metaDataError: {},
      };
    case schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_META_DATA_ERROR:
      return {
        ...state,
        metaDataLoading: false,
        metaDataError: action.error,
      };
    case schoolDevelopmentPlanActions.SET_UPDATE_SCHOOL_DEVELOPMENT_PLAN_DATA:
      return {
        ...state,
        setUpdateSchoolDevelopmentPlanData: action.data,
      };
    case schoolDevelopmentPlanActions.SET_UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_DATA:
      return {
        ...state,
        setUpdateSchoolDevelopmentPlanExpenditureData: action.data,
      };
    default:
      return state;
  }
};

export default schoolDevelopmentPlanReducer;
