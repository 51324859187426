const incomeStatement = {
  GET_INCOME_STATEMENT_REQUEST: "GET_INCOME_STATEMENT_REQUEST",
  GET_INCOME_STATEMENT_SUCCESS: "GET_INCOME_STATEMENT_SUCCESS",
  GET_INCOME_STATEMENT_ERROR: "GET_INCOME_STATEMENT_ERROR",

  GET_INCOME_STATEMENT_META_DATA_REQUEST:
    "GET_META_DATA_INCOME_STATEMENT_REQUEST",
  GET_INCOME_STATEMENT_META_DATA_SUCCESS:
    "GET_INCOME_STATEMENT_META_DATA_SUCCESS",
  GET_INCOME_STATEMENT_META_DATA_ERROR: "GET_INCOME_STATEMENT_META_DATA_ERROR",

  GET_BALANCE_SHEET_REQUEST: "GET_BALANCE_SHEET_REQUEST",
  GET_BALANCE_SHEET_SUCCESS: "GET_BALANCE_SHEET_SUCCESS",
  GET_BALANCE_SHEET_ERROR: "GET_BALANCE_SHEET_ERROR",

  SET_INCOME_STATEMENT_ACCOUNT_DATA: "SET_INCOME_STATEMENT_ACCOUNT_DATA",
  SET_GET_BY_DATA: "SET_GET_BY_DATA",

  getBalanceSheet: (params) => ({
    type: incomeStatement.GET_BALANCE_SHEET_REQUEST,
    params,
  }),
  getIncomeStatement: (data) => ({
    type: incomeStatement.GET_INCOME_STATEMENT_REQUEST,
    data,
  }),
  getMetaData: () => ({
    type: incomeStatement.GET_INCOME_STATEMENT_META_DATA_REQUEST,
  }),
  setIncomeStatementAccountData: (data) => ({
    type: incomeStatement.SET_INCOME_STATEMENT_ACCOUNT_DATA,
    data,
  }),
  setGetByData: (data) => ({
    type: incomeStatement.SET_GET_BY_DATA,
    data,
  }),
};

export default incomeStatement;
