import { all } from "redux-saga/effects";
import manualsAndPoliciesSaga from "./administration/manualsAndPolicies/manualsAndPolicies.saga";
import organisationDetailsSaga from "./administration/organisationDetails/organisationDetails.saga";
import rejectionsSaga from "./app/rejections.saga";
import authSaga from "./auth/auth.saga";
import financeStreamsSaga from "./businsessDevelopmentAndPartnerships/financeStreams/financeStreams.saga";
import chartOfAccountsSaga from "./finance/chartOfAccounts/chartOfAccounts.saga";
import organisationUsersSaga from "./humanResource/organisationUsers/organisationUsers.saga";
import organisationsSaga from "./organisations/organisations.saga";
import logDetailsSaga from "./settings/logDetails/logDetails.saga";
import permissionsSaga from "./settings/permissions/permissions.saga";
import rolesSaga from "./settings/roles/roles.saga";
import subscriptionsSaga from "./subscriptions/subscriptions.saga";
import userProfileSaga from "./settings/userProfile/userProfile.saga";
import learnersSaga from "./administration/learners/learners.saga";
import banksSaga from "./finance/banks/banks.saga";
import partnersSaga from "./businsessDevelopmentAndPartnerships/partners/partners.saga";
import budgetsSaga from "./finance/budgets/budgets.saga";
import assetRegisterSaga from "./assetsAndInventory/assetRegister/assetRegister.sagas";
import incomeReceiptsSaga from "./finance/incomeReceipts/incomeReceipts.saga";
import storesSaga from "./assetsAndInventory/stores/stores.saga";
import requisitionMemosSaga from "./finance/requisitionMemos/requisitionMemos.saga";
import projectsSaga from "./projects/projects.sagas";
import quotationsSaga from "./procurement/requestForQuotations/requestForQuotations.sagas";
import ledgersSaga from "./finance/ledgers/ledgers.saga";
import paymentVouchersSaga from "./finance/paymentVouchers/paymentVouchers.saga";
import classesSaga from "./administration/classes/classes.saga";
import academicYearsSaga from "./administration/academicYears/academicYears.saga";
import paymentStructureSaga from "./administration/paymentStructures/paymentStructure.saga";
import feesCollectionsSaga from "./administration/feesCollections/feesCollections.saga";
import advanceAccountabilityFormsSaga from "./finance/advanceAccountabilityForms/advanceAccountabilityForms.saga";
import cashBookSaga from "./finance/cashBook/cashBook.saga";
import enrollmentSaga from "./administration/enrollment/enrollment.saga";
import subjectClassSaga from "./academics/subjectClass/subjectClass.saga";
import teacherAssignmentSaga from "./academics/teacherAssignment/teacherAssignment.saga";
import bidEvaluationsSaga from "./procurement/bidEvaluations/bidEvaluations.sagas";
import examinationsSaga from "./academics/examinations/examinations.saga";
import optionalSubjectsSaga from "./academics/optionalSubjects/optionalSubjects.saga";
import subjectsSaga from "./academics/subjects/subjects.saga";
import recordOfBiddersSaga from "./procurement/recordOfBidders/recordOfBidders.saga";
import noticesOfBestBiddersSaga from "./procurement/noticesOfBestBidders/noticesOfBestBidders.sagas";
import gradingSaga from "./academics/grading/grading.saga";
import appSaga from "./app/app.saga";
import bursariesSaga from "./administration/bursaries/bursaries.saga";
import localPurchaseOrdersSaga from "./procurement/localPurchaseOrders/localPurchaseOrders.sagas";
import marksUploadSaga from "./academics/marksUpload/marksUpload.saga";
import studentReportsSaga from "./academics/studentReports/studentReports.saga";
import incomeStatementSaga from "./finance/incomeStatement/incomeStatement.saga";
import dashboardSaga from "./app/dashboard.saga";
import singleLearnerLedgerSaga from "./administration/singleLearnerLedger/singleLearnerLedger.saga";
import transferInstitutionSaga from "./settings/transferInstitution/transferInstitution.saga";
import learnerBillingSaga from "./administration/learnerBilling/learnerBilling.saga";
import performanceAnalysisSaga from "./academics/performanceAnalysis.saga";
import loansRepaymentScheduleSaga from "./finance/loansRepaymentSchedule.saga";
import supplierPaymentsSaga from "./finance/supplierPayments/supplierPayments.saga";
import learnerAttendanceSaga from "./administration/learnerAttendance/learnerAttendance.saga";
import staffAttendanceSaga from "./humanResource/staffAttendance/staffAttendance.saga";
import schoolCommunicationsSaga from "./administration/schoolCommunications/schoolCommunications.saga";
import schoolDevelopmentPlanSaga from "./administration/schoolDevelopmentPlan/schoolDevelopmentPlan.saga";
import staffPayRollSaga from "./humanResource/staffPayRoll/staffPayRoll.saga";
import clientInvoicesSaga from "./finance/clientInvoices/clientInvoices.saga";
import receivablesSaga from "./finance/receivables/receivables.saga";
import prepaymentsSaga from "./finance/prepayments/prepayments.saga";
import brmsComplianceSaga from "./administration/brmsCompliance/brmsCompliance.saga";
import moesDocumentsSaga from "./administration/moesDocuments/moesDocuments.saga";
import schoolGatePassSaga from "./administration/schoolGatePass/schoolGatePass.saga";
import timetableSaga from "./academics/timetable/timetable.saga";
import schemeOfWorkSaga from "./academics/schemeOfWork/schemeOfWork.saga";
import lessonPlansSaga from "./academics/lessonPlans/lessonPlans.saga";
import learnerIndisciplineSaga from "./administration/learnerIndiscipline/learnerIndiscipline.saga";
import ucedSaga from "./uced/uced.saga";

export default function* root() {
  yield all([
    ...dashboardSaga,
    ...loansRepaymentScheduleSaga,
    ...authSaga,
    ...appSaga,
    ...organisationsSaga,
    ...subscriptionsSaga,
    ...rolesSaga,
    ...logDetailsSaga,
    ...permissionsSaga,
    ...organisationDetailsSaga,
    ...manualsAndPoliciesSaga,
    ...rejectionsSaga,
    ...organisationUsersSaga,
    ...chartOfAccountsSaga,
    ...userProfileSaga,
    ...financeStreamsSaga,
    ...learnersSaga,
    ...banksSaga,
    ...incomeStatementSaga,
    ...partnersSaga,
    ...budgetsSaga,
    ...assetRegisterSaga,
    ...incomeReceiptsSaga,
    ...storesSaga,
    ...marksUploadSaga,
    ...requisitionMemosSaga,
    ...projectsSaga,
    ...quotationsSaga,
    ...ledgersSaga,
    ...paymentVouchersSaga,
    ...classesSaga,
    ...academicYearsSaga,
    ...paymentStructureSaga,
    ...feesCollectionsSaga,
    ...advanceAccountabilityFormsSaga,
    ...cashBookSaga,
    ...enrollmentSaga,
    ...moesDocumentsSaga,
    ...subjectClassSaga,
    ...teacherAssignmentSaga,
    ...bidEvaluationsSaga,
    ...examinationsSaga,
    ...optionalSubjectsSaga,
    ...subjectsSaga,
    ...recordOfBiddersSaga,
    ...noticesOfBestBiddersSaga,
    ...gradingSaga,
    ...bursariesSaga,
    ...localPurchaseOrdersSaga,
    ...studentReportsSaga,
    ...singleLearnerLedgerSaga,
    ...transferInstitutionSaga,
    ...learnerBillingSaga,
    ...performanceAnalysisSaga,
    ...supplierPaymentsSaga,
    ...learnerAttendanceSaga,
    ...staffAttendanceSaga,
    ...schemeOfWorkSaga,
    ...schoolCommunicationsSaga,
    ...schoolDevelopmentPlanSaga,
    ...staffPayRollSaga,
    ...clientInvoicesSaga,
    ...receivablesSaga,
    ...prepaymentsSaga,
    ...brmsComplianceSaga,
    ...schoolGatePassSaga,
    ...timetableSaga,
    ...lessonPlansSaga,
    ...learnerIndisciplineSaga,
    ...ucedSaga,
  ]);
}
