import { takeLatest, put, fork } from "redux-saga/effects";
import axios from "axios";
import { appUiActions, studentReportActions } from "../../../actions";

function* getStudentReports() {
  try {
    const response = yield axios({
      url: "/academics/student-reports",
    });
    yield put({
      type: studentReportActions.GET_STUDENT_REPORTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: studentReportActions.GET_STUDENT_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetStudentReports() {
  yield takeLatest(
    studentReportActions.GET_STUDENT_REPORTS_REQUEST,
    getStudentReports
  );
}

function* getMetaData() {
  try {
    const response = yield axios({
      url: "/academics/student-reports/meta-data",
    });
    yield put({
      type: studentReportActions.GET_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: studentReportActions.GET_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(studentReportActions.GET_META_DATA_REQUEST, getMetaData);
}

function* promoteLearners({ data, reportId }) {
  try {
    const response = yield axios({
      url: `/academics/student-reports/promote-learners/${reportId}`,
      method: "POST",
      data: data,
    });

    yield put({
      type: studentReportActions.PROMOTE_LEARNERS_SUCCESS,
      data: response,
    });

    yield put(studentReportActions.getStudentReportDetails(reportId));
  } catch (error) {
    yield put({
      type: studentReportActions.PROMOTE_LEARNERS_ERROR,
      error: error.data,
    });
  }
}

function* watchPromoteLearners() {
  yield takeLatest(
    studentReportActions.PROMOTE_LEARNERS_REQUEST,
    promoteLearners
  );
}

function* getStudentReportDetails({ id }) {
  try {
    const response = yield axios({
      url: `/academics/student-reports/${id}`,
    });
    yield put({
      type: studentReportActions.GET_STUDENT_REPORTS_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: studentReportActions.GET_STUDENT_REPORTS_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetStudentReportDetails() {
  yield takeLatest(
    studentReportActions.GET_STUDENT_REPORTS_DETAILS_REQUEST,
    getStudentReportDetails
  );
}

function* addStudentReport({ data }) {
  try {
    const response = yield axios({
      url: `/academics/student-reports`,
      method: "POST",
      data: data,
    });
    yield put({
      type: studentReportActions.ADD_STUDENT_REPORTS_SUCCESS,
      data: response,
    });
    yield put(studentReportActions.getStudentReports());
  } catch (error) {
    yield put({
      type: studentReportActions.ADD_STUDENT_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* watchAddStudentReport() {
  yield takeLatest(
    studentReportActions.ADD_STUDENT_REPORTS_REQUEST,
    addStudentReport
  );
}

function* updateStudentReport({ id, data }) {
  try {
    const response = yield axios({
      url: `/academics/student-reports/${id}`,
      method: "PUT",
      data: data,
    });
    yield put({
      type: studentReportActions.UPDATE_STUDENT_REPORTS_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleUpdateStudentReportsModal(false));
    yield put(studentReportActions.getStudentReports());
  } catch (error) {
    yield put({
      type: studentReportActions.UPDATE_STUDENT_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateStudentReport() {
  yield takeLatest(
    studentReportActions.UPDATE_STUDENT_REPORTS_REQUEST,
    updateStudentReport
  );
}

function* deleteStudentReport({ id }) {
  try {
    const response = yield axios({
      url: `/academics/student-reports/${id}`,
      method: "DELETE",
    });
    yield put({
      type: studentReportActions.DELETE_STUDENT_REPORTS_SUCCESS,
      data: response,
    });
    yield put(studentReportActions.getStudentReports());
  } catch (error) {
    yield put({
      type: studentReportActions.DELETE_STUDENT_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteStudentReport() {
  yield takeLatest(
    studentReportActions.DELETE_STUDENT_REPORTS_REQUEST,
    deleteStudentReport
  );
}

function* deleteReportExams({ id }) {
  try {
    const response = yield axios({
      url: `/academics/student-reports/exams/${id}`,
      method: "DELETE",
    });
    yield put({
      type: studentReportActions.DELETE_REPORT_EXAM_SUCCESS,
      data: response,
    });
    yield put(studentReportActions.getStudentReports());
  } catch (error) {
    yield put({
      type: studentReportActions.DELETE_REPORT_EXAM_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteReportExams() {
  yield takeLatest(
    studentReportActions.DELETE_REPORT_EXAM_REQUEST,
    deleteReportExams
  );
}

function* addReportComments({ data }) {
  try {
    const response = yield axios({
      url: `/academics/student-reports/comments`,
      method: "POST",
      data: data,
    });
    yield put({
      type: studentReportActions.ADD_REPORT_COMMENTS_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleReportCommentsModal(false));
  } catch (error) {
    yield put({
      type: studentReportActions.ADD_REPORT_COMMENTS_ERROR,
      error: error.data,
    });
  }
}

function* watchAddReportComments() {
  yield takeLatest(
    studentReportActions.ADD_REPORT_COMMENTS_REQUEST,
    addReportComments
  );
}

function* updateReportComments({ data, id }) {
  try {
    const response = yield axios({
      url: `/academics/student-reports/comments/${id}`,
      method: "PUT",
      data: data,
    });
    yield put({
      type: studentReportActions.UPDATE_REPORT_COMMENTS_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleReportCommentsModal(false));
  } catch (error) {
    yield put({
      type: studentReportActions.UPDATE_REPORT_COMMENTS_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateReportComments() {
  yield takeLatest(
    studentReportActions.UPDATE_REPORT_COMMENTS_REQUEST,
    updateReportComments
  );
}

function* deleteReportComments({ id }) {
  try {
    const response = yield axios({
      url: `/academics/student-reports/comments/${id}`,
      method: "DELETE",
    });
    yield put({
      type: studentReportActions.DELETE_REPORT_COMMENTS_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleReportCommentsModal(false));
  } catch (error) {
    yield put({
      type: studentReportActions.DELETE_REPORT_COMMENTS_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteReportComments() {
  yield takeLatest(
    studentReportActions.DELETE_REPORT_COMMENTS_REQUEST,
    deleteReportComments
  );
}

function* getLearnerBilling({ data }) {
  try {
    const response = yield axios({
      url: `/administration/learner-billing`,
      method: "GET",
      params: data,
    });
    yield put({
      type: studentReportActions.GET_LEARNER_BILLING_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: studentReportActions.GET_LEARNER_BILLING_ERROR,
      error: error.data,
    });
  }
}

function* watchGetLearnerBilling() {
  yield takeLatest(
    studentReportActions.GET_LEARNER_BILLING_REQUEST,
    getLearnerBilling
  );
}

function* getLearnerBillingMetaData() {
  try {
    const response = yield axios({
      url: `/administration/learner-billing/meta-data`,
      method: "GET",
    });
    yield put({
      type: studentReportActions.GET_LEARNER_BILLING_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: studentReportActions.GET_LEARNER_BILLING_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetLearnerBillingMetaData() {
  yield takeLatest(
    studentReportActions.GET_LEARNER_BILLING_META_DATA_REQUEST,
    getLearnerBillingMetaData
  );
}

function* createReportException({ data }) {
  try {
    const response = yield axios({
      url: `/academics/student-reports/exceptions`,
      method: "POST",
      data: data,
    });
    yield put({
      type: studentReportActions.CREATE_REPORT_EXCEPTION_SUCCESS,
      data: response,
    });
    yield put(
      studentReportActions.getReportExceptions({
        reportCardId: data.reportExceptions[0].learner_report_id,
      })
    );
  } catch (error) {
    yield put({
      type: studentReportActions.CREATE_REPORT_EXCEPTION_ERROR,
      error: error.data,
    });
  }
}

function* watchCreateReportException() {
  yield takeLatest(
    studentReportActions.CREATE_REPORT_EXCEPTION_REQUEST,
    createReportException
  );
}

function* getReportExceptions({ data }) {
  try {
    const response = yield axios({
      url: `/academics/student-reports/exceptions`,
      method: "GET",
      params: data,
    });
    yield put({
      type: studentReportActions.GET_REPORT_EXCEPTIONS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: studentReportActions.GET_REPORT_EXCEPTIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetReportExceptions() {
  yield takeLatest(
    studentReportActions.GET_REPORT_EXCEPTIONS_REQUEST,
    getReportExceptions
  );
}

function* updateReportException({ data, id }) {
  try {
    const response = yield axios({
      url: `/academics/student-reports/exceptions/${id}`,
      method: "PUT",
      data: data,
    });
    yield put({
      type: studentReportActions.UPDATE_REPORT_EXCEPTION_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleUpdateReportExceptionModal(false));
    yield put(
      studentReportActions.getReportExceptions({
        reportCardId: data.learner_report_id,
      })
    );
  } catch (error) {
    yield put({
      type: studentReportActions.UPDATE_REPORT_EXCEPTION_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateReportException() {
  yield takeLatest(
    studentReportActions.UPDATE_REPORT_EXCEPTION_REQUEST,
    updateReportException
  );
}

function* deleteReportException({ id, reportId }) {
  try {
    const response = yield axios({
      url: `/academics/student-reports/exceptions/${id}`,
      method: "DELETE",
    });
    yield put({
      type: studentReportActions.DELETE_REPORT_EXCEPTION_SUCCESS,
      data: response,
    });
    yield put(
      studentReportActions.getReportExceptions({
        reportCardId: reportId,
      })
    );
  } catch (error) {
    yield put({
      type: studentReportActions.DELETE_REPORT_EXCEPTION_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteReportException() {
  yield takeLatest(
    studentReportActions.DELETE_REPORT_EXCEPTION_REQUEST,
    deleteReportException
  );
}

export default [
  fork(watchGetStudentReports),
  fork(watchGetMetaData),
  fork(watchGetStudentReportDetails),
  fork(watchAddStudentReport),
  fork(watchUpdateStudentReport),
  fork(watchDeleteStudentReport),
  fork(watchDeleteReportExams),
  fork(watchAddReportComments),
  fork(watchUpdateReportComments),
  fork(watchDeleteReportComments),
  fork(watchGetLearnerBilling),
  fork(watchGetLearnerBillingMetaData),
  fork(watchCreateReportException),
  fork(watchGetReportExceptions),
  fork(watchUpdateReportException),
  fork(watchDeleteReportException),
  fork(watchPromoteLearners),
];
