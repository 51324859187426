import React from "react";
import { Collapse } from "antd";

const { Panel } = Collapse;

function AppFaq() {
  return (
    <div id="faq" className="block faqBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>Frequently Asked Questions</h2>
          <p>
            Below are the frequently asked question about FAMIS. You can also
            ask us on our{" "}
            <a href="https://www.facebook.com/famis.finance/">Facebook Page</a>{" "}
            or{" "}
            <a href="https://instagram.com/famis.finance?igshid=YmMyMTA2M2Y=">
              Instagram.
            </a>
          </p>
        </div>
        <Collapse defaultActiveKey={["1"]}>
          <Panel header="Can I change plan or cancel at any time?" key="1">
            <p>
              Yes, you can change plan or cancel at any time. You can also ask
              us to change or cancel your plan.
            </p>
          </Panel>
          <Panel header="Can I manage multiple tasks?" key="2">
            <p>
              Yes, you can manage multiple tasks. Famis allows you to open
              multiple tabs of its features and you can navigate from one open
              tab to another by just a click.
            </p>
          </Panel>
          <Panel header="How can I change my password?" key="3">
            <p>
              You can change your password by going to settings, select User
              Profile and click on Actions then click on the “Change Password”
              button.
            </p>
          </Panel>
          <Panel header="How to manage my account?" key="4">
            <p>
              You can manage your account by going to settings, select User
              Profile and click on Actions then all the “Manage Account” options
              will be displayed.
            </p>
          </Panel>
        </Collapse>
        <div className="quickSupport">
          <h3>Want quick support?</h3>
          <p>
            You can contact us on our Telephone. We are always on the move to
            help you.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AppFaq;
