import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { userProfileActions } from "../../../actions";

function* getUserProfile() {
  try {
    const response = yield axios({
      url: "/users/profile",
      method: "GET",
    });

    yield put({
      type: userProfileActions.GET_USER_PROFILE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: userProfileActions.GET_USER_PROFILE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetUserProfile() {
  yield takeLatest(userProfileActions.GET_USER_PROFILE_REQUEST, getUserProfile);
}

function* uploadUserProfileAvatar({ data, id }) {
  try {
    const response = yield axios({
      url: `/users/avatar/${id}`,
      method: "PATCH",
      data,
    });

    yield put({
      type: userProfileActions.UPLOAD_USER_PROFILE_AVATAR_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: userProfileActions.UPLOAD_USER_PROFILE_AVATAR_ERROR,
      error: error.data,
    });
  }
}

function* watchUploadUserProfileAvatar() {
  yield takeLatest(
    userProfileActions.UPLOAD_USER_PROFILE_AVATAR_REQUEST,
    uploadUserProfileAvatar
  );
}

function* uploadUserSignature({ data, id }) {
  try {
    const response = yield axios({
      url: `/users/signature/${id}`,
      method: "PATCH",
      data,
    });

    yield put({
      type: userProfileActions.UPLOAD_USER_SIGNATURE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: userProfileActions.UPLOAD_USER_SIGNATURE_ERROR,
      error: error.data,
    });
  }
}

function* watchUploadUserSignature() {
  yield takeLatest(
    userProfileActions.UPLOAD_USER_SIGNATURE_REQUEST,
    uploadUserSignature
  );
}

function* changeUserPassword({ data }) {
  try {
    const response = yield axios({
      url: "/users/auth/change-password",
      method: "PATCH",
      data,
    });

    yield put({
      type: userProfileActions.CHANGE_USER_PASSWORD_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: userProfileActions.CHANGE_USER_PASSWORD_ERROR,
      error: error.data,
    });
  }
}

function* watchChangeUserPassword() {
  yield takeLatest(
    userProfileActions.CHANGE_USER_PASSWORD_REQUEST,
    changeUserPassword
  );
}
 
function* updateUserEmail({ data }) {
  try {
    const response = yield axios({
      url: "/users/update-username-email",
      method: "PATCH",
      data,
    });

    yield put({
      type: userProfileActions.UPDATE_USER_EMAIL_SUCCESS,
      data: response,
    });

    yield put(userProfileActions.getUserProfile()); // Dispatch the action
    
  } catch (error) {
    yield put({
      type: userProfileActions.UPDATE_USER_EMAIL_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateUserEmail() {
  yield takeLatest(
    userProfileActions.UPDATE_USER_EMAIL_REQUEST,
    updateUserEmail
  );
}

export default [
  fork(watchGetUserProfile),
  fork(watchUploadUserProfileAvatar),
  fork(watchUploadUserSignature),
  fork(watchChangeUserPassword),
  fork(watchUpdateUserEmail),
];
