import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { supplierPaymentsActions, appUiActions } from "../../../actions";

function* getSupplierPaymentsSchedule() {
  try {
    const response = yield axios({
      url: "/finance/suppliers-payment-schedule",
    });

    yield put({
      type: supplierPaymentsActions.GET_SUPPLIER_PAYMENTS_SCHEDULE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: supplierPaymentsActions.GET_SUPPLIER_PAYMENTS_SCHEDULE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSupplierPaymentsSchedule() {
  yield takeLatest(
    supplierPaymentsActions.GET_SUPPLIER_PAYMENTS_SCHEDULE_REQUEST,
    getSupplierPaymentsSchedule
  );
}

function* getSupplierPaymentsScheduleMetaData() {
  try {
    const response = yield axios({
      url: "/finance/suppliers-payment-schedule/meta-data",
    });

    yield put({
      type: supplierPaymentsActions.GET_SUPPLIER_PAYMENTS_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: supplierPaymentsActions.GET_SUPPLIER_PAYMENTS_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSupplierPaymentsScheduleMetaData() {
  yield takeLatest(
    supplierPaymentsActions.GET_SUPPLIER_PAYMENTS_META_DATA_REQUEST,
    getSupplierPaymentsScheduleMetaData
  );
}

function* addSupplierPaymentSchedule({ data }) {
  try {
    const response = yield axios({
      url: "finance/suppliers-payment-schedule",
      method: "POST",
      data,
    });

    yield put({
      type: supplierPaymentsActions.ADD_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS,
      data: response,
    });

    yield put(supplierPaymentsActions.getSupplierPaymentsSchedule());
  } catch (error) {
    yield put({
      type: supplierPaymentsActions.ADD_SUPPLIER_PAYMENT_SCHEDULE_ERROR,
      error: error.data,
    });
  }
}

function* watchAddSupplierPaymentSchedule() {
  yield takeLatest(
    supplierPaymentsActions.ADD_SUPPLIER_PAYMENT_SCHEDULE_REQUEST,
    addSupplierPaymentSchedule
  );
}

function* deleteSupplierPaymentSchedule({ id }) {
  try {
    const response = yield axios({
      url: `/finance/suppliers-payment-schedule/${id}`,
      method: "DELETE",
    });

    yield put({
      type: supplierPaymentsActions.DELETE_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS,
      data: response,
    });
    yield put(supplierPaymentsActions.getSupplierPaymentsSchedule());
  } catch (error) {
    yield put({
      type: supplierPaymentsActions.DELETE_SUPPLIER_PAYMENT_SCHEDULE_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteSupplierPaymentSchedule() {
  yield takeLatest(
    supplierPaymentsActions.DELETE_SUPPLIER_PAYMENT_SCHEDULE_REQUEST,
    deleteSupplierPaymentSchedule
  );
}

function* updateSupplierPaymentSchedule({ id, data }) {
  try {
    const response = yield axios({
      url: `/finance/suppliers-payment-schedule/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: supplierPaymentsActions.UPDATE_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS,
      data: response,
    });
    yield put(supplierPaymentsActions.getSupplierPaymentsSchedule());
  } catch (error) {
    yield put({
      type: supplierPaymentsActions.UPDATE_SUPPLIER_PAYMENT_SCHEDULE_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateSupplierPaymentSchedule() {
  yield takeLatest(
    supplierPaymentsActions.UPDATE_SUPPLIER_PAYMENT_SCHEDULE_REQUEST,
    updateSupplierPaymentSchedule
  );
}

function* getSupplierPaymentScheduleDetails({ id }) {
  try {
    const response = yield axios({
      url: `/finance/suppliers-payment-schedule/${id}`,
    });

    yield put({
      type: supplierPaymentsActions.GET_SUPPLIER_PAYMENT_SCHEDULE_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: supplierPaymentsActions.GET_SUPPLIER_PAYMENT_SCHEDULE_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSupplierPaymentScheduleDetails() {
  yield takeLatest(
    supplierPaymentsActions.GET_SUPPLIER_PAYMENT_SCHEDULE_DETAILS_REQUEST,
    getSupplierPaymentScheduleDetails
  );
}

function* addSupplierPayments({ data }) {
  try {
    const response = yield axios({
      url: "/finance/suppliers-payment-schedule/payments",
      method: "POST",
      data,
    });

    yield put({
      type: supplierPaymentsActions.ADD_SUPPLIER_PAYMENTS_SUCCESS,
      data: {
        ...response,
        data,
      },
    });

    yield put(
      supplierPaymentsActions.getSupplierPaymentScheduleDetails(
        data.supplier_payment_schedule_id
      )
    );
  } catch (error) {
    yield put({
      type: supplierPaymentsActions.ADD_SUPPLIER_PAYMENTS_ERROR,
      error: error.data,
    });
  }
}

function* watchAddSupplierPayments() {
  yield takeLatest(
    supplierPaymentsActions.ADD_SUPPLIER_PAYMENTS_REQUEST,
    addSupplierPayments
  );
}

function* deleteSupplierPayment({ id, supplier_id }) {
  try {
    const response = yield axios({
      url: `/finance/suppliers-payment-schedule/payments/${id}`,
      method: "DELETE",
    });

    yield put({
      type: supplierPaymentsActions.DELETE_SUPPLIER_PAYMENT_SUCCESS,
      data: {
        ...response,
        id: id,
        supplier_payment_schedule_id: supplier_id,
      },
    });

    yield put(
      supplierPaymentsActions.getSupplierPaymentScheduleDetails(supplier_id)
    );
  } catch (error) {
    yield put({
      type: supplierPaymentsActions.DELETE_SUPPLIER_PAYMENT_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteSupplierPayment() {
  yield takeLatest(
    supplierPaymentsActions.DELETE_SUPPLIER_PAYMENT_REQUEST,
    deleteSupplierPayment
  );
}

function* updateSupplierPayment({ data }) {
  try {
    const response = yield axios({
      url: `/finance/suppliers-payment-schedule/payments/${data.id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: supplierPaymentsActions.UPDATE_SUPPLIER_PAYMENT_SUCCESS,
      data: {
        ...response,
        data,
      },
    });
  } catch (error) {
    yield put({
      type: supplierPaymentsActions.UPDATE_SUPPLIER_PAYMENT_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateSupplierPayment() {
  yield takeLatest(
    supplierPaymentsActions.UPDATE_SUPPLIER_PAYMENT_REQUEST,
    updateSupplierPayment
  );
}

function* getSupplierPaymentDetails({ id }) {
  try {
    const response = yield axios({
      url: `/finance/suppliers-payment-schedule/payments/${id}`,
    });

    yield put({
      type: supplierPaymentsActions.GET_SUPPLIER_PAYMENT_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: supplierPaymentsActions.GET_SUPPLIER_PAYMENT_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSupplierPaymentDetails() {
  yield takeLatest(
    supplierPaymentsActions.GET_SUPPLIER_PAYMENT_DETAILS_REQUEST,
    getSupplierPaymentDetails
  );
}

function* checkSupplierPaymentSchedule({ id }) {
  try {
    const response = yield axios({
      url: `/finance/suppliers-payment-schedule/check/${id}`,
      method: "PATCH",
    });

    yield put({
      type: supplierPaymentsActions.CHECK_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS,
      data: {
        ...response,
        id,
      },
    });
    // get supplier payment schedule details
    yield put(supplierPaymentsActions.getSupplierPaymentScheduleDetails(id));
  } catch (error) {
    yield put({
      type: supplierPaymentsActions.CHECK_SUPPLIER_PAYMENT_SCHEDULE_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckSupplierPaymentSchedule() {
  yield takeLatest(
    supplierPaymentsActions.CHECK_SUPPLIER_PAYMENT_SCHEDULE_REQUEST,
    checkSupplierPaymentSchedule
  );
}

function* approveSupplierPaymentSchedule({ id }) {
  try {
    const response = yield axios({
      url: `/finance/suppliers-payment-schedule/approve/${id}`,
      method: "PATCH",
    });

    yield put({
      type: supplierPaymentsActions.APPROVE_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS,
      data: {
        ...response,
        id,
      },
    });
    // get supplier payment schedule details
    yield put(supplierPaymentsActions.getSupplierPaymentScheduleDetails(id));
  } catch (error) {
    yield put({
      type: supplierPaymentsActions.APPROVE_SUPPLIER_PAYMENT_SCHEDULE_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveSupplierPaymentSchedule() {
  yield takeLatest(
    supplierPaymentsActions.APPROVE_SUPPLIER_PAYMENT_SCHEDULE_REQUEST,
    approveSupplierPaymentSchedule
  );
}

function* addSupplierPaymentInvoice({ data }) {
  try {
    const response = yield axios({
      url: "/finance/suppliers-payment-schedule/invoice",
      method: "POST",
      data,
    });

    yield put({
      type: supplierPaymentsActions.ADD_SUPPLIER_PAYMENT_INVOICE_SUCCESS,
      data: response,
    });

    yield put(
      supplierPaymentsActions.getSupplierPaymentScheduleDetails(
        data.supplier_payment_schedule_id
      )
    );
  } catch (error) {
    yield put({
      type: supplierPaymentsActions.ADD_SUPPLIER_PAYMENT_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* watchAddSupplierPaymentInvoice() {
  yield takeLatest(
    supplierPaymentsActions.ADD_SUPPLIER_PAYMENT_INVOICE_REQUEST,
    addSupplierPaymentInvoice
  );
}

function* updateSupplierPaymentInvoice({ id, data }) {
  try {
    const response = yield axios({
      url: `/finance/suppliers-payment-schedule/invoice/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: supplierPaymentsActions.UPDATE_SUPPLIER_PAYMENT_INVOICE_SUCCESS,
      data: {
        ...response,
        data,
      },
    });
    yield put(
      supplierPaymentsActions.getSupplierPaymentScheduleDetails(
        data.supplier_payment_schedule_id
      )
    );
    yield put(appUiActions.toggleUpdateSupplierPaymentInvoiceModal(false));
  } catch (error) {
    yield put({
      type: supplierPaymentsActions.UPDATE_SUPPLIER_PAYMENT_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateSupplierPaymentInvoice() {
  yield takeLatest(
    supplierPaymentsActions.UPDATE_SUPPLIER_PAYMENT_INVOICE_REQUEST,
    updateSupplierPaymentInvoice
  );
}

function* deleteSupplierPaymentInvoice({ id, supplier_id }) {
  try {
    const response = yield axios({
      url: `/finance/suppliers-payment-schedule/invoice/${id}`,
      method: "DELETE",
    });

    yield put({
      type: supplierPaymentsActions.DELETE_SUPPLIER_PAYMENT_INVOICE_SUCCESS,
      data: {
        ...response,
        id: id,
        supplier_payment_schedule_id: supplier_id,
      },
    });
    yield put(
      supplierPaymentsActions.getSupplierPaymentScheduleDetails(supplier_id)
    );
  } catch (error) {
    yield put({
      type: supplierPaymentsActions.DELETE_SUPPLIER_PAYMENT_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteSupplierPaymentInvoice() {
  yield takeLatest(
    supplierPaymentsActions.DELETE_SUPPLIER_PAYMENT_INVOICE_REQUEST,
    deleteSupplierPaymentInvoice
  );
}

export default [
  fork(watchGetSupplierPaymentsSchedule),
  fork(watchGetSupplierPaymentScheduleDetails),
  fork(watchGetSupplierPaymentsScheduleMetaData),
  fork(watchAddSupplierPaymentSchedule),
  fork(watchUpdateSupplierPaymentSchedule),
  fork(watchDeleteSupplierPaymentSchedule),
  fork(watchGetSupplierPaymentDetails),
  fork(watchAddSupplierPayments),
  fork(watchUpdateSupplierPayment),
  fork(watchDeleteSupplierPayment),
  fork(watchCheckSupplierPaymentSchedule),
  fork(watchApproveSupplierPaymentSchedule),
  fork(watchAddSupplierPaymentInvoice),
  fork(watchUpdateSupplierPaymentInvoice),
  fork(watchDeleteSupplierPaymentInvoice),
];
