const staffPayrollInitialState = {
  staffPayRoll: {
    staffPayRoll: {},
    staffPayRollLoading: false,
    staffPayRollError: {},

    addStaffPayRoll: {},
    addStaffPayRollLoading: false,
    addStaffPayRollError: {},

    metaData: {},
    metaDataLoading: false,
    metaDataError: {},

    updateStaffPayRoll: {},
    updateStaffPayRollLoading: false,
    updateStaffPayRollError: {},
    setUpdateStaffPayRollData: {},

    deleteStaffPayRoll: {},
    deleteStaffPayRollLoading: false,
    deleteStaffPayRollError: {},

    deleteStaffFromPayRoll: {},
    deleteStaffFromPayRollLoading: false,
    deleteStaffFromPayRollError: {},

    staffPayRollDetails: {},
    staffPayRollDetailsLoading: false,
    staffPayRollDetailsError: {},

    checkStaffPayRoll: {},
    checkStaffPayRollLoading: false,
    checkStaffPayRollError: {},

    approveStaffPayRoll: {},
    approveStaffPayRollLoading: false,
    approveStaffPayRollError: {},
  },
};

export default staffPayrollInitialState;
