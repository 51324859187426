import { takeLatest, takeEvery, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import {
  appUiActions,
  marksUploadActions,
  teacherAssignmentActions,
} from "../../../actions";

function* getTeacherAssignments() {
  try {
    const response = yield axios({ url: "/academics/teacher-assignment" });

    yield put({
      type: teacherAssignmentActions.GET_TEACHER_ASSIGNMENT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: teacherAssignmentActions.GET_TEACHER_ASSIGNMENT_ERROR,
      error: error.data,
    });
  }
}

function* watchGetTeacherAssignment() {
  yield takeLatest(
    teacherAssignmentActions.GET_TEACHER_ASSIGNMENT_REQUEST,
    getTeacherAssignments
  );
}

function* getTeacherAssignmentDetails({ id }) {
  try {
    const response = yield axios({
      url: `/academics/teacher-assignment/${id}`,
    });

    yield put({
      type: teacherAssignmentActions.GET_TEACHER_ASSIGNMENT_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: teacherAssignmentActions.GET_TEACHER_ASSIGNMENT_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetTeacherAssignmentDetails() {
  yield takeLatest(
    teacherAssignmentActions.GET_TEACHER_ASSIGNMENT_DETAILS_REQUEST,
    getTeacherAssignmentDetails
  );
}

function* getMetaData() {
  try {
    const response = yield axios({
      url: "/academics/teacher-assignment/meta-data",
    });

    yield put({
      type: teacherAssignmentActions.GET_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: teacherAssignmentActions.GET_META_DATA_SUCCESS,
      error: error.data,
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(teacherAssignmentActions.GET_META_DATA_REQUEST, getMetaData);
}

function* addTeacherAssignment({ data }) {
  try {
    const response = yield axios({
      url: "/academics/teacher-assignment",
      method: "POST",
      data,
    });

    yield put({
      type: teacherAssignmentActions.ADD_TEACHER_ASSIGNMENT_SUCCESS,
      data: response,
    });
    yield put(marksUploadActions.getMetaData());
  } catch (error) {
    yield put({
      type: teacherAssignmentActions.ADD_TEACHER_ASSIGNMENT_ERROR,
      error: error.data,
    });
  }
}

function* watchAddTeacherAssignment() {
  yield takeLatest(
    teacherAssignmentActions.ADD_TEACHER_ASSIGNMENT_REQUEST,
    addTeacherAssignment
  );
}

function* updateTeacherAssignment({ data, id }) {
  try {
    const response = yield axios({
      url: `/academics/teacher-assignment/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: teacherAssignmentActions.UPDATE_TEACHER_ASSIGNMENT_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleUpdateTeacherAssignmentModal(false));
  } catch (error) {
    yield put({
      type: teacherAssignmentActions.UPDATE_TEACHER_ASSIGNMENT_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateTeacherAssignment() {
  yield takeLatest(
    teacherAssignmentActions.UPDATE_TEACHER_ASSIGNMENT_REQUEST,
    updateTeacherAssignment
  );
}

function* deleteTeacherAssignment({ id }) {
  try {
    const response = yield axios({
      url: `/academics/teacher-assignment/${id}`,
      method: "DELETE",
    });

    yield put({
      type: teacherAssignmentActions.DELETE_TEACHER_ASSIGNMENT_SUCCESS,
      data: response,
    });

    yield put(teacherAssignmentActions.getTeacherAssignments());
  } catch (error) {
    yield put({
      type: teacherAssignmentActions.DELETE_TEACHER_ASSIGNMENT_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteTeacherAssignment() {
  yield takeEvery(
    teacherAssignmentActions.DELETE_TEACHER_ASSIGNMENT_REQUEST,
    deleteTeacherAssignment
  );
}

function* deleteTeacherExamPaper({ id }) {
  try {
    const response = yield axios({
      url: `/academics/teacher-assignment/teacher-exam-assignment/${id}`,
      method: "DELETE",
    });

    yield put({
      type: teacherAssignmentActions.DELETE_TEACHER_EXAM_PAPER_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: teacherAssignmentActions.DELETE_TEACHER_EXAM_PAPER_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteTeacherExamPaper() {
  yield takeEvery(
    teacherAssignmentActions.DELETE_TEACHER_EXAM_PAPER_REQUEST,
    deleteTeacherExamPaper
  );
}

function* removeTeacherAssignment({ data, teacherAssignmentId }) {
  try {
    const response = yield axios({
      url: `/academics/teacher-assignment/${teacherAssignmentId}`,
      method: "PUT",
      data,
    });

    yield put({
      type: teacherAssignmentActions.REMOVE_TEACHER_ASSIGNMENT_SUCCESS,
      data: response,
    });
    yield put(teacherAssignmentActions.getTeacherAssignments());
    yield put(marksUploadActions.getMetaData());
  } catch (error) {
    yield put({
      type: teacherAssignmentActions.REMOVE_TEACHER_ASSIGNMENT_ERROR,
      error: error.data,
    });
  }
}

function* watchRemoveTeacherAssignment() {
  yield takeEvery(
    teacherAssignmentActions.REMOVE_TEACHER_ASSIGNMENT_REQUEST,
    removeTeacherAssignment
  );
}

export default [
  fork(watchGetTeacherAssignment),
  fork(watchGetTeacherAssignmentDetails),
  fork(watchGetMetaData),
  fork(watchAddTeacherAssignment),
  fork(watchDeleteTeacherAssignment),
  fork(watchDeleteTeacherExamPaper),
  fork(watchUpdateTeacherAssignment),
  fork(watchRemoveTeacherAssignment),
];
