const classes = {
  GET_CLASSES_REQUEST: "GET_CLASSES_REQUEST",
  GET_CLASSES_SUCCESS: "GET_CLASSES_SUCCESS",
  GET_CLASSES_ERROR: "GET_CLASSES_ERROR",

  ADD_CLASS_REQUEST: "ADD_CLASS_REQUEST",
  ADD_CLASS_SUCCESS: "ADD_CLASS_SUCCESS",
  ADD_CLASS_ERROR: "ADD_CLASS_ERROR",

  GET_CLASS_DETAILS_REQUEST: "GET_CLASS_DETAILS_REQUEST",
  GET_CLASS_DETAILS_SUCCESS: "GET_CLASS_DETAILS_SUCCESS",
  GET_CLASS_DETAILS_ERROR: "GET_CLASS_DETAILS_ERROR",

  GET_CLASSES_META_DATA_REQUEST: "GET_CLASSES_META_DATA_REQUEST",
  GET_CLASSES_META_DATA_SUCCESS: "GET_CLASSES_META_DATA_SUCCESS",
  GET_CLASSES_META_DATA_ERROR: "GET_CLASSES_META_DATA_ERROR",

  DELETE_CLASS_REQUEST: "DELETE_CLASS_REQUEST",
  DELETE_CLASS_SUCCESS: "DELETE_CLASS_SUCCESS",
  DELETE_CLASS_ERROR: "DELETE_CLASS_ERROR",

  UPDATE_CLASS_DETAILS_REQUEST: "UPDATE_CLASS_DETAILS_REQUEST",
  UPDATE_CLASS_DETAILS_SUCCESS: "UPDATE_CLASS_DETAILS_SUCCESS",
  UPDATE_CLASS_DETAILS_ERROR: "UPDATE_CLASS_DETAILS_ERROR",

  SET_UPDATE_CLASS_DETAILS_DATA: "SET_UPDATE_CLASS_DETAILS_DATA",

  CHECK_CLASS_REQUEST: "CHECK_CLASS_REQUEST",
  CHECK_CLASS_SUCCESS: "CHECK_CLASS_SUCCESS",
  CHECK_CLASS_ERROR: "CHECH_CLASS_ERROR",

  APPROVE_CLASS_REQUEST: "APPROVE_CLASS_REQUEST",
  APPROVE_CLASS_SUCCESS: "APPROVE_CLASS_SUCCES",
  APPROVE_CLASS_ERROR: "APPROVE_CLASS_ERROR",

  ADD_STREAMS_REQUEST: "ADD_STREAMS_REQUEST",
  ADD_STREAMS_SUCCESS: "ADD_STREAMS_SUCCESS",
  ADD_STREAMS_ERROR: "ADD_STREAMS_ERROR",

  UPDATE_STREAMS_REQUEST: "UPDATE_STREAMS_REQUEST",
  UPDATE_STREAMS_SUCCESS: "UPDATE_STREAMS_SUCCESS",
  UPDATE_STREAMS_ERROR: "UPDATE_STREAMS_ERROR",
  UPDATE_STREAMS_DATA: "UPDATE_STREAMS_DATA",

  GET_STREAMS_DETAILS_REQUEST: "GET_STREAMS_DETAILS_REQUEST",
  GET_STREAMS_DETAILS_SUCCESS: "GET_STREAMS_DETAILS_SUCCESS",
  GET_STREAMS_DETAILS_ERROR: "GET_STREAMS_DETAILS_ERROR",

  DELETE_STREAMS_REQUEST: "DELETE_STREAMS_REQUEST",
  DELETE_STREAMS_SUCCESS: "DELETE_STREAMS_REQUEST",
  DELETE_STREAMS_ERROR: "DELETE_STREAMS_ERROR",

  addStreams: (data) => ({
    data,
    type: classes.ADD_STREAMS_REQUEST,
  }),
  updateStreams: (data, id) => ({
    type: classes.UPDATE_STREAMS_REQUEST,
    data,
    id,
  }),
  updateStreamsData: (data) => ({
    type: classes.UPDATE_STREAMS_DATA,
    data,
  }),
  deleteStream: (id) => ({
    type: classes.DELETE_STREAMS_REQUEST,
    id,
  }),
  getStreamDetails: (id) => ({
    id,
    type: classes.GET_STREAMS_DETAILS_REQUEST,
  }),
  getClasses: () => ({
    type: classes.GET_CLASSES_REQUEST,
  }),

  addClass: (data) => ({
    type: classes.ADD_CLASS_REQUEST,
    data,
  }),

  getClassDetails: (id) => ({
    type: classes.GET_CLASS_DETAILS_REQUEST,
    id,
  }),

  getClassesMetaData: () => ({
    type: classes.GET_CLASSES_META_DATA_REQUEST,
  }),

  deleteClass: (id, classesArray) => ({
    type: classes.DELETE_CLASS_REQUEST,
    id,
    classes: classesArray,
  }),

  updateClass: (id, data) => ({
    type: classes.UPDATE_CLASS_DETAILS_REQUEST,
    id,
    data,
  }),

  checkClass: (id) => ({
    type: classes.CHECK_CLASS_REQUEST,
    id,
  }),

  approveClass: (id) => ({
    type: classes.APPROVE_CLASS_REQUEST,
    id,
  }),

  setUpdateClassDetailsData: (data) => ({
    type: classes.SET_UPDATE_CLASS_DETAILS_DATA,
    data,
  }),
};

export default classes;
