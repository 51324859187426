const uced = {
  GET_DIOCESES_REQUEST: "GET_DIOCESES_REQUEST",
  GET_DIOCESES_SUCCESS: "GET_DIOCESES_SUCCESS",
  GET_DIOCESES_ERROR: "GET_DIOCESES_ERROR",

  GET_RELIGIONS_REQUEST: "GET_RELIGIONS_REQUEST",
  GET_RELIGIONS_SUCCESS: "GET_RELIGIONS_SUCCESS",
  GET_RELIGIONS_ERROR: "GET_RELIGIONS_ERROR",

  getDioceses: () => ({
    type: uced.GET_DIOCESES_REQUEST,
  }),
  getReligions: () => ({
    type: uced.GET_RELIGIONS_REQUEST,
  }),
};

export default uced;
