import { learnerIndisciplineActions } from "../../../actions";
import initialState from "../../../initialState";
import { map } from "lodash";

const learnerIndiscipline = (
  state = initialState.learnerIndiscipline,
  actions
) => {
  switch (actions.type) {
    case learnerIndisciplineActions.GET_INDISCIPLINE_CASES_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
        metaDataError: {},
      };
    case learnerIndisciplineActions.GET_INDISCIPLINE_CASES_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: actions.data,
        metaDataLoading: false,
      };
    case learnerIndisciplineActions.GET_INDISCIPLINE_CASES_META_DATA_ERROR:
      return {
        ...state,
        metaDataLoading: false,
        metaDataError: actions.error,
      };
    case learnerIndisciplineActions.GET_INDISCIPLINE_CASES_REQUEST:
      return {
        ...state,
        indisciplineCasesLoading: true,
        indisciplineCasesError: {},
        indisciplineCasesSuccess: {},
      };
    case learnerIndisciplineActions.GET_INDISCIPLINE_CASES_SUCCESS:
      return {
        ...state,
        indisciplineCasesSuccess: actions.data,
        indisciplineCasesLoading: false,
      };
    case learnerIndisciplineActions.GET_INDISCIPLINE_CASES_ERROR:
      return {
        ...state,
        indisciplineCasesLoading: false,
        indisciplineCasesError: actions.error,
      };
    case learnerIndisciplineActions.GET_INDISCIPLINE_CASE_DETAILS_REQUEST:
      return {
        ...state,
        indisciplineCaseDetailsLoading: true,
        indisciplineCaseDetailsError: {},
      };
    case learnerIndisciplineActions.GET_INDISCIPLINE_CASE_DETAILS_SUCCESS:
      return {
        ...state,
        indisciplineCaseDetailsSuccess: actions.data,
        indisciplineCaseDetailsLoading: false,
      };
    case learnerIndisciplineActions.GET_INDISCIPLINE_CASE_DETAILS_ERROR:
      return {
        ...state,
        indisciplineCaseDetailsLoading: false,
        indisciplineCaseDetailsError: actions.error,
      };
    case learnerIndisciplineActions.ADD_INDISCIPLINE_CASE_REQUEST:
      return {
        ...state,
        addIndisciplineCaseLoading: true,
        addIndisciplineCaseError: {},
      };
    case learnerIndisciplineActions.ADD_INDISCIPLINE_CASE_SUCCESS:
      return {
        ...state,
        addIndisciplineCaseSuccess: actions.data,
        addIndisciplineCaseLoading: false,
        // add the new data to the list
        indisciplineCasesSuccess: {
          ...state.indisciplineCasesSuccess,
          indiciplineCases: [
            actions.data,
            ...(state.indisciplineCasesSuccess?.indiciplineCases || []),
          ],
        },
      };
    case learnerIndisciplineActions.ADD_INDISCIPLINE_CASE_ERROR:
      return {
        ...state,
        addIndisciplineCaseLoading: false,
        addIndisciplineCaseError: actions.error,
      };
    case learnerIndisciplineActions.UPDATE_INDISCIPLINE_CASE_REQUEST:
      return {
        ...state,
        updateIndisciplineCaseLoading: true,
        updateIndisciplineCaseError: {},
      };
    case learnerIndisciplineActions.UPDATE_INDISCIPLINE_CASE_SUCCESS:
      return {
        ...state,
        updateIndisciplineCaseSuccess: actions.data,
        updateIndisciplineCaseLoading: false,
        // find the id and update the list with the new data from the server
        indisciplineCasesSuccess: {
          ...state.indisciplineCasesSuccess,
          indiciplineCases: map(
            state.indisciplineCasesSuccess?.indiciplineCases,
            (item) => {
              if (item.id === actions.data.id) {
                return {
                  ...item,
                  ...actions.data.data,
                };
              }
              return item;
            }
          ),
        },
      };
    case learnerIndisciplineActions.UPDATE_INDISCIPLINE_CASE_ERROR:
      return {
        ...state,
        updateIndisciplineCaseLoading: false,
        updateIndisciplineCaseError: actions.error,
      };
    case learnerIndisciplineActions.DELETE_INDISCIPLINE_CASE_REQUEST:
      return {
        ...state,
        deleteIndisciplineCaseLoading: true,
        deleteIndisciplineCaseError: {},
      };
    case learnerIndisciplineActions.DELETE_INDISCIPLINE_CASE_SUCCESS:
      return {
        ...state,
        deleteIndisciplineCaseSuccess: actions.data,
        deleteIndisciplineCaseLoading: false,
        // find the id and remove it from the list
        indisciplineCasesSuccess: {
          ...state.indisciplineCasesSuccess,
          indiciplineCases:
            state.indisciplineCasesSuccess?.indiciplineCases?.filter(
              (item) => item.id !== actions.data.id
            ),
        },
      };
    case learnerIndisciplineActions.DELETE_INDISCIPLINE_CASE_ERROR:
      return {
        ...state,
        deleteIndisciplineCaseLoading: false,
        deleteIndisciplineCaseError: actions.error,
      };
    case learnerIndisciplineActions.SET_INDISCIPLINE_CASE_DATA:
      return {
        ...state,
        setIndisciplineCaseData: actions.data,
      };
    default:
      return state;
  }
};

export default learnerIndiscipline;
