const optionalSubjects = {
  GET_OPTIONAL_SUBJECTS_REQUEST: "GET_OPTIONAL_SUBJECTS_REQUEST",
  GET_OPTIONAL_SUBJECTS_SUCCESS: "GET_OPTIONAL_SUBJECTS_SUCCESS",
  GET_OPTIONAL_SUBJECTS_ERROR: "GET_OPTIONAL_SUBJECTS_ERROR",

  GET_OPTIONAL_SUBJECT_DETAILS_REQUEST: "GET_OPTIONAL_SUBJECT_DETAILS_REQUEST",
  GET_OPTIONAL_SUBJECT_DETAILS_SUCCESS: "GET_OPTIONAL_SUBJECT_DETAILS_SUCCESS",
  GET_OPTIONAL_SUBJECT_DETAILS_ERROR: "GET_OPTIONAL_SUBJECT_DETAILS_ERROR",

  ADD_OPTIONAL_SUBJECT_REQUEST: "ADD_OPTIONAL_SUBJECT_REQUEST",
  ADD_OPTIONAL_SUBJECT_SUCCESS: "ADD_OPTIONAL_SUBJECT_SUCCESS",
  ADD_OPTIONAL_SUBJECT_ERROR: "ADD_OPTIONAL_SUBJECT_ERROR",

  ADD_OPTIONAL_SUBJECT_STUDENTS_REQUEST:
    "ADD_OPTIONAL_SUBJECT_STUDENTS_REQUEST",
  ADD_OPTIONAL_SUBJECT_STUDENTS_SUCCESS:
    "ADD_OPTIONAL_SUBJECT_STUDENTS_SUCCESS",
  ADD_OPTIONAL_SUBJECT_STUDENTS_ERROR: "ADD_OPTIONAL_SUBJECT_STUDENTS_ERROR",

  GET_OPTIONAL_SUBJECT_META_DATA_REQUEST:
    "GET_OPTIONAL_SUBJECT_META_DATA_REQUEST",
  GET_OPTIONAL_SUBJECT_META_DATA_SUCCESS:
    "GET_OPTIONAL_SUBJECT_META_DATA_SUCCESS",
  GET_OPTIONAL_SUBJECT_META_DATA_ERROR: "GET_OPTIONAL_SUBJECT_META_DATA_ERROR",

  DELETE_OPTIONAL_SUBJECT_REQUEST: "DELETE_OPTIONAL_SUBJECT_REQUEST",
  DELETE_OPTIONAL_SUBJECT_SUCCESS: "DELETE_OPTIONAL_SUBJECT_SUCCESS",
  DELETE_OPTIONAL_SUBJECT_ERROR: "DELETE_OPTIONAL_SUBJECT_ERROR",

  DELETE_OPTIONAL_SUBJECT_STUDENT_REQUEST:
    "DELETE_OPTIONAL_SUBJECT_STUDENT_REQUEST",
  DELETE_OPTIONAL_SUBJECT_STUDENT_SUCCESS:
    "DELETE_OPTIONAL_SUBJECT_STUDENT_SUCCESS",
  DELETE_OPTIONAL_SUBJECT_STUDENT_ERROR:
    "DELETE_OPTIONAL_SUBJECT_STUDENT_ERROR",

  DOWNLOAD_OPTIONAL_SUBJECT_STUDENTS_TEMPLATE_REQUEST:
    "DOWNLOAD_OPTIONAL_SUBJECT_STUDENTS_TEMPLATE_REQUEST",
  DOWNLOAD_OPTIONAL_SUBJECT_STUDENTS_TEMPLATE_SUCCESS:
    "DOWNLOAD_OPTIONAL_SUBJECT_STUDENTS_TEMPLATE_SUCCESS",
  DOWNLOAD_OPTIONAL_SUBJECT_STUDENTS_TEMPLATE_ERROR:
    "DOWNLOAD_OPTIONAL_SUBJECT_STUDENTS_TEMPLATE_ERROR",

  UPLOAD_OPTIONAL_SUBJECT_STUDENTS_REQUEST:
    "UPLOAD_OPTIONAL_SUBJECT_STUDENTS_REQUEST",
  UPLOAD_OPTIONAL_SUBJECT_STUDENTS_SUCCESS:
    "UPLOAD_OPTIONAL_SUBJECT_STUDENTS_SUCCESS",
  UPLOAD_OPTIONAL_SUBJECT_STUDENTS_ERROR:
    "UPLOAD_OPTIONAL_SUBJECT_STUDENTS_ERROR",

  getOptionalSubjects: () => ({
    type: optionalSubjects.GET_OPTIONAL_SUBJECTS_REQUEST,
  }),

  addOptionalSubject: (data) => ({
    type: optionalSubjects.ADD_OPTIONAL_SUBJECT_REQUEST,
    data,
  }),
  addOptionalSubjectStudents: (data) => ({
    type: optionalSubjects.ADD_OPTIONAL_SUBJECT_STUDENTS_REQUEST,
    data,
  }),
  getOptionalSubjectDetails: (id) => ({
    type: optionalSubjects.GET_OPTIONAL_SUBJECT_DETAILS_REQUEST,
    id,
  }),
  getOptionalSubjectsMetaData: () => ({
    type: optionalSubjects.GET_OPTIONAL_SUBJECT_META_DATA_REQUEST,
  }),
  deleteOptionalSubject: (id, enrollmentsArray) => ({
    type: optionalSubjects.DELETE_OPTIONAL_SUBJECT_REQUEST,
    id,
    optionalSubjects: enrollmentsArray,
  }),
  deleteOptionalSubjectStudent: (id) => ({
    type: optionalSubjects.DELETE_OPTIONAL_SUBJECT_STUDENT_REQUEST,
    id,
  }),
  downloadOptionalSubjectStudentsTemplate: (data) => ({
    type: optionalSubjects.DOWNLOAD_OPTIONAL_SUBJECT_STUDENTS_TEMPLATE_REQUEST,
    data,
  }),
  uploadOptionalSubjectStudents: (data) => ({
    type: optionalSubjects.UPLOAD_OPTIONAL_SUBJECT_STUDENTS_REQUEST,
    data,
  }),
};

export default optionalSubjects;
