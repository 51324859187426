const academicYearsInitialState = {
  academicYears: {
    academicYearsLoading: false,
    academicYearsSuccess: {},
    academicYearsError: {},

    academicYearDetailsLoading: false,
    academicYearDetailsError: {},
    academicYearDetailsSuccess: {},

    addAcademicYearsSuccess: {},
    addAcademicYearsLoading: false,
    addAcademicYearsError: {},

    updateAcademicYearLoading: false,
    updateAcademicYearSuccess: {},
    updateAcademicYearError: {},
    updateAcademicYearData: {},

    deleteAcademicYearLoading: false,
    deleteAcademicYearSuccess: {},
    deleteAcademicYearError: {},

    checkAcademicYearLoading: false,
    checkAcademicYearSuccess: {},
    checkAcademicYearError: {},

    approveAcademicYearLoading: false,
    approveAcademicYearError: {},
    approveAcademicYearSuccess: {},

    termDetailsLoading: false,
    termDetailsError: {},
    termDetailsSuccess: {},

    deleteTermLoading: false,
    deleteTermSuccess: {},
    deleteTermError: {},
  },
};

export default academicYearsInitialState;
