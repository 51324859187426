import { Input, Form, Button, Alert, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../config/actions";
import usePrevious from "../../../components/hooks/usePrevious";
import { isEmpty } from "lodash";
import AntdModal from "../../../components/common/AntdModal";
import { appUiActions } from "../../../config/actions";

const ForgotPassword = () => {
  // state management and effects declarations

  const { forgotPassword, forgotPasswordError, forgotPasswordSuccess } =
    useSelector((state) => state.auth);

  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const prevState = usePrevious({
    forgotPasswordSuccess,
    forgotPasswordError,
  });

  const { forgotPasswordModal } = useSelector((state) => state.appUi);
  const closeforgotPasswordModal = () => {
    dispatch(appUiActions.toggleForgotPasswordModal(false));
  };

  // all functions
  const onFinishforgot = (values) => {
    dispatch(authActions.forgotUserPassword(values));
  };

  // use effects
  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      forgotPasswordError &&
      prevState.forgotPasswordError !== forgotPasswordError
    ) {
      setError(
        forgotPasswordError?.message || forgotPasswordError?.server?.message
      );
    }
  }, [forgotPasswordError]);

  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      !isEmpty(forgotPasswordSuccess) &&
      prevState.forgotPasswordSuccess !== forgotPasswordSuccess
    ) {
      if (forgotPasswordSuccess.server?.status === true) {
        dispatch(appUiActions.toggleForgotPasswordModal(false));
        Modal.success({
          title: "PASSWORD RESET",
          content: "Please check your email for a reset password link.",
        });
      }
    }
  }, [forgotPasswordSuccess]);

  return (
    <div>
      <AntdModal
        open={forgotPasswordModal}
        closeModal={closeforgotPasswordModal}
        title="FORGOT YOUR PASSWORD"
      >
        <Form
          onFinish={onFinishforgot}
          name="forgotpassword page"
          layout="vertical"
        >
          {error && <Alert type="error" message={error} showIcon />}
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              {
                type: "email",
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input prefix={<AiOutlineMail className="text-secondary" />} />
          </Form.Item>
          <Form.Item className="mt-4">
            <Button loading={forgotPassword} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </AntdModal>
    </div>
  );
};

export default ForgotPassword;
