import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { dashboardActions } from "../../actions";

function* getMainDashboard({ data }) {
  try {
    const response = yield axios({
      url: "/dashboard/",
      params: data,
    });

    yield put({
      type: dashboardActions.GET_MAIN_DASHBOARD_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: dashboardActions.GET_MAIN_DASHBOARD_ERROR,
      error: error.data,
    });
  }
}

function* getLearnerAttendanceSummary({ data }) {
  try {
    const response = yield axios({
      url: "/dashboard/learner-attendance-summary",
      params: data,
    });

    yield put({
      type: dashboardActions.GET_LEARNER_ATTENDANCE_SUMMARY_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: dashboardActions.GET_LEARNER_ATTENDANCE_SUMMARY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetLearnerAttendanceSummary() {
  yield takeLatest(
    dashboardActions.GET_LEARNER_ATTENDANCE_SUMMARY_REQUEST,
    getLearnerAttendanceSummary
  );
}

function* getAttendanceSummary({ data }) {
  try {
    const response = axios({
      url: "/dashboard/attendance-summary",
      params: data,
    });

    yield put({
      type: dashboardActions.GET_ATTENDANCE_SUMMARY_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: dashboardActions.GET_ATTENDANCE_SUMMARY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAttendanceSummary() {
  yield takeLatest(
    dashboardActions.GET_ATTENDANCE_SUMMARY_REQUEST,
    getAttendanceSummary
  );
}

function* getBudgetExpensesSummary({ data }) {
  try {
    const response = yield axios({
      url: "/dashboard/budget-summary",
      params: data,
    });

    yield put({
      type: dashboardActions.GET_BUDGET_EXPENSES_SUMMARY_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: dashboardActions.GET_BUDGET_EXPENSES_SUMMARY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetBudgetExpensesSummary() {
  yield takeLatest(
    dashboardActions.GET_BUDGET_EXPENSES_SUMMARY_REQUEST,
    getBudgetExpensesSummary
  );
}

function* getSchoolFinancialSummary({ data }) {
  try {
    const response = yield axios({
      url: "/dashboard/financial-position",
      params: data,
    });

    yield put({
      type: dashboardActions.GET_SCHOOL_FINANCIAL_SUMMARY_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: dashboardActions.GET_SCHOOL_FINANCIAL_SUMMARY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSchoolFinancialSummary() {
  yield takeLatest(
    dashboardActions.GET_SCHOOL_FINANCIAL_SUMMARY_REQUEST,
    getSchoolFinancialSummary
  );
}

function* getProfitAndLossSummary({ data }) {
  try {
    const response = yield axios({
      url: "/dashboard/profit-and-loss",
      params: data,
    });

    yield put({
      type: dashboardActions.GET_PROFIT_AND_LOSS_SUMMARY_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: dashboardActions.GET_PROFIT_AND_LOSS_SUMMARY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetProfitAndLossSummary() {
  yield takeLatest(
    dashboardActions.GET_PROFIT_AND_LOSS_SUMMARY_REQUEST,
    getProfitAndLossSummary
  );
}

function* watchGetMainDashboard() {
  yield takeLatest(
    dashboardActions.GET_MAIN_DASHBOARD_REQUEST,
    getMainDashboard
  );
}

function* getBudgetSummary({ data }) {
  try {
    const response = yield axios({
      url: "/dashboard/budget-summary",
      params: data,
    });

    yield put({
      type: dashboardActions.GET_BUDGET_SUMMARY_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: dashboardActions.GET_BUDGET_SUMMARY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetBudgetSummary() {
  yield takeLatest(
    dashboardActions.GET_BUDGET_SUMMARY_REQUEST,
    getBudgetSummary
  );
}

function* getFeesSummary({ data }) {
  try {
    const response = yield axios({
      url: "/dashboard/fees-collections-reports",
      params: data,
    });

    yield put({
      type: dashboardActions.GET_FEES_SUMMARY_COLLECTIONS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: dashboardActions.GET_FEES_SUMMARY_COLLECTIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetFeesSummary() {
  yield takeLatest(
    dashboardActions.GET_FEES_SUMMARY_COLLECTIONS_REQUEST,
    getFeesSummary
  );
}

function* getMetaData({ data }) {
  try {
    const response = yield axios({
      url: "/dashboard/meta-data",
      params: data,
    });

    yield put({
      type: dashboardActions.GET_DASHBOARD_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: dashboardActions.GET_DASHBOARD_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(
    dashboardActions.GET_DASHBOARD_META_DATA_REQUEST,
    getMetaData
  );
}

function* getIncomeSummary({ data }) {
  try {
    const response = yield axios({
      url: "/dashboard/income-summary",
      params: data,
    });

    yield put({
      type: dashboardActions.GET_INCOME_SUMMARY_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: dashboardActions.GET_INCOME_SUMMARY_ERROR,
      error: error.data,
    });
  }
}

function* watchGetIncomeSummary() {
  yield takeLatest(
    dashboardActions.GET_INCOME_SUMMARY_REQUEST,
    getIncomeSummary
  );
}

export default [
  fork(watchGetMainDashboard),
  fork(watchGetFeesSummary),
  fork(watchGetMetaData),
  fork(watchGetBudgetSummary),
  fork(watchGetIncomeSummary),
  fork(watchGetSchoolFinancialSummary),
  fork(watchGetProfitAndLossSummary),
  fork(watchGetBudgetExpensesSummary),
  fork(watchGetAttendanceSummary),
  fork(watchGetLearnerAttendanceSummary),
];
