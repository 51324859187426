import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { appUiActions, organisationDetailsActions } from "../../../actions";

function* getOrganisationDetails() {
  try {
    const response = yield axios({
      url: "/administration/organisation-details",
    });

    yield put({
      type: organisationDetailsActions.GET_ORGANISATION_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: organisationDetailsActions.GET_ORGANISATION_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetOrganisationDetails() {
  yield takeLatest(
    organisationDetailsActions.GET_ORGANISATION_DETAILS_REQUEST,
    getOrganisationDetails
  );
}

function* addOrganisationDetails({ data }) {
  try {
    const response = yield axios({
      url: "/administration/organisation-details",
      method: "POST",
      data,
    });

    yield put({
      type: organisationDetailsActions.ADD_ORGANISATION_DETAILS_SUCCESS,
      data: response,
    });
    yield put({
      type: organisationDetailsActions.GET_ORGANISATION_DETAILS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: organisationDetailsActions.ADD_ORGANISATION_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchAddOrganisationDetails() {
  yield takeLatest(
    organisationDetailsActions.ADD_ORGANISATION_DETAILS_REQUEST,
    addOrganisationDetails
  );
}

function* updateOrganisationDetails({ data, id }) {
  try {
    yield axios({
      url: `/administration/organisation-details/${id}`,
      data,
      method: "PUT",
    });

    yield put({
      type: organisationDetailsActions.UPDATE_ORGANISATION_DETAILS_SUCCESS,
      data,
    });

    yield put({
      type: organisationDetailsActions.GET_ORGANISATION_DETAILS_REQUEST,
    });

    window.location.reload();
  } catch (error) {
    yield put({
      type: organisationDetailsActions.UPDATE_ORGANISATION_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateOrganisationDetails() {
  yield takeLatest(
    organisationDetailsActions.UPDATE_ORGANISATION_DETAILS_REQUEST,
    updateOrganisationDetails
  );
}

function* uploadOrganisationLogo({ data, id }) {
  try {
    const response = yield axios({
      url: `/administration/organisation-details/${id}`,
      method: "PATCH",
      data,
    });

    yield put({
      type: organisationDetailsActions.UPLOAD_ORGANISATION_LOGO_SUCCESS,
      data: response,
    });

    yield put({
      type: appUiActions.TOGGLE_ORGANISATION_LOGO_MODAL,
      data: false,
    });
  } catch (error) {
    yield put({
      type: organisationDetailsActions.UPLOAD_ORGANISATION_LOGO_ERROR,
      error: error.data,
    });
  }
}

function* watchUploadOrganisationLogo() {
  yield takeLatest(
    organisationDetailsActions.UPLOAD_ORGANISATION_LOGO_REQUEST,
    uploadOrganisationLogo
  );
}

export default [
  fork(watchGetOrganisationDetails),
  fork(watchAddOrganisationDetails),
  fork(watchUpdateOrganisationDetails),
  fork(watchUploadOrganisationLogo),
];
