const paymentVouchersInitialState = {
  paymentVouchersLoading: false,
  paymentVouchersSuccess: {},
  paymentVouchersError: {},

  getPaymentVoucherDetailsLoading: false,
  getPaymentVoucherDetailsSuccess: {},
  getPaymentVoucherDetailsError: {},

  deletePaymentVoucherLoading: false,
  deletePaymentVoucherSuccess: {},
  deletePaymentVoucherError: {},

  updatePaymentVoucherDetailsLoading: false,
  updatePaymentVoucherDetailsSuccess: {},
  updatePaymentVoucherDetailsError: {},
  updatePaymentVouchersDetails: {},

  checkPaymentVoucherLoading: false,
  checkPaymentVoucherSuccess: {},
  checkPaymentVoucherError: {},

  approvePaymentVoucherLoading: false,
  approvePaymentVoucherSuccess: {},
  approvePaymentVoucherError: {},

  getPaymentVouchersMetaDataLoading: false,
  getPaymentVouchersMetaDataSuccess: {},
  getPaymentVouchersMetaDataError: {},

  addPaymentVoucherLoading: false,
  addPaymentVoucherSuccess: {},
  addPaymentVoucherError: {},

  searchPaymentVouchersLoading: false,
  searchPaymentVouchersSuccess: {},
  searchPaymentVouchersError: {},

  sortPaymentVouchersLoading: false,
  sortPaymentVouchersSuccess: {},
  sortPaymentVouchersError: {},

  downloadPaymentVouchersLoading: false,
  downloadPaymentVouchersSuccess: {},
  downloadPaymentVouchersError: {},

  sortQuery: {},
};

export default paymentVouchersInitialState;
