const staffAttendanceInitialState = {
  staffAttendanceSuccess: {},
  staffAttendanceError: {},
  staffAttendanceLoading: false,

  staffAttendanceDetailsSuccess: {},
  staffAttendanceDetailsError: {},
  staffAttendanceDetailsLoading: false,

  metaData: {},
  metaDataError: {},
  metaDataLoading: false,

  addStaffAttendanceSuccess: {},
  addStaffAttendanceError: {},
  addStaffAttendanceLoading: false,

  updateStaffAttendanceSuccess: {},
  updateStaffAttendanceError: {},
  updateStaffAttendanceLoading: false,

  deleteStaffAttendanceSuccess: {},
  deleteStaffAttendanceError: {},
  deleteStaffAttendanceLoading: false,

  checkStaffAttendanceSuccess: {},
  checkStaffAttendanceError: {},
  checkStaffAttendanceLoading: false,

  approveStaffAttendanceSuccess: {},
  approveStaffAttendanceError: {},
  approveStaffAttendanceLoading: false,

  setRollCallData: [],
  setUpdateRollCallData: {},
};

export default staffAttendanceInitialState;
