const classesInitialState = {
  classesLoading: false,
  classesSuccess: {},
  classesError: {},

  classesMetaDataLoading: false,
  classesMetaDataSuccess: {},
  classesMetaDataError: {},

  addClassLoading: false,
  addClassSuccess: {},
  addClassError: {},

  getClassDetailsLoading: false,
  getClassDetailsSuccess: {},
  getClassDetailsError: {},

  updateClassDetailsLoading: false,
  updateClassDetailsSuccess: {},
  updateClassDetailsError: {},
  updateClassDetailsData: {},

  deleteClassLoading: false,
  deleteClassSuccess: {},
  deleteClassError: {},

  checkClassLoading: false,
  checkClassSuccess: {},
  checkClassError: {},

  approveClassLoading: false,
  approveClassSuccess: {},
  approveClassError: {},

  addStreamsLoading: false,
  addStreamsSuccess: {},
  addStreamsError: {},

  updateStreamsLoading: false,
  updateStreamsData: {},
  updateStreamsSuccess: {},
  updateStreamsError: {},

  streamsDetailsLoading: false,
  streamDetailsSuccess: {},
  streamDetailsError: {},

  deleteStreamsSuccess: {},
  deleteStreamsLoading: false,
  deleteStreamsError: {},
};

export default classesInitialState;
