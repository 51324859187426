const permissionsInitialState = {
  permissions: {
    rolePermissionsLoading: false,
    rolePermissionsSuccess: {},
    rolePermissionsError: {},

    permissionsMetaDataLoading: false,
    permissionsMetaDataSuccess: {},

    addRolePermissionsLoading: false,
    addRolePermissionsSuccess: {},
    addRolePermissionsError: {},

    deletePermissionsLoading: false,
    deletePermissionsSuccess: {},
    deletePermissionsError: {},
  },
};

module.exports = permissionsInitialState;
