const schoolGatePassInitialState = {
  schoolGatePass: {
    schoolGatePassSuccess: {},
    schoolGatePassError: {},
    schoolGatePassLoading: false,

    schoolGatePassDetailsSuccess: {},
    schoolGatePassDetailsError: {},
    schoolGatePassDetailsLoading: false,

    addSchoolGatePassSuccess: {},
    addSchoolGatePassError: {},
    addSchoolGatePassLoading: false,

    updateSchoolGatePassSuccess: {},
    updateSchoolGatePassError: {},
    updateSchoolGatePassLoading: false,
    setUpdateSchoolGatePassData: {},

    deleteSchoolGatePassSuccess: {},
    deleteSchoolGatePassError: {},
    deleteSchoolGatePassLoading: false,

    metaData: {},
    metaDataError: {},
    metaDataLoading: false,

    checkSchoolGatePassSuccess: {},
    checkSchoolGatePassError: {},
    checkSchoolGatePassLoading: false,

    approveSchoolGatePassSuccess: {},
    approveSchoolGatePassError: {},
    approveSchoolGatePassLoading: false,
  },
};

export default schoolGatePassInitialState;
