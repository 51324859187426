const assetTypesInitialState = {
    assetTypes: {
        getAssetTypesLoading: false,
        getAssetTypesSuccess: {},
        getAssetTypesError: {},

        addAssetTypeData: {},
        addAssetTypeLoading: false,
        addAssetTypeSuccess: {},
        addAssetTypeError: {},

        deleteAssetTypeLoading: false,
        deleteAssetTypeSuccess: {},
        deleteAssetTypeError: {},

        updateAssetTypeData: {},
        updateAssetTypeLoading: false,
        updateAssetTypeSuccess: {},
        updateAssetTypeError: {},

        assetTypeDetailsLoading: false,
        assetTypeDetailsSuccess: {},
        assetTypeDetailsError: {},

        checkAssetTypeLoading: false,
        checkAssetTypeError: {},
        checkAssetTypeSuccess: {},

        approveAssetTypeLoading: false,
        approveAssetTypeSuccess: {},
        approveAssetTypeError: {},
    }
};

export default assetTypesInitialState;
