import { takeLatest, put, fork, takeEvery } from "@redux-saga/core/effects";
import axios from "axios";
import { permissionsActions } from "../../../actions";

function* getRolePermissions({ data }) {
  try {
    const response = yield axios({ url: `/settings/permissions/${data}` });

    yield put({
      type: permissionsActions.GET_ROLE_PERMISSIONS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: permissionsActions.GET_ROLE_PERMISSIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetRolePermissions() {
  yield takeLatest(
    permissionsActions.GET_ROLE_PERMISSIONS_REQUEST,
    getRolePermissions
  );
}

function* getPermissionsMetaData() {
  try {
    const response = yield axios({ url: "/settings/permissions/meta-data" });
    yield put({
      type: permissionsActions.GET_PERMISSIONS_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: permissionsActions.GET_PERMISSIONS_META_DATA_SUCCESS,
      data: null,
    });
  }
}

function* watchGetPermissionsMetaData() {
  yield takeLatest(
    permissionsActions.GET_PERMISSIONS_META_DATA_LOADING,
    getPermissionsMetaData
  );
}

function* addRolePermissions({ data }) {
  try {
    const response = yield axios({
      url: "/settings/permissions",
      method: "POST",
      data,
    });

    yield put({
      type: permissionsActions.ADD_ROLE_PERMISSIONS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: permissionsActions.ADD_ROLE_PERMISSIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchAddRolePermission() {
  yield takeLatest(
    permissionsActions.ADD_ROLE_PERMISSIONS_REQUEST,
    addRolePermissions
  );
}

function* deletePermissions({ data }) {
  try {
    const response = yield axios({
      url: "/settings/permissions",
      method: "DELETE",
      data,
    });

    yield put({
      type: permissionsActions.DELETE_PERMISSIONS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: permissionsActions.DELETE_PERMISSIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchDeletePermissions() {
  yield takeEvery(
    permissionsActions.DELETE_PERMISSIONS_REQUEST,
    deletePermissions
  );
}

export default [
  fork(watchGetRolePermissions),
  fork(watchGetPermissionsMetaData),
  fork(watchAddRolePermission),
  fork(watchDeletePermissions),
];
