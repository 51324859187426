import { takeLatest, put, fork, takeEvery } from "@redux-saga/core/effects";
import axios from "axios";
import {
  appUiActions,
  banksActions,
  incomeReceiptActions,
  paymentStructureActions,
  requisitionMemoActions,
} from "../../../actions";

function* getBanks() {
  try {
    const response = yield axios({ url: "/finance/banks" });

    yield put({ type: banksActions.GET_BANKS_SUCCESS, data: response });
  } catch (error) {
    yield put({
      type: banksActions.GET_BANKS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetBanks() {
  yield takeLatest(banksActions.GET_BANKS_REQUEST, getBanks);
}

function* deleteBankSubAccount({ id, bankId }) {
  try {
    const response = yield axios({
      url: `/finance/banks/sub-accounts/${id}`,
      method: "DELETE",
    });

    yield put({
      type: banksActions.DELETE_SUB_ACCOUNTS_SUCCESS,
      data: response,
    });
    yield put(banksActions.getBankDetails(bankId));
  } catch (error) {
    yield put({
      type: banksActions.DELETE_SUB_ACCOUNTS_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteBankSubAccount() {
  yield takeEvery(
    banksActions.DELETE_SUB_ACCOUNTS_REQUEST,
    deleteBankSubAccount
  );
}

function* getMetaData() {
  try {
    const response = yield axios({
      url: "/finance/banks/meta-data",
    });

    yield put({
      type: banksActions.GET_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: banksActions.GET_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(banksActions.GET_META_DATA_REQUEST, getMetaData);
}

function* addBanks({ data }) {
  try {
    const response = yield axios({
      url: "/finance/banks",
      data,
      method: "POST",
    });
    yield put({ type: banksActions.ADD_BANKS_SUCCESS, data: response });
    yield put(paymentStructureActions.getMetaData());
    yield put(incomeReceiptActions.getMetaData());
    yield put(requisitionMemoActions.getMetaData());
  } catch (error) {
    yield put({ type: banksActions.ADD_BANKS_ERROR, error: error.data });
  }
}

function* watchAddBanks() {
  yield takeLatest(banksActions.ADD_BANKS_REQUEST, addBanks);
}

function* deleteBank({ data, banks }) {
  try {
    const response = yield axios({
      url: `/finance/banks/${data}`,
      method: "DELETE",
    });

    yield put({
      type: banksActions.DELETE_BANK_SUCCESS,
      banks,
      data: response,
    });
  } catch (error) {
    yield put({
      type: banksActions.DELETE_BANK_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteBank() {
  yield takeEvery(banksActions.DELETE_BANK_REQUEST, deleteBank);
}

function* updateBank({ data, banks, id }) {
  try {
    const response = yield axios({
      url: `/finance/banks/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: banksActions.UPDATE_BANKS_SUCCESS,
      data: response,
      banks,
    });

    yield put(appUiActions.toggleUpdateBankModal(false));
  } catch (error) {
    yield put({ type: banksActions.UPDATE_BANKS_ERROR, error: error.data });
  }
}

function* watchUpdateBank() {
  yield takeLatest(banksActions.UPDATE_BANKS_REQUEST, updateBank);
}

function* getBankDetails({ data }) {
  try {
    const response = yield axios({ url: `/finance/banks/${data}` });

    yield put({ type: banksActions.GET_BANK_DETAILS_SUCCESS, data: response });
  } catch (error) {
    yield put({ type: banksActions.GET_BANK_DETAILS_ERROR, error: error.data });
  }
}

function* watchGetBankDetails() {
  yield takeLatest(banksActions.GET_BANK_DETAILS_REQUEST, getBankDetails);
}

function* checkBank({ data }) {
  try {
    const response = yield axios({
      url: `/finance/banks/check/${data}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({ type: banksActions.CHECK_BANK_SUCCESS, data: response });
    yield put(banksActions.getBankDetails(data));
  } catch (error) {
    yield put({ type: banksActions.CHECK_BANK_ERROR, error: error.data });
  }
}

function* watchCheckBank() {
  yield takeLatest(banksActions.CHECK_BANK_REQUEST, checkBank);
}

function* approveBank({ data }) {
  try {
    const response = yield axios({
      url: `/finance/banks/approve/${data}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({ type: banksActions.APPROVE_BANK_SUCCESS, data: response });
    yield put(banksActions.getBankDetails(data));
  } catch (error) {
    yield put({ type: banksActions.APPROVE_BANK_ERROR, error: error.data });
  }
}

function* watchApproveBank() {
  yield takeLatest(banksActions.APPROVE_BANK_REQUEST, approveBank);
}

function* getTermlyBankStatement({ bank_id, year_id }) {
  try {
    const response = yield axios({
      url: `/finance/banks/bank-statement/${bank_id}?academicYearId=${year_id}`,
      method: "GET",
    });

    yield put({
      type: banksActions.TERMLY_BANK_STATEMENT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: banksActions.TERMLY_BANK_STATEMENT_ERROR,
      error: error.data,
    });
  }
}

function* watchGetTermlyBankStatement() {
  yield takeLatest(
    banksActions.TERMLY_BANK_STATEMENT_REQUEST,
    getTermlyBankStatement
  );
}

function* getBankStatement({ bank_id }) {
  try {
    const response = yield axios({
      url: `/finance/banks/bank-statement/${bank_id}`,
    });

    yield put({ type: banksActions.BANK_STATEMENT_SUCCESS, data: response });
  } catch (error) {
    yield put({ type: banksActions.BANK_STATEMENT_ERROR, error: error.data });
  }
}

function* watchGetBankStatement() {
  yield takeLatest(banksActions.BANK_STATEMENT_REQUEST, getBankStatement);
}

function* getYearlyBankStatement({ bank_id, year_id }) {
  try {
    const response = yield axios({
      url: `/finance/banks/bank-statement/${bank_id}?academicYearId=${year_id}`,
    });

    yield put({
      type: banksActions.YEARLY_BANK_STATEMENT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: banksActions.YEARLY_BANK_STATEMENT_ERROR,
      error: error.data,
    });
  }
}

function* watchGetYearlyBankStatement() {
  yield takeLatest(
    banksActions.YEARLY_BANK_STATEMENT_REQUEST,
    getYearlyBankStatement
  );
}

export default [
  fork(watchGetBanks),
  fork(watchAddBanks),
  fork(watchDeleteBank),
  fork(watchUpdateBank),
  fork(watchGetBankDetails),
  fork(watchCheckBank),
  fork(watchApproveBank),
  fork(watchDeleteBankSubAccount),
  fork(watchGetMetaData),
  fork(watchGetTermlyBankStatement),
  fork(watchGetBankStatement),
  fork(watchGetYearlyBankStatement),
];
