import { subjectClassActions } from "../../../actions";
import initialState from "../../../initialState";

const subjectClass = (state = initialState.subjectClass, actions) => {
  switch (actions.type) {
    case subjectClassActions.GET_SUBJECT_CLASS_REQUEST:
      return {
        ...state,
        subjectClassLoading: true,
        subjectClassError: {},
      };
    case subjectClassActions.GET_SUBJECT_CLASS_SUCCESS:
      return {
        ...state,
        subjectClassSuccess: actions.data,
        subjectClassLoading: false,
      };
    case subjectClassActions.GET_SUBJECT_CLASS_ERROR:
      return {
        ...state,
        subjectClassError: actions.error,
        subjectClassLoading: false,
      };

    case subjectClassActions.GET_SUBJECT_CLASS_DETAILS_REQUEST:
      return {
        ...state,
        subjectClassDetailsLoading: true,
        subjectClassDetailsError: {},
      };
    case subjectClassActions.GET_SUBJECT_CLASS_DETAILS_SUCCESS:
      return {
        ...state,
        subjectClassDetailsSuccess: actions.data,
        subjectClassDetailsLoading: false,
      };
    case subjectClassActions.GET_SUBJECT_CLASS_DETAILS_ERROR:
      return {
        ...state,
        subjectClassDetailsError: actions.error,
        subjectClassDetailsLoading: false,
      };
    case subjectClassActions.GET_SUBJECT_CLASS_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case subjectClassActions.GET_SUBJECT_CLASS_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataLoading: false,
        metaData: actions.data,
      };
    case subjectClassActions.ADD_SUBJECT_CLASS_REQUEST:
      return {
        ...state,
        addSubjectClassLoading: true,
        addSubjectClassError: {},
      };
    case subjectClassActions.ADD_SUBJECT_CLASS_SUCCESS:
      return {
        ...state,
        addSubjectClassSuccess: actions.data,
        addSubjectClassLoading: false,
      };
    case subjectClassActions.ADD_SUBJECT_CLASS_ERROR:
      return {
        ...state,
        addSubjectClassError: actions.data,
        addSubjectClassLoading: false,
      };
    case subjectClassActions.UPDATE_SUBJECT_CLASS_DATA:
      return {
        ...state,
        updateSubjectClassData: actions.data,
      };
    case subjectClassActions.UPDATE_SUBJECT_CLASS_REQUEST:
      return {
        ...state,
        updateSubjectClassLoading: true,
        updateSubjectClassError: {},
      };
    case subjectClassActions.UPDATE_SUBJECT_CLASS_SUCCESS:
      return {
        ...state,
        updateSubjectClassLoading: false,
        updateSubjectClassSuccess: actions.data,
      };
    case subjectClassActions.UPDATE_SUBJECT_CLASS_ERROR:
      return {
        ...state,
        updateSubjectClassLoading: false,
        updateSubjectClassError: actions.data,
      };
    case subjectClassActions.DELETE_SUBJECT_CLASS_REQUEST:
      return {
        ...state,
        deleteSubjectClassLoading: true,
        deleteSubjectClassError: {},
      };
    case subjectClassActions.DELETE_SUBJECT_CLASS_SUCCESS:
      return {
        ...state,
        deleteSubjectClassLoading: false,
        deleteSubjectClassSuccess: actions.data,
      };
    case subjectClassActions.DELETE_SUBJECT_CLASS_ERROR:
      return {
        ...state,
        deleteSubjectClassLoading: false,
        deleteSubjectClassError: actions.error,
      };
    case subjectClassActions.DELETE_EXAM_PAPER_REQUEST:
      return {
        ...state,
        deleteExamPaperLoading: true,
        deleteExamPaperError: {},
      };
    case subjectClassActions.DELETE_EXAM_PAPER_SUCCESS:
      return {
        ...state,
        deleteExamPaperLoading: false,
        deleteExamPaperSuccess: actions.data,
      };
    case subjectClassActions.DELETE_EXAM_PAPER_ERROR:
      return {
        ...state,
        deleteExamPaperLoading: false,
        deleteExamPaperError: actions.error,
      };
    default:
      return state;
  }
};

export default subjectClass;
