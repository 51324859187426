const clientInvoices = {
  GET_CLIENT_INVOICES_REQUEST: "GET_CLIENT_INVOICES_REQUEST",
  GET_CLIENT_INVOICES_SUCCESS: "GET_CLIENT_INVOICES_SUCCESS",
  GET_CLIENT_INVOICES_ERROR: "GET_CLIENT_INVOICES_ERROR",

  GET_CLIENT_INVOICE_DETAILS_REQUEST: "GET_CLIENT_INVOICE_DETAILS_REQUEST",
  GET_CLIENT_INVOICE_DETAILS_SUCCESS: "GET_CLIENT_INVOICE_DETAILS_SUCCESS",
  GET_CLIENT_INVOICE_DETAILS_ERROR: "GET_CLIENT_INVOICE_DETAILS_ERROR",

  ADD_CLIENT_INVOICE_REQUEST: "ADD_CLIENT_INVOICE_REQUEST",
  ADD_CLIENT_INVOICE_SUCCESS: "ADD_CLIENT_INVOICE_SUCCESS",
  ADD_CLIENT_INVOICE_ERROR: "ADD_CLIENT_INVOICE_ERROR",

  UPDATE_CLIENT_INVOICE_REQUEST: "UPDATE_CLIENT_INVOICE_REQUEST",
  UPDATE_CLIENT_INVOICE_SUCCESS: "UPDATE_CLIENT_INVOICE_SUCCESS",
  UPDATE_CLIENT_INVOICE_ERROR: "UPDATE_CLIENT_INVOICE_ERROR",
  SET_UPDATE_CLIENT_INVOICE_DATA: "SET_UPDATE_CLIENT_INVOICE_DATA",

  DELETE_CLIENT_INVOICE_REQUEST: "DELETE_CLIENT_INVOICE_REQUEST",
  DELETE_CLIENT_INVOICE_SUCCESS: "DELETE_CLIENT_INVOICE_SUCCESS",
  DELETE_CLIENT_INVOICE_ERROR: "DELETE_CLIENT_INVOICE_ERROR",

  GET_CLIENT_INVOICES_META_DATA_REQUEST:
    "GET_CLIENT_INVOICES_META_DATA_REQUEST",
  GET_CLIENT_INVOICES_META_DATA_SUCCESS:
    "GET_CLIENT_INVOICES_META_DATA_SUCCESS",
  GET_CLIENT_INVOICES_META_DATA_ERROR: "GET_CLIENT_INVOICES_META_DATA_ERROR",

  getClientInvoices: () => ({
    type: clientInvoices.GET_CLIENT_INVOICES_REQUEST,
  }),

  getClientInvoiceDetails: (id) => ({
    type: clientInvoices.GET_CLIENT_INVOICE_DETAILS_REQUEST,
    id,
  }),

  addClientInvoice: (data) => ({
    type: clientInvoices.ADD_CLIENT_INVOICE_REQUEST,
    data,
  }),

  updateClientInvoice: (id, data) => ({
    type: clientInvoices.UPDATE_CLIENT_INVOICE_REQUEST,
    data,
    id,
  }),

  setUpdateClientInvoiceData: (data) => ({
    type: clientInvoices.SET_UPDATE_CLIENT_INVOICE_DATA,
    data,
  }),

  deleteClientInvoice: (id) => ({
    type: clientInvoices.DELETE_CLIENT_INVOICE_REQUEST,
    id,
  }),

  getClientInvoicesMetaData: () => ({
    type: clientInvoices.GET_CLIENT_INVOICES_META_DATA_REQUEST,
  }),
};

export default clientInvoices;
