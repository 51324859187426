import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import {
  partnersActions,
  supplierPaymentsActions,
  clientInvoicesActions,
  incomeReceiptActions,
} from "../../../actions";

function* getPartners() {
  try {
    const response = yield axios({
      url: "/business-development-and-partnerships/partners/",
      method: "GET",
    });

    yield put({
      type: partnersActions.GET_PARTNERS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: partnersActions.GET_PARTNERS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetPartners() {
  yield takeLatest(partnersActions.GET_PARTNERS_REQUEST, getPartners);
}

function* addPartner({ data }) {
  try {
    const response = yield axios({
      url: "/business-development-and-partnerships/partners",
      method: "POST",
      data: data,
    });

    yield put({
      type: partnersActions.ADD_PARTNER_SUCCESS,
      data: response,
    });

    yield put(supplierPaymentsActions.getSupplierPaymentsScheduleMetaData()); // get the supplier payments schedule meta data that will be used in the supplier payments schedule page with the new data
    yield put(clientInvoicesActions.getClientInvoicesMetaData()); // get the client invoices meta data that will be used in the client invoices page with the new data
    yield put(incomeReceiptActions.getMetaData()); // get the income receipts meta data that will be used in the income receipts page with the new data
  } catch (error) {
    yield put({
      type: partnersActions.ADD_PARTNER_ERROR,
      error: error.data,
    });
  }
}
function* watchAddPartner() {
  yield takeLatest(partnersActions.ADD_PARTNER_REQUEST, addPartner);
}

function* getPartnerDetails({ id }) {
  try {
    const response = yield axios({
      url: `/business-development-and-partnerships/partners/${id}`,
      method: "GET",
    });

    yield put({
      type: partnersActions.GET_PARTNER_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: partnersActions.GET_PARTNER_DETAILS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetPartnerDetails() {
  yield takeLatest(
    partnersActions.GET_PARTNER_DETAILS_REQUEST,
    getPartnerDetails
  );
}

function* updatePartnerDetails({ data, id, partners }) {
  try {
    const response = yield axios({
      url: `/business-development-and-partnerships/partners/${id}`,
      method: "PUT",
      data: data,
    });

    yield put({
      type: partnersActions.UPDATE_PARTNER_DETAILS_SUCCESS,
      data: response,
      partners,
    });

    yield put(supplierPaymentsActions.getSupplierPaymentsScheduleMetaData());
    yield put(clientInvoicesActions.getClientInvoicesMetaData());
    yield put(incomeReceiptActions.getMetaData());
  } catch (error) {
    yield put({
      type: partnersActions.UPDATE_PARTNER_DETAILS_ERROR,
      error: error.data,
    });
  }
}
function* watchUpdatePartnerDetails() {
  yield takeLatest(
    partnersActions.UPDATE_PARTNER_DETAILS_REQUEST,
    updatePartnerDetails
  );
}

function* deletePartner({ id, partners }) {
  try {
    const response = yield axios({
      url: `/business-development-and-partnerships/partners/${id}`,
      method: "DELETE",
    });

    yield put({
      type: partnersActions.DELETE_PARTNER_SUCCESS,
      data: response,
      partners,
    });

    yield put(supplierPaymentsActions.getSupplierPaymentsScheduleMetaData());
    yield put(clientInvoicesActions.getClientInvoicesMetaData());
    yield put(incomeReceiptActions.getMetaData());
  } catch (error) {
    yield put({
      type: partnersActions.DELETE_PARTNER_ERROR,
      error: error.data,
    });
  }
}
function* watchDeletePartner() {
  yield takeLatest(partnersActions.DELETE_PARTNER_REQUEST, deletePartner);
}

function* checkPartner({ id }) {
  try {
    const response = yield axios({
      url: `/business-development-and-partnerships/partners/check/${id}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({
      type: partnersActions.CHECK_PARTNER_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: partnersActions.CHECK_PARTNER_ERROR,
      error: error.data,
    });
  }
}
function* watchCheckPartner() {
  yield takeLatest(partnersActions.CHECK_PARTNER_REQUEST, checkPartner);
}

function* approvePartner({ id }) {
  try {
    const response = yield axios({
      url: `/business-development-and-partnerships/partners/approve/${id}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: partnersActions.APPROVE_PARTNER_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: partnersActions.APPROVE_PARTNER_ERROR,
      error: error.data,
    });
  }
}
function* watchApprovePartner() {
  yield takeLatest(partnersActions.APPROVE_PARTNER_REQUEST, approvePartner);
}

export default [
  fork(watchGetPartners),
  fork(watchAddPartner),
  fork(watchGetPartnerDetails),
  fork(watchUpdatePartnerDetails),
  fork(watchDeletePartner),
  fork(watchCheckPartner),
  fork(watchApprovePartner),
];
