import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { schoolGatePassActions, appUiActions } from "../../../actions";

function* getSchoolGatePass() {
  try {
    const response = yield axios({
      url: "/administration/school-gate-pass/",
      method: "GET",
    });

    yield put({
      type: schoolGatePassActions.GET_SCHOOL_GATE_PASS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: schoolGatePassActions.GET_SCHOOL_GATE_PASS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSchoolGatePass() {
  yield takeLatest(
    schoolGatePassActions.GET_SCHOOL_GATE_PASS_REQUEST,
    getSchoolGatePass
  );
}

function* getSchoolGatePassMetaData() {
  try {
    const response = yield axios({
      url: "/administration/school-gate-pass/meta-data",
    });

    yield put({
      type: schoolGatePassActions.GET_SCHOOL_GATE_PASS_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: schoolGatePassActions.GET_SCHOOL_GATE_PASS_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSchoolGatePassMetaData() {
  yield takeLatest(
    schoolGatePassActions.GET_SCHOOL_GATE_PASS_META_DATA_REQUEST,
    getSchoolGatePassMetaData
  );
}

function* getSchoolGatePassDetails({ id }) {
  try {
    const response = yield axios({
      url: `/administration/school-gate-pass/${id}`,
    });

    yield put({
      type: schoolGatePassActions.GET_SCHOOL_GATE_PASS_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: schoolGatePassActions.GET_SCHOOL_GATE_PASS_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSchoolGatePassDetails() {
  yield takeLatest(
    schoolGatePassActions.GET_SCHOOL_GATE_PASS_DETAILS_REQUEST,
    getSchoolGatePassDetails
  );
}

function* addSchoolGatePass({ data }) {
  try {
    const response = yield axios({
      url: "/administration/school-gate-pass/",
      method: "POST",
      data,
    });

    yield put({
      type: schoolGatePassActions.ADD_SCHOOL_GATE_PASS_SUCCESS,
      data: response,
    });
    yield put(schoolGatePassActions.getSchoolGatePass());
    yield put(appUiActions.toggleAddSchoolGatePassModal(false));
  } catch (error) {
    yield put({
      type: schoolGatePassActions.ADD_SCHOOL_GATE_PASS_ERROR,
      error: error.data,
    });
  }
}

function* watchAddSchoolGatePass() {
  yield takeLatest(
    schoolGatePassActions.ADD_SCHOOL_GATE_PASS_REQUEST,
    addSchoolGatePass
  );
}

function* updateSchoolGatePass({ data, id }) {
  try {
    const cleanedData = {
      learner_id: data.learner_id,
      date_out: data.date_out,
      date_expected_in: data.date_expected_in,
      reason: data.reason,
    };

    const response = yield axios({
      url: `/administration/school-gate-pass/${id}`,
      method: "PUT",
      data: cleanedData,
    });

    yield put({
      type: schoolGatePassActions.UPDATE_SCHOOL_GATE_PASS_SUCCESS,
      data: {
        ...response,
        id,
        data,
      },
    });
  } catch (error) {
    yield put({
      type: schoolGatePassActions.UPDATE_SCHOOL_GATE_PASS_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateSchoolGatePass() {
  yield takeLatest(
    schoolGatePassActions.UPDATE_SCHOOL_GATE_PASS_REQUEST,
    updateSchoolGatePass
  );
}

function* deleteSchoolGatePass({ id }) {
  try {
    const response = yield axios({
      url: `/administration/school-gate-pass/${id}`,
      method: "DELETE",
    });

    yield put({
      type: schoolGatePassActions.DELETE_SCHOOL_GATE_PASS_SUCCESS,
      data: {
        ...response,
        id,
      },
    });
  } catch (error) {
    yield put({
      type: schoolGatePassActions.DELETE_SCHOOL_GATE_PASS_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteSchoolGatePass() {
  yield takeLatest(
    schoolGatePassActions.DELETE_SCHOOL_GATE_PASS_REQUEST,
    deleteSchoolGatePass
  );
}

function* checkSchoolGatePass({ id }) {
  try {
    const response = yield axios({
      url: `/administration/school-gate-pass/check/${id}`,
      method: "PATCH",
    });

    yield put({
      type: schoolGatePassActions.CHECK_SCHOOL_GATE_PASS_SUCCESS,
      data: {
        ...response,
        id,
      },
    });

    yield put(schoolGatePassActions.getSchoolGatePassDetails(id));
  } catch (error) {
    yield put({
      type: schoolGatePassActions.CHECK_SCHOOL_GATE_PASS_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckSchoolGatePass() {
  yield takeLatest(
    schoolGatePassActions.CHECK_SCHOOL_GATE_PASS_REQUEST,
    checkSchoolGatePass
  );
}

function* approveSchoolGatePass({ id }) {
  try {
    const response = yield axios({
      url: `/administration/school-gate-pass/approve/${id}`,
      method: "PATCH",
    });

    yield put({
      type: schoolGatePassActions.APPROVE_SCHOOL_GATE_PASS_SUCCESS,
      data: {
        ...response,
        id,
      },
    });

    yield put(schoolGatePassActions.getSchoolGatePassDetails(id));
  } catch (error) {
    yield put({
      type: schoolGatePassActions.APPROVE_SCHOOL_GATE_PASS_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveSchoolGatePass() {
  yield takeLatest(
    schoolGatePassActions.APPROVE_SCHOOL_GATE_PASS_REQUEST,
    approveSchoolGatePass
  );
}

export default [
  fork(watchGetSchoolGatePass),
  fork(watchGetSchoolGatePassMetaData),
  fork(watchGetSchoolGatePassDetails),
  fork(watchAddSchoolGatePass),
  fork(watchUpdateSchoolGatePass),
  fork(watchDeleteSchoolGatePass),
  fork(watchCheckSchoolGatePass),
  fork(watchApproveSchoolGatePass),
];
