import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import {
  learnerIndisciplineActions,
  enrollmentActions,
} from "../../../actions";

function* getIndisciplineCases() {
  try {
    const response = yield axios({
      url: "/administration/learner-discipline/",
    });

    yield put({
      type: learnerIndisciplineActions.GET_INDISCIPLINE_CASES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: learnerIndisciplineActions.GET_INDISCIPLINE_CASES_ERROR,
      error: error.data,
    });
  }
}

function* watchGetIndisciplineCases() {
  yield takeLatest(
    learnerIndisciplineActions.GET_INDISCIPLINE_CASES_REQUEST,
    getIndisciplineCases
  );
}

function* getIndisciplineMetaData() {
  try {
    const response = yield axios({
      url: "/administration/learner-discipline/meta-data",
    });

    yield put({
      type: learnerIndisciplineActions.GET_INDISCIPLINE_CASES_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: learnerIndisciplineActions.GET_INDISCIPLINE_CASES_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetIndisciplineMetaData() {
  yield takeLatest(
    learnerIndisciplineActions.GET_INDISCIPLINE_CASES_META_DATA_REQUEST,
    getIndisciplineMetaData
  );
}

function* getIndisciplineCaseDetails({ id }) {
  try {
    const response = yield axios({
      url: `/administration/learner-discipline/${id}`,
    });

    yield put({
      type: learnerIndisciplineActions.GET_INDISCIPLINE_CASE_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: learnerIndisciplineActions.GET_INDISCIPLINE_CASE_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetIndisciplineCaseDetails() {
  yield takeLatest(
    learnerIndisciplineActions.GET_INDISCIPLINE_CASE_DETAILS_REQUEST,
    getIndisciplineCaseDetails
  );
}

function* addIndisciplineCase({ data, isLearnerProfile }) {
  const {
    learner_id,
    learner_enrollment_id,
    date,
    incident,
    action_taken,
    remarks,
    incident_location,
    de_enrolled,
  } = data;
  try {
    const response = yield axios({
      url: "/administration/learner-discipline",
      method: "POST",
      data: {
        learner_id,
        learner_enrollment_id,
        date,
        incident,
        action_taken,
        remarks,
        incident_location,
        de_enrolled,
      },
    });

    yield put({
      type: learnerIndisciplineActions.ADD_INDISCIPLINE_CASE_SUCCESS,
      data: {
        ...response,
        ...data,
      },
    });
    if (isLearnerProfile) {
      yield put(enrollmentActions.getLearnerProfile(data?.learner_id));
    }
  } catch (error) {
    yield put({
      type: learnerIndisciplineActions.ADD_INDISCIPLINE_CASE_ERROR,
      error: error.data,
    });
  }
}

function* watchAddIndisciplineCase() {
  yield takeLatest(
    learnerIndisciplineActions.ADD_INDISCIPLINE_CASE_REQUEST,
    addIndisciplineCase
  );
}

function* updateIndisciplineCase({ data, id, isLearnerProfile }) {
  const {
    learner_id,
    learner_enrollment_id,
    date,
    incident,
    action_taken,
    remarks,
    incident_location,
    de_enrolled,
  } = data;
  try {
    const response = yield axios({
      url: `/administration/learner-discipline/${id}`,
      method: "PUT",
      data: {
        learner_id,
        learner_enrollment_id,
        date,
        incident,
        action_taken,
        remarks,
        incident_location,
        de_enrolled,
      },
    });

    yield put({
      type: learnerIndisciplineActions.UPDATE_INDISCIPLINE_CASE_SUCCESS,
      data: {
        ...response,
        id,
        data,
      },
    });
    if (isLearnerProfile) {
      yield put(enrollmentActions.getLearnerProfile(data?.learner_id));
    }
  } catch (error) {
    yield put({
      type: learnerIndisciplineActions.UPDATE_INDISCIPLINE_CASE_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateIndisciplineCase() {
  yield takeLatest(
    learnerIndisciplineActions.UPDATE_INDISCIPLINE_CASE_REQUEST,
    updateIndisciplineCase
  );
}

function* deleteIndisciplineCase({ id, learner_id }) {
  try {
    const response = yield axios({
      url: `/administration/learner-discipline/${id}`,
      method: "DELETE",
    });

    yield put({
      type: learnerIndisciplineActions.DELETE_INDISCIPLINE_CASE_SUCCESS,
      data: {
        ...response,
        id,
      },
    });
    if (learner_id) {
      yield put(enrollmentActions.getLearnerProfile(learner_id));
    }
  } catch (error) {
    yield put({
      type: learnerIndisciplineActions.DELETE_INDISCIPLINE_CASE_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteIndisciplineCase() {
  yield takeLatest(
    learnerIndisciplineActions.DELETE_INDISCIPLINE_CASE_REQUEST,
    deleteIndisciplineCase
  );
}

export default [
  fork(watchGetIndisciplineCases),
  fork(watchGetIndisciplineMetaData),
  fork(watchGetIndisciplineCaseDetails),
  fork(watchAddIndisciplineCase),
  fork(watchUpdateIndisciplineCase),
  fork(watchDeleteIndisciplineCase),
];
