import React from "react";
import { Row, Col } from "antd";
import { FcTabletAndroid, FcCalculator, FcBullish } from "react-icons/fc";

const items = [
  {
    key: "1",
    icon: <FcBullish />,
    title: "High Performance",
    content:
      "High performance is the key to success. Famis is built to be fast and efficient.",
  },
  {
    key: "2",
    icon: <FcTabletAndroid />,
    title: "Flat Design",
    content:
      "Famis is built with a flat design that is easy to use and easy to customize.",
  },
  {
    key: "3",
    icon: <FcCalculator />,
    title: "Simplified Workflow",
    content:
      "Famis is built with a simplified workflow that is easy to use and easy to customize.",
  },
];

function AppAbout() {
  return (
    <div id="about" className="block aboutBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>About</h2>
          <p>
            Famis is a finance and accounting management information system.
          </p>
        </div>
        <div className="contentHolder">
          <p>
            Famis is built to handle Finance, Human resource, Assets and
            Inventory management, Projects, Business development, Partnerships
            and Procurement.
          </p>
        </div>
        <Row gutter={[16, 16]} justify="space-around">
          {items.map((item) => {
            return (
              <Col md={{ span: 8 }} key={item.key}>
                <div className="content">
                  <div className="icon">{item.icon}</div>
                  <h3>{item.title}</h3>
                  <p>{item.content}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
}

export default AppAbout;
