import { takeLatest, put, fork, takeEvery } from "@redux-saga/core/effects";
import axios from "axios";
import {
  appUiActions,
  feesCollectionsActions,
  learnerBillingActions,
  paymentStructureActions,
} from "../../../actions";

function* getPaymentStructures() {
  try {
    const response = yield axios({
      url: "/administration/payment-structures/",
    });

    yield put({
      type: paymentStructureActions.GET_PAYMENT_STRUCTURES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: paymentStructureActions.GET_PAYMENT_STRUCTURES_ERROR,
      error: error.data,
    });
  }
}

function* watchGetPaymentStructures() {
  yield takeLatest(
    paymentStructureActions.GET_PAYMENT_STRUCTURES_REQUEST,
    getPaymentStructures
  );
}

function* getPaymentStructureDetails({ id }) {
  try {
    const response = yield axios({
      url: `/administration/payment-structures/${id}`,
    });

    yield put({
      type: paymentStructureActions.GET_PAYMENT_STRUCTURE_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: paymentStructureActions.GET_PAYMENT_STRUCTURE_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetPaymentStructureDetails() {
  yield takeLatest(
    paymentStructureActions.GET_PAYMENT_STRUCTURE_DETAILS_REQUEST,
    getPaymentStructureDetails
  );
}

function* setPaymentStructureCostBillingStatus({ id, data }) {
  try {
    const response = yield axios({
      url: `/administration/payment-structures/costs/billing-status/${id}`,
      data,
    });

    yield put({
      type: paymentStructureActions.GET_PAYMENT_STRUCTURE_DETAILS_SUCCESS,
      data: response,
      costId: id,
      updateData: data,
    });
  } catch (error) {
    yield put({
      type: paymentStructureActions.GET_PAYMENT_STRUCTURE_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchSetPaymentStructureCostBillingStatus() {
  yield takeLatest(
    paymentStructureActions.SET_PAYMENT_STRUCTURE_COST_STATUS_REQUEST,
    setPaymentStructureCostBillingStatus
  );
}

function* addPaymentStructure({ data }) {
  try {
    const response = yield axios({
      url: `/administration/payment-structures`,
      data,
      method: "POST",
    });

    yield put({
      type: paymentStructureActions.ADD_PAYMENT_STRUCUTURES_SUCCESS,
      data: response,
    });
    yield put(paymentStructureActions.getPaymentStructures());
    yield put(learnerBillingActions.getMetaData());
    yield put(feesCollectionsActions.getMetaData());
  } catch (error) {
    yield put({
      type: paymentStructureActions.ADD_PAYMENT_STRUCUTURES_ERROR,
      error: error.data,
    });
  }
}

function* watchAddPaymentStructure() {
  yield takeLatest(
    paymentStructureActions.ADD_PAYMENT_STRUCUTURES_REQUEST,
    addPaymentStructure
  );
}

function* updatePaymentStructure({ id, data, paymentStructures }) {
  try {
    const response = yield axios({
      url: `/administration/payment-structures/${id}`,
      data,
      method: "PUT",
    });

    yield put(appUiActions.toggleUpdatePaymentStructure(false));
    yield put({
      type: paymentStructureActions.UPDATE_PAYMENT_STRUCTURES_SUCCESS,
      data: response,
      paymentStructures,
    });
  } catch (error) {
    yield put({
      type: paymentStructureActions.UPDATE_PAYMENT_STRUCTURES_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdatePaymentStructure() {
  yield takeLatest(
    paymentStructureActions.UPDATE_PAYMENT_STRUCTURES_REQUEST,
    updatePaymentStructure
  );
}

function* deletePaymentStructure({ id, paymentStructures }) {
  try {
    const response = yield axios({
      url: `/administration/payment-structures/${id}`,
      method: "DELETE",
    });

    yield put({
      type: paymentStructureActions.DELETE_PAYMENT_STRUCTURES_SUCCESS,
      data: response,
      paymentStructures,
    });
  } catch (error) {
    yield put({
      type: paymentStructureActions.DELETE_PAYMENT_STRUCTURES_ERROR,
      error: error.data,
    });
  }
}

function* watchDeletePaymentStructures() {
  yield takeEvery(
    paymentStructureActions.DELETE_PAYMENT_STRUCTURES_REQUEST,
    deletePaymentStructure
  );
}

function* getCostDetails({ id }) {
  try {
    const response = yield axios({
      url: `/administration/payment-structures/costs/${id}`,
    });

    yield put({
      type: paymentStructureActions.GET_COST_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: paymentStructureActions.GET_COST_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetCostDetails() {
  yield takeLatest(
    paymentStructureActions.GET_COST_DETAILS_REQUEST,
    getCostDetails
  );
}

function* updateCostDetails({ id, data }) {
  try {
    const response = yield axios({
      url: `/administration/payment-structures/costs/${id}`,
      data,
      method: "PUT",
    });

    yield put(appUiActions.toggleUpdaPaymentStructureCostModal(false));
    yield put(learnerBillingActions.getMetaData());
    yield put({
      type: paymentStructureActions.UPDATE_COST_SUCCESS,
      data: response,
      costId: id,
      updateData: data,
    });
  } catch (error) {
    yield put({
      type: paymentStructureActions.UPDATE_COST_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateCost() {
  yield takeLatest(
    paymentStructureActions.UPDATE_COST_REQUEST,
    updateCostDetails
  );
}

function* deleteCost({ id, params }) {
  try {
    const response = yield axios({
      url: `/administration/payment-structures/costs/${id}`,
      method: "DELETE",
      params,
    });

    yield put(
      paymentStructureActions.getPaymentStructureDetails(
        params.paymentStructureId
      )
    );
    yield put({
      type: paymentStructureActions.DELETE_COST_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: paymentStructureActions.DELETE_COST_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteCost() {
  yield takeEvery(paymentStructureActions.DELETE_COST_REQUEST, deleteCost);
}

function* checkPaymentStructure({ id }) {
  try {
    const response = yield axios({
      url: `/administration/payment-structures/check/${id}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({
      type: paymentStructureActions.CHECK_PAYMENT_STRUCTURE_SUCCESS,
      data: response,
    });
    yield put(paymentStructureActions.getPaymentStructureDetails(id));
  } catch (error) {
    yield put({
      type: paymentStructureActions.CHECK_PAYMENT_STRUCTURE_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckPaymentStructure() {
  yield takeLatest(
    paymentStructureActions.CHECK_PAYMENT_STRUCTURE_REQUEST,
    checkPaymentStructure
  );
}

function* approvePaymentStructure({ id }) {
  try {
    const response = yield axios({
      url: `/administration/payment-structures/approve/${id}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: paymentStructureActions.APPROVE_PAYMENT_STRUCTURE_SUCCESS,
      data: response,
    });
    yield put(paymentStructureActions.getPaymentStructureDetails(id));
  } catch (error) {
    yield put({
      type: paymentStructureActions.APPROVE_PAYMENT_STRUCTURE_ERROR,
      error: error.data,
    });
  }
}

function* watchApprovePaymentStructure() {
  yield takeLatest(
    paymentStructureActions.APPROVE_PAYMENT_STRUCTURE_REQUEST,
    approvePaymentStructure
  );
}

function* getMetaData() {
  try {
    const response = yield axios({
      url: "/administration/payment-structures/meta-data",
    });

    yield put({
      type: paymentStructureActions.GET_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: paymentStructureActions.GET_META_DATA_SUCCESS,
      data: {},
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(paymentStructureActions.GET_META_DATA_REQUEST, getMetaData);
}

export default [
  fork(watchGetPaymentStructures),
  fork(watchGetPaymentStructureDetails),
  fork(watchAddPaymentStructure),
  fork(watchUpdatePaymentStructure),
  fork(watchDeletePaymentStructures),
  fork(watchGetCostDetails),
  fork(watchUpdateCost),
  fork(watchDeleteCost),
  fork(watchCheckPaymentStructure),
  fork(watchApprovePaymentStructure),
  fork(watchGetMetaData),
  fork(watchSetPaymentStructureCostBillingStatus),
];
