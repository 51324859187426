import { incomeStatementActions } from "../../../actions";
import initialState from "../../../initialState";

const incomeStatement = (state = initialState.incomeStatement, actions) => {
  switch (actions.type) {
    case incomeStatementActions.GET_INCOME_STATEMENT_REQUEST:
      return {
        ...state,
        incomeStatementLoading: true,
        incomeStatementError: {},
      };
    case incomeStatementActions.GET_INCOME_STATEMENT_SUCCESS:
      return {
        ...state,
        incomeStatementSuccess: actions.data,
        incomeStatementLoading: false,
      };
    case incomeStatementActions.GET_INCOME_STATEMENT_ERROR:
      return {
        ...state,
        incomeStatementError: actions.error,
        incomeStatementLoading: false,
      };
    case incomeStatementActions.GET_INCOME_STATEMENT_META_DATA_REQUEST:
      return {
        ...state,
        metaDataError: {},
        metaDataLoading: false,
      };
    case incomeStatementActions.GET_INCOME_STATEMENT_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataSuccess: actions.data,
        metaDataLoading: false,
      };
    case incomeStatementActions.GET_INCOME_STATEMENT_META_DATA_ERROR:
      return {
        ...state,
        metaDataError: actions.error,
        metaDataLoading: false,
      };
    case incomeStatementActions.GET_BALANCE_SHEET_REQUEST:
      return {
        ...state,
        balanceSheetLoading: true,
        balanceSheetError: {},
      };
    case incomeStatementActions.GET_BALANCE_SHEET_SUCCESS:
      return {
        ...state,
        balanceSheet: actions.data,
        balanceSheetLoading: false,
      };
    case incomeStatementActions.GET_BALANCE_SHEET_ERROR:
      return {
        ...state,
        balanceSheetError: actions.error,
        balanceSheetLoading: false,
      };
    case incomeStatementActions.SET_INCOME_STATEMENT_ACCOUNT_DATA:
      return {
        ...state,
        setIncomeStatementAccountData: actions.data,
      };
    case incomeStatementActions.SET_GET_BY_DATA:
      return {
        ...state,
        setGetByData: actions.data,
      };

    default:
      return state;
  }
};

export default incomeStatement;
