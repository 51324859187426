const loanRepaymentSchedule = {
  GET_LOAN_REPAYMENT_SCHEDULE_SUCCESS: "GET_LOAN_REPAYMENT_SCHEDULE_SUCCESS",
  GET_LOAN_REPAYMENT_SCHEDULE_REQUEST: "GET_LOAN_REPAYMENT_SCHEDULE_REQUEST",
  GET_LOAN_REPAYMENT_SCHEDULE_ERROR: "GET_LOAN_REPAYMENT_SCHEDULE_ERROR",

  ADD_LOAN_REPAYMENT_SCHEDULE_SUCCESS: "ADD_LOAN_REPAYMENT_SCHEDULE_SUCCESS",
  ADD_LOAN_REPAYMENT_SCHEDULE_REQUEST: "ADD_LOAN_REPAYMENT_SCHEDULE_REQUEST",
  ADD_LOAN_REPAYMENT_SCHEDULE_ERROR: "ADD_LOAN_REPAYMENT_SCHEDULE_ERROR",

  UPDATE_LOAN_REPAYMENT_SCHEDULE_SUCCESS:
    "UPDATE_LOAN_REPAYMENT_SCHEDULE_SUCCESS",
  UPDATE_LOAN_REPAYMENT_SCHEDULE_REQUEST:
    "UPDATE_LOAN_REPAYMENT_SCHEDULE_REQUEST",
  UPDATE_LOAN_REPAYMENT_SCHEDULE_ERROR: "UPDATE_LOAN_REPAYMENT_SCHEDULE_ERROR",
  SET_UPDATE_LOAN_REPAYMENT_SCHEDULE: "SET_UPDATE_LOAN_REPAYMENT_SCHEDULE",

  DELETE_LOAN_REPAYMENT_SCHEDULE_SUCCESS:
    "DELETE_LOAN_REPAYMENT_SCHEDULE_SUCCESS",
  DELETE_LOAN_REPAYMENT_SCHEDULE_REQUEST:
    "DELETE_LOAN_REPAYMENT_SCHEDULE_REQUEST",
  DELETE_LOAN_REPAYMENT_SCHEDULE_ERROR: "DELETE_LOAN_REPAYMENT_SCHEDULE_ERROR",

  GET_LOAN_REPAYMENT_SCHEDULE_DETAILS_SUCCESS:
    "GET_LOAN_REPAYMENT_SCHEDULE_DETAILS_SUCCESS",
  GET_LOAN_REPAYMENT_SCHEDULE_DETAILS_REQUEST:
    "GET_LOAN_REPAYMENT_SCHEDULE_DETAILS_REQUEST",
  GET_LOAN_REPAYMENT_SCHEDULE_DETAILS_ERROR:
    "GET_LOAN_REPAYMENT_SCHEDULE_DETAILS_ERROR",

  APPROVE_LOAN_REPAYMENT_SCHEDULE_SUCCESS:
    "APPROVE_LOAN_REPAYMENT_SCHEDULE_SUCCESS",
  APPROVE_LOAN_REPAYMENT_SCHEDULE_REQUEST:
    "APPROVE_LOAN_REPAYMENT_SCHEDULE_REQUEST",
  APPROVE_LOAN_REPAYMENT_SCHEDULE_ERROR:
    "APPROVE_LOAN_REPAYMENT_SCHEDULE_ERROR",

  CHECK_LOAN_REPAYMENT_SCHEDULE_SUCCESS:
    "CHECK_LOAN_REPAYMENT_SCHEDULE_SUCCESS",
  CHECK_LOAN_REPAYMENT_SCHEDULE_REQUEST:
    "CHECK_LOAN_REPAYMENT_SCHEDULE_REQUEST",
  CHECK_LOAN_REPAYMENT_SCHEDULE_ERROR: "CHECK_LOAN_REPAYMENT_SCHEDULE_ERROR",

  ADD_LOAN_PAYMENT_SUCCESS: "ADD_LOAN_PAYMENT_SUCCESS",
  ADD_LOAN_PAYMENT_REQUEST: "ADD_LOAN_PAYMENT_REQUEST",
  ADD_LOAN_PAYMENT_ERROR: "ADD_LOAN_PAYMENT_ERROR",

  UPDATE_LOAN_PAYMENT_DATA: "UPDATE_LOAN_PAYMENT_DATA",
  UPDATE_LOAN_PAYMENT_SUCCESS: "UPDATE_LOAN_PAYMENT_SUCCESS",
  UPDATE_LOAN_PAYMENT_ERROR: "UPDATE_LOAN_PAYMENT_ERROR",
  UPDATE_LOAN_PAYMENT_REQUEST: "UPDATE_LOAN_PAYMENT_REQUEST",

  DELETE_LOAN_PAYMENT_SUCCESS: "DELETE_LOAN_PAYMENT_SUCCESS",
  DELETE_LOAN_PAYMENT_ERROR: "DELETE_LOAN_PAYMENT_ERROR",
  DELETE_LOAN_PAYMENT_REQUEST: "DELETE_LOAN_PAYMENT_REQUEST",

  GET_META_DATA_SUCCESS: "GET_LOAN_REPAYMENT_SCHEDULE_META_DATA_SUCCESS",
  GET_META_DATA_ERROR: "GET_LOAN_REPAYMENT_SCHEDULE_META_DATA_ERROR",
  GET_META_DATA_REQUEST: "GET_LOAN_REPAYMENT_SCHEDULE_META_DATA_REQUEST",

  getLoanRepaymentSchedules: () => ({
    type: loanRepaymentSchedule.GET_LOAN_REPAYMENT_SCHEDULE_REQUEST,
  }),
  getMetaData: () => ({
    type: loanRepaymentSchedule.GET_META_DATA_REQUEST,
  }),
  addLoanRepaymentSchedule: (data) => ({
    type: loanRepaymentSchedule.ADD_LOAN_REPAYMENT_SCHEDULE_REQUEST,
    data,
  }),
  updateLoanRepaymentSchedule: (data) => ({
    type: loanRepaymentSchedule.UPDATE_LOAN_REPAYMENT_SCHEDULE_REQUEST,
    data,
  }),
  deleteLoanRepaymentSchedule: (data) => ({
    type: loanRepaymentSchedule.DELETE_LOAN_REPAYMENT_SCHEDULE_REQUEST,
    data,
  }),
  getLoanRepaymentScheduleDetails: (data) => ({
    type: loanRepaymentSchedule.GET_LOAN_REPAYMENT_SCHEDULE_DETAILS_REQUEST,
    data,
  }),
  approveLoanRepaymentSchedule: (data) => ({
    type: loanRepaymentSchedule.APPROVE_LOAN_REPAYMENT_SCHEDULE_REQUEST,
    data,
  }),
  checkLoanRepaymentSchedule: (data) => ({
    type: loanRepaymentSchedule.CHECK_LOAN_REPAYMENT_SCHEDULE_REQUEST,
    data,
  }),
  addLoanPayment: (data) => ({
    type: loanRepaymentSchedule.ADD_LOAN_PAYMENT_REQUEST,
    data,
  }),
  updateLoanPayment: (data) => ({
    type: loanRepaymentSchedule.UPDATE_LOAN_PAYMENT_REQUEST,
    data,
  }),
  deleteLoanPayment: (data) => ({
    type: loanRepaymentSchedule.DELETE_LOAN_PAYMENT_REQUEST,
    data,
  }),
  setUpdateLoanSchedule: (data) => ({
    type: loanRepaymentSchedule.SET_UPDATE_LOAN_REPAYMENT_SCHEDULE,
    data,
  }),
  setUpdateLoanPayment: (data) => ({
    type: loanRepaymentSchedule.UPDATE_LOAN_PAYMENT_DATA,
    data,
  }),
};

export default loanRepaymentSchedule;
