import { takeLatest, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { appUiActions, marksUploadActions } from "../../../actions";
const FileDownload = require("js-file-download");

function* getMarksUpload({ data }) {
  try {
    const response = yield axios({
      url: "/academics/marks",
      params: data,
    });

    yield put({
      type: marksUploadActions.GET_MARKS_UPLOAD_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: marksUploadActions.GET_MARKS_UPLOAD_ERROR,
      error: error.data,
    });
  }
}

function* watchGetMarksUpload() {
  yield takeLatest(marksUploadActions.GET_MARKS_UPLOAD_REQUEST, getMarksUpload);
}

function* getMetaData() {
  try {
    const response = yield axios({ url: "/academics/marks/meta-data" });

    yield put({
      type: marksUploadActions.GET_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: marksUploadActions.GET_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(marksUploadActions.GET_META_DATA_REQUEST, getMetaData);
}

function* getMarksUploadDetails({ data }) {
  try {
    const response = yield axios({
      url: `/academics/marks/details`,
      params: data,
    });

    yield put({
      type: marksUploadActions.GET_MARKS_UPLOAD_DETAILS_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleMarksUploadDetailsModal(true));
  } catch (error) {
    yield put({
      type: marksUploadActions.GET_MARKS_UPLOAD_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetMarksUploadDetails() {
  yield takeLatest(
    marksUploadActions.GET_MARKS_UPLOAD_DETAILS_REQUEST,
    getMarksUploadDetails
  );
}

function* addMarksUpload({ data }) {
  try {
    const response = yield axios({
      url: "/academics/marks",
      method: "POST",
      data,
    });

    yield put({
      type: marksUploadActions.ADD_MARKS_UPLOAD_SUCCESS,
      data: response,
    });
    yield put(marksUploadActions.getMarksUpload());
    yield put(
      marksUploadActions.getMarksUploadDetails({
        subject_assignment_id: data.subject_assignment_id,
        exam_id: data.exam_id,
        paper_id: data.paper_id,
        stream_id: data.stream_id,
      })
    );

    yield put(appUiActions.toggleMarksUploadDetailsModal(true));
    yield put(appUiActions.toggleAddMarksUploadModal(false));
  } catch (error) {
    yield put({
      type: marksUploadActions.ADD_MARKS_UPLOAD_ERROR,
      error: error.data,
    });
  }
}

function* watchAddMarksUpload() {
  yield takeLatest(marksUploadActions.ADD_MARKS_UPLOAD_REQUEST, addMarksUpload);
}

function* updateMarksUpload({ data, id }) {
  try {
    const response = yield axios({
      url: `/academics/marks/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: marksUploadActions.UPDATE_MARKS_UPLOAD_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleUpdateMarksUploadModal(false));
  } catch (error) {
    yield put({
      type: marksUploadActions.UPDATE_MARKS_UPLOAD_SUCCESS,
      error: error.data,
    });
  }
}

function* watchUpdateMarksUpload() {
  yield takeLatest(
    marksUploadActions.UPDATE_MARKS_UPLOAD_REQUEST,
    updateMarksUpload
  );
}

function* deleteMarksUpload({ id }) {
  try {
    const response = yield axios({
      url: `/academics/marks/${id}`,
      method: "DELETE",
    });

    yield put({
      type: marksUploadActions.DELETE_MARKS_UPLOAD_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: marksUploadActions.DELETE_MARKS_UPLOAD_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteMarksUpload() {
  yield takeEvery(
    marksUploadActions.DELETE_MARKS_UPLOAD_REQUEST,
    deleteMarksUpload
  );
}

function* uploadStudentMarks({ data, context }) {
  try {
    const response = yield axios({
      url: "/academics/marks/upload",
      method: "PUT",
      data,
    });

    yield put({
      type: marksUploadActions.UPLOAD_STUDENT_MARKS_SUCCESS,
      data: response,
    });
    yield put(marksUploadActions.getMarksUploadDetails(context));
  } catch (error) {
    yield put({
      type: marksUploadActions.UPLOAD_STUDENT_MARKS_ERROR,
      error: error.data,
    });
  }
}

function* watchUploadStudentMarks() {
  yield takeLatest(
    marksUploadActions.UPLOAD_STUDENT_MARKS_REQUEST,
    uploadStudentMarks
  );
}

function* addStudentMarks({ data }) {
  try {
    const response = yield axios({
      url: "/academics/marks/student-marks",
      method: "POST",
      data,
    });

    yield put({
      type: marksUploadActions.ADD_STUDENT_MARKS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: marksUploadActions.ADD_STUDENT_MARKS_ERROR,
      error: error.data,
    });
  }
}

function* watchAddStudentMarks() {
  yield takeLatest(
    marksUploadActions.ADD_STUDENT_MARKS_REQUEST,
    addStudentMarks
  );
}

function* updateStudentMarks({ data, id }) {
  try {
    const response = yield axios({
      url: `/academics/marks/student-marks/${id}`,
      method: "PATCH",
      data,
    });

    yield put({
      type: marksUploadActions.UPDATE_STUDENT_MARKS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: marksUploadActions.UPDATE_STUDENT_MARKS_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateStudentMarks() {
  yield takeLatest(
    marksUploadActions.UPDATE_STUDENT_MARKS_REQUEST,
    updateStudentMarks
  );
}

function* submitMarksUpload({ id }) {
  try {
    const response = yield axios({
      url: `/academics/marks/submit/${id}`,
      method: "PATCH",
    });

    yield put({
      type: marksUploadActions.SUBMIT_MARKS_UPLOAD_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: marksUploadActions.SUBMIT_MARKS_UPLOAD_ERROR,
      error: error.data,
    });
  }
}

function* watchSubmitMarksUpload() {
  yield takeLatest(
    marksUploadActions.SUBMIT_MARKS_UPLOAD_REQUEST,
    submitMarksUpload
  );
}

function* checkMarksUpload({ id }) {
  try {
    const response = yield axios({
      url: `/academics/marks/check/${id}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({
      type: marksUploadActions.CHECK_MARKS_UPLOAD_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: marksUploadActions.CHECK_MARKS_UPLOAD_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckMarksUpload() {
  yield takeLatest(
    marksUploadActions.CHECK_MARKS_UPLOAD_REQUEST,
    checkMarksUpload
  );
}

function* approveMarksUpload({ id }) {
  try {
    const response = yield axios({
      url: `/academics/marks/approve/${id}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: marksUploadActions.APPROVE_MARKS_UPLOAD_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: marksUploadActions.APPROVE_MARKS_UPLOAD_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveMarksUpload() {
  yield takeLatest(
    marksUploadActions.APPROVE_MARKS_UPLOAD_REQUEST,
    approveMarksUpload
  );
}

function* downloadStudentMarksExcel({ data }) {
  try {
    const response = yield axios({
      url: "/academics/marks/download-excel",
      method: "GET",
      params: data,
      responseType: "blob",
    });

    FileDownload(response.data, "Marks-Upload-Template.xlsx");

    yield put({
      type: marksUploadActions.DOWNLOAD_STUDENT_MARKS_EXCEL_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: marksUploadActions.DOWNLOAD_STUDENT_MARKS_EXCEL_ERROR,
      error: error.data,
    });
  }
}

function* watchDownloadStudentMarksExcel() {
  yield takeLatest(
    marksUploadActions.DOWNLOAD_STUDENT_MARKS_EXCEL_REQUEST,
    downloadStudentMarksExcel
  );
}

function* uploadStudentMarksExcel({ data, params }) {
  try {
    const response = yield axios({
      url: "/academics/marks/upload-excel",
      method: "POST",
      data,
      params: params,
    });

    yield put({
      type: marksUploadActions.UPLOAD_STUDENT_MARKS_EXCEL_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleUploadStudentMarksExcelModal(false));
    yield put(marksUploadActions.marksExcelUploaded(true));
  } catch (error) {
    yield put({
      type: marksUploadActions.UPLOAD_STUDENT_MARKS_EXCEL_ERROR,
      error: error.data,
    });
  }
}

function* watchUploadStudentMarksExcel() {
  yield takeLatest(
    marksUploadActions.UPLOAD_STUDENT_MARKS_EXCEL_REQUEST,
    uploadStudentMarksExcel
  );
}

export default [
  fork(watchGetMarksUpload),
  fork(watchGetMetaData),
  fork(watchGetMarksUploadDetails),
  fork(watchAddMarksUpload),
  fork(watchUpdateMarksUpload),
  fork(watchDeleteMarksUpload),
  fork(watchUploadStudentMarks),
  fork(watchAddStudentMarks),
  fork(watchUpdateStudentMarks),
  fork(watchSubmitMarksUpload),
  fork(watchCheckMarksUpload),
  fork(watchApproveMarksUpload),
  fork(watchDownloadStudentMarksExcel),
  fork(watchUploadStudentMarksExcel),
];
