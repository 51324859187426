const schoolGatePass = {
  GET_SCHOOL_GATE_PASS_REQUEST: "GET_SCHOOL_GATE_PASS_REQUEST",
  GET_SCHOOL_GATE_PASS_SUCCESS: "GET_SCHOOL_GATE_PASS_SUCCESS",
  GET_SCHOOL_GATE_PASS_ERROR: "GET_SCHOOL_GATE_PASS_ERROR",

  GET_SCHOOL_GATE_PASS_META_DATA_REQUEST:
    "GET_SCHOOL_GATE_PASS_META_DATA_REQUEST",
  GET_SCHOOL_GATE_PASS_META_DATA_SUCCESS:
    "GET_SCHOOL_GATE_PASS_META_DATA_SUCCESS",
  GET_SCHOOL_GATE_PASS_META_DATA_ERROR: "GET_SCHOOL_GATE_PASS_META_DATA_ERROR",

  GET_SCHOOL_GATE_PASS_DETAILS_REQUEST: "GET_SCHOOL_GATE_PASS_DETAILS_REQUEST",
  GET_SCHOOL_GATE_PASS_DETAILS_SUCCESS: "GET_SCHOOL_GATE_PASS_DETAILS_SUCCESS",
  GET_SCHOOL_GATE_PASS_DETAILS_ERROR: "GET_SCHOOL_GATE_PASS_DETAILS_ERROR",

  ADD_SCHOOL_GATE_PASS_REQUEST: "ADD_SCHOOL_GATE_PASS_REQUEST",
  ADD_SCHOOL_GATE_PASS_SUCCESS: "ADD_SCHOOL_GATE_PASS_SUCCESS",
  ADD_SCHOOL_GATE_PASS_ERROR: "ADD_SCHOOL_GATE_PASS_ERROR",

  UPDATE_SCHOOL_GATE_PASS_REQUEST: "UPDATE_SCHOOL_GATE_PASS_REQUEST",
  UPDATE_SCHOOL_GATE_PASS_SUCCESS: "UPDATE_SCHOOL_GATE_PASS_SUCCESS",
  UPDATE_SCHOOL_GATE_PASS_ERROR: "UPDATE_SCHOOL_GATE_PASS_ERROR",

  DELETE_SCHOOL_GATE_PASS_REQUEST: "DELETE_SCHOOL_GATE_PASS_REQUEST",
  DELETE_SCHOOL_GATE_PASS_SUCCESS: "DELETE_SCHOOL_GATE_PASS_SUCCESS",
  DELETE_SCHOOL_GATE_PASS_ERROR: "DELETE_SCHOOL_GATE_PASS_ERROR",

  CHECK_SCHOOL_GATE_PASS_REQUEST: "CHECK_SCHOOL_GATE_PASS_REQUEST",
  CHECK_SCHOOL_GATE_PASS_SUCCESS: "CHECK_SCHOOL_GATE_PASS_SUCCESS",
  CHECK_SCHOOL_GATE_PASS_ERROR: "CHECK_SCHOOL_GATE_PASS_ERROR",

  APPROVE_SCHOOL_GATE_PASS_REQUEST: "APPROVE_SCHOOL_GATE_PASS_REQUEST",
  APPROVE_SCHOOL_GATE_PASS_SUCCESS: "APPROVE_SCHOOL_GATE_PASS_SUCCESS",
  APPROVE_SCHOOL_GATE_PASS_ERROR: "APPROVE_SCHOOL_GATE_PASS_ERROR",

  SET_UPDATE_SCHOOL_GATE_PASS_DATA: "SET_UPDATE_SCHOOL_GATE_PASS_DATA",

  getSchoolGatePass: () => ({
    type: schoolGatePass.GET_SCHOOL_GATE_PASS_REQUEST,
  }),

  getSchoolGatePassMetaData: () => ({
    type: schoolGatePass.GET_SCHOOL_GATE_PASS_META_DATA_REQUEST,
  }),

  getSchoolGatePassDetails: (id) => ({
    type: schoolGatePass.GET_SCHOOL_GATE_PASS_DETAILS_REQUEST,
    id,
  }),

  addSchoolGatePass: (data) => ({
    type: schoolGatePass.ADD_SCHOOL_GATE_PASS_REQUEST,
    data,
  }),

  updateSchoolGatePass: (data, id) => ({
    type: schoolGatePass.UPDATE_SCHOOL_GATE_PASS_REQUEST,
    data,
    id,
  }),

  deleteSchoolGatePass: (id) => ({
    type: schoolGatePass.DELETE_SCHOOL_GATE_PASS_REQUEST,
    id,
  }),

  checkSchoolGatePass: (id) => ({
    type: schoolGatePass.CHECK_SCHOOL_GATE_PASS_REQUEST,
    id,
  }),

  approveSchoolGatePass: (id) => ({
    type: schoolGatePass.APPROVE_SCHOOL_GATE_PASS_REQUEST,
    id,
  }),

  setUpdateSchoolGatePassData: (data) => ({
    type: schoolGatePass.SET_UPDATE_SCHOOL_GATE_PASS_DATA,
    data,
  }),
};

export default schoolGatePass;
