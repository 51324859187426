import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { learnersActions, enrollmentActions } from "../../../actions";

function* getMetaData() {
  try {
    const response = yield axios({
      url: `/business-development-and-partnerships/customers/meta-data`,
    });

    yield put({
      type: learnersActions.GET_LEARNERS_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: learnersActions.GET_LEARNERS_META_DATA_SUCCESS,
      data: {},
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(learnersActions.GET_LEARNERS_META_DATA_REQUEST, getMetaData);
}

function* getLearners() {
  try {
    const response = yield axios({
      url: "/business-development-and-partnerships/customers/",
      method: "GET",
    });

    yield put({
      type: learnersActions.GET_LEARNERS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: learnersActions.GET_LEARNERS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetLearners() {
  yield takeLatest(learnersActions.GET_LEARNERS_REQUEST, getLearners);
}

function* uploadLearner({ data }) {
  try {
    const response = yield axios({
      url: "/business-development-and-partnerships/customers",
      method: "POST",
      data: data,
    });

    yield put({
      type: learnersActions.ADD_LEARNER_SUCCESS,
      data: response,
    });
    yield put(learnersActions.getLearners());
  } catch (error) {
    yield put({
      type: learnersActions.ADD_LEARNER_ERROR,
      error: error.data,
    });
  }
}

function* watchUploadLearner() {
  yield takeLatest(learnersActions.ADD_LEARNER_REQUEST, uploadLearner);
}

function* getLearnerDetails({ id }) {
  try {
    const response = yield axios({
      url: `/business-development-and-partnerships/customers/${id}`,
      method: "GET",
    });

    yield put({
      type: learnersActions.GET_LEARNER_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: learnersActions.GET_LEARNER_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetLearnerDetails() {
  yield takeLatest(
    learnersActions.GET_LEARNER_DETAILS_REQUEST,
    getLearnerDetails
  );
}

function* deleteLearner({ id }) {
  try {
    const response = yield axios({
      url: `/business-development-and-partnerships/customers/${id}`,
      method: "DELETE",
    });

    yield put({
      type: learnersActions.DELETE_LEARNER_SUCCESS,
      data: response,
    });
    yield put(learnersActions.getLearners());
  } catch (error) {
    yield put({
      type: learnersActions.DELETE_LEARNER_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteLearner() {
  yield takeLatest(learnersActions.DELETE_LEARNER_REQUEST, deleteLearner);
}

function* updateLearnerDetails({ id, data, enrollment_id }) {
  updateLearnerDetails;
  try {
    const response = yield axios({
      url: `/business-development-and-partnerships/customers/${id}`,
      method: "PUT",
      data: data,
    });

    yield put({
      type: learnersActions.UPDATE_LEARNER_DETAILS_SUCCESS,
      data: response,
    });
    yield put(enrollmentActions.getEnrollmentMetaData());
    yield put(enrollmentActions.getLearnerProfile(id));
    if (enrollment_id) {
      yield put(enrollmentActions.getEnrollmentDetails(enrollment_id));
    }
  } catch (error) {
    yield put({
      type: learnersActions.UPDATE_LEARNER_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateLearnerDetails() {
  yield takeLatest(
    learnersActions.UPDATE_LEARNER_DETAILS_REQUEST,
    updateLearnerDetails
  );
}

function* uploadLearnerProfile({ data }) {
  try {
    const response = yield axios({
      url: "/administration/student-details/",
      method: "POST",
      data: data,
    });

    yield put({
      type: learnersActions.ADD_LEARNER_PROFILE_SUCCESS,
      data: response,
    });
    yield put(learnersActions.getLearnerDetails(data.student_id));
  } catch (error) {
    yield put({
      type: learnersActions.ADD_LEARNER_PROFILE_ERROR,
      error: error.data,
    });
  }
}

function* watchUploadLearnerProfile() {
  yield takeLatest(
    learnersActions.ADD_LEARNER_PROFILE_REQUEST,
    uploadLearnerProfile
  );
}

function* updateLearnerProfile({ id, data }) {
  try {
    const response = yield axios({
      url: `/administration/student-details/${id}`,
      method: "PUT",
      data: data,
    });

    yield put({
      type: learnersActions.UPDATE_LEARNER_PROFILE_SUCCESS,
      data: response,
    });
    yield put(learnersActions.getLearnerDetails(data?.student_id));
    yield put(enrollmentActions.getLearnerProfile(data?.student_id));
  } catch (error) {
    yield put({
      type: learnersActions.UPDATE_LEARNER_PROFILE_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateLearnerProfile() {
  yield takeLatest(
    learnersActions.UPDATE_LEARNER_PROFILE_REQUEST,
    updateLearnerProfile
  );
}

function* uploadLearnerDocuments({ id, data }) {
  try {
    const response = yield axios({
      url: `/administration/student-details/documents/${id}`,
      method: "POST",
      data: data,
    });

    yield put({
      type: learnersActions.UPLOAD_LEARNER_DOCUMENTS_SUCCESS,
      data: response,
    });
    yield put(learnersActions.getLearnerDetails(id));
  } catch (error) {
    yield put({
      type: learnersActions.UPLOAD_LEARNER_DOCUMENTS_ERROR,
      error: error.data,
    });
  }
}

function* watchUploadLearnerDocuments() {
  yield takeLatest(
    learnersActions.UPLOAD_LEARNER_DOCUMENTS_REQUEST,
    uploadLearnerDocuments
  );
}

function* uploadLearnerImage({ id, data }) {
  try {
    const response = yield axios({
      url: `/administration/student-details/images/${id}`,
      method: "POST",
      data: data,
    });

    yield put({
      type: learnersActions.UPLOAD_LEARNER_IMAGE_SUCCESS,
      data: response,
    });
    yield put(learnersActions.getLearnerDetails(id));
  } catch (error) {
    yield put({
      type: learnersActions.UPLOAD_LEARNER_IMAGE_ERROR,
      error: error.data,
    });
  }
}

function* watchUploadLearnerImage() {
  yield takeLatest(
    learnersActions.UPLOAD_LEARNER_IMAGE_REQUEST,
    uploadLearnerImage
  );
}

function* deleteLearnerDocument({ id, studentId }) {
  try {
    const response = yield axios({
      url: `/administration/student-details/documents/${id}`,
      method: "DELETE",
    });

    yield put({
      type: learnersActions.DELETE_LEARNER_DOCUMENT_SUCCESS,
      data: response,
    });
    if (studentId) {
      yield put(enrollmentActions.getLearnerProfile(studentId));
    }
  } catch (error) {
    yield put({
      type: learnersActions.DELETE_LEARNER_DOCUMENT_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteLearnerDocument() {
  yield takeLatest(
    learnersActions.DELETE_LEARNER_DOCUMENT_REQUEST,
    deleteLearnerDocument
  );
}

export default [
  fork(watchGetLearners),
  fork(watchUploadLearner),
  fork(watchGetLearnerDetails),
  fork(watchDeleteLearner),
  fork(watchUpdateLearnerDetails),
  fork(watchGetMetaData),
  fork(watchUploadLearnerProfile),
  fork(watchUpdateLearnerProfile),
  fork(watchUploadLearnerDocuments),
  fork(watchUploadLearnerImage),
  fork(watchDeleteLearnerDocument),
];
