import { teacherAssignmentActions } from "../../../actions";
import initialState from "../../../initialState";

const teacherAssignment = (state = initialState.teacherAssignment, actions) => {
  switch (actions.type) {
    case teacherAssignmentActions.GET_TEACHER_ASSIGNMENT_REQUEST:
      return {
        ...state,
        teacherAssignmentLoading: true,
        teacherAssignmentError: {},
      };
    case teacherAssignmentActions.GET_TEACHER_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        teacherAssignmentSuccess: actions.data,
        teacherAssignmentLoading: false,
      };
    case teacherAssignmentActions.GET_TEACHER_ASSIGNMENT_ERROR:
      return {
        ...state,
        teacherAssignmentLoading: false,
        teacherAssignmentError: actions.error,
      };
    case teacherAssignmentActions.REMOVE_TEACHER_ASSIGNMENT_REQUEST:
      return {
        ...state,
        removeTeacherAssignmentLoading: true,
      };
    case teacherAssignmentActions.REMOVE_TEACHER_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        removeTeacherAssignmentSuccess: actions.data,
        removeTeacherAssignmentLoading: false,
      };
    case teacherAssignmentActions.REMOVE_TEACHER_ASSIGNMENT_ERROR:
      return {
        ...state,
        removeTeacherAssignmentLoading: false,
        removeTeacherAssignmentError: actions.error,
      };

    case teacherAssignmentActions.UPDATE_TEACHER_ASSIGNMENT_REQUEST:
      return {
        ...state,
        updateTeacherAssignmentLoading: true,
        updateTeacherAssignmentError: {},
      };
    case teacherAssignmentActions.UPDATE_TEACHER_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        updateTeacherAssignmentSuccess: actions.data,
        updateTeacherAssignmentLoading: false,
      };
    case teacherAssignmentActions.UPDATE_TEACHER_ASSIGNMENT_ERROR:
      return {
        ...state,
        updateTeacherAssignmentLoading: false,
        updateTeacherAssignmentError: actions.error,
      };
    case teacherAssignmentActions.UPDATE_TEACHER_ASSIGNMENT_DATA:
      return {
        ...state,
        updateTeacherAssignmentData: actions.data,
      };
    case teacherAssignmentActions.GET_TEACHER_ASSIGNMENT_DETAILS_REQUEST:
      return {
        ...state,
        teacherAssignmentDetailsLoading: true,
        teacherAssignmentDetailsError: {},
      };
    case teacherAssignmentActions.GET_TEACHER_ASSIGNMENT_DETAILS_SUCCESS:
      return {
        ...state,
        teacherAssignmentDetailsSuccess: actions.data,
        teacherAssignmentDetailsLoading: false,
      };
    case teacherAssignmentActions.GET_TEACHER_ASSIGNMENT_DETAILS_ERROR:
      return {
        ...state,
        teacherAssignmentDetailsLoading: false,
        teacherAssignmentDetailsError: actions.error,
      };
    case teacherAssignmentActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case teacherAssignmentActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: actions.data,
        metaDataLoading: false,
      };
    case teacherAssignmentActions.ADD_TEACHER_ASSIGNMENT_REQUEST:
      return {
        ...state,
        addTeachAssignmentLoading: true,
        addTeachAssignmentError: {},
      };
    case teacherAssignmentActions.ADD_TEACHER_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        addTeachAssignmentSuccess: actions.data,
        addTeachAssignmentLoading: false,
      };
    case teacherAssignmentActions.ADD_TEACHER_ASSIGNMENT_ERROR:
      return {
        ...state,
        addTeachAssignmentLoading: false,
        addTeachAssignmentError: actions.error,
      };
    case teacherAssignmentActions.DELETE_TEACHER_ASSIGNMENT_REQUEST:
      return {
        ...state,
        deleteTeacherAssignmentLoading: true,
        deleteTeacherAssignmentError: {},
      };
    case teacherAssignmentActions.DELETE_TEACHER_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        deleteTeacherAssignmentSuccess: actions.data,
        deleteTeacherAssignmentLoading: false,
      };
    case teacherAssignmentActions.DELETE_TEACHER_ASSIGNMENT_ERROR:
      return {
        ...state,
        deleteTeacherAssignmentLoading: false,
        deleteTeacherAssignmentError: actions.error,
      };
    case teacherAssignmentActions.DELETE_TEACHER_EXAM_PAPER_REQUEST:
      return {
        ...state,
        deleteTeacherExamPaperLoading: true,
        deleteTeacherExamPaperError: {},
      };
    case teacherAssignmentActions.DELETE_TEACHER_EXAM_PAPER_SUCCESS:
      return {
        ...state,
        deleteTeacherExamPaperSuccess: actions.data,
        deleteTeacherExamPaperLoading: false,
      };
    case teacherAssignmentActions.DELETE_TEACHER_EXAM_PAPER_ERROR:
      return {
        ...state,
        deleteTeacherExamPaperLoading: false,
        deleteTeacherExamPaperError: actions.error,
      };
    default:
      return state;
  }
};

export default teacherAssignment;
