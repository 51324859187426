const partnersInitialState = {
  partnersLoading: false,
  partnersSuccess: {},
  partnersError: {},

  addPartnerLoading: false,
  addPartnerSuccess: {},
  addPartnerError: {},

  getPartnerDetailsLoading: false,
  getPartnerDetailsSuccess: {},
  getPartnerDetailsError: {},

  deletePartnerLoading: false,
  deletePartnerSuccess: {},
  deletePartnerError: {},

  updatePartnerDetailsLoading: false,
  updatePartnerDetailsSuccess: {},
  updatePartnerDetailsError: {},
  updatePartnerDetailsData: {},

  checkPartnerLoading: false,
  checkPartnerSuccess: {},
  checkPartnerError: {},

  approvePartnerLoading: false,
  approvePartnerSuccess: {},
  approvePartnerError: {},
};

export default partnersInitialState;
