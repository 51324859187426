import { takeLatest, fork, put } from "redux-saga/effects";
import axios from "axios";
import { subjectsActions } from "../../../actions";

function* getSubjects() {
  try {
    const response = yield axios({
      url: "/administration/subjects",
      method: "GET",
    });
    yield put({
      type: subjectsActions.GET_SUBJECTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subjectsActions.GET_SUBJECTS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetSubjects() {
  yield takeLatest(subjectsActions.GET_SUBJECTS_REQUEST, getSubjects);
}

function* getSubjectsMetaData() {
  try {
    const response = yield axios({
      url: "/administration/subjects/meta-data",
      method: "GET",
    });
    yield put({
      type: subjectsActions.GET_SUBJECTS_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subjectsActions.GET_SUBJECTS_META_DATA_ERROR,
      error: error.data,
    });
  }
}
function* watchGetSubjectsMetaData() {
  yield takeLatest(
    subjectsActions.GET_SUBJECTS_META_DATA_REQUEST,
    getSubjectsMetaData
  );
}

function* addSubject({ data }) {
  try {
    const response = yield axios({
      url: "/administration/subjects",
      method: "POST",
      data: data,
    });
    yield put({
      type: subjectsActions.ADD_SUBJECT_SUCCESS,
      data: response,
    });
    yield put(subjectsActions.getSubjects());
  } catch (error) {
    yield put({
      type: subjectsActions.ADD_SUBJECT_ERROR,
      error: error.data,
    });
  }
}
function* watchAddSubject() {
  yield takeLatest(subjectsActions.ADD_SUBJECT_REQUEST, addSubject);
}

function* deleteSubject({ id }) {
  try {
    const response = yield axios({
      url: `/administration/subjects/${id}`,
      method: "DELETE",
    });

    yield put({
      type: subjectsActions.DELETE_SUBJECT_SUCCESS,
      data: response,
    });
    yield put(subjectsActions.getSubjects());
  } catch (error) {
    yield put({
      type: subjectsActions.DELETE_SUBJECT_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteSubject() {
  yield takeLatest(subjectsActions.DELETE_SUBJECT_REQUEST, deleteSubject);
}

function* updateSubject({ id, data }) {
  try {
    const response = yield axios({
      url: `/administration/subjects/${id}`,
      method: "PUT",
      data: data,
    });

    yield put({
      type: subjectsActions.UPDATE_SUBJECT_DETAILS_SUCCESS,
      data: response,
    });
    yield put(subjectsActions.getSubjects());
  } catch (error) {
    yield put({
      type: subjectsActions.UPDATE_SUBJECT_DETAILS_ERROR,
      error: error.data,
    });
  }
}
function* watchUpdateSubject() {
  yield takeLatest(
    subjectsActions.UPDATE_SUBJECT_DETAILS_REQUEST,
    updateSubject
  );
}

function* getSubjectDetails({ id }) {
  try {
    const response = yield axios({
      url: `/administration/subjects/details/${id}`,
      method: "GET",
    });

    yield put({
      type: subjectsActions.GET_SUBJECT_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subjectsActions.GET_SUBJECT_DETAILS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetSubjectDetails() {
  yield takeLatest(
    subjectsActions.GET_SUBJECT_DETAILS_REQUEST,
    getSubjectDetails
  );
}

function* checkSubject({ id }) {
  try {
    const response = yield axios({
      url: `/administration/subjects/check/${id}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({
      type: subjectsActions.CHECK_SUBJECT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subjectsActions.CHECK_SUBJECT_ERROR,
      error: error.data,
    });
  }
}
function* watchCheckSubject() {
  yield takeLatest(subjectsActions.CHECK_SUBJECT_REQUEST, checkSubject);
}

function* approveSubject({ id }) {
  try {
    const response = yield axios({
      url: `/administration/subjects/approve/${id}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: subjectsActions.APPROVE_SUBJECT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subjectsActions.APPROVE_SUBJECT_ERROR,
      error: error.data,
    });
  }
}
function* watchApproveSubject() {
  yield takeLatest(subjectsActions.APPROVE_SUBJECT_REQUEST, approveSubject);
}

function* createSubjects() {
  try {
    const response = yield axios({
      url: "/administration/subjects/bulk",
      method: "POST",
    });

    yield put({
      type: subjectsActions.CREATE_SUBJECTS_SUCCESS,
      data: response,
    });
    yield put(subjectsActions.getSubjects());
  } catch (error) {
    yield put({
      type: subjectsActions.CREATE_SUBJECTS_ERROR,
      error: error.data,
    });
  }
}

function* watchCreateSubjects() {
  yield takeLatest(subjectsActions.CREATE_SUBJECTS_REQUEST, createSubjects);
}

export default [
  fork(watchGetSubjects),
  fork(watchGetSubjectsMetaData),
  fork(watchAddSubject),
  fork(watchDeleteSubject),
  fork(watchUpdateSubject),
  fork(watchGetSubjectDetails),
  fork(watchCheckSubject),
  fork(watchApproveSubject),
  fork(watchCreateSubjects),
];
