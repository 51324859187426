import { filter, find } from "lodash";
import { brmsComplianceActions } from "../../../actions";
import initialState from "../../../initialState";
import constants from "../../../constants";

const brmsCompliance = (state = initialState.brmsCompliance, actions) => {
  switch (actions.type) {
    case brmsComplianceActions.GET_BRMS_COMPLIANCE_REQUEST:
      return {
        ...state,
        brmsComplianceLoading: true,
        brmsComplianceError: {},
      };
    case brmsComplianceActions.GET_BRMS_COMPLIANCE_SUCCESS:
      return {
        ...state,
        brmsComplianceLoading: false,
        brmsCompliance: actions.data,
      };
    case brmsComplianceActions.GET_BRMS_COMPLIANCE_ERROR:
      return {
        ...state,
        brmsComplianceLoading: false,
        brmsComplianceError: actions.error,
      };
    case brmsComplianceActions.GET_BRMS_COMPLIANCE_DETAILS_REQUEST:
      return {
        ...state,
        brmsComplianceDetailsLoading: true,
        brmsComplianceDetailsError: {},
      };
    case brmsComplianceActions.GET_BRMS_COMPLIANCE_DETAILS_SUCCESS:
      return {
        ...state,
        brmsComplianceDetailsLoading: false,
        brmsComplianceDetails: actions.data,
      };
    case brmsComplianceActions.GET_BRMS_COMPLIANCE_DETAILS_ERROR:
      return {
        ...state,
        brmsComplianceDetailsLoading: false,
        brmsComplianceDetailsError: actions.error,
      };
    case brmsComplianceActions.GET_BRMS_COMPLIANCE_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case brmsComplianceActions.GET_BRMS_COMPLIANCE_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataLoading: false,
        metaData: actions.data,
      };
    case brmsComplianceActions.ADD_BRMS_COMPLIANCE_REQUEST:
      return {
        ...state,
        addBrmsComplianceLoading: true,
        addBrmsComplianceError: {},
      };
    case brmsComplianceActions.ADD_BRMS_COMPLIANCE_SUCCESS:
      return {
        ...state,
        addBrmsComplianceLoading: false,
        addBrmsCompliance: actions.data,
      };
    case brmsComplianceActions.ADD_BRMS_COMPLIANCE_ERROR:
      return {
        ...state,
        addBrmsComplianceLoading: false,
        addBrmsComplianceError: actions.error,
      };
    case brmsComplianceActions.UPDATE_BRMS_COMPLIANCE_REQUEST:
      return {
        ...state,
        updateBrmsComplianceLoading: true,
        updateBrmsComplianceError: {},
      };
    case brmsComplianceActions.UPDATE_BRMS_COMPLIANCE_SUCCESS:
      return {
        ...state,
        updateBrmsCompliance: actions.data,
        updateBrmsComplianceLoading: false,
      };
    case brmsComplianceActions.UPDATE_BRMS_COMPLIANCE_ERROR:
      return {
        ...state,
        updateBrmsComplianceLoading: false,
        updateBrmsComplianceError: actions.error,
      };
    case brmsComplianceActions.UPDATE_BRMS_COMPLIANCE_DATA:
      return {
        ...state,
        updateBrmsComplianceData: actions.data,
      };
    case brmsComplianceActions.DELETE_BRMS_COMPLIANCE_REQUEST:
      return {
        ...state,
        deleteBrmsComplianceLoading: true,
        deleteBrmsComplianceError: {},
      };
    case brmsComplianceActions.DELETE_BRMS_COMPLIANCE_SUCCESS:
      return {
        ...state,
        deleteBrmsComplianceLoading: false,
        deleteBrmsCompliance: actions.data,
      };
    case brmsComplianceActions.DELETE_BRMS_COMPLIANCE_ERROR:
      return {
        ...state,
        deleteBrmsComplianceLoading: false,
        deleteBrmsComplianceError: actions.error,
      };
    case brmsComplianceActions.CHECK_BRMS_COMPLIANCE_REQUEST:
      return {
        ...state,
        checkBrmsComplianceLoading: true,
        checkBrmsComplianceError: {},
      };
    case brmsComplianceActions.CHECK_BRMS_COMPLIANCE_SUCCESS: {
      const brmsCompliances = filter(
        state.brmsCompliance?.brmsCompliance,
        (compliance) => {
          return compliance.id !== actions.id;
        }
      );
      const brmsCompliance = find(state.brmsCompliance?.brmsCompliance, {
        id: actions.id,
      });
      brmsCompliance.status = constants.recordStatus.checked;
      brmsCompliances.unshift(brmsCompliance);
      state.brmsComplianceDetails.status = constants.recordStatus.checked;
      return {
        ...state,
        brmsCompliance: {
          brmsCompliance: brmsCompliances,
        },
        checkBrmsComplianceLoading: false,
        checkBrmsCompliance: actions.data,
      };
    }
    case brmsComplianceActions.CHECK_BRMS_COMPLIANCE_ERROR:
      return {
        ...state,
        checkBrmsComplianceLoading: false,
        checkBrmsComplianceError: actions.error,
      };
    case brmsComplianceActions.APPROVE_BRMS_COMPLIANCE_REQUEST:
      return {
        ...state,
        approveBrmsComplianceLoading: true,
        approveBrmsComplianceError: {},
      };
    case brmsComplianceActions.APPROVE_BRMS_COMPLIANCE_SUCCESS: {
      const brmsCompliances = filter(
        state.brmsCompliance?.brmsCompliance,
        (compliance) => {
          return compliance.id !== actions.id;
        }
      );
      const brmsCompliance = find(state.brmsCompliance?.brmsCompliance, {
        id: actions.id,
      });
      brmsCompliance.status = constants.recordStatus.approved;
      brmsCompliances.unshift(brmsCompliance);
      state.brmsComplianceDetails.status = constants.recordStatus.approved;

      return {
        ...state,
        brmsCompliance: {
          brmsCompliance: brmsCompliances,
        },
        approveBrmsComplianceLoading: false,
        approveBrmsCompliance: actions.data,
      };
    }
    case brmsComplianceActions.APPROVE_BRMS_COMPLIANCE_ERROR:
      return {
        ...state,
        approveBrmsComplianceLoading: false,
        approveBrmsComplianceError: actions.error,
      };

    default:
      return state;
  }
};

export default brmsCompliance;
