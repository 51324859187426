const chartOfAccountsInitialState = {
  chartOfAccounts: {
    chartOfAccountsLoading: false,
    chartOfAccountsSuccess: {},
    chartOfAccountsError: {},

    addAccountTypeLoading: false,
    addAccountTypeSuccess: {},
    addAccountTypeError: {},
    addAccountTypeData: {},

    deleteAccountTypeLoading: false,
    deleteAccountTypeSuccess: {},
    deleteAccountTypeError: {},

    updateAccountTypeData: {},
    updateAccountTypeLoading: false,
    updateAccountTypeSuccess: {},
    updateAccountTypeError: {},

    accountTypeDetailsLoading: false,
    accountTypeDetailsSuccess: {},
    accountTypeDetailsError: {},

    checkAccountTypeLoading: false,
    checkAccountTypeError: {},
    checkAccountTypeSuccess: {},

    approveAccountTypeLoading: false,
    approveAccountTypeSuccess: {},
    approveAccountTypeError: {},

    addAccountData: {},
    addAccountLoading: false,
    addAccountSuccess: {},
    addAccountError: {},

    deleteAccountLoading: false,
    deleteAccountSuccess: {},
    deleteAccountError: {},

    updateAccountData: {},
    updateAccountLoading: false,
    updateAccountSuccess: {},
    updateAccountError: {},

    accountDetailsLoading: false,
    accountDetailsSuccess: {},
    accountDetailsError: {},

    checkAccountLoading: false,
    checkAccountError: {},
    checkAccountSuccess: {},

    approveAccountLoading: false,
    approveAccountSuccess: {},
    approveAccountError: {},

    creatingChartOfAccounts: false,
    createChartOfAccountsError: {},
  },
};

export default chartOfAccountsInitialState;
