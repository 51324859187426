import { storesActions } from "../../../actions";
import storesInitialState from "../../../initialState/assetsAndInventory/stores/stores.initialState";

const stores = (state = storesInitialState, action) => {
  switch (action.type) {
    case storesActions.GET_STORE_ITEMS_REQUEST:
      return {
        ...state,
        storeItemsLoading: true,
      };
    case storesActions.GET_STORE_ITEMS_SUCCESS:
      return {
        ...state,
        storeItemsSuccess: action.data,
        storeItemsError: {},
        storeItemsLoading: false,
      };
    case storesActions.GET_STORE_ITEMS_ERROR:
      return {
        ...state,
        storeItemsLoading: false,
        storeItemsSuccess: action.error,
      };
    case storesActions.ADD_STORE_ITEM_REQUEST:
      return {
        ...state,
        addStoreItemLoading: true,
        addStoreItemError: {},
      };
    case storesActions.ADD_STORE_ITEM_SUCCESS:
      action.data.storesItem.balance = action.data.storesItem.item_quantity;
      return {
        ...state,
        addStoreItemSuccess: action.data,
        storeItemsSuccess: {
          items: [action.data.storesItem, ...state.storeItemsSuccess.items],
        },
        addStoreItemLoading: false,
      };
    case storesActions.ADD_STORE_ITEM_ERROR:
      return {
        ...state,
        addStoreItemLoading: false,
        addStoreItemSuccess: action.error,
      };
    case storesActions.GET_STORE_ITEM_DETAILS_REQUEST:
      return {
        ...state,
        getStoreItemDetailsLoading: true,
      };
    case storesActions.GET_STORE_ITEM_DETAILS_SUCCESS:
      return {
        ...state,
        getStoreItemDetailsSuccess: action.data,
        getStoreItemDetailsError: {},
        getStoreItemDetailsLoading: false,
      };
    case storesActions.GET_STORE_ITEM_DETAILS_ERROR:
      return {
        ...state,
        getStoreItemDetailsLoading: false,
        getStoreItemDetailsSuccess: action.error,
      };
    case storesActions.DELETE_STORE_ITEM_REQUEST:
      return {
        ...state,
        deleteStoreItemLoading: true,
      };
    case storesActions.DELETE_STORE_ITEM_SUCCESS:
      return {
        ...state,
        deleteStoreItemSuccess: action.data,
        storeItemsSuccess: {
          ...state.storeItemsSuccess,
          items: action.items,
        },
        deleteStoreItemLoading: false,
      };
    case storesActions.DELETE_STORE_ITEM_ERROR:
      return {
        ...state,
        deleteStoreItemLoading: false,
        deleteStoreItemSuccess: action.error,
      };
    case storesActions.UPDATE_STORE_ITEM_REQUEST:
      return {
        ...state,
        updateStoreItemLoading: true,
        updateStoreItemError: {},
      };
    case storesActions.UPDATE_STORE_ITEM_SUCCESS:
      return {
        ...state,
        updateStoreItemSuccess: action.data,
        storeItemsSuccess: {
          ...state.storeItemsSuccess,
          stores: action.stores,
        },
        updateStoreItemLoading: false,
      };
    case storesActions.UPDATE_STORE_ITEM_ERROR:
      return {
        ...state,
        updateStoreItemLoading: false,
        updateStoreItemSuccess: action.error,
      };
    case storesActions.SET_UPDATE_STORE_ITEM_DETAILS:
      return {
        ...state,
        updateStoreItemDetails: action.data,
      };
    case storesActions.CHECK_STORE_ITEM_REQUEST:
      return {
        ...state,
        checkStoreItemLoading: true,
      };
    case storesActions.CHECK_STORE_ITEM_SUCCESS:
      return {
        ...state,
        checkStoreItemSuccess: action.data,
        checkStoreItemLoading: false,
      };
    case storesActions.CHECK_STORE_ITEM_ERROR:
      return {
        ...state,
        checkStoreItemLoading: false,
        checkStoreItemSuccess: action.error,
      };
    case storesActions.APPROVE_STORE_ITEM_REQUEST:
      return {
        ...state,
        approveStoreItemLoading: true,
      };
    case storesActions.APPROVE_STORE_ITEM_SUCCESS:
      return {
        ...state,
        approveStoreItemSuccess: action.data,
        approveStoreItemLoading: false,
      };
    case storesActions.APPROVE_STORE_ITEM_ERROR:
      return {
        ...state,
        approveStoreItemLoading: false,
        approveStoreItemSuccess: action.error,
      };
    case storesActions.GET_STORE_ITEMS_META_DATA_REQUEST:
      return {
        ...state,
        getStoreItemsMetaDataLoading: true,
      };
    case storesActions.GET_STORE_ITEMS_META_DATA_SUCCESS:
      return {
        ...state,
        getStoreItemsMetaDataSuccess: action.data,
        getStoreItemsMetaDataError: {},
        getStoreItemsMetaDataLoading: false,
      };
    case storesActions.GET_STORE_ITEMS_META_DATA_ERROR:
      return {
        ...state,
        getStoreItemsMetaDataLoading: false,
        getStoreItemsMetaDataSuccess: action.error,
      };
    case storesActions.GET_STORE_ISSUE_DETAILS_REQUEST:
      return {
        ...state,
        getStoreIssueDetailsLoading: true,
      };
    case storesActions.GET_STORE_ISSUE_DETAILS_SUCCESS:
      return {
        ...state,
        getStoreIssueDetailsSuccess: action.data,
        getStoreIssueDetailsError: {},
        getStoreIssueDetailsLoading: false,
      };
    case storesActions.GET_STORE_ISSUE_DETAILS_ERROR:
      return {
        ...state,
        getStoreIssueDetailsLoading: false,
        getStoreIssueDetailsSuccess: action.error,
      };
    case storesActions.DELETE_STORE_ISSUE_REQUEST:
      return {
        ...state,
        deleteStoreIssueLoading: true,
      };
    case storesActions.DELETE_STORE_ISSUE_SUCCESS:
      return {
        ...state,
        deleteStoreIssueSuccess: action.data,
        deleteStoreIssueLoading: false,
      };
    case storesActions.DELETE_STORE_ISSUE_ERROR:
      return {
        ...state,
        deleteStoreIssueLoading: false,
        deleteStoreIssueSuccess: action.error,
      };
    case storesActions.UPDATE_STORE_ISSUE_REQUEST:
      return {
        ...state,
        updateStoreIssueLoading: true,
      };
    case storesActions.UPDATE_STORE_ISSUE_SUCCESS:
      return {
        ...state,
        updateStoreIssueSuccess: action.data,
        updateStoreIssueLoading: false,
      };
    case storesActions.UPDATE_STORE_ISSUE_ERROR:
      return {
        ...state,
        updateStoreIssueLoading: false,
        updateStoreIssueSuccess: action.error,
      };
    case storesActions.SET_UPDATE_STORE_ISSUE_DETAILS:
      return {
        ...state,
        updateStoreIssueDetails: action.data,
      };
    case storesActions.ADD_STORE_ISSUE_REQUEST:
      return {
        ...state,
        addStoreIssueLoading: true,
        addStoreIssueError: {},
      };
    case storesActions.ADD_STORE_ISSUE_SUCCESS:
      return {
        ...state,
        addStoreIssueSuccess: action.data,
        addStoreIssueLoading: false,
      };
    case storesActions.SET_ADD_STORE_ISSUE_DATA:
      return {
        ...state,
        addStoreIssueData: action.data,
      };
    case storesActions.ADD_STORE_ISSUE_ERROR:
      return {
        ...state,
        addStoreIssueLoading: false,
        addStoreIssueError: action.error,
      };
    case storesActions.CHECK_STORE_ISSUE_REQUEST:
      return {
        ...state,
        checkStoreIssueLoading: true,
      };
    case storesActions.CHECK_STORE_ISSUE_SUCCESS:
      return {
        ...state,
        checkStoreIssueSuccess: action.data,
        checkStoreIssueLoading: false,
      };
    case storesActions.CHECK_STORE_ISSUE_ERROR:
      return {
        ...state,
        checkStoreIssueLoading: false,
        checkStoreIssueSuccess: action.error,
      };
    case storesActions.APPROVE_STORE_ISSUE_REQUEST:
      return {
        ...state,
        approveStoreIssueLoading: true,
      };
    case storesActions.APPROVE_STORE_ISSUE_SUCCESS:
      return {
        ...state,
        approveStoreIssueSuccess: action.data,
        approveStoreIssueLoading: false,
      };
    case storesActions.APPROVE_STORE_ISSUE_ERROR:
      return {
        ...state,
        approveStoreIssueLoading: false,
        approveStoreIssueSuccess: action.error,
      };
    case storesActions.GET_STORE_ISSUES_META_DATA_REQUEST:
      return {
        ...state,
        getStoreIssuesMetaDataLoading: true,
      };
    case storesActions.GET_STORE_ISSUES_META_DATA_SUCCESS:
      return {
        ...state,
        getStoreIssuesMetaDataSuccess: action.data,
        getStoreIssuesMetaDataError: {},
        getStoreIssuesMetaDataLoading: false,
      };
    case storesActions.GET_STORE_ISSUES_META_DATA_ERROR:
      return {
        ...state,
        getStoreIssuesMetaDataLoading: false,
        getStoreIssuesMetaDataSuccess: action.error,
      };

    default:
      return state;
  }
};

export default stores;
