const performanceAnalysisInitialState = {
  performanceAnalysis: {
    classPerformanceAnalysis: {},
    classPerformanceAnalysisLoading: false,
    classPerformanceAnalysisError: {},

    subjectPerformanceAnalysis: {},
    subjectPerformanceAnalysisLoading: false,
    subjectPerformanceAnalysisError: {},

    metaData: {},
    metaDataLoading: false,
    metaDataError: {},
  },
};

export default performanceAnalysisInitialState;
