import { takeLatest, put, fork } from "redux-saga/effects";
import axios from "axios";
import { transferInstitutionActions } from "../../../actions";

function* transferInstitution({ data }) {
    try {
        const response = yield axios({
            url: `/organisations/transfer-institution`,
            method: "POST",
            data,
        });

        yield put({
            type: transferInstitutionActions.TRANSFER_INSTITUTION_SUCCESS,
            data: response,
        });

        window.location.reload();
    } catch (error) {
        yield put({
            type: transferInstitutionActions.TRANSFER_INSTITUTION_ERROR,
            error: error.data,
        });
    }
}

function* watchTransferInstitution() {
    yield takeLatest(
        transferInstitutionActions.TRANSFER_INSTITUTION_REQUEST,
        transferInstitution
    );
}

function* getTransferInstitutionMetaData() {
    try {
        const response = yield axios({
            url: "/organisations/meta-data",
        });

        yield put({
            type: transferInstitutionActions.GET_TRANSFER_INSTITUTION_META_DATA_SUCCESS,
            data: response,
        });
    } catch (error) {
        yield put({
            type: transferInstitutionActions.GET_TRANSFER_INSTITUTION_META_DATA_ERROR,
            data: error.data,
        });
    }
}

function* watchGetTransferInstitutionMetaData() {
    yield takeLatest(
        transferInstitutionActions.GET_TRANSFER_INSTITUTION_META_DATA_REQUEST,
        getTransferInstitutionMetaData
    );
}

export default [fork(watchTransferInstitution),
fork(watchGetTransferInstitutionMetaData)];
