const teacherAssignmentInitialState = {
  teacherAssignment: {
    teacherAssignmentLoading: false,
    teacherAssignmentSuccess: {},
    teacherAssignmentError: {},

    addTeachAssignmentLoading: false,
    addTeachAssignmentSuccess: {},
    addTeachAssignmentError: {},

    metaDataLoading: false,
    metaData: {},

    teacherAssignmentDetailsLoading: false,
    teacherAssignmentDetailsSuccess: {},
    teacherAssignmentDetailsError: {},

    deleteTeacherAssignmentLoading: false,
    deleteTeacherAssignmentSuccess: {},
    deleteTeacherAssignmentError: {},

    deleteTeacherExamPaperLoading: false,
    deleteTeacherExamPaperSuccess: {},
    deleteTeacherExamPaperError: {},

    updateTeacherAssignmentData: {},
    updateTeacherAssignmentSuccess: {},
    updateTeacherAssignmentError: {},
    updateTeacherAssignmentLoading: false,

    removeTeacherAssignmentSuccess: {},
    removeTeacherAssignmentError: {},
    removeTeacherAssignmentLoading: false,
  },
};

export default teacherAssignmentInitialState;
