const academicYears = {
  GET_ACADEMIC_YEARS_REQUEST: "GET_ACADEMIC_YEARS_REQUETS",
  GET_ACADEMIC_YEARS_SUCCESS: "GET_ACADEMIC_YEARS_SUCCESS",
  GET_ACADEMIC_YEARS_ERROR: "GET_ACADEMIC_YEARS_ERROR",

  GET_ACADEMIC_YEAR_DETAILS_REQUEST: "GET_ACADEMIC_YEAR_DETAILS_REQUETS",
  GET_ACADEMIC_YEAR_DETAILS_SUCCESS: "GET_ACADEMIC_YEAR_DETAILS_SUCCESS",
  GET_ACADEMIC_YEAR_DETAILS_ERROR: "GET_ACADEMIC_YEAR_DETAILS_ERROR",

  ADD_ACADEMIC_YEAR_REQUEST: "ADD_ACADEMIC_YEAR_REQUEST",
  ADD_ACADEMIC_YEAR_SUCCESS: "ADD_ACADEMIC_YEAR_SUCCESS",
  ADD_ACADEMIC_YEAR_ERROR: "ADD_ACADEMIC_YEAR_ERROR",

  UPDATE_ACADEMIC_YEAR_REQUEST: "UPDATE_ACADEMIC_YEAR_REQUEST",
  UPDATE_ACADEMIC_YEAR_ERROR: "UPDATE_ACADEMIC_YEAR_ERROR",
  UPDATE_ACADEMIC_YEAR_SUCCESS: "UPDATE_ACADEMIC_YEAR_SUCCESS",
  SET_UPDATE_ACADEMIC_YEAR_DATA: "SET_UPDATE_ACADEMIC_YEAR_DATA",

  DELETE_ACADEMIC_YEAR_REQUEST: "DELETE_ACADEMIC_YEAR_REQUEST",
  DELETE_ACADEMIC_YEAR_SUCCESS: "DELETE_ACADEMIC_YEAR_SUCCESS",
  DELETE_ACADEMIC_YEAR_ERROR: "DELETE_ACADEMIC_YEAR_ERROR",

  CHECK_ACADEMIC_YEAR_REQUEST: "CHECK_ACADEMIC_YEAR_REQUEST",
  CHECK_ACADEMIC_YEAR_ERROR: "CHECK_ACADEMIC_YEAR_ERROR",
  CHECK_ACADEMIC_YEAR_SUCCESS: "CHECK_ACADEMIC_YEAR_SUCCESS",

  APPROVE_ACADEMIC_YEAR_REQUEST: "APPROVE_ACADEMIC_YEAR_REQUEST",
  APPROVE_ACADEMIC_YEAR_SUCCESS: "APPROVE_ACADEMIC_YEAR_SUCCESS",
  APPROVE_ACADEMIC_YEAR_ERROR: "APPROVE_ACADEMIC_YEAR_ERROR",

  GET_TERM_DETAILS_REQUEST: "GET_TERM_DETAILS_REQUEST",
  GET_TERM_DETAILS_SUCCESS: "GET_TERM_DETAILS_SUCCESS",
  GET_TERM_DETAILS_ERROR: "GET_TERM_DETAILS_ERROR",

  DELETE_TERM_REQUEST: "DELETE_TERM_REQUEST",
  DELETE_TERM_SUCCESS: "DELETE_TERM_SUCCESS",
  DELETE_TERM_ERROR: "DELETE_TERM_ERROR",

  getAcademicYears: () => ({
    type: academicYears.GET_ACADEMIC_YEARS_REQUEST,
  }),
  getAcademicYearDetails: (id) => ({
    type: academicYears.GET_ACADEMIC_YEAR_DETAILS_REQUEST,
    id,
  }),
  addAcademicYear: (data) => ({
    type: academicYears.ADD_ACADEMIC_YEAR_REQUEST,
    data,
  }),
  updateAcademicYear: (data, id, newArray) => ({
    type: academicYears.UPDATE_ACADEMIC_YEAR_REQUEST,
    data,
    id,
    academicYears: newArray,
  }),
  setUpdateAcademicYear: (data) => ({
    type: academicYears.SET_UPDATE_ACADEMIC_YEAR_DATA,
    data,
  }),
  deleteAcademicYears: (id, newArray) => ({
    type: academicYears.DELETE_ACADEMIC_YEAR_REQUEST,
    id,
    academicYears: newArray,
  }),
  checkAcademicYear: (id) => ({
    type: academicYears.CHECK_ACADEMIC_YEAR_REQUEST,
    id,
  }),
  approveAcademicYear: (id) => ({
    type: academicYears.APPROVE_ACADEMIC_YEAR_REQUEST,
    id,
  }),
  getTermDetails: (id) => ({
    type: academicYears.GET_TERM_DETAILS_REQUEST,
    id,
  }),
  deleteTerm: (id, params) => ({
    type: academicYears.DELETE_TERM_REQUEST,
    id,
    params,
  }),
};

export default academicYears;
