import { takeLatest, put, fork, takeEvery } from "@redux-saga/core/effects";
import axios from "axios";
import { appUiActions, budgetActions } from "../../../actions";

function* getBudgets() {
  try {
    const response = yield axios({ url: "/finance/budgets" });

    yield put({ type: budgetActions.GET_BUDGETS_SUCCESS, data: response });
  } catch (error) {
    yield put({ type: budgetActions.GET_BUDGETS_ERROR, error: error.data });
  }
}

function* watchGetBudgets() {
  yield takeLatest(budgetActions.GET_BUDGETS_REQUEST, getBudgets);
}

function* getMetaData() {
  try {
    const response = yield axios({ url: "/finance/budgets/meta-data" });

    yield put({ type: budgetActions.GET_META_DATA_SUCCESS, data: response });
  } catch (error) {
    yield put({ type: budgetActions.GET_META_DATA_SUCCESS, data: {} });
  }
}

function* watchGetMetaData() {
  yield takeLatest(budgetActions.GET_META_DATA_REQUEST, getMetaData);
}

function* addBudget({ data }) {
  try {
    const response = yield axios({
      url: "/finance/budgets",
      method: "POST",
      data,
    });

    yield put({ type: budgetActions.ADD_BUDGET_SUCCESS, data: response });

    yield put(appUiActions.toggleAddBudgetsModal(false));
    yield put(appUiActions.toggleBudgetDetailsPage(true));
    yield put(budgetActions.getBudgetDetails(response.id));
  } catch (error) {
    yield put({ type: budgetActions.ADD_BUDGET_ERROR, error: error.data });
  }
}

function* watchAddBudget() {
  yield takeLatest(budgetActions.ADD_BUDGET_REQUEST, addBudget);
}

function* deleteBudget({ data, budgets }) {
  try {
    const response = yield axios({
      url: `/finance/budgets/${data}`,
      method: "DELETE",
    });

    yield put({
      type: budgetActions.DELETE_BUDGET_SUCCESS,
      budgets,
      data: response,
    });
    yield put(budgetActions.getBudgets());
  } catch (error) {
    yield put({ type: budgetActions.DELETE_BUDGET_ERROR, error: error.data });
  }
}

function* watchDeleteBudget() {
  yield takeEvery(budgetActions.DELETE_BUDGET_REQUEST, deleteBudget);
}

function* updateBudget({ budgets, data, id }) {
  try {
    const response = yield axios({
      url: `/finance/budgets/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: budgetActions.UPDATE_BUDGET_SUCCESS,
      budgets,
      data: response,
    });

    yield put(appUiActions.toggleUpdateBudgetModal(false));
  } catch (error) {
    yield put({ type: budgetActions.UPDATE_BUDGET_ERROR, error: error.data });
  }
}

function* watchUpdateBudgets() {
  yield takeLatest(budgetActions.UPDATE_BUDGET_REQUEST, updateBudget);
}

function* getBudgetDetails({ data }) {
  try {
    const response = yield axios({ url: `/finance/budgets/${data}` });

    yield put({
      type: budgetActions.GET_BUDGET_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: budgetActions.GET_BUDGET_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetBudgetDetails() {
  yield takeLatest(budgetActions.GET_BUDGET_DETAILS_REQUEST, getBudgetDetails);
}

function* getBudgetStatements({ data }) {
  try {
    const response = yield axios({
      url: `/finance/budgets/monitoring-statement/${data.id}`,
      params: data,
    });

    yield put({
      type: budgetActions.GET_BUDGET_STATEMENTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: budgetActions.GET_BUDGET_STATEMENTS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetBudgetStatements() {
  yield takeLatest(
    budgetActions.GET_BUDGET_STATEMENTS_REQUEST,
    getBudgetStatements
  );
}

function* addBudgetLine({ data }) {
  try {
    const response = yield axios({
      url: "/finance/budgets/lines",
      data,
      method: "POST",
    });

    yield put({ type: budgetActions.ADD_BUDGET_LINE_SUCCESS, data: response });
    yield put(budgetActions.getBudgetStatements({ id: data.budget_id }));
  } catch (error) {
    yield put({ type: budgetActions.ADD_BUDGET_LINE_ERROR, error: error.data });
  }
}

function* watchAddBudgetLine() {
  yield takeLatest(budgetActions.ADD_BUDGET_LINE_REQUEST, addBudgetLine);
}

function* deleteBudgetLine({ data, budgetId }) {
  try {
    const response = yield axios({
      url: `/finance/budgets/lines/${data}`,
      method: "DELETE",
    });

    yield put({
      type: budgetActions.DELETE_BUDGET_LINE_SUCCESS,
      budgetId,
      data: response,
    });
    yield put(budgetActions.getBudgetStatements(budgetId));
  } catch (error) {
    yield put({
      type: budgetActions.DELETE_BUDGET_LINE_ERROR,
      error: error.data,
    });
  }
}
function* watchDeleteBudgetLine() {
  yield takeEvery(budgetActions.DELETE_BUDGET_LINE_REQUEST, deleteBudgetLine);
}

function* updateBudgetLine({ data, id }) {
  try {
    const response = yield axios({
      url: `/finance/budgets/lines/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: budgetActions.UPDATE_BUDGET_LINE_SUCCESS,
      data: response,
    });
    yield put(budgetActions.getBudgetStatements({ id: data.budget_id }));
    yield put(appUiActions.toggleUpdateBudgetLineModal(false));
  } catch (error) {
    yield put({
      type: budgetActions.UPDATE_BUDGET_LINE_ERROR,
      data: error.data,
    });
  }
}

function* watchUpdateBudgetLine() {
  yield takeLatest(budgetActions.UPDATE_BUDGET_LINE_REQUEST, updateBudgetLine);
}

function* getBudgetLineDetails({ data }) {
  try {
    const response = yield axios({ url: `/finance/budgets/lines/${data}` });

    yield put({
      type: budgetActions.GET_BUDGET_LINE_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: budgetActions.GET_BUDGET_LINE_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetBudgetLineDetails() {
  yield takeLatest(
    budgetActions.GET_BUDGET_LINE_DETAILS_REQUEST,
    getBudgetLineDetails
  );
}

function* addBudgetStream({ data, path }) {
  try {
    const response = yield axios({
      url: `/finance/budgets/${path}`,
      data,
      method: "POST",
    });

    yield put({
      type: budgetActions.ADD_BUDGET_STREAM_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: budgetActions.ADD_BUDGET_STREAM_ERROR,
      error: error.data,
    });
  }
}

function* watchAddBudgetStream() {
  yield takeEvery(budgetActions.ADD_BUDGET_STREAM_REQUEST, addBudgetStream);
}

function* deleteBudgetStream({ id, budget_id, line_id }) {
  try {
    const response = yield axios({
      url: `/finance/budgets/streams/${id}`,
      method: "DELETE",
    });

    yield put({
      type: budgetActions.DELETE_BUDGET_STREAM_SUCCESS,
      data: response,
    });
    if (budget_id) yield put(budgetActions.getBudgetStatements(budget_id));
    if (line_id) yield put(budgetActions.getBudgetLineDetails(line_id));
  } catch (error) {
    yield put({
      type: budgetActions.DELETE_BUDGET_STREAM_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteBudgetStream() {
  yield takeEvery(
    budgetActions.DELETE_BUDGET_STREAM_REQUEST,
    deleteBudgetStream
  );
}

function* updateBudgetStream({ path, data }) {
  try {
    const response = yield axios({
      url: `/finance/budgets/streams/${path}`,
      data,
      method: "PUT",
    });

    yield put({
      type: budgetActions.UPDATE_BUDGET_STREAM_SUCCESS,
      data: response,
    });
    yield put(
      budgetActions.getBudgetStatements({
        id: data.budget_id,
      })
    );
    yield put(appUiActions.toggleUpdateBudgetStreamModal(false));
  } catch (error) {
    yield put({
      type: budgetActions.UPDATE_BUDGET_STREAM_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateBudgetStream() {
  yield takeLatest(
    budgetActions.UPDATE_BUDGET_STREAM_REQUEST,
    updateBudgetStream
  );
}

function* getBudgetStreamDetails({ path }) {
  try {
    const response = yield axios({ url: `/finance/budgets/${path}` });

    yield put({
      type: budgetActions.GET_BUDGET_STREAM_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: budgetActions.GET_BUDGET_STREAM_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetBudgetStreamDetails() {
  yield takeLatest(
    budgetActions.GET_BUDGET_STREAM_DETAILS_REQUEST,
    getBudgetStreamDetails
  );
}

function* submitBudget({ data }) {
  try {
    const response = yield axios({
      url: `/finance/budgets/submit/${data}`,
      method: "PATCH",
    });
    yield put({
      type: budgetActions.SUBMIT_BUDGET_SUCCESS,
      data: response,
    });
    yield put(budgetActions.getBudgetDetails(data));
  } catch (error) {
    yield put({
      type: budgetActions.SUBMIT_BUDGET_ERROR,
      error: error.data,
    });
  }
}

function* watchSubmitBudget() {
  yield takeLatest(budgetActions.SUBMIT_BUDGET_REQUEST, submitBudget);
}

function* checkBudget({ data }) {
  try {
    const response = yield axios({
      url: `/finance/budgets/check/${data}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({ type: budgetActions.CHECK_BUDGET_SUCCESS, data: response });
    yield put(budgetActions.getBudgetDetails(data));
  } catch (error) {
    yield put({
      type: budgetActions.CHECK_BUDGET_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckBudget() {
  yield takeLatest(budgetActions.CHECK_BUDGET_REQUEST, checkBudget);
}

function* approveBudget({ data }) {
  try {
    const response = yield axios({
      url: `/finance/budgets/approve/${data}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({ type: budgetActions.APPROVE_BUDGET_SUCCESS, data: response });
    yield put(budgetActions.getBudgetDetails(data));
  } catch (error) {
    yield put({ type: budgetActions.APPROVE_BUDGET_ERROR, error: error.data });
  }
}

function* watchApproveBudget() {
  yield takeLatest(budgetActions.APPROVE_BUDGET_REQUEST, approveBudget);
}

function* copyBudget({ data }) {
  try {
    const response = yield axios({
      url: "/finance/budgets/copy-budget",
      method: "POST",
      data,
    });

    yield put({ type: budgetActions.COPY_BUDGET_SUCCESS, data: response });
    yield put(budgetActions.getBudgets());
    yield put(appUiActions.toggleCopyBudgetModal(false));
    yield put(appUiActions.toggleBudgetDetailsPage(true));
    yield put(budgetActions.getBudgetDetails(response.id));
    yield put(
      budgetActions.getBudgetStatements({
        id: response.id,
      })
    );
  } catch (error) {
    yield put({
      type: budgetActions.COPY_BUDGET_ERROR,
      error: error.data,
    });
  }
}

function* watchCopyBudget() {
  yield takeLatest(budgetActions.COPY_BUDGET_REQUEST, copyBudget);
}

export default [
  fork(watchGetBudgets),
  fork(watchCopyBudget),
  fork(watchAddBudget),
  fork(watchGetMetaData),
  fork(watchDeleteBudget),
  fork(watchUpdateBudgets),
  fork(watchGetBudgetDetails),
  fork(watchGetBudgetStatements),
  fork(watchAddBudgetLine),
  fork(watchDeleteBudgetLine),
  fork(watchUpdateBudgetLine),
  fork(watchGetBudgetLineDetails),
  fork(watchAddBudgetStream),
  fork(watchDeleteBudgetStream),
  fork(watchUpdateBudgetStream),
  fork(watchGetBudgetStreamDetails),
  fork(watchSubmitBudget),
  fork(watchCheckBudget),
  fork(watchApproveBudget),
];
