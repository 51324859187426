const authInitialState = {
  auth: {
    loggingIn: false,
    loginSuccess: false,
    loginError: {},
    authenticated: false,

    authUser: {},
    gettingAuthUser: false,
    authUserError: {},

    logOutUserLoading: false,
    logOutUserSuccess: {},
    logOutUserError: {},

    signingUp: false,
    signupSuccess: false,
    signupError: {},

    forgotPassword: false,
    forgotPasswordSuccess: false,
    forgotPasswordError: {},

    resetPassword: false,
    resetPasswordSuccess: false,
    resetPasswordError: {},

    confirmAccount: false,
    confirmAccountSuccess: false,
    confirmAccountError: {},
  },
};

export default authInitialState;
