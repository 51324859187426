import { lessonPlansActions } from "../../../actions";
import initialState from "../../../initialState";

const lessonPlans = (state = initialState.lessonPlans, action) => {
  switch (action.type) {
    case lessonPlansActions.GET_LESSON_PLANS_REQUEST:
      return {
        ...state,
        lessonPlansLoading: true,
        lessonPlansError: {},
      };
    case lessonPlansActions.GET_LESSON_PLANS_SUCCESS:
      return {
        ...state,
        lessonPlansSuccess: action.data,
        lessonPlansLoading: false,
      };
    case lessonPlansActions.GET_LESSON_PLANS_ERROR:
      return {
        ...state,
        lessonPlansLoading: false,
        lessonPlansError: action.error,
      };
    case lessonPlansActions.GET_LESSON_PLAN_DETAILS_REQUEST:
      return {
        ...state,
        lessonPlanDetailsLoading: true,
        lessonPlanDetailsError: {},
      };
    case lessonPlansActions.GET_LESSON_PLAN_DETAILS_SUCCESS:
      return {
        ...state,
        lessonPlanDetailsSuccess: action.data,
        lessonPlanDetailsLoading: false,
      };
    case lessonPlansActions.GET_LESSON_PLAN_DETAILS_ERROR:
      return {
        ...state,
        lessonPlanDetailsLoading: false,
        lessonPlanDetailsError: action.error,
      };
    case lessonPlansActions.ADD_LESSON_PLAN_REQUEST:
      return {
        ...state,
        addLessonPlanLoading: true,
        addLessonPlanError: {},
      };
    case lessonPlansActions.ADD_LESSON_PLAN_SUCCESS:
      return {
        ...state,
        addLessonPlanSuccess: action.data,
        addLessonPlanLoading: false,
      };
    case lessonPlansActions.ADD_LESSON_PLAN_ERROR:
      return {
        ...state,
        addLessonPlanLoading: false,
        addLessonPlanError: action.error,
      };
    case lessonPlansActions.UPDATE_LESSON_PLAN_REQUEST:
      return {
        ...state,
        updateLessonPlanLoading: true,
        updateLessonPlanError: {},
      };
    case lessonPlansActions.UPDATE_LESSON_PLAN_SUCCESS:
      return {
        ...state,
        updateLessonPlanSuccess: action.data,
        updateLessonPlanLoading: false,
      };
    case lessonPlansActions.UPDATE_LESSON_PLAN_ERROR:
      return {
        ...state,
        updateLessonPlanLoading: false,
        updateLessonPlanError: action.error,
      };
    case lessonPlansActions.SET_UPDATE_LESSON_PLAN_DATA:
      return {
        ...state,
        setUpdateLessonPlanData: action.data,
      };
    case lessonPlansActions.DELETE_LESSON_PLAN_REQUEST:
      return {
        ...state,
        deleteLessonPlanLoading: true,
        deleteLessonPlanError: {},
      };
    case lessonPlansActions.DELETE_LESSON_PLAN_SUCCESS:
      return {
        ...state,
        deleteLessonPlanSuccess: action.data,
        deleteLessonPlanLoading: false,
        // filter out the deleted lesson plan
        lessonPlansSuccess: {
          ...state.lessonPlansSuccess,
          lessonPlans: state.lessonPlansSuccess?.lessonPlans?.filter(
            (lessonPlan) => lessonPlan.id !== action?.data?.id
          ),
        },
      };
    case lessonPlansActions.DELETE_LESSON_PLAN_ERROR:
      return {
        ...state,
        deleteLessonPlanLoading: false,
        deleteLessonPlanError: action.error,
      };
    case lessonPlansActions.GET_LESSON_PLAN_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case lessonPlansActions.GET_LESSON_PLAN_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: action.data,
        metaDataLoading: false,
      };
    case lessonPlansActions.GET_LESSON_PLAN_META_DATA_ERROR:
      return {
        ...state,
        metaDataLoading: false,
        metaDataError: action.error,
      };
    case lessonPlansActions.CHECK_LESSON_PLAN_REQUEST:
      return {
        ...state,
        checkLessonPlanLoading: true,
        checkLessonPlanError: {},
      };
    case lessonPlansActions.CHECK_LESSON_PLAN_SUCCESS:
      return {
        ...state,
        checkLessonPlanSuccess: action.data,
        checkLessonPlanLoading: false,
      };
    case lessonPlansActions.CHECK_LESSON_PLAN_ERROR:
      return {
        ...state,
        checkLessonPlanLoading: false,
        checkLessonPlanError: action.error,
      };
    case lessonPlansActions.APPROVE_LESSON_PLAN_REQUEST:
      return {
        ...state,
        approveLessonPlanLoading: true,
        approveLessonPlanError: {},
      };
    case lessonPlansActions.APPROVE_LESSON_PLAN_SUCCESS:
      return {
        ...state,
        approveLessonPlanSuccess: action.data,
        approveLessonPlanLoading: false,
      };
    case lessonPlansActions.APPROVE_LESSON_PLAN_ERROR:
      return {
        ...state,
        approveLessonPlanLoading: false,
        approveLessonPlanError: action.error,
      };
    case lessonPlansActions.GET_LESSON_PLAN_PROCEDURE_STAGES_REQUEST:
      return {
        ...state,
        lessonPlanProcedureStagesLoading: true,
      };
    case lessonPlansActions.GET_LESSON_PLAN_PROCEDURE_STAGES_SUCCESS:
      return {
        ...state,
        lessonPlanProcedureStages: action.data,
        lessonPlanProcedureStagesLoading: false,
      };
    case lessonPlansActions.GET_LESSON_PLAN_PROCEDURE_STAGES_ERROR:
      return {
        ...state,
        lessonPlanProcedureStagesLoading: false,
        lessonPlanProcedureStagesError: action.error,
      };
    case lessonPlansActions.ADD_LESSON_PLAN_PROCEDURE_STAGE_REQUEST:
      return {
        ...state,
        addLessonPlanProcedureStageLoading: true,
        addLessonPlanProcedureStageError: {},
      };
    case lessonPlansActions.ADD_LESSON_PLAN_PROCEDURE_STAGE_SUCCESS:
      return {
        ...state,
        addLessonPlanProcedureStageSuccess: action.data,
        addLessonPlanProcedureStageLoading: false,
      };
    case lessonPlansActions.ADD_LESSON_PLAN_PROCEDURE_STAGE_ERROR:
      return {
        ...state,
        addLessonPlanProcedureStageLoading: false,
        addLessonPlanProcedureStageError: action.error,
      };
    case lessonPlansActions.UPDATE_LESSON_PLAN_PROCEDURE_STAGE_REQUEST:
      return {
        ...state,
        updateLessonPlanProcedureStageLoading: true,
        updateLessonPlanProcedureStageError: {},
      };
    case lessonPlansActions.UPDATE_LESSON_PLAN_PROCEDURE_STAGE_SUCCESS:
      return {
        ...state,
        updateLessonPlanProcedureStageSuccess: action.data,
        updateLessonPlanProcedureStageLoading: false,
      };
    case lessonPlansActions.UPDATE_LESSON_PLAN_PROCEDURE_STAGE_ERROR:
      return {
        ...state,
        updateLessonPlanProcedureStageLoading: false,
        updateLessonPlanProcedureStageError: action.error,
      };
    case lessonPlansActions.SET_UPDATE_LESSON_PLAN_PROCEDURE_STAGE_DATA:
      return {
        ...state,
        setUpdateLessonPlanProcedureStageData: action.data,
      };
    case lessonPlansActions.DELETE_LESSON_PLAN_PROCEDURE_STAGE_REQUEST:
      return {
        ...state,
        deleteLessonPlanProcedureStageLoading: true,
        deleteLessonPlanProcedureStageError: {},
      };
    case lessonPlansActions.DELETE_LESSON_PLAN_PROCEDURE_STAGE_SUCCESS:
      return {
        ...state,
        deleteLessonPlanProcedureStageSuccess: action.data,
        deleteLessonPlanProcedureStageLoading: false,
      };
    case lessonPlansActions.DELETE_LESSON_PLAN_PROCEDURE_STAGE_ERROR:
      return {
        ...state,
        deleteLessonPlanProcedureStageLoading: false,
        deleteLessonPlanProcedureStageError: action.error,
      };
    default:
      return state;
  }
};

export default lessonPlans;
