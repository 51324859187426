const learnerIndisciplineInitialState = {
  learnerIndiscipline: {
    indisciplineCasesLoading: false,
    indisciplineCasesSuccess: {},
    indisciplineCasesError: {},

    indisciplineCaseDetailsLoading: false,
    indisciplineCaseDetailsSuccess: {},
    indisciplineCaseDetailsError: {},

    addIndisciplineCaseLoading: false,
    addIndisciplineCaseSuccess: {},
    addIndisciplineCaseError: {},

    updateIndisciplineCaseLoading: false,
    updateIndisciplineCaseSuccess: {},
    updateIndisciplineCaseError: {},

    deleteIndisciplineCaseLoading: false,
    deleteIndisciplineCaseSuccess: {},
    deleteIndisciplineCaseError: {},

    metaData: {},
    metaDataError: {},
    metaDataLoading: false,

    setIndisciplineCaseData: {},
  },
};

export default learnerIndisciplineInitialState;
