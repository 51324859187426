const userProfileInitialState = {
  userProfile: {
    userProfileLoading: false,
    userProfileSuccess: {},
    userProfileError: {},

    updateUserProfileLoading: false,
    updateUserProfileSuccess: {},
    updateUserProfileError: {},

    uploadUserProfileAvatarLoading: false,
    uploadUserProfileAvatarSuccess: {},
    uploadUserProfileAvatarError: {},

    uploadUserSignatureLoading: false,
    uploadUserSignatureSuccess: {},
    uploadUserSignatureError: {},

    changeUserPasswordLoading: false,
    changeUserPasswordSuccess: {},
    changeUserPasswordError: {},

    updateUserEmailLoading: false,
    updateUserEmailSuccess: {},
    updateUserEmailError: {},
    updateUserEmailData: {},
  },
};
export default userProfileInitialState;
