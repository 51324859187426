import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { logDetailsActions } from "../../../actions";

function* getOrganisationLogDetails() {
  try {
    const response = yield axios({
      url: "/settings/log-details/organisation",
    });

    yield put({
      type: logDetailsActions.GET_ORGANISATION_LOG_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: logDetailsActions.GET_ORGANISATION_LOG_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetOrganisationLogDetails() {
  yield takeLatest(
    logDetailsActions.GET_ORGANISATION_LOG_DETAILS_REQUEST,
    getOrganisationLogDetails
  );
}

export default [fork(watchGetOrganisationLogDetails)];
