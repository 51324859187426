const manualsAndPolicies = {
  GET_MANUALS_AND_POLICIES_REQUEST: "GET_MANUALS_AND_POLICIES_REQUEST",
  GET_MANUALS_AND_POLICIES_SUCCESS: "GET_MANUALS_AND_POLICIES_SUCCESS",
  GET_MANUALS_AND_POLICIES_ERROR: "GET_MANUALS_AND_POLICIES_ERROR",

  ADD_MANUALS_AND_POLICIES_REQUEST: "ADD_MANUALS_AND_POLICIES_REQUEST",
  ADD_MANUALS_AND_POLICIES_SUCCESS: "ADD_MANUALS_AND_POLICIES_SUCCESS",
  ADD_MANUALS_AND_POLICIES_ERROR: "ADD_MANUALS_AND_POLICIES_ERROR",

  DELETE_MANUALS_AND_POLICIES_REQUEST: "DELETE_MANUALS_AND_POLICIES_REQUEST",
  DELETE_MANUALS_AND_POLICIES_SUCCESS: "DELETE_MANUALS_AND_POLICIES_SUCCESS",
  DELETE_MANUALS_AND_POLICIES_ERROR: "DELETE_MANUALS_AND_POLICIES_ERROR",

  UPDATE_MANUALS_AND_POLICIES_REQUEST: "UPDATE_MANUALS_AND_POLICIES_REQUEST",
  UPDATE_MANUALS_AND_POLICIES_SUCCESS: "UPDATE_MANUALS_AND_POLICIES_SUCCESS",
  UPDATE_MANUALS_AND_POLICIES_ERROR: "UPDATE_MANUALS_AND_POLICIES_ERROR",
  SET_UPDATE_MANUALS_AND_POLICIES_DATA: "SET_UPDATE_MANUALS_AND_POLICIES_DATA",

  GET_MANUALS_AND_POLICIES_DETAILS_REQUEST:
    "GET_MANUALS_AND_POLICIES_DETAILS_REQUEST",
  GET_MANUALS_AND_POLICIES_DETAILS_SUCCESS:
    "GET_MANUALS_AND_POLICIES_DETAILS_SUCCESS",
  GET_MANUALS_AND_POLICIES_DETAILS_ERROR:
    "GET_MANUALS_AND_POLICIES_DETAILS_ERROR",

  CHECK_MANUALS_AND_POLICIES_REQUEST: "CHECK_MANUALS_AND_POLICIES_REQUEST",
  CHECK_MANUALS_AND_POLICIES_SUCCESS: "CHECK_MANUALS_AND_POLICIES_SUCCESS",
  CHECK_MANUALS_AND_POLICIES_ERROR: "CHECK_MANUALS_AND_POLICIES_ERROR",

  APPROVE_MANUALS_AND_POLICIES_SUCCESS: "APPROVE_MANUALS_AND_POLICIES_SUCCESS",
  APPROVE_MANUALS_AND_POLICIES_REQUEST: "APPROVE_MANUALS_AND_POLICIES_REQUEST",
  APPROVE_MANUALS_AND_POLICIES_ERROR: "APPROVE_MANUALS_AND_POLICIES_ERROR",

  getManualsAndPolicies: () => ({
    type: manualsAndPolicies.GET_MANUALS_AND_POLICIES_REQUEST,
  }),
  addManualsAndPolicies: (data) => ({
    type: manualsAndPolicies.ADD_MANUALS_AND_POLICIES_REQUEST,
    data,
  }),
  deleteManaulsAndPolicies: (data, records) => ({
    type: manualsAndPolicies.DELETE_MANUALS_AND_POLICIES_REQUEST,
    data,
    records,
  }),
  setUpdateManualsAndPoliciesData: (data) => ({
    type: manualsAndPolicies.SET_UPDATE_MANUALS_AND_POLICIES_DATA,
    data,
  }),
  updateManualsAndPolicies: (data, records, id) => ({
    type: manualsAndPolicies.UPDATE_MANUALS_AND_POLICIES_REQUEST,
    records,
    id,
    data,
  }),
  getManualsAndPoliciesDetails: (data) => ({
    type: manualsAndPolicies.GET_MANUALS_AND_POLICIES_DETAILS_REQUEST,
    data,
  }),
  checkManualsAndPolicies: (data) => ({
    type: manualsAndPolicies.CHECK_MANUALS_AND_POLICIES_REQUEST,
    data,
  }),
  approveManualsAndPolicies: (data) => ({
    type: manualsAndPolicies.APPROVE_MANUALS_AND_POLICIES_REQUEST,
    data,
  }),
};

export default manualsAndPolicies;
