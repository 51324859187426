import { takeLatest, takeEvery, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { appUiActions, brmsComplianceActions } from "../../../actions";

function* getBrmsCompliance() {
  try {
    const response = yield axios({ url: "/administration/brms-compliance" });

    yield put({
      type: brmsComplianceActions.GET_BRMS_COMPLIANCE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: brmsComplianceActions.GET_BRMS_COMPLIANCE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetBrmsCompliance() {
  yield takeLatest(
    brmsComplianceActions.GET_BRMS_COMPLIANCE_REQUEST,
    getBrmsCompliance
  );
}

function* getBrmsComplianceDetails({ id }) {
  try {
    const response = yield axios({
      url: `/administration/brms-compliance/${id}`,
    });

    yield put({
      type: brmsComplianceActions.GET_BRMS_COMPLIANCE_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: brmsComplianceActions.GET_BRMS_COMPLIANCE_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetBrmsComplianceDetails() {
  yield takeLatest(
    brmsComplianceActions.GET_BRMS_COMPLIANCE_DETAILS_REQUEST,
    getBrmsComplianceDetails
  );
}

function* getMetaData() {
  try {
    const response = yield axios({
      url: "/administration/brms-compliance/meta-data",
    });

    yield put({
      type: brmsComplianceActions.GET_BRMS_COMPLIANCE_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: brmsComplianceActions.GET_BRMS_COMPLIANCE_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(
    brmsComplianceActions.GET_BRMS_COMPLIANCE_META_DATA_REQUEST,
    getMetaData
  );
}

function* addBrmsCompliance({ data, indicatorCategoryId }) {
  try {
    const response = yield axios({
      url: "/administration/brms-compliance",
      method: "POST",
      data,
    });

    yield put(brmsComplianceActions.getBrmsCompliance());
    yield put({
      type: brmsComplianceActions.ADD_BRMS_COMPLIANCE_SUCCESS,
      data: response,
    });
    yield put(
      brmsComplianceActions.getBrmsComplianceDetails(indicatorCategoryId)
    );
    yield put(appUiActions.toggleAddBrmsComplianceModal(false));
    yield put(appUiActions.toggleBrmsComplianceDetailsModal(true));
  } catch (error) {
    yield put({
      type: brmsComplianceActions.ADD_BRMS_COMPLIANCE_ERROR,
      error: error.data,
    });
  }
}

function* watchAddBrmsCompliance() {
  yield takeLatest(
    brmsComplianceActions.ADD_BRMS_COMPLIANCE_REQUEST,
    addBrmsCompliance
  );
}

function* updateBrmsCompliance({ id, data }) {
  try {
    const response = yield axios({
      url: `/administration/brms-compliance/`,
      method: "PUT",
      data,
    });

    yield put(brmsComplianceActions.getBrmsCompliance());
    yield put(brmsComplianceActions.getBrmsComplianceDetails(id));
    yield put({
      type: brmsComplianceActions.UPDATE_BRMS_COMPLIANCE_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleUpdateBrmsComplianceModal(false));
    yield put(appUiActions.toggleBrmsComplianceDetailsModal(true));
  } catch (error) {
    yield put({
      type: brmsComplianceActions.UPDATE_BRMS_COMPLIANCE_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateBrmsCompliance() {
  yield takeLatest(
    brmsComplianceActions.UPDATE_BRMS_COMPLIANCE_REQUEST,
    updateBrmsCompliance
  );
}

function* deleteBrmsCompliance({ id }) {
  try {
    const response = yield axios({
      url: `/administration/brms-compliance/${id}`,
      method: "DELETE",
    });

    yield put(brmsComplianceActions.getBrmsCompliance());
    yield put({
      type: brmsComplianceActions.DELETE_BRMS_COMPLIANCE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: brmsComplianceActions.DELETE_BRMS_COMPLIANCE_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteBrmsCompliance() {
  yield takeEvery(
    brmsComplianceActions.DELETE_BRMS_COMPLIANCE_REQUEST,
    deleteBrmsCompliance
  );
}

function* checkBrmsComplaince({ id }) {
  try {
    const response = yield axios({
      url: `/administration/brms-compliance/check/${id}`,
      method: "PATCH",
    });

    yield put({
      type: brmsComplianceActions.CHECK_BRMS_COMPLIANCE_SUCCESS,
      data: response,
      id,
    });
  } catch (error) {
    yield put({
      type: brmsComplianceActions.CHECK_BRMS_COMPLIANCE_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckBrmsComplaince() {
  yield takeLatest(
    brmsComplianceActions.CHECK_BRMS_COMPLIANCE_REQUEST,
    checkBrmsComplaince
  );
}

function* approveBrmsCompliance({ id }) {
  try {
    const response = yield axios({
      url: `/administration/brms-compliance/approve/${id}`,
      method: "PATCH",
    });

    yield put({
      type: brmsComplianceActions.APPROVE_BRMS_COMPLIANCE_SUCCESS,
      data: response,
      id,
    });
  } catch (error) {
    yield put({
      type: brmsComplianceActions.APPROVE_BRMS_COMPLIANCE_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveBrmsCompliance() {
  yield takeLatest(
    brmsComplianceActions.APPROVE_BRMS_COMPLIANCE_REQUEST,
    approveBrmsCompliance
  );
}

export default [
  fork(watchGetBrmsCompliance),
  fork(watchGetBrmsComplianceDetails),
  fork(watchGetMetaData),
  fork(watchAddBrmsCompliance),
  fork(watchUpdateBrmsCompliance),
  fork(watchDeleteBrmsCompliance),
  fork(watchCheckBrmsComplaince),
  fork(watchApproveBrmsCompliance),
];
