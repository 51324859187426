import { transferInstitutionActions } from "../../../actions";
import transferInstitutionInitialState from "../../../initialState/settings/transferInstitution/transferInstitution.initialState";

const transferInstitution = (state = transferInstitutionInitialState, actions) => {
    switch (actions.type) {
        case transferInstitutionActions.TRANSFER_INSTITUTION_REQUEST:
            return {
                ...state,
                transferInstitutionLoading: true,
                transferInstitutionError: {},
                transferInstitutionSuccess: {},
            };
        case transferInstitutionActions.TRANSFER_INSTITUTION_SUCCESS:
            return {
                ...state,
                transferInstitutionSuccess: actions.data,
                transferInstitutionLoading: false,
            };
        case transferInstitutionActions.TRANSFER_INSTITUTION_ERROR:
            return {
                ...state,
                transferInstitutionLoading: false,
                transferInstitutionError: actions.error,
            };
        case transferInstitutionActions.GET_TRANSFER_INSTITUTION_META_DATA_REQUEST:
            return {
                ...state,
                transferInstitutionMetaDataLoading: true,
                transferInstitutionMetaDataError: {},
                transferInstitutionMetaDataSuccess: {},
            };
        case transferInstitutionActions.GET_TRANSFER_INSTITUTION_META_DATA_SUCCESS:
            return {
                ...state,
                transferInstitutionMetaDataSuccess: actions.data,
                transferInstitutionMetaDataLoading: false,
            };
        case transferInstitutionActions.GET_TRANSFER_INSTITUTION_META_DATA_ERROR:
            return {
                ...state,
                transferInstitutionMetaDataLoading: false,
                transferInstitutionMetaDataError: actions.error,
            };
        default:
            return state;
    }
}


export default transferInstitution;