import { clientInvoicesActions } from "../../../actions";
import initialState from "../../../initialState";

const clientInvoices = (state = initialState.clientInvoices, action) => {
  switch (action.type) {
    case clientInvoicesActions.GET_CLIENT_INVOICES_REQUEST:
      return {
        ...state,
        clientInvoicesLoading: true,
        clientInvoicesError: {},
      };
    case clientInvoicesActions.GET_CLIENT_INVOICES_SUCCESS:
      return {
        ...state,
        clientInvoices: action.data,
        clientInvoicesLoading: false,
      };
    case clientInvoicesActions.GET_CLIENT_INVOICES_ERROR:
      return {
        ...state,
        clientInvoicesError: action.error,
        clientInvoicesLoading: false,
      };
    case clientInvoicesActions.GET_CLIENT_INVOICE_DETAILS_REQUEST:
      return {
        ...state,
        clientInvoiceDetailsLoading: true,
        clientInvoiceDetailsError: {},
      };
    case clientInvoicesActions.GET_CLIENT_INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        clientInvoiceDetails: action.data,
        clientInvoiceDetailsLoading: false,
      };
    case clientInvoicesActions.GET_CLIENT_INVOICE_DETAILS_ERROR:
      return {
        ...state,
        clientInvoiceDetailsError: action.error,
        clientInvoiceDetailsLoading: false,
      };
    case clientInvoicesActions.ADD_CLIENT_INVOICE_REQUEST:
      return {
        ...state,
        addClientInvoiceLoading: true,
        addClientInvoiceError: {},
      };
    case clientInvoicesActions.ADD_CLIENT_INVOICE_SUCCESS:
      return {
        ...state,
        addClientInvoice: action.data,
        addClientInvoiceLoading: false,
        // pass the new data to the clientInvoices array
        clientInvoices: {
          ...state.clientInvoices,
          clientInvoices: [...state.clientInvoices.clientInvoices, action.data],
        },
      };
    case clientInvoicesActions.ADD_CLIENT_INVOICE_ERROR:
      return {
        ...state,
        addClientInvoiceError: action.error,
        addClientInvoiceLoading: false,
      };
    case clientInvoicesActions.UPDATE_CLIENT_INVOICE_REQUEST:
      return {
        ...state,
        updateClientInvoiceLoading: true,
        updateClientInvoiceError: {},
      };
    case clientInvoicesActions.UPDATE_CLIENT_INVOICE_SUCCESS:
      return {
        ...state,
        updateClientInvoice: action.data,
        updateClientInvoiceLoading: false,
        // pass the updated data to the clientInvoices array and replace the old data with only the updated data
        clientInvoices: {
          ...state.clientInvoices,
          clientInvoices: state.clientInvoices.clientInvoices.map((item) => {
            if (item.id === action.data.id) {
              const updatedItem = {
                ...item,
                ...action.data.data,
              };
              return updatedItem;
            } else {
              return item;
            }
          }),
        },
      };
    case clientInvoicesActions.UPDATE_CLIENT_INVOICE_ERROR:
      return {
        ...state,
        updateClientInvoiceError: action.error,
        updateClientInvoiceLoading: false,
      };
    case clientInvoicesActions.SET_UPDATE_CLIENT_INVOICE_DATA:
      return {
        ...state,
        setUpdateClientInvoiceData: action.data,
      };
    case clientInvoicesActions.DELETE_CLIENT_INVOICE_REQUEST:
      return {
        ...state,
        deleteClientInvoiceLoading: true,
        deleteClientInvoiceError: {},
      };
    case clientInvoicesActions.DELETE_CLIENT_INVOICE_SUCCESS:
      return {
        ...state,
        deleteClientInvoice: action.data,
        deleteClientInvoiceLoading: false,
        // pass the updated data to the clientInvoices array and replace the old data with only the updated data
        clientInvoices: {
          ...state.clientInvoices,
          clientInvoices: state.clientInvoices.clientInvoices.filter(
            (item) => item.id !== action.data.id
          ),
        },
      };
    case clientInvoicesActions.DELETE_CLIENT_INVOICE_ERROR:
      return {
        ...state,
        deleteClientInvoiceError: action.error,
        deleteClientInvoiceLoading: false,
      };
    case clientInvoicesActions.GET_CLIENT_INVOICES_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
        metaDataError: {},
      };
    case clientInvoicesActions.GET_CLIENT_INVOICES_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: action.data,
        metaDataLoading: false,
      };
    case clientInvoicesActions.GET_CLIENT_INVOICES_META_DATA_ERROR:
      return {
        ...state,
        metaDataError: action.error,
        metaDataLoading: false,
      };
    default:
      return state;
  }
};

export default clientInvoices;
