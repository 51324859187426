import React from "react";
import { BackTop } from "antd";

const style = {
  height: 40,
  width: 40,
  lineHeight: "40px",
  borderRadius: 2,
  backgroundColor: "#1088e9",
  color: "#fff",
  textAlign: "center",
  fontSize: 14,
};

function AppFooter() {
  return (
    <div className="container-fluid">
      <div className="footer">
        <a href="http://gatesandbytesltd.com">
          {" "}
          SCHOOL BOOKS ©2022 Created by Gates & Bytes LTD
        </a>
        <BackTop visibilityHeight={100}>
          <div style={style}>UP</div>
        </BackTop>
      </div>
    </div>
  );
}

export default AppFooter;
