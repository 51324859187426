const grading = {
  GET_GRADINGS_REQUEST: "GET_GRADINGS_REQUEST",
  GET_GRADINGS_SUCCESS: "GET_GRADINGS_SUCCESS",
  GET_GRADINGS_ERROR: "GET_GRADINGS_ERROR",

  ADD_GRADING_REQUEST: "ADD_GRADING_REQUEST",
  ADD_GRADING_SUCCESS: "ADD_GRADING_SUCCESS",
  ADD_GRADING_ERROR: "ADD_GRADING_ERROR",

  GET_GRADING_DETAILS_REQUEST: "GET_GRADING_DETAILS_REQUEST",
  GET_GRADING_DETAILS_SUCCESS: "GET_GRADING_DETAILS_SUCCESS",
  GET_GRADING_DETAILS_ERROR: "GET_GRADING_DETAILS_ERROR",

  UPDATE_GRADING_DETAILS_REQUEST: "UPDATE_GRADING_DETAILS_REQUEST",
  UPDATE_GRADING_DETAILS_SUCCESS: "UPDATE_GRADING_DETAILS_SUCCESS",
  UPDATE_GRADING_DETAILS_ERROR: "UPDATE_GRADING_DETAILS_ERROR",

  DELETE_GRADING_REQUEST: "DELETE_GRADING_REQUEST",
  DELETE_GRADING_SUCCESS: "DELETE_GRADING_SUCCESS",
  DELETE_GRADING_ERROR: "DELETE_GRADING_ERROR",

  DELETE_GRADING_SCORE_REQUEST: "DELETE_GRADING_SCORE_REQUEST",
  DELETE_GRADING_SCORE_SUCCESS: "DELETE_GRADING_SCORE_SUCCESS",
  DELETE_GRADING_SCORE_ERROR: "DELETE_GRADING_SCORE_ERROR",

  SET_UPDATE_GRADING_DETAILS_DATA: "SET_UPDATE_GRADING_DETAILS_DATA",

  CHECK_GRADING_REQUEST: "CHECK_GRADING_REQUEST",
  CHECK_GRADING_SUCCESS: "CHECK_GRADING_SUCCESS",
  CHECK_GRADING_ERROR: "CHECK_GRADING_ERROR",

  APPROVE_GRADING_REQUEST: "APPROVE_GRADING_REQUEST",
  APPROVE_GRADING_SUCCESS: "APPROVE_GRADING_SUCCESS",
  APPROVE_GRADING_ERROR: "APPROVE_GRADING_ERROR",

  ADD_ALEVEL_GRADING_REQUEST: "ADD_ALEVEL_GRADING_REQUEST",
  ADD_ALEVEL_GRADING_SUCCESS: "ADD_ALEVEL_GRADING_SUCCESS",
  ADD_ALEVEL_GRADING_ERROR: "ADD_ALEVEL_GRADING_ERROR",

  UPDATE_ALEVEL_GRADING_REQUEST: "UPDATE_ALEVEL_GRADING_REQUEST",
  UPDATE_ALEVEL_GRADING_SUCCESS: "UPDATE_ALEVEL_GRADING_SUCCESS",
  UPDATE_ALEVEL_GRADING_ERROR: "UPDATE_ALEVEL_GRADING_ERROR",
  UPDATE_ALEVEL_GRADING_DATA: "UPDATE_ALEVEL_GRADING_DATA",

  DELETE_ALEVEL_GRADING_REQUEST: "DELETE_ALEVEL_GRADING_REQUEST",
  DELETE_ALEVEL_GRADING_SUCCESS: "DELETE_ALEVEL_GRADING_SUCCESS",
  DELETE_ALEVEL_GRADING_ERROR: "DELETE_ALEVEL_GRADING_ERROR",

  ADD_DIVISIONS_REQUEST: "ADD_DIVISIONS_REQUEST",
  ADD_DIVISIONS_SUCCESS: "ADD_DIVISIONS_SUCCESS",
  ADD_DIVISIONS_ERROR: "ADD_DIVISIONS_ERROR",

  UPDATE_DIVISION_REQUEST: "UPDATE_DIVISION_REQUEST",
  UPDATE_DIVISION_SUCCESS: "UPDATE_DIVISION_SUCCESS",
  UPDATE_DIVISION_ERROR: "UPDATE_DIVISION_ERROR",

  DELETE_DIVISION_REQUEST: "DELETE_DIVISION_REQUEST",
  DELETE_DIVISION_SUCCESS: "DELETE_DIVISION_SUCCESS",
  DELETE_DIVISION_ERROR: "DELETE_DIVISION_ERROR",

  SET_UPDATE_DIVISION_DATA: "SET_UPDATE_DIVISION_DATA",

  ADD_COMPETENCY_GRADING_SCALE_REQUEST: "ADD_COMPETENCY_GRADING_SCALE_REQUEST",
  ADD_COMPETENCY_GRADING_SCALE_SUCCESS: "ADD_COMPETENCY_GRADING_SCALE_SUCCESS",
  ADD_COMPETENCY_GRADING_SCALE_ERROR: "ADD_COMPETENCY_GRADING_SCALE_ERROR",

  UPDATE_COMPETENCY_GRADING_SCALE_REQUEST:
    "UPDATE_COMPETENCY_GRADING_SCALE_REQUEST",
  UPDATE_COMPETENCY_GRADING_SCALE_SUCCESS:
    "UPDATE_COMPETENCY_GRADING_SCALE_SUCCESS",
  UPDATE_COMPETENCY_GRADING_SCALE_ERROR:
    "UPDATE_COMPETENCY_GRADING_SCALE_ERROR",
  SET_UPDATE_COMPETENCY_GRADING_SCALE_DATA:
    "SET_UPDATE_COMPETENCY_GRADING_SCALE_DATA",

  DELETE_COMPETENCY_GRADING_SCALE_REQUEST:
    "DELETE_COMPETENCY_GRADING_SCALE_REQUEST",
  DELETE_COMPETENCY_GRADING_SCALE_SUCCESS:
    "DELETE_COMPETENCY_GRADING_SCALE_SUCCESS",
  DELETE_COMPETENCY_GRADING_SCALE_ERROR:
    "DELETE_COMPETENCY_GRADING_SCALE_ERROR",

  getGradings: () => ({
    type: grading.GET_GRADINGS_REQUEST,
  }),
  addAlevelGrading: (data) => ({
    type: grading.ADD_ALEVEL_GRADING_REQUEST,
    data,
  }),
  updateAlevelGrading: (data, id) => ({
    type: grading.UPDATE_ALEVEL_GRADING_REQUEST,
    data,
    id,
  }),
  setAlevelGradingData: (data) => ({
    type: grading.UPDATE_ALEVEL_GRADING_DATA,
    data,
  }),
  deleteAlevelGrading: (id, data) => ({
    type: grading.DELETE_ALEVEL_GRADING_REQUEST,
    id,
    data,
  }),
  addGrading: (data) => ({
    type: grading.ADD_GRADING_REQUEST,
    data,
  }),

  getGradingDetails: (id) => ({
    type: grading.GET_GRADING_DETAILS_REQUEST,
    id,
  }),

  updateGradingDetails: (id, data) => ({
    type: grading.UPDATE_GRADING_DETAILS_REQUEST,
    id,
    data,
  }),

  deleteGrading: (id) => ({
    type: grading.DELETE_GRADING_REQUEST,
    id,
  }),

  deleteGradingScore: (id, gradingId) => ({
    type: grading.DELETE_GRADING_SCORE_REQUEST,
    id,
    gradingId,
  }),

  setUpdateGradingDetailsData: (data) => ({
    type: grading.SET_UPDATE_GRADING_DETAILS_DATA,
    data,
  }),

  checkGrading: (id) => ({
    type: grading.CHECK_GRADING_REQUEST,
    id,
  }),

  approveGrading: (id) => ({
    type: grading.APPROVE_GRADING_REQUEST,
    id,
  }),
  addDivisions: (data, id) => ({
    type: grading.ADD_DIVISIONS_REQUEST,
    data,
    id,
  }),
  updateDivision: (data, id, gradingId) => ({
    type: grading.UPDATE_DIVISION_REQUEST,
    data,
    id,
    gradingId,
  }),
  setUpdateDivisionData: (data) => ({
    type: grading.SET_UPDATE_DIVISION_DATA,
    data,
  }),
  deleteDivision: (id, gradingId) => ({
    type: grading.DELETE_DIVISION_REQUEST,
    id,
    gradingId,
  }),
  addCompetencyGradingScale: (data, gradingId) => ({
    type: grading.ADD_COMPETENCY_GRADING_SCALE_REQUEST,
    data,
    gradingId,
  }),
  updateCompetencyGradingScale: (data, id, gradingId) => ({
    type: grading.UPDATE_COMPETENCY_GRADING_SCALE_REQUEST,
    data,
    id,
    gradingId,
  }),
  deleteCompetencyGradingScale: (id, gradingId) => ({
    type: grading.DELETE_COMPETENCY_GRADING_SCALE_REQUEST,
    id,
    gradingId,
  }),
  setUpdateCompetencyGradingScaleData: (data) => ({
    type: grading.SET_UPDATE_COMPETENCY_GRADING_SCALE_DATA,
    data,
  }),
};

export default grading;
