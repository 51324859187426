const bursariesInitialState = {
  bursaries: {
    bursariesSuccess: {},
    bursariesError: {},
    bursariesLoading: false,

    bursaryDetailsSuccess: {},
    bursaryDetailsError: {},
    bursaryDetailsLoading: false,

    addBursarySuccess: {},
    addBursaryError: {},
    addBursaryLoading: false,

    updateBursaryData: {},
    updateBursarySuccess: {},
    updateBursaryError: {},
    updateBursaryLoading: false,

    deleteBursarySuccess: {},
    deleteBursaryError: {},
    deleteBursaryLoading: false,

    checkBursarySuccess: {},
    checkBursaryError: {},
    checkBursaryLoading: false,

    approveBursarySuccess: {},
    approveBursaryError: {},
    approveBursaryLoading: false,

    deleteBursaryCostSuccess: {},
    deleteBursaryCostError: {},
    deleteBursaryCostLoading: false,

    updateBursaryCostSuccess: {},
    updateBursaryCostError: {},
    updateBursaryCostLoading: false,
    updateBursaryCostData: {},

    bursaryCostDetailsSuccess: {},
    bursaryCostDetailsError: {},
    bursaryCostDetailsLoading: false,

    metaDataLoading: false,
    metaData: {},
  },
};

export default bursariesInitialState;
