import { gradingActions } from "../../../actions";
import gradingInitialState from "../../../initialState/academics/grading/grading.initialState";

const grading = (state = gradingInitialState, action) => {
  switch (action.type) {
    case gradingActions.GET_GRADINGS_REQUEST:
      return {
        ...state,
        gradingsLoading: true,
      };
    case gradingActions.GET_GRADINGS_SUCCESS:
      return {
        ...state,
        gradingsSuccess: action.data,
        gradingsError: {},
        gradingsLoading: false,
      };
    case gradingActions.GET_GRADINGS_ERROR:
      return {
        ...state,
        gradingsError: action.error,
        gradingsLoading: false,
      };
    case gradingActions.ADD_ALEVEL_GRADING_REQUEST:
      return {
        ...state,
        addAlevelGradingLoading: true,
        addAlevelGradingError: {},
      };
    case gradingActions.ADD_ALEVEL_GRADING_SUCCESS:
      return {
        ...state,
        addAlevelGradingSuccess: action.data,
        addAlevelGradingLoading: false,
      };
    case gradingActions.ADD_ALEVEL_GRADING_ERROR:
      return {
        ...state,
        addAlevelGradingError: action.error,
        addAlevelGradingLoading: false,
      };
    case gradingActions.UPDATE_ALEVEL_GRADING_REQUEST:
      return {
        ...state,
        updateAlevelGradingLoading: true,
        updateAlevelGradingError: {},
      };
    case gradingActions.UPDATE_ALEVEL_GRADING_SUCCESS:
      return {
        ...state,
        updateAlevelGradingSuccess: action.data,
        updateAlevelGradingLoading: false,
      };
    case gradingActions.UPDATE_ALEVEL_GRADING_ERROR:
      return {
        ...state,
        updateAlevelGradingError: action.error,
        updateAlevelGradingLoading: false,
      };
    case gradingActions.UPDATE_ALEVEL_GRADING_DATA:
      return {
        ...state,
        updateAlevelGradingData: action.data,
      };
    case gradingActions.DELETE_ALEVEL_GRADING_REQUEST:
      return {
        ...state,
        deleteAlevelGradingLoading: true,
        deleteAlevelGradingError: {},
      };
    case gradingActions.DELETE_ALEVEL_GRADING_SUCCESS:
      return {
        ...state,
        deleteAlevelGradingSuccess: action.data,
        deleteAlevelGradingLoading: false,
      };
    case gradingActions.DELETE_ALEVEL_GRADING_ERROR:
      return {
        ...state,
        deleteAlevelGradingError: action.error,
        deleteAlevelGradingLoading: false,
      };
    case gradingActions.ADD_GRADING_REQUEST:
      return {
        ...state,
        addGradingLoading: true,
      };
    case gradingActions.ADD_GRADING_SUCCESS:
      return {
        ...state,
        addGradingSuccess: action.data,
        addGradingLoading: false,
      };
    case gradingActions.ADD_GRADING_ERROR:
      return {
        ...state,
        addGradingLoading: false,
        addGradingError: action.error,
      };
    case gradingActions.GET_GRADING_DETAILS_REQUEST:
      return {
        ...state,
        getGradingDetailsLoading: true,
      };
    case gradingActions.GET_GRADING_DETAILS_SUCCESS:
      return {
        ...state,
        getGradingDetailsSuccess: action.data,
        getGradingDetailsError: {},
        getGradingDetailsLoading: false,
      };
    case gradingActions.GET_GRADING_DETAILS_ERROR:
      return {
        ...state,
        getGradingDetailsError: action.error,
        getGradingDetailsLoading: false,
      };
    case gradingActions.UPDATE_GRADING_DETAILS_REQUEST:
      return {
        ...state,
        updateGradingDetailsLoading: true,
      };
    case gradingActions.UPDATE_GRADING_DETAILS_SUCCESS:
      return {
        ...state,
        updateGradingDetailsSuccess: action.data,
        updateGradingDetailsError: {},
        updateGradingDetailsLoading: false,
      };
    case gradingActions.UPDATE_GRADING_DETAILS_ERROR:
      return {
        ...state,
        updateGradingDetailsError: action.error,
        updateGradingDetailsLoading: false,
      };

    case gradingActions.DELETE_GRADING_REQUEST:
      return {
        ...state,
        deleteGradingLoading: true,
      };
    case gradingActions.DELETE_GRADING_SUCCESS:
      return {
        ...state,
        deleteGradingSuccess: action.data,
        deleteGradingError: {},
        deleteGradingLoading: false,
      };
    case gradingActions.DELETE_GRADING_ERROR:
      return {
        ...state,
        deleteGradingError: action.error,
        deleteGradingLoading: false,
      };

    case gradingActions.DELETE_GRADING_SCORE_REQUEST:
      return {
        ...state,
        deleteGradingScoreLoading: true,
      };
    case gradingActions.DELETE_GRADING_SCORE_SUCCESS:
      return {
        ...state,
        deleteGradingScoreSuccess: action.data,
        deleteGradingScoreError: {},
        deleteGradingScoreLoading: false,
      };
    case gradingActions.DELETE_GRADING_SCORE_ERROR:
      return {
        ...state,
        deleteGradingScoreError: action.error,
        deleteGradingScoreLoading: false,
      };
    case gradingActions.CHECK_GRADING_REQUEST:
      return {
        ...state,
        checkGradingLoading: true,
      };
    case gradingActions.CHECK_GRADING_SUCCESS:
      return {
        ...state,
        checkGradingSuccess: action.data,
        checkGradingError: {},
        checkGradingLoading: false,
      };
    case gradingActions.CHECK_GRADING_ERROR:
      return {
        ...state,
        checkGradingError: action.error,
        checkGradingLoading: false,
      };
    case gradingActions.APPROVE_GRADING_REQUEST:
      return {
        ...state,
        approveGradingLoading: true,
      };
    case gradingActions.APPROVE_GRADING_SUCCESS:
      return {
        ...state,
        approveGradingSuccess: action.data,
        approveGradingError: {},
        approveGradingLoading: false,
      };
    case gradingActions.APPROVE_GRADING_ERROR:
      return {
        ...state,
        approveGradingError: action.error,
        approveGradingLoading: false,
      };

    case gradingActions.SET_UPDATE_GRADING_DETAILS_DATA:
      return {
        ...state,
        updateGradingDetailsData: action.data,
      };
    case gradingActions.ADD_DIVISIONS_REQUEST:
      return {
        ...state,
        addDivisionsLoading: true,
        addDivisionsError: {},
      };
    case gradingActions.ADD_DIVISIONS_SUCCESS:
      return {
        ...state,
        addDivisionsSuccess: action.data,
        addDivisionsLoading: false,
      };
    case gradingActions.ADD_DIVISIONS_ERROR:
      return {
        ...state,
        addDivisionsError: action.error,
        addDivisionsLoading: false,
      };
    case gradingActions.UPDATE_DIVISION_REQUEST:
      return {
        ...state,
        updateDivisionLoading: true,
        updateDivisionError: {},
      };
    case gradingActions.UPDATE_DIVISION_SUCCESS:
      return {
        ...state,
        updateDivisionSuccess: action.data,
        updateDivisionLoading: false,
      };
    case gradingActions.UPDATE_DIVISION_ERROR:
      return {
        ...state,
        updateDivisionError: action.error,
        updateDivisionLoading: false,
      };
    case gradingActions.DELETE_DIVISION_REQUEST:
      return {
        ...state,
        deleteDivisionLoading: true,
        deleteDivisionError: {},
      };
    case gradingActions.DELETE_DIVISION_SUCCESS:
      return {
        ...state,
        deleteDivisionSuccess: action.data,
        deleteDivisionLoading: false,
      };
    case gradingActions.SET_UPDATE_DIVISION_DATA:
      return {
        ...state,
        updateDivisionData: action.data,
      };
    case gradingActions.DELETE_DIVISION_ERROR:
      return {
        ...state,
        deleteDivisionError: action.error,
        deleteDivisionLoading: false,
      };
    case gradingActions.ADD_COMPETENCY_GRADING_SCALE_REQUEST:
      return {
        ...state,
        addingCompetencyGradingScaleLoading: true,
        addingCompetencyGradingScaleError: {},
      };
    case gradingActions.ADD_COMPETENCY_GRADING_SCALE_SUCCESS:
      return {
        ...state,
        addingCompetencyGradingScaleSuccess: action.data,
        addingCompetencyGradingScaleLoading: false,
      };
    case gradingActions.ADD_COMPETENCY_GRADING_SCALE_ERROR:
      return {
        ...state,
        addingCompentencyGradingScaleError: action.error,
        addingCompetencyGradingScaleLoading: false,
      };
    case gradingActions.UPDATE_COMPETENCY_GRADING_SCALE_REQUEST:
      return {
        ...state,
        updatingCompetencyGradingScaleLoading: true,
        updatingCompetencyGradingScaleError: {},
      };
    case gradingActions.UPDATE_COMPETENCY_GRADING_SCALE_SUCCESS:
      return {
        ...state,
        updatingCompetencyGradingScaleSuccess: action.data,
        updatingCompetencyGradingScaleLoading: false,
      };
    case gradingActions.UPDATE_COMPETENCY_GRADING_SCALE_ERROR:
      return {
        ...state,
        updatingCompetencyGradingScaleError: action.error,
        updatingCompetencyGradingScaleLoading: false,
      };
    case gradingActions.SET_UPDATE_COMPETENCY_GRADING_SCALE_DATA:
      return {
        ...state,
        updatingCompetencyGradingScaleData: action.data,
      };
    case gradingActions.DELETE_COMPETENCY_GRADING_SCALE_REQUEST:
      return {
        ...state,
        deletingCompetencyGradingScaleLoading: true,
        deletingCompetencyGradingError: {},
      };
    case gradingActions.DELETE_COMPETENCY_GRADING_SCALE_SUCCESS:
      return {
        ...state,
        deletingCompetencyGradingScaleSuccess: action.data,
        deletingCompetencyGradingScaleLoading: false,
      };
    case gradingActions.DELETE_COMPETENCY_GRADING_SCALE_ERROR:
      return {
        ...state,
        deletingCompetencyGradingError: action.error,
        deletingCompetencyGradingScaleLoading: false,
      };

    default:
      return state;
  }
};

export default grading;
