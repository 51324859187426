import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { organisationActions, subscriptionsActions } from "../../actions";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "../../services/storage.service";

function* getOrganisations() {
  try {
    const response = yield axios({
      url: "/organisations/user-organisations",
      method: "GET",
    });

    yield put({
      type: organisationActions.GET_ORGANISATIONS_SUCCESS,
      data: response,
    });

    const organisation = getLocalStorageItem("organisation");

    response?.userOrganisations?.length > 0 &&
      !organisation &&
      setLocalStorageItem("organisation", response?.userOrganisations?.[0]?.id);

    response?.userOrganisations?.length < 1 &&
      removeLocalStorageItem("organisation");
    yield put(subscriptionsActions.getSubscriptionPlanRequest());
  } catch (error) {
    yield put({
      type: organisationActions.GET_ORGANISATIONS_ERROR,
      data: error.data,
    });
  }
}

function* watchGetOrganisations() {
  yield takeLatest(
    organisationActions.GET_ORGANISATIONS_REQUEST,
    getOrganisations
  );
}

function* addOrganisation(actions) {
  try {
    const response = yield axios({
      url: "/organisations",
      method: "POST",
      data: actions.data,
    });

    yield put({
      type: organisationActions.ADD_ORGANISATION_SUCCESS,
      data: response,
    });
    const organisation = getLocalStorageItem("organisation");

    !organisation &&
      setLocalStorageItem("organisation", response?.organisation?.id);
  } catch (error) {
    yield put({
      type: organisationActions.ADD_ORGANISATION_ERROR,
      error: error.data,
    });
  }
}

function* watchAddOrganisation() {
  yield takeLatest(
    organisationActions.ADD_ORGANISATION_REQUEST,
    addOrganisation
  );
}

function* updateOrganisationName({ data, id }) {
  try {
    const response = yield axios({
      url: `/organisations/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: organisationActions.UPDATE_ORGANISATION_NAME_SUCCESS,
      data: response,
    });
    yield put({
      type: organisationActions.GET_ORGANISATIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: organisationActions.UPDATE_ORGANISATION_NAME_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateOrganisationName() {
  yield takeLatest(
    organisationActions.UPDATE_ORGANISATION_NAME_REQUEST,
    updateOrganisationName
  );
}

export default [
  fork(watchGetOrganisations),
  fork(watchAddOrganisation),
  fork(watchUpdateOrganisationName),
];
