import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { subscriptionsActions } from "../../actions";

function* getSubscriptionPlans() {
  try {
    const response = yield axios({ url: "/subscriptions/plan", method: "GET" });

    yield put({
      type: subscriptionsActions.GET_SUBSCRIPTION_PLAN_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subscriptionsActions.GET_SUBSCRIPTION_PLAN_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSubscriptionPlans() {
  yield takeLatest(
    subscriptionsActions.GET_SUBSCRIPTION_PLANS_REQUEST,
    getSubscriptionPlans
  );
}

function* addSubscription(actions) {
  try {
    const response = yield axios({
      url: "/subscriptions",
      method: "POST",
      data: actions.data,
    });

    yield put({
      type: subscriptionsActions.ADD_SUBSCRIPTION_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subscriptionsActions.ADD_SUBSCRIPTION_ERROR,
      error: error.data,
    });
  }
}

function* watchAddSubscription() {
  yield takeLatest(
    subscriptionsActions.ADD_SUBSCRIPTION_REQUEST,
    addSubscription
  );
}

function* getSubscriptions(actions) {
  try {
    const response = yield axios({
      url: `/subscriptions/organisations-subscriptions/${actions.data}`,
      method: "GET",
    });

    yield put({
      type: subscriptionsActions.GET_SUBSCRIPTIONS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subscriptionsActions.GET_SUBSCRIPTIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSubscriptions() {
  yield takeLatest(
    subscriptionsActions.GET_SUBSCRIPTIONS_REQUEST,
    getSubscriptions
  );
}

function* addSubscriptionPayment(actions) {
  try {
    const response = yield axios({
      url: "/payments/mobile-money",
      method: "POST",
      data: actions.data,
    });

    yield put({
      type: subscriptionsActions.ADD_SUBSCRIPTION_PAYMENT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subscriptionsActions.ADD_SUBSCRIPTION_PAYMENT_ERROR,
      error: error.data,
    });
  }
}

function* watchAddSubscriptionPayment() {
  yield takeLatest(
    subscriptionsActions.ADD_SUBSCRIPTION_PAYMENT_REQUEST,
    addSubscriptionPayment
  );
}

function* getLatestSubscription(actions) {
  try {
    const response = yield axios({
      url: `/subscriptions/latest-subscription/${actions.data}`,
      method: "GET",
    });

    yield put({
      type: subscriptionsActions.GET_LATEST_SUBSCRIPTIONS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subscriptionsActions.GET_LATEST_SUBSCRIPTIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetLatestSubscription() {
  yield takeLatest(
    subscriptionsActions.GET_LATEST_SUBSCRIPTIONS_REQUEST,
    getLatestSubscription
  );
}

function* cancelPendingSubscription(actions) {
  try {
    const response = yield axios({
      url: `/subscriptions/failed-payment/${actions.data}`,
      method: "GET",
    });

    yield put({
      type: subscriptionsActions.CANCEL_PENDING_SUBSCRIPTION_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subscriptionsActions.CANCEL_PENDING_SUBSCRIPTION_ERROR,
      error: error.data,
    });
  }
}

function* watchCancelPendingSubscription() {
  yield takeLatest(
    subscriptionsActions.CANCEL_PENDING_SUBSCRIPTION_REQUEST,
    cancelPendingSubscription
  );
}

export default [
  fork(watchGetSubscriptionPlans),
  fork(watchAddSubscription),
  fork(watchGetSubscriptions),
  fork(watchAddSubscriptionPayment),
  fork(watchGetLatestSubscription),
  fork(watchCancelPendingSubscription),
];
