import { takeLatest, takeEvery, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import {
  appUiActions,
  subjectClassActions,
  teacherAssignmentActions,
} from "../../../actions";

function* getSubjectClassAssignments() {
  try {
    const response = yield axios({
      url: "/academics/subject-class-assignments/",
    });

    yield put({
      type: subjectClassActions.GET_SUBJECT_CLASS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subjectClassActions.GET_SUBJECT_CLASS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSubjectClassAssignment() {
  yield takeLatest(
    subjectClassActions.GET_SUBJECT_CLASS_REQUEST,
    getSubjectClassAssignments
  );
}

function* getMetaData() {
  try {
    const response = yield axios({
      url: "/academics/subject-class-assignments/meta-data",
    });

    yield put({
      type: subjectClassActions.GET_SUBJECT_CLASS_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subjectClassActions.GET_SUBJECT_CLASS_META_DATA_SUCCESS,
      error: {},
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(
    subjectClassActions.GET_SUBJECT_CLASS_META_DATA_REQUEST,
    getMetaData
  );
}

function* addSubjectClass({ data }) {
  try {
    const response = yield axios({
      url: "/academics/subject-class-assignments/",
      data,
      method: "POST",
    });

    yield put({
      type: subjectClassActions.ADD_SUBJECT_CLASS_SUCCESS,
      data: response,
    });
    yield put(teacherAssignmentActions.getMetaData());
  } catch (error) {
    yield put({
      type: subjectClassActions.ADD_SUBJECT_CLASS_ERROR,
      error: error.data,
    });
  }
}

function* watchAddSubjectClass() {
  yield takeLatest(
    subjectClassActions.ADD_SUBJECT_CLASS_REQUEST,
    addSubjectClass
  );
}

function* getSubjectClassDetails({ id }) {
  try {
    const response = yield axios({
      url: `/academics/subject-class-assignments/${id}`,
    });

    yield put({
      type: subjectClassActions.GET_SUBJECT_CLASS_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subjectClassActions.GET_SUBJECT_CLASS_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSubjectClassDetails() {
  yield takeLatest(
    subjectClassActions.GET_SUBJECT_CLASS_DETAILS_REQUEST,
    getSubjectClassDetails
  );
}

function* updateSubjectClass({ data, id }) {
  try {
    const response = yield axios({
      url: `/academics/subject-class-assignments/${id}`,
      data,
      method: "PUT",
    });

    yield put({
      type: subjectClassActions.UPDATE_SUBJECT_CLASS_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleUpdateSubjectClassModal(false));
  } catch (error) {
    yield put({
      type: subjectClassActions.UPDATE_SUBJECT_CLASS_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateSubjectClass() {
  yield takeLatest(
    subjectClassActions.UPDATE_SUBJECT_CLASS_REQUEST,
    updateSubjectClass
  );
}

function* deleteSubjectClass({ id }) {
  try {
    const response = yield axios({
      url: `/academics/subject-class-assignments/${id}`,
      method: "DELETE",
    });

    yield put({
      type: subjectClassActions.DELETE_SUBJECT_CLASS_SUCCESS,
      data: response,
    });
    yield put(subjectClassActions.getSubjectClass());
  } catch (error) {
    yield put({
      type: subjectClassActions.DELETE_SUBJECT_CLASS_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteSubjectClass() {
  yield takeEvery(
    subjectClassActions.DELETE_SUBJECT_CLASS_REQUEST,
    deleteSubjectClass
  );
}

function* deleteExamPaper({ id }) {
  try {
    const response = yield axios({
      url: `/academics/subject-class-assignments/exam-paper/${id}`,
      method: "DELETE",
    });

    yield put({
      type: subjectClassActions.DELETE_EXAM_PAPER_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: subjectClassActions.DELETE_EXAM_PAPER_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteExamPaper() {
  yield takeEvery(
    subjectClassActions.DELETE_EXAM_PAPER_REQUEST,
    deleteExamPaper
  );
}

export default [
  fork(watchGetSubjectClassAssignment),
  fork(watchGetMetaData),
  fork(watchAddSubjectClass),
  fork(watchGetSubjectClassDetails),
  fork(watchUpdateSubjectClass),
  fork(watchDeleteSubjectClass),
  fork(watchDeleteExamPaper),
];
