import { learnerAttendanceActions } from "../../../actions";
import learnerAttendanceInitialState from "../../../initialState/administration/learnerAttendance/learnerAttendance.initialState";

const learnerAttendance = (state = learnerAttendanceInitialState, actions) => {
  switch (actions.type) {
    case learnerAttendanceActions.GET_LEARNER_ATTENDANCE_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case learnerAttendanceActions.GET_LEARNER_ATTENDANCE_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: actions.data,
        metaDataLoading: false,
      };
    case learnerAttendanceActions.GET_LEARNER_ATTENDANCE_META_DATA_ERROR:
      return {
        ...state,
        metaDataLoading: false,
        metaDataError: actions.error,
      };
    case learnerAttendanceActions.GET_LEARNER_ATTENDANCE_REQUEST:
      return {
        ...state,
        learnerAttendanceLoading: true,
        learnerAttendanceError: {},
      };
    case learnerAttendanceActions.GET_LEARNER_ATTENDANCE_SUCCESS:
      return {
        ...state,
        learnerAttendanceSuccess: actions.data,
        learnerAttendanceLoading: false,
      };
    case learnerAttendanceActions.GET_LEARNER_ATTENDANCE_ERROR:
      return {
        ...state,
        learnerAttendanceLoading: false,
        learnerAttendanceError: actions.error,
      };
    case learnerAttendanceActions.GET_LEARNER_ATTENDANCE_DETAILS_REQUEST:
      return {
        ...state,
        learnerAttendanceDetailsLoading: true,
        learnerAttendanceDetailsError: {},
      };
    case learnerAttendanceActions.GET_LEARNER_ATTENDANCE_DETAILS_SUCCESS:
      return {
        ...state,
        learnerAttendanceDetailsSuccess: actions.data,
        learnerAttendanceDetailsLoading: false,
      };
    case learnerAttendanceActions.GET_LEARNER_ATTENDANCE_DETAILS_ERROR:
      return {
        ...state,
        learnerAttendanceDetailsLoading: false,
        learnerAttendanceDetailsError: actions.error,
      };
    case learnerAttendanceActions.ADD_LEARNER_ATTENDANCE_REQUEST:
      return {
        ...state,
        addLearnerAttendanceLoading: true,
        addLearnerAttendanceError: {},
      };
    case learnerAttendanceActions.ADD_LEARNER_ATTENDANCE_SUCCESS:
      return {
        ...state,
        addLearnerAttendanceSuccess: actions.data,
        addLearnerAttendanceLoading: false,
      };
    case learnerAttendanceActions.ADD_LEARNER_ATTENDANCE_ERROR:
      return {
        ...state,
        addLearnerAttendanceLoading: false,
        addLearnerAttendanceError: actions.error,
      };
    case learnerAttendanceActions.UPDATE_LEARNER_ATTENDANCE_REQUEST:
      return {
        ...state,
        updateLearnerAttendanceLoading: true,
        updateLearnerAttendanceError: {},
      };
    case learnerAttendanceActions.UPDATE_LEARNER_ATTENDANCE_SUCCESS:
      return {
        ...state,
        updateLearnerAttendanceSuccess: actions.data,
        updateLearnerAttendanceLoading: false,
      };
    case learnerAttendanceActions.UPDATE_LEARNER_ATTENDANCE_ERROR:
      return {
        ...state,
        updateLearnerAttendanceLoading: false,
        updateLearnerAttendanceError: actions.error,
      };
    case learnerAttendanceActions.DELETE_LEARNER_ATTENDANCE_REQUEST:
      return {
        ...state,
        deleteLearnerAttendanceLoading: true,
        deleteLearnerAttendanceError: {},
      };
    case learnerAttendanceActions.DELETE_LEARNER_ATTENDANCE_SUCCESS:
      return {
        ...state,
        deleteLearnerAttendanceSuccess: actions.data,
        deleteLearnerAttendanceLoading: false,
      };
    case learnerAttendanceActions.DELETE_LEARNER_ATTENDANCE_ERROR:
      return {
        ...state,
        deleteLearnerAttendanceLoading: false,
        deleteLearnerAttendanceError: actions.error,
      };
    case learnerAttendanceActions.CHECK_LEARNER_ATTENDANCE_REQUEST:
      return {
        ...state,
        checkLearnerAttendanceLoading: true,
        checkLearnerAttendanceError: {},
      };
    case learnerAttendanceActions.CHECK_LEARNER_ATTENDANCE_SUCCESS:
      return {
        ...state,
        checkLearnerAttendanceSuccess: actions.data,
        checkLearnerAttendanceLoading: false,
      };
    case learnerAttendanceActions.CHECK_LEARNER_ATTENDANCE_ERROR:
      return {
        ...state,
        checkLearnerAttendanceLoading: false,
        checkLearnerAttendanceError: actions.error,
      };
    case learnerAttendanceActions.APPROVE_LEARNER_ATTENDANCE_REQUEST:
      return {
        ...state,
        approveLearnerAttendanceLoading: true,
        approveLearnerAttendanceError: {},
      };
    case learnerAttendanceActions.APPROVE_LEARNER_ATTENDANCE_SUCCESS:
      return {
        ...state,
        approveLearnerAttendanceSuccess: actions.data,
        approveLearnerAttendanceLoading: false,
      };
    case learnerAttendanceActions.APPROVE_LEARNER_ATTENDANCE_ERROR:
      return {
        ...state,
        approveLearnerAttendanceLoading: false,
        approveLearnerAttendanceError: actions.error,
      };
    case learnerAttendanceActions.SET_LEARNER_ROLL_CALL_DATA:
      return {
        ...state,
        setRollCallData: actions.data,
      };
    case learnerAttendanceActions.SET_UPDATE_LEARNER_ROLL_CALL_DATA:
      return {
        ...state,
        setUpdateRollCallData: actions.data,
      };
    default:
      return state;
  }
};

export default learnerAttendance;
