import { dashboardActions } from "../../actions";
import initialState from "../../initialState";

const dashboards = (state = initialState.dashboards, actions) => {
  switch (actions.type) {
    case dashboardActions.GET_MAIN_DASHBOARD_REQUEST:
      return { ...state, mainDashboardLoading: true, mainDashboardError: {} };
    case dashboardActions.GET_MAIN_DASHBOARD_SUCCESS:
      return {
        ...state,
        mainDashboardSuccess: actions.data,
        mainDashboardLoading: false,
      };
    case dashboardActions.GET_MAIN_DASHBOARD_ERROR:
      return {
        ...state,
        mainDashboardError: actions.error,
        mainDashboardLoading: false,
      };
    case dashboardActions.GET_LEARNER_ATTENDANCE_SUMMARY_REQUEST:
      return {
        ...state,
        learnerAttendanceSummaryLoading: true,
        learnerAttendanceSummaryError: {},
      };
    case dashboardActions.GET_LEARNER_ATTENDANCE_SUMMARY_SUCCESS:
      return {
        ...state,
        learnerAttendanceSummary: actions.data,
        learnerAttendanceSummaryLoading: false,
      };
    case dashboardActions.GET_LEARNER_ATTENDANCE_SUMMARY_ERROR:
      return {
        ...state,
        learnerAttendanceSummaryError: actions.error,
        learnerAttendanceSummaryLoading: false,
      };
    case dashboardActions.GET_FEES_SUMMARY_COLLECTIONS_REQUEST:
      return {
        ...state,
        feesCollectionSummaryLoading: true,
        feesCollectionSummaryError: {},
      };
    case dashboardActions.GET_FEES_SUMMARY_COLLECTIONS_SUCCESS:
      return {
        ...state,
        feesCollectionSummarySuccess: actions.data,
        feesCollectionSummaryLoading: false,
      };
    case dashboardActions.GET_FEES_SUMMARY_COLLECTIONS_ERROR:
      return {
        ...state,
        feesCollectionSummaryError: actions.error,
        feesCollectionSummaryLoading: false,
      };
    case dashboardActions.GET_DASHBOARD_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
        metaDataError: {},
      };
    case dashboardActions.GET_DASHBOARD_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: actions.data,
        metaDataLoading: false,
      };
    case dashboardActions.GET_DASHBOARD_META_DATA_ERROR:
      return {
        ...state,
        metaDataError: actions.error,
        metaDataLoading: false,
      };
    case dashboardActions.GET_BUDGET_SUMMARY_REQUEST:
      return {
        ...state,
        budgetSummaryLoading: true,
        budgetSummaryError: {},
      };
    case dashboardActions.GET_BUDGET_SUMMARY_SUCCESS:
      return {
        ...state,
        budgetSummarySuccess: actions.data,
        budgetSummaryLoading: false,
      };
    case dashboardActions.GET_BUDGET_SUMMARY_ERROR:
      return {
        ...state,
        budgetSummaryError: actions.error,
        budgetSummaryLoading: false,
      };
    case dashboardActions.GET_INCOME_SUMMARY_REQUEST:
      return {
        ...state,
        incomeSummaryLoading: true,
        incomeSummaryError: {},
      };
    case dashboardActions.GET_INCOME_SUMMARY_SUCCESS:
      return {
        ...state,
        incomeSummarySuccess: actions.data,
        incomeSummaryLoading: false,
      };
    case dashboardActions.GET_INCOME_SUMMARY_ERROR:
      return {
        ...state,
        incomeSummaryError: actions.error,
        incomeSummaryLoading: false,
      };
    case dashboardActions.GET_SCHOOL_FINANCIAL_SUMMARY_REQUEST:
      return {
        ...state,
        schoolFinancialPositionSummaryLoading: true,
        schoolFinancialPositionSummaryError: {},
      };
    case dashboardActions.GET_SCHOOL_FINANCIAL_SUMMARY_SUCCESS:
      return {
        ...state,
        schoolFinancialPositionSummary: actions.data,
        schoolFinancialPositionSummaryLoading: false,
      };

    case dashboardActions.GET_SCHOOL_FINANCIAL_SUMMARY_ERROR:
      return {
        ...state,
        schoolFinancialPositionSummaryError: actions.error,
        schoolFinancialPositionSummaryLoading: false,
      };
    case dashboardActions.GET_PROFIT_AND_LOSS_SUMMARY_REQUEST:
      return {
        ...state,
        profitAndLossSummaryLoading: true,
        profitAndLossSummaryError: {},
      };
    case dashboardActions.GET_PROFIT_AND_LOSS_SUMMARY_SUCCESS:
      return {
        ...state,
        profitAndLossSummarySuccess: actions.data,
        profitAndLossSummaryLoading: false,
      };
    case dashboardActions.GET_PROFIT_AND_LOSS_SUMMARY_ERROR:
      return {
        ...state,
        profitAndLossSummaryError: actions.error,
        profitAndLossSummaryLoading: false,
      };
    case dashboardActions.GET_STORES_ITEMS_REQUEST:
      return {
        ...state,
        schoolInventorySummaryLoading: true,
        schoolInventorySummaryError: {},
      };
    case dashboardActions.GET_STORES_ITEMS_SUCCESS:
      return {
        ...state,
        schoolInventorySummarySuccess: actions.data,
        schoolInventorySummaryLoading: false,
      };
    case dashboardActions.GET_STORES_ITEMS_ERROR:
      return {
        ...state,
        schoolInventorySummaryError: actions.error,
        schoolInventorySummaryLoading: false,
      };
    case dashboardActions.GET_ATTENDANCE_SUMMARY_REQUEST:
      return {
        ...state,
        attendanceSummaryLoading: true,
        attendanceSummaryError: {},
      };
    case dashboardActions.GET_ATTENDANCE_SUMMARY_SUCCESS:
      return {
        ...state,
        attendanceSummarySuccess: actions.data,
        attendanceSummaryLoading: false,
      };
    case dashboardActions.GET_ATTENDANCE_SUMMARY_ERROR:
      return {
        ...state,
        attendanceSummaryError: actions.error,
        attendanceSummaryLoading: false,
      };
    case dashboardActions.GET_BUDGET_EXPENSES_SUMMARY_REQUEST:
      return {
        ...state,
        expenseBudgetSummmaryLoading: true,
        expenseBudgetSummaryError: {},
      };
    case dashboardActions.GET_BUDGET_EXPENSES_SUMMARY_SUCCESS:
      return {
        ...state,
        expenseBudgetSummarySuccess: actions.data,
        expenseBudgetSummmaryLoading: false,
      };
    case dashboardActions.GET_BUDGET_EXPENSES_SUMMARY_ERROR:
      return {
        ...state,
        expenseBudgetSummaryError: actions.error,
        expenseBudgetSummmaryLoading: false,
      };

    default:
      return state;
  }
};

export default dashboards;
