import { takeLatest, takeEvery, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import {
  academicYearActions,
  appUiActions,
  budgetActions,
  bursaryActions,
  enrollmentActions,
  examinationsActions,
  feesCollectionsActions,
  learnerBillingActions,
  optionalSubjectsActions,
  studentReportActions,
} from "../../../actions";

function* getAcademicYears() {
  try {
    const response = yield axios({ url: "/administration/academic-years" });

    yield put({
      type: academicYearActions.GET_ACADEMIC_YEARS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: academicYearActions.GET_ACADEMIC_YEARS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAcademicYears() {
  yield takeLatest(
    academicYearActions.GET_ACADEMIC_YEARS_REQUEST,
    getAcademicYears
  );
}

function* getAcademicYearDetails({ id }) {
  try {
    const response = yield axios({
      url: `/administration/academic-years/${id}`,
    });

    yield put({
      type: academicYearActions.GET_ACADEMIC_YEAR_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: academicYearActions.GET_ACADEMIC_YEAR_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAcademicYearDetails() {
  yield takeLatest(
    academicYearActions.GET_ACADEMIC_YEAR_DETAILS_REQUEST,
    getAcademicYearDetails
  );
}

function* addAcademicYear({ data }) {
  try {
    const response = yield axios({
      url: "administration/academic-years",
      method: "POST",
      data,
    });

    yield put(academicYearActions.getAcademicYears());
    yield put({
      type: academicYearActions.ADD_ACADEMIC_YEAR_SUCCESS,
      data: response,
    });
    yield put(enrollmentActions.getEnrollmentMetaData());
    yield put(budgetActions.getMetaData());
    yield put(optionalSubjectsActions.getOptionalSubjectsMetaData());
    yield put(examinationsActions.getMetaData());
    yield put(studentReportActions.getMetaData());
    yield put(learnerBillingActions.getMetaData());
    yield put(bursaryActions.getMetaData());
    yield put(feesCollectionsActions.getMetaData());
  } catch (error) {
    yield put({
      type: academicYearActions.ADD_ACADEMIC_YEAR_ERROR,
      error: error.data,
    });
  }
}

function* watchAddAcademicYear() {
  yield takeLatest(
    academicYearActions.ADD_ACADEMIC_YEAR_REQUEST,
    addAcademicYear
  );
}

function* updateAcademicYear({ id, data, academicYears }) {
  try {
    const response = yield axios({
      method: "PUT",
      url: `/administration/academic-years/${id}`,
      data,
    });

    yield put({
      type: academicYearActions.UPDATE_ACADEMIC_YEAR_SUCCESS,
      data: response,
      academicYears,
    });

    yield put(appUiActions.toggleUpdateAcademicYearsModal(false));
  } catch (error) {
    yield put({
      type: academicYearActions.UPDATE_ACADEMIC_YEAR_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateAcademicYear() {
  yield takeLatest(
    academicYearActions.UPDATE_ACADEMIC_YEAR_REQUEST,
    updateAcademicYear
  );
}

function* deleteAcademicAcademicYear({ id, academicYears }) {
  try {
    const response = yield axios({
      method: "DELETE",
      url: `/administration/academic-years/${id}`,
    });

    yield put({
      type: academicYearActions.DELETE_ACADEMIC_YEAR_SUCCESS,
      data: response,
      academicYears,
    });
  } catch (error) {
    yield put({
      type: academicYearActions.DELETE_ACADEMIC_YEAR_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteAcademicYear() {
  yield takeEvery(
    academicYearActions.DELETE_ACADEMIC_YEAR_REQUEST,
    deleteAcademicAcademicYear
  );
}

function* checkAcademicYear({ id }) {
  try {
    const response = yield axios({
      method: "PATCH",
      url: `/administration/academic-years/check/${id}`,
      data: { checked_at: new Date() },
    });

    yield put({
      type: academicYearActions.CHECK_ACADEMIC_YEAR_SUCCESS,
      data: response,
    });

    yield put(academicYearActions.getAcademicYearDetails(id));
    yield put(academicYearActions.getAcademicYears());
  } catch (error) {
    yield put({
      type: academicYearActions.CHECK_ACADEMIC_YEAR_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckAcademicYear() {
  yield takeLatest(
    academicYearActions.CHECK_ACADEMIC_YEAR_REQUEST,
    checkAcademicYear
  );
}

function* approveAcademicYear({ id }) {
  try {
    const response = yield axios({
      method: "PATCH",
      url: `/administration/academic-years/approve/${id}`,
      data: { approved_at: new Date() },
    });

    yield put({
      type: academicYearActions.APPROVE_ACADEMIC_YEAR_SUCCESS,
      data: response,
    });

    yield put(academicYearActions.getAcademicYearDetails(id));
    yield put(academicYearActions.getAcademicYears());
  } catch (error) {
    yield put({
      type: academicYearActions.APPROVE_ACADEMIC_YEAR_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveAcademicYear() {
  yield takeLatest(
    academicYearActions.APPROVE_ACADEMIC_YEAR_REQUEST,
    approveAcademicYear
  );
}

function* getTermDetails({ id }) {
  try {
    const response = yield axios({
      url: `/administration/academic-years/terms/${id}`,
    });

    yield put({
      type: academicYearActions.GET_TERM_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: academicYearActions.GET_TERM_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetTermDetails() {
  yield takeLatest(
    academicYearActions.GET_TERM_DETAILS_REQUEST,
    getTermDetails
  );
}

function* deleteTerm({ id, params }) {
  try {
    const response = yield axios({
      url: `/administration/academic-years/terms/${id}`,
      method: "DELETE",
      params,
    });

    yield put({
      type: academicYearActions.DELETE_TERM_SUCCESS,
      data: response,
    });

    yield put(
      academicYearActions.getAcademicYearDetails(params.academicYearId)
    );
  } catch (error) {
    yield put({
      type: academicYearActions.DELETE_TERM_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteTerm() {
  yield takeEvery(academicYearActions.DELETE_TERM_REQUEST, deleteTerm);
}

export default [
  fork(watchGetAcademicYears),
  fork(watchGetAcademicYearDetails),
  fork(watchAddAcademicYear),
  fork(watchUpdateAcademicYear),
  fork(watchDeleteAcademicYear),
  fork(watchCheckAcademicYear),
  fork(watchApproveAcademicYear),
  fork(watchGetTermDetails),
  fork(watchDeleteTerm),
];
