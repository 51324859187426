import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { appUiActions, projectsActions } from "../../../config/actions";

function* getProjects() {
  try {
    const data = yield axios({
      url: "/projects/",
    });

    yield put({
      type: projectsActions.GET_PROJECTS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: projectsActions.GET_PROJECTS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetProjects() {
  yield takeLatest(projectsActions.GET_PROJECTS_REQUEST, getProjects);
}

function* getMetaData() {
  try {
    const response = yield axios({
      url: "/projects/meta-data",
    });

    yield put({
      type: projectsActions.GET_PROJECTS_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: projectsActions.GET_PROJECTS_META_DATA_SUCCESS,
      data: {},
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(projectsActions.GET_PROJECTS_META_DATA_REQUEST, getMetaData);
}

function* addProject({ data }) {
  try {
    const response = yield axios({
      url: "/projects/",
      method: "POST",
      data,
    });

    yield put({
      type: projectsActions.ADD_PROJECT_SUCCESS,
      data: response,
    });

    yield put({ type: projectsActions.GET_PROJECTS_REQUEST });
  } catch (error) {
    yield put({
      type: projectsActions.ADD_PROJECT_ERROR,
      error: error.data,
    });
  }
}

function* watchAddProject() {
  yield takeLatest(projectsActions.ADD_PROJECT_REQUEST, addProject);
}

function* deleteProject({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `/projects/${data}`,
      method: "DELETE",
    });

    yield put({
      type: projectsActions.DELETE_PROJECT_SUCCESS,
      data: response,
    });

    yield put({ type: projectsActions.GET_PROJECTS_REQUEST });
  } catch (error) {
    yield put({
      type: projectsActions.DELETE_PROJECT_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteProject() {
  yield takeLatest(projectsActions.DELETE_PROJECT_REQUEST, deleteProject);
}

function* updateProject({ data, id }) {
  // id in url
  try {
    const response = yield axios({
      url: `/projects/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: projectsActions.UPDATE_PROJECT_SUCCESS,
      data: response,
    });

    yield put({ type: appUiActions.TOGGLE_UPDATE_PROJECT_MODAL, data: false });
  } catch (error) {
    yield put({
      type: projectsActions.UPDATE_PROJECT_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateProject() {
  yield takeLatest(projectsActions.UPDATE_PROJECT_REQUEST, updateProject);
}

function* getProjectDetails({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `/projects/details/${data}`,
    });

    yield put({
      type: projectsActions.GET_PROJECT_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: projectsActions.GET_PROJECT_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetProjectDetails() {
  yield takeLatest(
    projectsActions.GET_PROJECT_DETAILS_REQUEST,
    getProjectDetails
  );
}

function* checkProject({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `/projects/check/${data}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({
      type: projectsActions.CHECK_PROJECT_SUCCESS,
      data: response,
    });

    yield put({
      type: projectsActions.GET_PROJECT_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: projectsActions.CHECK_PROJECT_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckProject() {
  yield takeLatest(projectsActions.CHECK_PROJECT_REQUEST, checkProject);
}

function* approveProject({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `/projects/approve/${data}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: projectsActions.APPROVE_PROJECT_SUCCESS,
      data: response,
    });

    yield put({
      type: projectsActions.GET_PROJECT_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: projectsActions.APPROVE_PROJECT_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveProject() {
  yield takeLatest(projectsActions.APPROVE_PROJECT_REQUEST, approveProject);
}

export default [
  fork(watchGetProjects),
  fork(watchGetMetaData),
  fork(watchAddProject),
  fork(watchDeleteProject),
  fork(watchUpdateProject),
  fork(watchGetProjectDetails),
  fork(watchCheckProject),
  fork(watchApproveProject),
];
