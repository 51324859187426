import { takeLatest, put, fork } from "redux-saga/effects";
import axios from "axios";
import { recordOfBiddersActions } from "../../../actions";

function* getRecordOfBidders() {
  try {
    const response = yield axios({
      url: "/procurement/record-of-bidders/",
      method: "GET",
    });

    yield put({
      type: recordOfBiddersActions.GET_RECORD_OF_BIDDERS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: recordOfBiddersActions.GET_RECORD_OF_BIDDERS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetRecordOfBidders() {
  yield takeLatest(
    recordOfBiddersActions.GET_RECORD_OF_BIDDERS_REQUEST,
    getRecordOfBidders
  );
}

function* getRecordOfBiddersDetails({ id }) {
  try {
    const response = yield axios({
      url: `/procurement/record-of-bidders/details/${id}`,
      method: "GET",
    });
    yield put({
      type: recordOfBiddersActions.GET_RECORD_OF_BIDDERS_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: recordOfBiddersActions.GET_RECORD_OF_BIDDERS_DETAILS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetRecordOfBiddersDetails() {
  yield takeLatest(
    recordOfBiddersActions.GET_RECORD_OF_BIDDERS_DETAILS_REQUEST,
    getRecordOfBiddersDetails
  );
}

function* getRecordOfBiddersMetaData() {
  try {
    const response = yield axios({
      url: "/procurement/record-of-bidders/meta-data",
      method: "GET",
    });
    yield put({
      type: recordOfBiddersActions.GET_RECORD_OF_BIDDERS_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: recordOfBiddersActions.GET_RECORD_OF_BIDDERS_META_DATA_ERROR,
      error: error.data,
    });
  }
}
function* watchGetRecordOfBiddersMetaData() {
  yield takeLatest(
    recordOfBiddersActions.GET_RECORD_OF_BIDDERS_META_DATA_REQUEST,
    getRecordOfBiddersMetaData
  );
}

function* addRecordOfBidders({ data }) {
  try {
    const response = yield axios({
      url: "/procurement/record-of-bidders/",
      method: "POST",
      data: data,
    });
    yield put({
      type: recordOfBiddersActions.ADD_RECORD_OF_BIDDERS_SUCCESS,
      data: response,
    });
    yield put(recordOfBiddersActions.getRecordOfBidders());
  } catch (error) {
    yield put({
      type: recordOfBiddersActions.ADD_RECORD_OF_BIDDERS_ERROR,
      error: error.data,
    });
  }
}
function* watchAddRecordOfBidders() {
  yield takeLatest(
    recordOfBiddersActions.ADD_RECORD_OF_BIDDERS_REQUEST,
    addRecordOfBidders
  );
}

function* updateRecordOfBidders({ id, data }) {
  try {
    const response = yield axios({
      url: `/procurement/record-of-bidders/${id}`,
      method: "PUT",
      data: data,
    });
    yield put({
      type: recordOfBiddersActions.UPDATE_RECORD_OF_BIDDERS_DETAILS_SUCCESS,
      data: response,
    });
    yield put(recordOfBiddersActions.getRecordOfBidders());
  } catch (error) {
    yield put({
      type: recordOfBiddersActions.UPDATE_RECORD_OF_BIDDERS_DETAILS_ERROR,
      error: error.data,
    });
  }
}
function* watchUpdateRecordOfBidders() {
  yield takeLatest(
    recordOfBiddersActions.UPDATE_RECORD_OF_BIDDERS_DETAILS_REQUEST,
    updateRecordOfBidders
  );
}

function* deleteRecordOfBidders({ id }) {
  try {
    const response = yield axios({
      url: `/procurement/record-of-bidders/${id}`,
      method: "DELETE",
    });
    yield put({
      type: recordOfBiddersActions.DELETE_RECORD_OF_BIDDERS_SUCCESS,
      data: response,
    });
    yield put(recordOfBiddersActions.getRecordOfBidders());
  } catch (error) {
    yield put({
      type: recordOfBiddersActions.DELETE_RECORD_OF_BIDDERS_ERROR,
      error: error.data,
    });
  }
}
function* watchDeleteRecordOfBidders() {
  yield takeLatest(
    recordOfBiddersActions.DELETE_RECORD_OF_BIDDERS_REQUEST,
    deleteRecordOfBidders
  );
}

function* checkRecordOfBidders({ id }) {
  try {
    const response = yield axios({
      url: `/procurement/record-of-bidders/check/${id}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });
    yield put({
      type: recordOfBiddersActions.CHECK_RECORD_OF_BIDDERS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: recordOfBiddersActions.CHECK_RECORD_OF_BIDDERS_ERROR,
      error: error.data,
    });
  }
}
function* watchCheckRecordOfBidders() {
  yield takeLatest(
    recordOfBiddersActions.CHECK_RECORD_OF_BIDDERS_REQUEST,
    checkRecordOfBidders
  );
}

function* approveRecordOfBidders({ id }) {
  try {
    const response = yield axios({
      url: `/procurement/record-of-bidders/approve/${id}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });
    yield put({
      type: recordOfBiddersActions.APPROVE_RECORD_OF_BIDDERS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: recordOfBiddersActions.APPROVE_RECORD_OF_BIDDERS_ERROR,
      error: error.data,
    });
  }
}
function* watchApproveRecordOfBidders() {
  yield takeLatest(
    recordOfBiddersActions.APPROVE_RECORD_OF_BIDDERS_REQUEST,
    approveRecordOfBidders
  );
}

//Bidders
function* getBidders() {
  try {
    const response = yield axios({
      url: "/procurement/bidders/",
      method: "GET",
    });
    yield put({
      type: recordOfBiddersActions.GET_BIDDERS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: recordOfBiddersActions.GET_BIDDERS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetBidders() {
  yield takeLatest(recordOfBiddersActions.GET_BIDDERS_REQUEST, getBidders);
}

function* getBiddersMetaData() {
  try {
    const response = yield axios({
      url: "/procurement/bidders/meta-data",
      method: "GET",
    });
    yield put({
      type: recordOfBiddersActions.GET_BIDDERS_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: recordOfBiddersActions.GET_BIDDERS_META_DATA_ERROR,
      error: error.data,
    });
  }
}
function* watchGetBiddersMetaData() {
  yield takeLatest(
    recordOfBiddersActions.GET_BIDDERS_META_DATA_REQUEST,
    getBiddersMetaData
  );
}

function* getBidderDetails({ id }) {
  try {
    const response = yield axios({
      url: `/procurement/bidders/details/${id}`,
      method: "GET",
    });
    yield put({
      type: recordOfBiddersActions.GET_BIDDER_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: recordOfBiddersActions.GET_BIDDER_DETAILS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetBidderDetails() {
  yield takeLatest(
    recordOfBiddersActions.GET_BIDDER_DETAILS_REQUEST,
    getBidderDetails
  );
}

function* addBidder({ data }) {
  try {
    const response = yield axios({
      url: "/procurement/bidders/",
      method: "POST",
      data: data,
    });
    yield put({
      type: recordOfBiddersActions.ADD_BIDDER_SUCCESS,
      data: response,
    });
    yield put(recordOfBiddersActions.getBidders());
  } catch (error) {
    yield put({
      type: recordOfBiddersActions.ADD_BIDDER_ERROR,
      error: error.data,
    });
  }
}
function* watchAddBidder() {
  yield takeLatest(recordOfBiddersActions.ADD_BIDDER_REQUEST, addBidder);
}

function* updateBidderDetails({ id, data }) {
  try {
    const response = yield axios({
      url: `/procurement/bidders/${id}`,
      method: "PUT",
      data: data,
    });
    yield put({
      type: recordOfBiddersActions.UPDATE_BIDDER_DETAILS_SUCCESS,
      data: response,
    });
    yield put(recordOfBiddersActions.getBidders());
  } catch (error) {
    yield put({
      type: recordOfBiddersActions.UPDATE_BIDDER_DETAILS_ERROR,
      error: error.data,
    });
  }
}
function* watchUpdateBidderDetails() {
  yield takeLatest(
    recordOfBiddersActions.UPDATE_BIDDER_DETAILS_REQUEST,
    updateBidderDetails
  );
}

function* deleteBidder({ id }) {
  try {
    const response = yield axios({
      url: `/procurement/bidders/${id}`,
      method: "DELETE",
    });
    yield put({
      type: recordOfBiddersActions.DELETE_BIDDER_SUCCESS,
      data: response,
    });
    yield put(recordOfBiddersActions.getBidders());
  } catch (error) {
    yield put({
      type: recordOfBiddersActions.DELETE_BIDDER_ERROR,
      error: error.data,
    });
  }
}
function* watchDeleteBidder() {
  yield takeLatest(recordOfBiddersActions.DELETE_BIDDER_REQUEST, deleteBidder);
}

export default [
  fork(watchGetRecordOfBidders),
  fork(watchGetRecordOfBiddersDetails),
  fork(watchGetRecordOfBiddersMetaData),
  fork(watchAddRecordOfBidders),
  fork(watchUpdateRecordOfBidders),
  fork(watchDeleteRecordOfBidders),
  fork(watchCheckRecordOfBidders),
  fork(watchApproveRecordOfBidders),
  fork(watchGetBidders),
  fork(watchGetBiddersMetaData),
  fork(watchGetBidderDetails),
  fork(watchAddBidder),
  fork(watchUpdateBidderDetails),
  fork(watchDeleteBidder),
];
