const supplierPaymentsInitialState = {
  supplierPayments: {
    supplierPaymentsScheduleLoading: false,
    supplierPaymentsScheduleSuccess: {},
    supplierPaymentsScheduleError: {},

    metaDataLoading: false,
    metaData: {},

    addSupplierPaymentScheduleLoading: false,
    addSupplierPaymentScheduleSuccess: {},
    addSupplierPaymentScheduleError: {},

    deleteSupplierPaymentScheduleLoading: false,
    deleteSupplierPaymentScheduleSuccess: false,
    deleteSupplierPaymentScheduleError: {},

    updateSupplierPaymentScheduleLoading: false,
    updateSupplierPaymentScheduleSuccess: {},
    updateSupplierPaymentScheduleError: {},
    updateSupplierPaymentScheduleData: {},

    supplierPaymentScheduleDetailsLoading: false,
    supplierPaymentScheduleDetailsSuccess: {},
    supplierPaymentScheduleDetailsError: {},

    addSupplierPaymentsLoading: false,
    addSupplierPaymentsSuccess: {},
    addSupplierPaymentsError: {},

    deleteSupplierPaymentLoading: false,
    deleteSupplierPaymentSuccess: {},
    deleteSupplierPaymentError: {},

    updateSupplierPaymentData: {},
    updateSupplierPaymentLoading: false,
    updateSupplierPaymentSuccess: {},
    updateSupplierPaymentError: {},

    supplierPaymentDetailsLoading: false,
    supplierPaymentDetailsSuccess: {},
    supplierPaymentDetailsError: {},

    checkSupplierPaymentScheduleLoading: false,
    checkSupplierPaymentScheduleSuccess: {},
    checkSupplierPaymentScheduleError: {},

    approveSupplierPaymentScheduleLoading: false,
    approveSupplierPaymentScheduleSuccess: {},
    approveSupplierPaymentScheduleError: {},

    addSupplierPaymentInvoiceLoading: false,
    addSupplierPaymentInvoiceSuccess: {},
    addSupplierPaymentInvoiceError: {},

    deleteSupplierPaymentInvoiceLoading: false,
    deleteSupplierPaymentInvoiceSuccess: {},
    deleteSupplierPaymentInvoiceError: {},

    updateSupplierPaymentInvoiceData: {},
    updateSupplierPaymentInvoiceLoading: false,
    updateSupplierPaymentInvoiceSuccess: {},
    updateSupplierPaymentInvoiceError: {},
  },
};

export default supplierPaymentsInitialState;
