import { schoolCommunicationsActions } from "../../../actions";
import initialState from "../../../initialState";

const schoolCommunications = (
  state = initialState.schoolCommunications,
  actions
) => {
  switch (actions.type) {
    case schoolCommunicationsActions.GET_SCHOOL_COMMUNICATIONS_REQUEST:
      return {
        ...state,
        schoolCommunicationsLoading: true,
        schoolCommunicationsError: {},
      };
    case schoolCommunicationsActions.GET_SCHOOL_COMMUNICATIONS_SUCCESS:
      return {
        ...state,
        schoolCommunicationsLoading: false,
        schoolCommunications: actions.data,
      };
    case schoolCommunicationsActions.GET_SCHOOL_COMMUNICATIONS_ERROR:
      return {
        ...state,
        schoolCommunicationsLoading: false,
        schoolCommunicationsError: actions.error,
      };
    case schoolCommunicationsActions.GET_SCHOOL_COMMUNICATION_DETAILS_REQUEST:
      return {
        ...state,
        schoolCommunicationDetailsLoading: true,
        schoolCommunicationDetailsError: {},
      };
    case schoolCommunicationsActions.GET_SCHOOL_COMMUNICATION_DETAILS_SUCCESS:
      return {
        ...state,
        schoolCommunicationDetailsLoading: false,
        schoolCommunicationDetails: actions.data,
      };
    case schoolCommunicationsActions.GET_SCHOOL_COMMUNICATION_DETAILS_ERROR:
      return {
        ...state,
        schoolCommunicationDetailsLoading: false,
        schoolCommunicationDetailsError: actions.error,
      };
    case schoolCommunicationsActions.SEND_DEFAULTER_COMMUNICATION_REQUEST:
      return {
        ...state,
        sendDefaulterCommunicationLoading: true,
        sendDefaulterCommunicationError: {},
      };
    case schoolCommunicationsActions.SEND_DEFAULTER_COMMUNICATION_SUCCESS:
      return {
        ...state,
        sendDefaulterCommunicationLoading: false,
        sendDefaulterCommunication: actions.data,
      };
    case schoolCommunicationsActions.SEND_DEFAULTER_COMMUNICATION_ERROR:
      return {
        ...state,
        sendDefaulterCommunicationLoading: false,
        sendDefaulterCommunicationError: actions.error,
      };
    case schoolCommunicationsActions.ADD_GENERAL_SCHOOL_COMMUNICATION_REQUEST:
      return {
        ...state,
        addGeneralCommunicationLoading: true,
        addGeneralCommunicationError: {},
      };
    case schoolCommunicationsActions.ADD_GENERAL_SCHOOL_COMMUNICATION_SUCCESS:
      return {
        ...state,
        addGeneralCommunicationLoading: false,
        addGeneralCommunication: actions.data,
      };
    case schoolCommunicationsActions.ADD_GENERAL_SCHOOL_COMMUNICATION_ERROR:
      return {
        ...state,
        addGeneralCommunicationLoading: false,
        addGeneralCommunicationError: actions.error,
      };
    case schoolCommunicationsActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
        metaDataError: {},
      };
    case schoolCommunicationsActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataLoading: false,
        metaData: actions.data,
      };
    case schoolCommunicationsActions.GET_META_DATA_ERROR:
      return {
        ...state,
        metaDataLoading: false,
        metaDataError: actions.error,
      };
    default:
      return state;
  }
};

export default schoolCommunications;
