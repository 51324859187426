import React from "react";
import { Row, Col, Card } from "antd";
import assets from "../../assets/images/Assets.png";
import partnership from "../../assets/images/Partnership.png";
import projects from "../../assets/images/Projects.png";
import humanresource from "../../assets/images/Human-Resource.png";
import procurement from "../../assets/images/Procurement.png";
import finance from "../../assets/images/Finance.png";
const { Meta } = Card;
function AppFeature() {
  return (
    <div id="feature" className="block featureBlock bgGray">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>Key Features</h2>
          <p>
            Famis key features are built to handle all the finance management
            and human resource management tasks.
          </p>
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <Card hoverable cover={<img alt="Track Projects" src={projects} />}>
              <Meta title="Track Projects" />
            </Card>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <Card
              hoverable
              cover={<img alt="Human Resource" src={humanresource} />}
            >
              <Meta title="Human Resource" />
            </Card>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <Card hoverable cover={<img alt="Procurement" src={procurement} />}>
              <Meta title="Procurement" />
            </Card>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <Card
              hoverable
              cover={<img alt="Finance Management" src={finance} />}
            >
              <Meta title="Finance Management" />
            </Card>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <Card
              hoverable
              cover={
                <img
                  alt="Business Development and Partnerships"
                  src={partnership}
                />
              }
            >
              <Meta title="Business Development and Partnerships" />
            </Card>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <Card
              hoverable
              cover={<img alt="Assets and Inventory" src={assets} />}
            >
              <Meta title="Assets and Inventory" />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default AppFeature;
