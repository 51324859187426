import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { isEmpty } from "lodash";
import { authActions } from "../../actions";
import {
  clearToken,
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
  setToken,
} from "../../services/storage.service";

function* loginUser(actions) {
  try {
    const response = yield axios({
      url: "/users/auth/login",
      method: "POST",
      data: actions.data,
    });
    yield put({
      type: authActions.LOGIN_SUCCESS,
      data: response,
    });

    const currentOrganisation = getLocalStorageItem("organisation");

    const userOrganisation = response?.organisations?.find(
      (org) => org.organisation_id == currentOrganisation
    );
    if (!userOrganisation && !isEmpty(response.organisations))
      setLocalStorageItem(
        "organisation",
        response?.organisations[0]?.organisation_id
      );
    else if (userOrganisation)
      setLocalStorageItem("organisation", userOrganisation?.organisation_id);
    else if (isEmpty(response.organisations))
      removeLocalStorageItem("organisation");

    setToken(response.access_token);
    window.location = "/";
  } catch (error) {
    yield put({
      type: authActions.LOGIN_ERROR,
      error: error.data,
    });
  }
}

function* watchLoginUser() {
  yield takeLatest(authActions.LOGIN_REQUEST, loginUser);
}

function* getAuthUser() {
  try {
    const response = yield axios({
      url: "/users/auth/verify-token",
      method: "GET",
    });
    yield put({
      type: authActions.GETTING_AUTH_USER_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.GETTING_AUTH_USER_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAuthUser() {
  yield takeLatest(authActions.GETTING_AUTH_USER, getAuthUser);
}

function* logOutUser() {
  try {
    const response = yield axios({ url: "/users/auth/log-out" });
    yield put({ type: authActions.LOGOUT_USER_SUCCESS, data: response });
    clearToken();
  } catch (error) {
    yield put({
      type: authActions.LOGOUT_USER_ERROR,
      error: error.data,
    });
  }
}

function* watchLogOutUser() {
  yield takeLatest(authActions.LOGOUT_USER_REQUEST, logOutUser);
}

function* signupUser(actions) {
  try {
    const response = yield axios({
      url: "/users/auth/register",
      method: "POST",
      data: actions.data,
    });
    yield put({
      type: authActions.SIGNUP_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.SIGNUP_ERROR,
      error: error.data,
    });
  }
}

function* watchSignupUser() {
  yield takeLatest(authActions.SIGNUP_REQUEST, signupUser);
}

function* forgotUserPassword(actions) {
  try {
    const response = yield axios({
      url: "/users/auth/forgot-password",
      method: "POST",
      data: actions.data,
    });
    yield put({
      type: authActions.FORGOT_PASSWORD_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.FORGOT_PASSWORD_ERROR,
      error: error.data,
    });
  }
}

function* watchForgotUserPassword() {
  yield takeLatest(authActions.FORGOT_PASSWORD_REQUEST, forgotUserPassword);
}

function* resetUserPassword(actions) {
  try {
    const response = yield axios({
      url: `/users/auth/reset-password/${actions.token}`,
      method: "PATCH",
      data: actions.data,
    });
    yield put({
      type: authActions.RESET_PASSWORD_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.RESET_PASSWORD_ERROR,
      error: error.data,
    });
  }
}

function* watchResetUserPassword() {
  yield takeLatest(authActions.RESET_PASSWORD_REQUEST, resetUserPassword);
}

function* confirmUserAccount(actions) {
  try {
    const response = yield axios({
      url: `/users/auth/verify-account/${actions.token}`,
      method: "POST",
      data: actions.data,
    });
    yield put({
      type: authActions.CONFIRM_ACCOUNT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.CONFIRM_ACCOUNT_ERROR,
      error: error.data,
    });
  }
}

function* watchConfirmUserAccount() {
  yield takeLatest(authActions.RESET_PASSWORD_REQUEST, confirmUserAccount);
}

export default [
  fork(watchLoginUser),
  fork(watchGetAuthUser),
  fork(watchLogOutUser),
  fork(watchSignupUser),
  fork(watchForgotUserPassword),
  fork(watchResetUserPassword),
  fork(watchConfirmUserAccount),
];
