import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { lessonPlansActions } from "../../../actions";

function* getLessonPlans() {
  try {
    const response = yield axios.get("/academics/lesson-plans");
    yield put({
      type: lessonPlansActions.GET_LESSON_PLANS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: lessonPlansActions.GET_LESSON_PLANS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetLessonPlans() {
  yield takeLatest(lessonPlansActions.GET_LESSON_PLANS_REQUEST, getLessonPlans);
}

function* getLessonPlanDetails({ id }) {
  try {
    const response = yield axios.get(`/academics/lesson-plans/${id}`);
    yield put({
      type: lessonPlansActions.GET_LESSON_PLAN_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: lessonPlansActions.GET_LESSON_PLAN_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetLessonPlanDetails() {
  yield takeLatest(
    lessonPlansActions.GET_LESSON_PLAN_DETAILS_REQUEST,
    getLessonPlanDetails
  );
}

function* addLessonPlan({ data }) {
  try {
    const response = yield axios.post("/academics/lesson-plans", data);
    yield put({
      type: lessonPlansActions.ADD_LESSON_PLAN_SUCCESS,
      data: response,
    });
    yield put(lessonPlansActions.getLessonPlans());
  } catch (error) {
    yield put({
      type: lessonPlansActions.ADD_LESSON_PLAN_ERROR,
      error: error.data,
    });
  }
}

function* watchAddLessonPlan() {
  yield takeLatest(lessonPlansActions.ADD_LESSON_PLAN_REQUEST, addLessonPlan);
}

function* updateLessonPlan({ data, id }) {
  try {
    const response = yield axios.put(`/academics/lesson-plans/${id}`, data);
    yield put({
      type: lessonPlansActions.UPDATE_LESSON_PLAN_SUCCESS,
      data: response,
    });
    yield put(lessonPlansActions.getLessonPlans());
  } catch (error) {
    yield put({
      type: lessonPlansActions.UPDATE_LESSON_PLAN_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateLessonPlan() {
  yield takeLatest(
    lessonPlansActions.UPDATE_LESSON_PLAN_REQUEST,
    updateLessonPlan
  );
}

function* deleteLessonPlan({ id }) {
  try {
    const response = yield axios.delete(`/academics/lesson-plans/${id}`);
    yield put({
      type: lessonPlansActions.DELETE_LESSON_PLAN_SUCCESS,
      data: {
        ...response,
        id,
      },
    });
  } catch (error) {
    yield put({
      type: lessonPlansActions.DELETE_LESSON_PLAN_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteLessonPlan() {
  yield takeLatest(
    lessonPlansActions.DELETE_LESSON_PLAN_REQUEST,
    deleteLessonPlan
  );
}

function* checkLessonPlan({ id }) {
  try {
    const response = yield axios.patch(`/academics/lesson-plans/check/${id}`);
    yield put({
      type: lessonPlansActions.CHECK_LESSON_PLAN_SUCCESS,
      data: response,
    });
    yield put(lessonPlansActions.getLessonPlanDetails(id));
  } catch (error) {
    yield put({
      type: lessonPlansActions.CHECK_LESSON_PLAN_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckLessonPlan() {
  yield takeLatest(
    lessonPlansActions.CHECK_LESSON_PLAN_REQUEST,
    checkLessonPlan
  );
}

function* approveLessonPlan({ id }) {
  try {
    const response = yield axios.patch(`/academics/lesson-plans/approve/${id}`);
    yield put({
      type: lessonPlansActions.APPROVE_LESSON_PLAN_SUCCESS,
      data: response,
    });
    yield put(lessonPlansActions.getLessonPlanDetails(id));
  } catch (error) {
    yield put({
      type: lessonPlansActions.APPROVE_LESSON_PLAN_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveLessonPlan() {
  yield takeLatest(
    lessonPlansActions.APPROVE_LESSON_PLAN_REQUEST,
    approveLessonPlan
  );
}

function* getLessonPlanMetaData() {
  try {
    const response = yield axios.get("/academics/lesson-plans/meta-data");
    yield put({
      type: lessonPlansActions.GET_LESSON_PLAN_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: lessonPlansActions.GET_LESSON_PLAN_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetLessonPlanMetaData() {
  yield takeLatest(
    lessonPlansActions.GET_LESSON_PLAN_META_DATA_REQUEST,
    getLessonPlanMetaData
  );
}

function* getLessonPlanProcedureStages({ id }) {
  try {
    const response = yield axios.get(`/academics/lesson-plans/stages/${id}`);
    yield put({
      type: lessonPlansActions.GET_LESSON_PLAN_PROCEDURE_STAGES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: lessonPlansActions.GET_LESSON_PLAN_PROCEDURE_STAGES_ERROR,
      error: error.data,
    });
  }
}

function* watchGetLessonPlanProcedureStages() {
  yield takeLatest(
    lessonPlansActions.GET_LESSON_PLAN_PROCEDURE_STAGES_REQUEST,
    getLessonPlanProcedureStages
  );
}

function* updateLessonPlanProcedureStage({ id, data }) {
  try {
    const response = yield axios.put(
      `/academics/lesson-plans/stages/${id}`,
      data
    );
    yield put({
      type: lessonPlansActions.UPDATE_LESSON_PLAN_PROCEDURE_STAGE_SUCCESS,
      data: response,
    });
    yield put(lessonPlansActions.getLessonPlanDetails(data?.lesson_plan_id));
  } catch (error) {
    yield put({
      type: lessonPlansActions.UPDATE_LESSON_PLAN_PROCEDURE_STAGE_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateLessonPlanProcedureStage() {
  yield takeLatest(
    lessonPlansActions.UPDATE_LESSON_PLAN_PROCEDURE_STAGE_REQUEST,
    updateLessonPlanProcedureStage
  );
}

function* addLessonPlanProcedureStage({ data }) {
  try {
    const response = yield axios.post(
      `/academics/lesson-plans/stages/bulk-create`,
      data
    );
    yield put({
      type: lessonPlansActions.ADD_LESSON_PLAN_PROCEDURE_STAGE_SUCCESS,
      data: response,
    });
    yield put(lessonPlansActions.getLessonPlanDetails(data?.lesson_plan_id));
  } catch (error) {
    yield put({
      type: lessonPlansActions.ADD_LESSON_PLAN_PROCEDURE_STAGE_ERROR,
      error: error.data,
    });
  }
}

function* watchAddLessonPlanProcedureStage() {
  yield takeLatest(
    lessonPlansActions.ADD_LESSON_PLAN_PROCEDURE_STAGE_REQUEST,
    addLessonPlanProcedureStage
  );
}

function* deleteLessonPlanProcedureStage({ id, planId }) {
  try {
    const response = yield axios.delete(`/academics/lesson-plans/stages/${id}`);
    yield put({
      type: lessonPlansActions.DELETE_LESSON_PLAN_PROCEDURE_STAGE_SUCCESS,
      data: response,
    });
    yield put(lessonPlansActions.getLessonPlanDetails(planId));
  } catch (error) {
    yield put({
      type: lessonPlansActions.DELETE_LESSON_PLAN_PROCEDURE_STAGE_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteLessonPlanProcedureStage() {
  yield takeLatest(
    lessonPlansActions.DELETE_LESSON_PLAN_PROCEDURE_STAGE_REQUEST,
    deleteLessonPlanProcedureStage
  );
}

export default [
  fork(watchGetLessonPlans),
  fork(watchGetLessonPlanDetails),
  fork(watchAddLessonPlan),
  fork(watchUpdateLessonPlan),
  fork(watchDeleteLessonPlan),
  fork(watchCheckLessonPlan),
  fork(watchApproveLessonPlan),
  fork(watchGetLessonPlanMetaData),
  fork(watchGetLessonPlanProcedureStages),
  fork(watchUpdateLessonPlanProcedureStage),
  fork(watchAddLessonPlanProcedureStage),
  fork(watchDeleteLessonPlanProcedureStage),
];
