const singleLearnerLedgerInitialState = {
    singleLearnerLedgerLoading: false,
    singleLearnerLedgerSuccess: {},
    singleLearnerLedgerError: {},

    singleLearnerLedgerMetaDataLoading: false,
    singleLearnerLedgerMetaDataSuccess: {},
    singleLearnerLedgerMetaDataError: {},

    singleLearnerLedgerData: {
        isSingleLearnertLedger: false,
        learnerInfo: {},
    },
}

export default singleLearnerLedgerInitialState