import { bursaryActions } from "../../../actions";
import initialState from "../../../initialState";

export default (state = initialState.bursaries, actions) => {
  switch (actions.type) {
    case bursaryActions.GET_BURSARIES_REQUEST:
      return {
        ...state,
        bursariesLoading: true,
        bursariesError: {},
      };
    case bursaryActions.GET_BURSARIES_SUCCESS:
      return {
        ...state,
        bursariesLoading: false,
        bursariesSuccess: actions.data,
      };
    case bursaryActions.GET_BURSARIES_ERROR:
      return {
        ...state,
        bursariesLoading: false,
        bursariesError: actions.error,
      };
    case bursaryActions.GET_BURSARY_DETAILS_REQUEST:
      return {
        ...state,
        bursaryDetailsLoading: true,
        bursaryDetailsError: {},
      };
    case bursaryActions.GET_BURSARY_DETAILS_SUCCESS:
      return {
        ...state,
        bursaryDetailsLoading: false,
        bursaryDetailsSuccess: actions.data,
      };
    case bursaryActions.GET_BURSARY_DETAILS_ERROR:
      return {
        ...state,
        bursaryDetailsLoading: false,
        bursaryDetailsError: actions.error,
      };
    case bursaryActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case bursaryActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataLoading: false,
        metaData: actions.data,
      };
    case bursaryActions.GET_BURSARY_COST_DETAILS_REQUEST:
      return {
        ...state,
        bursaryCostDetailsLoading: true,
        bursaryCostDetailsError: {},
      };
    case bursaryActions.GET_BURSARY_COST_DETAILS_SUCCESS:
      return {
        ...state,
        bursaryCostDetailsLoading: false,
        bursaryCostDetailsSuccess: actions.data,
      };
    case bursaryActions.GET_BURSARY_COST_DETAILS_ERROR:
      return {
        ...state,
        bursaryCostDetailsLoading: false,
        bursaryCostDetailsError: actions.error,
      };
    case bursaryActions.ADD_BURSARY_REQUEST:
      return {
        ...state,
        addBursaryLoading: true,
        addBursaryError: {},
      };
    case bursaryActions.ADD_BURSARY_SUCCESS:
      return {
        ...state,
        addBursaryLoading: false,
        addBursarySuccess: actions.data,
      };
    case bursaryActions.ADD_BURSARY_ERROR:
      return {
        ...state,
        addBursaryLoading: false,
        addBursaryError: actions.error,
      };
    case bursaryActions.UPDATE_BURSARY_REQUEST:
      return {
        ...state,
        updateBursaryLoading: true,
        updateBursaryError: {},
      };
    case bursaryActions.UPDATE_BURSARY_SUCCESS:
      return {
        ...state,
        updateBursaryLoading: false,
        updateBursarySuccess: actions.data,
      };
    case bursaryActions.UPDATE_BURSARY_ERROR:
      return {
        ...state,
        updateBursaryLoading: false,
        updateBursaryError: actions.error,
      };
    case bursaryActions.DELETE_BURSARY_REQUEST:
      return {
        ...state,
        deleteBursaryLoading: true,
        deleteBursaryError: {},
      };
    case bursaryActions.DELETE_BURSARY_SUCCESS:
      return {
        ...state,
        deleteBursaryLoading: false,
        deleteBursarySuccess: actions.data,
      };
    case bursaryActions.DELETE_BURSARY_ERROR:
      return {
        ...state,
        deleteBursaryLoading: false,
        deleteBursaryError: actions.error,
      };
    case bursaryActions.CHECK_BURSARY_REQUEST:
      return {
        ...state,
        checkBursaryLoading: true,
        checkBursaryError: {},
      };
    case bursaryActions.CHECK_BURSARY_SUCCESS:
      return {
        ...state,
        checkBursaryLoading: false,
        checkBursarySuccess: actions.data,
      };
    case bursaryActions.CHECK_BURSARY_ERROR:
      return {
        ...state,
        checkBursaryLoading: false,
        checkBursaryError: actions.error,
      };
    case bursaryActions.APPROVE_BURSARY_REQUEST:
      return {
        ...state,
        approveBursaryLoading: true,
        approveBursaryError: {},
      };
    case bursaryActions.APPROVE_BURSARY_SUCCESS:
      return {
        ...state,
        approveBursaryLoading: false,
        approveBursarySuccess: actions.data,
      };
    case bursaryActions.APPROVE_BURSARY_ERROR:
      return {
        ...state,
        approveBursaryLoading: false,
        approveBursaryError: actions.error,
      };
    case bursaryActions.UPDATE_BURSARY_DATA:
      return {
        ...state,
        updateBursaryData: actions.data,
      };
    case bursaryActions.UPDATE_BURSARY_COST_DATA:
      return {
        ...state,
        updateBursaryCostData: actions.data,
      };
    case bursaryActions.UPDATE_BURSARY_COST_REQUEST:
      return {
        ...state,
        updateBursaryCostLoading: true,
        updateBursaryCostError: {},
      };
    case bursaryActions.UPDATE_BURSARY_COST_SUCCESS:
      return {
        ...state,
        updateBursaryCostSuccess: actions.data,
        updateBursaryCostLoading: false,
      };
    case bursaryActions.UPDATE_BURSARY_COST_ERROR:
      return {
        ...state,
        updateBursaryCostError: actions.error,
        updateBursaryCostLoading: false,
      };
    case bursaryActions.DELETE_BURSARY_COST_REQUEST:
      return {
        ...state,
        deleteBursaryCostLoading: true,
        deleteBursaryCostError: {},
      };
    case bursaryActions.DELETE_BURSARY_COST_SUCCESS:
      return {
        ...state,
        deleteBursaryCostLoading: false,
        deleteBursaryCostSuccess: actions.data,
      };
    case bursaryActions.DELETE_BURSARY_COST_ERROR:
      return {
        ...state,
        deleteBursaryCostLoading: false,
        deleteBursaryCostError: actions.error,
      };

    default:
      return state;
  }
};
