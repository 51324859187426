const incomeReceiptsInitialState = {
  incomeReceipts: {
    incomeReceiptsLoading: false,
    incomeReceiptsSuccess: {},
    incomeReceiptsError: {},

    metaDataLoading: false,
    metaData: {},

    addIncomeReceiptLoading: false,
    addIncomeReceiptSuccess: {},
    addIncomeReceiptError: {},

    searchIncomeReceiptsLoading: false,
    searchIncomeReceiptsError: {},
    searchIncomeReceiptsSuccess: {},

    updateIncomeReceiptLoading: false,
    updateIncomeReceiptSuccess: {},
    updateIncomeReceiptError: {},
    updateIncomeReceiptData: {},

    deleteIncomeReceiptLoading: false,
    deleteIncomeReceiptSuccess: {},
    deleteIncomeReceiptError: {},

    getIncomeReceiptDetailsLoading: false,
    getIncomeReceiptDetailsSuccess: {},
    getIncomeReceiptDetailsError: {},

    getIncomeReceiptStreamsLoading: false,
    getIncomeReceiptStreamsSuccess: {},
    getIncomeReceiptStreamsError: {},

    addIncomeReceiptStreamLoading: false,
    addIncomeReceiptStreamSuccess: {},
    addIncomeReceiptStreamError: {},

    updateIncomeReceiptStreamLoading: false,
    updateIncomeReceiptStreamSuccess: {},
    updateIncomeReceiptStreamError: {},
    updateIncomeReceiptStreamData: {},

    deleteIncomeReceiptStreamLoading: false,
    deleteIncomeReceiptStreamSuccess: {},
    deleteIncomeReceiptStreamError: {},

    getIncomeReceiptStreamDetailsLoading: false,
    getIncomeReceiptStreamDetailsSuccess: {},
    getIncomeReceiptStreamDetailsError: {},

    addIncomeReceiptDocumentLoading: false,
    addIncomeReceiptDocumentSuccess: {},
    addIncomeReceiptDocumentError: {},

    deleteIncomeReceiptDocumentLoading: false,
    deleteIncomeReceiptDocumentSuccess: {},
    deleteIncomeReceiptDocumentError: {},

    downloadExcelLoading: false,
    downloadExcelSuccess: {},
    downloadExcelError: {},

    uploadExcelLoading: false,
    uploadExcelSuccess: {},
    uploadExcelError: {},

    sortIncomeReceiptsLoading: false,
    sortIncomeReceiptsSuccess: {},
    sortIncomeReceiptsError: {},

    downloadIncomeReceiptsLoading: false,
    downloadIncomeReceiptsSuccess: {},
    downloadIncomeReceiptsError: {},

    syncSchoolPayTransactions: {},
    syncSchoolPayTransactionsLoading: false,
    syncSchoolPayTransactionsError: {},

    setDownloadQueryData: {},

    importSchoolPayReceiptsSuccess: {},
    importSchoolPayReceiptsError: {},
    importSchoolPayReceiptsLoading: false,
  },
};

export default incomeReceiptsInitialState;
