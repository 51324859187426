import { logDetailsActions } from "../../../actions";
import initialState from "../../../initialState";

const logDetails = (state = initialState.logDetails, actions) => {
  switch (actions.type) {
    case logDetailsActions.GET_ORGANISATION_LOG_DETAILS_REQUEST:
      return {
        ...state,
        organisationLogDetailsLoading: true,
      };
    case logDetailsActions.GET_ORGANISATION_LOG_DETAILS_SUCCESS:
      return {
        ...state,
        organisationLogDetailsSuccess: actions.data,
        organisationLogDetailsError: {},
        organisationLogDetailsLoading: false,
      };
    case logDetailsActions.GET_ORGANISATION_LOG_DETAILS_ERROR:
      return {
        ...state,
        organisationLogDetailsLoading: false,
        organisationLogDetailsError: actions.error,
      };
    default:
      return state;
  }
};

export default logDetails;
