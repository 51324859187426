const feesCollectionInitialState = {
  feesCollections: {
    metaData: {},
    metaDataLoading: false,

    feesCollectionsLoading: false,
    feesCollectionsSuccess: {},
    feesCollectionsError: {},

    installmentReportContext: {},
    installmentPaymentReport: {},
    installmentPaymentReportLoading: false,
    installmentPaymentReportError: {},

    downloadInstallmentPaymentReportExcel: {},
    downloadInstallmentPaymentReportExcelLoading: false,
    downloadInstallmentPaymentReportExcelError: {},
  },
  feesDefaultersLoading: false,
  feesDefaultersSuccess: {},
  feesDefaultersError: {},
  feesDefaultersData: {},
  feesDefaultersMessageClass: {},
};

export default feesCollectionInitialState;
