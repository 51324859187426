const permissions = {
  GET_ROLE_PERMISSIONS_REQUEST: "GET_ROLE_PERMISSIONS_REQUEST",
  GET_ROLE_PERMISSIONS_SUCCESS: "GET_ROLE_PERMISSIONS_SUCCESS",
  GET_ROLE_PERMISSIONS_ERROR: "GET_ROLE_PERMISSIONS_ERROR",

  GET_PERMISSIONS_META_DATA_LOADING: "GET_PERMISSIONS_META_DATA_LOADING",
  GET_PERMISSIONS_META_DATA_SUCCESS: "GET_PERMISSIONS_META_DATA_SUCCESS",

  ADD_ROLE_PERMISSIONS_REQUEST: "ADD_ROLE_PERMISSIONS_REQUEST",
  ADD_ROLE_PERMISSIONS_SUCCESS: "ADD_ROLE_PERMISSIONS_SUCCESS",
  ADD_ROLE_PERMISSIONS_ERROR: "ADD_ROLE_PERMISSIONS_ERROR",

  DELETE_PERMISSIONS_REQUEST: "DELETE_PERMISSIONS_REQUEST",
  DELETE_PERMISSIONS_SUCCESS: "DELETE_PERMISSIONS_SUCCESS",
  DELETE_PERMISSIONS_ERROR: "DELETE_PERMISSIONS_ERROR",

  getRolePermissions: (data) => ({
    type: permissions.GET_ROLE_PERMISSIONS_REQUEST,
    data,
  }),
  getPermissionsMetaData: () => ({
    type: permissions.GET_PERMISSIONS_META_DATA_LOADING,
  }),
  addRolePermission: (data) => ({
    type: permissions.ADD_ROLE_PERMISSIONS_REQUEST,
    data,
  }),
  deletePermission: (data) => ({
    type: permissions.DELETE_PERMISSIONS_REQUEST,
    data,
  }),
};

export default permissions;
