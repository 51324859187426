import { takeLatest, put, fork, takeEvery } from "@redux-saga/core/effects";
import axios from "axios";
import {
  advanceAccountabilityFormActions,
  appUiActions,
  requisitionMemoActions,
} from "../../../actions";
const FileDownload = require("js-file-download");

function* getRequisitionMemos() {
  try {
    const response = yield axios({ url: "/finance/requisition-memos" });

    yield put({
      type: requisitionMemoActions.GET_REQUISITION_MEMOS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: requisitionMemoActions.GET_REQUISITION_MEMOS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetRequisitionMemos() {
  yield takeLatest(
    requisitionMemoActions.GET_REQUISITION_MEMOS_REQUEST,
    getRequisitionMemos
  );
}

function* getMetaData({ data }) {
  try {
    const response = yield axios({
      url: "/finance/requisition-memos/meta-data",
      params: data,
    });

    yield put({
      type: requisitionMemoActions.GET_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({ type: requisitionMemoActions.GET_META_DATA_SUCCESS, data: {} });
  }
}

function* watchGetMetaData() {
  yield takeLatest(requisitionMemoActions.GET_META_DATA_REQUEST, getMetaData);
}

function* addRequisitionMemo({ data }) {
  try {
    const response = yield axios({
      url: "/finance/requisition-memos",
      method: "POST",
      data,
    });

    yield put({
      type: requisitionMemoActions.ADD_REQUISITION_MEMO_SUCCESS,
      data: response,
    });
    yield put(requisitionMemoActions.getRequisitionMemos());
    yield put(requisitionMemoActions.getRequisitionMemoDetails(response.id));
    yield put(
      requisitionMemoActions.getMetaData({
        budgetLineId: response.budget_line_id,
      })
    );
    yield put(appUiActions.toggleAddRequisitionMemoModal(false));
    yield put(appUiActions.toggleRequisitionMemoDetailsModal(true));
    yield put(advanceAccountabilityFormActions.getMetaData());
  } catch (error) {
    yield put({
      type: requisitionMemoActions.ADD_REQUISITION_MEMO_ERROR,
      error: error.data,
    });
  }
}

function* watchAddRequsitionMemo() {
  yield takeLatest(
    requisitionMemoActions.ADD_REQUISITION_MEMO_REQUEST,
    addRequisitionMemo
  );
}

function* searchRequisitionMemos({ data }) {
  try {
    const response = yield axios({
      url: "/finance/requisition-memos/search",
      method: "PATCH",
      data,
    });

    yield put({
      type: requisitionMemoActions.SEARCH_REQUISITION_MEMOS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: requisitionMemoActions.SEARCH_REQUISITION_MEMOS_ERROR,
      error: error.data,
    });
  }
}

function* watchSearchRequisitionMemos() {
  yield takeLatest(
    requisitionMemoActions.SEARCH_REQUISITION_MEMOS_REQUEST,
    searchRequisitionMemos
  );
}

function* deleteRequisitionMemos({ data, requisitionMemos }) {
  try {
    const response = yield axios({
      url: `/finance/requisition-memos/${data}`,
      method: "DELETE",
    });

    yield put({
      type: requisitionMemoActions.DELETE_REQUISITION_MEMO_SUCCESS,
      data: response,
      requisitionMemos,
    });
  } catch (error) {
    yield put({
      type: requisitionMemoActions.DELETE_REQUISITION_MEMO_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteRequisitionMemo() {
  yield takeEvery(
    requisitionMemoActions.DELETE_REQUISITION_MEMO_REQUEST,
    deleteRequisitionMemos
  );
}

function* updateRequisitionMemo({ data, id }) {
  try {
    const response = yield axios({
      url: `/finance/requisition-memos/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: requisitionMemoActions.UPDATE_REQUISITION_MEMO_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleUpdateRequisitionMemoModal(false));
    yield put(requisitionMemoActions.getRequisitionMemos());
  } catch (error) {
    yield put({
      type: requisitionMemoActions.UPDATE_REQUISITION_MEMO_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateRequisitionMemo() {
  yield takeLatest(
    requisitionMemoActions.UPDATE_REQUISITION_MEMO_REQUEST,
    updateRequisitionMemo
  );
}

function* getRequisitionMemoDetails({ data }) {
  try {
    const response = yield axios({ url: `/finance/requisition-memos/${data}` });

    yield put({
      type: requisitionMemoActions.GET_REQUISITION_MEMO_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: requisitionMemoActions.GET_REQUISITION_MEMO_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetRequisitionMemoDetails() {
  yield takeLatest(
    requisitionMemoActions.GET_REQUISITION_MEMO_DETAILS_REQUEST,
    getRequisitionMemoDetails
  );
}

function* addRequisitionMemoCost({ data }) {
  try {
    const response = yield axios({
      url: "/finance/requisition-memos/costs",
      method: "POST",
      data,
    });

    yield put({
      type: requisitionMemoActions.ADD_REQUISITION_MEMO_COST_SUCCESS,
      data: response,
    });

    yield put(
      requisitionMemoActions.getRequisitionMemoDetails(data.requisition_memo_id)
    );
  } catch (error) {
    yield put({
      type: requisitionMemoActions.ADD_REQUISITION_MEMO_COST_ERROR,
      error: error.data,
    });
  }
}

function* watchAddRequisitionMemoCost() {
  yield takeLatest(
    requisitionMemoActions.ADD_REQUISITION_MEMO_COST_REQUEST,
    addRequisitionMemoCost
  );
}

function* updateRequsitionMemoCost({ data, id }) {
  try {
    const response = yield axios({
      url: `/finance/requisition-memos/costs/${id}`,
      data,
      method: "PUT",
    });

    yield put({
      type: requisitionMemoActions.UPDATE_REQUISITION_MEMO_COST_SUCCESS,
      data: response,
    });

    yield put(appUiActions.toggleUpdateRequisitionMemoCostModal(false));
    yield put(
      requisitionMemoActions.getRequisitionMemoDetails(
        data?.requisition_memo_id
      )
    );
  } catch (error) {
    yield put({
      type: requisitionMemoActions.UPDATE_REQUISITION_MEMO_COST_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateRequisitionMemoCost() {
  yield takeLatest(
    requisitionMemoActions.UPDATE_REQUISITION_MEMO_COST_REQUEST,
    updateRequsitionMemoCost
  );
}

function* deleteRequisitionMemoCost({ data, params }) {
  try {
    const response = yield axios({
      url: `/finance/requisition-memos/costs/${data}`,
      method: "DELETE",
      params,
    });

    yield put({
      type: requisitionMemoActions.DELETE_REQUISITION_MEMO_COST_SUCCESS,
      data: response,
    });
    yield put(requisitionMemoActions.getRequisitionMemos());
  } catch (error) {
    yield put({
      type: requisitionMemoActions.DELETE_REQUISITION_MEMO_COST_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteRequisitionMemoCost() {
  yield takeEvery(
    requisitionMemoActions.DELETE_REQUISITION_MEMO_COST_REQUEST,
    deleteRequisitionMemoCost
  );
}

function* getRequisitionMemoCostDetails({ data }) {
  try {
    const response = yield axios({
      url: `/finance/requisition-memos/costs/${data}`,
    });

    yield put({
      type: requisitionMemoActions.GET_REQUISITION_MEMO_COST_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: requisitionMemoActions.GET_REQUISITION_MEMO_COST_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetRequisitionMemoCostDetails() {
  yield takeLatest(
    requisitionMemoActions.GET_REQUISITION_MEMO_COST_DETAILS_REQUEST,
    getRequisitionMemoCostDetails
  );
}

function* submitRequisitionMemo({ data }) {
  try {
    const response = yield axios({
      url: `/finance/requisition-memos/submit/${data}`,
      method: "PATCH",
    });

    yield put({
      type: requisitionMemoActions.SUBMIT_REQUISITION_MEMO_SUCCESS,
      data: response,
    });
    yield put(requisitionMemoActions.getRequisitionMemoDetails(data));
  } catch (error) {
    yield put({
      type: requisitionMemoActions.SUBMIT_REQUISITION_MEMO_ERROR,
      error: error.data,
    });
  }
}

function* watchSubmitRequisitionMemo() {
  yield takeLatest(
    requisitionMemoActions.SUBMIT_REQUISITION_MEMO_REQUEST,
    submitRequisitionMemo
  );
}

function* checkRequisitionMemo({ data }) {
  try {
    const response = yield axios({
      url: `/finance/requisition-memos/check/${data}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({
      type: requisitionMemoActions.CHECK_REQUISITION_MEMO_SUCCESS,
      data: response,
    });
    yield put(requisitionMemoActions.getRequisitionMemoDetails(data));
  } catch (error) {
    yield put({
      type: requisitionMemoActions.CHECK_REQUISITION_MEMO_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckRequisitionMemo() {
  yield takeLatest(
    requisitionMemoActions.CHECK_REQUISITION_MEMO_REQUEST,
    checkRequisitionMemo
  );
}

function* approveRequisitionMemo({ data }) {
  try {
    const response = yield axios({
      url: `/finance/requisition-memos/approve/${data}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: requisitionMemoActions.APPROVE_REQUISITION_MEMO_SUCCESS,
      data: response,
    });
    yield put(requisitionMemoActions.getRequisitionMemoDetails(data));
    yield put(advanceAccountabilityFormActions.getMetaData());
  } catch (error) {
    yield put({
      type: requisitionMemoActions.APPROVE_REQUISITION_MEMO_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveRequisitionMemo() {
  yield takeLatest(
    requisitionMemoActions.APPROVE_REQUISITION_MEMO_REQUEST,
    approveRequisitionMemo
  );
}

function* sortRequisitionMemos({ data }) {
  try {
    const response = yield axios({
      url: `/finance/requisition-memos/sort`,
      method: "GET",
      params: data,
    });

    yield put({
      type: requisitionMemoActions.SORT_REQUISITION_MEMOS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: requisitionMemoActions.SORT_REQUISITION_MEMOS_ERROR,
      error: error.data,
    });
    yield put(appUiActions.toggleSortFromTableModal(false));
  }
}

function* watchSortRequisitionMemos() {
  yield takeLatest(
    requisitionMemoActions.SORT_REQUISITION_MEMOS_REQUEST,
    sortRequisitionMemos
  );
}

function* downloadRequisitionMemos({ data }) {
  try {
    const response = yield axios({
      url: `/finance/requisition-memos/excel-report`,
      method: "GET",
      params: data,
      responseType: "blob",
    });

    FileDownload(response.data, "requisition-forms.xlsx");

    yield put({
      type: requisitionMemoActions.DOWNLOAD_REQUISITION_MEMOS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: requisitionMemoActions.DOWNLOAD_REQUISITION_MEMOS_ERROR,
      error: error.data,
    });
  }
}

function* watchDownloadRequisitionMemos() {
  yield takeLatest(
    requisitionMemoActions.DOWNLOAD_REQUISITION_MEMOS_REQUEST,
    downloadRequisitionMemos
  );
}

export default [
  fork(watchGetRequisitionMemos),
  fork(watchGetMetaData),
  fork(watchAddRequsitionMemo),
  fork(watchSearchRequisitionMemos),
  fork(watchDeleteRequisitionMemo),
  fork(watchGetRequisitionMemoDetails),
  fork(watchUpdateRequisitionMemo),
  fork(watchAddRequisitionMemoCost),
  fork(watchUpdateRequisitionMemoCost),
  fork(watchDeleteRequisitionMemoCost),
  fork(watchGetRequisitionMemoCostDetails),
  fork(watchSubmitRequisitionMemo),
  fork(watchCheckRequisitionMemo),
  fork(watchApproveRequisitionMemo),
  fork(watchSortRequisitionMemos),
  fork(watchDownloadRequisitionMemos),
];
