import React from "react";
import propTypes from "prop-types";
import { Spin } from "antd";

const PageLoader = ({ message = "Please wait..." }) => {
  return (
    <div className="vh-100 text-center bg-light d-flex flex-column justify-content-center align-items-center m-0 p-4">
      <div className="align-middle my-auto mx-auto">
        <h2 className="text-primary">SCHOOLBOOK</h2>
        <Spin size="LARGE" />
        <div className="font500 text-uppercase text-sm text-secondary mt-3">
          {message}
        </div>
      </div>
    </div>
  );
};

PageLoader.propTypes = {
  message: propTypes.string,
};

export default PageLoader;
