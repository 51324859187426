const singleLearnerLedger = {
    GET_SINGLE_LEARNER_LEDGER: 'GET_SINGLE_LEARNER_LEDGER',
    GET_SINGLE_LEARNER_LEDGER_SUCCESS: 'GET_SINGLE_LEARNER_LEDGER_SUCCESS',
    GET_SINGLE_LEARNER_LEDGER_ERROR: 'GET_SINGLE_LEARNER_LEDGER_ERROR',

    GET_SINGLE_LEARNER_LEDGER_META_DATA: 'GET_SINGLE_LEARNER_LEDGER_META_DATA',
    GET_SINGLE_LEARNER_LEDGER_META_DATA_SUCCESS: 'GET_SINGLE_LEARNER_LEDGER_META_DATA_SUCCESS',
    GET_SINGLE_LEARNER_LEDGER_META_DATA_ERROR: 'GET_SINGLE_LEARNER_LEDGER_META_DATA_ERROR',

    SET_SINGLE_LEARNER_LEDGER_DATA: 'SET_SINGLE_LEARNER_LEDGER_DATA',

    getSingleLearnerLedger: (data) => ({
        type: singleLearnerLedger.GET_SINGLE_LEARNER_LEDGER,
        data,
    }),
    getSingleLearnerLedgerMetaData: () => ({
        type: singleLearnerLedger.GET_SINGLE_LEARNER_LEDGER_META_DATA,
    }),
    setSingleLearnerLedgerData: (data) => ({
        type: singleLearnerLedger.SET_SINGLE_LEARNER_LEDGER_DATA,
        data,
    }),
}

export default singleLearnerLedger