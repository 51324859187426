import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { schoolDevelopmentPlanActions } from "../../../actions";

function* getSchoolDevelopmentPlan() {
  try {
    const response = yield axios({
      url: "/administration/school-development-plan",
    });
    yield put({
      type: schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSchoolDevelopmentPlan() {
  yield takeLatest(
    schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_REQUEST,
    getSchoolDevelopmentPlan
  );
}

function* addSchoolDevelopmentPlan({ data }) {
  try {
    const response = yield axios({
      url: "/administration/school-development-plan",
      data,
      method: "POST",
    });
    yield put({
      type: schoolDevelopmentPlanActions.ADD_SCHOOL_DEVELOPMENT_PLAN_SUCCESS,
      data: response,
    });
    yield put(schoolDevelopmentPlanActions.getSchoolDevelopmentPlan());
  } catch (error) {
    yield put({
      type: schoolDevelopmentPlanActions.ADD_SCHOOL_DEVELOPMENT_PLAN_ERROR,
      error: error.data,
    });
  }
}

function* watchAddSchoolDevelopmentPlan() {
  yield takeLatest(
    schoolDevelopmentPlanActions.ADD_SCHOOL_DEVELOPMENT_PLAN_REQUEST,
    addSchoolDevelopmentPlan
  );
}

function* updateSchoolDevelopmentPlan({ id, data }) {
  try {
    const response = yield axios({
      url: `/administration/school-development-plan/${id}`,
      data,
      method: "PUT",
    });
    yield put({
      type: schoolDevelopmentPlanActions.UPDATE_SCHOOL_DEVELOPMENT_PLAN_SUCCESS,
      data: {
        ...response,
        data,
      },
    });
  } catch (error) {
    yield put({
      type: schoolDevelopmentPlanActions.UPDATE_SCHOOL_DEVELOPMENT_PLAN_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateSchoolDevelopmentPlan() {
  yield takeLatest(
    schoolDevelopmentPlanActions.UPDATE_SCHOOL_DEVELOPMENT_PLAN_REQUEST,
    updateSchoolDevelopmentPlan
  );
}

function* deleteSchoolDevelopmentPlan({ id }) {
  try {
    const response = yield axios({
      url: `/administration/school-development-plan/${id}`,
      method: "DELETE",
    });
    yield put({
      type: schoolDevelopmentPlanActions.DELETE_SCHOOL_DEVELOPMENT_PLAN_SUCCESS,
      data: {
        ...response,
        id,
      },
    });
  } catch (error) {
    yield put({
      type: schoolDevelopmentPlanActions.DELETE_SCHOOL_DEVELOPMENT_PLAN_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteSchoolDevelopmentPlan() {
  yield takeLatest(
    schoolDevelopmentPlanActions.DELETE_SCHOOL_DEVELOPMENT_PLAN_REQUEST,
    deleteSchoolDevelopmentPlan
  );
}

function* addSchoolDevelopmentPlanExpenditure({ id, data }) {
  try {
    const response = yield axios({
      url: `/administration/school-development-plan/expenditure?schoolDevelopmentPlanId=${id}`,
      data,
      method: "POST",
    });
    yield put({
      type: schoolDevelopmentPlanActions.ADD_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_SUCCESS,
      data: {
        ...response,
        data,
        id,
      },
    });
    yield put(schoolDevelopmentPlanActions.getSchoolDevelopmentPlanDetails(id));
  } catch (error) {
    yield put({
      type: schoolDevelopmentPlanActions.ADD_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_ERROR,
      error: error.data,
    });
  }
}

function* watchAddSchoolDevelopmentPlanExpenditure() {
  yield takeLatest(
    schoolDevelopmentPlanActions.ADD_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_REQUEST,
    addSchoolDevelopmentPlanExpenditure
  );
}

function* updateSchoolDevelopmentPlanExpenditure({ id, data }) {
  try {
    const response = yield axios({
      url: `/administration/school-development-plan/expenditure/${id}`,
      data,
      method: "PUT",
    });
    yield put({
      type: schoolDevelopmentPlanActions.UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_SUCCESS,
      data: {
        ...response,
        data,
        id,
      },
    });
    yield put(
      schoolDevelopmentPlanActions.getSchoolDevelopmentPlanDetails(
        data.details_id
      )
    );
  } catch (error) {
    yield put({
      type: schoolDevelopmentPlanActions.UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateSchoolDevelopmentPlanExpenditure() {
  yield takeLatest(
    schoolDevelopmentPlanActions.UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_REQUEST,
    updateSchoolDevelopmentPlanExpenditure
  );
}

function* deleteSchoolDevelopmentPlanExpenditure({ id, plan_id }) {
  try {
    const response = yield axios({
      url: `/administration/school-development-plan/expenditure/${id}`,
      method: "DELETE",
    });
    yield put({
      type: schoolDevelopmentPlanActions.DELETE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_SUCCESS,
      data: {
        ...response,
        id,
        plan_id,
      },
    });
    yield put(
      schoolDevelopmentPlanActions.getSchoolDevelopmentPlanDetails(plan_id)
    );
  } catch (error) {
    yield put({
      type: schoolDevelopmentPlanActions.DELETE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteSchoolDevelopmentPlanExpenditure() {
  yield takeLatest(
    schoolDevelopmentPlanActions.DELETE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_REQUEST,
    deleteSchoolDevelopmentPlanExpenditure
  );
}

function* checkSchoolDevelopmentPlan({ id }) {
  try {
    const response = yield axios({
      url: `/administration/school-development-plan/check/${id}`,
      method: "PATCH",
    });
    yield put({
      type: schoolDevelopmentPlanActions.CHECK_SCHOOL_DEVELOPMENT_PLAN_SUCCESS,
      data: {
        ...response,
        id,
      },
    });
    yield put(schoolDevelopmentPlanActions.getSchoolDevelopmentPlanDetails(id));
  } catch (error) {
    yield put({
      type: schoolDevelopmentPlanActions.CHECK_SCHOOL_DEVELOPMENT_PLAN_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckSchoolDevelopmentPlan() {
  yield takeLatest(
    schoolDevelopmentPlanActions.CHECK_SCHOOL_DEVELOPMENT_PLAN_REQUEST,
    checkSchoolDevelopmentPlan
  );
}

function* approveSchoolDevelopmentPlan({ id }) {
  try {
    const response = yield axios({
      url: `/administration/school-development-plan/approve/${id}`,
      method: "PATCH",
    });
    yield put({
      type: schoolDevelopmentPlanActions.APPROVE_SCHOOL_DEVELOPMENT_PLAN_SUCCESS,
      data: {
        ...response,
        id,
      },
    });
    yield put(schoolDevelopmentPlanActions.getSchoolDevelopmentPlanDetails(id));
  } catch (error) {
    yield put({
      type: schoolDevelopmentPlanActions.APPROVE_SCHOOL_DEVELOPMENT_PLAN_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveSchoolDevelopmentPlan() {
  yield takeLatest(
    schoolDevelopmentPlanActions.APPROVE_SCHOOL_DEVELOPMENT_PLAN_REQUEST,
    approveSchoolDevelopmentPlan
  );
}

function* getSchoolDevelopmentPlanDetails({ id }) {
  try {
    const response = yield axios({
      url: `/administration/school-development-plan/${id}`,
      method: "GET",
    });
    yield put({
      type: schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSchoolDevelopmentPlanDetails() {
  yield takeLatest(
    schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_DETAILS_REQUEST,
    getSchoolDevelopmentPlanDetails
  );
}

function* getSchoolDevelopmentPlanMetaData() {
  try {
    const response = yield axios({
      url: "/administration/school-development-plan/meta-data",
      method: "GET",
    });
    yield put({
      type: schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSchoolDevelopmentPlanMetaData() {
  yield takeLatest(
    schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_META_DATA_REQUEST,
    getSchoolDevelopmentPlanMetaData
  );
}

export default [
  fork(watchGetSchoolDevelopmentPlan),
  fork(watchAddSchoolDevelopmentPlan),
  fork(watchUpdateSchoolDevelopmentPlan),
  fork(watchDeleteSchoolDevelopmentPlan),
  fork(watchAddSchoolDevelopmentPlanExpenditure),
  fork(watchUpdateSchoolDevelopmentPlanExpenditure),
  fork(watchDeleteSchoolDevelopmentPlanExpenditure),
  fork(watchCheckSchoolDevelopmentPlan),
  fork(watchApproveSchoolDevelopmentPlan),
  fork(watchGetSchoolDevelopmentPlanDetails),
  fork(watchGetSchoolDevelopmentPlanMetaData),
];
