import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import {
  organisationUsersActions,
  appUiActions,
  staffPayRollActions,
} from "../../../actions";
const FileDownload = require("js-file-download");

function* getOrganisationUsers() {
  try {
    const response = yield axios({
      url: "/organisations/users",
    });

    yield put({
      type: organisationUsersActions.GET_ORGANISATION_USERS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: organisationUsersActions.GET_ORGANISATION_USERS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetOrganisationUsers() {
  yield takeLatest(
    organisationUsersActions.GET_ORGANISATION_USERS_REQUEST,
    getOrganisationUsers
  );
}

function* getOrganisationUserMetadata() {
  try {
    const response = yield axios({ url: "/organisations/users/meta-data" });
    yield put({
      type: organisationUsersActions.GET_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: organisationUsersActions.GET_META_DATA_SUCCESS,
      data: {},
    });
  }
}

function* watchGetOrganisationUsersMetaData() {
  yield takeLatest(
    organisationUsersActions.GET_META_DATA_REQUEST,
    getOrganisationUserMetadata
  );
}

function* addOrganisationUsers({ data, role }) {
  try {
    const response = yield axios({
      url: "/organisations/users",
      method: "POST",
      data,
      params: { role },
    });

    yield put({
      type: organisationUsersActions.ADD_ORGANISATION_USERS_SUCCESS,
      data: response,
    });

    yield put({
      type: organisationUsersActions.GET_ORGANISATION_USERS_REQUEST,
    });
    yield put(staffPayRollActions.getStaffPayRollMetaData());
  } catch (error) {
    yield put({
      type: organisationUsersActions.ADD_ORGANISATION_USERS_ERROR,
      error: error.data,
    });
  }
}

function* watchAddOrganisationUsers() {
  yield takeLatest(
    organisationUsersActions.ADD_ORGANISATION_USERS_REQUEST,
    addOrganisationUsers
  );
}

function* updateOrganisationUsers({ data, organisationUserId }) {
  try {
    const response = yield axios({
      url: `/organisations/users/${organisationUserId}`,
      method: "PUT",
      data,
    });

    yield put({
      type: organisationUsersActions.UPDATE_ORGANISATION_USERS_SUCCESS,
      data: response,
    });

    yield put(organisationUsersActions.getOrganisationUsers());
    yield put(staffPayRollActions.getStaffPayRollMetaData());
  } catch (error) {
    yield put({
      type: organisationUsersActions.UPDATE_ORGANISATION_USERS_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateOrganisationUsers() {
  yield takeLatest(
    organisationUsersActions.UPDATE_ORGANISATION_USERS_REQUEST,
    updateOrganisationUsers
  );
}

function* getOrganisationUserDetails({ data }) {
  try {
    const response = yield axios({ url: `/organisations/users/${data}` });
    yield put({
      type: organisationUsersActions.GET_ORGANISATION_USER_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: organisationUsersActions.GET_ORGANISATION_USER_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetOrganisationUserDetails() {
  yield takeLatest(
    organisationUsersActions.GET_ORGANISATION_USER_DETAILS_REQUEST,
    getOrganisationUserDetails
  );
}

function* activateUserAccount({ data }) {
  try {
    const response = yield axios({
      url: `/organisations/users/activate-account/${data.id}`,
      params: { organisationRoleId: data.role },
      method: "PATCH",
    });

    yield put({
      type: organisationUsersActions.ACTIVATE_ACCOUNT_SUCCESS,
      data: response,
    });

    yield put({
      type: organisationUsersActions.GET_ORGANISATION_USER_DETAILS_REQUEST,
      data: data.id,
    });
    yield put(staffPayRollActions.getStaffPayRollMetaData());
  } catch (error) {
    put({
      type: organisationUsersActions.ACTIVATE_ACCOUNT_ERROR,
      error: error.data,
    });
  }
}

function* watchActivateUserAccount() {
  yield takeLatest(
    organisationUsersActions.ACTIVATE_ACCOUNT_REQUEST,
    activateUserAccount
  );
}

function* deactivateUserAccount({ data }) {
  try {
    const response = yield axios({
      url: `/organisations/users/deactivate-account/${data.id}`,
      params: { organisationRoleId: data.role },
      method: "PATCH",
    });

    yield put({
      type: organisationUsersActions.DEACTIVATE_ACCOUNT_SUCCESS,
      data: response,
    });

    yield put({
      type: organisationUsersActions.GET_ORGANISATION_USER_DETAILS_REQUEST,
      data: data.id,
    });
    yield put(staffPayRollActions.getStaffPayRollMetaData());
  } catch (error) {
    yield put({
      type: organisationUsersActions.DEACTIVATE_ACCOUNT_ERROR,
      error: error.data,
    });
  }
}

function* watchDectivateUserAccount() {
  yield takeLatest(
    organisationUsersActions.DEACTIVATE_ACCOUNT_REQUEST,
    deactivateUserAccount
  );
}

function* createStaffDetails({ data }) {
  try {
    const response = yield axios({
      url: "/human-resource/staff-details",
      method: "POST",
      data,
    });

    yield put({
      type: organisationUsersActions.CREATE_STAFF_DETAILS_SUCCESS,
      data: response,
    });

    yield put(
      organisationUsersActions.getOrganisationUserDetails(
        data.organisationUser_id
      )
    );
  } catch (error) {
    yield put({
      type: organisationUsersActions.CREATE_STAFF_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchCreateStaffDetails() {
  yield takeLatest(
    organisationUsersActions.CREATE_STAFF_DETAILS_REQUEST,
    createStaffDetails
  );
}

function* updateStaffDetails({ data, id }) {
  try {
    const response = yield axios({
      url: `/human-resource/staff-details/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: organisationUsersActions.UPDATE_STAFF_DETAILS_SUCCESS,
      data: response,
    });

    yield put(
      organisationUsersActions.getOrganisationUserDetails(
        data.organisationUser_id
      )
    );
  } catch (error) {
    yield put({
      type: organisationUsersActions.UPDATE_STAFF_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateStaffDetails() {
  yield takeLatest(
    organisationUsersActions.UPDATE_STAFF_DETAILS_REQUEST,
    updateStaffDetails
  );
}

function* deleteStaffDetails({ id }) {
  try {
    const response = yield axios({
      url: `/human-resource/staff-details/${id}`,
      method: "DELETE",
    });

    yield put({
      type: organisationUsersActions.DELETE_STAFF_DETAILS_SUCCESS,
      data: response,
    });

    yield put(organisationUsersActions.getOrganisationUserDetails(id));
  } catch (error) {
    yield put({
      type: organisationUsersActions.DELETE_STAFF_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteStaffDetails() {
  yield takeLatest(
    organisationUsersActions.DELETE_STAFF_DETAILS_REQUEST,
    deleteStaffDetails
  );
}

function* createStaffDependants({ data }) {
  try {
    const response = yield axios({
      url: "/human-resource/staff-details/dependants",
      method: "POST",
      data,
    });

    yield put({
      type: organisationUsersActions.CREATE_STAFF_DEPENDANTS_SUCCESS,
      data: response,
    });

    yield put(
      organisationUsersActions.getOrganisationUserDetails(
        data.organisationUser_id
      )
    );
    yield put(appUiActions.toggleAddStaffDependantsModal(false));
  } catch (error) {
    yield put({
      type: organisationUsersActions.CREATE_STAFF_DEPENDANTS_ERROR,
      error: error.data,
    });
  }
}

function* watchCreateStaffDependants() {
  yield takeLatest(
    organisationUsersActions.CREATE_STAFF_DEPENDANTS_REQUEST,
    createStaffDependants
  );
}

function* updateStaffDependants({ data, id }) {
  try {
    const response = yield axios({
      url: `/human-resource/staff-details/dependants/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: organisationUsersActions.UPDATE_STAFF_DEPENDANTS_SUCCESS,
      data: response,
    });

    yield put(
      organisationUsersActions.getOrganisationUserDetails(
        data.organisationUser_id
      )
    );
    yield put(appUiActions.toggleUpdateStaffDependantsModal(false));
  } catch (error) {
    yield put({
      type: organisationUsersActions.UPDATE_STAFF_DEPENDANTS_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateStaffDependants() {
  yield takeLatest(
    organisationUsersActions.UPDATE_STAFF_DEPENDANTS_REQUEST,
    updateStaffDependants
  );
}

function* deleteStaffDependants({ id, user_id }) {
  try {
    const response = yield axios({
      url: `/human-resource/staff-details/dependants/${id}`,
      method: "DELETE",
    });

    yield put({
      type: organisationUsersActions.DELETE_STAFF_DEPENDANTS_SUCCESS,
      data: response,
    });

    yield put(organisationUsersActions.getOrganisationUserDetails(user_id));
  } catch (error) {
    yield put({
      type: organisationUsersActions.DELETE_STAFF_DEPENDANTS_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteStaffDependants() {
  yield takeLatest(
    organisationUsersActions.DELETE_STAFF_DEPENDANTS_REQUEST,
    deleteStaffDependants
  );
}

function* uploadStaffDocuments({ data, id }) {
  try {
    const response = yield axios({
      url: "/human-resource/staff-details/documents",
      method: "POST",
      data,
    });

    yield put({
      type: organisationUsersActions.UPLOAD_STAFF_DOCUMENTS_SUCCESS,
      data: response,
    });

    yield put(organisationUsersActions.getOrganisationUserDetails(id));
    yield put(appUiActions.toggleUploadStaffDocumentsModal(false));
  } catch (error) {
    yield put({
      type: organisationUsersActions.UPLOAD_STAFF_DOCUMENTS_ERROR,
      error: error.data,
    });
  }
}

function* watchUploadStaffDocuments() {
  yield takeLatest(
    organisationUsersActions.UPLOAD_STAFF_DOCUMENTS_REQUEST,
    uploadStaffDocuments
  );
}

function* deleteStaffDocuments({ id, user_id }) {
  try {
    const response = yield axios({
      url: `/human-resource/staff-details/documents/${id}`,
      method: "DELETE",
    });

    yield put({
      type: organisationUsersActions.DELETE_STAFF_DOCUMENTS_SUCCESS,
      data: response,
    });

    yield put(organisationUsersActions.getOrganisationUserDetails(user_id));
  } catch (error) {
    yield put({
      type: organisationUsersActions.DELETE_STAFF_DOCUMENTS_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteStaffDocuments() {
  yield takeLatest(
    organisationUsersActions.DELETE_STAFF_DOCUMENTS_REQUEST,
    deleteStaffDocuments
  );
}

function* updateStaffDocuments({ data, id }) {
  try {
    const response = yield axios({
      url: `/human-resource/staff-details/documents/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: organisationUsersActions.UPDATE_STAFF_DOCUMENTS_SUCCESS,
      data: response,
    });

    yield put(
      organisationUsersActions.getOrganisationUserDetails(
        data.organisationUser_id
      )
    );
    yield put(appUiActions.toggleUpdateStaffDocumentsModal(false));
  } catch (error) {
    yield put({
      type: organisationUsersActions.UPDATE_STAFF_DOCUMENTS_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateStaffDocuments() {
  yield takeLatest(
    organisationUsersActions.UPDATE_STAFF_DOCUMENTS_REQUEST,
    updateStaffDocuments
  );
}

function* getStaffQualificationDetails({ id }) {
  try {
    const response = yield axios({
      url: `/human-resource/staff-details/qualifications/${id}`,
      method: "GET",
    });

    yield put({
      type: organisationUsersActions.STAFF_QUALIFICATION_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: organisationUsersActions.STAFF_QUALIFICATION_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetStaffQualificationDetails() {
  yield takeLatest(
    organisationUsersActions.STAFF_QUALIFICATION_DETAILS_REQUEST,
    getStaffQualificationDetails
  );
}

function* addStaffQualification({ data }) {
  try {
    const response = yield axios({
      url: `/human-resource/staff-details/qualifications`,
      method: "POST",
      data,
    });

    yield put({
      type: organisationUsersActions.ADD_STAFF_QUALIFICATION_SUCCESS,
      data: response,
    });

    yield put(
      organisationUsersActions.getOrganisationUserDetails(
        data.organisationUser_id
      )
    );
    yield put(appUiActions.toggleAddStaffQualificationModal(false));
  } catch (error) {
    yield put({
      type: organisationUsersActions.ADD_STAFF_QUALIFICATION_ERROR,
      error: error.data,
    });
  }
}

function* watchAddStaffQualification() {
  yield takeLatest(
    organisationUsersActions.ADD_STAFF_QUALIFICATION_REQUEST,
    addStaffQualification
  );
}

function* updateStaffQualification({ id, data }) {
  try {
    const response = yield axios({
      url: `/human-resource/staff-details/qualifications/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: organisationUsersActions.UPDATE_STAFF_QUALIFICATION_SUCCESS,
      data: response,
    });

    yield put(
      organisationUsersActions.getOrganisationUserDetails(
        data.organisationUser_id
      )
    );
    yield put(appUiActions.toggleUpdateStaffQualificationModal(false));
  } catch (error) {
    yield put({
      type: organisationUsersActions.UPDATE_STAFF_QUALIFICATION_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateStaffQualification() {
  yield takeLatest(
    organisationUsersActions.UPDATE_STAFF_QUALIFICATION_REQUEST,
    updateStaffQualification
  );
}

function* deleteStaffQualification({ id, user_id }) {
  try {
    const response = yield axios({
      url: `/human-resource/staff-details/qualifications/${id}`,
      method: "DELETE",
    });

    yield put({
      type: organisationUsersActions.DELETE_STAFF_QUALIFICATION_SUCCESS,
      data: response,
    });

    yield put(organisationUsersActions.getOrganisationUserDetails(user_id));
  } catch (error) {
    yield put({
      type: organisationUsersActions.DELETE_STAFF_QUALIFICATION_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteStaffQualification() {
  yield takeLatest(
    organisationUsersActions.DELETE_STAFF_QUALIFICATION_REQUEST,
    deleteStaffQualification
  );
}

function* generateNewConfirmationToken({ id }) {
  try {
    const response = yield axios({
      url: `/organisations/users/generate-new-comfirmation/${id}`,
      method: "PATCH",
    });

    yield put({
      type: organisationUsersActions.GENERATE_NEW_CONFIRMATION_TOKEN_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: organisationUsersActions.GENERATE_NEW_CONFIRMATION_TOKEN_ERROR,
      error: error.data,
    });
  }
}

function* watchGenerateNewConfirmationToken() {
  yield takeLatest(
    organisationUsersActions.GENERATE_NEW_CONFIRMATION_TOKEN_REQUEST,
    generateNewConfirmationToken
  );
}

function* updateStaffProfile({ data, id }) {
  try {
    const response = yield axios({
      url: `/organisations/users/account-update/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: organisationUsersActions.UPDATE_STAFF_PROFILE_SUCCESS,
      data: response,
    });

    yield put(
      organisationUsersActions.getOrganisationUserDetails(
        data.organisationUser_id
      )
    );
    yield put(organisationUsersActions.getOrganisationUsers());
  } catch (error) {
    yield put({
      type: organisationUsersActions.UPDATE_STAFF_PROFILE_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateStaffProfile() {
  yield takeLatest(
    organisationUsersActions.UPDATE_STAFF_PROFILE_REQUEST,
    updateStaffProfile
  );
}

function* downloadStaffUploadTemplate() {
  try {
    const response = yield axios({
      url: `/organisations/users/download-staff-excel-template`,
      method: "GET",
      responseType: "blob",
    });

    FileDownload(
      response?.data,
      `NEW STAFF UPLOAD TEMPLATE-${Date.now()}.xlsx`
    );

    yield put({
      type: organisationUsersActions.DOWNLOAD_STAFF_UPLOAD_TEMPLATE_SUCCESS,
      data: {},
    });
  } catch (error) {
    yield put({
      type: organisationUsersActions.DOWNLOAD_STAFF_UPLOAD_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* watchDownloadStaffUploadTemplate() {
  yield takeLatest(
    organisationUsersActions.DOWNLOAD_STAFF_UPLOAD_TEMPLATE_REQUEST,
    downloadStaffUploadTemplate
  );
}

export default [
  fork(watchGetOrganisationUsers),
  fork(watchGetOrganisationUsersMetaData),
  fork(watchAddOrganisationUsers),
  fork(watchUpdateOrganisationUsers),
  fork(watchGetOrganisationUserDetails),
  fork(watchActivateUserAccount),
  fork(watchDectivateUserAccount),
  fork(watchCreateStaffDetails),
  fork(watchUpdateStaffDetails),
  fork(watchDeleteStaffDetails),
  fork(watchCreateStaffDependants),
  fork(watchUpdateStaffDependants),
  fork(watchDeleteStaffDependants),
  fork(watchUploadStaffDocuments),
  fork(watchDeleteStaffDocuments),
  fork(watchUpdateStaffDocuments),
  fork(watchGetStaffQualificationDetails),
  fork(watchAddStaffQualification),
  fork(watchUpdateStaffQualification),
  fork(watchDeleteStaffQualification),
  fork(watchGenerateNewConfirmationToken),
  fork(watchUpdateStaffProfile),
  fork(watchDownloadStaffUploadTemplate),
];
