const budgetsInitiaState = {
  budgets: {
    budgetsLoading: false,
    budgetsSuccess: {},
    budgetsError: {},

    metaDataLoading: false,
    metaData: {},

    addBudgetsLoading: false,
    addBudgetsSuccess: {},
    addBudgetsError: {},

    deleteBudgetsLoading: false,
    deleteBudgetsSuccess: {},
    deleteBudgetsError: {},

    updateBudgetData: {},
    updateBudgetLoading: false,
    updateBudgetSuccess: {},
    updateBudgetError: {},

    budgetDetailsLoading: false,
    budgetDetailsSuccess: {},
    budgetDetailsError: {},

    budgetEstimateLoading: false,
    budgetEstimateSuccess: {},
    budgetEstimateError: {},

    addBudgetLineLoading: false,
    addBudgetLineData: {},
    addBudgetLineSuccess: {},
    addBudgetLineError: {},

    deleteBudgetLineSuccess: {},
    deleteBudgetLineLoading: false,
    deleteBudgetLineError: {},

    updateBudgetLineData: {},
    updateBudgetLineLoading: false,
    updateBudgetLineSuccess: {},
    updateBudgetLineError: {},

    budgetLineDetailsSuccess: {},
    budgetLineDetailsLoading: false,
    budgetLineDetailsError: {},

    addBudgetStreamsLoading: false,
    addBudgetStreamsSuccess: {},
    addBudgetStreamsError: {},

    deleteBudgetStreamLoading: false,
    deleteBudgetStreamSuccess: {},
    deleteBudgetStreamError: {},

    updateBudgetStreamData: {},
    updateBudgetStreamLoading: false,
    updateBudgetStreamSuccess: {},
    updateBudgetStreamError: {},

    budgetStreamDetailsLoading: false,
    budgetStreamDetailsSuccess: {},
    budgetStreamDetailsError: {},

    submitBudgetLoading: false,
    submitBudgetSuccess: {},
    submitBudgetError: {},

    checkBudgetLoading: false,
    checkBudgetSuccess: {},
    checkBudgetError: {},

    approveBudgetLoading: false,
    approveBudgetSuccess: {},
    approveBudgetError: {},

    budgetStatementsLoading: false,
    budgetStatementsSuccess: {},
    budgetStatementsError: {},

    copyBudgetLoading: false,
    copyBudgetSuccess: {},
    copyBudgetError: {},
  },
};

export default budgetsInitiaState;
