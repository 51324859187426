const assetsInitialState = {
  assets: {
    assetsLoading: false,
    assetsSuccess: {},
    assetsError: {},

    metaDataLoading: false,
    metaDataSuccess: {},

    addAssetData: {},
    addAssetLoading: false,
    addAssetSuccess: {},
    addAssetError: {},

    deleteAssetLoading: false,
    deleteAssetSuccess: {},
    deleteAssetError: {},

    updateAssetData: {},
    updateAssetLoading: false,
    updateAssetSuccess: {},
    updateAssetError: {},

    assetDetailsLoading: false,
    assetDetailsSuccess: {},
    assetDetailsError: {},

    checkAssetLoading: false,
    checkAssetError: {},
    checkAssetSuccess: {},

    approveAssetLoading: false,
    approveAssetSuccess: {},
    approveAssetError: {},
  },
};

export default assetsInitialState;
