import { prepaymentsActions } from "../../../actions";
import initialState from "../../../initialState";

const prepayments = (state = initialState.prepayments, actions) => {
  switch (actions.type) {
    case prepaymentsActions.GET_PREPAYMENTS_REQUEST:
      return {
        ...state,
        prepaymentsError: {},
        prepaymentsLoading: true,
      };
    case prepaymentsActions.GET_PREPAYMENTS_SUCCESS:
      return {
        ...state,
        prepayments: actions.data,
        prepaymentsLoading: false,
      };
    case prepaymentsActions.GET_PREPAYMENTS_ERROR:
      return {
        ...state,
        prepaymentsError: actions.data,
        prepaymentsLoading: false,
      };
    case prepaymentsActions.SET_SELECTED_PREPAYMENT:
      return {
        ...state,
        selectedPrepayment: actions.data,
      };

    default:
      return state;
  }
};

export default prepayments;
