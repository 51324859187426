const cashBook = {
  GET_CASH_BOOK_REQUEST: "GET_CASH_BOOK_REQUEST",
  GET_CASH_BOOK_SUCCESS: "GET_CASH_BOOK_SUCCESS",
  GET_CASH_BOOK_ERROR: "GET_CASH_BOOK_ERROR",

  GET_META_DATA_REQUEST: "GET_CASH_BOOK_META_DATA_REQUEST",
  GET_META_DATA_SUCCESS: "GET_CASH_BOOK_META_DATA_SUCCESS",

  GENERATE_EXCEL_REQUEST: "GENERATE_EXCEL_REQUEST",
  GENERATE_EXCEL_SUCCESS: "GENERATE_EXCEL_SUCCESS",
  GENERATE_EXCEL_ERROR: "GENERATE_EXCEL_ERROR",

  getCashBook: (params) => ({
    type: cashBook.GET_CASH_BOOK_REQUEST,
    params,
  }),
  getMetaData: () => ({
    type: cashBook.GET_META_DATA_REQUEST,
  }),
  generateExcel: (params) => ({
    type: cashBook.GENERATE_EXCEL_REQUEST,
    params,
  }),
};

export default cashBook;
