const transferInstitutionInitialState = {
    transferInstitutionLoading: false,
    transferInstitutionSuccess: {},
    transferInstitutionError: {},
    
    transferInstitutionMetaDataLoading: false,
    transferInstitutionMetaDataSuccess: {},
    transferInstitutionMetaDataError: {},
}

export default transferInstitutionInitialState;