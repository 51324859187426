import { takeLatest, fork, put } from "redux-saga/effects";
import axios from "axios";
import { learnerBillingActions } from "../../../actions";

function* getLearnerBilling({ data }) {
  try {
    const response = yield axios({
      url: "/administration/learner-billing",
      method: "GET",
      params: data,
    });
    yield put({
      type: learnerBillingActions.LEARNER_BILLING_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: learnerBillingActions.LEARNER_BILLING_ERROR,
      error: error.data,
    });
  }
}
function* watchGetLearnerBilling() {
  yield takeLatest(
    learnerBillingActions.LEARNER_BILLING_REQUEST,
    getLearnerBilling
  );
}

function* getMetaData() {
  try {
    const response = yield axios({
      url: "/administration/learner-billing/meta-data",
      method: "GET",
    });
    yield put({
      type: learnerBillingActions.META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: learnerBillingActions.META_DATA_ERROR,
      error: error.data,
    });
  }
}
function* watchGetMetaData() {
  yield takeLatest(learnerBillingActions.META_DATA_REQUEST, getMetaData);
}

function* billLearners({ data, reloadData }) {
  try {
    const response = yield axios({
      url: "/administration/learner-billing",
      method: "POST",
      data: data,
    });
    yield put({
      type: learnerBillingActions.BILL_LEARNERS_SUCCESS,
      data: response,
    });
    yield put(learnerBillingActions.getLearnerBilling(reloadData));
  } catch (error) {
    yield put({
      type: learnerBillingActions.BILL_LEARNERS_ERROR,
      error: error.data,
    });
  }
}
function* watchBillLearners() {
  yield takeLatest(learnerBillingActions.BILL_LEARNERS_REQUEST, billLearners);
}

function* deleteLearnerBill({ id, data }) {
  try {
    const response = yield axios({
      url: `/administration/learner-billing/${id}`,
      method: "DELETE",
    });
    yield put({
      type: learnerBillingActions.DELETE_LEARNER_BILL_SUCCESS,
      data: response,
    });
    yield put(learnerBillingActions.getLearnerBilling(data));
  } catch (error) {
    yield put({
      type: learnerBillingActions.DELETE_LEARNER_BILL_ERROR,
      error: error.data,
    });
  }
}
function* watchDeleteLearnerBill() {
  yield takeLatest(
    learnerBillingActions.DELETE_LEARNER_BILL_REQUEST,
    deleteLearnerBill
  );
}

export default [
  fork(watchGetLearnerBilling),
  fork(watchGetMetaData),
  fork(watchBillLearners),
  fork(watchDeleteLearnerBill),
];
