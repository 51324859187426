const bursaryActions = {
  GET_BURSARIES_REQUEST: "GET_BURSARIES_REQUEST",
  GET_BURSARIES_SUCCESS: "GET_BURSARIES_SUCCESS",
  GET_BURSARIES_ERROR: "GET_BURSARIES_ERROR",

  GET_BURSARY_DETAILS_REQUEST: "GET_BURSARY_DETAILS_REQUEST",
  GET_BURSARY_DETAILS_SUCCESS: "GET_BURSARY_DETAILS_SUCCESS",
  GET_BURSARY_DETAILS_ERROR: "GET_BURSARY_DETAILS_ERROR",

  ADD_BURSARY_REQUEST: "ADD_BURSARY_REQUEST",
  ADD_BURSARY_SUCCESS: "ADD_BURSARY_SUCCESS",
  ADD_BURSARY_ERROR: "ADD_BURSARY_ERROR",

  UPDATE_BURSARY_REQUEST: "UPDATE_BURSARY_REQUEST",
  UPDATE_BURSARY_SUCCESS: "UPDATE_BURSARY_SUCCESS",
  UPDATE_BURSARY_ERROR: "UPDATE_BURSARY_ERROR",
  UPDATE_BURSARY_DATA: "UPDATE_BURSARY_DATA",

  DELETE_BURSARY_REQUEST: "DELETE_BURSARY_REQUEST",
  DELETE_BURSARY_SUCCESS: "DELETE_BURSARY_SUCCESS",
  DELETE_BURSARY_ERROR: "DELETE_BURSARY_ERROR",

  DELETE_BURSARY_COST_REQUEST: "DELETE_BURSARY_COST_REQUEST",
  DELETE_BURSARY_COST_SUCCESS: "DELETE_BURSARY_COST_SUCCESS",
  DELETE_BURSARY_COST_ERROR: "DELETE_BURSARY_COST_ERROR",

  UPDATE_BURSARY_COST_REQUEST: "UPDATE_BURSARY_COST_REQUEST",
  UPDATE_BURSARY_COST_SUCCESS: "UPDATE_BURSARY_COST_SUCCESS",
  UPDATE_BURSARY_COST_ERROR: "UPDATE_BURSARY_COST_ERROR",
  UPDATE_BURSARY_COST_DATA: "UPDATE_BURSARY_COST_DATA",

  CHECK_BURSARY_REQUEST: "CHECK_BURSARY_REQUEST",
  CHECK_BURSARY_SUCCESS: "CHECK_BURSARY_SUCCESS",
  CHECK_BURSARY_ERROR: "CHECK_BURSARY_ERROR",

  APPROVE_BURSARY_REQUEST: "APPROVE_BURSARY_REQUEST",
  APPROVE_BURSARY_SUCCESS: "APPROVE_BURSARY_SUCCESS",
  APPROVE_BURSARY_ERROR: "APPROVE_BURSARY_ERROR",

  GET_META_DATA_REQUEST: "GET_BURSARIES_META_DATA_REQUEST",
  GET_META_DATA_SUCCESS: "GET_BURSARIES_META_DATA_SUCCESS",

  GET_BURSARY_COST_DETAILS_REQUEST: "GET_BURSARY_COST_DETAILS_REQUEST",
  GET_BURSARY_COST_DETAILS_SUCCESS: "GET_BURSARY_COST_DETAILS_SUCCESS",
  GET_BURSARY_COST_DETAILS_ERROR: "GET_BURSARY_COST_DETAILS_ERROR",

  getBursaries: () => ({
    type: bursaryActions.GET_BURSARIES_REQUEST,
  }),
  getBursaryDetails: (id) => ({
    type: bursaryActions.GET_BURSARY_DETAILS_REQUEST,
    id,
  }),
  addBursary: (data) => ({
    type: bursaryActions.ADD_BURSARY_REQUEST,
    data,
  }),
  updateBursary: (data, id) => ({
    type: bursaryActions.UPDATE_BURSARY_REQUEST,
    data,
    id,
  }),
  deleteBursary: (id) => ({
    type: bursaryActions.DELETE_BURSARY_REQUEST,
    id,
  }),
  deleteBursaryCost: (id, bursaryId) => ({
    type: bursaryActions.DELETE_BURSARY_COST_REQUEST,
    id,
    bursaryId,
  }),
  updateBursaryCost: (data, id) => ({
    type: bursaryActions.UPDATE_BURSARY_COST_REQUEST,
    data,
    id,
  }),
  checkBursary: (id) => ({
    type: bursaryActions.CHECK_BURSARY_REQUEST,
    id,
  }),
  approveBursary: (id) => ({
    type: bursaryActions.APPROVE_BURSARY_REQUEST,
    id,
  }),
  getMetaData: () => ({
    type: bursaryActions.GET_META_DATA_REQUEST,
  }),
  getBursaryCostDetails: (id) => ({
    type: bursaryActions.GET_BURSARY_COST_DETAILS_REQUEST,
    id,
  }),
  updateBursaryData: (data) => ({
    type: bursaryActions.UPDATE_BURSARY_DATA,
    data,
  }),
  updateBursaryCostData: (data) => ({
    type: bursaryActions.UPDATE_BURSARY_COST_DATA,
    data,
  }),
};

export default bursaryActions;
