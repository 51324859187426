const gradingInitialState = {
  gradingsLoading: false,
  gradingsSuccess: {},
  gradingsError: {},

  addGradingLoading: false,
  addGradingSuccess: {},
  addGradingError: {},

  getGradingDetailsLoading: false,
  getGradingDetailsSuccess: {},
  getGradingDetailsError: {},

  updateGradingDetailsLoading: false,
  updateGradingDetailsSuccess: {},
  updateGradingDetailsError: {},
  updateGradingDetailsData: {},

  deleteGradingLoading: false,
  deleteGradingSuccess: {},
  deleteGradingError: {},

  deleteGradingScoreLoading: false,
  deleteGradingScoreSuccess: {},
  deleteGradingScoreError: {},

  checkGradingLoading: false,
  checkGradingSuccess: {},
  checkGradingError: {},

  approveGradingLoading: false,
  approveGradingSuccess: {},
  approveGradingError: {},

  addAlevelGradingLoading: false,
  addAlevelGradingSuccess: {},
  addAlevelGradingError: {},

  updateAlevelGradingLoading: false,
  updateAlevelGradingSuccess: {},
  updateAlevelGradingError: {},
  updateAlevelGradingData: {},

  deleteAlevelGradingLoading: false,
  deleteAlevelGradingSuccess: {},
  deleteAlevelGradingError: {},

  addDivisionsLoading: false,
  addDivisionsSuccess: {},
  addDivisionsError: {},

  updateDivisionLoading: false,
  updateDivisionSuccess: {},
  updateDivisionError: {},
  updateDivisionData: {},

  deleteDivisionLoading: false,
  deleteDivisionSuccess: {},
  deleteDivisionError: {},

  addingCompetencyGradingScaleLoading: false,
  addingCompetencyGradingScaleSuccess: {},
  addingCompentencyGradingScaleError: {},

  updatingCompetencyGradingScaleLoading: false,
  updatingCompetencyGradingScaleSuccess: {},
  updatingCompetencyGradingScaleError: {},
  updatingCompetencyGradingScaleData: {},

  deletingCompetencyGradingScaleLoading: false,
  deletingCompetencyGradingScaleSuccess: {},
  deletingCompetencyGradingError: {},
};

export default gradingInitialState;
