const examinationsInitialState = {
  examinations: {
    examinationsLoading: false,
    examinationsSuccess: {},
    examinationsError: {},

    addExaminationsLoading: false,
    addExaminationsSuccess: {},
    addExaminationsError: {},

    updateExaminationsData: {},
    updateExaminationsLoading: false,
    updateExaminationsSuccess: {},
    updateExaminationsError: {},

    metaDataLoading: false,
    metaData: {},

    deleteExaminationsLoading: false,
    deleteExaminationsSuccess: {},
    deleteExaminationsError: {},

    getExaminationDetailsLoading: false,
    getExaminationDetailsSuccess: {},
    getExaminationDetailsError: {},

    checkExaminationsLoading: false,
    checkExaminationsSuccess: {},
    checkExaminationsError: {},

    approveExaminationsLoading: false,
    approveExaminationsSuccess: {},
    approveExaminationsError: {},

    examType: "",
  },
};

export default examinationsInitialState;
