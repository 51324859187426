const performanceAnalysis = {
  GET_CLASS_PERFORMANCE_ANALYSIS_REQUEST:
    "GET_CLASS_PERFORMANCE_ANALYSIS_REQUEST",
  GET_CLASS_PERFORMANCE_ANALYSIS_SUCCESS:
    "GET_CLASS_PERFORMANCE_ANALYSIS_SUCCESS",
  GET_CLASS_PERFORMANCE_ANALYSIS_ERROR: "GET_CLASS_PERFORMANCE_ANALYSIS_ERROR",

  GET_SUBJECT_PERFORMANCE_ANALYSIS_REQUEST:
    "GET_SUBJECT_PERFORMANCE_ANALYSIS_REQUEST",
  GET_SUBJECT_PERFORMANCE_ANALYSIS_SUCCESS:
    "GET_SUBJECT_PERFORMANCE_ANALYSIS_SUCCESS",
  GET_SUBJECT_PERFORMANCE_ANALYSIS_ERROR:
    "GET_SUBJECT_PERFORMANCE_ANALYSIS_ERROR",

  GET_META_DATA_REQUEST: "GET_META_PERFORMANCE_ANALYSIS_DATA_REQUEST",
  GET_META_DATA_SUCCESS: "GET_META_PERFORMANCE_ANALYSIS_DATA_SUCCESS",
  GET_META_DATA_ERROR: "GET_META_PERFORMANCE_ANALYSIS_DATA_ERROR",

  getClassPerformanceAnalysis: (params) => ({
    type: performanceAnalysis.GET_CLASS_PERFORMANCE_ANALYSIS_REQUEST,
    params,
  }),
  getSubjectPerformanceAnalysis: (params) => ({
    type: performanceAnalysis.GET_SUBJECT_PERFORMANCE_ANALYSIS_REQUEST,
    params,
  }),
  getMetaData: (params) => ({
    type: performanceAnalysis.GET_META_DATA_REQUEST,
    params,
  }),
};

export default performanceAnalysis;
