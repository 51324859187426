import { authActions } from "../../actions";
import initialState from "../../initialState";

const auth = (state = initialState.auth, actions) => {
  switch (actions.type) {
    case authActions.LOGIN_REQUEST:
      return {
        ...state,
        loginError: {},
        loggingIn: true,
        authUserError: {},
      };
    case authActions.LOGIN_SUCCESS:
      return {
        ...state,
        loginSuccess: actions.data,
        loginError: {},
        loggingIn: false,
        authenticated: true,
      };
    case authActions.LOGIN_ERROR:
      return {
        ...state,
        loginError: actions.error,
        loggingIn: false,
      };
    case authActions.LOGOUT_USER_REQUEST:
      return {
        ...state,
        logOutUserLoading: true,
      };
    case authActions.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        logOutUserLoading: false,
        logOutUserError: {},
        logOutUserSuccess: actions.data,
      };
    case authActions.LOGOUT_USER_ERROR:
      return {
        ...state,
        logOutUserLoading: false,
        logOutUserError: actions.error,
        logOutUserSuccess: {},
      };
    case authActions.GETTING_AUTH_USER:
      return {
        ...state,
        gettingAuthUser: true,
      };
    case authActions.GETTING_AUTH_USER_SUCCESS:
      return {
        ...state,
        gettingAuthUser: false,
        authUser: actions.data,
        authUserError: {},
      };
    case authActions.GETTING_AUTH_USER_ERROR:
      return {
        ...state,
        gettingAuthUser: false,
        authUserError: actions.data,
      };
    case authActions.SIGNUP_REQUEST:
      return {
        ...state,
        signupError: {},
        signingUp: true,
      };
    case authActions.SIGNUP_SUCCESS:
      return {
        ...state,
        signupSuccess: actions.data,
        signupError: {},
        signingUp: false,
      };
    case authActions.SIGNUP_ERROR:
      return {
        ...state,
        signupError: actions.error,
        signingUp: false,
      };
    case authActions.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordError: {},
        forgotPassword: true,
      };
    case authActions.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordSuccess: actions.data,
        forgotPasswordError: {},
        forgotPassword: false,
      };
    case authActions.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPasswordError: actions.error,
        forgotPassword: false,
      };
    case authActions.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordError: {},
        resetPassword: true,
      };
    case authActions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: actions.data,
        resetPasswordError: {},
        resetPassword: false,
      };
    case authActions.RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordError: actions.error,
        resetPassword: false,
      };
    case authActions.CONFIRM_ACCOUNT_REQUEST:
      return {
        ...state,
        confirmAccountError: {},
        confirmAccount: true,
      };
    case authActions.CONFIRM_ACCOUNT_SUCCESS:
      return {
        ...state,
        confirmAccountSuccess: actions.data,
        confirmAccountError: {},
        confirmAccount: false,
      };
    case authActions.CONFIRM_ACCOUNT_ERROR:
      return {
        ...state,
        confirmAccountError: actions.error,
        confirmAccount: false,
      };
    default:
      return state;
  }
};

export default auth;
