const banksInitialState = {
  banks: {
    banksLoading: false,
    banksSuccess: {},
    banksError: {},

    addBanksLoading: false,
    addBanksSuccess: {},
    addBanksError: {},

    deleteBankLoading: false,
    deleteBankSuccess: {},
    deleteBankError: {},

    updateBankData: {},
    updateBankLoading: false,
    updateBankSuccess: {},
    updateBankError: {},

    bankDetailsLoading: false,
    bankDetailsSuccess: {},
    bankDetailsError: {},

    checkBankLoading: false,
    checkBankSuccess: {},
    checkBankError: {},

    approveBankLoading: false,
    approveBankSuccess: {},
    approveBankError: {},

    metaData: {},
    metaDataLoading: false,
    metaDataError: {},

    deleteSubAccountLoading: false,
    deleteSubAccountSuccess: {},
    deleteSubAccountError: {},

    termlyBankStatementLoading: false,
    termlyBankStatementSuccess: {},
    termlyBankStatementError: {},

    bankStatementLoading: false,
    bankStatementSuccess: {},
    bankStatementError: {},

    yearlyBankStatementLoading: false,
    yearlyBankStatementSuccess: {},
    yearlyBankStatementError: {},

    bankStatementData: {},
    periodBankStatement: [],
  },
};

export default banksInitialState;
