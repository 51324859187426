import { partnersActions } from "../../../actions";
import partnersInitialState from "../../../initialState/businessDevelopmentAndPartnerships/partners/partners.initialState";

const partners = (state = partnersInitialState, action) => {
  switch (action.type) {
    case partnersActions.GET_PARTNERS_REQUEST:
      return {
        ...state,
        partnersLoading: true,
        partnersError: {},
      };
    case partnersActions.GET_PARTNERS_SUCCESS:
      return {
        ...state,
        partnersSuccess: action.data,
        partnersLoading: false,
      };
    case partnersActions.GET_PARTNERS_ERROR:
      return {
        ...state,
        partnersLoading: false,
        partnersError: action.error,
      };
    case partnersActions.ADD_PARTNER_REQUEST:
      return {
        ...state,
        addPartnerLoading: true,
        addPartnerError: {},
      };
    case partnersActions.ADD_PARTNER_SUCCESS:
      return {
        ...state,
        addPartnerSuccess: action.data,
        partnersSuccess: {
          partners: [action.data, ...state.partnersSuccess.partners],
        },
        addPartnerLoading: false,
      };
    case partnersActions.ADD_PARTNER_ERROR:
      return {
        ...state,
        addPartnerLoading: false,
        addPartnerSuccess: action.error,
      };
    case partnersActions.GET_PARTNER_DETAILS_REQUEST:
      return {
        ...state,
        getPartnerDetailsLoading: true,
      };
    case partnersActions.GET_PARTNER_DETAILS_SUCCESS:
      return {
        ...state,
        getPartnerDetailsSuccess: action.data,
        getPartnerDetailsError: {},
        getPartnerDetailsLoading: false,
      };
    case partnersActions.GET_PARTNER_DETAILS_ERROR:
      return {
        ...state,
        getPartnerDetailsLoading: false,
        getPartnerDetailsSuccess: action.error,
      };
    case partnersActions.DELETE_PARTNER_REQUEST:
      return {
        ...state,
        deletePartnerLoading: true,
      };
    case partnersActions.DELETE_PARTNER_SUCCESS:
      return {
        ...state,
        deletePartnerSuccess: action.data,
        partnersSuccess: {
          ...state.partnersSuccess,
          partners: action.partners,
        },
        deletePartnerLoading: false,
      };
    case partnersActions.DELETE_PARTNER_ERROR:
      return {
        ...state,
        deletePartnerLoading: false,
        deletePartnerSuccess: action.error,
      };
    case partnersActions.UPDATE_PARTNER_DETAILS_REQUEST:
      return {
        ...state,
        updatePartnerDetailsLoading: true,
        updatePartnerDetailsError: {},
      };
    case partnersActions.UPDATE_PARTNER_DETAILS_SUCCESS:
      return {
        ...state,
        updatePartnerDetailsSuccess: action.data,
        partnersSuccess: {
          ...state.partnersSuccess,
          partners: action.partners,
        },
        updatePartnerDetailsLoading: false,
      };
    case partnersActions.UPDATE_PARTNER_DETAILS_ERROR:
      return {
        ...state,
        updatePartnerDetailsLoading: false,
        updatePartnerDetailsSuccess: action.error,
      };
    case partnersActions.SET_UPDATE_PARTNER_DETAILS_DATA:
      return {
        ...state,
        updatePartnerDetailsData: action.data,
      };
    case partnersActions.CHECK_PARTNER_REQUEST:
      return {
        ...state,
        checkPartnerLoading: true,
      };
    case partnersActions.CHECK_PARTNER_SUCCESS:
      return {
        ...state,
        checkPartnerSuccess: action.data,
        checkPartnerError: {},
        checkPartnerLoading: false,
      };
    case partnersActions.CHECK_PARTNER_ERROR:
      return {
        ...state,
        checkPartnerLoading: false,
        checkPartnerSuccess: action.error,
      };
    case partnersActions.APPROVE_PARTNER_REQUEST:
      return {
        ...state,
        approvePartnerLoading: true,
      };
    case partnersActions.APPROVE_PARTNER_SUCCESS:
      return {
        ...state,
        approvePartnerSuccess: action.data,
        approvePartnerError: {},
        approvePartnerLoading: false,
      };
    case partnersActions.APPROVE_PARTNER_ERROR:
      return {
        ...state,
        approvePartnerLoading: false,
        approvePartnerSuccess: action.error,
      };
    default:
      return state;
  }
}; // end of partners()

export default partners;
