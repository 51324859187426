const prepayments = {
  GET_PREPAYMENTS_REQUEST: "GET_PREPAYMENTS_REQUEST",
  GET_PREPAYMENTS_SUCCESS: "GET_PREPAYMENTS_SUCCESS",
  GET_PREPAYMENTS_ERROR: "GET_PREPAYMENTS_ERROR",

  SET_SELECTED_PREPAYMENT: "SET_SELECTED_PREPAYMENT",

  getPrepayments: (data) => ({
    type: prepayments.GET_PREPAYMENTS_REQUEST,
    data,
  }),
  setSelectedPrepayment: (data) => ({
    type: prepayments.SET_SELECTED_PREPAYMENT,
    data,
  }),
};

export default prepayments;
