import { Avatar, Steps, Tabs } from "antd";
import React, { createElement } from "react";
import Login from "./login";
import Signup from "./signup";
import AppLogo from "../../assets/AppLogo.png";
import { useNavigate } from "react-router-dom";
import routePaths from "../../config/routes/routePaths.routes";

const AuthPage = () => {
  const navigate = useNavigate();
  const goToLanding = () => navigate(`../${routePaths.landingPage.path}`);
  return (
    <div className="d-md-flex  min-vh-100">
      <div className="w-50 w-sm-100 w-md-100 bg-light mh-100 d-lg-flex flex-column d-none ">
        <div
          className="w-75 mx-4 my-4 cursor-pointer d-flex align-items-center"
          onClick={goToLanding}
        >
          <Avatar size={70} src={AppLogo} />{" "}
          <h3 className="mx-1 text-primary">SCHOOLBOOK</h3>
        </div>
        <div className="d-flex flex-column  align-items-center">
          <div className="w-75 px-1">
            <h4 className="mb-3">SCHOOLBOOK SERVICES</h4>
            <div className="d-flex flex-column">
              <Steps direction="vertical" current={0}>
                <Steps.Step
                  icon={createElement(routePaths.administration.icon)}
                  key={0}
                  title={routePaths.administration.name}
                  description="Our school's administration feature allows the school to manage all aspects of its administration with features on learner enrollment, payment structure setting, class setting, staff management, and storage of school policies."
                />
              </Steps>
              <Steps current={0} direction="vertical">
                <Steps.Step
                  icon={createElement(routePaths.academics.icon)}
                  description={
                    "SchoolBook's academics feature simplifies academic activities and tracking of learner progress, making it easier to manage subjects, assign teachers, allocate learners, score performance, and prepare academic reports."
                  }
                  key={1}
                  title={routePaths.academics.name?.toUpperCase()}
                />
              </Steps>
              <Steps current={0} direction="vertical">
                <Steps.Step
                  icon={createElement(routePaths.finance.icon)}
                  description={
                    "The Finance feature of SchoolBook enables accurate record, tracking, and reporting of financial transactions (incomes and expenses), requisition and payments processes, school budget preparation and monitoring, school fees collection tracking, and preparation of the school financial reports such as the cashbook, balance sheet, and profit & loss report."
                  }
                  key={0}
                  title={routePaths.finance.name?.toUpperCase()}
                />
              </Steps>
              <Steps direction="vertical" current={0}>
                <Steps.Step
                  icon={createElement(
                    routePaths.assetsAndInventoryManagement.icon
                  )}
                  description={
                    "Our Asset and Inventory Management feature is a key component of SchoolBook support in the management of the school assets and inventory in the school store."
                  }
                  key={0}
                  title={routePaths.assetsAndInventoryManagement.name?.toUpperCase()}
                />
              </Steps>
            </div>
          </div>
          <div className="d-flex flex-column align-items-center text-secondary mt-3 ">
            <hr className="w-50" />
            <p className="text-center m-0 w-75">
              <span className="fw-bold">School Book</span> | A product by (
              <span className=" text-primary">
                Amani Elevator Facility +256784951295/+256703154084
              </span>
              )
            </p>
          </div>
        </div>
      </div>
      <div className="w-50 w-sm-100 w-md-100 mh-100 d-flex flex-column justify-content-center align-items-center p-1">
        <div className="w-75">
          <div
            className=" w-75 mx-auto cursor-pointer d-md-block d-lg-none"
            onClick={goToLanding}
          >
            <div className="d-flex flex-column align-items-center w-100">
              <Avatar size={70} src={AppLogo} />{" "}
              <h3 className="mx-1 text-center d-sm-none d-none">SCHOOLBOOK</h3>
            </div>
          </div>
          <h3 className="mb-3 mt-4 text-center">
            WELCOME TO <span className="text-primary">SCHOOLBOOK</span>
          </h3>
          <p className="m-0 mb-3 text-secondary text-center">
            Improving Your School Financial Health
          </p>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                label: "Login",
                key: "1",
                className: "mb-0",
                children: (
                  <div className="w-sm-100">
                    <Login />
                  </div>
                ),
              },
              {
                label: "Sign Up",
                key: "2",
                className: "mb-0",
                children: (
                  <div className=" w-sm-100">
                    <Signup />
                  </div>
                ),
              },
            ]}
          />
        </div>
        <div className="d-lg-none d-mb-block">
          <div className="d-flex flex-column align-items-center text-secondary mt-3 ">
            <hr className="w-50" />
            <p className="text-center m-0 w-75">
              <span className="fw-bold">SchoolBook</span> | A product of{" "}
              <span className="text-primary cursor-pointer">
                Gates & Bytes Ltd (gatesandbytesltd@gmail.com)
              </span>{" "}
              promoted by (
              <span className=" text-primary">
                {" "}
                Amani Elevator Facility +256784951295/+256703154084
              </span>
              )
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPage;
