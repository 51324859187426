import { organisationUsersActions } from "../../../actions";
import initialState from "../../../initialState";

const organisationUsers = (state = initialState.organisationUsers, actions) => {
  switch (actions.type) {
    case organisationUsersActions.GET_ORGANISATION_USERS_REQUEST:
      return {
        ...state,
        organisationUsersLoading: true,
      };
    case organisationUsersActions.GET_ORGANISATION_USERS_SUCCESS:
      return {
        ...state,
        organisationUsersSuccess: actions.data,
        organisationUsersError: {},
        organisationUsersLoading: false,
      };
    case organisationUsersActions.GET_ORGANISATION_USERS_ERROR:
      return {
        ...state,
        organisationUsersLoading: false,
        organisationUsersSuccess: actions.error,
      };
    case organisationUsersActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case organisationUsersActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataLoading: false,
        metaDataSuccess: actions.data,
      };
    case organisationUsersActions.ADD_ORGANISATION_USERS_REQUEST:
      return {
        ...state,
        addOrganisationUsersSuccess: {},
        addOrganisationUsersLoading: true,
        addOrganisationUsersError: {},
      };
    case organisationUsersActions.ADD_ORGANISATION_USERS_SUCCESS:
      return {
        ...state,
        addOrganisationUsersLoading: false,
        addOrganisationUsersSuccess: actions.data,
        addOrganisationUsersError: {},
      };
    case organisationUsersActions.ADD_ORGANISATION_USERS_ERROR:
      return {
        ...state,
        addOrganisationUsersError: actions.error,
        addOrganisationUsersLoading: false,
      };
    case organisationUsersActions.SET_UPDATE_ORGANISATION_USERS_DATA:
      return {
        ...state,
        updateOrganisationUserData: actions.data,
      };
    case organisationUsersActions.UPDATE_ORGANISATION_USERS_REQUEST:
      return {
        ...state,
        updateOrganisationUsersLoading: true,
      };
    case organisationUsersActions.UPDATE_ORGANISATION_USERS_SUCCESS:
      return {
        ...state,
        updateOrganisationUsersSuccess: {},
        updateOrganisationUsersError: actions.error,
        updateOrganisationUsersLoading: false,
      };
    case organisationUsersActions.UPDATE_ORGANISATION_USERS_ERROR:
      return {
        ...state,
        updateOrganisationUsersLoading: false,
        updateOrganisationUsersError: actions.error,
      };
    case organisationUsersActions.GET_ORGANISATION_USER_DETAILS_REQUEST:
      return {
        ...state,
        organisationUserDetailsLoading: true,
      };
    case organisationUsersActions.GET_ORGANISATION_USER_DETAILS_SUCCESS:
      return {
        ...state,
        organisationUserDetailsSuccess: actions.data,
        organisationUserDetailsError: {},
        organisationUserDetailsLoading: false,
      };
    case organisationUsersActions.GET_ORGANISATION_USER_DETAILS_ERROR:
      return {
        ...state,
        organisationUserDetailsLoading: false,
        organisationUserDetailsError: actions.error,
      };
    case organisationUsersActions.ACTIVATE_ACCOUNT_REQUEST:
      return {
        ...state,
        activateAccountLoading: true,
      };
    case organisationUsersActions.ACTIVATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        activateAccountSuccess: actions.data,
        activateAccountLoading: false,
        activateAccountError: {},
      };
    case organisationUsersActions.ACTIVATE_ACCOUNT_ERROR:
      return {
        ...state,
        activateAccountError: actions.error,
        activateAccountLoading: false,
      };
    case organisationUsersActions.DEACTIVATE_ACCOUNT_REQUEST:
      return {
        ...state,
        deactivateAccountLoading: true,
      };
    case organisationUsersActions.DEACTIVATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deactivateAccountError: {},
        deactivateAccountSuccess: actions.data,
        deactivateAccountLoading: false,
      };
    case organisationUsersActions.DEACTIVATE_ACCOUNT_ERROR:
      return {
        ...state,
        deactivateAccountError: actions.error,
        deactivateAccountLoading: false,
      };
    case organisationUsersActions.CREATE_STAFF_DETAILS_REQUEST:
      return {
        ...state,
        createStaffDetailsLoading: true,
      };
    case organisationUsersActions.CREATE_STAFF_DETAILS_SUCCESS:
      return {
        ...state,
        createStaffDetailsSuccess: actions.data,
        createStaffDetailsError: {},
        createStaffDetailsLoading: false,
      };
    case organisationUsersActions.CREATE_STAFF_DETAILS_ERROR:
      return {
        ...state,
        createStaffDetailsError: actions.error,
        createStaffDetailsLoading: false,
      };
    case organisationUsersActions.UPDATE_STAFF_DETAILS_REQUEST:
      return {
        ...state,
        updateStaffDetailsLoading: true,
      };
    case organisationUsersActions.UPDATE_STAFF_DETAILS_SUCCESS:
      return {
        ...state,
        updateStaffDetailsSuccess: actions.data,
        updateStaffDetailsError: {},
        updateStaffDetailsLoading: false,
      };
    case organisationUsersActions.UPDATE_STAFF_DETAILS_ERROR:
      return {
        ...state,
        updateStaffDetailsError: actions.error,
        updateStaffDetailsLoading: false,
      };
    case organisationUsersActions.DELETE_STAFF_DETAILS_REQUEST:
      return {
        ...state,
        deleteStaffDetailsLoading: true,
      };
    case organisationUsersActions.DELETE_STAFF_DETAILS_SUCCESS:
      return {
        ...state,
        deleteStaffDetailsSuccess: actions.data,
        deleteStaffDetailsError: {},
        deleteStaffDetailsLoading: false,
      };
    case organisationUsersActions.DELETE_STAFF_DETAILS_ERROR:
      return {
        ...state,
        deleteStaffDetailsError: actions.error,
        deleteStaffDetailsLoading: false,
      };
    case organisationUsersActions.CREATE_STAFF_DEPENDANTS_REQUEST:
      return {
        ...state,
        createStaffDependantsLoading: true,
      };
    case organisationUsersActions.CREATE_STAFF_DEPENDANTS_SUCCESS:
      return {
        ...state,
        createStaffDependantsSuccess: actions.data,
        createStaffDependantsError: {},
        createStaffDependantsLoading: false,
      };
    case organisationUsersActions.CREATE_STAFF_DEPENDANTS_ERROR:
      return {
        ...state,
        createStaffDependantsError: actions.error,
        createStaffDependantsLoading: false,
      };
    case organisationUsersActions.UPDATE_STAFF_DEPENDANTS_REQUEST:
      return {
        ...state,
        updateStaffDependantsLoading: true,
      };
    case organisationUsersActions.UPDATE_STAFF_DEPENDANTS_SUCCESS:
      return {
        ...state,
        updateStaffDependantsSuccess: actions.data,
        updateStaffDependantsError: {},
        updateStaffDependantsLoading: false,
      };
    case organisationUsersActions.UPDATE_STAFF_DEPENDANTS_ERROR:
      return {
        ...state,
        updateStaffDependantsError: actions.error,
        updateStaffDependantsLoading: false,
      };
    case organisationUsersActions.DELETE_STAFF_DEPENDANTS_REQUEST:
      return {
        ...state,
        deleteStaffDependantsLoading: true,
      };
    case organisationUsersActions.DELETE_STAFF_DEPENDANTS_SUCCESS:
      return {
        ...state,
        deleteStaffDependantsSuccess: actions.data,
        deleteStaffDependantsError: {},
        deleteStaffDependantsLoading: false,
      };
    case organisationUsersActions.DELETE_STAFF_DEPENDANTS_ERROR:
      return {
        ...state,
        deleteStaffDependantsError: actions.error,
        deleteStaffDependantsLoading: false,
      };
    case organisationUsersActions.UPLOAD_STAFF_DOCUMENTS_REQUEST:
      return {
        ...state,
        uploadStaffDocumentsLoading: true,
      };
    case organisationUsersActions.UPLOAD_STAFF_DOCUMENTS_SUCCESS:
      return {
        ...state,
        uploadStaffDocumentsSuccess: actions.data,
        uploadStaffDocumentsError: {},
        uploadStaffDocumentsLoading: false,
      };
    case organisationUsersActions.UPLOAD_STAFF_DOCUMENTS_ERROR:
      return {
        ...state,
        uploadStaffDocumentsError: actions.error,
        uploadStaffDocumentsLoading: false,
      };
    case organisationUsersActions.DELETE_STAFF_DOCUMENTS_REQUEST:
      return {
        ...state,
        deleteStaffDocumentsLoading: true,
      };
    case organisationUsersActions.DELETE_STAFF_DOCUMENTS_SUCCESS:
      return {
        ...state,
        deleteStaffDocumentsSuccess: actions.data,
        deleteStaffDocumentsError: {},
        deleteStaffDocumentsLoading: false,
      };
    case organisationUsersActions.DELETE_STAFF_DOCUMENTS_ERROR:
      return {
        ...state,
        deleteStaffDocumentsError: actions.error,
        deleteStaffDocumentsLoading: false,
      };
    case organisationUsersActions.UPDATE_STAFF_DOCUMENTS_REQUEST:
      return {
        ...state,
        updateStaffDocumentsLoading: true,
      };
    case organisationUsersActions.UPDATE_STAFF_DOCUMENTS_SUCCESS:
      return {
        ...state,
        updateStaffDocumentsSuccess: actions.data,
        updateStaffDocumentsError: {},
        updateStaffDocumentsLoading: false,
      };
    case organisationUsersActions.UPDATE_STAFF_DOCUMENTS_ERROR:
      return {
        ...state,
        updateStaffDocumentsError: actions.error,
        updateStaffDocumentsLoading: false,
      };
    case organisationUsersActions.SET_UPDATE_STAFF_DETAILS_DATA:
      return {
        ...state,
        organisationUserDetailsSuccess: {
          ...state.organisationUserDetailsSuccess,
          userDetails: {
            ...state.organisationUserDetailsSuccess.userDetails,
            staff_details: [actions.data],
          },
        },
      };
    case organisationUsersActions.SET_UPDATE_STAFF_DEPENDANTS_DATA:
      return {
        ...state,
        setUpdateStaffDependantsData: actions.data,
      };
    case organisationUsersActions.SET_UPDATE_STAFF_DOCUMENTS_DATA:
      return {
        ...state,
        setUpdateStaffDocumentsData: actions.data,
      };
    case organisationUsersActions.STAFF_QUALIFICATION_DETAILS_REQUEST:
      return {
        ...state,
        staffQualificationDetailsLoading: true,
      };
    case organisationUsersActions.STAFF_QUALIFICATION_DETAILS_SUCCESS:
      return {
        ...state,
        staffQualificationDetailsSuccess: actions.data,
        staffQualificationDetailsError: {},
        staffQualificationDetailsLoading: false,
      };
    case organisationUsersActions.STAFF_QUALIFICATION_DETAILS_ERROR:
      return {
        ...state,
        staffQualificationDetailsError: actions.error,
        staffQualificationDetailsLoading: false,
      };
    case organisationUsersActions.ADD_STAFF_QUALIFICATION_REQUEST:
      return {
        ...state,
        addStaffQualificationLoading: true,
      };
    case organisationUsersActions.ADD_STAFF_QUALIFICATION_SUCCESS:
      return {
        ...state,
        addStaffQualificationSuccess: actions.data,
        addStaffQualificationError: {},
        addStaffQualificationLoading: false,
      };
    case organisationUsersActions.ADD_STAFF_QUALIFICATION_ERROR:
      return {
        ...state,
        addStaffQualificationError: actions.error,
        addStaffQualificationLoading: false,
      };
    case organisationUsersActions.UPDATE_STAFF_QUALIFICATION_REQUEST:
      return {
        ...state,
        updateStaffQualificationLoading: true,
      };
    case organisationUsersActions.UPDATE_STAFF_QUALIFICATION_SUCCESS:
      return {
        ...state,
        updateStaffQualificationSuccess: actions.data,
        updateStaffQualificationError: {},
        updateStaffQualificationLoading: false,
      };
    case organisationUsersActions.UPDATE_STAFF_QUALIFICATION_ERROR:
      return {
        ...state,
        updateStaffQualificationError: actions.error,
        updateStaffQualificationLoading: false,
      };
    case organisationUsersActions.DELETE_STAFF_QUALIFICATION_REQUEST:
      return {
        ...state,
        deleteStaffQualificationLoading: true,
      };
    case organisationUsersActions.DELETE_STAFF_QUALIFICATION_SUCCESS:
      return {
        ...state,
        deleteStaffQualificationSuccess: actions.data,
        deleteStaffQualificationError: {},
        deleteStaffQualificationLoading: false,
      };
    case organisationUsersActions.DELETE_STAFF_QUALIFICATION_ERROR:
      return {
        ...state,
        deleteStaffQualificationError: actions.error,
        deleteStaffQualificationLoading: false,
      };
    case organisationUsersActions.SET_UPDATE_STAFF_QUALIFICATION_DATA:
      return {
        ...state,
        updateStaffQualificationsData: actions.data,
      };
    case organisationUsersActions.GENERATE_NEW_CONFIRMATION_TOKEN_REQUEST:
      return {
        ...state,
        generateNewConfirmationTokenLoading: true,
      };
    case organisationUsersActions.GENERATE_NEW_CONFIRMATION_TOKEN_SUCCESS:
      return {
        ...state,
        generateNewConfirmationTokenSuccess: actions.data,
        generateNewConfirmationTokenError: {},
        generateNewConfirmationTokenLoading: false,
      };
    case organisationUsersActions.GENERATE_NEW_CONFIRMATION_TOKEN_ERROR:
      return {
        ...state,
        generateNewConfirmationTokenError: actions.error,
        generateNewConfirmationTokenLoading: false,
      };
    case organisationUsersActions.UPDATE_STAFF_PROFILE_REQUEST:
      return {
        ...state,
        updateStaffProfileLoading: true,
      };
    case organisationUsersActions.UPDATE_STAFF_PROFILE_SUCCESS:
      return {
        ...state,
        updateStaffProfileSuccess: actions.data,
        updateStaffProfileError: {},
        updateStaffProfileLoading: false,
      };
    case organisationUsersActions.UPDATE_STAFF_PROFILE_ERROR:
      return {
        ...state,
        updateStaffProfileError: actions.error,
        updateStaffProfileLoading: false,
      };
    case organisationUsersActions.DOWNLOAD_STAFF_UPLOAD_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingStaffUploadTemplate: true,
        downloadStaffTemplateError: {},
      };
    case organisationUsersActions.DOWNLOAD_STAFF_UPLOAD_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadStaffTemplateSuccess: {},
        downloadingStaffUploadTemplate: false,
      };
    case organisationUsersActions.DOWNLOAD_STAFF_UPLOAD_TEMPLATE_ERROR:
      return {
        ...state,
        downloadStaffTemplateError: {},
        downloadingStaffUploadTemplate: false,
      };
    default:
      return state;
  }
};

export default organisationUsers;
