import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { appUiActions, noticesOfBestBiddersActions } from "../../../actions/";

function* getNoticesOfBestBidders() {
  try {
    const data = yield axios({
      url: "procurement/notice-of-best-bidder/",
    });

    yield put({
      type: noticesOfBestBiddersActions.GET_NOTICES_OF_BEST_BIDDERS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: noticesOfBestBiddersActions.GET_NOTICES_OF_BEST_BIDDERS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetNoticesOfBestBidders() {
  yield takeLatest(
    noticesOfBestBiddersActions.GET_NOTICES_OF_BEST_BIDDERS_REQUEST,
    getNoticesOfBestBidders
  );
}

function* getMetaData() {
  try {
    const response = yield axios({
      url: "procurement/notice-of-best-bidder/meta-data",
    });

    yield put({
      type: noticesOfBestBiddersActions.GET_NOTICES_OF_BEST_BIDDERS_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: noticesOfBestBiddersActions.GET_NOTICES_OF_BEST_BIDDERS_META_DATA_SUCCESS,
      data: {},
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(
    noticesOfBestBiddersActions.GET_NOTICES_OF_BEST_BIDDERS_META_DATA_REQUEST,
    getMetaData
  );
}

function* addNoticeOfBestBidder({ data }) {
  try {
    const response = yield axios({
      url: "procurement/notice-of-best-bidder/",
      method: "POST",
      data,
    });

    yield put({
      type: noticesOfBestBiddersActions.ADD_NOTICE_OF_BEST_BIDDER_SUCCESS,
      data: response,
    });

    yield put({
      type: noticesOfBestBiddersActions.GET_NOTICES_OF_BEST_BIDDERS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: noticesOfBestBiddersActions.ADD_NOTICE_OF_BEST_BIDDER_ERROR,
      error: error.data,
    });
  }
}

function* watchAddNoticeOfBestBidder() {
  yield takeLatest(
    noticesOfBestBiddersActions.ADD_NOTICE_OF_BEST_BIDDER_REQUEST,
    addNoticeOfBestBidder
  );
}

function* deleteNoticeOfBestBidder({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `procurement/notice-of-best-bidder/${data}`,
      method: "DELETE",
    });

    yield put({
      type: noticesOfBestBiddersActions.DELETE_NOTICE_OF_BEST_BIDDER_SUCCESS,
      data: response,
    });

    yield put({
      type: noticesOfBestBiddersActions.GET_NOTICES_OF_BEST_BIDDERS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: noticesOfBestBiddersActions.DELETE_NOTICE_OF_BEST_BIDDER_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteNoticeOfBestBidder() {
  yield takeLatest(
    noticesOfBestBiddersActions.DELETE_NOTICE_OF_BEST_BIDDER_REQUEST,
    deleteNoticeOfBestBidder
  );
}

function* updateNoticeOfBestBidder({ data, id }) {
  // id in url
  try {
    const response = yield axios({
      url: `procurement/notice-of-best-bidder/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: noticesOfBestBiddersActions.UPDATE_NOTICE_OF_BEST_BIDDER_SUCCESS,
      data: response,
    });

    yield put({
      type: appUiActions.TOGGLE_UPDATE_NOTICE_OF_BEST_BIDDER_MODAL,
      data: false,
    });
  } catch (error) {
    yield put({
      type: noticesOfBestBiddersActions.UPDATE_NOTICE_OF_BEST_BIDDER_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateNoticeOfBestBidder() {
  yield takeLatest(
    noticesOfBestBiddersActions.UPDATE_NOTICE_OF_BEST_BIDDER_REQUEST,
    updateNoticeOfBestBidder
  );
}

function* getNoticeOfBestBidderDetails({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `procurement/notice-of-best-bidder/details/${data}`,
    });

    yield put({
      type: noticesOfBestBiddersActions.GET_NOTICE_OF_BEST_BIDDER_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: noticesOfBestBiddersActions.GET_NOTICE_OF_BEST_BIDDER_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetNoticeOfBestBidderDetails() {
  yield takeLatest(
    noticesOfBestBiddersActions.GET_NOTICE_OF_BEST_BIDDER_DETAILS_REQUEST,
    getNoticeOfBestBidderDetails
  );
}

function* checkNoticeOfBestBidder({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `procurement/notice-of-best-bidder/check/${data}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({
      type: noticesOfBestBiddersActions.CHECK_NOTICE_OF_BEST_BIDDER_SUCCESS,
      data: response,
    });

    yield put({
      type: noticesOfBestBiddersActions.GET_NOTICE_OF_BEST_BIDDER_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: noticesOfBestBiddersActions.CHECK_NOTICE_OF_BEST_BIDDER_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckNoticeOfBestBidder() {
  yield takeLatest(
    noticesOfBestBiddersActions.CHECK_NOTICE_OF_BEST_BIDDER_REQUEST,
    checkNoticeOfBestBidder
  );
}

function* approveNoticeOfBestBidder({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `procurement/notice-of-best-bidder/approve/${data}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: noticesOfBestBiddersActions.APPROVE_NOTICE_OF_BEST_BIDDER_SUCCESS,
      data: response,
    });

    yield put({
      type: noticesOfBestBiddersActions.GET_NOTICE_OF_BEST_BIDDER_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: noticesOfBestBiddersActions.APPROVE_NOTICE_OF_BEST_BIDDER_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveNoticeOfBestBidder() {
  yield takeLatest(
    noticesOfBestBiddersActions.APPROVE_NOTICE_OF_BEST_BIDDER_REQUEST,
    approveNoticeOfBestBidder
  );
}

export default [
  fork(watchGetNoticesOfBestBidders),
  fork(watchGetMetaData),
  fork(watchAddNoticeOfBestBidder),
  fork(watchDeleteNoticeOfBestBidder),
  fork(watchUpdateNoticeOfBestBidder),
  fork(watchGetNoticeOfBestBidderDetails),
  fork(watchCheckNoticeOfBestBidder),
  fork(watchApproveNoticeOfBestBidder),
];
