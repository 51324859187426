const localPurchaseOrdersInitialState = {
  localPurchaseOrders: {
    localPurchaseOrdersLoading: false,
    localPurchaseOrdersSuccess: {},
    localPurchaseOrdersError: {},
    localPurchaseOrderItemsLoading: false,
    localPurchaseOrderItemsSuccess: {},
    localPurchaseOrderItemsError: {},

    localPurchaseOrdersMetaDataLoading: false,
    localPurchaseOrdersMetaDataSuccess: {},
    localPurchaseOrderItemsMetaDataLoading: false,
    localPurchaseOrderItemsMetaDataSuccess: {},

    addLocalPurchaseOrderData: {},
    addLocalPurchaseOrderLoading: false,
    addLocalPurchaseOrderSuccess: {},
    addLocalPurchaseOrderError: {},
    addLocalPurchaseOrderItemData: {},
    addLocalPurchaseOrderItemLoading: false,
    addLocalPurchaseOrderItemSuccess: {},
    addLocalPurchaseOrderItemError: {},

    deleteLocalPurchaseOrderLoading: false,
    deleteLocalPurchaseOrderSuccess: {},
    deleteLocalPurchaseOrderError: {},
    deleteLocalPurchaseOrderItemLoading: false,
    deleteLocalPurchaseOrderItemSuccess: {},
    deleteLocalPurchaseOrderItemError: {},

    updateLocalPurchaseOrderData: {},
    updateLocalPurchaseOrderLoading: false,
    updateLocalPurchaseOrderSuccess: {},
    updateLocalPurchaseOrderError: {},
    updateLocalPurchaseOrderItemData: {},
    updateLocalPurchaseOrderItemLoading: false,
    updateLocalPurchaseOrderItemSuccess: {},
    updateLocalPurchaseOrderItemError: {},

    localPurchaseOrderDetailsLoading: false,
    localPurchaseOrderDetailsSuccess: {},
    localPurchaseOrderDetailsError: {},
    localPurchaseOrderItemDetailsLoading: false,
    localPurchaseOrderItemDetailsSuccess: {},
    localPurchaseOrderItemDetailsError: {},

    checkLocalPurchaseOrderLoading: false,
    checkLocalPurchaseOrderSuccess: {},
    checkLocalPurchaseOrderError: {},

    approveLocalPurchaseOrderLoading: false,
    approveLocalPurchaseOrderSuccess: {},
    approveLocalPurchaseOrderError: {},
  },
};

export default localPurchaseOrdersInitialState;
