import React from "react";
import { Carousel } from "antd";
const items = [
  {
    key: "1",
    title: "Finance management for your business",
    content:
      "Famis is a financial management system that helps you manage your finances. It is a complete solution for your business.",
  },
  {
    key: "2",
    title: "Work better together",
    content:
      "Famis enables you to work with your team and your clients in a more efficient way.",
  },
  {
    key: "3",
    title: "The best finance system to increase your productivity",
    content:
      "Famis increases your productivity by making it easier to manage your finances.",
  },
];

function AppHero() {
  return (
    <div id="hero" className="heroBlock">
      <Carousel autoplay>
        {items.map((item) => {
          return (
            <div key={item.key} className="container-fluid">
              <div className="content">
                <h3>{item.title}</h3>
                <p>{item.content}</p>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}

export default AppHero;
