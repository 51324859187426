const learnerAttendanceInitialState = {
  learnerAttendanceSuccess: {},
  learnerAttendanceError: {},
  learnerAttendanceLoading: false,

  learnerAttendanceDetailsSuccess: {},
  learnerAttendanceDetailsError: {},
  learnerAttendanceDetailsLoading: false,

  metaData: {},
  metaDataError: {},
  metaDataLoading: false,

  addLearnerAttendanceSuccess: {},
  addLearnerAttendanceError: {},
  addLearnerAttendanceLoading: false,

  updateLearnerAttendanceSuccess: {},
  updateLearnerAttendanceError: {},
  updateLearnerAttendanceLoading: false,

  deleteLearnerAttendanceSuccess: {},
  deleteLearnerAttendanceError: {},
  deleteLearnerAttendanceLoading: false,

  checkLearnerAttendanceSuccess: {},
  checkLearnerAttendanceError: {},
  checkLearnerAttendanceLoading: false,

  approveLearnerAttendanceSuccess: {},
  approveLearnerAttendanceError: {},
  approveLearnerAttendanceLoading: false,

  setRollCallData: [],
  setUpdateRollCallData: {},
};

export default learnerAttendanceInitialState;
