import { subscriptionsActions } from "../../actions";
import initialState from "../../initialState";

const subscriptions = (state = initialState.subscriptions, actions) => {
  switch (actions.type) {
    case subscriptionsActions.GET_SUBSCRIPTION_PLANS_REQUEST:
      return {
        ...state,
        subscriptionPlansLoading: true,
      };
    case subscriptionsActions.GET_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        subscriptionPlansLoading: false,
        subscriptionsPlansSuccess: actions.data,
        subscriptionsPlansError: {},
      };
    case subscriptionsActions.GET_SUBSCRIPTION_PLAN_ERROR:
      return {
        ...state,
        subscriptionPlansLoading: false,
        subscriptionsPlansSuccess: {},
        subscriptionsPlansError: actions.error,
      };
    case subscriptionsActions.ADD_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        addSubscriptionLoading: true,
        addSubscriptionSuccess: {},
        addSubscriptionError: {},
      };
    case subscriptionsActions.ADD_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        addSubscriptionLoading: false,
        addSubscriptionSuccess: actions.data,
        addSubscriptionError: {},
      };
    case subscriptionsActions.ADD_SUBSCRIPTION_ERROR:
      return {
        ...state,
        addSubscriptionLoading: false,
        addSubscriptionSuccess: {},
        addSubscriptionError: actions.error,
      };
    case subscriptionsActions.GET_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        subscriptionsLoading: true,
      };
    case subscriptionsActions.GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptionsLoading: false,
        subscriptionsSuccess: actions.data,
        subscriptionsError: {},
      };
    case subscriptionsActions.GET_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        subscriptionsLoading: false,
        subscriptionsError: actions.error,
      };
    case subscriptionsActions.SET_SUBSCRIPTION_PAYMENT_DETAILS:
      return {
        ...state,
        subscriptionsPaymentDetails: actions.data,
      };
    case subscriptionsActions.ADD_SUBSCRIPTION_PAYMENT_REQUEST:
      return {
        ...state,
        addSubscriptionsPaymentsLoading: true,
        addSubscriptionsPaymentsSuccess: {},
        addSubscriptionsPaymentsError: {},
      };
    case subscriptionsActions.ADD_SUBSCRIPTION_PAYMENT_SUCCESS:
      return {
        ...state,
        addSubscriptionsPaymentsLoading: false,
        addSubscriptionsPaymentsSuccess: actions.data,
        addSubscriptionsPaymentsError: {},
      };
    case subscriptionsActions.ADD_SUBSCRIPTION_PAYMENT_ERROR:
      return {
        ...state,
        addSubscriptionsPaymentsLoading: false,
        addSubscriptionsPaymentsError: actions.error,
      };
    case subscriptionsActions.GET_LATEST_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        latestSubscriptionLoading: true,
      };
    case subscriptionsActions.GET_LATEST_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        latestSubscriptionLoading: false,
        latestSubscriptionSuccess: actions.data,
        latestSubscriptionError: {},
      };
    case subscriptionsActions.GET_LATEST_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        latestSubscriptionLoading: false,
        latestSubscriptionError: actions.error,
      };
    case subscriptionsActions.CANCEL_PENDING_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        cancelPendingSubscriptionLoading: true,
        cancelPendingSubscriptionSuccess: {},
        cancelPendingSubscriptionError: {},
      };
    case subscriptionsActions.CANCEL_PENDING_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        cancelPendingSubscriptionSuccess: actions.data,
        cancelPendingSubscriptionLoading: false,
        cancelPendingSubscriptionError: {},
      };
    case subscriptionsActions.CANCEL_PENDING_SUBSCRIPTION_ERROR:
      return {
        ...state,
        cancelPendingSubscriptionSuccess: {},
        cancelPendingSubscriptionLoading: false,
        cancelPendingSubscriptionError: actions.error,
      };
    default:
      return state;
  }
};

export default subscriptions;
