import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { clientInvoicesActions } from "../../../actions";

function* getClientInvoices() {
  try {
    const response = yield axios.get("/finance/client-invoices");
    yield put({
      type: clientInvoicesActions.GET_CLIENT_INVOICES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: clientInvoicesActions.GET_CLIENT_INVOICES_ERROR,
      error: error.data,
    });
  }
}

function* watchGetClientInvoices() {
  yield takeLatest(
    clientInvoicesActions.GET_CLIENT_INVOICES_REQUEST,
    getClientInvoices
  );
}

function* getClientInvoiceDetails({ id }) {
  try {
    const response = yield axios.get(`/finance/client-invoices/${id}`);
    yield put({
      type: clientInvoicesActions.GET_CLIENT_INVOICE_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: clientInvoicesActions.GET_CLIENT_INVOICE_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetClientInvoiceDetails() {
  yield takeLatest(
    clientInvoicesActions.GET_CLIENT_INVOICE_DETAILS_REQUEST,
    getClientInvoiceDetails
  );
}

function* addClientInvoice({ data }) {
  try {
    const response = yield axios.post("/finance/client-invoices", data);
    yield put({
      type: clientInvoicesActions.ADD_CLIENT_INVOICE_SUCCESS,
      data: response,
    });
    yield put(clientInvoicesActions.getClientInvoices());
  } catch (error) {
    yield put({
      type: clientInvoicesActions.ADD_CLIENT_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* watchAddClientInvoice() {
  yield takeLatest(
    clientInvoicesActions.ADD_CLIENT_INVOICE_REQUEST,
    addClientInvoice
  );
}

function* updateClientInvoice({ id, data }) {
  try {
    const response = yield axios.put(`/finance/client-invoices/${id}`, data);
    yield put({
      type: clientInvoicesActions.UPDATE_CLIENT_INVOICE_SUCCESS,
      data: {
        ...response,
        id,
        data,
      },
    });
  } catch (error) {
    yield put({
      type: clientInvoicesActions.UPDATE_CLIENT_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateClientInvoice() {
  yield takeLatest(
    clientInvoicesActions.UPDATE_CLIENT_INVOICE_REQUEST,
    updateClientInvoice
  );
}

function* deleteClientInvoice({ id }) {
  try {
    const response = yield axios.delete(`/finance/client-invoices/${id}`);
    yield put({
      type: clientInvoicesActions.DELETE_CLIENT_INVOICE_SUCCESS,
      data: {
        ...response,
        id,
      },
    });
  } catch (error) {
    yield put({
      type: clientInvoicesActions.DELETE_CLIENT_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteClientInvoice() {
  yield takeLatest(
    clientInvoicesActions.DELETE_CLIENT_INVOICE_REQUEST,
    deleteClientInvoice
  );
}

function* getClientInvoicesMetaData() {
  try {
    const response = yield axios.get("/finance/client-invoices/meta-data/");
    yield put({
      type: clientInvoicesActions.GET_CLIENT_INVOICES_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: clientInvoicesActions.GET_CLIENT_INVOICES_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetClientInvoicesMetaData() {
  yield takeLatest(
    clientInvoicesActions.GET_CLIENT_INVOICES_META_DATA_REQUEST,
    getClientInvoicesMetaData
  );
}

export default [
  fork(watchGetClientInvoices),
  fork(watchGetClientInvoiceDetails),
  fork(watchAddClientInvoice),
  fork(watchUpdateClientInvoice),
  fork(watchDeleteClientInvoice),
  fork(watchGetClientInvoicesMetaData),
];
