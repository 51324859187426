import { takeLatest, put, fork, takeEvery } from "@redux-saga/core/effects";
import axios from "axios";
const FileDownload = require("js-file-download");
import {
  appUiActions,
  feesCollectionsActions,
  incomeReceiptActions,
  serverActions,
} from "../../../actions";

function* downloadExcel({ data }) {
  try {
    const response = yield axios({
      url: "/finance/income-receipts/download-excel-template",
      data,
      method: "POST",
      responseType: "blob",
    });

    FileDownload(response?.data, `INCOME RECEIPTS EXCEL UPLOAD.xlsx`);

    yield put({
      type: incomeReceiptActions.DOWNLOAD_EXCEL_SUCCESS,
      data: response,
    });

    yield put({
      type: serverActions.SERVER_SUCCESS,
      data: {
        server: { status: true, message: "Successfully downloaded excel" },
        message: "Successfully Downloaded template",
      },
    });
  } catch (error) {
    yield put({
      type: serverActions.SERVER_ERROR,
      error: {
        data: {
          server: { status: false, message: "Unable to download excel" },
          message: "Unable to Download template",
        },
      },
    });
    yield put({
      type: incomeReceiptActions.DOWNLOAD_EXCEL_ERROR,
      error: error?.data,
    });
  }
}

function* watchDownloadExcel() {
  yield takeLatest(incomeReceiptActions.DOWNLOAD_EXCEL_REQUEST, downloadExcel);
}

function* syncSchoolPayTransactions({ data }) {
  try {
    const response = yield axios({
      url: "/finance/income-receipts/sync-school-pay-transactions",
      data,
      method: "POST",
    });

    yield put({
      type: incomeReceiptActions.SYNC_SCHOOL_PAY_TRANSACTIONS_SUCCESS,
      data: response,
    });
    yield put(incomeReceiptActions.getIncomeReceipts());
    yield put(feesCollectionsActions.getFeesDefaulters());
  } catch (error) {
    yield put({
      type: incomeReceiptActions.SYNC_SCHOOL_PAY_TRANSACTIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchSyncSchoolPayTransactions() {
  yield takeLatest(
    incomeReceiptActions.SYNC_SCHOOL_PAY_TRANSACTIONS_REQUEST,
    syncSchoolPayTransactions
  );
}
function* getIncomeReceipts() {
  try {
    const response = yield axios.get("/finance/income-receipts");

    yield put({
      type: incomeReceiptActions.GET_INCOME_RECEIPTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: incomeReceiptActions.GET_INCOME_RECEIPTS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetIncomeReceipts() {
  yield takeLatest(
    incomeReceiptActions.GET_INCOME_RECEIPTS_REQUEST,
    getIncomeReceipts
  );
}

function* getMetaData({ data }) {
  try {
    const response = yield axios.get("/finance/income-receipts/meta-data", {
      params: { budgetId: data },
    });

    yield put({
      type: incomeReceiptActions.GET_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: incomeReceiptActions.GET_META_DATA_SUCCESS,
      data: {},
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(incomeReceiptActions.GET_META_DATA_REQUEST, getMetaData);
}

function* uploadExcel({ data }) {
  try {
    const response = yield axios({
      url: "/finance/income-receipts/upload",
      method: "POST",
      data,
    });

    yield put({
      type: incomeReceiptActions.UPLOAD_EXCEL_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: incomeReceiptActions.UPLOAD_EXCEL_ERROR,
      error: error.data,
    });
  }
}

function* watchUploadExcel() {
  yield takeLatest(incomeReceiptActions.UPLOAD_EXCEL_REQUEST, uploadExcel);
}

function* addIncomeReceipt({ data }) {
  try {
    const response = yield axios({
      url: "/finance/income-receipts/",
      data,
      method: "POST",
    });

    yield put({
      type: incomeReceiptActions.ADD_INCOME_RECEIPTS_SUCCESS,
      data: response,
    });
    yield put(incomeReceiptActions.getIncomeReceipts());
  } catch (error) {
    yield put({
      type: incomeReceiptActions.ADD_INCOME_RECEIPTS_ERROR,
      error: error.data,
    });
  }
}

function* watchAddIncomeReceipt() {
  yield takeLatest(
    incomeReceiptActions.ADD_INCOME_RECEIPTS_REQUEST,
    addIncomeReceipt
  );
}

function* searchIncomeReceipt({ data }) {
  try {
    const response = yield axios({
      url: "/finance/income-receipts/search",
      method: "POST",
      data,
    });

    yield put({
      type: incomeReceiptActions.SEARCH_INCOME_RECEIPT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: incomeReceiptActions.SEARCH_INCOME_RECEIPT_ERROR,
      error: error.data,
    });
  }
}

function* watchSearchIncomeReceipt() {
  yield takeLatest(
    incomeReceiptActions.SEARCH_INCOME_RECEIPT_REQUEST,
    searchIncomeReceipt
  );
}

function* updateIncomeReceipts({ data, id }) {
  try {
    const response = yield axios({
      url: `/finance/income-receipts/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: incomeReceiptActions.UPDATE_INCOME_RECEIPT_SUCCESS,
      data: response,
    });
    yield put(incomeReceiptActions.getIncomeReceipts());
    yield put(appUiActions.toggleUpdateIncomeReceiptModal(false));
  } catch (error) {
    yield put({
      type: incomeReceiptActions.UPDATE_INCOME_RECEIPT_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateIncomeReceipts() {
  yield takeLatest(
    incomeReceiptActions.UPDATE_INCOME_RECEIPT_REQUEST,
    updateIncomeReceipts
  );
}

function* deleteIncomeReceipts({ data, sortData }) {
  try {
    const response = yield axios.delete(`/finance/income-receipts/${data}`);
    yield put({
      type: incomeReceiptActions.DELETE_INCOME_RECEIPT_SUCCESS,
      data: response,
    });
    yield put(incomeReceiptActions.getIncomeReceipts());
    yield put(incomeReceiptActions.sortIncomeReceipts(sortData));
  } catch (error) {
    yield put({
      type: incomeReceiptActions.DELETE_INCOME_RECEIPT_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteIncomeReceipt() {
  yield takeEvery(
    incomeReceiptActions.DELETE_INCOME_RECEIPT_REQUEST,
    deleteIncomeReceipts
  );
}

function* getIncomeReceiptDetails({ data }) {
  try {
    const response = yield axios.get(`/finance/income-receipts/${data}`);

    yield put({
      type: incomeReceiptActions.GET_INCOME_RECEIPT_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: incomeReceiptActions.GET_INCOME_RECEIPT_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetIncomeReceiptDetails() {
  yield takeLatest(
    incomeReceiptActions.GET_INCOME_RECEIPT_DETAILS_REQUEST,
    getIncomeReceiptDetails
  );
}

function* getIncomeReceiptStreams({ data }) {
  try {
    const response = yield axios.get(`/finance/income-receipts/streams`, {
      params: { incomeReceiptId: data },
    });

    yield put({
      type: incomeReceiptActions.GET_INCOME_RECEIPT_STREAMS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: incomeReceiptActions.GET_INCOME_RECEIPT_STREAMS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetIncomeReceiptStreams() {
  yield takeLatest(
    incomeReceiptActions.GET_INCOME_RECEIPT_STREAMS_REQUEST,
    getIncomeReceiptStreams
  );
}

function* addIncomeReceiptStreams({ data, incomeReceiptStreams }) {
  try {
    const response = yield axios({
      url: "/finance/income-receipts/streams",
      data,
      method: "POST",
    });

    yield put({
      type: incomeReceiptActions.ADD_INCOME_RECEIPT_STREAM_SUCCESS,
      incomeReceiptStreams,
      data: response,
    });
  } catch (error) {
    yield put({
      type: incomeReceiptActions.ADD_INCOME_RECEIPT_STREAM_ERROR,
      error: error.data,
    });
  }
}

function* watchAddIncomeReceiptStream() {
  yield takeLatest(
    incomeReceiptActions.ADD_INCOME_RECEIPT_STREAM_REQUEST,
    addIncomeReceiptStreams
  );
}

function* updateIncomeReceiptStream({ data, id, incomeReceiptStreams }) {
  try {
    const response = yield axios({
      data,
      url: `/finance/income-receipts/streams/${id}`,
      method: "PUT",
    });

    yield put(
      incomeReceiptActions.getIncomeReceiptDetails(data.income_receipt_id)
    );
    yield put({
      type: incomeReceiptActions.UPDATE_INCOME_RECEIPT_STREAM_SUCCESS,
      data: response,
      incomeReceiptStreams,
    });
    yield put(appUiActions.toggleUpdateIncomeReceiptStreamModal(false));
  } catch (error) {
    yield put({
      type: incomeReceiptActions.UPDATE_INCOME_RECEIPT_STREAM_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateIncomeReceiptStreams() {
  yield takeLatest(
    incomeReceiptActions.UPDATE_INCOME_RECEIPT_STREAM_REQUEST,
    updateIncomeReceiptStream
  );
}

function* deleteIncomeReceiptStream({ data, params }) {
  try {
    const response = yield axios({
      url: `/finance/income-receipts/streams`,
      data,
      params,
      method: "DELETE",
    });

    yield put({
      type: incomeReceiptActions.DELETE_INCOME_RECEIPT_STREAM_SUCCESS,
      data: response,
    });
    yield put(incomeReceiptActions.getIncomeReceipts());
  } catch (error) {
    yield put({
      type: incomeReceiptActions.DELETE_INCOME_RECEIPT_STREAM_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteIncomeReceiptStream() {
  yield takeEvery(
    incomeReceiptActions.DELETE_INCOME_RECEIPT_STREAM_REQUEST,
    deleteIncomeReceiptStream
  );
}

function* getIncomeReceiptStreamDetails({ data }) {
  try {
    const response = yield axios.get(
      `/finance/income-receipts/streams/${data}`
    );

    yield put({
      type: incomeReceiptActions.GET_INCOME_RECEIPT_STREAM_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: incomeReceiptActions.GET_INCOME_RECEIPT_STREAM_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetIncomeReceiptStreamDetails() {
  yield takeLatest(
    incomeReceiptActions.GET_INCOME_RECEIPT_STREAM_DETAILS_REQUEST,
    getIncomeReceiptStreamDetails
  );
}

function* addIncomeReceiptDocuments({ data, params }) {
  try {
    const response = yield axios({
      url: "/finance/income-receipts/stream-documents",
      data,
      params,
      method: "POST",
    });
    yield put({
      type: incomeReceiptActions.ADD_INCOME_RECEIPT_DOCUMENT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: incomeReceiptActions.ADD_INCOME_RECEIPT_DOCUMENT_ERROR,
      error: error.data,
    });
  }
}

function* watchAddIncomeReceiptDocuments() {
  yield takeLatest(
    incomeReceiptActions.ADD_INCOME_RECEIPT_DOCUMENT_REQUEST,
    addIncomeReceiptDocuments
  );
}

function* deleteIncomeReceiptDocument({ data }) {
  try {
    const response = yield axios.delete(
      `/finance/income-receipts/stream-documents/${data}`
    );

    yield put({
      type: incomeReceiptActions.DELETE_INCOME_RECEIPT_DOCUMENT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: incomeReceiptActions.DELETE_INCOME_RECEIPT_DOCUMENT_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteIncomeReceiptDocument() {
  yield takeEvery(
    incomeReceiptActions.DELETE_INCOME_RECEIPT_DOCUMENT_REQUEST,
    deleteIncomeReceiptDocument
  );
}

function* sortIncomeReceipts({ data }) {
  try {
    const response = yield axios({
      url: `/finance/income-receipts/sort`,
      method: "GET",
      params: data,
    });

    yield put({
      type: incomeReceiptActions.SORT_INCOME_RECEIPTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: incomeReceiptActions.SORT_INCOME_RECEIPTS_ERROR,
      error: error.data,
    });
    yield put(appUiActions.toggleSortFromTableModal(false));
  }
}

function* watchSortIncomeReceipts() {
  yield takeLatest(
    incomeReceiptActions.SORT_INCOME_RECEIPTS_REQUEST,
    sortIncomeReceipts
  );
}

function* downloadIncomeReceipts({ data }) {
  try {
    const response = yield axios({
      url: `/finance/income-receipts/excel-report`,
      method: "GET",
      params: data,
      responseType: "blob",
    });

    FileDownload(response.data, "income-receipts.xlsx");

    yield put({
      type: incomeReceiptActions.DOWNLOAD_INCOME_RECEIPTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: incomeReceiptActions.DOWNLOAD_INCOME_RECEIPTS_ERROR,
      error: error.data,
    });
  }
}

function* watchDownloadIncomeReceipts() {
  yield takeLatest(
    incomeReceiptActions.DOWNLOAD_INCOME_RECEIPTS_REQUEST,
    downloadIncomeReceipts
  );
}

function* importSchoolPayExcel({ data }) {
  try {
    const response = axios({
      url: "/finance/income-receipts/import-school-pay-excel",
      method: "POST",
      data,
    });

    yield put({
      type: incomeReceiptActions.IMPORT_SCHOOL_PAY_EXCEL_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: incomeReceiptActions.IMPORT_SCHOOL_PAY_EXCEL_ERROR,
      error: error.data,
    });
  }
}

function* watchImportSchoolPayExcel() {
  yield takeLatest(
    incomeReceiptActions.IMPORT_SCHOOL_PAY_EXCEL_REQUEST,
    importSchoolPayExcel
  );
}

export default [
  fork(watchGetIncomeReceipts),
  fork(watchAddIncomeReceipt),
  fork(watchGetMetaData),
  fork(watchUpdateIncomeReceipts),
  fork(watchSearchIncomeReceipt),
  fork(watchDeleteIncomeReceipt),
  fork(watchGetIncomeReceiptDetails),
  fork(watchGetIncomeReceiptStreams),
  fork(watchAddIncomeReceiptStream),
  fork(watchUpdateIncomeReceiptStreams),
  fork(watchDeleteIncomeReceiptStream),
  fork(watchGetIncomeReceiptStreamDetails),
  fork(watchAddIncomeReceiptDocuments),
  fork(watchDeleteIncomeReceiptDocument),
  fork(watchUploadExcel),
  fork(watchDownloadExcel),
  fork(watchSortIncomeReceipts),
  fork(watchDownloadIncomeReceipts),
  fork(watchSyncSchoolPayTransactions),
  fork(watchImportSchoolPayExcel),
];
