import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { appUiActions, bidEvaluationsActions } from "../../../actions/";

function* getBidEvaluations() {
  try {
    const data = yield axios({
      url: "procurement/bid-evaluations",
    });

    yield put({
      type: bidEvaluationsActions.GET_BID_EVALUATIONS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.GET_BID_EVALUATIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetBidEvaluations() {
  yield takeLatest(
    bidEvaluationsActions.GET_BID_EVALUATIONS_REQUEST,
    getBidEvaluations
  );
}

function* getEligibilityEvaluations({ id }) {
  try {
    // Bid evaluation id
    const data = yield axios({
      url: `procurement/eligibility-evaluations/${id}`,
    });

    yield put({
      type: bidEvaluationsActions.GET_ELIGIBILITY_EVALUATIONS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.GET_ELIGIBILITY_EVALUATIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetEligibilityEvaluations() {
  yield takeLatest(
    bidEvaluationsActions.GET_ELIGIBILITY_EVALUATIONS_REQUEST,
    getEligibilityEvaluations
  );
}

function* getTechnicalEvaluations({ id }) {
  try {
    // Bid evaluation id
    const data = yield axios({
      url: `procurement/technical-evaluations/${id}`,
    });

    yield put({
      type: bidEvaluationsActions.GET_TECHNICAL_EVALUATIONS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.GET_TECHNICAL_EVALUATIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetTechnicalEvaluations() {
  yield takeLatest(
    bidEvaluationsActions.GET_TECHNICAL_EVALUATIONS_REQUEST,
    getTechnicalEvaluations
  );
}

function* getFinancialEvaluations({ id }) {
  try {
    // Bid evaluation id
    const data = yield axios({
      url: `procurement/financial-evaluations/${id}`,
    });

    yield put({
      type: bidEvaluationsActions.GET_FINANCIAL_EVALUATIONS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.GET_FINANCIAL_EVALUATIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetFinancialEvaluations() {
  yield takeLatest(
    bidEvaluationsActions.GET_FINANCIAL_EVALUATIONS_REQUEST,
    getFinancialEvaluations
  );
}

function* getBidEvaluationDetails({ data }) {
  try {
    const response = yield axios({
      url: `procurement/bid-evaluations/details/${data}`,
    });

    yield put({
      type: bidEvaluationsActions.GET_BID_EVALUATION_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.GET_BID_EVALUATION_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetBidEvaluationDetails() {
  yield takeLatest(
    bidEvaluationsActions.GET_BID_EVALUATION_DETAILS_REQUEST,
    getBidEvaluationDetails
  );
}

function* getFinancialEvaluationDetails({ data }) {
  try {
    const response = yield axios({
      url: `procurement/financial-evaluations/details/${data}`,
    });

    yield put({
      type: bidEvaluationsActions.GET_FINANCIAL_EVALUATION_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.GET_FINANCIAL_EVALUATION_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetFinancialEvaluationDetails() {
  yield takeLatest(
    bidEvaluationsActions.GET_FINANCIAL_EVALUATION_DETAILS_REQUEST,
    getFinancialEvaluationDetails
  );
}

function* getBidEvaluationsMetaData() {
  try {
    const response = yield axios({
      url: "procurement/bid-evaluations/meta-data",
    });

    yield put({
      type: bidEvaluationsActions.GET_BID_EVALUATIONS_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.GET_BID_EVALUATIONS_META_DATA_SUCCESS,
      data: {},
    });
  }
}

function* watchGetBidEvaluationsMetaData() {
  yield takeLatest(
    bidEvaluationsActions.GET_BID_EVALUATIONS_META_DATA_REQUEST,
    getBidEvaluationsMetaData
  );
}

function* getEligibilityEvaluationsMetaData() {
  try {
    const response = yield axios({
      url: "procurement/eligibility-evaluations/meta-data",
    });

    yield put({
      type: bidEvaluationsActions.GET_ELIGIBILITY_EVALUATIONS_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.GET_ELIGIBILITY_EVALUATIONS_META_DATA_SUCCESS,
      data: {},
    });
  }
}

function* watchGetEligibilityEvaluationsMetaData() {
  yield takeLatest(
    bidEvaluationsActions.GET_ELIGIBILITY_EVALUATIONS_META_DATA_REQUEST,
    getEligibilityEvaluationsMetaData
  );
}

function* addBidEvaluation({ data }) {
  try {
    const response = yield axios({
      url: "procurement/bid-evaluations/",
      method: "POST",
      data,
    });

    yield put({
      type: bidEvaluationsActions.ADD_BID_EVALUATION_SUCCESS,
      data: response,
    });

    yield put({
      type: bidEvaluationsActions.GET_BID_EVALUATIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.ADD_BID_EVALUATION_ERROR,
      error: error.data,
    });
  }
}

function* watchAddBidEvaluation() {
  yield takeLatest(
    bidEvaluationsActions.ADD_BID_EVALUATION_REQUEST,
    addBidEvaluation
  );
}

function* addEligibilityEvaluation({ data }) {
  try {
    const response = yield axios({
      url: "procurement/eligibility-evaluations/",
      method: "POST",
      data,
    });

    yield put({
      type: bidEvaluationsActions.ADD_ELIGIBILITY_EVALUATION_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.ADD_ELIGIBILITY_EVALUATION_ERROR,
      error: error.data,
    });
  }
}

function* watchAddEligibilityEvaluation() {
  yield takeLatest(
    bidEvaluationsActions.ADD_ELIGIBILITY_EVALUATION_REQUEST,
    addEligibilityEvaluation
  );
}

function* addTechnicalEvaluation({ data }) {
  try {
    const response = yield axios({
      url: "procurement/technical-evaluations/",
      method: "POST",
      data,
    });

    yield put({
      type: bidEvaluationsActions.ADD_TECHNICAL_EVALUATION_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.ADD_TECHNICAL_EVALUATION_ERROR,
      error: error.data,
    });
  }
}

function* watchAddTechnicalEvaluation() {
  yield takeLatest(
    bidEvaluationsActions.ADD_TECHNICAL_EVALUATION_REQUEST,
    addTechnicalEvaluation
  );
}

function* addFinancialEvaluation({ data }) {
  try {
    const response = yield axios({
      url: "procurement/financial-evaluations/",
      method: "POST",
      data,
    });

    yield put({
      type: bidEvaluationsActions.ADD_FINANCIAL_EVALUATION_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.ADD_FINANCIAL_EVALUATION_ERROR,
      error: error.data,
    });
  }
}

function* watchAddFinancialEvaluation() {
  yield takeLatest(
    bidEvaluationsActions.ADD_FINANCIAL_EVALUATION_REQUEST,
    addFinancialEvaluation
  );
}

function* deleteBidEvaluation({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `procurement/bid-evaluations/${data}`,
      method: "DELETE",
    });

    yield put({
      type: bidEvaluationsActions.DELETE_BID_EVALUATION_SUCCESS,
      data: response,
    });

    yield put({
      type: bidEvaluationsActions.GET_BID_EVALUATIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.DELETE_BID_EVALUATION_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteBidEvaluation() {
  yield takeLatest(
    bidEvaluationsActions.DELETE_BID_EVALUATION_REQUEST,
    deleteBidEvaluation
  );
}

function* deleteEligibilityEvaluation({ bidId, id }) {
  // data in url
  try {
    const response = yield axios({
      url: `procurement/eligibility-evaluations/${id}`,
      method: "DELETE",
    });

    yield put({
      type: bidEvaluationsActions.DELETE_ELIGIBILITY_EVALUATION_SUCCESS,
      data: response,
    });

    yield put({
      type: bidEvaluationsActions.GET_ELIGIBILITY_EVALUATIONS_REQUEST,
      id: bidId,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.DELETE_ELIGIBILITY_EVALUATION_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteEligibilityEvaluation() {
  yield takeLatest(
    bidEvaluationsActions.DELETE_ELIGIBILITY_EVALUATION_REQUEST,
    deleteEligibilityEvaluation
  );
}

function* deleteTechnicalEvaluation({ bidId, id }) {
  // data in url
  try {
    const response = yield axios({
      url: `procurement/technical-evaluations/${id}`,
      method: "DELETE",
    });

    yield put({
      type: bidEvaluationsActions.DELETE_TECHNICAL_EVALUATION_SUCCESS,
      data: response,
    });

    yield put({
      type: bidEvaluationsActions.GET_TECHNICAL_EVALUATIONS_REQUEST,
      id: bidId,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.DELETE_TECHNICAL_EVALUATION_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteTechnicalEvaluation() {
  yield takeLatest(
    bidEvaluationsActions.DELETE_TECHNICAL_EVALUATION_REQUEST,
    deleteTechnicalEvaluation
  );
}

function* updateBidEvaluation({ data, id }) {
  // id in url
  try {
    const response = yield axios({
      url: `procurement/bid-evaluations/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: bidEvaluationsActions.UPDATE_BID_EVALUATION_SUCCESS,
      data: response,
    });

    yield put({
      type: appUiActions.TOGGLE_UPDATE_BID_EVALUATION_MODAL,
      data: false,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.UPDATE_BID_EVALUATION_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateBidEvaluation() {
  yield takeLatest(
    bidEvaluationsActions.UPDATE_BID_EVALUATION_REQUEST,
    updateBidEvaluation
  );
}

function* updateEligibilityEvaluation({ data, id }) {
  // id in url
  try {
    const response = yield axios({
      url: `procurement/eligibility-evaluations/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: bidEvaluationsActions.UPDATE_ELIGIBILITY_EVALUATION_SUCCESS,
      data: response,
    });

    yield put({
      type: appUiActions.TOGGLE_UPDATE_ELIGIBILITY_EVALUATION_MODAL,
      data: false,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.UPDATE_ELIGIBILITY_EVALUATION_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateEligibilityEvaluation() {
  yield takeLatest(
    bidEvaluationsActions.UPDATE_ELIGIBILITY_EVALUATION_REQUEST,
    updateEligibilityEvaluation
  );
}

function* updateTechnicalEvaluation({ data, id }) {
  // id in url
  try {
    const response = yield axios({
      url: `procurement/technical-evaluations/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: bidEvaluationsActions.UPDATE_TECHNICAL_EVALUATION_SUCCESS,
      data: response,
    });

    yield put({
      type: appUiActions.TOGGLE_UPDATE_TECHNICAL_EVALUATION_MODAL,
      data: false,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.UPDATE_TECHNICAL_EVALUATION_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateTechnicalEvaluation() {
  yield takeLatest(
    bidEvaluationsActions.UPDATE_TECHNICAL_EVALUATION_REQUEST,
    updateTechnicalEvaluation
  );
}

function* updateFinancialEvaluation({ data, id }) {
  // id in url
  try {
    const response = yield axios({
      url: `procurement/financial-evaluations/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: bidEvaluationsActions.UPDATE_FINANCIAL_EVALUATION_SUCCESS,
      data: response,
    });

    yield put({
      type: appUiActions.TOGGLE_UPDATE_FINANCIAL_EVALUATION_MODAL,
      data: false,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.UPDATE_FINANCIAL_EVALUATION_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateFinancialEvaluation() {
  yield takeLatest(
    bidEvaluationsActions.UPDATE_FINANCIAL_EVALUATION_REQUEST,
    updateFinancialEvaluation
  );
}

function* checkBidEvaluation({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `procurement/bid-evaluations/check/${data}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({
      type: bidEvaluationsActions.CHECK_BID_EVALUATION_SUCCESS,
      data: response,
    });

    yield put({
      type: bidEvaluationsActions.GET_BID_EVALUATION_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.CHECK_BID_EVALUATION_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckBidEvaluation() {
  yield takeLatest(
    bidEvaluationsActions.CHECK_BID_EVALUATION_REQUEST,
    checkBidEvaluation
  );
}

function* approveBidEvaluation({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `procurement/bid-evaluations/approve/${data}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: bidEvaluationsActions.APPROVE_BID_EVALUATION_SUCCESS,
      data: response,
    });

    yield put({
      type: bidEvaluationsActions.GET_BID_EVALUATION_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: bidEvaluationsActions.APPROVE_BID_EVALUATION_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveBidEvaluation() {
  yield takeLatest(
    bidEvaluationsActions.APPROVE_BID_EVALUATION_REQUEST,
    approveBidEvaluation
  );
}
export default [
  fork(watchGetBidEvaluations),
  fork(watchGetEligibilityEvaluations),
  fork(watchGetTechnicalEvaluations),
  fork(watchGetFinancialEvaluations),
  fork(watchGetBidEvaluationDetails),
  fork(watchGetFinancialEvaluationDetails),
  fork(watchGetBidEvaluationsMetaData),
  fork(watchGetEligibilityEvaluationsMetaData),
  fork(watchAddBidEvaluation),
  fork(watchAddEligibilityEvaluation),
  fork(watchAddTechnicalEvaluation),
  fork(watchAddFinancialEvaluation),
  fork(watchDeleteBidEvaluation),
  fork(watchDeleteEligibilityEvaluation),
  fork(watchDeleteTechnicalEvaluation),
  fork(watchUpdateBidEvaluation),
  fork(watchUpdateEligibilityEvaluation),
  fork(watchUpdateTechnicalEvaluation),
  fork(watchUpdateFinancialEvaluation),
  fork(watchCheckBidEvaluation),
  fork(watchApproveBidEvaluation),
];
