const staffPayRollActions = {
  GET_STAFF_PAYROLL_REQUEST: "GET_STAFF_PAYROLL_REQUEST",
  GET_STAFF_PAYROLL_SUCCESS: "GET_STAFF_PAYROLL_SUCCESS",
  GET_STAFF_PAYROLL_ERROR: "GET_STAFF_PAYROLL_ERROR",

  ADD_STAFF_PAYROLL_REQUEST: "ADD_STAFF_PAYROLL_REQUEST",
  ADD_STAFF_PAYROLL_SUCCESS: "ADD_STAFF_PAYROLL_SUCCESS",
  ADD_STAFF_PAYROLL_ERROR: "ADD_STAFF_PAYROLL_ERROR",

  UPDATE_STAFF_PAYROLL_REQUEST: "UPDATE_STAFF_PAYROLL_REQUEST",
  UPDATE_STAFF_PAYROLL_SUCCESS: "UPDATE_STAFF_PAYROLL_SUCCESS",
  UPDATE_STAFF_PAYROLL_ERROR: "UPDATE_STAFF_PAYROLL_ERROR",

  DELETE_STAFF_PAYROLL_REQUEST: "DELETE_STAFF_PAYROLL_REQUEST",
  DELETE_STAFF_PAYROLL_SUCCESS: "DELETE_STAFF_PAYROLL_SUCCESS",
  DELETE_STAFF_PAYROLL_ERROR: "DELETE_STAFF_PAYROLL_ERROR",

  SET_UPDATE_STAFF_PAYROLL_DATA: "SET_UPDATE_STAFF_PAYROLL_DATA",

  GET_STAFF_PAYROLL_META_DATA_REQUEST: "GET_STAFF_PAYROLL_META_DATA_REQUEST",
  GET_STAFF_PAYROLL_META_DATA_SUCCESS: "GET_STAFF_PAYROLL_META_DATA_SUCCESS",
  GET_STAFF_PAYROLL_META_DATA_ERROR: "GET_STAFF_PAYROLL_META_DATA_ERROR",

  GET_STAFF_PAYROLL_DETAILS_REQUEST: "GET_STAFF_PAYROLL_DETAILS_REQUEST",
  GET_STAFF_PAYROLL_DETAILS_SUCCESS: "GET_STAFF_PAYROLL_DETAILS_SUCCESS",
  GET_STAFF_PAYROLL_DETAILS_ERROR: "GET_STAFF_PAYROLL_DETAILS_ERROR",

  DELETE_STAFF_FROM_PAYROLL_REQUEST: "DELETE_STAFF_FROM_PAYROLL_REQUEST",
  DELETE_STAFF_FROM_PAYROLL_SUCCESS: "DELETE_STAFF_FROM_PAYROLL_SUCCESS",
  DELETE_STAFF_FROM_PAYROLL_ERROR: "DELETE_STAFF_FROM_PAYROLL_ERROR",

  CHECK_STAFF_PAYROLL_REQUEST: "CHECK_STAFF_PAYROLL_REQUEST",
  CHECK_STAFF_PAYROLL_SUCCESS: "CHECK_STAFF_PAYROLL_SUCCESS",
  CHECK_STAFF_PAYROLL_ERROR: "CHECK_STAFF_PAYROLL_ERROR",

  APPROVE_STAFF_PAYROLL_REQUEST: "APPROVE_STAFF_PAYROLL_REQUEST",
  APPROVE_STAFF_PAYROLL_SUCCESS: "APPROVE_STAFF_PAYROLL_SUCCESS",
  APPROVE_STAFF_PAYROLL_ERROR: "APPROVE_STAFF_PAYROLL_ERROR",

  getStaffPayRoll: () => ({
    type: staffPayRollActions.GET_STAFF_PAYROLL_REQUEST,
  }),

  getStaffPayRollMetaData: () => ({
    type: staffPayRollActions.GET_STAFF_PAYROLL_META_DATA_REQUEST,
  }),

  getStaffPayRollDetails: (id) => ({
    type: staffPayRollActions.GET_STAFF_PAYROLL_DETAILS_REQUEST,
    id,
  }),

  addStaffPayRoll: (data) => ({
    type: staffPayRollActions.ADD_STAFF_PAYROLL_REQUEST,
    data,
  }),

  updateStaffPayRoll: (id, data) => ({
    type: staffPayRollActions.UPDATE_STAFF_PAYROLL_REQUEST,
    id,
    data,
  }),

  deleteStaffPayRoll: (id) => ({
    type: staffPayRollActions.DELETE_STAFF_PAYROLL_REQUEST,
    id,
  }),

  deleteStaffFromPayRoll: (id, payroll_id) => ({
    type: staffPayRollActions.DELETE_STAFF_FROM_PAYROLL_REQUEST,
    id,
    payroll_id,
  }),

  setUpdateStaffPayRollData: (data) => ({
    type: staffPayRollActions.SET_UPDATE_STAFF_PAYROLL_DATA,
    data,
  }),

  checkStaffPayRoll: (id) => ({
    type: staffPayRollActions.CHECK_STAFF_PAYROLL_REQUEST,
    id,
  }),

  approveStaffPayRoll: (id) => ({
    type: staffPayRollActions.APPROVE_STAFF_PAYROLL_REQUEST,
    id,
  }),
};

export default staffPayRollActions;
