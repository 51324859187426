const dashboardsInitialState = {
  dashboards: {
    mainDashboardLoading: false,
    mainDashboardSuccess: {},
    mainDashboardError: {},

    feesCollectionSummaryLoading: false,
    feesCollectionSummarySuccess: {},
    feesCollectionSummaryError: {},

    metaDataLoading: false,
    metaDataSuccess: {},
    metaDataError: {},

    budgetSummaryLoading: false,
    budgetSummarySuccess: {},
    budgetSummaryError: {},

    expenseBudgetSummmaryLoading: false,
    expenseBudgetSummarySuccess: {},
    expenseBudgetSummaryError: {},

    profitAndLossSummaryLoading: false,
    profitAndLossSummarySuccess: {},
    profitAndLossSummaryError: {},

    schoolFinancialPositionSummaryLoading: false,
    schoolFinancialPositionSummary: {},
    schoolFinancialPositionSummaryError: {},

    schoolInventorySummaryLoading: false,
    schoolInventorySummarySuccess: {},
    schoolInventorySummaryError: {},

    attendanceSummaryLoading: false,
    attendanceSummarySuccess: {},
    attendanceSummaryError: {},

    learnerAttendanceSummary: {},
    learnerAttendanceSummaryLoading: false,
    learnerAttendanceSummaryError: {},

    dashboardDetails: {},

    incomeSummaryLoading: false,
    incomeSummarySuccess: {},
    incomeSummaryError: {},
  },
};

export default dashboardsInitialState;
