import { DatePicker, Form, Input, InputNumber, Select, TimePicker } from "antd";
import { toUpper } from "lodash";
import PropTypes, { any, array, object } from "prop-types";
import React from "react";

const { TextArea } = Input;

const AppInput = ({
  name = "",
  label = null,
  rules = [],
  itemAttributes = {},
  inputAttributes = {},
  type = "text",
  options = [],
  allowClear = false,
  showTimePicker,
}) => {
  return (
    <Form.Item
      key={name}
      label={label}
      name={name}
      rules={rules}
      className="mb-2"
      {...itemAttributes}
    >
      {(type === "select" && (
        <Select
          placeholder="Select option"
          // allowClear
          showSearch
          allowClear={allowClear}
          filterOption={(input, option) =>
            (toUpper(option?.label) ?? "").includes(toUpper(input))
          }
          options={options}
          {...inputAttributes}
        />
      )) ||
        (type === "textarea" && <TextArea rows={4} {...inputAttributes} />) ||
        (type === "number" && (
          <InputNumber
            style={{
              width: "100%",
            }}
            {...inputAttributes}
          />
        )) ||
        (type === "date" && (
          <DatePicker
            style={{
              width: "100%",
            }}
            showTime={showTimePicker ? { format: "h:mm a" } : undefined}
            {...inputAttributes}
          />
        )) ||
        (type === "timer" && (
          <TimePicker
            style={{
              width: "100%",
            }}
            {...inputAttributes}
          />
        )) ||
        (type === "password" && <Input.Password {...inputAttributes} />) || (
          <Input {...inputAttributes} />
        )}
    </Form.Item>
  );
};

AppInput.propTypes = {
  name: PropTypes.oneOfType([any]),
  label: PropTypes.string,
  type: PropTypes.string,
  rules: PropTypes.oneOfType([array]),
  options: PropTypes.oneOfType([array]),
  itemAttributes: PropTypes.oneOfType([object]),
  inputAttributes: PropTypes.oneOfType([object]),
  allowClear: PropTypes.bool,
  showTimePicker: PropTypes.bool,
};

export default AppInput;
