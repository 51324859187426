const organisationInitialState = {
  organisations: {
    gettingOrganisations: false,
    organisationsSuccess: {},
    organisationsError: {},

    addOrganisationLoading: false,
    addOrganisationSuccess: {},
    addOrganisationError: {},

    updateOrganisationNameLoading: false,
    updateOrganisationNameSuccess: {},
    updateOrganisationNameError: {},
    setUpdateOrganisationNameData: {},
  },
};

module.exports = organisationInitialState;
