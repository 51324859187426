import { takeLatest, put, fork } from "redux-saga/effects";
import axios from "axios";
import { singleLearnerLedgerActions } from "../../../actions";

function* getSingleLearnerLedger({ data }) {
  try {
    const response = yield axios({
      url: `/administration/fees-collection-data/learner-ledger`,
      method: "GET",
      params: data,
    });

    yield put({
      type: singleLearnerLedgerActions.GET_SINGLE_LEARNER_LEDGER_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: singleLearnerLedgerActions.GET_SINGLE_LEARNER_LEDGER_ERROR,
      data: error.data,
    });
  }
}

function* watchGetSingleLearnerLedger() {
  yield takeLatest(
    singleLearnerLedgerActions.GET_SINGLE_LEARNER_LEDGER,
    getSingleLearnerLedger
  );
}

function* getSingleLearnerLedgerMetaData() {
  try {
    const response = yield axios({
      url: "/administration/fees-collection-data/meta-data",
    });

    yield put({
      type: singleLearnerLedgerActions.GET_SINGLE_LEARNER_LEDGER_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: singleLearnerLedgerActions.GET_SINGLE_LEARNER_LEDGER_META_DATA_ERROR,
      data: error.data,
    });
  }
}

function* watchGetSingleLearnerLedgerMetaData() {
  yield takeLatest(
    singleLearnerLedgerActions.GET_SINGLE_LEARNER_LEDGER_META_DATA,
    getSingleLearnerLedgerMetaData
  );
}

export default [
  fork(watchGetSingleLearnerLedger),
  fork(watchGetSingleLearnerLedgerMetaData),
];
