import { supplierPaymentsActions } from "../../../actions";
import initialState from "../../../initialState";

const supplierPayments = (state = initialState.supplierPayments, actions) => {
  switch (actions.type) {
    case supplierPaymentsActions.GET_SUPPLIER_PAYMENTS_SCHEDULE_REQUEST:
      return {
        ...state,
        supplierPaymentsScheduleLoading: true,
        supplierPaymentsScheduleError: {},
      };
    case supplierPaymentsActions.GET_SUPPLIER_PAYMENTS_SCHEDULE_SUCCESS:
      return {
        ...state,
        supplierPaymentsScheduleLoading: false,
        supplierPaymentsScheduleSuccess: actions.data,
      };
    case supplierPaymentsActions.GET_SUPPLIER_PAYMENTS_SCHEDULE_ERROR:
      return {
        ...state,
        supplierPaymentsScheduleLoading: false,
        supplierPaymentsScheduleError: actions.error,
      };
    case supplierPaymentsActions.GET_SUPPLIER_PAYMENTS_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case supplierPaymentsActions.GET_SUPPLIER_PAYMENTS_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataLoading: false,
        metaData: actions.data,
      };
    case supplierPaymentsActions.ADD_SUPPLIER_PAYMENT_SCHEDULE_REQUEST:
      return {
        ...state,
        addSupplierPaymentScheduleLoading: true,
        addSupplierPaymentScheduleError: {},
      };
    case supplierPaymentsActions.ADD_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        addSupplierPaymentScheduleLoading: false,
        addSupplierPaymentScheduleSuccess: actions.data,
      };
    case supplierPaymentsActions.ADD_SUPPLIER_PAYMENT_SCHEDULE_ERROR:
      return {
        ...state,
        addSupplierPaymentScheduleLoading: false,
        addSupplierPaymentScheduleError: actions.error,
      };
    case supplierPaymentsActions.DELETE_SUPPLIER_PAYMENT_SCHEDULE_REQUEST:
      return {
        ...state,
        deleteSupplierPaymentScheduleLoading: true,
        deleteSupplierPaymentScheduleError: {},
      };
    case supplierPaymentsActions.DELETE_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        deleteSupplierPaymentScheduleLoading: false,
        deleteSupplierPaymentScheduleSuccess: actions.data,
      };
    case supplierPaymentsActions.DELETE_SUPPLIER_PAYMENT_SCHEDULE_ERROR:
      return {
        ...state,
        deleteSupplierPaymentScheduleLoading: false,
        deleteSupplierPaymentScheduleError: actions.error,
      };
    case supplierPaymentsActions.UPDATE_SUPPLIER_PAYMENT_SCHEDULE_REQUEST:
      return {
        ...state,
        updateSupplierPaymentScheduleLoading: true,
        updateSupplierPaymentScheduleError: {},
      };
    case supplierPaymentsActions.UPDATE_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        updateSupplierPaymentScheduleLoading: false,
        updateSupplierPaymentScheduleSuccess: actions.data,
      };
    case supplierPaymentsActions.UPDATE_SUPPLIER_PAYMENT_SCHEDULE_ERROR:
      return {
        ...state,
        updateSupplierPaymentScheduleLoading: false,
        updateSupplierPaymentScheduleError: actions.error,
      };
    case supplierPaymentsActions.GET_SUPPLIER_PAYMENT_SCHEDULE_DETAILS_REQUEST:
      return {
        ...state,
        supplierPaymentScheduleDetailsLoading: true,
        supplierPaymentScheduleDetailsError: {},
      };
    case supplierPaymentsActions.GET_SUPPLIER_PAYMENT_SCHEDULE_DETAILS_SUCCESS:
      return {
        ...state,
        supplierPaymentScheduleDetailsLoading: false,
        supplierPaymentScheduleDetailsSuccess: actions.data,
      };
    case supplierPaymentsActions.GET_SUPPLIER_PAYMENT_SCHEDULE_DETAILS_ERROR:
      return {
        ...state,
        supplierPaymentScheduleDetailsLoading: false,
        supplierPaymentScheduleDetailsError: actions.error,
      };
    case supplierPaymentsActions.GET_SUPPLIER_PAYMENT_DETAILS_REQUEST:
      return {
        ...state,
        supplierPaymentDetailsLoading: true,
        supplierPaymentDetailsError: {},
      };
    case supplierPaymentsActions.GET_SUPPLIER_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        supplierPaymentDetailsLoading: false,
        supplierPaymentDetailsSuccess: actions.data,
      };
    case supplierPaymentsActions.GET_SUPPLIER_PAYMENT_DETAILS_ERROR:
      return {
        ...state,
        supplierPaymentDetailsLoading: false,
        supplierPaymentDetailsError: actions.error,
      };
    case supplierPaymentsActions.ADD_SUPPLIER_PAYMENTS_REQUEST:
      return {
        ...state,
        addSupplierPaymentsLoading: true,
        addSupplierPaymentsError: {},
      };

    case supplierPaymentsActions.ADD_SUPPLIER_PAYMENTS_SUCCESS:
      return {
        ...state,
        addSupplierPaymentsLoading: false,
        addSupplierPaymentsSuccess: actions.data,
        // pass the data to the supplierPaymentsScheduleSuccess, payments state
        supplierPaymentsScheduleSuccess: {
          ...state.supplierPaymentsScheduleSuccess,
          supplierPaymentSchedules:
            state.supplierPaymentsScheduleSuccess.supplierPaymentSchedules.map(
              (schedule) => {
                if (
                  schedule.id === actions.data.data.supplier_payment_schedule_id
                ) {
                  // Create a new payments array with the added payment data
                  const updatedPayments = [
                    ...schedule.payments,
                    ...actions.data.data.payments,
                  ];
                  // Return the updated schedule object
                  return {
                    ...schedule,
                    payments: updatedPayments,
                  };
                }
                return schedule;
              }
            ),
        },
      };

    case supplierPaymentsActions.ADD_SUPPLIER_PAYMENTS_ERROR:
      return {
        ...state,
        addSupplierPaymentsLoading: false,
        addSupplierPaymentsError: actions.error,
      };
    case supplierPaymentsActions.DELETE_SUPPLIER_PAYMENT_REQUEST:
      return {
        ...state,
        deleteSupplierPaymentLoading: true,
        deleteSupplierPaymentError: {},
      };
    case supplierPaymentsActions.DELETE_SUPPLIER_PAYMENT_SUCCESS:
      return {
        ...state,
        deleteSupplierPaymentLoading: false,
        deleteSupplierPaymentSuccess: actions.data,
        // pass the data to the supplierPaymentsScheduleSuccess, by removing the deleted payment
        supplierPaymentsScheduleSuccess: {
          ...state.supplierPaymentsScheduleSuccess,
          supplierPaymentSchedules:
            state.supplierPaymentsScheduleSuccess.supplierPaymentSchedules.map(
              (schedule) => {
                if (schedule.id === actions.data.supplier_payment_schedule_id) {
                  const updatedPayments = [
                    ...schedule.payments.filter(
                      (payment) => payment.id !== actions.data.id
                    ),
                  ];

                  return {
                    ...schedule,
                    payments: updatedPayments,
                  };
                }
                return schedule;
              }
            ),
        },
      };
    case supplierPaymentsActions.DELETE_SUPPLIER_PAYMENT_ERROR:
      return {
        ...state,
        deleteSupplierPaymentLoading: false,
        deleteSupplierPaymentError: actions.error,
      };
    case supplierPaymentsActions.UPDATE_SUPPLIER_PAYMENT_REQUEST:
      return {
        ...state,
        updateSupplierPaymentLoading: true,
        updateSupplierPaymentError: {},
      };
    case supplierPaymentsActions.UPDATE_SUPPLIER_PAYMENT_SUCCESS:
      return {
        ...state,
        updateSupplierPaymentLoading: false,
        updateSupplierPaymentSuccess: actions.data,
        // pass the data to the supplierPaymentsScheduleSuccess, by updating the payment
        supplierPaymentsScheduleSuccess: {
          ...state.supplierPaymentsScheduleSuccess,
          supplierPaymentSchedules:
            state.supplierPaymentsScheduleSuccess.supplierPaymentSchedules.map(
              (schedule) => {
                if (
                  schedule.id === actions.data.data.supplier_payment_schedule_id
                ) {
                  const updatedPayments = [
                    ...schedule.payments.filter(
                      (payment) => payment.id !== actions.data.data.id
                    ),
                    actions.data.data,
                  ];

                  return {
                    ...schedule,
                    payments: updatedPayments,
                  };
                }
                return schedule;
              }
            ),
        },
      };
    case supplierPaymentsActions.UPDATE_SUPPLIER_PAYMENT_ERROR:
      return {
        ...state,
        updateSupplierPaymentLoading: false,
        updateSupplierPaymentError: actions.error,
      };

    case supplierPaymentsActions.CHECK_SUPPLIER_PAYMENT_SCHEDULE_REQUEST:
      return {
        ...state,
        checkSupplierPaymentScheduleLoading: true,
        checkSupplierPaymentScheduleError: {},
      };
    case supplierPaymentsActions.CHECK_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        checkSupplierPaymentScheduleLoading: false,
        checkSupplierPaymentScheduleSuccess: actions.data,

        supplierPaymentsScheduleSuccess: {
          ...state.supplierPaymentsScheduleSuccess,
          supplierPaymentSchedules:
            state.supplierPaymentsScheduleSuccess.supplierPaymentSchedules.map(
              (schedule) => {
                if (schedule.id == actions.data.id) {
                  // change the status of the schedule to Checked
                  return {
                    ...schedule,
                    status: "Checked",
                  };
                }
                return schedule;
              }
            ),
        },
      };
    case supplierPaymentsActions.CHECK_SUPPLIER_PAYMENT_SCHEDULE_ERROR:
      return {
        ...state,
        checkSupplierPaymentScheduleLoading: false,
        checkSupplierPaymentScheduleError: actions.error,
      };

    case supplierPaymentsActions.APPROVE_SUPPLIER_PAYMENT_SCHEDULE_REQUEST:
      return {
        ...state,
        approveSupplierPaymentScheduleLoading: true,
        approveSupplierPaymentScheduleError: {},
      };
    case supplierPaymentsActions.APPROVE_SUPPLIER_PAYMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        approveSupplierPaymentScheduleLoading: false,
        approveSupplierPaymentScheduleSuccess: actions.data,

        supplierPaymentsScheduleSuccess: {
          ...state.supplierPaymentsScheduleSuccess,
          supplierPaymentSchedules:
            state.supplierPaymentsScheduleSuccess.supplierPaymentSchedules.map(
              (schedule) => {
                if (schedule.id == actions.data.id) {
                  // change the status of the schedule to Approved
                  return {
                    ...schedule,
                    status: "Approved",
                  };
                }
                return schedule;
              }
            ),
        },
      };
    case supplierPaymentsActions.APPROVE_SUPPLIER_PAYMENT_SCHEDULE_ERROR:
      return {
        ...state,
        approveSupplierPaymentScheduleLoading: false,
        approveSupplierPaymentScheduleError: actions.error,
      };
    case supplierPaymentsActions.SET_UPDATE_SUPPLIER_PAYMENT_SCHEDULE_DATA:
      return {
        ...state,
        updateSupplierPaymentScheduleData: actions.data,
      };
    case supplierPaymentsActions.ADD_SUPPLIER_PAYMENT_INVOICE_REQUEST:
      return {
        ...state,
        addSupplierPaymentInvoiceLoading: true,
        addSupplierPaymentInvoiceError: {},
      };
    case supplierPaymentsActions.ADD_SUPPLIER_PAYMENT_INVOICE_SUCCESS:
      return {
        ...state,
        addSupplierPaymentInvoiceLoading: false,
        addSupplierPaymentInvoiceSuccess: actions.data,
        supplierPaymentsScheduleSuccess: {
          ...state.supplierPaymentsScheduleSuccess,
          supplierPaymentSchedules:
            state.supplierPaymentsScheduleSuccess.supplierPaymentSchedules.map(
              (schedule) => {
                if (
                  schedule.id ===
                  actions.data.invoice.supplier_payment_schedule_id
                ) {
                  // Create a new invoices array with the added invoice data
                  const updatedInvoices = [
                    ...schedule.invoices,
                    actions.data.invoice,
                  ];
                  // Return the updated schedule object
                  return {
                    ...schedule,
                    invoices: updatedInvoices,
                  };
                }
                return schedule;
              }
            ),
        },
      };

    case supplierPaymentsActions.ADD_SUPPLIER_PAYMENT_INVOICE_ERROR:
      return {
        ...state,
        addSupplierPaymentInvoiceLoading: false,
        addSupplierPaymentInvoiceError: actions.error,
      };
    case supplierPaymentsActions.DELETE_SUPPLIER_PAYMENT_INVOICE_REQUEST:
      return {
        ...state,
        deleteSupplierPaymentInvoiceLoading: true,
        deleteSupplierPaymentInvoiceError: {},
      };
    case supplierPaymentsActions.DELETE_SUPPLIER_PAYMENT_INVOICE_SUCCESS:
      return {
        ...state,
        deleteSupplierPaymentInvoiceLoading: false,
        deleteSupplierPaymentInvoiceSuccess: actions.data,
        // pass the data to the supplierPaymentsScheduleSuccess, by removing the deleted invoice
        supplierPaymentsScheduleSuccess: {
          ...state.supplierPaymentsScheduleSuccess,
          supplierPaymentSchedules:
            state.supplierPaymentsScheduleSuccess.supplierPaymentSchedules.map(
              (schedule) => {
                if (schedule.id === actions.data.supplier_payment_schedule_id) {
                  const updatedInvoices = [
                    ...schedule.invoices.filter(
                      (invoice) => invoice.id !== actions.data.id
                    ),
                  ];

                  return {
                    ...schedule,
                    invoices: updatedInvoices,
                  };
                }
                return schedule;
              }
            ),
        },
      };
    case supplierPaymentsActions.DELETE_SUPPLIER_PAYMENT_INVOICE_ERROR:
      return {
        ...state,
        deleteSupplierPaymentInvoiceLoading: false,
        deleteSupplierPaymentInvoiceError: actions.error,
      };
    case supplierPaymentsActions.UPDATE_SUPPLIER_PAYMENT_INVOICE_REQUEST:
      return {
        ...state,
        updateSupplierPaymentInvoiceLoading: true,
        updateSupplierPaymentInvoiceError: {},
      };
    case supplierPaymentsActions.UPDATE_SUPPLIER_PAYMENT_INVOICE_SUCCESS:
      return {
        ...state,
        updateSupplierPaymentInvoiceLoading: false,
        updateSupplierPaymentInvoiceSuccess: actions.data,
        supplierPaymentsScheduleSuccess: {
          ...state.supplierPaymentsScheduleSuccess,
          supplierPaymentSchedules:
            state.supplierPaymentsScheduleSuccess.supplierPaymentSchedules.map(
              (schedule) => {
                if (
                  schedule.id === actions.data.data.supplier_payment_schedule_id
                ) {
                  const updatedInvoices = [
                    ...schedule.invoices.filter(
                      (invoice) => invoice.id !== actions.data.data.id
                    ),
                    actions.data.data,
                  ];

                  return {
                    ...schedule,
                    invoices: updatedInvoices,
                  };
                }
                return schedule;
              }
            ),
        },
      };

    case supplierPaymentsActions.UPDATE_SUPPLIER_PAYMENT_INVOICE_ERROR:
      return {
        ...state,
        updateSupplierPaymentInvoiceLoading: false,
        updateSupplierPaymentInvoiceError: actions.error,
      };
    case supplierPaymentsActions.SET_UPDATE_SUPPLIER_PAYMENT_INVOICE_DATA:
      return {
        ...state,
        updateSupplierPaymentInvoiceData: actions.data,
      };
    default:
      return state;
  }
};

export default supplierPayments;
