const auth = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",

  GETTING_AUTH_USER: "GETTING_AUTH_USER",
  GETTING_AUTH_USER_SUCCESS: "GETTING_AUTH_USER_SUCCESS",
  GETTING_AUTH_USER_ERROR: "GETTING_AUTH_USER_ERROR",

  LOGOUT_USER_REQUEST: "LOGOUT_USER_REQUEST",
  LOGOUT_USER_SUCCESS: "LOGOUT_USER_SUCCESS",
  LOGOUT_USER_ERROR: "LOGOUT_USER_ERROR",

  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_ERROR: "SIGNUP_ERROR",

  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",

  CONFIRM_ACCOUNT_REQUEST: "CONFIRM_ACCOUNT_REQUEST",
  CONFIRM_ACCOUNT_SUCCESS: "CONFIRM_ACCOUNT_SUCCESS",
  CONFIRM_ACCOUNT_ERROR: "CONFIRM_ACCOUNT_ERROR",

  loginUser: (data) => ({
    type: auth.LOGIN_REQUEST,
    data,
  }),
  logoutUser: () => ({ type: auth.LOGOUT_USER_REQUEST }),
  getAuthUser: () => ({ type: auth.GETTING_AUTH_USER }),

  signupUser: (data) => ({
    type: auth.SIGNUP_REQUEST,
    data,
  }),

  forgotUserPassword: (data) => ({
    type: auth.FORGOT_PASSWORD_REQUEST,
    data,
  }),

  resetUserPassword: (data, token) => ({
    type: auth.RESET_PASSWORD_REQUEST,
    data,
    token,
  }),

  confirmUserAccount: (data, token) => ({
    type: auth.CONFIRM_ACCOUNT_REQUEST,
    data,
    token,
  }),
};

export default auth;
