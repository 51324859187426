import { classesActions } from "../../../actions";
import classesInitialState from "../../../initialState/administration/classes/classes.initialState";

const classes = (state = classesInitialState, action) => {
  switch (action.type) {
    case classesActions.GET_CLASSES_REQUEST:
      return {
        ...state,
        classesLoading: true,
      };
    case classesActions.GET_CLASSES_SUCCESS:
      return {
        ...state,
        classesSuccess: action.data,
        classesError: {},
        classesLoading: false,
      };
    case classesActions.GET_CLASSES_ERROR:
      return {
        ...state,
        classesLoading: false,
        classesSuccess: action.error,
      };
    case classesActions.ADD_CLASS_REQUEST:
      return {
        ...state,
        addClassLoading: true,
        addClassError: {},
      };
    case classesActions.ADD_CLASS_SUCCESS:
      return {
        ...state,
        addClassSuccess: action.data,
        addClassLoading: false,
      };
    case classesActions.ADD_CLASS_ERROR:
      return {
        ...state,
        addClassLoading: false,
        addClassSuccess: action.error,
      };
    case classesActions.GET_CLASSES_META_DATA_REQUEST:
      return {
        ...state,
        classesMetaDataLoading: true,
        classesMetaDataError: {},
      };
    case classesActions.GET_CLASSES_META_DATA_SUCCESS:
      return {
        ...state,
        classesMetaDataSuccess: action.data,
        classesMetaDataError: {},
        classesMetaDataLoading: false,
      };
    case classesActions.GET_CLASSES_META_DATA_ERROR:
      return {
        ...state,
        classesMetaDataLoading: false,
        classesMetaDataSuccess: action.error,
      };
    case classesActions.GET_CLASS_DETAILS_REQUEST:
      return {
        ...state,
        getClassDetailsLoading: true,
      };
    case classesActions.GET_CLASS_DETAILS_SUCCESS:
      return {
        ...state,
        getClassDetailsSuccess: action.data,
        getClassDetailsError: {},
        getClassDetailsLoading: false,
      };
    case classesActions.GET_CLASS_DETAILS_ERROR:
      return {
        ...state,
        getClassDetailsLoading: false,
        getClassDetailsSuccess: action.error,
      };
    case classesActions.UPDATE_CLASS_DETAILS_REQUEST:
      return {
        ...state,
        updateClassDetailsLoading: true,
        updateClassDetailsError: {},
      };
    case classesActions.UPDATE_CLASS_DETAILS_SUCCESS:
      return {
        ...state,
        updateClassDetailsSuccess: action.data,
        updateClassDetailsLoading: false,
      };
    case classesActions.UPDATE_CLASS_DETAILS_ERROR:
      return {
        ...state,
        updateClassDetailsLoading: false,
        updateClassDetailsSuccess: action.error,
      };
    case classesActions.DELETE_CLASS_REQUEST:
      return {
        ...state,
        deleteClassLoading: true,
        deleteClassError: {},
      };
    case classesActions.DELETE_CLASS_SUCCESS:
      return {
        ...state,
        deleteClassSuccess: action.data,
        classesSuccess: {
          classes: state.classesSuccess.classes.filter((item) => {
            return item.id !== action.data.id;
          }),
        },
        deleteClassLoading: false,
      };
    case classesActions.DELETE_CLASS_ERROR:
      return {
        ...state,
        deleteClassLoading: false,
        deleteClassSuccess: action.error,
      };
    case classesActions.CHECK_CLASS_REQUEST:
      return {
        ...state,
        checkClassLoading: true,
        checkClassError: {},
      };
    case classesActions.CHECK_CLASS_SUCCESS:
      return {
        ...state,
        checkClassSuccess: action.data,
        checkClassError: {},
        checkClassLoading: false,
      };
    case classesActions.CHECK_CLASS_ERROR:
      return {
        ...state,
        checkClassLoading: false,
        checkClassSuccess: action.error,
      };
    case classesActions.APPROVE_CLASS_REQUEST:
      return {
        ...state,
        approveClassLoading: true,
        approveClassError: {},
      };
    case classesActions.APPROVE_CLASS_SUCCESS:
      return {
        ...state,
        approveClassSuccess: action.data,
        classesSuccess: {
          classes: state.classesSuccess.classes.map((item) => {
            if (item.id === action.data.id) {
              return action.data;
            }
            return item;
          }),
        },
        approveClassLoading: false,
      };
    case classesActions.APPROVE_CLASS_ERROR:
      return {
        ...state,
        approveClassLoading: false,
        approveClassSuccess: action.error,
      };
    case classesActions.SET_UPDATE_CLASS_DETAILS_DATA:
      return {
        ...state,
        updateClassDetailsData: action.data,
      };
    case classesActions.ADD_STREAMS_REQUEST:
      return {
        ...state,
        addStreamsLoading: true,
        addStreamsError: {},
      };
    case classesActions.ADD_STREAMS_SUCCESS:
      return {
        ...state,
        addStreamsSuccess: action.data,
        addStreamsLoading: false,
      };
    case classesActions.ADD_STREAMS_ERROR:
      return {
        ...state,
        addStreamsError: action.error,
        addStreamsLoading: false,
      };
    case classesActions.UPDATE_STREAMS_DATA:
      return {
        ...state,
        updateStreamsData: action.data,
      };
    case classesActions.UPDATE_STREAMS_REQUEST:
      return {
        ...state,
        updateStreamsLoading: true,
        updateStreamsError: {},
      };
    case classesActions.UPDATE_STREAMS_SUCCESS:
      return {
        ...state,
        updateStreamsSuccess: {},
        updateStreamsLoading: false,
      };
    case classesActions.UPDATE_STREAMS_ERROR:
      return {
        ...state,
        updateStreamsError: action.error,
        updateStreamsLoading: false,
      };
    case classesActions.GET_STREAMS_DETAILS_REQUEST:
      return {
        ...state,
        streamDetailsLoading: true,
        streamDetailsError: {},
      };
    case classesActions.GET_STREAMS_DETAILS_SUCCESS:
      return {
        ...state,
        streamDetailsSuccess: action.data,
        streamDetailsLoading: false,
      };
    case classesActions.GET_STREAMS_DETAILS_ERROR:
      return {
        ...state,
        streamDetailsError: action.error,
        streamDetailsLoading: false,
      };
    case classesActions.DELETE_STREAMS_REQUEST:
      return {
        ...state,
        deleteStreamsLoading: true,
        deleteStreamsError: {},
      };
    case classesActions.DELETE_STREAMS_SUCCESS:
      return {
        ...state,
        deleteStreamsSuccess: action.data,
        deleteStreamsLoading: false,
      };
    case classesActions.DELETE_STREAMS_ERROR:
      return {
        ...state,
        deleteStreamsError: action.error,
        deleteClassLoading: false,
      };
    default:
      return state;
  }
};

export default classes;
