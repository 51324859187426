import { feesCollectionsActions } from "../../../actions";
import initialState from "../../../initialState";

const feesCollections = (state = initialState.feesCollections, actions) => {
  switch (actions.type) {
    case feesCollectionsActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case feesCollectionsActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: actions.data,
        metaDataLoading: false,
      };
    case feesCollectionsActions.GET_FEES_COLLECTIONS_REQUEST:
      return {
        ...state,
        feesCollectionsLoading: true,
        feesCollectionsError: {},
        feesCollectionsSuccess: {},
      };
    case feesCollectionsActions.GET_FEES_COLLECTIONS_SUCCESS:
      return {
        ...state,
        feesCollectionsSuccess: actions.data,
        feesCollectionsLoading: false,
      };
    case feesCollectionsActions.GET_FEES_COLLECTIONS_ERROR:
      return {
        ...state,
        feesCollectionsLoading: false,
        feesCollectionsError: actions.error,
      };
    case feesCollectionsActions.GET_FEES_DEFAULTERS_REQUEST:
      return {
        ...state,
        feesDefaultersLoading: true,
        feesDefaultersError: {},
      };
    case feesCollectionsActions.GET_FEES_DEFAULTERS_SUCCESS:
      return {
        ...state,
        feesDefaultersSuccess: actions.data,
        feesDefaultersLoading: false,
      };
    case feesCollectionsActions.GET_FEES_DEFAULTERS_ERROR:
      return {
        ...state,
        feesDefaultersLoading: false,
        feesDefaultersError: actions.error,
      };
    case feesCollectionsActions.SET_FEES_DEFAULTERS_DATA:
      return {
        ...state,
        feesDefaultersData: actions.data,
      };
    case feesCollectionsActions.SET_FEES_DEFAULTERS_MESSAGE_CLASS:
      return {
        ...state,
        feesDefaultersMessageClass: actions.data,
      };
    case feesCollectionsActions.GET_INSTALLMENT_PAYMENT_REPORT_REQUEST:
      return {
        ...state,
        installmentPaymentReportLoading: true,
        installmentReportContext: actions.data,
        installmentPaymentReportError: {},
      };
    case feesCollectionsActions.GET_INSTALLMENT_PAYMENT_REPORT_SUCCESS:
      return {
        ...state,
        installmentPaymentReport: actions.data,
        installmentPaymentReportLoading: false,
      };
    case feesCollectionsActions.GET_INSTALLMENT_PAYMENT_REPORT_ERROR:
      return {
        ...state,
        installmentPaymentReportError: actions.error,
        installmentPaymentReportLoading: false,
      };
    case feesCollectionsActions.DOWNLOAD_INSTALLMENT_PAYMENT_REPORT_EXCEL_REQUEST:
      return {
        ...state,
        downloadInstallmentPaymentReportExcelLoading: true,
        downloadInstallmentPaymentReportExcelError: {},
      };
    case feesCollectionsActions.DOWNLOAD_INSTALLMENT_PAYMENT_REPORT_EXCEL_SUCCESS:
      return {
        ...state,
        downloadInstallmentPaymentReportExcelLoading: false,
        downloadInstallmentPaymentReportExcel: actions.data,
      };
    case feesCollectionsActions.DOWNLOAD_INSTALLMENT_PAYMENT_REPORT_EXCEL_ERROR:
      return {
        ...state,
        downloadInstallmentPaymentReportExcelError: actions.error,
        downloadInstallmentPaymentReportExcelLoading: false,
      };
    default:
      return state;
  }
};

export default feesCollections;
