const advanceAccountabilityForms = {
  GET_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST:
    "GET_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST",
  GET_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
    "GET_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS",
  GET_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
    "GET_ADVANCE_ACCOUNTABILITY_FORMS_ERROR",

  SEARCH_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST:
    "SEARCH_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST",
  SEARCH_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
    "SEARCH_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS",
  SEARCH_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
    "SEARCH_ADVANCE_ACCOUNTABILITY_FORMS_ERROR",

  ADD_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST:
    "ADD_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST",
  ADD_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
    "ADD_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS",
  ADD_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
    "ADD_ADVANCE_ACCOUNTABILITY_FORMS_ERROR",

  UPDATE_ADVANCE_ACCOUNTABILIT_FORMS_DATA:
    "UPDATE_ADVANCE_ACCOUNTABILIT_FORMS_DATA",
  UPDATE_ADVANCE_ACCOUNTABILIT_FORMS_REQUEST:
    "UPDATE_ADVANCE_ACCOUNTABILIT_FORMS_REQUEST",
  UPDATE_ADVANCE_ACCOUNTABILIT_FORMS_SUCCESS:
    "UPDATE_ADVANCE_ACCOUNTABILIT_FORMS_SUCCESS",
  UPDATE_ADVANCE_ACCOUNTABILIT_FORMS_ERROR:
    "UPDATE_ADVANCE_ACCOUNTABILIT_FORMS_ERROR",

  DELETE_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST:
    "DELETE_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST",
  DELETE_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
    "DELETE_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS",
  DELETE_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
    "DELETE_ADVANCE_ACCOUNTABILITY_FORMS_ERROR",

  GET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_REQUEST:
    "GET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_REQUEST",
  GET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_SUCCESS:
    "GET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_SUCCESS",
  GET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_ERROR:
    "GET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_ERROR",

  SUBMIT_ADVANCE_ACCOUNTABILITY_FORM_REQUEST:
    "SUBMIT_ADVANCE_ACCOUNTABILITY_FORM_REQUEST",
  SUBMIT_ADVANCE_ACCOUNTABILITY_FORM_SUCCESS:
    "SUBMIT_ADVANCE_ACCOUNTABILITY_FORM_SUCCESS",
  SUBMIT_ADVANCE_ACCOUNTABILITY_FORM_ERROR:
    "SUBMIT_ADVANCE_ACCOUNTABILITY_FORM_ERROR",

  CHECK_ADVANCE_ACCOUNTABILITY_FORM_REQUEST:
    "CHECK_ADVANCE_ACCOUNTABILITY_FORM_REQUEST",
  CHECK_ADVANCE_ACCOUNTABILITY_FORM_SUCCESS:
    "CHECK_ADVANCE_ACCOUNTABILITY_FORM_SUCCESS",
  CHECK_ADVANCE_ACCOUNTABILITY_FORM_ERROR:
    "CHECK_ADVANCE_ACCOUNTABILITY_FORM_ERROR",

  APPROVE_ADVANCE_ACCOUNTABILITY_FORM_REQUEST:
    "APPROVE_ADVANCE_ACCOUNTABILITY_FORM_REQUEST",
  APPROVE_ADVANCE_ACCOUNTABILITY_FORM_SUCCESS:
    "APPROVE_ADVANCE_ACCOUNTABILITY_FORM_SUCCESS",
  APPROVE_ADVANCE_ACCOUNTABILITY_FORM_ERROR:
    "APPROVE_ADVANCE_ACCOUNTABILITY_FORM_ERROR",

  GET_ACTUAL_EXPENDITURE_DETAILS_REQUEST:
    "GET_ACTUAL_EXPENDITURE_DETAILS_REQUEST",
  GET_ACTUAL_EXPENDITURE_DETAILS_SUCCESS:
    "GET_ACTUAL_EXPENDITURE_DETAILS_SUCCESS",
  GET_ACTUAL_EXPENDITURE_DETAILS_ERROR: "GET_ACTUAL_EXPENDITURE_DETAILS_ERROR",

  ADD_ACTUAL_EXPENDITURE_REQUEST: "ADD_ACTUAL_EXPENDITURE_REQUEST",
  ADD_ACTUAL_EXPENDITURE_SUCCESS: "ADD_ACTUAL_EXPENDITURE_SUCCESS",
  ADD_ACTUAL_EXPENDITURE_ERROR: "ADD_ACTUAL_EXPENDITURE_ERROR",

  UPDATE_ACTUAL_EXPENDITURE_DATA: "UPDATE_ACTUAL_EXPENDITURE_DATA",
  UPDATE_ACTUAL_EXPENDITURE_REQUEST: "UPDATE_ACTUAL_EXPENDITURE_REQUEST",
  UPDATE_ACTUAL_EXPENDITURE_SUCCESS: "UPDATE_ACTUAL_EXPENDITURE_SUCCESS",
  UPDATE_ACTUAL_EXPENDITURE_ERROR: "UPDATE_ACTUAL_EXPENDITURE_ERROR",

  DELETE_ACTUAL_EXPENDITURE_REQUEST: "DELETE_ACTUAL_EXPENDITURE_REQUEST",
  DELETE_ACTUAL_EXPENDITURE_SUCCESS: "DELETE_ACTUAL_EXPENDITURE_SUCCESS",
  DELETE_ACTUAL_EXPENDITURE_ERROR: "DELETE_ACTUAL_EXPENDITURE_ERROR",

  GET_META_DATA_REQUEST: "GET_ADVANCE_ACCOUNTABILITY_FORM_META_DATA_REQUEST",
  GET_META_DATA_SUCCESS: "GET_ADVANCE_ACCOUNTABILITY_FORM_META_DATA_SUCCESS",

  UPLOAD_ADVANCE_ACCOUNTABILITY_DOCUMENTS_REQUEST:
    "UPLOAD_ADVANCE_ACCOUNTABILITY_DOCUMENTS_REQUEST",
  UPLOAD_ADVANCE_ACCOUNTABILITY_DOCUMENTS_SUCCESS:
    "UPLOAD_ADVANCE_ACCOUNTABILITY_DOCUMENTS_SUCCESS",
  UPLOAD_ADVANCE_ACCOUNTABILITY_DOCUMENTS_ERROR:
    "UPLOAD_ADVANCE_ACCOUNTABILITY_DOCUMENTS_ERROR",

  SORT_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST:
    "SORT_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST",
  SORT_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
    "SORT_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS",
  SORT_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
    "SORT_ADVANCE_ACCOUNTABILITY_FORMS_ERROR",

  DOWNLOAD_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST:
    "DOWNLOAD_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST",
  DOWNLOAD_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS:
    "DOWNLOAD_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS",
  DOWNLOAD_ADVANCE_ACCOUNTABILITY_FORMS_ERROR:
    "DOWNLOAD_ADVANCE_ACCOUNTABILITY_FORMS_ERROR",

  SET_SORT_QUERY: "SET_SORT_QUERY",

  getMetaData: (data) => ({
    type: advanceAccountabilityForms.GET_META_DATA_REQUEST,
    data,
  }),
  search: () => ({
    type: advanceAccountabilityForms.SEARCH_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST,
  }),
  getAdvanceAccountabilityForms: () => ({
    type: advanceAccountabilityForms.GET_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST,
  }),
  getDetails: (id) => ({
    type: advanceAccountabilityForms.GET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_REQUEST,
    id,
  }),
  addForm: (data) => ({
    type: advanceAccountabilityForms.ADD_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST,
    data,
  }),
  setUpdateData: (data) => ({
    type: advanceAccountabilityForms.UPDATE_ADVANCE_ACCOUNTABILIT_FORMS_DATA,
    data,
  }),
  updateForm: (data, id, newArray) => ({
    type: advanceAccountabilityForms.UPDATE_ADVANCE_ACCOUNTABILIT_FORMS_REQUEST,
    data,
    id,
    advanceAccountabilityForms: newArray,
  }),
  deleteForm: (id, newArray) => ({
    type: advanceAccountabilityForms.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST,
    id,
    advanceAccountabilityForms: newArray,
  }),
  submitForm: (id) => ({
    type: advanceAccountabilityForms.SUBMIT_ADVANCE_ACCOUNTABILITY_FORM_REQUEST,
    id,
  }),
  checkForm: (id) => ({
    type: advanceAccountabilityForms.CHECK_ADVANCE_ACCOUNTABILITY_FORM_REQUEST,
    id,
  }),
  approveForm: (id) => ({
    type: advanceAccountabilityForms.APPROVE_ADVANCE_ACCOUNTABILITY_FORM_REQUEST,
    id,
  }),
  addExpenditure: (data) => ({
    type: advanceAccountabilityForms.ADD_ACTUAL_EXPENDITURE_REQUEST,
    data,
  }),
  updateExpenditure: (data, id) => ({
    type: advanceAccountabilityForms.UPDATE_ACTUAL_EXPENDITURE_REQUEST,
    data,
    id,
  }),
  setUpdateActualExpenditureData: (data) => ({
    type: advanceAccountabilityForms.UPDATE_ACTUAL_EXPENDITURE_DATA,
    data,
  }),
  getExpenditureDetails: (id) => ({
    type: advanceAccountabilityForms.GET_ACTUAL_EXPENDITURE_DETAILS_REQUEST,
    id,
  }),
  deleteActualExpenditure: (id, data) => ({
    type: advanceAccountabilityForms.DELETE_ACTUAL_EXPENDITURE_REQUEST,
    id,
    data,
  }),
  uploadAdvanceAccountabilityDocuments: (id, data) => ({
    type: advanceAccountabilityForms.UPLOAD_ADVANCE_ACCOUNTABILITY_DOCUMENTS_REQUEST,
    id,
    data,
  }),
  sortAdvanceAccountabilityForms: (data) => ({
    type: advanceAccountabilityForms.SORT_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST,
    data,
  }),
  downloadAdvanceAccountabilityForms: (data) => ({
    type: advanceAccountabilityForms.DOWNLOAD_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST,
    data,
  }),
  setSortQuery: (data) => ({
    type: advanceAccountabilityForms.SET_SORT_QUERY,
    data,
  }),
};

export default advanceAccountabilityForms;
