const subjectsInitialState = {
  subjectsLoading: false,
  subjectsSuccess: {},
  subjectsError: {},

  getSubjectsMetaDataLoading: false,
  getSubjectsMetaDataSuccess: {},
  getSubjectsMetaDataError: {},

  addSubjectLoading: false,
  addSubjectSuccess: {},
  addSubjectError: {},

  getSubjectDetailsLoading: false,
  getSubjectDetailsSuccess: {},
  getSubjectDetailsError: {},

  updateSubjectDetailsLoading: false,
  updateSubjectDetailsSuccess: {},
  updateSubjectDetailsError: {},
  updateSubjectDetailsData: {},

  deleteSubjectLoading: false,
  deleteSubjectSuccess: {},
  deleteSubjectError: {},

  checkSubjectLoading: false,
  checkSubjectSuccess: {},
  checkSubjectError: {},

  approveSubjectLoading: false,
  approveSubjectSuccess: {},
  approveSubjectError: {},

  creatingSubjects: false,
  createSubjectsError: {},
};

export default subjectsInitialState;
