const subscriptionsInitialState = {
  subscriptions: {
    subscriptionPlansLoading: false,
    subscriptionsPlansSuccess: {},
    subscriptionsPlansError: {},

    addSubscriptionLoading: false,
    addSubscriptionSuccess: {},
    addSubscriptionError: {},

    addSubscriptionsPaymentsLoading: false,
    addSubscriptionsPaymentsSuccess: {},
    addSubscriptionsPaymentsError: {},

    subscriptionsLoading: false,
    subscriptionsSuccess: {},
    subscriptionsError: {},

    cancelPendingSubscriptionLoading: false,
    cancelPendingSubscriptionSuccess: {},
    cancelPendingSubscriptionError: {},

    subscriptionsPaymentDetails: {},

    latestSubscriptionLoading: false,
    latestSubscriptionSuccess: {},
    latestSubscriptionError: {},
  },
};

export default subscriptionsInitialState;
