const ledgers = {
  GET_LEDGERS_REQUEST: "GET_LEDGERS_REQUEST",
  GET_LEDGERS_SUCCESS: "GET_LEDGERS_SUCCESS",
  GET_LEDGERS_ERROR: "GET_LEDGERS_ERROR",

  GET_META_DATA_REQUEST: "GET_LEDGERS_META_DATA_REQUEST",
  GET_META_DATA_SUCCESS: "GET_LEDGERS_META_DATA_SUCCESS",

  ADD_LEDGERS_REQUEST: "ADD_LEDGERS_REQUEST",
  ADD_LEDGERS_SUCCESS: "ADD_LEDGERS_SUCCESS",
  ADD_LEDGERS_ERROR: "ADD_LEDGERS_ERROR",

  UPDATE_LEDGERS_REQUEST: "UPDATE_LEDGERS_REQUEST",
  UPDATE_LEDGERS_SUCCESS: "UPDATE_LEDGERS_SUCCESS",
  UPDATE_LEDGERS_ERROR: "UPDATE_LEDGERS_ERROR",
  UPDATE_LEDGER_DATA: "UPDATE_LEDGER_DATA",

  DELETE_LEDGERS_REQUEST: "DELETE_LEDGERS_REQUEST",
  DELETE_LEDGERS_SUCCESS: "DELETE_LEDGERS_SUCCESS",
  DELETE_LEDGERS_ERROR: "DELETE_LEDGERS_ERROR",

  GET_LEDGER_DETAILS_REQUEST: "GET_LEDGER_DETAILS_REQUEST",
  GET_LEDGER_DETAILS_SUCCESS: "GET_LEDGER_DETAILS_SUCCESS",
  GET_LEDGER_DETAILS_ERROR: "GET_LEDGER_DETAILS_ERROR",

  GET_LEDGER_ACCOUNT_DETAILS_REQUEST: "GET_LEDGER_ACCOUNT_DETAILS_REQUEST",
  GET_LEDGER_ACCOUNT_DETAILS_SUCCESS: "GET_LEDGER_ACCOUNT_DETAILS_SUCCESS",
  GET_LEDGER_ACCOUNT_DETAILS_ERROR: "GET_LEDGER_ACCOUNT_DETAILS_ERROR",

  DELETE_LEDGER_ACCOUNT_REQUEST: "DELETE_LEDGER_ACCOUNT_REQUEST",
  DELETE_LEDGER_ACCOUNT_SUCCESS: "DELETE_LEDGER_ACCOUNT_SUCCESS",
  DELETE_LEDGER_ACCOUNT_ERROR: "DELETE_LEDGER_ACCOUNT_ERROR",

  CHECK_LEDGER_REQUEST: "CHECK_LEDGER_REQUEST",
  CHECK_LEDGER_SUCCESS: "CHECK_LEDGER_SUCCESS",
  CHECK_LEDGER_ERROR: "CHECK_LEDGER_ERROR",

  APPROVE_LEDGER_REQUEST: "APPROVE_LEDGER_REQUEST",
  APPROVE_LEDGER_SUCCESS: "APPROVE_LEDGER_SUCCESS",
  APPROVE_LEDGER_ERROR: "APPROVE_LEDGER_ERROR",

  getMetaData: () => ({
    type: ledgers.GET_META_DATA_REQUEST,
  }),
  getLedgers: () => ({
    type: ledgers.GET_LEDGERS_REQUEST,
  }),
  addLedgers: (data) => ({
    type: ledgers.ADD_LEDGERS_REQUEST,
    data,
  }),
  updateLedgers: (data, id) => ({
    type: ledgers.UPDATE_LEDGERS_REQUEST,
    data,
    id,
  }),
  updateLedgerData: (data) => ({
    type: ledgers.UPDATE_LEDGER_DATA,
    data,
  }),
  deleteLedgers: (id) => ({
    type: ledgers.DELETE_LEDGERS_REQUEST,
    id,
  }),
  getLedgerDetails: (id) => ({
    type: ledgers.GET_LEDGER_DETAILS_REQUEST,
    id,
  }),
  getLedgerAccountDetails: (id) => ({
    type: ledgers.GET_LEDGER_ACCOUNT_DETAILS_REQUEST,
    id,
  }),
  deleteLedgerAccount: (data) => ({
    type: ledgers.DELETE_LEDGER_ACCOUNT_REQUEST,
    data,
  }),
  checkLedger: (id) => ({
    type: ledgers.CHECK_LEDGER_REQUEST,
    id,
  }),
  approveLedger: (id) => ({
    type: ledgers.APPROVE_LEDGER_REQUEST,
    id,
  }),
};

export default ledgers;
