const roles = {
  GET_ROLES_REQUEST: "GET_ROLES_REQUEST",
  GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
  GET_ROLES_ERROR: "GET_ROLES_ERROR",

  ADD_ROLES_REQUEST: "ADD_ROLES_RQUEST",
  ADD_ROLES_SUCCESS: "ADD_ROLES_SUCCESS",
  ADD_ROLES_ERROR: "ADD_ROLES_ERROR",

  DELETE_ROLES_REQUEST: "DELETE_ROLES_REQUEST",
  DELETE_ROLES_SUCCESS: "DELETE_ROLES_SUCCESS",
  DELETE_ROLES_ERROR: "DELETE_ROLES_ERROR",

  UPDATE_ROLE_REQUEST: "UPDATE_ROLE_REQUEST",
  UPDATE_ROLE_ERROR: "UPDATE_ROLE_ERROR",
  UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",
  SET_UPDATE_ROLE_DATA: "SET_UPDATE_ROLE_DATA",

  GET_ROLES_DETAILS_REQUEST: "GET_ROLES_DETAILS_REQUEST",
  GET_ROLES_DETAILS_SUCCESS: "GET_ROLES_DETAILS_SUCCESS",
  GET_ROLES_DETAILS_ERROR: "GET_ROLES_DETAILS_ERROR",

  getRoles: (data) => ({
    type: roles.GET_ROLES_REQUEST,
    data,
  }),
  addRole: (data) => ({
    type: roles.ADD_ROLES_REQUEST,
    data,
  }),
  deleteRole: (data) => ({
    type: roles.DELETE_ROLES_REQUEST,
    data,
  }),
  updateRole: (data, rolesArray, id) => ({
    type: roles.UPDATE_ROLE_REQUEST,
    data,
    rolesArray,
    id,
  }),
  setUpdateRoleData: (data) => ({
    data,
    type: roles.SET_UPDATE_ROLE_DATA,
  }),
  getRoleDetails: (data) => ({
    type: roles.GET_ROLES_DETAILS_REQUEST,
    data,
  }),
};

export default roles;
