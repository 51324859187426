import { assetsActions }from "../../../actions";
import initialState from "../../../initialState/";

const assets = (state = initialState.assets, actions) => {
  switch (actions.type) {
    case assetsActions.GET_ASSETS_REQUEST:
      return {
        ...state,
        assetsLoading: true,
      };
    case assetsActions.GET_ASSETS_SUCCESS:
      return {
        ...state,
        assetsLoading: false,
        assetsSuccess: actions.data,
        assetsError: {},
      };
    case assetsActions.GET_ASSETS_ERROR:
      return {
        ...state,
        assetsLoading: false,
        assetsError: actions.error,
      };
    case assetsActions.GET_ASSETS_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      }
    case assetsActions.GET_ASSETS_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataLoading: false,
        metaDataSuccess: actions.data,
      }
    case assetsActions.ADD_ASSET_REQUEST:
      return {
        ...state,
        addAssetLoading: true,
        addAssetSuccess: {},
        addAssetError: {},
      };
    case assetsActions.ADD_ASSET_SUCCESS:
      return {
        ...state,
        addAssetLoading: false,
        addAssetSuccess: actions.data,
        addAssetError: {},
      };
    case assetsActions.ADD_ASSET_ERROR:
      return {
        ...state,
        addAssetError: actions.error,
        addAssetLoading: false,
      };
    case assetsActions.SET_ADD_ASSET_DATA:
      return {
        ...state,
        addAssetData: actions.data,
      };
    case assetsActions.DELETE_ASSET_REQUEST:
      return {
        ...state,
        deleteAssetLoading: true,
        deleteAssetSuccess: {},
        deleteAssetError: {},
      };
    case assetsActions.DELETE_ASSET_SUCCESS:
      return {
        ...state,
        deleteAssetSuccess: actions.data,
        deleteAssetLoading: false,
      };
    case assetsActions.DELETE_ASSET_ERROR:
      return {
        ...state,
        deleteAssetError: actions.error,
        deleteAssetLoading: false,
      };
    case assetsActions.SET_UPDATE_ASSET_UPDATE_DATA:
      return {
        ...state,
        updateAssetData: actions.data,
      };
    case assetsActions.UPDATE_ASSET_REQUEST:
      return {
        ...state,
        updateAssetLoading: true,
      };
    case assetsActions.UPDATE_ASSET_SUCCESS:
      return {
        ...state,
        updateAssetSuccess: actions.data,
        updateAssetLoading: false,
        updateAssetError: {},
      };
    case assetsActions.UPDATE_ASSET_ERROR:
      return {
        ...state,
        updateAssetError: actions.error,
        updateAssetLoading: false,
      };
    case assetsActions.GET_ASSET_DETAILS_REQUEST:
      return {
        ...state,
        assetDetailsLoading: true,
      };
    case assetsActions.GET_ASSET_DETAILS_SUCCESS:
      return {
        ...state,
        assetDetailsSuccess: actions.data,
        assetDetailsError: {},
        assetDetailsLoading: false,
      };
    case assetsActions.GET_ASSET_DETAILS_ERROR:
      return {
        ...state,
        assetDetailsLoading: false,
        assetDetailsError: actions.error,
      };
    case assetsActions.CHECK_ASSET_REQUEST:
      return {
        ...state,
        checkAssetLoading: true,
        checkAssetError: {},
        checkAssetSuccess: {},
      };
    case assetsActions.CHECK_ASSET_SUCCESS:
      return {
        ...state,
        checkAssetLoading: false,
        checkAssetSuccess: actions.data,
      };
    case assetsActions.CHECK_ASSET_ERROR:
      return {
        ...state,
        checkAssetError: actions.error,
        checkAssetLoading: false,
      };
    case assetsActions.APPROVE_ASSET_REQUEST:
      return {
        ...state,
        approveAssetLoading: true,
        approveAssetError: {},
        approveAssetSuccess: {},
      };
    case assetsActions.APPROVE_ASSET_SUCCESS:
      return {
        ...state,
        approveAssetLoading: false,
        approveAssetSuccess: actions.data,
      };
    case assetsActions.APPROVE_ASSET_ERROR:
      return {
        ...state,
        approveAssetLoading: false,
        approveAssetError: actions.error,
      };
    default:
      return state;
  }
};

export default assets;
