const incomeReceipts = {
  GET_INCOME_RECEIPTS_REQUEST: "GET_INCOME_RECEIPTS_REQUEST",
  GET_INCOME_RECEIPTS_SUCCESS: "GET_INCOME_RECEIPTS_SUCCESS",
  GET_INCOME_RECEIPTS_ERROR: "GET_INCOME_RECEIPTS_ERROR",

  ADD_INCOME_RECEIPTS_REQUEST: "ADD_INCOME_RECEIPTS_REQUEST",
  ADD_INCOME_RECEIPTS_SUCCESS: "ADD_INCOME_RECEIPTS_SUCCESS",
  ADD_INCOME_RECEIPTS_ERROR: "ADD_INCOME_RECEIPTS_ERROR",

  GET_META_DATA_REQUEST: "GET_INCOME_RECEIPTS_META_DATA_REQUEST",
  GET_META_DATA_SUCCESS: "GET_INCOME_RECEIPTS_META_DATA_SUCCESS",

  SEARCH_INCOME_RECEIPT_REQUEST: "SEARCH_INCOME_RECEIPT_REQUEST",
  SEARCH_INCOME_RECEIPT_ERROR: "SEARCH_INCOME_RECEIPT_ERROR",
  SEARCH_INCOME_RECEIPT_SUCCESS: "SEARCH_INCOME_RECEIPT_SUCCESS",

  UPDATE_INCOME_RECEIPT_REQUEST: "UPDATE_INCOME_RECEIPT_REQUEST",
  UPDATE_INCOME_RECEIPT_SUCCESS: "UPDATE_INCOME_RECEIPT_SUCCESS",
  UPDATE_INCOME_RECEIPT_ERROR: "UPDATE_INCOME_RECEIPT_ERROR",
  SET_UPDATE_INCOME_RECEIPT_DATA: "SET_UPDATE_INCOME_RECEIPT_DATA",

  DELETE_INCOME_RECEIPT_STREAM_REQUEST: "DELETE_INCOME_RECEIPT_STREAM_REQUEST",
  DELETE_INCOME_RECEIPT_STREAM_SUCCESS: "DELETE_INCOME_RECEIPT_STREAM_SUCCESS",
  DELETE_INCOME_RECEIPT_STREAM_ERROR: "DELETE_INCOME_RECEIPT_STREAM_ERROR",

  DELETE_INCOME_RECEIPT_REQUEST: "DELETE_INCOME_RECEIPT_REQUEST",
  DELETE_INCOME_RECEIPT_SUCCESS: "DELETE_INCOME_RECEIPT_SUCCESS",
  DELETE_INCOME_RECEIPT_ERROR: "DELETE_INCOME_RECEIPT_ERROR",

  GET_INCOME_RECEIPT_DETAILS_REQUEST: "GET_INCOME_RECEIPT_DETAILS_REQUEST",
  GET_INCOME_RECEIPT_DETAILS_SUCCESS: "GET_INCOME_RECEIPT_DETAILS_SUCCESS",
  GET_INCOME_RECEIPT_DETAILS_ERROR: "GET_INCOME_RECEIPT_DETAILS_ERROR",

  GET_INCOME_RECEIPT_STREAMS_REQUEST: "GET_INCOME_RECEIPT_STREAMS_REQUEST",
  GET_INCOME_RECEIPT_STREAMS_SUCCESS: "GET_INCOME_RECEIPT_STREAMS_SUCCESS",
  GET_INCOME_RECEIPT_STREAMS_ERROR: "GET_INCOME_RECEIPT_STREAMS_ERROR",

  ADD_INCOME_RECEIPT_STREAM_REQUEST: "ADD_INCOME_RECEIPT_STREAM_REQUEST",
  ADD_INCOME_RECEIPT_STREAM_ERROR: "ADD_INCOME_RECEIPT_STREAM_ERROR",
  ADD_INCOME_RECEIPT_STREAM_SUCCESS: "ADD_INCOME_RECEIPT_STREAM_SUCCESS",

  UPDATE_INCOME_RECEIPT_STREAM_REQUEST: "UPDATE_INCOME_RECEIPT_STREAM_REQUEST",
  UPDATE_INCOME_RECEIPT_STREAM_SUCCESS: "UPDATE_INCOME_RECEIPT_STREAM_SUCCESS",
  UPDATE_INCOME_RECEIPT_STREAM_ERROR: "UPDATE_INCOME_RECEIPT_STREAM_ERROR",
  SET_UPDATE_INCOME_RECEIPT_STREAM_DATA:
    "SET_UPDATE_INCOME_RECEIPT_STREAM_DATA",

  GET_INCOME_RECEIPT_STREAM_DETAILS_REQUEST:
    "GET_INCOME_RECEIPT_STREAM_DETAILS_REQUEST",
  GET_INCOME_RECEIPT_STREAM_DETAILS_SUCCESS:
    "GET_INCOME_RECEIPT_STREAM_DETAILS_SUCCESS",
  GET_INCOME_RECEIPT_STREAM_DETAILS_ERROR:
    "GET_INCOME_RECEIPT_STREAM_DETAILS_ERROR",

  ADD_INCOME_RECEIPT_DOCUMENT_REQUEST: "ADD_INCOME_RECEIPT_DOCUMENT_REQUEST",
  ADD_INCOME_RECEIPT_DOCUMENT_SUCCESS: "ADD_INCOME_RECEIPT_DOCUMENT_SUCCESS",
  ADD_INCOME_RECEIPT_DOCUMENT_ERROR: "ADD_INCOME_RECEIPT_DOCUMENT_ERROR",

  DELETE_INCOME_RECEIPT_DOCUMENT_REQUEST:
    "DELETE_INCOME_RECEIPT_DOCUMENT_REQUEST",
  DELETE_INCOME_RECEIPT_DOCUMENT_SUCCESS:
    "DELETE_INCOME_RECEIPT_DOCUMENT_SUCCESS",
  DELETE_INCOME_RECEIPT_DOCUMENT_ERROR: "DELETE_INCOME_RECEIPT_DOCUMENT_ERROR",

  DOWNLOAD_EXCEL_REQUEST: "DOWNLOAD_INCOME_RECEIPT_EXCEL_REQUEST",
  DOWNLOAD_EXCEL_SUCCESS: "DOWNLOAD_INCOME_RECEIPT_EXCEL_SUCCESS",
  DOWNLOAD_EXCEL_ERROR: "DOWNLOAD_INCOME_RECEIPT_EXCEL_ERROR",

  UPLOAD_EXCEL_REQUEST: "UPLOAD_INCOME_RECEIPT_EXCEL_REQUEST",
  UPLOAD_EXCEL_SUCCESS: "UPLOAD_INCOME_RECEIPT_EXCEL_SUCCESS",
  UPLOAD_EXCEL_ERROR: "UPLOAD_INCOME_RECEIPT_EXCEL_ERROR",

  SORT_INCOME_RECEIPTS_REQUEST: "SORT_INCOME_RECEIPTS_REQUEST",
  SORT_INCOME_RECEIPTS_SUCCESS: "SORT_INCOME_RECEIPTS_SUCCESS",
  SORT_INCOME_RECEIPTS_ERROR: "SORT_INCOME_RECEIPTS_ERROR",

  DOWNLOAD_INCOME_RECEIPTS_REQUEST: "DOWNLOAD_INCOME_RECEIPTS_REQUEST",
  DOWNLOAD_INCOME_RECEIPTS_SUCCESS: "DOWNLOAD_INCOME_RECEIPTS_SUCCESS",
  DOWNLOAD_INCOME_RECEIPTS_ERROR: "DOWNLOAD_INCOME_RECEIPTS_ERROR",

  SYNC_SCHOOL_PAY_TRANSACTIONS_REQUEST: "SYNC_SCHOOL_PAY_TRANSACTIONS_REQUEST",
  SYNC_SCHOOL_PAY_TRANSACTIONS_SUCCESS: "SYNC_SCHOOL_PAY_TRANSACTIONS_SUCCESS",
  SYNC_SCHOOL_PAY_TRANSACTIONS_ERROR: "SYNC_SCHOOL_PAY_TRANSACTIONS_ERROR",

  SET_DOWNLOAD_QUERY_DATA: "SET_DOWNLOAD_QUERY_DATA",

  IMPORT_SCHOOL_PAY_EXCEL_REQUEST: "IMPORT_SCHOOL_PAY_EXCEL_REQUEST",
  IMPORT_SCHOOL_PAY_EXCEL_SUCCESS: "IMPORT_SCHOOL_PAY_EXCEL_SUCCESS",
  IMPORT_SCHOOL_PAY_EXCEL_ERROR: "IMPORT_SCHOOL_PAY_EXCEL_ERROR",

  uploadExcel: (data) => ({
    type: incomeReceipts.UPLOAD_EXCEL_REQUEST,
    data,
  }),
  importSchoolPayExcel: (data) => ({
    type: incomeReceipts.IMPORT_SCHOOL_PAY_EXCEL_REQUEST,
    data,
  }),
  syncSchoolPayTransactions: (data) => ({
    type: incomeReceipts.SYNC_SCHOOL_PAY_TRANSACTIONS_REQUEST,
    data,
  }),
  downloadExcel: (data) => ({
    type: incomeReceipts.DOWNLOAD_EXCEL_REQUEST,
    data,
  }),
  getIncomeReceipts: () => ({
    type: incomeReceipts.GET_INCOME_RECEIPTS_REQUEST,
  }),
  getMetaData: (data) => ({
    data,
    type: incomeReceipts.GET_META_DATA_REQUEST,
  }),
  addIncomeReceipt: (data) => ({
    type: incomeReceipts.ADD_INCOME_RECEIPTS_REQUEST,
    data,
  }),
  search: (data) => ({
    type: incomeReceipts.SEARCH_INCOME_RECEIPT_REQUEST,
    data,
  }),
  setUpdateData: (data) => ({
    type: incomeReceipts.SET_UPDATE_INCOME_RECEIPT_DATA,
    data,
  }),
  updateIncomeReceipts: (data, id) => ({
    type: incomeReceipts.UPDATE_INCOME_RECEIPT_REQUEST,
    data,
    id,
  }),
  deleteIncomeReceipt: (data, sortData) => ({
    type: incomeReceipts.DELETE_INCOME_RECEIPT_REQUEST,
    data,
    sortData,
  }),
  getIncomeReceiptDetails: (data) => ({
    type: incomeReceipts.GET_INCOME_RECEIPT_DETAILS_REQUEST,
    data,
  }),
  getIncomeReceiptStreams: (data) => ({
    type: incomeReceipts.GET_INCOME_RECEIPT_STREAMS_REQUEST,
    data,
  }),
  addIncomeReceiptStream: (data) => ({
    type: incomeReceipts.ADD_INCOME_RECEIPT_STREAM_REQUEST,
    data,
  }),
  updateIncomeReceiptStream: (data, incomeReceiptStreams, id) => ({
    type: incomeReceipts.UPDATE_INCOME_RECEIPT_STREAM_REQUEST,
    incomeReceiptStreams,
    id,
    data,
  }),
  deleteIncomeReceiptStream: (data, incomeReceiptStreams) => ({
    type: incomeReceipts.DELETE_INCOME_RECEIPT_STREAM_REQUEST,
    data,
    incomeReceiptStreams,
  }),
  getIncomeReceiptStreamDetails: (data) => ({
    type: incomeReceipts.GET_INCOME_RECEIPT_STREAM_DETAILS_REQUEST,
    data,
  }),
  addIncomeReceiptDocument: (data, params) => ({
    type: incomeReceipts.ADD_INCOME_RECEIPT_DOCUMENT_REQUEST,
    data,
    params,
  }),
  deleteIncomeReceiptDocument: (data) => ({
    type: incomeReceipts.DELETE_INCOME_RECEIPT_DOCUMENT_REQUEST,
    data,
  }),
  setUpdateIncomeReceiptStreamData: (data) => ({
    type: incomeReceipts.SET_UPDATE_INCOME_RECEIPT_STREAM_DATA,
    data,
  }),
  sortIncomeReceipts: (data) => ({
    type: incomeReceipts.SORT_INCOME_RECEIPTS_REQUEST,
    data,
  }),
  downloadIncomeReceipts: (data) => ({
    type: incomeReceipts.DOWNLOAD_INCOME_RECEIPTS_REQUEST,
    data,
  }),
  setDownloadQueryData: (data) => ({
    type: incomeReceipts.SET_DOWNLOAD_QUERY_DATA,
    data,
  }),
};

export default incomeReceipts;
