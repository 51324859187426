const lessonPlansInitialState = {
  lessonPlans: {
    lessonPlansSuccess: {},
    lessonPlansError: {},
    lessonPlansLoading: false,

    lessonPlanDetails: {},
    lessonPlanDetailsError: {},
    lessonPlanDetailsLoading: false,

    addLessonPlanSuccess: {},
    addLessonPlanError: {},
    addLessonPlanLoading: false,

    updateLessonPlanSuccess: {},
    updateLessonPlanError: {},
    updateLessonPlanLoading: false,
    setUpdateLessonPlanData: {},

    deleteLessonPlanSuccess: {},
    deleteLessonPlanError: {},
    deleteLessonPlanLoading: false,

    metaData: {},
    metaDataError: {},
    metaDataLoading: false,

    checkLessonPlanSuccess: {},
    checkLessonPlanError: {},
    checkLessonPlanLoading: false,

    approveLessonPlanSuccess: {},
    approveLessonPlanError: {},
    approveLessonPlanLoading: false,

    lessonPlanProcedureStages: {},
    lessonPlanProcedureStagesError: {},
    lessonPlanProcedureStagesLoading: false,

    addLessonPlanProcedureStage: {},
    addLessonPlanProcedureStageError: {},
    addLessonPlanProcedureStageLoading: false,

    updateLessonPlanProcedureStage: {},
    updateLessonPlanProcedureStageError: {},
    updateLessonPlanProcedureStageLoading: false,
    setUpdateLessonPlanProcedureStageData: {},

    deleteLessonPlanProcedureStage: {},
    deleteLessonPlanProcedureStageError: {},
    deleteLessonPlanProcedureStageLoading: false,
  },
};

export default lessonPlansInitialState;
