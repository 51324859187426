const learnersInitialState = {
  learnerDetailsLoading: false,
  learnerDetailsSuccess: {},
  learnerDetailsError: {},

  metaData: {},
  metaDataLoading: false,

  addLearnerLoading: false,
  addLearnerSuccess: {},
  addLearnerError: {},

  learnersLoading: false,
  learnersSuccess: {},
  learnersError: {},

  getLearnerDetailsLoading: false,
  getLearnerDetailsSuccess: {},
  getLearnerDetailsError: {},

  deleteLearnerLoading: false,
  deleteLearnerSuccess: {},
  deleteLearnerError: {},

  updateLearnerDetailsLoading: false,
  updateLearnerDetailsSuccess: {},
  updateLearnerDetailsError: {},
  updateLearnerDetailsData: {},

  addLearnerProfileLoading: false,
  addLearnerProfileSuccess: {},
  addLearnerProfileError: {},

  updateLearnerProfileLoading: false,
  updateLearnerProfileSuccess: {},
  updateLearnerProfileError: {},

  LearnerProfileData: {},

  uploadLearnerDocumentsLoading: false,
  uploadLearnerDocumentsSuccess: {},
  uploadLearnerDocumentsError: {},

  uploadLearnerImageLoading: false,
  uploadLearnerImageSuccess: {},
  uploadLearnerImageError: {},

  deleteLearnerDocumentLoading: false,
  deleteLearnerDocumentSuccess: {},
  deleteLearnerDocumentError: {},
};

export default learnersInitialState;
