import { staffAttendanceActions } from "../../../actions";
import staffAttendanceInitialState from "../../../initialState/humanResource/staffAttendance/staffAttendance.initialState";

const staffAttendance = (state = staffAttendanceInitialState, actions) => {
  switch (actions.type) {
    case staffAttendanceActions.GET_STAFF_ATTENDANCE_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case staffAttendanceActions.GET_STAFF_ATTENDANCE_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: actions.data,
        metaDataLoading: false,
      };
    case staffAttendanceActions.GET_STAFF_ATTENDANCE_META_DATA_ERROR:
      return {
        ...state,
        metaDataLoading: false,
        metaDataError: actions.error,
      };
    case staffAttendanceActions.GET_STAFF_ATTENDANCE_REQUEST:
      return {
        ...state,
        staffAttendanceLoading: true,
        staffAttendanceError: {},
      };
    case staffAttendanceActions.GET_STAFF_ATTENDANCE_SUCCESS:
      return {
        ...state,
        staffAttendanceSuccess: actions.data,
        staffAttendanceLoading: false,
      };
    case staffAttendanceActions.GET_STAFF_ATTENDANCE_ERROR:
      return {
        ...state,
        staffAttendanceLoading: false,
        staffAttendanceError: actions.error,
      };
    case staffAttendanceActions.GET_STAFF_ATTENDANCE_DETAILS_REQUEST:
      return {
        ...state,
        staffAttendanceDetailsLoading: true,
        staffAttendanceDetailsError: {},
      };
    case staffAttendanceActions.GET_STAFF_ATTENDANCE_DETAILS_SUCCESS:
      return {
        ...state,
        staffAttendanceDetailsSuccess: actions.data,
        staffAttendanceDetailsLoading: false,
      };
    case staffAttendanceActions.GET_STAFF_ATTENDANCE_DETAILS_ERROR:
      return {
        ...state,
        staffAttendanceDetailsLoading: false,
        staffAttendanceDetailsError: actions.error,
      };
    case staffAttendanceActions.ADD_STAFF_ATTENDANCE_REQUEST:
      return {
        ...state,
        addStaffAttendanceLoading: true,
        addStaffAttendanceError: {},
      };
    case staffAttendanceActions.ADD_STAFF_ATTENDANCE_SUCCESS:
      return {
        ...state,
        addStaffAttendanceSuccess: actions.data,
        addStaffAttendanceLoading: false,
      };
    case staffAttendanceActions.ADD_STAFF_ATTENDANCE_ERROR:
      return {
        ...state,
        addStaffAttendanceLoading: false,
        addStaffAttendanceError: actions.error,
      };
    case staffAttendanceActions.UPDATE_STAFF_ATTENDANCE_REQUEST:
      return {
        ...state,
        updateStaffAttendanceLoading: true,
        updateStaffAttendanceError: {},
      };
    case staffAttendanceActions.UPDATE_STAFF_ATTENDANCE_SUCCESS:
      return {
        ...state,
        updateStaffAttendanceSuccess: actions.data,
        updateStaffAttendanceLoading: false,
      };
    case staffAttendanceActions.UPDATE_STAFF_ATTENDANCE_ERROR:
      return {
        ...state,
        updateStaffAttendanceLoading: false,
        updateStaffAttendanceError: actions.error,
      };
    case staffAttendanceActions.DELETE_STAFF_ATTENDANCE_REQUEST:
      return {
        ...state,
        deleteStaffAttendanceLoading: true,
        deleteStaffAttendanceError: {},
      };
    case staffAttendanceActions.DELETE_STAFF_ATTENDANCE_SUCCESS:
      return {
        ...state,
        deleteStaffAttendanceSuccess: actions.data,
        deleteStaffAttendanceLoading: false,
      };
    case staffAttendanceActions.DELETE_STAFF_ATTENDANCE_ERROR:
      return {
        ...state,
        deleteStaffAttendanceLoading: false,
        deleteStaffAttendanceError: actions.error,
      };
    case staffAttendanceActions.CHECK_STAFF_ATTENDANCE_REQUEST:
      return {
        ...state,
        checkStaffAttendanceLoading: true,
        checkStaffAttendanceError: {},
      };
    case staffAttendanceActions.CHECK_STAFF_ATTENDANCE_SUCCESS:
      return {
        ...state,
        checkStaffAttendanceSuccess: actions.data,
        checkStaffAttendanceLoading: false,
        // pass back into state for staffAttendanceSuccess and make the status for the staffAttendance id Checked
        staffAttendanceSuccess: {
          ...state.staffAttendanceSuccess,
          staffAttendanceRecords:
            state.staffAttendanceSuccess.staffAttendanceRecords.map((item) =>
              item.id === actions.data.id
                ? { ...item, status: "Checked" }
                : { ...item }
            ),
        },
      };
    case staffAttendanceActions.CHECK_STAFF_ATTENDANCE_ERROR:
      return {
        ...state,
        checkStaffAttendanceLoading: false,
        checkStaffAttendanceError: actions.error,
      };
    case staffAttendanceActions.APPROVE_STAFF_ATTENDANCE_REQUEST:
      return {
        ...state,
        approveStaffAttendanceLoading: true,
        approveStaffAttendanceError: {},
      };
    case staffAttendanceActions.APPROVE_STAFF_ATTENDANCE_SUCCESS:
      return {
        ...state,
        approveStaffAttendanceSuccess: actions.data,
        approveStaffAttendanceLoading: false,
        // pass back into state for staffAttendanceSuccess and make the status for the staffAttendance id Approved
        staffAttendanceSuccess: {
          ...state.staffAttendanceSuccess,
          staffAttendanceRecords:
            state.staffAttendanceSuccess.staffAttendanceRecords.map((item) =>
              item.id === actions.data.id
                ? { ...item, status: "Approved" }
                : { ...item }
            ),
        },
      };
    case staffAttendanceActions.APPROVE_STAFF_ATTENDANCE_ERROR:
      return {
        ...state,
        approveStaffAttendanceLoading: false,
        approveStaffAttendanceError: actions.error,
      };
    case staffAttendanceActions.SET_STAFF_ROLL_CALL_DATA:
      return {
        ...state,
        setRollCallData: actions.data,
      };
    case staffAttendanceActions.SET_UPDATE_STAFF_ROLL_CALL_DATA:
      return {
        ...state,
        setUpdateRollCallData: actions.data,
      };
    default:
      return state;
  }
};

export default staffAttendance;
