import { takeLatest, put, fork, takeEvery } from "@redux-saga/core/effects";
import axios from "axios";
import {
  advanceAccountabilityFormActions,
  appUiActions,
  requisitionMemoActions,
  supplierPaymentsActions,
} from "../../../actions";
const FileDownload = require("js-file-download");

function* getAdvanceAccountabilityForms() {
  try {
    const response = yield axios({
      url: "/finance/advance-accountability-forms/",
    });

    yield put({
      type: advanceAccountabilityFormActions.GET_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: advanceAccountabilityFormActions.GET_ADVANCE_ACCOUNTABILITY_FORMS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAdvanceAccountabilityForms() {
  yield takeLatest(
    advanceAccountabilityFormActions.GET_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST,
    getAdvanceAccountabilityForms
  );
}

function* getMetaData({ data }) {
  try {
    const response = yield axios({
      url: "/finance/advance-accountability-forms/meta-data",
      params: data,
    });

    yield put({
      type: advanceAccountabilityFormActions.GET_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: advanceAccountabilityFormActions.GET_META_DATA_SUCCESS,
      error: {},
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(
    advanceAccountabilityFormActions.GET_META_DATA_REQUEST,
    getMetaData
  );
}

function* addAdvanceAccountabilityForm({ data }) {
  try {
    const response = yield axios({
      method: "POST",
      url: "/finance/advance-accountability-forms/",
      data,
    });

    yield put({
      type: advanceAccountabilityFormActions.ADD_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS,
      data: response,
    });
    yield put(
      advanceAccountabilityFormActions.getAdvanceAccountabilityForms(
        response.id
      )
    );
    yield put(advanceAccountabilityFormActions.getDetails(response.id));
    yield put(
      advanceAccountabilityFormActions.getMetaData({
        requisitionMemoId: response.requisition_memo_id,
      })
    );
    yield put(appUiActions.toggleAddAdvanceAccountabilityFormsModal(false));
    yield put(appUiActions.toggleAdvanceAccountabilityFormDetailsModal(true));
  } catch (error) {
    yield put({
      type: advanceAccountabilityFormActions.ADD_ADVANCE_ACCOUNTABILITY_FORMS_ERROR,
      error: error.data,
    });
  }
}

function* watchAddAdvanceAccountabilityForm() {
  yield takeLatest(
    advanceAccountabilityFormActions.ADD_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST,
    addAdvanceAccountabilityForm
  );
}

function* updateAdvanceAccountabilityForm({
  id,
  data,
  advanceAccountabilityForms,
}) {
  try {
    const response = yield axios({
      url: `/finance/advance-accountability-forms/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: advanceAccountabilityFormActions.UPDATE_ADVANCE_ACCOUNTABILIT_FORMS_SUCCESS,
      data: response,
      advanceAccountabilityForms,
    });

    yield put(appUiActions.toggleUpdateAdvanceAccountabilityFormsModal(false));
  } catch (error) {
    yield put({
      type: advanceAccountabilityFormActions.UPDATE_ADVANCE_ACCOUNTABILIT_FORMS_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateAdvanceAccountabilityForms() {
  yield takeLatest(
    advanceAccountabilityFormActions.UPDATE_ADVANCE_ACCOUNTABILIT_FORMS_REQUEST,
    updateAdvanceAccountabilityForm
  );
}

function* deleteAdvanceAccountabilityForm({ id }) {
  try {
    const response = yield axios({
      url: `/finance/advance-accountability-forms/${id}`,
      method: "DELETE",
    });

    yield put({
      type: advanceAccountabilityFormActions.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS,
      data: response,
    });
    yield put(advanceAccountabilityFormActions.getAdvanceAccountabilityForms());
    yield put(advanceAccountabilityFormActions.getMetaData());
  } catch (error) {
    yield put({
      type: advanceAccountabilityFormActions.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteAdvanceAccountabilityForm() {
  yield takeEvery(
    advanceAccountabilityFormActions.DELETE_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST,
    deleteAdvanceAccountabilityForm
  );
}

function* getAdvanceAccountabilityFormDetails({ id }) {
  try {
    const response = yield axios({
      url: `/finance/advance-accountability-forms/${id}`,
    });

    yield put({
      type: advanceAccountabilityFormActions.GET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: advanceAccountabilityFormActions.GET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAdvanceAccountabilityFormDetails() {
  yield takeLatest(
    advanceAccountabilityFormActions.GET_ADVANCE_ACCOUNTABILITY_FORMS_DETAILS_REQUEST,
    getAdvanceAccountabilityFormDetails
  );
}

function* search({ data }) {
  try {
    const response = yield axios({
      url: "/finance/advance-accountability-forms/search",
      method: "POST",
      data,
    });

    yield put({
      type: advanceAccountabilityFormActions.SEARCH_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: advanceAccountabilityFormActions.SEARCH_ADVANCE_ACCOUNTABILITY_FORMS_ERROR,
      error: error.data,
    });
  }
}

function* watchSearch() {
  yield takeLatest(
    advanceAccountabilityFormActions.SEARCH_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST,
    search
  );
}

function* submitAdvanceAccountabilityForm({ id }) {
  try {
    const response = yield axios({
      url: `/finance/advance-accountability-forms/submit/${id}`,
      method: "PATCH",
    });

    yield put({
      type: advanceAccountabilityFormActions.SUBMIT_ADVANCE_ACCOUNTABILITY_FORM_SUCCESS,
      data: response,
    });

    yield put(advanceAccountabilityFormActions.getDetails(id));
  } catch (error) {
    yield put({
      type: advanceAccountabilityFormActions.SUBMIT_ADVANCE_ACCOUNTABILITY_FORM_ERROR,
      error: error.data,
    });
  }
}

function* watchSubmitAdvanceAccountabilityForm() {
  yield takeLatest(
    advanceAccountabilityFormActions.SUBMIT_ADVANCE_ACCOUNTABILITY_FORM_REQUEST,
    submitAdvanceAccountabilityForm
  );
}

function* checkAdvanceAccountabilityForm({ id }) {
  try {
    const response = yield axios({
      url: `/finance/advance-accountability-forms/check/${id}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({
      type: advanceAccountabilityFormActions.CHECK_ADVANCE_ACCOUNTABILITY_FORM_SUCCESS,
      data: response,
    });

    yield put(advanceAccountabilityFormActions.getDetails(id));
  } catch (error) {
    yield put({
      type: advanceAccountabilityFormActions.CHECK_ADVANCE_ACCOUNTABILITY_FORM_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckAdvanceAccountabilityForm() {
  yield takeLatest(
    advanceAccountabilityFormActions.CHECK_ADVANCE_ACCOUNTABILITY_FORM_REQUEST,
    checkAdvanceAccountabilityForm
  );
}

function* approveAdvanceAccountabilityForm({ id }) {
  try {
    const response = yield axios({
      url: `/finance/advance-accountability-forms/approve/${id}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: advanceAccountabilityFormActions.APPROVE_ADVANCE_ACCOUNTABILITY_FORM_SUCCESS,
      data: response,
    });

    yield put(advanceAccountabilityFormActions.getDetails(id));
    yield put(supplierPaymentsActions.getSupplierPaymentsSchedule());
    yield put(requisitionMemoActions.getMetaData());
  } catch (error) {
    yield put({
      type: advanceAccountabilityFormActions.APPROVE_ADVANCE_ACCOUNTABILITY_FORM_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveAdvanceAccountabilityForm() {
  yield takeLatest(
    advanceAccountabilityFormActions.APPROVE_ADVANCE_ACCOUNTABILITY_FORM_REQUEST,
    approveAdvanceAccountabilityForm
  );
}

function* getActualExpenditureDetails({ id }) {
  try {
    const response = yield axios({
      url: `/finance/advance-accountability-forms/actual-expenditure/${id}`,
    });

    yield put({
      type: advanceAccountabilityFormActions.GET_ACTUAL_EXPENDITURE_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: advanceAccountabilityFormActions.GET_ACTUAL_EXPENDITURE_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetActualExpenditureDetails() {
  yield takeLatest(
    advanceAccountabilityFormActions.GET_ACTUAL_EXPENDITURE_DETAILS_REQUEST,
    getActualExpenditureDetails
  );
}

function* addActualExpendtiture({ data }) {
  try {
    const response = yield axios({
      url: `/finance/advance-accountability-forms/actual-expenditure`,
      method: "POST",
      data,
    });

    yield put({
      type: advanceAccountabilityFormActions.ADD_ACTUAL_EXPENDITURE_SUCCESS,
      data: response,
    });

    yield put(
      advanceAccountabilityFormActions.getDetails(
        data.advance_accountability_form_id
      )
    );
  } catch (error) {
    yield put({
      type: advanceAccountabilityFormActions.ADD_ACTUAL_EXPENDITURE_ERROR,
      error: error.data,
    });
  }
}

function* watchAddActualExpenditure() {
  yield takeLatest(
    advanceAccountabilityFormActions.ADD_ACTUAL_EXPENDITURE_REQUEST,
    addActualExpendtiture
  );
}

function* updateActualExpendtiture({ data, id }) {
  try {
    const response = yield axios({
      url: `/finance/advance-accountability-forms/actual-expenditure/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: advanceAccountabilityFormActions.UPDATE_ACTUAL_EXPENDITURE_SUCCESS,
      data: response,
    });

    yield put(
      advanceAccountabilityFormActions.getDetails(
        data.advance_accountability_form_id
      )
    );
    yield put(appUiActions.toggleUpdateActualExpenditureModal(false));
  } catch (error) {
    yield put({
      type: advanceAccountabilityFormActions.UPDATE_ACTUAL_EXPENDITURE_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateActualExpenditure() {
  yield takeLatest(
    advanceAccountabilityFormActions.UPDATE_ACTUAL_EXPENDITURE_REQUEST,
    updateActualExpendtiture
  );
}

function* deleteActualExpendtiture({ data, id }) {
  try {
    const response = yield axios({
      url: `/finance/advance-accountability-forms/actual-expenditure/${id}`,
      method: "DELETE",
      params: data,
    });

    yield put({
      type: advanceAccountabilityFormActions.DELETE_ACTUAL_EXPENDITURE_SUCCESS,
      data: response,
    });

    yield put(advanceAccountabilityFormActions.getAdvanceAccountabilityForms());
    yield put(advanceAccountabilityFormActions.getMetaData());
  } catch (error) {
    yield put({
      type: advanceAccountabilityFormActions.DELETE_ACTUAL_EXPENDITURE_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteActualExpenditure() {
  yield takeLatest(
    advanceAccountabilityFormActions.DELETE_ACTUAL_EXPENDITURE_REQUEST,
    deleteActualExpendtiture
  );
}

function* uploadAdvanceAccountabilityDocuments({ id, data }) {
  try {
    const response = yield axios({
      url: `/finance/advance-accountability-forms/documents?id=${id}`,
      method: "POST",
      data,
    });

    yield put({
      type: advanceAccountabilityFormActions.UPLOAD_ADVANCE_ACCOUNTABILITY_DOCUMENTS_SUCCESS,
      data: response,
    });

    yield put(advanceAccountabilityFormActions.getDetails(id));
  } catch (error) {
    yield put({
      type: advanceAccountabilityFormActions.UPLOAD_ADVANCE_ACCOUNTABILITY_DOCUMENTS_ERROR,
      error: error.data,
    });
  }
}

function* watchUploadAdvanceAccountabilityDocuments() {
  yield takeLatest(
    advanceAccountabilityFormActions.UPLOAD_ADVANCE_ACCOUNTABILITY_DOCUMENTS_REQUEST,
    uploadAdvanceAccountabilityDocuments
  );
}

function* sortAdvanceAccountabilityForms({ data }) {
  try {
    const response = yield axios({
      url: `/finance/advance-accountability-forms/sort`,
      method: "GET",
      params: data,
    });

    yield put({
      type: advanceAccountabilityFormActions.SORT_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: advanceAccountabilityFormActions.SORT_ADVANCE_ACCOUNTABILITY_FORMS_ERROR,
      error: error.data,
    });
    yield put(appUiActions.toggleSortFromTableModal(false));
  }
}

function* watchSortAdvanceAccountabilityForms() {
  yield takeLatest(
    advanceAccountabilityFormActions.SORT_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST,
    sortAdvanceAccountabilityForms
  );
}

function* downloadAdvanceAccountabilityForm({ data }) {
  try {
    const response = yield axios({
      url: `/finance/advance-accountability-forms/excel-report`,
      method: "GET",
      params: data,
      responseType: "blob",
    });

    FileDownload(response.data, "Advance-Accountability-Forms.xlsx");

    yield put({
      type: advanceAccountabilityFormActions.DOWNLOAD_ADVANCE_ACCOUNTABILITY_FORMS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: advanceAccountabilityFormActions.DOWNLOAD_ADVANCE_ACCOUNTABILITY_FORMS_ERROR,
      error: error.data,
    });
  }
}

function* watchDownloadAdvanceAccountabilityForm() {
  yield takeLatest(
    advanceAccountabilityFormActions.DOWNLOAD_ADVANCE_ACCOUNTABILITY_FORMS_REQUEST,
    downloadAdvanceAccountabilityForm
  );
}

export default [
  fork(watchGetAdvanceAccountabilityForms),
  fork(watchAddAdvanceAccountabilityForm),
  fork(watchUpdateAdvanceAccountabilityForms),
  fork(watchDeleteAdvanceAccountabilityForm),
  fork(watchGetAdvanceAccountabilityFormDetails),
  fork(watchSearch),
  fork(watchSubmitAdvanceAccountabilityForm),
  fork(watchCheckAdvanceAccountabilityForm),
  fork(watchApproveAdvanceAccountabilityForm),
  fork(watchGetActualExpenditureDetails),
  fork(watchGetActualExpenditureDetails),
  fork(watchUpdateActualExpenditure),
  fork(watchAddActualExpenditure),
  fork(watchDeleteActualExpenditure),
  fork(watchGetMetaData),
  fork(watchUploadAdvanceAccountabilityDocuments),
  fork(watchSortAdvanceAccountabilityForms),
  fork(watchDownloadAdvanceAccountabilityForm),
];
