import { manualsAndPoliciesActions } from "../../../actions";
import initialState from "../../../initialState";

const manualsAndPolicies = (
  state = initialState.manualsAndPolicies,
  actions
) => {
  switch (actions.type) {
    case manualsAndPoliciesActions.GET_MANUALS_AND_POLICIES_REQUEST:
      return {
        ...state,
        manualsAndPoliciesLoading: true,
      };
    case manualsAndPoliciesActions.GET_MANUALS_AND_POLICIES_SUCCESS:
      return {
        ...state,
        manualsAndPoliciesLoading: false,
        manualsAndPoliciesSuccess: actions.data,
        manualsAndPoliciesError: {},
      };
    case manualsAndPoliciesActions.GET_MANUALS_AND_POLICIES_ERROR:
      return {
        ...state,
        manualsAndPoliciesLoading: false,
        manualsAndPoliciesError: actions.error,
      };
    case manualsAndPoliciesActions.ADD_MANUALS_AND_POLICIES_REQUEST:
      return {
        ...state,
        addManualsAndPoliciesLoading: true,
        addManualsAndPoliciesSuccess: {},
        addManualsAndPoliciesError: {},
      };
    case manualsAndPoliciesActions.ADD_MANUALS_AND_POLICIES_SUCCESS:
      return {
        ...state,
        addManualsAndPoliciesLoading: false,
        addManualsAndPoliciesSuccess: actions.data,
        addManualsAndPoliciesError: {},
        manualsAndPoliciesSuccess: {
          ...state.manualsAndPoliciesSuccess,
          manualsAndPolicies: [
            ...state.manualsAndPoliciesSuccess.manualsAndPolicies,
            actions.data.manualsAndPolicies,
          ],
        },
      };
    case manualsAndPoliciesActions.ADD_MANUALS_AND_POLICIES_ERROR:
      return {
        ...state,
        addManualsAndPoliciesLoading: false,
        addManualsAndPoliciesError: actions.error,
        addManualsAndPoliciesSuccess: {},
      };

    case manualsAndPoliciesActions.DELETE_MANUALS_AND_POLICIES_REQUEST:
      return {
        ...state,
        deleteManualsAndPoliciesLoading: true,
        deleteManualsAndPoliciesSuccess: {},
        deleteManualsAndPoliciesError: {},
      };
    case manualsAndPoliciesActions.DELETE_MANUALS_AND_POLICIES_SUCCESS:
      return {
        ...state,
        deleteManualsAndPoliciesLoading: false,
        deleteManualsAndPoliciesSuccess: actions.data,
        deleteManualsAndPoliciesError: {},
        manualsAndPoliciesSuccess: {
          ...state.manualsAndPoliciesSuccess,
          manualsAndPolicies: actions.records,
        },
      };
    case manualsAndPoliciesActions.DELETE_MANUALS_AND_POLICIES_ERROR:
      return {
        ...state,
        deleteManualsAndPoliciesLoading: false,
        deleteManualsAndPoliciesSuccess: {},
        deleteManualsAndPoliciesError: actions.error,
      };
    case manualsAndPoliciesActions.SET_UPDATE_MANUALS_AND_POLICIES_DATA:
      return {
        ...state,
        updateManualsAndPoliciesData: actions.data,
      };
    case manualsAndPoliciesActions.UPDATE_MANUALS_AND_POLICIES_REQUEST:
      return {
        ...state,
        updateManualsAndPoliciesLoading: true,
        updateManualsAndPoliciesSuccess: {},
        updateManualsAndPoliciesError: {},
      };
    case manualsAndPoliciesActions.UPDATE_MANUALS_AND_POLICIES_SUCCESS:
      return {
        ...state,
        updateManualsAndPoliciesLoading: false,
        updateManualsAndPoliciesError: {},
        updateManualsAndPoliciesSuccess: actions.data,
        manualsAndPoliciesSuccess: {
          ...state.manualsAndPoliciesSuccess,
          manualsAndPolicies: actions.records,
        },
      };
    case manualsAndPoliciesActions.UPDATE_MANUALS_AND_POLICIES_ERROR:
      return {
        ...state,
        updateManualsAndPoliciesError: actions.error,
        updateManualsAndPoliciesSuccess: {},
        updateManualsAndPoliciesLoading: false,
      };
    case manualsAndPoliciesActions.GET_MANUALS_AND_POLICIES_DETAILS_REQUEST:
      return {
        ...state,
        manualsAndPoliciesDetailsLoading: true,
        manualsAndPoliciesDetailsSuccess: {},
        manualsAndPoliciesDetailsError: {},
      };
    case manualsAndPoliciesActions.GET_MANUALS_AND_POLICIES_DETAILS_SUCCESS:
      return {
        ...state,
        manualsAndPoliciesDetailsLoading: false,
        manualsAndPoliciesDetailsSuccess: actions.data,
        manualsAndPoliciesDetailsError: {},
      };
    case manualsAndPoliciesActions.GET_MANUALS_AND_POLICIES_DETAILS_ERROR:
      return {
        ...state,
        manualsAndPoliciesDetailsLoading: false,
        manualsAndPoliciesDetailsError: actions.data,
      };
    case manualsAndPoliciesActions.CHECK_MANUALS_AND_POLICIES_REQUEST:
      return {
        ...state,
        checkManualsAndPoliciesLoading: true,
        checkManualsAndPoliciesSuccess: {},
        checkManualsAndPoliciesError: {},
      };
    case manualsAndPoliciesActions.CHECK_MANUALS_AND_POLICIES_SUCCESS:
      return {
        ...state,
        checkManualsAndPoliciesLoading: false,
        checkManualsAndPoliciesSuccess: actions.data,
        checkManualsAndPoliciesError: {},
      };
    case manualsAndPoliciesActions.CHECK_MANUALS_AND_POLICIES_ERROR:
      return {
        ...state,
        checkManualsAndPoliciesLoading: false,
        checkManualsAndPoliciesSuccess: {},
        checkManualsAndPoliciesError: actions.error,
      };
    case manualsAndPoliciesActions.APPROVE_MANUALS_AND_POLICIES_REQUEST:
      return {
        ...state,
        approveManualsAndPoliciesLoading: true,
        approveManualsAndPoliciesSuccess: {},
        approveManualsAndPoliciesError: {},
      };
    case manualsAndPoliciesActions.APPROVE_MANUALS_AND_POLICIES_SUCCESS:
      return {
        ...state,
        approveManualsAndPoliciesLoading: false,
        approveManualsAndPoliciesSuccess: actions.data,
        approveManualsAndPoliciesError: {},
      };
    case manualsAndPoliciesActions.APPROVE_MANUALS_AND_POLICIES_ERROR:
      return {
        ...state,
        approveManualsAndPoliciesLoading: false,
        approveManualsAndPoliciesError: actions.error,
      };
    default:
      return state;
  }
};

export default manualsAndPolicies;
