import { organisationDetailsActions } from "../../../actions";
import initialState from "../../../initialState";

const organisationDetails = (
  state = initialState.organisationDetails,
  actions
) => {
  switch (actions.type) {
    case organisationDetailsActions.GET_ORGANISATION_DETAILS_REQUEST:
      return {
        ...state,
        organisationDetailsLoading: true,
      };
    case organisationDetailsActions.GET_ORGANISATION_DETAILS_SUCCESS:
      return {
        ...state,
        organisationDetailsLoading: false,
        organisationDetailsSuccess: actions.data,
        organisationDetailsError: {},
      };
    case organisationDetailsActions.GET_ORGANISATION_DETAILS_ERROR:
      return {
        ...state,
        organisationDetailsLoading: false,
        organisationDetailsError: actions.error,
      };
    case organisationDetailsActions.ADD_ORGANISATION_DETAILS_REQUEST:
      return {
        ...state,
        addOrganisationDetailsLoading: true,
        addOrganisationDetailsSuccess: {},
        addOrganisationDetailsError: {},
      };
    case organisationDetailsActions.ADD_ORGANISATION_DETAILS_SUCCESS:
      return {
        ...state,
        addOrganisationDetailsLoading: false,
        addOrganisationDetailsSuccess: actions.data,
        addOrganisationDetailsError: {},
        organisationDetailsSuccess: {
          ...state.organisationDetailsSuccess,
          organisationDetails: actions.data,
        },
      };
    case organisationDetailsActions.ADD_ORGANISATION_DETAILS_ERROR:
      return {
        ...state,
        addOrganisationDetailsLoading: false,
        addOrganisationDetailsSuccess: {},
        addOrganisationDetailsError: actions.error,
      };
    case organisationDetailsActions.UPDATE_ORGANISATION_DETAILS_REQUEST:
      return {
        ...state,
        updateOrganisationDetailsLoading: true,
      };
    case organisationDetailsActions.UPDATE_ORGANISATION_DETAILS_SUCCESS:
      return {
        ...state,
        updateOrganisationDetailsLoading: false,
        updateOrganisationDetailsSuccess: actions.data,
        updateOrganisationDetailsError: {},
      };
    case organisationDetailsActions.UPDATE_ORGANISATION_DETAILS_ERROR:
      return {
        ...state,
        updateOrganisationDetailsLoading: false,
        updateOrganisationDetailsError: actions.error,
      };
    case organisationDetailsActions.UPLOAD_ORGANISATION_LOGO_REQUEST:
      return {
        ...state,
        uploadOrganisationLogoLoading: true,
      };
    case organisationDetailsActions.UPLOAD_ORGANISATION_LOGO_SUCCESS:
      return {
        ...state,
        uploadOrganisationLogoSuccess: actions.data,
        uploadOrganisationLogoLoading: false,
        uploadOrganisationLogoError: {},
      };
    case organisationDetailsActions.UPLOAD_ORGANISATION_LOGO_ERROR:
      return {
        ...state,
        uploadOrganisationLogoError: actions.error,
        uploadOrganisationLogoLoading: false,
      };
    default:
      return state;
  }
};

export default organisationDetails;
