import { takeLatest, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { appUiActions, gradingActions } from "../../../actions";

function* getGradings() {
  try {
    const response = yield axios({
      url: "/academics/grading",
      method: "GET",
    });
    yield put({
      type: gradingActions.GET_GRADINGS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: gradingActions.GET_GRADINGS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetGradings() {
  yield takeLatest(gradingActions.GET_GRADINGS_REQUEST, getGradings);
}

function* addGrading({ data }) {
  try {
    const response = yield axios({
      url: "/academics/grading",
      method: "POST",
      data: data,
    });
    yield put({
      type: gradingActions.ADD_GRADING_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: gradingActions.ADD_GRADING_ERROR,
      error: error.data,
    });
  }
}
function* watchAddGrading() {
  yield takeLatest(gradingActions.ADD_GRADING_REQUEST, addGrading);
}

function* getGradingDetails({ id }) {
  try {
    const response = yield axios({
      url: `/academics/grading/${id}`,
      method: "GET",
    });
    yield put({
      type: gradingActions.GET_GRADING_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: gradingActions.GET_GRADING_DETAILS_ERROR,
      error: error.data,
    });
  }
}
function* watchGetGradingDetails() {
  yield takeLatest(
    gradingActions.GET_GRADING_DETAILS_REQUEST,
    getGradingDetails
  );
}

function* updateGradingDetails({ id, data }) {
  try {
    const response = yield axios({
      url: `/academics/grading/${id}`,
      method: "PUT",
      data: data,
    });
    yield put({
      type: gradingActions.UPDATE_GRADING_DETAILS_SUCCESS,
      data: response,
    });
    yield put(gradingActions.getGradings());
  } catch (error) {
    yield put({
      type: gradingActions.UPDATE_GRADING_DETAILS_ERROR,
      error: error.data,
    });
  }
}
function* watchUpdateGrading() {
  yield takeLatest(
    gradingActions.UPDATE_GRADING_DETAILS_REQUEST,
    updateGradingDetails
  );
}

function* deleteGrading({ id }) {
  try {
    const response = yield axios({
      url: `/academics/grading/${id}`,
      method: "DELETE",
    });
    yield put({
      type: gradingActions.DELETE_GRADING_SUCCESS,
      data: response,
    });
    yield put(gradingActions.getGradings());
  } catch (error) {
    yield put({
      type: gradingActions.DELETE_GRADING_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteGrading() {
  yield takeEvery(gradingActions.DELETE_GRADING_REQUEST, deleteGrading);
}

function* deleteGradingScore({ id, gradingId }) {
  try {
    const response = yield axios({
      url: `/academics/grading/grade-score/${id}?gradingId=${gradingId}`,
      method: "DELETE",
    });
    yield put({
      type: gradingActions.DELETE_GRADING_SCORE_SUCCESS,
      data: response,
    });
    yield put(gradingActions.getGradingDetails(gradingId));
  } catch (error) {
    yield put({
      type: gradingActions.DELETE_GRADING_SCORE_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteGradingScore() {
  yield takeLatest(
    gradingActions.DELETE_GRADING_SCORE_REQUEST,
    deleteGradingScore
  );
}

function* checkGrading({ id }) {
  try {
    const response = yield axios({
      url: `/academics/grading/check/${id}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });
    yield put({
      type: gradingActions.CHECK_GRADING_SUCCESS,
      data: response,
    });
    yield put(gradingActions.getGradingDetails(id));
  } catch (error) {
    yield put({
      type: gradingActions.CHECK_GRADING_ERROR,
      error: error.data,
    });
  }
}
function* watchCheckGrading() {
  yield takeLatest(gradingActions.CHECK_GRADING_REQUEST, checkGrading);
}

function* approveGrading({ id }) {
  try {
    const response = yield axios({
      url: `/academics/grading/approve/${id}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });
    yield put({
      type: gradingActions.APPROVE_GRADING_SUCCESS,
      data: response,
    });
    yield put(gradingActions.getGradingDetails(id));
  } catch (error) {
    yield put({
      type: gradingActions.APPROVE_GRADING_ERROR,
    });
  }
}
function* watchApproveGrading() {
  yield takeLatest(gradingActions.APPROVE_GRADING_REQUEST, approveGrading);
}

function* addAlevelGrading({ data }) {
  try {
    const response = yield axios({
      url: "/academics/grading/alevel",
      method: "POST",
      data: data,
    });
    yield put({
      type: gradingActions.ADD_ALEVEL_GRADING_SUCCESS,
      data: response,
    });
    yield put(gradingActions.getGradingDetails(data?.grading_id));
  } catch (error) {
    yield put({
      type: gradingActions.ADD_ALEVEL_GRADING_ERROR,
      error: error.data,
    });
  }
}

function* watchAddAlevelGrading() {
  yield takeLatest(gradingActions.ADD_ALEVEL_GRADING_REQUEST, addAlevelGrading);
}

function* updateAlevelGrading({ data, id }) {
  try {
    const response = yield axios({
      url: `/academics/grading/alevel/${id}`,
      method: "PUT",
      data: data,
    });

    yield put({
      type: gradingActions.UPDATE_ALEVEL_GRADING_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleUpdateAlevelGradingModal(false));
  } catch (error) {
    yield put({
      type: gradingActions.UPDATE_ALEVEL_GRADING_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateAlevelGrading() {
  yield takeLatest(
    gradingActions.UPDATE_ALEVEL_GRADING_REQUEST,
    updateAlevelGrading
  );
}

function* deleteAlevelGrading({ id, data }) {
  try {
    const response = yield axios({
      url: `/academics/grading/alevel/${id}`,
      method: "DELETE",
      params: data,
    });
    yield put({
      type: gradingActions.DELETE_ALEVEL_GRADING_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: gradingActions.DELETE_ALEVEL_GRADING_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteAlevelGrading() {
  yield takeLatest(
    gradingActions.DELETE_ALEVEL_GRADING_REQUEST,
    deleteAlevelGrading
  );
}

function* addDivisions({ data, id }) {
  try {
    const response = yield axios({
      url: "/academics/grading/divisions",
      method: "POST",
      data: data,
    });
    yield put({
      type: gradingActions.ADD_DIVISIONS_SUCCESS,
      data: response,
    });
    yield put(gradingActions.getGradingDetails(id));
  } catch (error) {
    yield put({
      type: gradingActions.ADD_DIVISIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchAddDivisions() {
  yield takeLatest(gradingActions.ADD_DIVISIONS_REQUEST, addDivisions);
}

function* updateDivision({ data, id, gradingId }) {
  try {
    const response = yield axios({
      url: `/academics/grading/divisions/${id}`,
      method: "PUT",
      data: data,
    });
    yield put({
      type: gradingActions.UPDATE_DIVISION_SUCCESS,
      data: response,
    });
    yield put(gradingActions.getGradingDetails(gradingId));
    yield put(appUiActions.toggleUpdateDivisionModal(false));
  } catch (error) {
    yield put({
      type: gradingActions.UPDATE_DIVISION_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateDivision() {
  yield takeLatest(gradingActions.UPDATE_DIVISION_REQUEST, updateDivision);
}

function* deleteDivision({ id, gradingId }) {
  try {
    const response = yield axios({
      url: `/academics/grading/divisions/${id}`,
      method: "DELETE",
    });
    yield put({
      type: gradingActions.DELETE_DIVISION_SUCCESS,
      data: response,
    });
    yield put(gradingActions.getGradingDetails(gradingId));
  } catch (error) {
    yield put({
      type: gradingActions.DELETE_DIVISION_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteDivision() {
  yield takeLatest(gradingActions.DELETE_DIVISION_REQUEST, deleteDivision);
}

function* addCompetencyGradingScale({ data, gradingId }) {
  try {
    const response = yield axios({
      url: "/academics/grading/competency-grading-scales",
      method: "POST",
      data: data,
    });
    yield put({
      type: gradingActions.ADD_COMPETENCY_GRADING_SCALE_SUCCESS,
      data: response,
    });
    yield put(gradingActions.getGradingDetails(gradingId));
  } catch (error) {
    yield put({
      type: gradingActions.ADD_COMPETENCY_GRADING_SCALE_ERROR,
      error: error.data,
    });
  }
}

function* watchAddCompetencyGradingScale() {
  yield takeLatest(
    gradingActions.ADD_COMPETENCY_GRADING_SCALE_REQUEST,
    addCompetencyGradingScale
  );
}

function* updateCompetencyGradingScale({ data, id, gradingId }) {
  try {
    const response = yield axios({
      url: `/academics/grading/competency-grading-scales/${id}`,
      method: "PUT",
      data: data,
    });
    yield put({
      type: gradingActions.UPDATE_COMPETENCY_GRADING_SCALE_SUCCESS,
      data: response,
    });
    yield put(gradingActions.getGradingDetails(gradingId));
  } catch (error) {
    yield put({
      type: gradingActions.UPDATE_COMPETENCY_GRADING_SCALE_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateCompetencyGradingScale() {
  yield takeLatest(
    gradingActions.UPDATE_COMPETENCY_GRADING_SCALE_REQUEST,
    updateCompetencyGradingScale
  );
}

function* deleteCompetencyGradingScale({ id, gradingId }) {
  try {
    const response = yield axios({
      url: `/academics/grading/competency-grading-scales/${id}`,
      method: "DELETE",
    });
    yield put({
      type: gradingActions.DELETE_COMPETENCY_GRADING_SCALE_SUCCESS,
      data: response,
    });
    yield put(gradingActions.getGradingDetails(gradingId));
  } catch (error) {
    yield put({
      type: gradingActions.DELETE_COMPETENCY_GRADING_SCALE_ERROR,
      error: error.data,
    });
  }
}

export function* watchDeleteCompetencyGradingScale() {
  yield takeLatest(
    gradingActions.DELETE_COMPETENCY_GRADING_SCALE_REQUEST,
    deleteCompetencyGradingScale
  );
}

export default [
  fork(watchGetGradings),
  fork(watchAddGrading),
  fork(watchGetGradingDetails),
  fork(watchUpdateGrading),
  fork(watchDeleteGrading),
  fork(watchCheckGrading),
  fork(watchApproveGrading),
  fork(watchDeleteGradingScore),
  fork(watchAddAlevelGrading),
  fork(watchUpdateAlevelGrading),
  fork(watchDeleteAlevelGrading),
  fork(watchAddDivisions),
  fork(watchUpdateDivision),
  fork(watchDeleteDivision),
  fork(watchAddCompetencyGradingScale),
  fork(watchUpdateCompetencyGradingScale),
  fork(watchDeleteCompetencyGradingScale),
];
