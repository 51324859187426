import { projectsActions } from "../../actions";
import initialState from "../../initialState";

const projects = (state = initialState.projects, actions) => {
  switch (actions.type) {
    case projectsActions.GET_PROJECTS_REQUEST:
      return {
        ...state,
        projectsLoading: true,
      };
    case projectsActions.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projectsLoading: false,
        projectsSuccess: actions.data,
      };
    case projectsActions.GET_PROJECTS_ERROR:
      return {
        ...state,
        projectsLoading: false,
        projectsError: actions.error,
      };
    case projectsActions.GET_PROJECTS_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case projectsActions.GET_PROJECTS_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataLoading: false,
        metaDataSuccess: actions.data,
      };
    case projectsActions.ADD_PROJECT_REQUEST:
      return {
        ...state,
        addProjectLoading: true,
      };
    case projectsActions.ADD_PROJECT_SUCCESS:
      return {
        ...state,
        addProjectLoading: false,
        addProjectSuccess: actions.data,
      };
    case projectsActions.ADD_PROJECT_ERROR:
      return {
        ...state,
        addProjectLoading: false,
        addProjectError: actions.error,
      };
    case projectsActions.SET_ADD_PROJECT_DATA:
      return {
        ...state,
        addProjectData: actions.data,
      };
    case projectsActions.DELETE_PROJECT_REQUEST:
      return {
        ...state,
        deleteProjectLoading: true,
      };
    case projectsActions.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        deleteProjectLoading: false,
        deleteProjectSuccess: actions.data,
      };
    case projectsActions.DELETE_PROJECT_ERROR:
      return {
        ...state,
        deleteProjectLoading: false,
        deleteProjectError: actions.error,
      };
    case projectsActions.SET_UPDATE_PROJECT_UPDATE_DATA:
      return {
        ...state,
        updateProjectData: actions.data,
      };
    case projectsActions.UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        updateProjectLoading: true,
      };
    case projectsActions.UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        updateProjectLoading: false,
        updateProjectSuccess: actions.data,
      };
    case projectsActions.UPDATE_PROJECT_ERROR:
      return {
        ...state,
        updateProjectLoading: false,
        updateProjectError: actions.error,
      };
    case projectsActions.GET_PROJECT_DETAILS_REQUEST:
      return {
        ...state,
        projectDetailsLoading: true,
      };
    case projectsActions.GET_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        projectDetailsLoading: false,
        projectDetailsSuccess: actions.data,
      };
    case projectsActions.GET_PROJECT_DETAILS_ERROR:
      return {
        ...state,
        projectDetailsLoading: false,
        projectDetailsError: actions.error,
      };
    case projectsActions.CHECK_PROJECT_REQUEST:
      return {
        ...state,
        checkProjectLoading: true,
      };
    case projectsActions.CHECK_PROJECT_SUCCESS:
      return {
        ...state,
        checkProjectLoading: false,
        checkProjectSuccess: actions.data,
      };
    case projectsActions.CHECK_PROJECT_ERROR:
      return {
        ...state,
        checkProjectLoading: false,
        checkProjectError: actions.error,
      };
    case projectsActions.APPROVE_PROJECT_REQUEST:
      return {
        ...state,
        approveProjectLoading: true,
      };
    case projectsActions.APPROVE_PROJECT_SUCCESS:
      return {
        ...state,
        approveProjectLoading: false,
        approveProjectSuccess: actions.data,
      };
    case projectsActions.APPROVE_PROJECT_ERROR:
      return {
        ...state,
        approveProjectLoading: false,
        approveProjectError: actions.error,
      };
    default:
      return state;
  }
};

export default projects;
