import { schemeOfWorkActions } from "../../../actions";
import initialState from "../../../initialState";

const schemeOfWork = (state = initialState.schemeOfWork, actions) => {
  switch (actions.type) {
    case schemeOfWorkActions.GET_SCHEMES_OF_WORK_REQUEST:
      return {
        ...state,
        schemesOfWorkLoading: true,
        schemesOfWorkError: {},
      };
    case schemeOfWorkActions.GET_SCHEMES_OF_WORK_SUCCESS:
      return {
        ...state,
        schemesOfWork: actions.data,
        schemesOfWorkLoading: false,
      };
    case schemeOfWorkActions.GET_SCHEMES_OF_WORK_ERROR:
      return {
        ...state,
        schemesOfWorkError: actions.error,
        schemesOfWorkLoading: false,
      };
    case schemeOfWorkActions.GET_SCHEME_OF_WORK_DETAILS_REQUEST:
      return {
        ...state,
        schemeOfWorkDetailsLoading: true,
        schemeOfWorkDetailsError: {},
      };
    case schemeOfWorkActions.GET_SCHEME_OF_WORK_DETAILS_SUCCESS:
      return {
        ...state,
        schemeOfWorkDetails: actions.data,
        schemeOfWorkDetailsLoading: false,
      };
    case schemeOfWorkActions.GET_SCHEME_OF_WORK_DETAILS_ERROR:
      return {
        ...state,
        schemeOfWorkDetailsError: actions.error,
        schemeOfWorkDetailsLoading: false,
      };
    case schemeOfWorkActions.ADD_SCHEME_OF_WORK_REQUEST:
      return {
        ...state,
        addSchemeOfWorkLoading: true,
        addSchemeOfWorkError: {},
      };
    case schemeOfWorkActions.ADD_SCHEME_OF_WORK_SUCCESS:
      return {
        ...state,
        addSchemeOfWork: actions.data,
        addSchemeOfWorkLoading: false,
      };
    case schemeOfWorkActions.ADD_SCHEME_OF_WORK_ERROR:
      return {
        ...state,
        addSchemeOfWorkError: actions.error,
        addSchemeOfWorkLoading: false,
      };
    case schemeOfWorkActions.GET_SCHEME_OF_WORK_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
        metaDataError: {},
      };
    case schemeOfWorkActions.GET_SCHEME_OF_WORK_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: actions.data,
        metaDataLoading: false,
      };
    case schemeOfWorkActions.GET_SCHEME_OF_WORK_META_DATA_ERROR:
      return {
        ...state,
        metaDataError: actions.error,
        metaDataLoading: false,
      };
    case schemeOfWorkActions.UPDATE_SCHEME_OF_WORK_REQUEST:
      return {
        ...state,
        updateSchemeOfWorkLoading: true,
        updateSchemeOfWorkError: {},
      };
    case schemeOfWorkActions.UPDATE_SCHEME_OF_WORK_SUCCESS:
      return {
        ...state,
        updateSchemeOfWork: actions.data,
        updateSchemeOfWorkLoading: false,
      };
    case schemeOfWorkActions.UPDATE_SCHEME_OF_WORK_ERROR:
      return {
        ...state,
        updateSchemeOfWorkError: actions.error,
        updateSchemeOfWorkLoading: false,
      };
    case schemeOfWorkActions.SET_UPDATE_SCHEME_OF_WORK_DATA:
      return {
        ...state,
        updateSchemeOfWorkData: actions.data,
      };
    case schemeOfWorkActions.DELETE_SCHEME_OF_WORK_REQUEST:
      return {
        ...state,
        deleteSchemeOfWorkLoading: true,
        deleteSchemeOfWorkError: {},
      };
    case schemeOfWorkActions.DELETE_SCHEME_OF_WORK_SUCCESS:
      return {
        ...state,
        deleteSchemeOfWork: actions.data,
        deleteSchemeOfWorkLoading: false,
      };
    case schemeOfWorkActions.DELETE_SCHEME_OF_WORK_ERROR:
      return {
        ...state,
        deleteSchemeOfWorkError: actions.error,
        deleteSchemeOfWorkLoading: false,
      };
    case schemeOfWorkActions.ADD_SCHEME_OF_WORK_ITEM_REQUEST:
      return {
        ...state,
        addSchemeOfWorkItemLoading: true,
        addSchemeOfWorkItemError: {},
      };
    case schemeOfWorkActions.ADD_SCHEME_OF_WORK_ITEM_SUCCESS:
      return {
        ...state,
        addSchemeOfWorkItem: actions.data,
        addSchemeOfWorkItemLoading: false,
      };
    case schemeOfWorkActions.ADD_SCHEME_OF_WORK_ITEM_ERROR:
      return {
        ...state,
        addSchemeOfWorkItemError: actions.error,
        addSchemeOfWorkItemLoading: false,
      };
    case schemeOfWorkActions.UPDATE_SCHEME_OF_WORK_ITEM_REQUEST:
      return {
        ...state,
        updateSchemeOfWorkItemLoading: true,
        updateSchemeOfWorkItemError: {},
      };
    case schemeOfWorkActions.UPDATE_SCHEME_OF_WORK_ITEM_SUCCESS:
      return {
        ...state,
        updateSchemeOfWorkItem: actions.data,
        updateSchemeOfWorkItemLoading: false,
      };
    case schemeOfWorkActions.UPDATE_SCHEME_OF_WORK_ITEM_ERROR:
      return {
        ...state,
        updateSchemeOfWorkItemError: actions.error,
        updateSchemeOfWorkItemLoading: false,
      };
    case schemeOfWorkActions.SET_UPDATE_SCHEME_OF_WORK_ITEM_DATA:
      return {
        ...state,
        updateSchemeOfWorkItemData: actions.data,
      };
    case schemeOfWorkActions.DELETE_SCHEME_OF_WORK_ITEM_REQUEST:
      return {
        ...state,
        deleteSchemeOfWorkItemLoading: true,
        deleteSchemeOfWorkItemError: {},
      };
    case schemeOfWorkActions.DELETE_SCHEME_OF_WORK_ITEM_SUCCESS:
      return {
        ...state,
        deleteSchemeOfWorkItem: actions.data,
        deleteSchemeOfWorkItemLoading: false,
      };
    case schemeOfWorkActions.DELETE_SCHEME_OF_WORK_ITEM_ERROR:
      return {
        ...state,
        deleteSchemeOfWorkItemError: actions.error,
        deleteSchemeOfWorkItemLoading: false,
      };
    case schemeOfWorkActions.CHECK_SCHEME_OF_WORK_REQUEST:
      return {
        ...state,
        checkSchemeOfWorkLoading: true,
        checkSchemeOfWorkError: {},
      };
    case schemeOfWorkActions.CHECK_SCHEME_OF_WORK_SUCCESS:
      return {
        ...state,
        checkSchemeOfWork: actions.data,
        checkSchemeOfWorkLoading: false,
      };
    case schemeOfWorkActions.CHECK_SCHEME_OF_WORK_ERROR:
      return {
        ...state,
        checkSchemeOfWorkError: actions.error,
        checkSchemeOfWorkLoading: false,
      };
    case schemeOfWorkActions.APPROVE_SCHEME_OF_WORK_REQUEST:
      return {
        ...state,
        approveSchemeOfWorkLoading: true,
        approveSchemeOfWorkError: {},
      };
    case schemeOfWorkActions.APPROVE_SCHEME_OF_WORK_SUCCESS:
      return {
        ...state,
        approveSchemeOfWork: actions.data,
        approveSchemeOfWorkLoading: false,
      };

    case schemeOfWorkActions.APPROVE_SCHEME_OF_WORK_ERROR:
      return {
        ...state,
        approveSchemeOfWorkError: actions.error,
        approveSchemeOfWorkLoading: false,
      };
    default:
      return state;
  }
};

export default schemeOfWork;
