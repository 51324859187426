import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthLayout from "./components/layouts/AuthLayout";
import LandingLayout from "./components/layouts/LandingLayout";
import routePaths from "./config/routes/routePaths.routes";
import PasswordresetPage from "./containers/auth/resetPasswordPage/resetPasswordPage";
import AccountconfirmPage from "./containers/auth/confirmAccountPage/confirmAccountPage";
import PageLoader from "./components/common/PageLoader";

const MainLayout = React.lazy(() => import("./components/layouts/MainLayout"));

const App = () => {
  useEffect(() => {
    // Prevent Going back to previous page
    window.history.pushState(null, document.title, "/");
    window.addEventListener("popstate", () => {
      // TO view the events, pass event as a prop to the function

      window.history.pushState(null, document.title, "/");
    });
  });

  return (
    <Suspense fallback={<PageLoader />}>
      <Router>
        <Routes>
          <Route path="/" element={<MainLayout />} />
          <Route path="/auth" element={<AuthLayout />} />
          <Route
            path={routePaths.landingPage.path}
            element={<LandingLayout />}
          />
          <Route
            path={routePaths.resetPasswordPage.path}
            element={<PasswordresetPage />}
          />
          <Route
            path={routePaths.confirmAccountPage.path}
            element={<AccountconfirmPage />}
          />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;
