import { takeLatest, takeEvery, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import {
  appUiActions,
  examinationsActions,
  marksUploadActions,
  studentReportActions,
} from "../../../actions";

function* getExaminations() {
  try {
    const response = yield axios({ url: "/academics/examinations" });

    yield put({
      type: examinationsActions.GET_EXAMINATIONS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: examinationsActions.GET_EXAMINATIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetExaminations() {
  yield takeLatest(
    examinationsActions.GET_EXAMINATIONS_REQUEST,
    getExaminations
  );
}

function* getExaminationDetails({ id }) {
  try {
    const response = yield axios({ url: `/academics/examinations/${id}` });

    yield put({
      type: examinationsActions.GET_EXAMINATIONS_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: examinationsActions.GET_EXAMINATIONS_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetExaminationDetails() {
  yield takeLatest(
    examinationsActions.GET_EXAMINATIONS_DETAILS_REQUEST,
    getExaminationDetails
  );
}

function* getMetaData() {
  try {
    const response = yield axios({ url: "/academics/examinations/meta-data" });

    yield put({
      type: examinationsActions.GET_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: examinationsActions.GET_META_DATA_SUCCESS,
      error: error.data,
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(examinationsActions.GET_META_DATA_REQUEST, getMetaData);
}

function* addExamination({ data }) {
  try {
    const response = yield axios({
      url: "/academics/examinations",
      method: "POST",
      data,
    });

    yield put({
      type: examinationsActions.ADD_EXAMINATIONS_SUCCESS,
      data: response,
    });
    yield put(examinationsActions.getExaminations());
    yield put(marksUploadActions.getMetaData());
    yield put(studentReportActions.getMetaData());
  } catch (error) {
    yield put({
      type: examinationsActions.ADD_EXAMINATIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchAddExamination() {
  yield takeLatest(
    examinationsActions.ADD_EXAMINATIONS_REQUEST,
    addExamination
  );
}

function* updateExamination({ data, id }) {
  try {
    const response = yield axios({
      url: `/academics/examinations/${id}`,
      method: "PUT",
      data,
    });

    yield put(examinationsActions.getExaminations());
    yield put(appUiActions.toggleUpdateExaminationModal(false));
    yield put({
      type: examinationsActions.UPDATE_EXAMINATION_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: examinationsActions.UPDATE_EXAMINATION_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateExamination() {
  yield takeLatest(
    examinationsActions.UPDATE_EXAMINATION_REQUEST,
    updateExamination
  );
}

function* deleteExamination({ id }) {
  try {
    const response = yield axios({
      url: `/academics/examinations/${id}`,
      method: "DELETE",
    });

    yield put(examinationsActions.getExaminations());
    yield put({
      type: examinationsActions.DELETE_EXAMINATIONS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: examinationsActions.DELETE_EXAMINATIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteExamination() {
  yield takeEvery(
    examinationsActions.DELETE_EXAMINATIONS_REQUEST,
    deleteExamination
  );
}

function* checkExamination({ id }) {
  try {
    const response = yield axios({
      url: `/academics/examinations/check/${id}`,
      method: "PATCH",
      data: {
        checked_at: new Date(),
      },
    });

    yield put(examinationsActions.getExaminationDetails(id));
    yield put({
      type: examinationsActions.CHECK_EXAMINATIONS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: examinationsActions.CHECK_EXAMINATIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckExaminations() {
  yield takeLatest(
    examinationsActions.CHECK_EXAMINATIONS_REQUEST,
    checkExamination
  );
}

function* approveExamination({ id }) {
  try {
    const response = yield axios({
      url: `/academics/examinations/approve/${id}`,
      data: {
        approved_at: new Date(),
      },
      method: "PATCH",
    });

    yield put(examinationsActions.getExaminationDetails(id));
    yield put({
      type: examinationsActions.APPROVE_EXAMINATIONS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: examinationsActions.APPROVE_EXAMINATIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveExamination() {
  yield takeLatest(
    examinationsActions.APPROVE_EXAMINATIONS_REQUEST,
    approveExamination
  );
}

export default [
  fork(watchGetExaminations),
  fork(watchGetExaminationDetails),
  fork(watchGetMetaData),
  fork(watchAddExamination),
  fork(watchUpdateExamination),
  fork(watchDeleteExamination),
  fork(watchCheckExaminations),
  fork(watchApproveExamination),
];
