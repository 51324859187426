import { filter, find } from "lodash";
import { paymentStructureActions } from "../../../actions";
import initialState from "../../../initialState";

const paymentStructures = (state = initialState.paymentStructures, actions) => {
  switch (actions.type) {
    case paymentStructureActions.GET_PAYMENT_STRUCTURES_REQUEST:
      return {
        ...state,
        paymentStructuresLoading: true,
      };
    case paymentStructureActions.GET_PAYMENT_STRUCTURES_SUCCESS:
      return {
        ...state,
        paymentStructuresSuccess: actions.data,
        paymentStructuresLoading: false,
      };
    case paymentStructureActions.GET_PAYMENT_STRUCTURES_ERROR:
      return {
        ...state,
        paymentStructuresError: actions.error,
        paymentStructuresLoading: false,
      };
    case paymentStructureActions.GET_PAYMENT_STRUCTURE_DETAILS_REQUEST:
      return {
        ...state,
        paymentStructureDetailsLoading: true,
        paymentStructureDetailsError: {},
      };
    case paymentStructureActions.GET_PAYMENT_STRUCTURE_DETAILS_SUCCESS:
      return {
        ...state,
        paymentStructureDetailsSuccess: actions.data,
        paymentStructureDetailsLoading: false,
      };
    case paymentStructureActions.GET_PAYMENT_STRUCTURE_DETAILS_ERROR:
      return {
        ...state,
        paymentStructureDetailsLoading: false,
        paymentStructureDetailsError: actions.error,
      };
    case paymentStructureActions.SET_PAYMENT_STRUCTURE_COST_STATUS_REQUEST:
      return {
        ...state,
        setPaymentStructureCostStatusLoading: true,
        setPaymentStructureCostStatusError: {},
      };
    case paymentStructureActions.SET_PAYMENT_STRUCTURE_COST_STATUS_SUCCESS: {
      const cost = find(
        state.paymentStructureDetailsSuccess.payment_structure_costs,
        {
          id: actions.costId,
        }
      );

      cost.active = actions.updateData.active;

      state.paymentStructureDetailsSuccess.payment_structure_costs = [
        cost,
        ...filter(
          state.paymentStructureDetailsSuccess.payment_structure_costs,
          (l) => l.id !== actions.costId
        ),
      ];
      return {
        ...state,
        setPaymentStructureCostStatusLoading: false,
        setPaymentStructureCostStatusSuccess: actions.data,
      };
    }
    case paymentStructureActions.SET_PAYMENT_STRUCTURE_COST_STATUS_ERROR:
      return {
        ...state,
        setPaymentStructureCostStatusLoading: false,
        setPaymentStructureCostStatusError: actions.error,
      };
    case paymentStructureActions.ADD_PAYMENT_STRUCUTURES_REQUEST:
      return {
        ...state,
        addPaymentStructuresLoading: true,
        addpaymentStructuresError: {},
      };
    case paymentStructureActions.ADD_PAYMENT_STRUCUTURES_SUCCESS:
      return {
        ...state,
        addPaymentStructuresSuccess: actions.data,
        addPaymentStructuresLoading: false,
        paymentStructuresSuccess: {
          paymentStructures: [
            actions.data,
            ...state.paymentStructuresSuccess.paymentStructures,
          ],
        },
      };
    case paymentStructureActions.ADD_PAYMENT_STRUCUTURES_ERROR:
      return {
        ...state,
        addPaymentStructuresError: actions.error,
        addPaymentStructuresLoading: false,
      };
    case paymentStructureActions.UPDATE_PAYMENT_STRUCTURES_REQUEST:
      return {
        ...state,
        updatePaymentStructuresLoading: true,
        updatePaymentStructuresError: {},
      };
    case paymentStructureActions.UPDATE_PAYMENT_STRUCTURES_SUCCESS:
      return {
        ...state,
        updatePaymentStructuresSuccess: actions.data,
        updatePaymentStructuresLoading: false,
        paymentStructuresSuccess: {
          paymentStructures: actions.paymentStructures,
        },
      };
    case paymentStructureActions.UPDATE_PAYMENT_STRUCTURES_ERROR:
      return {
        ...state,
        updatePaymentStructuresError: actions.error,
        updatePaymentStructuresLoading: false,
      };
    case paymentStructureActions.SET_UPDATE_PAYMENT_STRUCTURES_DATA:
      return {
        ...state,
        updatePaymentStructureData: actions.data,
      };
    case paymentStructureActions.DELETE_PAYMENT_STRUCTURES_REQUEST:
      return {
        ...state,
        deletePaymentStructuresLoading: true,
      };
    case paymentStructureActions.DELETE_PAYMENT_STRUCTURES_SUCCESS:
      return {
        ...state,
        deletePaymentStructuresSuccess: actions.data,
        deletePaymentStructuresLoading: false,
        paymentStructuresSuccess: {
          paymentStructures: actions.paymentStructures,
        },
      };
    case paymentStructureActions.DELETE_PAYMENT_STRUCTURES_ERROR:
      return {
        ...state,
        deletePaymentStructuresError: actions.error,
        deletePaymentStructuresLoading: false,
      };
    case paymentStructureActions.GET_COST_DETAILS_REQUEST:
      return {
        ...state,
        costDetailsLoading: true,
        costDetailsError: {},
      };
    case paymentStructureActions.GET_COST_DETAILS_SUCCESS:
      return {
        ...state,
        costDetailsSuccess: actions.data,
        costDetailsLoading: false,
      };
    case paymentStructureActions.GET_COST_DETAILS_ERROR:
      return {
        ...state,
        costDetailsError: actions.error,
        costDetailsLoading: false,
      };
    case paymentStructureActions.DELETE_COST_REQUEST:
      return {
        ...state,
        deleteCostLoading: true,
      };
    case paymentStructureActions.DELETE_COST_SUCCESS:
      return {
        ...state,
        deleteCostSuccess: actions.data,
        deleteCostLoading: false,
      };
    case paymentStructureActions.DELETE_COST_ERROR:
      return {
        ...state,
        deleteCostError: actions.error,
        deleteCostLoading: false,
      };
    case paymentStructureActions.SET_UPDATE_COST_DATA:
      return {
        ...state,
        updateCostData: actions.data,
      };
    case paymentStructureActions.UPDATE_COST_REQUEST:
      return {
        ...state,
        updateCostLoading: true,
        updateCostError: {},
      };
    case paymentStructureActions.UPDATE_COST_SUCCESS: {
      let cost = find(
        state.paymentStructureDetailsSuccess.payment_structure_costs,
        {
          id: actions.costId,
        }
      );
      cost = {
        ...cost,
        ...actions.updateData,
      };

      let updatedCosts = [
        cost,
        ...filter(
          state.paymentStructureDetailsSuccess.payment_structure_costs,
          (l) => l.id !== actions.costId
        ),
      ];
      state.paymentStructureDetailsSuccess.payment_structure_costs =
        updatedCosts;

      return {
        ...state,
        updateCostLoading: false,
        updateCostSuccess: actions.data,
      };
    }
    case paymentStructureActions.UPDATE_COST_ERROR:
      return {
        ...state,
        updateCostLoading: false,
        updateCostError: actions.error,
      };
    case paymentStructureActions.CHECK_PAYMENT_STRUCTURE_REQUEST:
      return {
        ...state,
        checkPaymentStructureLoading: true,
      };
    case paymentStructureActions.CHECK_PAYMENT_STRUCTURE_SUCCESS:
      return {
        ...state,
        checkPaymentStructureSuccess: actions.data,
        checkPaymentStructureLoading: false,
      };
    case paymentStructureActions.CHECK_PAYMENT_STRUCTURE_ERROR:
      return {
        ...state,
        checkPaymentStructureError: actions.error,
        checkPaymentStructureLoading: false,
      };
    case paymentStructureActions.APPROVE_PAYMENT_STRUCTURE_REQUEST:
      return {
        ...state,
        approvePaymentStructureLoading: true,
      };
    case paymentStructureActions.APPROVE_PAYMENT_STRUCTURE_SUCCESS:
      return {
        ...state,
        approvePaymentStructureLoading: false,
        approvePaymentStructureSuccess: actions.data,
      };
    case paymentStructureActions.APPROVE_PAYMENT_STRUCTURE_ERROR:
      return {
        ...state,
        approvePaymentStructureLoading: false,
        approvePaymentStructureError: actions.error,
      };
    case paymentStructureActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case paymentStructureActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: actions.data,
        metaDataLoading: false,
      };
    default:
      return state;
  }
};

export default paymentStructures;
