const ledgersInitialState = {
  ledgers: {
    metaDataLoading: false,
    metaData: {},

    ledgersLoading: false,
    ledgersSuccess: {},
    ledgersError: {},

    addLedgersLoading: false,
    addLedgersSuccess: {},
    addLedgersError: {},

    updateLedgersLoading: false,
    updateLedgersSuccess: {},
    updateLedgersError: {},
    updateLedgerData: {},

    deleteLedgersLoading: false,
    deleteLedgersSuccess: {},
    deleteLedgersError: {},

    ledgerDetailsLoading: false,
    ledgerDetailsSuccess: {},
    ledgerDetailsError: {},

    ledgerAccountDetailsLoading: false,
    ledgerAccountDetailsSuccess: {},
    ledgerAccountDetailsError: {},

    deleteLedgerAccountLoading: false,
    deleteLedgerAccountSuccess: {},
    deleteLedgerAccountError: {},

    checkLedgerLoading: false,
    checkLedgerSuccess: {},
    checkLedgerError: {},

    approveLedgerLoading: false,
    approveLedgerSuccess: {},
    approveLedgerError: {},
  },
};

export default ledgersInitialState;
