import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { staffAttendanceActions, appUiActions } from "../../../actions";

function* getStaffAttendance() {
  try {
    const response = yield axios({
      url: "/human-resource/staff-attendance/",
      method: "GET",
    });

    yield put({
      type: staffAttendanceActions.GET_STAFF_ATTENDANCE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: staffAttendanceActions.GET_STAFF_ATTENDANCE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetStaffAttendance() {
  yield takeLatest(
    staffAttendanceActions.GET_STAFF_ATTENDANCE_REQUEST,
    getStaffAttendance
  );
}

function* getStaffAttendanceMetaData() {
  try {
    const response = yield axios({
      url: "/human-resource/staff-attendance/meta-data",
    });

    yield put({
      type: staffAttendanceActions.GET_STAFF_ATTENDANCE_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: staffAttendanceActions.GET_STAFF_ATTENDANCE_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetStaffAttendanceMetaData() {
  yield takeLatest(
    staffAttendanceActions.GET_STAFF_ATTENDANCE_META_DATA_REQUEST,
    getStaffAttendanceMetaData
  );
}

function* getStaffAttendanceDetails({ id }) {
  try {
    const response = yield axios({
      url: `/human-resource/staff-attendance/${id}`,
    });

    yield put({
      type: staffAttendanceActions.GET_STAFF_ATTENDANCE_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: staffAttendanceActions.GET_STAFF_ATTENDANCE_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetStaffAttendanceDetails() {
  yield takeLatest(
    staffAttendanceActions.GET_STAFF_ATTENDANCE_DETAILS_REQUEST,
    getStaffAttendanceDetails
  );
}

function* addStaffAttendance({ data }) {
  try {
    const response = yield axios({
      url: "/human-resource/staff-attendance/",
      method: "POST",
      data,
    });

    yield put({
      type: staffAttendanceActions.ADD_STAFF_ATTENDANCE_SUCCESS,
      data: response,
    });
    // get the learner attendance
    yield put(staffAttendanceActions.getStaffAttendance());
    yield put(appUiActions.toggleAddStaffAttendanceModal(false));
  } catch (error) {
    yield put({
      type: staffAttendanceActions.ADD_STAFF_ATTENDANCE_ERROR,
      error: error.data,
    });
  }
}

function* watchAddStaffAttendance() {
  yield takeLatest(
    staffAttendanceActions.ADD_STAFF_ATTENDANCE_REQUEST,
    addStaffAttendance
  );
}

function* updateStaffAttendance({ data, id }) {
  try {
    const response = yield axios({
      url: `/human-resource/staff-attendance/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: staffAttendanceActions.UPDATE_STAFF_ATTENDANCE_SUCCESS,
      data: response,
    });

    yield put(staffAttendanceActions.getStaffAttendance());
  } catch (error) {
    yield put({
      type: staffAttendanceActions.UPDATE_STAFF_ATTENDANCE_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateStaffAttendance() {
  yield takeLatest(
    staffAttendanceActions.UPDATE_STAFF_ATTENDANCE_REQUEST,
    updateStaffAttendance
  );
}

function* deleteStaffAttendance({ id }) {
  try {
    const response = yield axios({
      url: `/human-resource/staff-attendance/${id}`,
      method: "DELETE",
    });

    yield put({
      type: staffAttendanceActions.DELETE_STAFF_ATTENDANCE_SUCCESS,
      data: response,
    });

    yield put(staffAttendanceActions.getStaffAttendance());
  } catch (error) {
    yield put({
      type: staffAttendanceActions.DELETE_STAFF_ATTENDANCE_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteStaffAttendance() {
  yield takeLatest(
    staffAttendanceActions.DELETE_STAFF_ATTENDANCE_REQUEST,
    deleteStaffAttendance
  );
}

function* checkStaffAttendance({ id }) {
  try {
    const response = yield axios({
      url: `/human-resource/staff-attendance/check/${id}`,
      method: "PATCH",
    });

    yield put({
      type: staffAttendanceActions.CHECK_STAFF_ATTENDANCE_SUCCESS,
      data: {
        ...response,
        id,
      },
    });
    yield put(staffAttendanceActions.getStaffAttendanceDetails(id));
  } catch (error) {
    yield put({
      type: staffAttendanceActions.CHECK_STAFF_ATTENDANCE_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckStaffAttendance() {
  yield takeLatest(
    staffAttendanceActions.CHECK_STAFF_ATTENDANCE_REQUEST,
    checkStaffAttendance
  );
}

function* approveStaffAttendance({ id }) {
  try {
    const response = yield axios({
      url: `/human-resource/staff-attendance/approve/${id}`,
      method: "PATCH",
    });

    yield put({
      type: staffAttendanceActions.APPROVE_STAFF_ATTENDANCE_SUCCESS,
      data: {
        ...response,
        id,
      },
    });
    yield put(staffAttendanceActions.getStaffAttendanceDetails(id));
  } catch (error) {
    yield put({
      type: staffAttendanceActions.APPROVE_STAFF_ATTENDANCE_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveStaffAttendance() {
  yield takeLatest(
    staffAttendanceActions.APPROVE_STAFF_ATTENDANCE_REQUEST,
    approveStaffAttendance
  );
}

export default [
  fork(watchGetStaffAttendance),
  fork(watchGetStaffAttendanceMetaData),
  fork(watchGetStaffAttendanceDetails),
  fork(watchAddStaffAttendance),
  fork(watchUpdateStaffAttendance),
  fork(watchDeleteStaffAttendance),
  fork(watchCheckStaffAttendance),
  fork(watchApproveStaffAttendance),
];
