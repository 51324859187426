import { examinationsActions } from "../../../actions";
import initialState from "../../../initialState";

const examinations = (state = initialState.examinations, actions) => {
  switch (actions.type) {
    case examinationsActions.GET_EXAMINATIONS_REQUEST:
      return {
        ...state,
        examinationsLoading: true,
        examinationsError: {},
      };
    case examinationsActions.GET_EXAMINATIONS_SUCCESS:
      return {
        ...state,
        examinationsSuccess: actions.data,
        examinationsLoading: false,
      };
    case examinationsActions.GET_EXAMINATIONS_ERROR:
      return {
        ...state,
        examinationsError: actions.error,
        examinationsLoading: false,
      };
    case examinationsActions.GET_EXAMINATIONS_DETAILS_REQUEST:
      return {
        ...state,
        getExaminationDetailsLoading: true,
        getExaminationDetailsError: {},
      };
    case examinationsActions.GET_EXAMINATIONS_DETAILS_SUCCESS:
      return {
        ...state,
        getExaminationDetailsSuccess: actions.data,
        getExaminationDetailsLoading: false,
      };
    case examinationsActions.GET_EXAMINATIONS_DETAILS_ERROR:
      return {
        ...state,
        getExaminationDetailsError: actions.error,
        getExaminationDetailsLoading: false,
      };
    case examinationsActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case examinationsActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaData: actions.data,
        metaDataLoading: false,
      };
    case examinationsActions.ADD_EXAMINATIONS_REQUEST:
      return {
        ...state,
        addExaminationsLoading: true,
        addExaminationsError: {},
      };
    case examinationsActions.ADD_EXAMINATIONS_SUCCESS:
      return {
        ...state,
        addExaminationsLoading: false,
        addExaminationsSuccess: actions.data,
      };
    case examinationsActions.ADD_EXAMINATIONS_ERROR:
      return {
        ...state,
        addExaminationsLoading: false,
        addExaminationsError: actions.error,
      };
    case examinationsActions.UPDATE_EXAMINATION_DATA:
      return {
        ...state,
        updateExaminationsData: actions.data,
      };
    case examinationsActions.UPDATE_EXAMINATION_REQUEST:
      return {
        ...state,
        updateExaminationsLoading: true,
        updateExaminationsError: {},
      };
    case examinationsActions.UPDATE_EXAMINATION_SUCCESS:
      return {
        ...state,
        updateExaminationsSuccess: actions.data,
        updateExaminationsLoading: false,
      };
    case examinationsActions.UPDATE_EXAMINATION_ERROR:
      return {
        ...state,
        updateExaminationsError: actions.error,
        updateExaminationsLoading: false,
      };
    case examinationsActions.DELETE_EXAMINATIONS_REQUEST:
      return {
        ...state,
        deleteExaminationsLoading: true,
        deleteExaminationsError: {},
      };
    case examinationsActions.DELETE_EXAMINATIONS_SUCCESS:
      return {
        ...state,
        deleteExaminationsSuccess: {},
        deleteExaminationsLoading: false,
      };
    case examinationsActions.DELETE_EXAMINATIONS_ERROR:
      return {
        ...state,
        deleteExaminationsError: actions.error,
        deleteExaminationsLoading: false,
      };
    case examinationsActions.CHECK_EXAMINATIONS_REQUEST:
      return {
        ...state,
        checkExaminationsLoading: true,
        checkExaminationsError: {},
      };
    case examinationsActions.CHECK_EXAMINATIONS_SUCCESS:
      return {
        ...state,
        checkExaminationsSuccess: actions.data,
        checkExaminationsLoading: false,
      };
    case examinationsActions.CHECK_EXAMINATIONS_ERROR:
      return {
        ...state,
        checkExaminationsLoading: false,
        checkExaminationsError: actions.error,
      };
    case examinationsActions.APPROVE_EXAMINATIONS_REQUEST:
      return {
        ...state,
        approveExaminationsLoading: true,
        approveExaminationsError: {},
      };
    case examinationsActions.APPROVE_EXAMINATIONS_SUCCESS:
      return {
        ...state,
        approveExaminationsLoading: false,
        approveExaminationsSuccess: actions.data,
      };
    case examinationsActions.APPROVE_EXAMINATIONS_ERROR:
      return {
        ...state,
        approveExaminationsLoading: false,
        approveExaminationsError: actions.errors,
      };
    case examinationsActions.SET_EXAM_TYPE:
      return {
        ...state,
        examType: actions.data,
      };
    default:
      return state;
  }
};

export default examinations;
