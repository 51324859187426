import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { prepaymentsActions } from "../../../actions";

function* getPrepayments() {
  try {
    const response = yield axios({
      url: "/finance/financial-reports/receivables",
    });

    yield put({
      type: prepaymentsActions.GET_PREPAYMENTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: prepaymentsActions.GET_PREPAYMENTS_ERROR,
      data: error,
    });
  }
}

function* watchGetPrepayments() {
  yield takeLatest(prepaymentsActions.GET_PREPAYMENTS_REQUEST, getPrepayments);
}

export default [fork(watchGetPrepayments)];
