const requestForQuotations = {
  GET_REQUEST_FOR_QUOTATIONS_REQUEST: "GET_REQUEST_FOR_QUOTATIONS_REQUEST",
  GET_REQUEST_FOR_QUOTATIONS_SUCCESS: "GET_REQUEST_FOR_QUOTATIONS_SUCCESS",
  GET_REQUEST_FOR_QUOTATIONS_ERROR: "GET_REQUEST_FOR_QUOTATIONS_ERROR",

  GET_REQUEST_FOR_QUOTATIONS_META_DATA_REQUEST:
    "GET_REQUEST_FOR_QUOTATIONS_META_DATA_REQUEST",
  GET_REQUEST_FOR_QUOTATIONS_META_DATA_SUCCESS:
    "GET_REQUEST_FOR_QUOTATIONS_META_DATA_SUCCESS",

  SET_ADD_REQUEST_FOR_QUOTATION_DATA: "SET_ADD_REQUEST_FOR_QUOTATION_DATA",
  ADD_REQUEST_FOR_QUOTATION_REQUEST: "ADD_REQUEST_FOR_QUOTATION_REQUEST",
  ADD_REQUEST_FOR_QUOTATION_SUCCESS: "ADD_REQUEST_FOR_QUOTATION_SUCCESS",
  ADD_REQUEST_FOR_QUOTATION_ERROR: "ADD_REQUEST_FOR_QUOTATION_ERROR",

  DELETE_REQUEST_FOR_QUOTATION_REQUEST: "DELETE_REQUEST_FOR_QUOTATION_REQUEST",
  DELETE_REQUEST_FOR_QUOTATION_SUCCESS: "DELETE_REQUEST_FOR_QUOTATION_SUCCESS",
  DELETE_REQUEST_FOR_QUOTATION_ERROR: "DELETE_REQUEST_FOR_QUOTATION_ERROR",

  SET_UPDATE_REQUEST_FOR_QUOTATION_UPDATE_DATA:
    "SET_UPDATE_REQUEST_FOR_QUOTATION_UPDATE_DATA",
  UPDATE_REQUEST_FOR_QUOTATION_REQUEST: "UPDATE_REQUEST_FOR_QUOTATION_REQUEST",
  UPDATE_REQUEST_FOR_QUOTATION_SUCCESS: "UPDATE_REQUEST_FOR_QUOTATION_SUCCESS",
  UPDATE_REQUEST_FOR_QUOTATION_ERROR: "UPDATE_REQUEST_FOR_QUOTATION_ERROR",

  GET_REQUEST_FOR_QUOTATION_DETAILS_REQUEST:
    "GET_REQUEST_FOR_QUOTATION_DETAILS_REQUEST",
  GET_REQUEST_FOR_QUOTATION_DETAILS_SUCCESS:
    "GET_REQUEST_FOR_QUOTATION_DETAILS_SUCCESS",
  GET_REQUEST_FOR_QUOTATION_DETAILS_ERROR:
    "GET_REQUEST_FOR_QUOTATION_DETAILS_ERROR",

  APPROVE_REQUEST_FOR_QUOTATION_REQUEST:
    "APPROVE_REQUEST_FOR_QUOTATION_REQUEST",
  APPROVE_REQUEST_FOR_QUOTATION_SUCCESS:
    "APPROVE_REQUEST_FOR_QUOTATION_SUCCESS",
  APPROVE_REQUEST_FOR_QUOTATION_ERROR: "APPROVE_REQUEST_FOR_QUOTATION_ERROR",

  CHECK_REQUEST_FOR_QUOTATION_REQUEST: "CHECK_REQUEST_FOR_QUOTATION_REQUEST",
  CHECK_REQUEST_FOR_QUOTATION_SUCCESS: "CHECK_REQUEST_FOR_QUOTATION_SUCCESS",
  CHECK_REQUEST_FOR_QUOTATION_ERROR: "CHECK_REQUEST_FOR_QUOTATION_ERROR",

  getRequestForQuotations: () => ({
    type: requestForQuotations.GET_REQUEST_FOR_QUOTATIONS_REQUEST,
  }),
  getMetaData: () => ({
    type: requestForQuotations.GET_REQUEST_FOR_QUOTATIONS_META_DATA_REQUEST,
  }),
  setAddRequestForQuotationData: (data) => ({
    type: requestForQuotations.SET_ADD_REQUEST_FOR_QUOTATION_DATA,
    data,
  }),
  addRequestForQuotation: (data) => ({
    type: requestForQuotations.ADD_REQUEST_FOR_QUOTATION_REQUEST,
    data,
  }),
  deleteRequestForQuotation: (data) => ({
    type: requestForQuotations.DELETE_REQUEST_FOR_QUOTATION_REQUEST,
    data,
  }),
  updateRequestForQuotation: (data, id) => ({
    type: requestForQuotations.UPDATE_REQUEST_FOR_QUOTATION_REQUEST,
    data,
    id,
  }),
  setUpdateRequestForQuotationData: (data) => ({
    type: requestForQuotations.SET_UPDATE_REQUEST_FOR_QUOTATION_UPDATE_DATA,
    data,
  }),
  getRequestForQuotationDetails: (data) => ({
    type: requestForQuotations.GET_REQUEST_FOR_QUOTATION_DETAILS_REQUEST,
    data,
  }),
  checkRequestForQuotation: (data) => ({
    type: requestForQuotations.CHECK_REQUEST_FOR_QUOTATION_REQUEST,
    data,
  }),
  approveRequestForQuotation: (data) => ({
    type: requestForQuotations.APPROVE_REQUEST_FOR_QUOTATION_REQUEST,
    data,
  }),
};

export default requestForQuotations;
