import { bidEvaluationsActions } from "../../../actions";
import initialState from "../../../initialState";

const bidEvaluations = (state = initialState.bidEvaluations, actions) => {
  switch (actions.type) {
    case bidEvaluationsActions.GET_BID_EVALUATIONS_REQUEST:
      return {
        ...state,
        bidEvaluationsLoading: true,
      };
    case bidEvaluationsActions.GET_BID_EVALUATIONS_SUCCESS:
      return {
        ...state,
        bidEvaluationsLoading: false,
        bidEvaluationsSuccess: actions.data,
      };
    case bidEvaluationsActions.GET_BID_EVALUATIONS_ERROR:
      return {
        ...state,
        bidEvaluationsLoading: false,
        bidEvaluationsError: actions.error,
      };
    case bidEvaluationsActions.GET_ELIGIBILITY_EVALUATIONS_REQUEST:
      return {
        ...state,
        eligibilityEvaluationsLoading: true,
      };
    case bidEvaluationsActions.GET_ELIGIBILITY_EVALUATIONS_SUCCESS:
      return {
        ...state,
        eligibilityEvaluationsLoading: false,
        eligibilityEvaluationsSuccess: actions.data,
      };
    case bidEvaluationsActions.GET_ELIGIBILITY_EVALUATIONS_ERROR:
      return {
        ...state,
        eligibilityEvaluationsLoading: false,
        eligibilityEvaluationsError: actions.error,
      };
    case bidEvaluationsActions.GET_TECHNICAL_EVALUATIONS_REQUEST:
      return {
        ...state,
        technicalEvaluationsLoading: true,
      };
    case bidEvaluationsActions.GET_TECHNICAL_EVALUATIONS_SUCCESS:
      return {
        ...state,
        technicalEvaluationsLoading: false,
        technicalEvaluationsSuccess: actions.data,
      };
    case bidEvaluationsActions.GET_TECHNICAL_EVALUATIONS_ERROR:
      return {
        ...state,
        technicalEvaluationsLoading: false,
        technicalEvaluationsError: actions.error,
      };
    case bidEvaluationsActions.GET_FINANCIAL_EVALUATIONS_REQUEST:
      return {
        ...state,
        financialEvaluationsLoading: true,
      };
    case bidEvaluationsActions.GET_FINANCIAL_EVALUATIONS_SUCCESS:
      return {
        ...state,
        financialEvaluationsLoading: false,
        financialEvaluationsSuccess: actions.data,
      };
    case bidEvaluationsActions.GET_FINANCIAL_EVALUATIONS_ERROR:
      return {
        ...state,
        financialEvaluationsLoading: false,
        financialEvaluationsError: actions.error,
      };
    case bidEvaluationsActions.GET_BID_EVALUATIONS_META_DATA_REQUEST:
      return {
        ...state,
        bidEvaluationsMetaDataLoading: true,
      };
    case bidEvaluationsActions.GET_BID_EVALUATIONS_META_DATA_SUCCESS:
      return {
        ...state,
        bidEvaluationsMetaDataLoading: false,
        bidEvaluationsMetaDataSuccess: actions.data,
      };
    case bidEvaluationsActions.GET_ELIGIBILITY_EVALUATIONS_META_DATA_REQUEST:
      return {
        ...state,
        eligibilityEvaluationsMetaDataLoading: true,
      };
    case bidEvaluationsActions.GET_ELIGIBILITY_EVALUATIONS_META_DATA_SUCCESS:
      return {
        ...state,
        eligibilityEvaluationsMetaDataLoading: false,
        eligibilityEvaluationsMetaDataSuccess: actions.data,
      };
    case bidEvaluationsActions.GET_TECHNICAL_EVALUATIONS_META_DATA_REQUEST:
      return {
        ...state,
        technicalEvaluationsMetaDataLoading: true,
      };
    case bidEvaluationsActions.GET_TECHNICAL_EVALUATIONS_META_DATA_SUCCESS:
      return {
        ...state,
        technicalEvaluationsMetaDataLoading: false,
        technicalEvaluationsMetaDataSuccess: actions.data,
      };
    case bidEvaluationsActions.GET_FINANCIAL_EVALUATIONS_META_DATA_REQUEST:
      return {
        ...state,
        financialEvaluationsMetaDataLoading: true,
      };
    case bidEvaluationsActions.GET_FINANCIAL_EVALUATIONS_META_DATA_SUCCESS:
      return {
        ...state,
        financialEvaluationsMetaDataLoading: false,
        financialEvaluationsMetaDataSuccess: actions.data,
      };
    case bidEvaluationsActions.ADD_BID_EVALUATION_REQUEST:
      return {
        ...state,
        addBidEvaluationLoading: true,
      };
    case bidEvaluationsActions.ADD_BID_EVALUATION_SUCCESS:
      return {
        ...state,
        addBidEvaluationLoading: false,
        addBidEvaluationSuccess: actions.data,
      };
    case bidEvaluationsActions.ADD_BID_EVALUATION_ERROR:
      return {
        ...state,
        addBidEvaluationLoading: false,
        addBidEvaluationError: actions.error,
      };
    case bidEvaluationsActions.SET_ADD_BID_EVALUATION_DATA:
      return {
        ...state,
        addBidEvaluationData: actions.data,
      };
    case bidEvaluationsActions.ADD_ELIGIBILITY_EVALUATION_REQUEST:
      return {
        ...state,
        addEligibilityEvaluationLoading: true,
      };
    case bidEvaluationsActions.ADD_ELIGIBILITY_EVALUATION_SUCCESS:
      return {
        ...state,
        addEligibilityEvaluationLoading: false,
        addEligibilityEvaluationSuccess: actions.data,
      };
    case bidEvaluationsActions.ADD_ELIGIBILITY_EVALUATION_ERROR:
      return {
        ...state,
        addEligibilityEvaluationLoading: false,
        addEligibilityEvaluationError: actions.error,
      };
    case bidEvaluationsActions.SET_ADD_ELIGIBILITY_EVALUATION_DATA:
      return {
        ...state,
        addEligibilityEvaluationData: actions.data,
      };
    case bidEvaluationsActions.ADD_TECHNICAL_EVALUATION_REQUEST:
      return {
        ...state,
        addTechnicalEvaluationLoading: true,
      };
    case bidEvaluationsActions.ADD_TECHNICAL_EVALUATION_SUCCESS:
      return {
        ...state,
        addTechnicalEvaluationLoading: false,
        addTechnicalEvaluationSuccess: actions.data,
      };
    case bidEvaluationsActions.ADD_TECHNICAL_EVALUATION_ERROR:
      return {
        ...state,
        addTechnicalEvaluationLoading: false,
        addTechnicalEvaluationError: actions.error,
      };
    case bidEvaluationsActions.SET_ADD_TECHNICAL_EVALUATION_DATA:
      return {
        ...state,
        addTechnicalEvaluationData: actions.data,
      };
    case bidEvaluationsActions.ADD_FINANCIAL_EVALUATION_REQUEST:
      return {
        ...state,
        addFinancialEvaluationLoading: true,
      };
    case bidEvaluationsActions.ADD_FINANCIAL_EVALUATION_SUCCESS:
      return {
        ...state,
        addFinancialEvaluationLoading: false,
        addFinancialEvaluationSuccess: actions.data,
      };
    case bidEvaluationsActions.ADD_FINANCIAL_EVALUATION_ERROR:
      return {
        ...state,
        addFinancialEvaluationLoading: false,
        addFinancialEvaluationError: actions.error,
      };
    case bidEvaluationsActions.SET_ADD_FINANCIAL_EVALUATION_DATA:
      return {
        ...state,
        addFinancialEvaluationData: actions.data,
      };
    case bidEvaluationsActions.DELETE_BID_EVALUATION_REQUEST:
      return {
        ...state,
        deleteBidEvaluationLoading: true,
      };
    case bidEvaluationsActions.DELETE_BID_EVALUATION_SUCCESS:
      return {
        ...state,
        deleteBidEvaluationLoading: false,
        deleteBidEvaluationSuccess: actions.data,
      };
    case bidEvaluationsActions.DELETE_BID_EVALUATION_ERROR:
      return {
        ...state,
        deleteBidEvaluationLoading: false,
        deleteBidEvaluationError: actions.error,
      };
    case bidEvaluationsActions.DELETE_ELIGIBILITY_EVALUATION_REQUEST:
      return {
        ...state,
        deleteEligibilityEvaluationLoading: true,
      };
    case bidEvaluationsActions.DELETE_ELIGIBILITY_EVALUATION_SUCCESS:
      return {
        ...state,
        deleteEligibilityEvaluationLoading: false,
        deleteEligibilityEvaluationSuccess: actions.data,
      };
    case bidEvaluationsActions.DELETE_ELIGIBILITY_EVALUATION_ERROR:
      return {
        ...state,
        deleteEligibilityEvaluationLoading: false,
        deleteEligibilityEvaluationError: actions.error,
      };
    case bidEvaluationsActions.DELETE_TECHNICAL_EVALUATION_REQUEST:
      return {
        ...state,
        deleteTechnicalEvaluationLoading: true,
      };
    case bidEvaluationsActions.DELETE_TECHNICAL_EVALUATION_SUCCESS:
      return {
        ...state,
        deleteTechnicalEvaluationLoading: false,
        deleteTechnicalEvaluationSuccess: actions.data,
      };
    case bidEvaluationsActions.DELETE_TECHNICAL_EVALUATION_ERROR:
      return {
        ...state,
        deleteTechnicalEvaluationLoading: false,
        deleteTechnicalEvaluationError: actions.error,
      };
    case bidEvaluationsActions.DELETE_FINANCIAL_EVALUATION_REQUEST:
      return {
        ...state,
        deleteFinancialEvaluationLoading: true,
      };
    case bidEvaluationsActions.DELETE_FINANCIAL_EVALUATION_SUCCESS:
      return {
        ...state,
        deleteFinancialEvaluationLoading: false,
        deleteFinancialEvaluationSuccess: actions.data,
      };
    case bidEvaluationsActions.DELETE_FINANCIAL_EVALUATION_ERROR:
      return {
        ...state,
        deleteFinancialEvaluationLoading: false,
        deleteFinancialEvaluationError: actions.error,
      };
    case bidEvaluationsActions.GET_BID_EVALUATION_DETAILS_REQUEST:
      return {
        ...state,
        bidEvaluationDetailsLoading: true,
      };
    case bidEvaluationsActions.GET_BID_EVALUATION_DETAILS_SUCCESS:
      return {
        ...state,
        bidEvaluationDetailsLoading: false,
        bidEvaluationDetailsSuccess: actions.data,
      };
    case bidEvaluationsActions.GET_BID_EVALUATION_DETAILS_ERROR:
      return {
        ...state,
        bidEvaluationDetailsLoading: false,
        bidEvaluationDetailsError: actions.error,
      };
    case bidEvaluationsActions.GET_ELIGIBILITY_EVALUATION_DETAILS_REQUEST:
      return {
        ...state,
        eligibilityEvaluationDetailsLoading: true,
      };
    case bidEvaluationsActions.GET_ELIGIBILITY_EVALUATION_DETAILS_SUCCESS:
      return {
        ...state,
        eligibiltiyEvaluationDetailsLoading: false,
        eligibilityEvaluationDetailsSuccess: actions.data,
      };
    case bidEvaluationsActions.GET_ELIGIBILITY_EVALUATION_DETAILS_ERROR:
      return {
        ...state,
        eligibilityEvaluationDetailsLoading: false,
        eligibilityEvaluationDetailsError: actions.error,
      };
    case bidEvaluationsActions.GET_TECHNICAL_EVALUATION_DETAILS_REQUEST:
      return {
        ...state,
        technicalEvaluationDetailsLoading: true,
      };
    case bidEvaluationsActions.GET_TECHNICAL_EVALUATION_DETAILS_SUCCESS:
      return {
        ...state,
        technicalEvaluationDetailsLoading: false,
        technicalEvaluationDetailsSuccess: actions.data,
      };
    case bidEvaluationsActions.GET_TECHNICAL_EVALUATION_DETAILS_ERROR:
      return {
        ...state,
        technicalEvaluationDetailsLoading: false,
        technicalEvaluationDetailsError: actions.error,
      };
    case bidEvaluationsActions.GET_FINANCIAL_EVALUATION_DETAILS_REQUEST:
      return {
        ...state,
        financialEvaluationDetailsLoading: true,
      };
    case bidEvaluationsActions.GET_FINANCIAL_EVALUATION_DETAILS_SUCCESS:
      return {
        ...state,
        financialEvaluationDetailsLoading: false,
        financialEvaluationDetailsSuccess: actions.data,
      };
    case bidEvaluationsActions.GET_FINANCIAL_EVALUATION_DETAILS_ERROR:
      return {
        ...state,
        financialEvaluationDetailsLoading: false,
        financialEvaluationDetailsError: actions.error,
      };
    case bidEvaluationsActions.UPDATE_BID_EVALUATION_REQUEST:
      return {
        ...state,
        updateBidEvaluationLoading: true,
      };
    case bidEvaluationsActions.UPDATE_BID_EVALUATION_SUCCESS:
      return {
        ...state,
        updateBidEvaluationLoading: false,
        updateBidEvaluationSuccess: actions.data,
      };
    case bidEvaluationsActions.UPDATE_BID_EVALUATION_ERROR:
      return {
        ...state,
        updateBidEvaluationLoading: false,
        updateBidEvaluationError: actions.error,
      };
    case bidEvaluationsActions.SET_UPDATE_BID_EVALUATION_UPDATE_DATA:
      return {
        ...state,
        updateBidEvaluationData: actions.data,
      };
    case bidEvaluationsActions.UPDATE_ELIGIBILITY_EVALUATION_REQUEST:
      return {
        ...state,
        updateEligibilityEvaluationLoading: true,
      };
    case bidEvaluationsActions.UPDATE_ELIGIBILITY_EVALUATION_SUCCESS:
      return {
        ...state,
        updateEligibilityEvaluationLoading: false,
        updateEligibilityEvaluationSuccess: actions.data,
      };
    case bidEvaluationsActions.UPDATE_ELIGIBILITY_EVALUATION_ERROR:
      return {
        ...state,
        updateEligibilityEvaluationLoading: false,
        updateEligibilityEvaluationError: actions.error,
      };
    case bidEvaluationsActions.SET_UPDATE_ELIGIBILITY_EVALUATION_UPDATE_DATA:
      return {
        ...state,
        updateEligibilityEvaluationData: actions.data,
      };
    case bidEvaluationsActions.UPDATE_TECHNICAL_EVALUATION_REQUEST:
      return {
        ...state,
        updateTechnicalEvaluationLoading: true,
      };
    case bidEvaluationsActions.UPDATE_TECHNICAL_EVALUATION_SUCCESS:
      return {
        ...state,
        updateTechnicalEvaluationLoading: false,
        updateTechnicalEvaluationSuccess: actions.data,
      };
    case bidEvaluationsActions.UPDATE_TECHNICAL_EVALUATION_ERROR:
      return {
        ...state,
        updateTechnicalEvaluationLoading: false,
        updateTechnicalEvaluationError: actions.error,
      };
    case bidEvaluationsActions.SET_UPDATE_TECHNICAL_EVALUATION_UPDATE_DATA:
      return {
        ...state,
        updateTechnicalEvaluationData: actions.data,
      };
    case bidEvaluationsActions.UPDATE_FINANCIAL_EVALUATION_REQUEST:
      return {
        ...state,
        updateTechnicalEvaluationLoading: true,
      };
    case bidEvaluationsActions.UPDATE_FINANCIAL_EVALUATION_SUCCESS:
      return {
        ...state,
        updateFinancialEvaluationLoading: false,
        updateFinancialEvaluationSuccess: actions.data,
      };
    case bidEvaluationsActions.UPDATE_FINANCIAL_EVALUATION_ERROR:
      return {
        ...state,
        updateFinancialEvaluationLoading: false,
        updateFinancialEvaluationError: actions.error,
      };
    case bidEvaluationsActions.SET_UPDATE_FINANCIAL_EVALUATION_UPDATE_DATA:
      return {
        ...state,
        updateFinancialEvaluationData: actions.data,
      };
    case bidEvaluationsActions.CHECK_BID_EVALUATION_REQUEST:
      return {
        ...state,
        checkBidEvaluationLoading: true,
      };
    case bidEvaluationsActions.CHECK_BID_EVALUATION_SUCCESS:
      return {
        ...state,
        checkBidEvaluationLoading: false,
        checkBidEvaluationSuccess: actions.data,
      };
    case bidEvaluationsActions.CHECK_BID_EVALUATION_ERROR:
      return {
        ...state,
        checkBidEvaluationLoading: false,
        checkBidEvaluationError: actions.error,
      };
    case bidEvaluationsActions.APPROVE_BID_EVALUATION_REQUEST:
      return {
        ...state,
        approveBidEvaluationLoading: true,
      };
    case bidEvaluationsActions.APPROVE_BID_EVALUATION_SUCCESS:
      return {
        ...state,
        approveBidEvaluationLoading: false,
        approveBidEvaluationSuccess: actions.data,
      };
    case bidEvaluationsActions.APPROVE_BID_EVALUATION_ERROR:
      return {
        ...state,
        approveBidEvaluationLoading: false,
        approveBidEvaluationError: actions.error,
      };
    default:
      return state;
  }
};
export default bidEvaluations;
