import { subjectsActions } from "../../../actions";
import subjectsInitialState from "../../../initialState/academics/subjects/subjects.initialState";

const subjects = (state = subjectsInitialState, action) => {
  switch (action.type) {
    case subjectsActions.GET_SUBJECTS_REQUEST:
      return {
        ...state,
        subjectsLoading: true,
      };
    case subjectsActions.GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        subjectsSuccess: action.data,
        subjectsError: {},
        subjectsLoading: false,
      };
    case subjectsActions.GET_SUBJECTS_ERROR:
      return {
        ...state,
        subjectsError: action.error,
        subjectsLoading: false,
      };
    case subjectsActions.ADD_SUBJECT_REQUEST:
      return {
        ...state,
        addSubjectLoading: true,
      };
    case subjectsActions.ADD_SUBJECT_SUCCESS:
      return {
        ...state,
        addSubjectSuccess: action.data,
        addSubjectLoading: false,
      };
    case subjectsActions.ADD_SUBJECT_ERROR:
      return {
        ...state,
        addSubjectLoading: false,
        addSubjectError: action.error,
      };
    case subjectsActions.GET_SUBJECT_DETAILS_REQUEST:
      return {
        ...state,
        getSubjectDetailsLoading: true,
      };
    case subjectsActions.GET_SUBJECT_DETAILS_SUCCESS:
      return {
        ...state,
        getSubjectDetailsSuccess: action.data,
        getSubjectDetailsError: {},
        getSubjectDetailsLoading: false,
      };
    case subjectsActions.GET_SUBJECT_DETAILS_ERROR:
      return {
        ...state,
        getSubjectDetailsError: action.error,
        getSubjectDetailsLoading: false,
      };
    case subjectsActions.GET_SUBJECTS_META_DATA_REQUEST:
      return {
        ...state,
        getSubjectsMetaDataLoading: true,
      };
    case subjectsActions.GET_SUBJECTS_META_DATA_SUCCESS:
      return {
        ...state,
        getSubjectsMetaDataSuccess: action.data,
        getSubjectsMetaDataError: {},
        getSubjectsMetaDataLoading: false,
      };
    case subjectsActions.GET_SUBJECTS_META_DATA_ERROR:
      return {
        ...state,
        getSubjectsMetaDataLoading: false,
        getSubjectsMetaDataError: action.error,
      };
    case subjectsActions.UPDATE_SUBJECT_DETAILS_REQUEST:
      return {
        ...state,
        updateSubjectDetailsLoading: true,
      };
    case subjectsActions.UPDATE_SUBJECT_DETAILS_SUCCESS:
      return {
        ...state,
        updateSubjectDetailsSuccess: action.data,
        updateSubjectDetailsLoading: false,
      };
    case subjectsActions.UPDATE_SUBJECT_DETAILS_ERROR:
      return {
        ...state,
        updateSubjectDetailsLoading: false,
        updateSubjectDetailsError: action.error,
      };
    case subjectsActions.DELETE_SUBJECT_REQUEST:
      return {
        ...state,
        deleteSubjectLoading: true,
      };
    case subjectsActions.DELETE_SUBJECT_SUCCESS:
      return {
        ...state,
        deleteSubjectSuccess: action.data,
        subjectsSuccess: {
          subjects: state.subjectsSuccess.subjects.filter((item) => {
            return item.id !== action.data.id;
          }),
        },
        deleteSubjectLoading: false,
      };
    case subjectsActions.DELETE_SUBJECT_ERROR:
      return {
        ...state,
        deleteSubjectLoading: false,
        deleteSubjectError: action.error,
      };
    case subjectsActions.CHECK_SUBJECT_REQUEST:
      return {
        ...state,
        checkSubjectLoading: true,
      };
    case subjectsActions.CHECK_SUBJECT_SUCCESS:
      return {
        ...state,
        checkSubjectSuccess: action.data,
        checkSubjectError: {},
        checkSubjectLoading: false,
      };
    case subjectsActions.CHECK_SUBJECT_ERROR:
      return {
        ...state,
        checkSubjectLoading: false,
        checkSubjectError: action.error,
      };
    case subjectsActions.APPROVE_SUBJECT_REQUEST:
      return {
        ...state,
        approveSubjectLoading: true,
      };
    case subjectsActions.APPROVE_SUBJECT_SUCCESS:
      return {
        ...state,
        approveSubjectSuccess: action.data,
        approveSubjectLoading: false,
      };
    case subjectsActions.APPROVE_SUBJECT_ERROR:
      return {
        ...state,
        approveSubjectLoading: false,
        approveSubjectError: action.error,
      };
    case subjectsActions.SET_UPDATE_SUBJECT_DETAILS_DATA:
      return {
        ...state,
        updateSubjectDetailsData: action.data,
      };
    case subjectsActions.CREATE_SUBJECTS_REQUEST:
      return {
        ...state,
        creatingSubjects: true,
        createSubjectsError: {},
      };
    case subjectsActions.CREATE_SUBJECTS_SUCCESS:
      return {
        ...state,
        creatingSubjects: false,
      };
    case subjectsActions.CREATE_SUBJECTS_ERROR:
      return {
        ...state,
        creatingSubjects: false,
        createSubjectsError: action.error,
      };
    default:
      return state;
  }
};

export default subjects;
