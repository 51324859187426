import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { appUiActions, localPurchaseOrdersActions } from "../../../actions/";

function* getLocalPurchaseOrders() {
  try {
    const data = yield axios({
      url: "procurement/local-purchase-orders/",
    });

    yield put({
      type: localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDERS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDERS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetLocalPurchaseOrders() {
  yield takeLatest(
    localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDERS_REQUEST,
    getLocalPurchaseOrders
  );
}

function* getLocalPurchaseOrderItems({ id }) {
  try {
    const data = yield axios({
      url: `procurement/local-purchase-order-items/${id}`,
    });

    yield put({
      type: localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_ITEMS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_ITEMS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetLocalPurchaseOrderItems() {
  yield takeLatest(
    localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_ITEMS_REQUEST,
    getLocalPurchaseOrderItems
  );
}

function* getLocalPurchaseOrdersMetaData() {
  try {
    const response = yield axios({
      url: "procurement/local-purchase-orders/meta-data",
    });

    yield put({
      type: localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDERS_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDERS_META_DATA_SUCCESS,
      data: {},
    });
  }
}

function* watchGetLocalPurchaseOrdersMetaData() {
  yield takeLatest(
    localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDERS_META_DATA_REQUEST,
    getLocalPurchaseOrdersMetaData
  );
}

function* getLocalPurchaseOrderItemsMetaData() {
  try {
    const response = yield axios({
      url: `procurement/local-purchase-order-items/meta-data`,
    });

    yield put({
      type: localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_ITEMS_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_ITEMS_META_DATA_SUCCESS,
      data: {},
    });
  }
}

function* watchGetLocalPurchaseOrderItemsMetaData() {
  yield takeLatest(
    localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_ITEMS_META_DATA_REQUEST,
    getLocalPurchaseOrderItemsMetaData
  );
}

function* addLocalPurchaseOrder({ data }) {
  try {
    const response = yield axios({
      url: "procurement/local-purchase-orders/",
      method: "POST",
      data,
    });

    yield put({
      type: localPurchaseOrdersActions.ADD_LOCAL_PURCHASE_ORDER_SUCCESS,
      data: response,
    });

    yield put({
      type: localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDERS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: localPurchaseOrdersActions.ADD_LOCAL_PURCHASE_ORDER_ERROR,
      error: error.data,
    });
  }
}

function* watchAddLocalPurchaseOrder() {
  yield takeLatest(
    localPurchaseOrdersActions.ADD_LOCAL_PURCHASE_ORDER_REQUEST,
    addLocalPurchaseOrder
  );
}

function* addLocalPurchaseOrderItem({ data }) {
  try {
    const response = yield axios({
      url: "procurement/local-purchase-order-items/",
      method: "POST",
      data,
    });

    yield put({
      type: localPurchaseOrdersActions.ADD_LOCAL_PURCHASE_ORDER_ITEM_SUCCESS,
      data: response,
    });

    yield put(
      yield put(
        localPurchaseOrdersActions.getLocalPurchaseOrderItems(
          data.local_purchase_order_id
        )
      )
    );
  } catch (error) {
    yield put({
      type: localPurchaseOrdersActions.ADD_LOCAL_PURCHASE_ORDER_ITEM_ERROR,
      error: error.data,
    });
  }
}

function* watchAddLocalPurchaseOrderItem() {
  yield takeLatest(
    localPurchaseOrdersActions.ADD_LOCAL_PURCHASE_ORDER_ITEM_REQUEST,
    addLocalPurchaseOrderItem
  );
}

function* deleteLocalPurchaseOrder({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `procurement/local-purchase-orders/${data}`,
      method: "DELETE",
    });

    yield put({
      type: localPurchaseOrdersActions.DELETE_LOCAL_PURCHASE_ORDER_SUCCESS,
      data: response,
    });

    yield put({
      type: localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDERS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: localPurchaseOrdersActions.DELETE_LOCAL_PURCHASE_ORDER_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteLocalPurchaseOrder() {
  yield takeLatest(
    localPurchaseOrdersActions.DELETE_LOCAL_PURCHASE_ORDER_REQUEST,
    deleteLocalPurchaseOrder
  );
}

function* deleteLocalPurchaseOrderItem({ id, lpo_id }) {
  // data in url
  try {
    const response = yield axios({
      url: `procurement/local-purchase-order-items/${id}`,
      method: "DELETE",
    });

    yield put({
      type: localPurchaseOrdersActions.DELETE_LOCAL_PURCHASE_ORDER_ITEM_SUCCESS,
      data: response,
    });

    yield put(localPurchaseOrdersActions.getLocalPurchaseOrderItems(lpo_id));
  } catch (error) {
    yield put({
      type: localPurchaseOrdersActions.DELETE_LOCAL_PURCHASE_ORDER_ITEM_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteLocalPurchaseOrderItem() {
  yield takeLatest(
    localPurchaseOrdersActions.DELETE_LOCAL_PURCHASE_ORDER_ITEM_REQUEST,
    deleteLocalPurchaseOrderItem
  );
}

function* updateLocalPurchaseOrder({ data, id }) {
  // id in url
  try {
    const response = yield axios({
      url: `procurement/local-purchase-orders/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: localPurchaseOrdersActions.UPDATE_LOCAL_PURCHASE_ORDER_SUCCESS,
      data: response,
    });

    yield put({
      type: appUiActions.TOGGLE_UPDATE_LOCAL_PURCHASE_ORDER_MODAL,
      data: false,
    });
  } catch (error) {
    yield put({
      type: localPurchaseOrdersActions.UPDATE_LOCAL_PURCHASE_ORDER_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateLocalPurchaseOrder() {
  yield takeLatest(
    localPurchaseOrdersActions.UPDATE_LOCAL_PURCHASE_ORDER_REQUEST,
    updateLocalPurchaseOrder
  );
}

function* updateLocalPurchaseOrderItem({ data, id }) {
  // id in url
  try {
    const response = yield axios({
      url: `procurement/local-purchase-order-items/${id}`,
      method: "PUT",
      data,
    });

    yield put({
      type: localPurchaseOrdersActions.UPDATE_LOCAL_PURCHASE_ORDER_ITEM_SUCCESS,
      data: response,
    });

    yield put(
      localPurchaseOrdersActions.getLocalPurchaseOrderItems(
        data?.local_purchase_order_id
      )
    );
  } catch (error) {
    yield put({
      type: localPurchaseOrdersActions.UPDATE_LOCAL_PURCHASE_ORDER_ITEM_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateLocalPurchaseOrderItem() {
  yield takeLatest(
    localPurchaseOrdersActions.UPDATE_LOCAL_PURCHASE_ORDER_ITEM_REQUEST,
    updateLocalPurchaseOrderItem
  );
}

function* getLocalPurchaseOrderDetails({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `procurement/local-purchase-orders/details/${data}`,
    });

    yield put({
      type: localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetLocalPurchaseOrderDetails() {
  yield takeLatest(
    localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_DETAILS_REQUEST,
    getLocalPurchaseOrderDetails
  );
}

function* getLocalPurchaseOrderItemDetails({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `procurement/local-purchase-order-items/details/${data}`,
    });

    yield put({
      type: localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_ITEM_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_ITEM_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetLocalPurchaseOrderItemDetails() {
  yield takeLatest(
    localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_ITEM_DETAILS_REQUEST,
    getLocalPurchaseOrderItemDetails
  );
}

function* checkLocalPurchaseOrder({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `procurement/local-purchase-orders/check/${data}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({
      type: localPurchaseOrdersActions.CHECK_LOCAL_PURCHASE_ORDER_SUCCESS,
      data: response,
    });

    yield put({
      type: localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: localPurchaseOrdersActions.CHECK_LOCAL_PURCHASE_ORDER_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckLocalPurchaseOrder() {
  yield takeLatest(
    localPurchaseOrdersActions.CHECK_LOCAL_PURCHASE_ORDER_REQUEST,
    checkLocalPurchaseOrder
  );
}

function* approveLocalPurchaseOrder({ data }) {
  // data in url
  try {
    const response = yield axios({
      url: `procurement/local-purchase-orders/approve/${data}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: localPurchaseOrdersActions.APPROVE_LOCAL_PURCHASE_ORDER_SUCCESS,
      data: response,
    });

    yield put({
      type: localPurchaseOrdersActions.GET_LOCAL_PURCHASE_ORDER_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: localPurchaseOrdersActions.APPROVE_LOCAL_PURCHASE_ORDER_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveLocalPurchaseOrder() {
  yield takeLatest(
    localPurchaseOrdersActions.APPROVE_LOCAL_PURCHASE_ORDER_REQUEST,
    approveLocalPurchaseOrder
  );
}

export default [
  fork(watchGetLocalPurchaseOrders),
  fork(watchGetLocalPurchaseOrderItems),
  fork(watchGetLocalPurchaseOrdersMetaData),
  fork(watchGetLocalPurchaseOrderItemsMetaData),
  fork(watchAddLocalPurchaseOrder),
  fork(watchAddLocalPurchaseOrderItem),
  fork(watchDeleteLocalPurchaseOrder),
  fork(watchDeleteLocalPurchaseOrderItem),
  fork(watchUpdateLocalPurchaseOrder),
  fork(watchUpdateLocalPurchaseOrderItem),
  fork(watchGetLocalPurchaseOrderDetails),
  fork(watchGetLocalPurchaseOrderItemDetails),
  fork(watchCheckLocalPurchaseOrder),
  fork(watchApproveLocalPurchaseOrder),
];
