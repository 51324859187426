const chartOfAccounts = {
  GET_CHART_OF_ACCOUNTS_REQUEST: "GET_CHART_OF_ACCOUNTS_REQUEST",
  GET_CHART_OF_ACCOUNTS_SUCCESS: "GET_CHART_OF_ACCOUNTS_SUCCCESS",
  GET_CHART_OF_ACCOUNTS_ERROR: "GET_CHART_OF_ACCOUNTS_ERROR",

  ADD_ACCOUNT_TYPE_REQUEST: "ADD_ACCOUNT_TYPE_REQUEST",
  ADD_ACCOUNT_TYPE_SUCCESS: "ADD_ACCOUNT_TYPE_SUCCESS",
  ADD_ACCOUNT_TYPE_ERROR: "ADD_ACCOUNT_TYPE_ERROR",
  SET_ADD_ACCOUNT_TYPE_DATA: "SET_ADD_ACCOUNT_TYPE_DATA",

  DELETE_ACCOUNT_TYPE_REQUEST: "DELETE_ACCOUNT_TYPE_REQUEST",
  DELETE_ACCOUNT_TYPE_SUCCESS: "DELETE_ACCOUNT_TYPE_SUCCESS",
  DELETE_ACCOUNT_TYPE_ERROR: "DELETE_ACCOUNT_TYPE_ERROR",

  SET_UPDATE_ACCOUNT_TYPE_UPDATE_DATA: "SET_UPDATE_ACCOUNT_TYPE_UPDATE_DATA",
  UPDATE_ACCOUNT_TYPE_REQUEST: "UPDATE_ACCOUNT_TYPE_REQUEST",
  UPDATE_ACCOUNT_TYPE_SUCCESS: "UPDATE_ACCOUNT_TYPE_SUCCESS",
  UPDATE_ACCOUNT_TYPE_ERROR: "UPDATE_ACCOUNT_TYPE_ERROR",

  GET_ACCOUNT_TYPE_DETAILS_REQUEST: "GET_ACCOUNT_TYPE_DETAILS_REQUEST",
  GET_ACCOUNT_TYPE_DETAILS_SUCCESS: "GET_ACCOUNT_TYPE_DETAILS_SUCCESS",
  GET_ACCOUNT_TYPE_DETAILS_ERROR: "GET_ACCOUNT_TYPE_DETAILS_ERROR",

  CHECK_ACCOUNT_TYPE_REQUEST: "CHECK_ACCOUNT_TYPE_REQUEST",
  CHECK_ACCOUNT_TYPE_SUCCESS: "CHECK_ACCOUNT_TYPE_SUCCESS",
  CHECK_ACCOUNT_TYPE_ERROR: "CHECK_ACCOUNT_TYPE_ERROR",

  APPROVE_ACCOUNT_TYPE_REQUEST: "APPROVE_ACCOUNT_TYPE_REQUEST",
  APPROVE_ACCOUNT_TYPE_SUCCESS: "APPROVE_ACCOUNT_TYPE_SUCCESS",
  APPROVE_ACCOUNT_TYPE_ERROR: "APPROVE_ACCOUNT_TYPE_ERROR",

  SET_ADD_ACCOUNT_DATA: "SET_ADD_ACCOUNT_DATA",
  ADD_ACCOUNT_REQUEST: "ADD_ACCOUNT_REQUEST",
  ADD_ACCOUNT_SUCCESS: "ADD_ACCOUNT_SUCCESS",
  ADD_ACCOUNT_ERROR: "ADD_ACCOUNT_ERROR",

  DELETE_ACCOUNT_REQUEST: "DELETE_ACCOUNT_REQUEST",
  DELETE_ACCOUNT_SUCCESS: "DELETE_ACCOUNT_SUCCESS",
  DELETE_ACCOUNT_ERROR: "DELETE_ACCOUNT_ERROR",

  SET_UPDATE_ACCOUNT_UPDATE_DATA: "SET_UPDATE_ACCOUNT_UPDATE_DATA",
  UPDATE_ACCOUNT_REQUEST: "UPDATE_ACCOUNT_REQUEST",
  UPDATE_ACCOUNT_SUCCESS: "UPDATE_ACCOUNT_SUCCESS",
  UPDATE_ACCOUNT_ERROR: "UPDATE_ACCOUNT_ERROR",

  GET_ACCOUNT_DETAILS_REQUEST: "GET_ACCOUNT_DETAILS_REQUEST",
  GET_ACCOUNT_DETAILS_SUCCESS: "GET_ACCOUNT_DETAILS_SUCCESS",
  GET_ACCOUNT_DETAILS_ERROR: "GET_ACCOUNT_DETAILS_ERROR",

  CHECK_ACCOUNT_REQUEST: "CHECK_ACCOUNT_REQUEST",
  CHECK_ACCOUNT_SUCCESS: "CHECK_ACCOUNT_SUCCESS",
  CHECK_ACCOUNT_ERROR: "CHECK_ACCOUNT_ERROR",

  APPROVE_ACCOUNT_REQUEST: "APPROVE_ACCOUNT_REQUEST",
  APPROVE_ACCOUNT_SUCCESS: "APPROVE_ACCOUNT_SUCCESS",
  APPROVE_ACCOUNT_ERROR: "APPROVE_ACCOUNT_ERROR",

  CREATE_COA_REQUEST: "CREATE_COA_REQUEST",
  CREATE_COA_SUCCESS: "CREATE_COA_SUCCESS",
  CREATE_COA_ERROR: "CREATE_COA_ERROR",

  getChartOfAccounts: () => ({
    type: chartOfAccounts.GET_CHART_OF_ACCOUNTS_REQUEST,
  }),
  createCOA: (data) => ({
    type: chartOfAccounts.CREATE_COA_REQUEST,
    data,
  }),
  addAccountType: (data) => ({
    type: chartOfAccounts.ADD_ACCOUNT_TYPE_REQUEST,
    data,
  }),
  setAddAccountTypeData: (data) => ({
    type: chartOfAccounts.SET_ADD_ACCOUNT_TYPE_DATA,
    data,
  }),
  deleteAccountType: (data) => ({
    type: chartOfAccounts.DELETE_ACCOUNT_TYPE_REQUEST,
    data,
  }),
  updateAccountType: (data, id) => ({
    type: chartOfAccounts.UPDATE_ACCOUNT_TYPE_REQUEST,
    data,
    id,
  }),
  setUpdateAccountTypeData: (data) => ({
    type: chartOfAccounts.SET_UPDATE_ACCOUNT_TYPE_UPDATE_DATA,
    data,
  }),
  getAccountTypeDetails: (data) => ({
    type: chartOfAccounts.GET_ACCOUNT_TYPE_DETAILS_REQUEST,
    data,
  }),
  checkAccountType: (data) => ({
    type: chartOfAccounts.CHECK_ACCOUNT_TYPE_REQUEST,
    data,
  }),
  approveAccountType: (data) => ({
    type: chartOfAccounts.APPROVE_ACCOUNT_TYPE_REQUEST,
    data,
  }),
  setAddAccountData: (data) => ({
    type: chartOfAccounts.SET_ADD_ACCOUNT_DATA,
    data,
  }),
  addAccount: (data) => ({
    type: chartOfAccounts.ADD_ACCOUNT_REQUEST,
    data,
  }),
  deleteAccount: (data) => ({
    type: chartOfAccounts.DELETE_ACCOUNT_REQUEST,
    data,
  }),
  updateAccount: (data, id) => ({
    type: chartOfAccounts.UPDATE_ACCOUNT_REQUEST,
    data,
    id,
  }),
  setUpdateAccountData: (data) => ({
    type: chartOfAccounts.SET_UPDATE_ACCOUNT_UPDATE_DATA,
    data,
  }),
  getAccountDetails: (data) => ({
    type: chartOfAccounts.GET_ACCOUNT_DETAILS_REQUEST,
    data,
  }),
  checkAccount: (data) => ({
    type: chartOfAccounts.CHECK_ACCOUNT_REQUEST,
    data,
  }),
  approveAccount: (data) => ({
    type: chartOfAccounts.APPROVE_ACCOUNT_REQUEST,
    data,
  }),
};

export default chartOfAccounts;
