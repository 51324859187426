import { studentReportActions } from "../../../actions";
import initialState from "../../../initialState";

const studentReports = (state = initialState.studentReports, actions) => {
  switch (actions.type) {
    case studentReportActions.SET_REPORT_DATA:
      return {
        ...state,
        reportData: actions.data,
      };
    case studentReportActions.GET_STUDENT_REPORTS_REQUEST:
      return {
        ...state,
        studentReportsLoading: true,
        studentReportsError: {},
      };
    case studentReportActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
        metaDataError: {},
      };
    case studentReportActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataLoading: false,
        metaData: actions.data,
      };
    case studentReportActions.SET_REPORT_COMMENT_TYPE:
      return {
        ...state,
        commentType: actions.data,
      };
    case studentReportActions.GET_META_DATA_ERROR:
      return {
        ...state,
        metaDataLoading: false,
        metaDataError: actions.error,
      };
    case studentReportActions.PROMOTE_LEARNERS_REQUEST:
      return {
        ...state,
        promoteLearnersLoading: true,
        promoteLearnersError: {},
      };
    case studentReportActions.PROMOTE_LEARNERS_SUCCESS:
      return {
        ...state,
        promoteLearnersLoading: false,
        promoteLearners: actions.data,
      };
    case studentReportActions.PROMOTE_LEARNERS_ERROR:
      return {
        ...state,
        promoteLearnersLoading: false,
        promoteLearnersError: actions.error,
      };
    case studentReportActions.GET_STUDENT_REPORTS_SUCCESS:
      return {
        ...state,
        studentReportsLoading: false,
        studentReportsSuccess: actions.data,
      };
    case studentReportActions.GET_STUDENT_REPORTS_ERROR:
      return {
        ...state,
        studentReportsLoading: false,
        studentReportsError: actions.error,
      };
    case studentReportActions.ADD_STUDENT_REPORTS_REQUEST:
      return {
        ...state,
        addStudentReportsLoading: true,
        addStudentReportsError: {},
      };
    case studentReportActions.ADD_STUDENT_REPORTS_SUCCESS:
      return {
        ...state,
        addStudentReportsLoading: false,
        addStudentReportsSuccess: actions.data,
      };
    case studentReportActions.ADD_STUDENT_REPORTS_ERROR:
      return {
        ...state,
        addStudentReportsLoading: false,
        addStudentReportsError: actions.error,
      };
    case studentReportActions.UPDATE_STUDENT_REPORTS_REQUEST:
      return {
        ...state,
        updateStudentReportsLoading: true,
        updateStudentReportsError: {},
      };
    case studentReportActions.UPDATE_STUDENT_REPORTS_SUCCESS:
      return {
        ...state,
        updateStudentReportsLoading: false,
        updateStudentReportsSuccess: actions.data,
      };
    case studentReportActions.UPDATE_STUDENT_REPORTS_ERROR:
      return {
        ...state,
        updateStudentReportsLoading: false,
        updateStudentReportsError: actions.error,
      };
    case studentReportActions.DELETE_STUDENT_REPORTS_REQUEST:
      return {
        ...state,
        deleteStudentReportsLoading: true,
        deleteStudentReportsError: {},
      };
    case studentReportActions.DELETE_STUDENT_REPORTS_SUCCESS:
      return {
        ...state,
        deleteStudentReportsLoading: false,
        deleteStudentReportsSuccess: actions.data,
      };
    case studentReportActions.DELETE_STUDENT_REPORTS_ERROR:
      return {
        ...state,
        deleteStudentReportsLoading: false,
        deleteStudentReportsError: actions.error,
      };
    case studentReportActions.GET_STUDENT_REPORTS_DETAILS_REQUEST:
      return {
        ...state,
        studentReportDetailsLoading: true,
        studentReportDetailsError: {},
      };
    case studentReportActions.GET_STUDENT_REPORTS_DETAILS_SUCCESS:
      return {
        ...state,
        studentReportDetailsLoading: false,
        studentReportDetailsSuccess: actions.data,
      };
    case studentReportActions.GET_STUDENT_REPORTS_DETAILS_ERROR:
      return {
        ...state,
        studentReportDetailsLoading: false,
        studentReportDetailsError: actions.error,
      };
    case studentReportActions.UPDATE_STUDENT_REPORTS_DATA:
      return {
        ...state,
        updateStudentReportsData: actions.data,
      };
    case studentReportActions.DELETE_REPORT_EXAM_REQUEST:
      return {
        ...state,
        deleteReportExamLoading: true,
        deleteReportExamError: {},
      };
    case studentReportActions.DELETE_REPORT_EXAM_SUCCESS:
      return {
        ...state,
        deleteReportExamLoading: false,
        deleteReportExamSuccess: actions.data,
      };
    case studentReportActions.DELETE_REPORT_EXAM_ERROR:
      return {
        ...state,
        deleteReportExamLoading: false,
        deleteReportExamError: actions.error,
      };
    case studentReportActions.ADD_REPORT_COMMENTS_REQUEST:
      return {
        ...state,
        addReportCommentsLoading: true,
        addReportCommentsError: {},
      };
    case studentReportActions.ADD_REPORT_COMMENTS_SUCCESS:
      return {
        ...state,
        addReportCommentsLoading: false,
        addReportCommentsSuccess: actions.data,
        reportData: {
          ...state.reportData,
          classTeacherComment: actions.data.reportComment.class_teacher_comment,
          headTeacherComment: actions.data.reportComment.head_teacher_comment,
        },
        studentReportDetailsSuccess: {
          ...state.studentReportDetailsSuccess,
          comments: [
            ...state.studentReportDetailsSuccess.comments,
            actions.data.reportComment,
          ],
          // studentReports: [
          //   ...state.studentReportDetailsSuccess.studentReports[0].studentReports.map(
          //     (report) => {
          //       if (report.id == actions.data.reportComment.student_id) {
          //         return {
          //           ...report,
          //           classTeacherComment:
          //             actions.data.reportComment.class_teacher_comment,
          //           headTeacherComment:
          //             actions.data.reportComment.head_teacher_comment,
          //         };
          //       }
          //       return report;
          //     }
          //   ),
          // ],
        },
      };

    case studentReportActions.ADD_REPORT_COMMENTS_ERROR:
      return {
        ...state,
        addReportCommentsLoading: false,
        addReportCommentsError: actions.error,
      };
    case studentReportActions.UPDATE_REPORT_COMMENTS_REQUEST:
      return {
        ...state,
        updateReportCommentsLoading: true,
        updateReportCommentsError: {},
      };
    case studentReportActions.UPDATE_REPORT_COMMENTS_SUCCESS:
      return {
        ...state,
        updateReportCommentsLoading: false,
        updateReportCommentsSuccess: actions.data,
        studentReportDetailsSuccess: {
          ...state.studentReportDetailsSuccess,
          comments: state.studentReportDetailsSuccess.comments.map(
            (comment) => {
              if (comment.student_id == state.reportComments?.student_id) {
                return {
                  ...comment,
                  class_teacher_comment:
                    state.reportComments?.class_teacher_comment,
                  head_teacher_comment:
                    state.reportComments?.head_teacher_comment,
                };
              }
              return comment;
            }
          ),
        },
      };
    case studentReportActions.UPDATE_REPORT_COMMENTS_ERROR:
      return {
        ...state,
        updateReportCommentsLoading: false,
        updateReportCommentsError: actions.error,
      };
    case studentReportActions.DELETE_REPORT_COMMENTS_REQUEST:
      return {
        ...state,
        deleteReportCommentsLoading: true,
        deleteReportCommentsError: {},
      };
    case studentReportActions.DELETE_REPORT_COMMENTS_SUCCESS:
      return {
        ...state,
        deleteReportCommentsLoading: false,
        deleteReportCommentsSuccess: actions.data,
        studentReportDetailsSuccess: {
          ...state.studentReportDetailsSuccess,
          comments: state.studentReportDetailsSuccess.comments.filter(
            (comment) => comment.student_id != state.reportComments?.student_id
          ),
        },
        reportComments: {
          ...state.reportComments,
          class_teacher_comment: "",
          head_teacher_comment: "",
        },
      };
    case studentReportActions.DELETE_REPORT_COMMENTS_ERROR:
      return {
        ...state,
        deleteReportCommentsLoading: false,
        deleteReportCommentsError: actions.error,
      };
    case studentReportActions.SET_REPORT_COMMENTS_DATA:
      return {
        ...state,
        reportComments: actions.data,
      };
    case studentReportActions.GET_LEARNER_BILLING_META_DATA_REQUEST:
      return {
        ...state,
        learnerBillingMetaDataLoading: true,
        learnerBillingMetaDataError: {},
      };
    case studentReportActions.GET_LEARNER_BILLING_META_DATA_SUCCESS:
      return {
        ...state,
        learnerBillingMetaDataLoading: false,
        learnerBillingMetaData: actions.data,
      };
    case studentReportActions.GET_LEARNER_BILLING_META_DATA_ERROR:
      return {
        ...state,
        learnerBillingMetaDataLoading: false,
        learnerBillingMetaDataError: actions.error,
      };
    case studentReportActions.GET_LEARNER_BILLING_REQUEST:
      return {
        ...state,
        learnerBillingLoading: true,
        learnerBillingError: {},
      };
    case studentReportActions.GET_LEARNER_BILLING_SUCCESS:
      return {
        ...state,
        learnerBillingLoading: false,
        learnerBillingSuccess: actions.data,
      };
    case studentReportActions.GET_LEARNER_BILLING_ERROR:
      return {
        ...state,
        learnerBillingLoading: false,
        learnerBillingError: actions.error,
      };
    case studentReportActions.CREATE_REPORT_EXCEPTION_REQUEST:
      return {
        ...state,
        createReportExceptionLoading: true,
        createReportExceptionError: {},
      };
    case studentReportActions.CREATE_REPORT_EXCEPTION_SUCCESS:
      return {
        ...state,
        createReportExceptionLoading: false,
        createReportExceptionSuccess: actions.data,
      };
    case studentReportActions.CREATE_REPORT_EXCEPTION_ERROR:
      return {
        ...state,
        createReportExceptionLoading: false,
        createReportExceptionError: actions.error,
      };
    case studentReportActions.GET_REPORT_EXCEPTIONS_REQUEST:
      return {
        ...state,
        reportExceptionsLoading: true,
        reportExceptionsError: {},
      };
    case studentReportActions.GET_REPORT_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        reportExceptionsLoading: false,
        reportExceptionsSuccess: actions.data,
      };
    case studentReportActions.GET_REPORT_EXCEPTIONS_ERROR:
      return {
        ...state,
        reportExceptionsLoading: false,
        reportExceptionsError: actions.error,
      };
    case studentReportActions.UPDATE_REPORT_EXCEPTION_REQUEST:
      return {
        ...state,
        updateReportExceptionLoading: true,
        updateReportExceptionError: {},
      };
    case studentReportActions.UPDATE_REPORT_EXCEPTION_SUCCESS:
      return {
        ...state,
        updateReportExceptionLoading: false,
        updateReportExceptionSuccess: actions.data,
      };
    case studentReportActions.UPDATE_REPORT_EXCEPTION_ERROR:
      return {
        ...state,
        updateReportExceptionLoading: false,
        updateReportExceptionError: actions.error,
      };
    case studentReportActions.SET_REPORT_EXCEPTION_DATA:
      return {
        ...state,
        setReportExceptionData: actions.data,
      };
    case studentReportActions.DELETE_REPORT_EXCEPTION_REQUEST:
      return {
        ...state,
        deleteReportExceptionLoading: true,
        deleteReportExceptionError: {},
      };
    case studentReportActions.DELETE_REPORT_EXCEPTION_SUCCESS:
      return {
        ...state,
        deleteReportExceptionLoading: false,
        deleteReportExceptionSuccess: actions.data,
      };
    case studentReportActions.DELETE_REPORT_EXCEPTION_ERROR:
      return {
        ...state,
        deleteReportExceptionLoading: false,
        deleteReportExceptionError: actions.error,
      };
    default:
      return state;
  }
};

export default studentReports;
