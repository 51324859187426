import { academicYearActions } from "../../../actions";
import initialState from "../../../initialState";

const academicYears = (state = initialState.academicYears, actions) => {
  switch (actions.type) {
    case academicYearActions.GET_ACADEMIC_YEARS_REQUEST:
      return {
        ...state,
        academicYearsLoading: true,
      };
    case academicYearActions.GET_ACADEMIC_YEARS_SUCCESS:
      return {
        ...state,
        academicYearsSuccess: actions.data,
        academicYearsLoading: false,
      };
    case academicYearActions.GET_ACADEMIC_YEARS_ERROR:
      return {
        ...state,
        academicYearsError: actions.error,
        academicYearsLoading: false,
      };
    case academicYearActions.GET_ACADEMIC_YEAR_DETAILS_REQUEST:
      return {
        ...state,
        academicYearDetailsLoading: true,
        academicYearDetailsError: {},
      };
    case academicYearActions.GET_ACADEMIC_YEAR_DETAILS_SUCCESS:
      return {
        ...state,
        academicYearDetailsSuccess: actions.data,
        academicYearDetailsLoading: false,
      };
    case academicYearActions.GET_ACADEMIC_YEAR_DETAILS_ERROR:
      return {
        ...state,
        academicYearDetailsError: actions.error,
        academicYearDetailsLoading: false,
      };
    case academicYearActions.ADD_ACADEMIC_YEAR_REQUEST:
      return {
        ...state,
        addAcademicYearsLoading: true,
      };
    case academicYearActions.ADD_ACADEMIC_YEAR_SUCCESS:
      return {
        ...state,
        addAcademicYearsSuccess: actions.data,
        addAcademicYearsLoading: false,
        academicYearsSuccess: {
          academicYears: [
            actions.data,
            ...state.academicYearsSuccess.academicYears,
          ],
        },
      };
    case academicYearActions.ADD_ACADEMIC_YEAR_ERROR:
      return {
        ...state,
        addAcademicYearsLoading: false,
        addAcademicYearsError: actions.error,
      };
    case academicYearActions.SET_UPDATE_ACADEMIC_YEAR_DATA:
      return {
        ...state,
        updateAcademicYearData: actions.data,
      };
    case academicYearActions.UPDATE_ACADEMIC_YEAR_REQUEST:
      return {
        ...state,
        updateAcademicYearLoading: true,
        updateAcademicYearError: {},
      };
    case academicYearActions.UPDATE_ACADEMIC_YEAR_SUCCESS:
      return {
        ...state,
        updateAcademicYearSuccess: actions.data,
        updateAcademicYearLoading: false,
        academicYearsSuccess: {
          academicYears: actions.academicYears,
        },
      };
    case academicYearActions.UPDATE_ACADEMIC_YEAR_ERROR:
      return {
        ...state,
        updateAcademicYearError: actions.error,
        updateAcademicYearLoading: false,
      };
    case academicYearActions.DELETE_ACADEMIC_YEAR_REQUEST:
      return {
        ...state,
        deleteAcademicYearLoading: true,
      };
    case academicYearActions.DELETE_ACADEMIC_YEAR_SUCCESS:
      return {
        ...state,
        deleteAcademicYearSuccess: actions.data,
        deleteAcademicYearLoading: false,
        academicYearsSuccess: {
          academicYears: actions.academicYears,
        },
      };
    case academicYearActions.DELETE_ACADEMIC_YEAR_ERROR:
      return {
        ...state,
        deleteAcademicYearError: actions.error,
        deleteAcademicYearLoading: false,
      };
    case academicYearActions.CHECK_ACADEMIC_YEAR_REQUEST:
      return {
        ...state,
        checkAcademicYearLoading: true,
      };
    case academicYearActions.CHECK_ACADEMIC_YEAR_ERROR:
      return {
        ...state,
        checkAcademicYearError: actions.error,
        checkAcademicYearLoading: false,
      };
    case academicYearActions.CHECK_ACADEMIC_YEAR_SUCCESS:
      return {
        ...state,
        checkAcademicYearSuccess: actions.data,
        checkAcademicYearLoading: false,
      };
    case academicYearActions.APPROVE_ACADEMIC_YEAR_REQUEST:
      return {
        ...state,
        approveAcademicYearLoading: true,
      };
    case academicYearActions.APPROVE_ACADEMIC_YEAR_SUCCESS:
      return {
        ...state,
        approveAcademicYearSuccess: actions.data,
        approveAcademicYearLoading: false,
      };
    case academicYearActions.APPROVE_ACADEMIC_YEAR_ERROR:
      return {
        ...state,
        approveAcademicYearError: actions.data,
        approveAcademicYearLoading: false,
      };
    case academicYearActions.GET_TERM_DETAILS_REQUEST:
      return {
        ...state,
        termDetailsLoading: true,
      };
    case academicYearActions.GET_TERM_DETAILS_SUCCESS:
      return {
        ...state,
        termDetailsSuccess: actions.data,
        termDetailsLoading: false,
      };
    case academicYearActions.GET_TERM_DETAILS_ERROR:
      return {
        ...state,
        termDetailsError: actions.error,
        termDetailsLoading: false,
      };
    case academicYearActions.DELETE_TERM_REQUEST:
      return {
        ...state,
        deleteTermLoading: true,
      };
    case academicYearActions.DELETE_TERM_SUCCESS:
      return {
        ...state,
        deleteTermSuccess: actions.data,
        deleteTermLoading: false,
      };
    case academicYearActions.DELETE_TERM_ERROR:
      return {
        ...state,
        deleteTermError: actions.error,
        deleteTermLoading: false,
      };
    default:
      return state;
  }
};

export default academicYears;
