const advanceAccountabilityFormsInitialState = {
  advanceAccountabilityForms: {
    advanceAccountabilityFormsSuccess: {},
    advanceAccountabilityFormsLoading: false,
    advanceAccountabilityFormsError: {},

    addAdvanceAccountabilityFormsLoading: false,
    addAdvanceAccountabilityFormsSuccess: {},
    addAdvanceAccountabilityFormsError: {},

    searchAdvanceAccountabilityFormsLoading: false,
    searchAdvanceAccountabilityFormsSuccess: {},
    searchAdvanceAccountabilityFormsError: {},

    updateAdvanceAccountabilityFormsData: {},
    updateAdvanceAccountabilityFormsLoading: false,
    updateAdvanceAccountabilityFormsSuccess: {},
    updateAdvanceAccountabilityFormsError: {},

    deleteAdvanceAccountabilityFormsLoading: false,
    deleteAdvanceAccountabilityFormsSuccess: {},
    deleteAdvanceAccountabilityFormsError: {},

    advanceAccountabilityFormDetailsLoading: false,
    advanceAccountabilityFormDetailsSuccess: {},
    advanceAccountabilityFormDetailsError: {},

    submitAdvanceAccountabilityFormLoading: false,
    submitAdvanceAccountabilityFormSuccess: {},
    submitAdvanceAccountabilityFormError: {},

    checkAdvanceAccountabilityFormLoading: false,
    checkAdvanceAccountabilityFormSuccess: {},
    checkAdvanceAccountabilityFormError: {},

    approveAdvanceAccountabilityFormLoading: false,
    approveAdvanceAccountabilityFormSuccess: {},
    approveAdvanceAccountabilityFormError: {},

    addActualExpenditureLoading: false,
    addActualExpenditureSuccess: {},
    addActualExpenditureError: {},

    updateActualExpenditureData: {},
    updateActualExpenditureLoading: false,
    updateActualExpenditureSuccess: {},
    updateActualExpenditureError: {},

    deleteActualExpenditureLoading: false,
    deleteActualExpenditureSuccess: {},
    deleteActualExpenditureError: {},

    actualExpenditureDetailsLoading: false,
    actualExpenditureDetailsSuccess: {},
    actualExpenditureDetailsError: {},

    metaData: {},
    metaDataLoading: false,

    uploadAdvanceAccountabilityDocumentsLoading: false,
    uploadAdvanceAccountabilityDocumentsSuccess: {},
    uploadAdvanceAccountabilityDocumentsError: {},

    sortAdvanceAccountabilityFormsLoading: false,
    sortAdvanceAccountabilityFormsSuccess: {},
    sortAdvanceAccountabilityFormsError: {},

    downloadAdvanceAccountabilityFormsLoading: false,
    downloadAdvanceAccountabilityFormsSuccess: {},
    downloadAdvanceAccountabilityFormsError: {},

    sortQuery: {},
  },
};

export default advanceAccountabilityFormsInitialState;
