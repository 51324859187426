import { paymentVouchersActions } from "../../../actions";
import paymentVouchersInitialState from "../../../initialState/finance/paymentVouchers/paymentVouchers.initialState";

const paymentVouchers = (state = paymentVouchersInitialState, action) => {
  switch (action.type) {
    case paymentVouchersActions.GET_PAYMENT_VOUCHERS_REQUEST:
      return {
        ...state,
        paymentVouchersLoading: true,
      };
    case paymentVouchersActions.GET_PAYMENT_VOUCHERS_SUCCESS:
      return {
        ...state,
        paymentVouchersSuccess: action.data,
        paymentVouchersError: {},
        paymentVouchersLoading: false,
      };
    case paymentVouchersActions.GET_PAYMENT_VOUCHERS_ERROR:
      return {
        ...state,
        paymentVouchersLoading: false,
        paymentVouchersSuccess: action.error,
      };
    case paymentVouchersActions.ADD_PAYMENT_VOUCHER_REQUEST:
      return {
        ...state,
        addPaymentVoucherLoading: true,
        addPaymentVoucherError: {},
      };
    case paymentVouchersActions.ADD_PAYMENT_VOUCHER_SUCCESS:
      return {
        ...state,
        addPaymentVoucherSuccess: action.data,
        addPaymentVoucherLoading: false,
      };
    case paymentVouchersActions.ADD_PAYMENT_VOUCHER_ERROR:
      return {
        ...state,
        addPaymentVoucherLoading: false,
        addPaymentVoucherError: action.error,
      };
    case paymentVouchersActions.GET_PAYMENT_VOUCHER_DETAILS_REQUEST:
      return {
        ...state,
        getPaymentVoucherDetailsLoading: true,
      };
    case paymentVouchersActions.GET_PAYMENT_VOUCHER_DETAILS_SUCCESS:
      return {
        ...state,
        getPaymentVoucherDetailsSuccess: action.data,
        getPaymentVoucherDetailsError: {},
        getPaymentVoucherDetailsLoading: false,
      };
    case paymentVouchersActions.GET_PAYMENT_VOUCHER_DETAILS_ERROR:
      return {
        ...state,
        getPaymentVoucherDetailsLoading: false,
        getPaymentVoucherDetailsSuccess: action.error,
      };
    case paymentVouchersActions.UPDATE_PAYMENT_VOUCHER_REQUEST:
      return {
        ...state,
        updatePaymentVoucherDetailsLoading: true,
        updatePaymentVoucherDetailsError: {},
      };
    case paymentVouchersActions.UPDATE_PAYMENT_VOUCHER_SUCCESS:
      return {
        ...state,
        updatePaymentVoucherDetailsSuccess: action.data,
        paymentVouchersSuccess: {
          ...state.paymentVouchersSuccess,
          paymentVouchers: action.paymentVouchers,
        },
        updatePaymentVoucherDetailsLoading: false,
      };
    case paymentVouchersActions.UPDATE_PAYMENT_VOUCHER_ERROR:
      return {
        ...state,
        updatePaymentVoucherDetailsLoading: false,
        updatePaymentVoucherDetailsSuccess: action.error,
      };
    case paymentVouchersActions.DELETE_PAYMENT_VOUCHER_REQUEST:
      return {
        ...state,
        deletePaymentVoucherLoading: true,
        deletePaymentVoucherError: {},
      };
    case paymentVouchersActions.DELETE_PAYMENT_VOUCHER_SUCCESS:
      return {
        ...state,
        deletePaymentVoucherSuccess: action.data,
        paymentVouchersSuccess: {
          ...state.paymentVouchersSuccess,
          paymentVouchers: action.paymentVouchers,
        },
        deletePaymentVoucherLoading: false,
      };
    case paymentVouchersActions.DELETE_PAYMENT_VOUCHER_ERROR:
      return {
        ...state,
        deletePaymentVoucherLoading: false,
        deletePaymentVoucherSuccess: action.error,
      };
    case paymentVouchersActions.GET_PAYMENT_VOUCHERS_META_DATA_REQUEST:
      return {
        ...state,
        getPaymentVouchersMetaDataLoading: true,
      };
    case paymentVouchersActions.GET_PAYMENT_VOUCHERS_META_DATA_SUCCESS:
      return {
        ...state,
        getPaymentVouchersMetaDataSuccess: action.data,
        getPaymentVouchersMetaDataError: {},
        getPaymentVouchersMetaDataLoading: false,
      };
    case paymentVouchersActions.GET_PAYMENT_VOUCHERS_META_DATA_ERROR:
      return {
        ...state,
        getPaymentVouchersMetaDataLoading: false,
        getPaymentVouchersMetaDataSuccess: action.error,
      };
    case paymentVouchersActions.SEARCH_PAYMENT_VOUCHERS_REQUEST:
      return {
        ...state,
        searchPaymentVouchersLoading: true,
        searchPaymentVouchersError: {},
      };
    case paymentVouchersActions.SEARCH_PAYMENT_VOUCHERS_SUCCESS:
      return {
        ...state,
        searchPaymentVouchersSuccess: action.data,
        searchPaymentVouchersError: {},
        searchPaymentVouchersLoading: false,
      };
    case paymentVouchersActions.SEARCH_PAYMENT_VOUCHERS_ERROR:
      return {
        ...state,
        searchPaymentVouchersLoading: false,
        searchPaymentVouchersSuccess: action.error,
      };
    case paymentVouchersActions.SET_UPDATE_PAYMENT_VOUCHER_DETAILS:
      return {
        ...state,
        updatePaymentVoucherDetails: action.data,
      };
    case paymentVouchersActions.CHECK_PAYMENT_VOUCHER_REQUEST:
      return {
        ...state,
        checkPaymentVoucherLoading: true,
        checkPaymentVoucherError: {},
      };
    case paymentVouchersActions.CHECK_PAYMENT_VOUCHER_SUCCESS:
      return {
        ...state,
        checkPaymentVoucherSuccess: action.data,
        checkPaymentVoucherLoading: false,
      };
    case paymentVouchersActions.CHECK_PAYMENT_VOUCHER_ERROR:
      return {
        ...state,
        checkPaymentVoucherLoading: false,
        checkPaymentVoucherSuccess: action.error,
      };

    case paymentVouchersActions.APPROVE_PAYMENT_VOUCHER_REQUEST:
      return {
        ...state,
        approvePaymentVoucherLoading: true,
        approvePaymentVoucherError: {},
      };
    case paymentVouchersActions.APPROVE_PAYMENT_VOUCHER_SUCCESS:
      return {
        ...state,
        approvePaymentVoucherSuccess: action.data,
        approvePaymentVoucherLoading: false,
      };
    case paymentVouchersActions.APPROVE_PAYMENT_VOUCHER_ERROR:
      return {
        ...state,
        approvePaymentVoucherLoading: false,
        approvePaymentVoucherSuccess: action.error,
      };
    case paymentVouchersActions.SORT_PAYMENT_VOUCHERS_REQUEST:
      return {
        ...state,
        sortPaymentVouchersLoading: true,
        sortPaymentVouchersError: {},
      };
    case paymentVouchersActions.SORT_PAYMENT_VOUCHERS_SUCCESS:
      return {
        ...state,
        sortPaymentVouchersSuccess: action.data,
        sortPaymentVouchersLoading: false,
      };
    case paymentVouchersActions.SORT_PAYMENT_VOUCHERS_ERROR:
      return {
        ...state,
        sortPaymentVouchersLoading: false,
        sortPaymentVouchersSuccess: action.error,
      };
    case paymentVouchersActions.DOWNLOAD_PAYMENT_VOUCHERS_REQUEST:
      return {
        ...state,
        downloadPaymentVouchersLoading: true,
        downloadPaymentVouchersError: {},
      };
    case paymentVouchersActions.DOWNLOAD_PAYMENT_VOUCHERS_SUCCESS:
      return {
        ...state,
        downloadPaymentVouchersSuccess: action.data,
        downloadPaymentVouchersLoading: false,
      };
    case paymentVouchersActions.DOWNLOAD_PAYMENT_VOUCHERS_ERROR:
      return {
        ...state,
        downloadPaymentVouchersLoading: false,
        downloadPaymentVouchersSuccess: action.error,
      };
    case paymentVouchersActions.SET_SORT_QUERY:
      return {
        ...state,
        sortQuery: action.data,
      };
    default:
      return state;
  }
};

export default paymentVouchers;
