const noticesOfBestBiddersInitialState = {
  noticesOfBestBidders: {
    noticesOfBestBiddersLoading: false,
    noticesOfBestBiddersSuccess: {},
    noticesOfBestBiddersError: {},

    metaDataLoading: false,
    metaDataSuccess: {},

    addNoticeOfBestBidderData: {},
    addNoticeOfBestBidderLoading: false,
    addNoticeOfBestBidderSuccess: {},
    addNoticeOfBestBidderError: {},

    deleteNoticeOfBestBidderLoading: false,
    deleteNoticeOfBestBidderSuccess: {},
    deleteNoticeOfBestBidderError: {},

    updateNoticeOfBestBidderData: {},
    updateNoticeOfBestBidderLoading: false,
    updateNoticeOfBestBidderSuccess: {},
    updateNoticeOfBestBidderError: {},

    noticeOfBestBidderDetailsLoading: false,
    noticeOfBestBidderDetailsSuccess: {},
    noticeOfBestBidderDetailsError: {},

    checkNoticeOfBestBidderLoading: false,
    checkNoticeOfBestBidderSuccess: {},
    checkNoticeOfBestBidderError: {},

    approveNoticeOfBestBidderLoading: false,
    approveNoticeOfBestBidderSuccess: {},
    approveNoticeOfBestBidderError: {},
  },
};

export default noticesOfBestBiddersInitialState;
