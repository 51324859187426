const schoolDevelopmentPlanActions = {
  GET_SCHOOL_DEVELOPMENT_PLAN_REQUEST: "GET_SCHOOL_DEVELOPMENT_PLAN_REQUEST",
  GET_SCHOOL_DEVELOPMENT_PLAN_SUCCESS: "GET_SCHOOL_DEVELOPMENT_PLAN_SUCCESS",
  GET_SCHOOL_DEVELOPMENT_PLAN_ERROR: "GET_SCHOOL_DEVELOPMENT_PLAN_ERROR",

  ADD_SCHOOL_DEVELOPMENT_PLAN_REQUEST: "ADD_SCHOOL_DEVELOPMENT_PLAN_REQUEST",
  ADD_SCHOOL_DEVELOPMENT_PLAN_SUCCESS: "ADD_SCHOOL_DEVELOPMENT_PLAN_SUCCESS",
  ADD_SCHOOL_DEVELOPMENT_PLAN_ERROR: "ADD_SCHOOL_DEVELOPMENT_PLAN_ERROR",

  UPDATE_SCHOOL_DEVELOPMENT_PLAN_REQUEST:
    "UPDATE_SCHOOL_DEVELOPMENT_PLAN_REQUEST",
  UPDATE_SCHOOL_DEVELOPMENT_PLAN_SUCCESS:
    "UPDATE_SCHOOL_DEVELOPMENT_PLAN_SUCCESS",
  UPDATE_SCHOOL_DEVELOPMENT_PLAN_ERROR: "UPDATE_SCHOOL_DEVELOPMENT_PLAN_ERROR",

  DELETE_SCHOOL_DEVELOPMENT_PLAN_REQUEST:
    "DELETE_SCHOOL_DEVELOPMENT_PLAN_REQUEST",
  DELETE_SCHOOL_DEVELOPMENT_PLAN_SUCCESS:
    "DELETE_SCHOOL_DEVELOPMENT_PLAN_SUCCESS",
  DELETE_SCHOOL_DEVELOPMENT_PLAN_ERROR: "DELETE_SCHOOL_DEVELOPMENT_PLAN_ERROR",

  SET_UPDATE_SCHOOL_DEVELOPMENT_PLAN_DATA:
    "SET_UPDATE_SCHOOL_DEVELOPMENT_PLAN_DATA",

  GET_SCHOOL_DEVELOPMENT_PLAN_META_DATA_REQUEST:
    "GET_SCHOOL_DEVELOPMENT_PLAN_META_DATA_REQUEST",
  GET_SCHOOL_DEVELOPMENT_PLAN_META_DATA_SUCCESS:
    "GET_SCHOOL_DEVELOPMENT_PLAN_META_DATA_SUCCESS",
  GET_SCHOOL_DEVELOPMENT_PLAN_META_DATA_ERROR:
    "GET_SCHOOL_DEVELOPMENT_PLAN_META_DATA_ERROR",

  GET_SCHOOL_DEVELOPMENT_PLAN_DETAILS_REQUEST:
    "GET_SCHOOL_DEVELOPMENT_PLAN_DETAILS_REQUEST",
  GET_SCHOOL_DEVELOPMENT_PLAN_DETAILS_SUCCESS:
    "GET_SCHOOL_DEVELOPMENT_PLAN_DETAILS_SUCCESS",
  GET_SCHOOL_DEVELOPMENT_PLAN_DETAILS_ERROR:
    "GET_SCHOOL_DEVELOPMENT_PLAN_DETAILS_ERROR",

  ADD_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_REQUEST:
    "ADD_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_REQUEST",
  ADD_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_SUCCESS:
    "ADD_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_SUCCESS",
  ADD_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_ERROR:
    "ADD_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_ERROR",

  UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_REQUEST:
    "UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_REQUEST",
  UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_SUCCESS:
    "UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_SUCCESS",
  UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_ERROR:
    "UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_ERROR",

  DELETE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_REQUEST:
    "DELETE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_REQUEST",
  DELETE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_SUCCESS:
    "DELETE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_SUCCESS",
  DELETE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_ERROR:
    "DELETE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_ERROR",

  SET_UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_DATA:
    "SET_UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_DATA",

  CHECK_SCHOOL_DEVELOPMENT_PLAN_REQUEST:
    "CHECK_SCHOOL_DEVELOPMENT_PLAN_REQUEST",
  CHECK_SCHOOL_DEVELOPMENT_PLAN_SUCCESS:
    "CHECK_SCHOOL_DEVELOPMENT_PLAN_SUCCESS",
  CHECK_SCHOOL_DEVELOPMENT_PLAN_ERROR: "CHECK_SCHOOL_DEVELOPMENT_PLAN_ERROR",

  APPROVE_SCHOOL_DEVELOPMENT_PLAN_REQUEST:
    "APPROVE_SCHOOL_DEVELOPMENT_PLAN_REQUEST",
  APPROVE_SCHOOL_DEVELOPMENT_PLAN_SUCCESS:
    "APPROVE_SCHOOL_DEVELOPMENT_PLAN_SUCCESS",
  APPROVE_SCHOOL_DEVELOPMENT_PLAN_ERROR:
    "APPROVE_SCHOOL_DEVELOPMENT_PLAN_ERROR",

  getSchoolDevelopmentPlan: () => ({
    type: schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_REQUEST,
  }),

  getMetaData: () => ({
    type: schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_META_DATA_REQUEST,
  }),

  getSchoolDevelopmentPlanDetails: (id) => ({
    type: schoolDevelopmentPlanActions.GET_SCHOOL_DEVELOPMENT_PLAN_DETAILS_REQUEST,
    id,
  }),

  addSchoolDevelopmentPlan: (data) => ({
    type: schoolDevelopmentPlanActions.ADD_SCHOOL_DEVELOPMENT_PLAN_REQUEST,
    data,
  }),

  updateSchoolDevelopmentPlan: (id, data) => ({
    type: schoolDevelopmentPlanActions.UPDATE_SCHOOL_DEVELOPMENT_PLAN_REQUEST,
    id,
    data,
  }),

  deleteSchoolDevelopmentPlan: (id) => ({
    type: schoolDevelopmentPlanActions.DELETE_SCHOOL_DEVELOPMENT_PLAN_REQUEST,
    id,
  }),

  setUpdateSchoolDevelopmentPlanData: (data) => ({
    type: schoolDevelopmentPlanActions.SET_UPDATE_SCHOOL_DEVELOPMENT_PLAN_DATA,
    data,
  }),

  addSchoolDevelopmentPlanExpenditure: (id, data) => ({
    type: schoolDevelopmentPlanActions.ADD_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_REQUEST,
    id,
    data,
  }),

  updateSchoolDevelopmentPlanExpenditure: (id, data) => ({
    type: schoolDevelopmentPlanActions.UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_REQUEST,
    id,
    data,
  }),

  deleteSchoolDevelopmentPlanExpenditure: (id, plan_id) => ({
    type: schoolDevelopmentPlanActions.DELETE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_REQUEST,
    id,
    plan_id,
  }),

  setUpdateSchoolDevelopmentPlanExpenditureData: (data) => ({
    type: schoolDevelopmentPlanActions.SET_UPDATE_SCHOOL_DEVELOPMENT_PLAN_EXPENDITURE_DATA,
    data,
  }),

  checkSchoolDevelopmentPlan: (id) => ({
    type: schoolDevelopmentPlanActions.CHECK_SCHOOL_DEVELOPMENT_PLAN_REQUEST,
    id,
  }),

  approveSchoolDevelopmentPlan: (id) => ({
    type: schoolDevelopmentPlanActions.APPROVE_SCHOOL_DEVELOPMENT_PLAN_REQUEST,
    id,
  }),
};

export default schoolDevelopmentPlanActions;
