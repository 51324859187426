const recordOfBiddersInitialState = {
  recordOfBiddersLoading: false,
  recordOfBiddersSuccess: {},
  recordOfBiddersError: {},

  recordOfBiddersMetaDataLoading: false,
  recordOfBiddersMetaDataSuccess: {},
  recordOfBiddersMetaDataError: {},

  addRecordOfBiddersLoading: false,
  addRecordOfBiddersSuccess: {},
  addRecordOfBiddersError: {},

  recordOfBiddersDetailsLoading: false,
  recordOfBiddersDetailsSuccess: {},
  recordOfBiddersDetailsError: {},

  updateRecordOfBiddersDetailsLoading: false,
  updateRecordOfBiddersDetailsSuccess: {},
  updateRecordOfBiddersDetailsError: {},
  updateRecordOfBiddersDetailsData: {},

  deleteRecordOfBiddersLoading: false,
  deleteRecordOfBiddersSuccess: {},
  deleteRecordOfBiddersError: {},

  checkRecordOfBiddersLoading: false,
  checkRecordOfBiddersSuccess: {},
  checkRecordOfBiddersError: {},

  approveRecordOfBiddersLoading: false,
  approveRecordOfBiddersSuccess: {},
  approveRecordOfBiddersError: {},

  // Bidders initial state
  biddersLoading: false,
  biddersSuccess: {},
  biddersError: {},

  biddersMetaDataLoading: false,
  biddersMetaDataSuccess: {},
  biddersMetaDataError: {},

  addBidderLoading: false,
  addBidderSuccess: {},
  addBidderError: {},

  bidderDetailsLoading: false,
  bidderDetailsSuccess: {},
  bidderDetailsError: {},

  updateBidderDetailsLoading: false,
  updateBidderDetailsSuccess: {},
  updateBidderDetailsError: {},
  updateBidderDetailsData: {},

  deleteBidderLoading: false,
  deleteBidderSuccess: {},
  deleteBidderError: {},
};

export default recordOfBiddersInitialState;
