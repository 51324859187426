const learnerIndisciplineActions = {
  GET_INDISCIPLINE_CASES_REQUEST: "GET_INDISCIPLINE_CASES_REQUEST",
  GET_INDISCIPLINE_CASES_SUCCESS: "GET_INDISCIPLINE_CASES_SUCCESS",
  GET_INDISCIPLINE_CASES_ERROR: "GET_INDISCIPLINE_CASES_ERROR",

  GET_INDISCIPLINE_CASES_META_DATA_REQUEST:
    "GET_INDISCIPLINE_CASES_META_DATA_REQUEST",
  GET_INDISCIPLINE_CASES_META_DATA_SUCCESS:
    "GET_INDISCIPLINE_CASES_META_DATA_SUCCESS",
  GET_INDISCIPLINE_CASES_META_DATA_ERROR:
    "GET_INDISCIPLINE_CASES_META_DATA_ERROR",

  ADD_INDISCIPLINE_CASE_REQUEST: "ADD_INDISCIPLINE_CASE_REQUEST",
  ADD_INDISCIPLINE_CASE_SUCCESS: "ADD_INDISCIPLINE_CASE_SUCCESS",
  ADD_INDISCIPLINE_CASE_ERROR: "ADD_INDISCIPLINE_CASE_ERROR",

  UPDATE_INDISCIPLINE_CASE_REQUEST: "UPDATE_INDISCIPLINE_CASE_REQUEST",
  UPDATE_INDISCIPLINE_CASE_SUCCESS: "UPDATE_INDISCIPLINE_CASE_SUCCESS",
  UPDATE_INDISCIPLINE_CASE_ERROR: "UPDATE_INDISCIPLINE_CASE_ERROR",

  DELETE_INDISCIPLINE_CASE_REQUEST: "DELETE_INDISCIPLINE_CASE_REQUEST",
  DELETE_INDISCIPLINE_CASE_SUCCESS: "DELETE_INDISCIPLINE_CASE_SUCCESS",
  DELETE_INDISCIPLINE_CASE_ERROR: "DELETE_INDISCIPLINE_CASE_ERROR",

  GET_INDISCIPLINE_CASE_DETAILS_REQUEST:
    "GET_INDISCIPLINE_CASE_DETAILS_REQUEST",
  GET_INDISCIPLINE_CASE_DETAILS_SUCCESS:
    "GET_INDISCIPLINE_CASE_DETAILS_SUCCESS",
  GET_INDISCIPLINE_CASE_DETAILS_ERROR: "GET_INDISCIPLINE_CASE_DETAILS_ERROR",

  SET_INDISCIPLINE_CASE_DATA: "SET_INDISCIPLINE_CASE_DATA",

  getIndisciplineCases: () => ({
    type: learnerIndisciplineActions.GET_INDISCIPLINE_CASES_REQUEST,
  }),

  getIndisciplineCasesMetaData: () => ({
    type: learnerIndisciplineActions.GET_INDISCIPLINE_CASES_META_DATA_REQUEST,
  }),

  addIndisciplineCase: (data, isLearnerProfile) => ({
    type: learnerIndisciplineActions.ADD_INDISCIPLINE_CASE_REQUEST,
    data,
    isLearnerProfile,
  }),

  updateIndisciplineCase: (data, id, isLearnerProfile) => ({
    type: learnerIndisciplineActions.UPDATE_INDISCIPLINE_CASE_REQUEST,
    data,
    id,
    isLearnerProfile,
  }),

  deleteIndisciplineCase: (id, learner_id) => ({
    type: learnerIndisciplineActions.DELETE_INDISCIPLINE_CASE_REQUEST,
    id,
    learner_id,
  }),

  getIndisciplineCaseDetails: (id) => ({
    type: learnerIndisciplineActions.GET_INDISCIPLINE_CASE_DETAILS_REQUEST,
    id,
  }),

  setIndisciplineCaseData: (data) => ({
    type: learnerIndisciplineActions.SET_INDISCIPLINE_CASE_DATA,
    data,
  }),
};

export default learnerIndisciplineActions;
