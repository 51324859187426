const transferInstitution = {
    TRANSFER_INSTITUTION_REQUEST: 'TRANSFER_INSTITUTION_REQUEST',
    TRANSFER_INSTITUTION_SUCCESS: 'TRANSFER_INSTITUTION_SUCCESS',
    TRANSFER_INSTITUTION_ERROR: 'TRANSFER_INSTITUTION_ERROR',

    GET_TRANSFER_INSTITUTION_META_DATA_REQUEST: 'GET_TRANSFER_INSTITUTION_META_DATA_REQUEST',
    GET_TRANSFER_INSTITUTION_META_DATA_SUCCESS: 'GET_TRANSFER_INSTITUTION_META_DATA_SUCCESS',
    GET_TRANSFER_INSTITUTION_META_DATA_ERROR: 'GET_TRANSFER_INSTITUTION_META_DATA_ERROR',

    transferInstitution: (data) => ({
        type: transferInstitution.TRANSFER_INSTITUTION_REQUEST,
        data,
    }),
    getTransferInstitutionMetaData: () => ({
        type: transferInstitution.GET_TRANSFER_INSTITUTION_META_DATA_REQUEST,
    }),
}

export default transferInstitution