import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { incomeStatementActions } from "../../../actions";

function* getIncomeStatement({ data }) {
  try {
    const response = yield axios({
      // url: "/finance/financial-reports/income-statement",
      url: "/finance/financial-reports/accrual-income-statement",
      params: data,
    });

    yield put({
      type: incomeStatementActions.GET_INCOME_STATEMENT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: incomeStatementActions.GET_INCOME_STATEMENT_ERROR,
      error: error.data,
    });
  }
}

function* watchGetIncomeStatement() {
  yield takeLatest(
    incomeStatementActions.GET_INCOME_STATEMENT_REQUEST,
    getIncomeStatement
  );
}

function* getMetaData() {
  try {
    const response = yield axios({
      url: "/finance/financial-reports/meta-data",
    });

    yield put({
      type: incomeStatementActions?.GET_INCOME_STATEMENT_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: incomeStatementActions.GET_INCOME_STATEMENT_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(
    incomeStatementActions.GET_INCOME_STATEMENT_META_DATA_REQUEST,
    getMetaData
  );
}

function* getBalanceSheet({ params }) {
  try {
    const response = yield axios({
      url: "/finance/financial-reports/accrual-balance-sheet",
      params,
    });

    yield put({
      type: incomeStatementActions.GET_BALANCE_SHEET_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: incomeStatementActions.GET_BALANCE_SHEET_ERROR,
      error: error.data,
    });
  }
}

function* watchGetBalanceSheet() {
  yield takeLatest(
    incomeStatementActions.GET_BALANCE_SHEET_REQUEST,
    getBalanceSheet
  );
}

export default [
  fork(watchGetIncomeStatement),
  fork(watchGetMetaData),
  fork(watchGetBalanceSheet),
];
