const timetableInitialState = {
  timetable: {
    timetables: [],
    timetableLoading: false,
    timetableError: {},

    addTimetableLoading: false,
    addTimetableError: {},
    addTimetableSuccess: {},

    updateTimetableLoading: false,
    updateTimetableError: {},
    updateTimetableSuccess: {},
    updateTimetableData: {},

    deleteTimetableLoading: false,
    deleteTimetableError: {},
    deleteTimetableSuccess: {},

    timetableDetails: {},
    timetableDetailsLoading: false,
    timetableDetailsError: {},

    addTimetableActivityLoading: false,
    addTimetableActivityError: {},
    addTimetableActivitySuccess: {},

    updateTimetableActivityLoading: false,
    updateTimetableActivityError: {},
    updateTimetableActivitySuccess: {},
    updateTimetableActivityData: {},

    deleteTimetableActivityLoading: false,
    deleteTimetableActivityError: {},
    deleteTimetableActivitySuccess: {},

    metaData: {},
    metaDataLoading: false,
  },
};

export default timetableInitialState;
