import { organisationActions } from "../../actions";
import initialState from "../../initialState";

const organisaitons = (state = initialState.organisations, actions) => {
  switch (actions.type) {
    case organisationActions.GET_ORGANISATIONS_REQUEST:
      return {
        ...state,
        gettingOrganisations: true,
      };
    case organisationActions.GET_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        gettingOrganisations: false,
        organisationsError: {},
        organisationsSuccess: actions.data,
      };
    case organisationActions.GET_ORGANISATIONS_ERROR:
      return {
        ...state,
        gettingOrganisations: false,
        organisationsError: actions.error,
      };

    case organisationActions.ADD_ORGANISATION_REQUEST:
      return {
        ...state,
        addOrganisationLoading: true,
        addOrganisationError: {},
        addOrganisationSuccess: {},
      };
    case organisationActions.ADD_ORGANISATION_SUCCESS:
      return {
        ...state,
        addOrganisationLoading: false,
        addOrganisationSuccess: actions.data,
        addOrganisationError: {},
      };
    case organisationActions.ADD_ORGANISATION_ERROR:
      return {
        ...state,
        addOrganisationLoading: false,
        addOrganisationSuccess: {},
        addOrganisationError: actions.error,
      };
    case organisationActions.UPDATE_ORGANISATION_NAME_REQUEST:
      return {
        ...state,
        updateOrganisationNameLoading: true,
        updateOrganisationNameError: {},
        updateOrganisationNameSuccess: {},
      };
    case organisationActions.UPDATE_ORGANISATION_NAME_SUCCESS:
      return {
        ...state,
        updateOrganisationNameLoading: false,
        updateOrganisationNameSuccess: actions.data,
        updateOrganisationNameError: {},
      };
    case organisationActions.UPDATE_ORGANISATION_NAME_ERROR:
      return {
        ...state,
        updateOrganisationNameLoading: false,
        updateOrganisationNameSuccess: {},
        updateOrganisationNameError: actions.error,
      };
    case organisationActions.SET_UPDATE_ORGANISATION_NAME_DATA:
      return {
        ...state,
        setUpdateOrganisationNameData: actions.data,
      };
    default:
      return state;
  }
};

export default organisaitons;
