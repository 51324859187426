const enrollmentInitialState = {
  enrollmentsLoading: false,
  enrollmentsSuccess: {},
  enrollmentsError: {},

  getEnrollmentMetaDataLoading: false,
  getEnrollmentMetaDataSuccess: {},
  getEnrollmentMetaDataError: {},

  getEnrollmentDetailsLoading: false,
  getEnrollmentDetailsSuccess: {},
  getEnrollmentDetailsError: {},

  addEnrollmentLoading: false,
  addEnrollmentSuccess: {},
  addEnrollmentError: {},

  enrollStudentsLoading: false,
  enrollStudentsSuccess: {},
  enrollStudentsError: {},

  deleteEnrollmentLoading: false,
  deleteEnrollmentSuccess: {},
  deleteEnrollmentError: {},

  unenrollStudentLoading: false,
  unenrollStudentSuccess: {},
  unenrollStudentError: {},

  uploadEnrollmentDocumentLoading: false,
  uploadEnrollmentDocumentSuccess: {},
  uploadEnrollmentDocumentError: {},

  billStudentsLoading: false,
  billStudentsSuccess: {},
  billStudentsError: {},

  deleteBillingLoading: false,
  deleteBillingSuccess: {},
  deleteBillingError: {},

  deEnrollLearner: {},
  deEnrollLearnerLoading: false,
  deEnrollLearnerError: {},

  updateEnrollmentLoading: false,
  updateEnrollmentSuccess: {},
  updateEnrollmentError: {},
  updateEnrollmentData: {},

  enrollSingleStudentLoading: false,
  enrollSingleStudentSuccess: {},
  enrollSingleStudentError: {},

  searchStudentsLoading: false,
  searchStudentsSuccess: {},
  searchStudentsError: {},

  downloadLearnerDetailsExcel: {},
  downloadLearnerDetailsExcelLoading: false,
  downloadLearnerDetailsExcelError: {},

  joiningClasses: false,
  joiningClassesError: {},
  joiningClassesSuccess: {},
  enrollmentAcademicYear: {},

  learnerProfile: {},
  learnerProfileLoading: false,
  learnerProfileError: {},
};

export default enrollmentInitialState;
