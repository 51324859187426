const schemeOfWork = {
  GET_SCHEMES_OF_WORK_REQUEST: "GET_SCHEMES_OF_WORK_REQUEST",
  GET_SCHEMES_OF_WORK_SUCCESS: "GET_SCHEMES_OF_WORK_SUCCESS",
  GET_SCHEMES_OF_WORK_ERROR: "GET_SCHEMES_OF_WORK_ERROR",

  GET_SCHEME_OF_WORK_DETAILS_REQUEST: "GET_SCHEME_OF_WORK_DETAILS_REQUEST",
  GET_SCHEME_OF_WORK_DETAILS_SUCCESS: "GET_SCHEME_OF_WORK_DETAILS_SUCCESS",
  GET_SCHEME_OF_WORK_DETAILS_ERROR: "GET_SCHEME_OF_WORK_DETAILS_ERROR",

  ADD_SCHEME_OF_WORK_REQUEST: "ADD_SCHEME_OF_WORK_REQUEST",
  ADD_SCHEME_OF_WORK_SUCCESS: "ADD_SCHEME_OF_WORK_SUCCESS",
  ADD_SCHEME_OF_WORK_ERROR: "ADD_SCHEME_OF_WORK_ERROR",

  UPDATE_SCHEME_OF_WORK_REQUEST: "UPDATE_SCHEME_OF_WORK_REQUEST",
  UPDATE_SCHEME_OF_WORK_SUCCESS: "UPDATE_SCHEME_OF_WORK_SUCCESS",
  UPDATE_SCHEME_OF_WORK_ERROR: "UPDATE_SCHEME_OF_WORK_ERROR",
  SET_UPDATE_SCHEME_OF_WORK_DATA: "SET_UPDATE_SCHEME_OF_WORK_DATA",

  DELETE_SCHEME_OF_WORK_REQUEST: "DELETE_SCHEME_OF_WORK_REQUEST",
  DELETE_SCHEME_OF_WORK_SUCCESS: "DELETE_SCHEME_OF_WORK_SUCCESS",
  DELETE_SCHEME_OF_WORK_ERROR: "DELETE_SCHEME_OF_WORK_ERROR",

  GET_SCHEME_OF_WORK_META_DATA_REQUEST: "GET_SCHEME_OF_WORK_META_DATA_REQUEST",
  GET_SCHEME_OF_WORK_META_DATA_SUCCESS: "GET_SCHEME_OF_WORK_META_DATA_SUCCESS",
  GET_SCHEME_OF_WORK_META_DATA_ERROR: "GET_SCHEME_OF_WORK_META_DATA_ERROR",

  ADD_SCHEME_OF_WORK_ITEM_REQUEST: "ADD_SCHEME_OF_WORK_ITEM_REQUEST",
  ADD_SCHEME_OF_WORK_ITEM_SUCCESS: "ADD_SCHEME_OF_WORK_ITEM_SUCCESS",
  ADD_SCHEME_OF_WORK_ITEM_ERROR: "ADD_SCHEME_OF_WORK_ITEM_ERROR",

  UPDATE_SCHEME_OF_WORK_ITEM_REQUEST: "UPDATE_SCHEME_OF_WORK_ITEM_REQUEST",
  UPDATE_SCHEME_OF_WORK_ITEM_SUCCESS: "UPDATE_SCHEME_OF_WORK_ITEM_SUCCESS",
  UPDATE_SCHEME_OF_WORK_ITEM_ERROR: "UPDATE_SCHEME_OF_WORK_ITEM_ERROR",
  SET_UPDATE_SCHEME_OF_WORK_ITEM_DATA: "SET_UPDATE_SCHEME_OF_WORK_ITEM_DATA",

  DELETE_SCHEME_OF_WORK_ITEM_REQUEST: "DELETE_SCHEME_OF_WORK_ITEM_REQUEST",
  DELETE_SCHEME_OF_WORK_ITEM_SUCCESS: "DELETE_SCHEME_OF_WORK_ITEM_SUCCESS",
  DELETE_SCHEME_OF_WORK_ITEM_ERROR: "DELETE_SCHEME_OF_WORK_ITEM_ERROR",

  CHECK_SCHEME_OF_WORK_REQUEST: "CHECK_SCHEME_OF_WORK_REQUEST",
  CHECK_SCHEME_OF_WORK_SUCCESS: "CHECK_SCHEME_OF_WORK_SUCCESS",
  CHECK_SCHEME_OF_WORK_ERROR: "CHECK_SCHEME_OF_WORK_ERROR",

  APPROVE_SCHEME_OF_WORK_REQUEST: "APPROVE_SCHEME_OF_WORK_REQUEST",
  APPROVE_SCHEME_OF_WORK_SUCCESS: "APPROVE_SCHEME_OF_WORK_SUCCESS",
  APPROVE_SCHEME_OF_WORK_ERROR: "APPROVE_SCHEME_OF_WORK_ERROR",

  getSchemeOfWork: () => ({
    type: schemeOfWork.GET_SCHEMES_OF_WORK_REQUEST,
  }),
  getSchemeOfWorkDetails: (id) => ({
    type: schemeOfWork.GET_SCHEME_OF_WORK_DETAILS_REQUEST,
    id,
  }),
  getSchemeOfWorkMetaData: () => ({
    type: schemeOfWork.GET_SCHEME_OF_WORK_META_DATA_REQUEST,
  }),
  addSchemeOfWork: (data) => ({
    type: schemeOfWork.ADD_SCHEME_OF_WORK_REQUEST,
    data,
  }),
  updateSchemeOfWork: (data, id) => ({
    type: schemeOfWork.UPDATE_SCHEME_OF_WORK_REQUEST,
    data,
    id,
  }),
  setUpdateSchemeOfWorkData: (data) => ({
    type: schemeOfWork.SET_UPDATE_SCHEME_OF_WORK_DATA,
    data,
  }),
  deleteSchemeOfWork: (id) => ({
    type: schemeOfWork.DELETE_SCHEME_OF_WORK_REQUEST,
    id,
  }),
  addSchemeOfWorkItem: (data) => ({
    type: schemeOfWork.ADD_SCHEME_OF_WORK_ITEM_REQUEST,
    data,
  }),
  updateSchemeOfWorkItem: (data, id) => ({
    type: schemeOfWork.UPDATE_SCHEME_OF_WORK_ITEM_REQUEST,
    data,
    id,
  }),
  setUpdateSchemeOfWorkItemData: (data) => ({
    type: schemeOfWork.SET_UPDATE_SCHEME_OF_WORK_ITEM_DATA,
    data,
  }),
  deleteSchemeOfWorkItem: (id, schemeOfWorkId) => ({
    type: schemeOfWork.DELETE_SCHEME_OF_WORK_ITEM_REQUEST,
    schemeOfWorkId,
    id,
  }),
  checkSchemeOfWork: (id) => ({
    type: schemeOfWork.CHECK_SCHEME_OF_WORK_REQUEST,
    id,
  }),
  approveSchemeOfWork: (id) => ({
    type: schemeOfWork.APPROVE_SCHEME_OF_WORK_REQUEST,
    id,
  }),
};

export default schemeOfWork;
