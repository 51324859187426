import { financeStreamsActions } from "../../../actions";
import initialState from "../../../initialState";

const financeStreams = (state = initialState.financeStreams, actions) => {
  switch (actions.type) {
    case financeStreamsActions.GET_FINANCE_STREAMS_REQUEST:
      return {
        ...state,
        financeStreamsLoading: true,
      };
    case financeStreamsActions.GET_FINANCE_STREAMS_SUCCESS:
      return {
        ...state,
        financeStreamsSuccess: actions.data,
        financeStreamsError: {},
        financeStreamsLoading: false,
      };
    case financeStreamsActions.GET_FINANCE_STREAMS_ERROR:
      return {
        ...state,
        financeStreamsError: actions.error,
        financeStreamsLoading: false,
      };

    case financeStreamsActions.GET_FINANCE_STREAMS_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case financeStreamsActions.GET_FINANCE_STREAMS_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataSuccess: actions.data,
        metaDataLoading: false,
      };
    case financeStreamsActions.ADD_FINANCE_STREAM_REQUEST:
      return {
        ...state,
        addFinanceStreamsLoading: true,
        addFinanceStreamsError: {},
      };
    case financeStreamsActions.ADD_FINANCE_STREAM_SUCCESS:
      return {
        ...state,
        addFinanceStreamsSuccess: actions.data,
        addFinanceStreamsLoading: false,
        financeStreamsSuccess: {
          ...state.financeStreamsSuccess,
          financeStreams: [
            ...state.financeStreamsSuccess.financeStreams,
            actions.data,
          ],
        },
      };
    case financeStreamsActions.ADD_FINANCE_STREAM_ERROR:
      return {
        ...state,
        addFinanceStreamsError: false,
        addFinanceStreamsLoading: false,
      };

    case financeStreamsActions.DELETE_FINANCE_STREAM_REQUEST:
      return {
        ...state,
        deleteFinanceStreamLoading: true,
        deleteFinanceStreamError: {},
      };
    case financeStreamsActions.DELETE_FINANCE_STREAM_SUCCESS:
      return {
        ...state,
        deleteFinanceStreamSuccess: actions.data,
        deleteFinanceStreamLoading: false,
        financeStreamsSuccess: {
          ...state.financeStreamsSuccess,
          financeStreams: actions.streams,
        },
      };
    case financeStreamsActions.DELETE_FINANCE_STREAM_ERROR:
      return {
        ...state,
        deleteFinanceStreamLoading: false,
        deleteFinanceStreamError: actions.error,
      };
    case financeStreamsActions.SET_UPDATE_FINANCE_STREAM_DATA:
      return {
        ...state,
        updateFinanceStreamData: actions.data,
      };
    case financeStreamsActions.UPDATE_FINANCE_STREAM_REQUEST:
      return {
        ...state,
        updateFinanceStreamLoading: true,
        updateFinanceStreamError: {},
      };
    case financeStreamsActions.UPDATE_FINANCE_STREAM_SUCCESS:
      return {
        ...state,
        updateFinanceStreamSuccess: actions.data,
        updateFinanceStreamLoading: false,
        financeStreamsSuccess: {
          ...state.financeStreamsSuccess,
          financeStreams: actions.streams,
        },
      };
    case financeStreamsActions.UPDATE_FINANCE_STREAM_ERROR:
      return {
        ...state,
        updateFinanceStreamLoading: false,
        updateFinanceStreamError: actions.error,
      };
    case financeStreamsActions.GET_FINANCE_STREAM_DETAILS_REQUEST:
      return {
        ...state,
        financeStreamDetailsLoading: true,
        financeStreamDetailsError: {},
      };
    case financeStreamsActions.GET_FINANCE_STREAM_DETAILS_SUCCESS:
      return {
        ...state,
        financeStreamDetailsSuccess: actions.data,
        financeStreamDetailsLoading: false,
      };
    case financeStreamsActions.GET_FINANCE_STREAM_DETAILS_ERROR:
      return {
        ...state,
        financeStreamDetailsError: actions.error,
        financeStreamDetailsLoading: false,
      };
    case financeStreamsActions.CHECK_FINANCE_STREAM_REQUEST:
      return {
        ...state,
        checkFinanceStreamError: {},
        checkFinanceStreamLoading: true,
      };
    case financeStreamsActions.CHECK_FINANCE_STREAM_SUCCESS:
      return {
        ...state,
        checkFinanceStreamSuccess: actions.data,
        checkFinanceStreamLoading: false,
      };
    case financeStreamsActions.CHECK_FINANCE_STREAM_ERROR:
      return {
        ...state,
        checkFinanceStreamLoading: false,
        checkFinanceStreamError: actions.error,
      };
    case financeStreamsActions.APPROVE_FINANCE_STREAM_REQUEST:
      return {
        ...state,
        approveFinanceStreamLoading: true,
        approveFinanceStreamError: {},
      };
    case financeStreamsActions.APPROVE_FINANCE_STREAM_SUCCESS:
      return {
        ...state,
        approveFinanceStreamSuccess: actions.data,
        approveFinanceStreamLoading: false,
      };
    case financeStreamsActions.APPROVE_FINANCE_STREAM_ERROR:
      return {
        ...state,
        approveFinanceStreamLoading: false,
        approveFinanceStreamError: actions.error,
      };
    default:
      return state;
  }
};

export default financeStreams;
