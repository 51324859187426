const bidEvaluationsInitialState = {
  bidEvaluations: {
    bidEvaluationsLoading: false,
    bidEvaluationsSuccess: {},
    bidEvaluationsError: {},

    eligibilityEvaluationsLoading: false,
    eligibilityEvaluationsSuccess: {},
    eligibilityEvaluationsError: {},

    technicalEvaluationsLoading: false,
    technicalEvaluationsSuccess: {},
    technicalEvaluationsError: {},

    financialEvaluationsLoading: false,
    financialEvaluationsSuccess: {},
    financialEvaluationsError: {},

    bidEvaluationDetailsLoading: false,
    bidEvaluationDetailsSuccess: {},
    bidEvaluationDetailsError: {},

    eligibilityEvaluationDetailsLoading: false,
    eligibilityEvaluationDetailsSuccess: {},
    eligibilityEvaluationDetailsError: {},

    technicalEvaluationDetailsLoading: false,
    technicalEvaluationDetailsSuccess: {},
    technicalEvaluationDetailsError: {},

    financialEvaluationDetailsLoading: false,
    financialEvaluationDetailsSuccess: {},
    financialEvaluationDetailsError: {},

    bidEvaluationsMetaDataLoading: false,
    bidEvaluationsMetaDataSuccess: {},
    bidEvaluationsMetaDataError: {},

    eligibilityEvaluationsMetaDataLoading: false,
    eligibilityEvaluationsMetaDataSuccess: {},
    eligibilityEvaluationsMetaDataError: {},

    technicalEvaluationsMetaDataLoading: false,
    technicalEvaluationsMetaDataSuccess: {},
    technicalEvaluationsMetaDataError: {},

    financialEvaluationsMetaDataLoading: false,
    financialEvaluationsMetaDataSuccess: {},
    financialEvaluationsMetaDataError: {},

    addBidEvaluationData: {},
    addBidEvaluationLoading: false,
    addBidEvaluationSuccess: {},
    addBidEvaluationError: {},

    addEligibilityEvaluationData: {},
    addEligibilityEvaluationLoading: false,
    addEligibilityEvaluationSuccess: {},
    addEligibilityEvaluationError: {},

    addTechnicalEvaluationData: {},
    addTechnicalEvaluationLoading: false,
    addTechnicalEvaluationSuccess: {},
    addTechnicalEvaluationError: {},

    addFinancialEvaluationData: {},
    addFinancialEvaluationLoading: false,
    addFinancialEvaluationSuccess: {},
    addFinancialEvaluationError: {},

    updateBidEvaluationData: {},
    updateBidEvaluationLoading: false,
    updateBidEvaluationSuccess: {},
    updateBidEvaluationError: {},

    updateEligibilityEvaluationData: {},
    updateEligibilityEvaluationLoading: false,
    updateEligibilityEvaluationSuccess: {},
    updateEligibilityEvaluationError: {},

    updateTechnicalEvaluationData: {},
    updateTechnicalEvaluationLoading: false,
    updateTechnicalEvaluationSuccess: {},
    updateTechnicalEvaluationError: {},

    updateFinancialEvaluationData: {},
    updateFinancialEvaluationLoading: false,
    updateFinancialEvaluationSuccess: {},
    updateFinancialEvaluationError: {},

    deleteBidEvaluationLoading: false,
    deleteBidEvaluationSuccess: {},
    deleteBidEvaluationError: {},

    deleteEligibilityEvaluationLoading: false,
    deleteEligibilityEvaluationSuccess: {},
    deleteEligibilityEvaluationError: {},

    deleteTechnicalEvaluationLoading: false,
    deleteTechnicalEvaluationSuccess: {},
    deleteTechnicalEvaluationError: {},

    deleteFinancialEvaluationLoading: false,
    deleteFinancialEvaluationSuccess: {},
    deleteFinancialEvaluationError: {},

    checkBidEvaluationsLoading: false,
    checkBidEvaluationsSuccess: {},
    checkBidEvaluationsError: {},

    approveBidEvaluationLoading: false,
    approveBidEvaluationsSuccess: {},
    approveBidEvaluationsError: {},
  },
};

export default bidEvaluationsInitialState;
