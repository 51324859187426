const subjectClass = {
  GET_SUBJECT_CLASS_REQUEST: "GET_SUBJECT_CLASS_REQUEST",
  GET_SUBJECT_CLASS_SUCCESS: "GET_SUBJECT_CLASS_SUCCESS",
  GET_SUBJECT_CLASS_ERROR: "GET_SUBJECT_CLASS_ERROR",

  GET_SUBJECT_CLASS_META_DATA_REQUEST: "GET_SUBJECT_CLASS_META_DATA_REQUEST",
  GET_SUBJECT_CLASS_META_DATA_SUCCESS: "GET_SUBJECT_CLASS_META_DATA_SUCCESS",

  ADD_SUBJECT_CLASS_REQUEST: "ADD_SUBJECT_CLASS_REQUEST",
  ADD_SUBJECT_CLASS_SUCCESS: "ADD_SUBJECT_CLASS_SUCCESS",
  ADD_SUBJECT_CLASS_ERROR: "ADD_SUBJECT_CLASS_ERROR",

  UPDATE_SUBJECT_CLASS_DATA: "UPDATE_SUBJECT_CLASS_DATA",
  UPDATE_SUBJECT_CLASS_REQUEST: "UPDATE_SUBJECT_CLASS_REQUEST",
  UPDATE_SUBJECT_CLASS_SUCCESS: "UPDATE_SUBJECT_CLASS_SUCCESS",
  UPDATE_SUBJECT_CLASS_ERROR: "UPDATE_SUBJECT_CLASS_ERROR",

  GET_SUBJECT_CLASS_DETAILS_REQUEST: "GET_SUBJECT_CLASS_DETAILS_REQUEST",
  GET_SUBJECT_CLASS_DETAILS_SUCCESS: "GET_SUBJECT_CLASS_DETAILS_SUCCESS",
  GET_SUBJECT_CLASS_DETAILS_ERROR: "GET_SUBJECT_CLASS_DETAILS_ERROR",

  DELETE_SUBJECT_CLASS_REQUEST: "DELETE_SUBJECT_CLASS_REQUEST",
  DELETE_SUBJECT_CLASS_SUCCESS: "DELETE_SUBJECT_CLASS_SUCCESS",
  DELETE_SUBJECT_CLASS_ERROR: "DELETE_SUBJECT_CLASS_ERROR",

  DELETE_EXAM_PAPER_REQUEST: "DELETE_EXAM_PAPER_REQUEST",
  DELETE_EXAM_PAPER_SUCCESS: "DELETE_EXAM_PAPER_SUCCESS",
  DELETE_EXAM_PAPER_ERROR: "DELETE_EXAM_PAPER_ERROR",

  getSubjectClass: () => ({
    type: subjectClass.GET_SUBJECT_CLASS_REQUEST,
  }),
  addSubjectClass: (data) => ({
    type: subjectClass.ADD_SUBJECT_CLASS_REQUEST,
    data,
  }),
  updateSubjectClass: (data, id) => ({
    type: subjectClass.UPDATE_SUBJECT_CLASS_REQUEST,
    data,
    id,
  }),
  deleteSubjectClass: (id) => ({
    type: subjectClass.DELETE_SUBJECT_CLASS_REQUEST,
    id,
  }),
  getSubjectClassDetails: (id) => ({
    type: subjectClass.GET_SUBJECT_CLASS_DETAILS_REQUEST,
    id,
  }),
  getMetaData: () => ({
    type: subjectClass.GET_SUBJECT_CLASS_META_DATA_REQUEST,
  }),
  deleteExamPaper: (id) => ({
    type: subjectClass.DELETE_EXAM_PAPER_REQUEST,
    id,
  }),
  updateSubjectClassData: (data) => ({
    type: subjectClass.UPDATE_SUBJECT_CLASS_DATA,
    data,
  }),
};

export default subjectClass;
