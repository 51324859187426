import { takeLatest, put, fork } from "@redux-saga/core/effects";
import { appUiActions, performanceAnalysisActions } from "../../actions";
import axios from "axios";

function* getClassPerformanceAnalysis({ params }) {
  try {
    const response = yield axios({
      url: `/academics/performance-analysis`,
      params,
    });

    yield put({
      type: performanceAnalysisActions.GET_CLASS_PERFORMANCE_ANALYSIS_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleClassPerformanceAnalysis(true));
  } catch (error) {
    yield put({
      type: performanceAnalysisActions.GET_CLASS_PERFORMANCE_ANALYSIS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetClassPerformanceAnalysis() {
  yield takeLatest(
    performanceAnalysisActions.GET_CLASS_PERFORMANCE_ANALYSIS_REQUEST,
    getClassPerformanceAnalysis
  );
}

function* getSubjectPerformanceAnalysis({ params }) {
  try {
    const response = yield axios({
      url: `/academics/performance-analysis/subject`,
      params,
    });

    yield put({
      type: performanceAnalysisActions.GET_SUBJECT_PERFORMANCE_ANALYSIS_SUCCESS,
      data: response,
    });
    yield put(appUiActions.toggleSubjectPerformanceAnalysis(true));
  } catch (error) {
    yield put({
      type: performanceAnalysisActions.GET_SUBJECT_PERFORMANCE_ANALYSIS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSubjectPerformanceAnalysis() {
  yield takeLatest(
    performanceAnalysisActions.GET_SUBJECT_PERFORMANCE_ANALYSIS_REQUEST,
    getSubjectPerformanceAnalysis
  );
}

function* getMetaData() {
  try {
    const response = yield axios({
      url: "/academics/performance-analysis/meta-data",
    });

    yield put({
      type: performanceAnalysisActions.GET_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: performanceAnalysisActions.GET_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(
    performanceAnalysisActions.GET_META_DATA_REQUEST,
    getMetaData
  );
}

export default [
  fork(watchGetClassPerformanceAnalysis),
  fork(watchGetSubjectPerformanceAnalysis),
  fork(watchGetMetaData),
];
