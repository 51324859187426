import {
  FcBriefcase,
  FcConferenceCall,
  FcCurrencyExchange,
  FcDiploma1,
  FcFolder,
  FcGlobe,
  FcPackage,
  FcSettings,
  FcWorkflow,
  FcBarChart,
  FcSalesPerformance,
} from "react-icons/fc";

const routePaths = {
  applications: {
    name: "My Applications",
    pageTitle: "My Applications",
    path: "my-applications",
    icon: FcWorkflow,
    display: false,
  },
  dashboard: {
    name: "Dashboard",
    pageTitle: "Dashboard",
    path: "dashboard",
    icon: FcBarChart,
    display: false,
    permissions: {
      canViewDashboardStatistics: "canViewDashboardStatistics",
      canViewFeesCollectionSummary: "canViewFeesCollectionSummary",
      canViewGenderStatistics: "canViewGenderStatistics",
      canViewClassTotals: "canViewClassTotals",
      canViewIncomeSummary: "canViewIncomeSummary",
      canViewBudgetSummary: "canViewBudgetSummary",
      canViewProfitAndLoss: "canViewProfitAndLoss",
      canViewSchoolFinancialPosition: "canViewSchoolFinancialPosition",
      canViewSchoolInventory: "canViewSchoolInventory",
      canViewAttendanceSummary: "canViewAttendanceSummary",
    },
  },
  administration: {
    name: "ADMINISTRATION",
    pageTitle: "SCHOOLBOOK | ADMINISTRATION",
    path: "administration",
    icon: FcBriefcase,
    display: true,
  },
  academics: {
    name: "Academics",
    pageTitle: "SCHOOLBOOK | ACADEMICS",
    path: "academics",
    icon: FcDiploma1,
    display: true,
  },
  humanResource: {
    name: "Human Resource",
    pageTitle: "SCHOOLBOOK | HUMAN RESOURCE",
    path: "human-resource",
    icon: FcConferenceCall,
    display: true,
  },
  businessDevelopmentAndPartnerships: {
    name: "Business Development And Partnerships",
    pageTitle: "SCHOOLBOOK | BUSINESS DEVELOPMENT AND PARTNERSHIPS",
    path: "business-development-and-partnerships",
    icon: FcGlobe,
    display: false,
  },
  schoolFeesManagement: {
    name: "School Fees Management",
    pageTitle: "SCHOOLBOOK | SCHOOL FEES MANAGEMENT",
    path: "school-fees-management",
    icon: FcSalesPerformance,
    display: true,
  },
  finance: {
    name: "Finance",
    pageTitle: "SCHOOLBOOK | FINANCE",
    path: "finance",
    icon: FcCurrencyExchange,
    display: true,
  },
  procurement: {
    name: "Procurement",
    pageTitle: "SCHOOLBOOK | PROCUREMENT",
    path: "procurement",
    icon: FcPackage,
    display: false,
  },
  settings: {
    name: "Settings",
    pageTitle: "SCHOOLBOOK | SETTINGS",
    path: "settings",
    icon: FcSettings,
    display: false,
  },
  assetsAndInventoryManagement: {
    name: "Procurement And Inventory Management",
    pageTitle: "SCHOOLBOOK | INVENTORY",
    path: "assets-and-inventory-management",
    icon: FcFolder,
    display: true,
  },
  landingPage: {
    name: "Landing Page",
    pageTitle: "SCHOOLBOOK | LANDING PAGE",
    path: "landing-page",
  },
  auth: {
    name: "SCHOOLBOOK | AUTH",
    pageTitle: "SCHOOLBOOK | AUTH",
    path: "auth",
  },
  resetPasswordPage: {
    name: "Resetpassword Page",
    pageTitle: "SCHOOLBOOK | RESET PASSWORD PAGE",
    path: "/reset-password/:token",
  },
  confirmAccountPage: {
    name: "Verifyuser Page",
    pageTitle: "SCHOOLBOOK | VERIFY USER PAGE",
    path: "/confirm-account/:token",
  },
};

export default routePaths;
