const stores = {
  GET_STORE_ITEMS_REQUEST: "GET_STORE_ITEMS_REQUEST",
  GET_STORE_ITEMS_SUCCESS: "GET_STORE_ITEMS_SUCCESS",
  GET_STORE_ITEMS_ERROR: "GET_STORE_ITEMS_ERROR",

  ADD_STORE_ITEM_REQUEST: "ADD_STORE_ITEM_REQUEST",
  ADD_STORE_ITEM_SUCCESS: "ADD_STORE_ITEM_SUCCESS",
  ADD_STORE_ITEM_ERROR: "ADD_STORE_ITEM_ERROR",

  DELETE_STORE_ITEM_REQUEST: "DELETE_STORE_ITEM_REQUEST",
  DELETE_STORE_ITEM_SUCCESS: "DELETE_STORE_ITEM_SUCCESS",
  DELETE_STORE_ITEM_ERROR: "DELETE_STORE_ITEM_ERROR",

  UPDATE_STORE_ITEM_REQUEST: "UPDATE_STORE_ITEM_REQUEST",
  UPDATE_STORE_ITEM_SUCCESS: "UPDATE_STORE_ITEM_SUCCESS",
  UPDATE_STORE_ITEM_ERROR: "UPDATE_STORE_ITEM_ERROR",
  SET_UPDATE_STORE_ITEM_DETAILS: "SET_STORE_ITEM_DETAILS",

  GET_STORE_ITEM_DETAILS_REQUEST: "GET_STORE_ITEM_DETAILS_REQUEST",
  GET_STORE_ITEM_DETAILS_SUCCESS: "GET_STORE_ITEM_DETAILS_SUCCESS",
  GET_STORE_ITEM_DETAILS_ERROR: "GET_STORE_ITEM_DETAILS_ERROR",

  CHECK_STORE_ITEM_REQUEST: "CHECK_STORE_ITEM_REQUEST",
  CHECK_STORE_ITEM_SUCCESS: "CHECK_STORE_ITEM_SUCCESS",
  CHECK_STORE_ITEM_ERROR: "CHECK_STORE_ITEM_ERROR",

  APPROVE_STORE_ITEM_REQUEST: "APPROVE_STORE_ITEM_REQUEST",
  APPROVE_STORE_ITEM_SUCCESS: "APPROVE_STORE_ITEM_SUCCESS",
  APPROVE_STORE_ITEM_ERROR: "APPROVE_STORE_ITEM_ERROR",

  GET_STORE_ITEMS_META_DATA_REQUEST: "GET_STORE_ITEMS_META_DATA_REQUEST",
  GET_STORE_ITEMS_META_DATA_SUCCESS: "GET_STORE_ITEMS_META_DATA_SUCCESS",
  GET_STORE_ITEMS_META_DATA_ERROR: "GET_STORE_ITEMS_META_DATA_ERROR",

  ADD_STORE_ISSUE_REQUEST: "ADD_STORE_ISSUES_REQUEST",
  ADD_STORE_ISSUE_SUCCESS: "ADD_STORE_ISSUES_SUCCESS",
  ADD_STORE_ISSUE_ERROR: "ADD_STORE_ISSUES_ERROR",
  SET_ADD_STORE_ISSUE_DATA: "SET_ADD_STORE_ISSUE_DATA",

  DELETE_STORE_ISSUE_REQUEST: "DELETE_STORE_ISSUE_REQUEST",
  DELETE_STORE_ISSUE_SUCCESS: "DELETE_STORE_ISSUE_SUCCESS",
  DELETE_STORE_ISSUE_ERROR: "DELETE_STORE_ISSUE_ERROR",

  UPDATE_STORE_ISSUE_REQUEST: "UPDATE_STORE_ISSUE_REQUEST",
  UPDATE_STORE_ISSUE_SUCCESS: "UPDATE_STORE_ISSUE_SUCCESS",
  UPDATE_STORE_ISSUE_ERROR: "UPDATE_STORE_ISSUE_ERROR",
  SET_UPDATE_STORE_ISSUE_DETAILS: "SET_STORE_ISSUE_DETAILS",

  GET_STORE_ISSUE_DETAILS_REQUEST: "GET_STORE_ISSUE_DETAILS_REQUEST",
  GET_STORE_ISSUE_DETAILS_SUCCESS: "GET_STORE_ISSUE_DETAILS_SUCCESS",
  GET_STORE_ISSUE_DETAILS_ERROR: "GET_STORE_ISSUE_DETAILS_ERROR",

  CHECK_STORE_ISSUE_REQUEST: "CHECK_STORE_ISSUE_REQUEST",
  CHECK_STORE_ISSUE_SUCCESS: "CHECK_STORE_ISSUE_SUCCESS",
  CHECK_STORE_ISSUE_ERROR: "CHECK_STORE_ISSUE_ERROR",

  APPROVE_STORE_ISSUE_REQUEST: "APPROVE_STORE_ISSUE_REQUEST",
  APPROVE_STORE_ISSUE_SUCCESS: "APPROVE_STORE_ISSUE_SUCCESS",
  APPROVE_STORE_ISSUE_ERROR: "APPROVE_STORE_ISSUE_ERROR",

  GET_STORE_ISSUES_META_DATA_REQUEST: "GET_STORE_ISSUES_META_DATA_REQUEST",
  GET_STORE_ISSUES_META_DATA_SUCCESS: "GET_STORE_ISSUES_META_DATA_SUCCESS",
  GET_STORE_ISSUES_META_DATA_ERROR: "GET_STORE_ISSUES_META_DATA_ERROR",

  getStoreItems: () => ({
    type: stores.GET_STORE_ITEMS_REQUEST,
  }),
  addStoreItem: (data) => ({
    type: stores.ADD_STORE_ITEM_REQUEST,
    data,
  }),
  deleteStoreItem: (id, itemsArray) => ({
    type: stores.DELETE_STORE_ITEM_REQUEST,
    id,
    items: itemsArray,
  }),
  updateStoreItem: (id, data, storeItemsArray) => ({
    type: stores.UPDATE_STORE_ITEM_REQUEST,
    data,
    id,
    storeItems: storeItemsArray,
  }),
  setUpdateStoreItemDetails: (data) => ({
    type: stores.SET_UPDATE_STORE_ITEM_DETAILS,
    data,
  }),
  getStoreItemDetails: (id) => ({
    type: stores.GET_STORE_ITEM_DETAILS_REQUEST,
    id,
  }),
  checkStoreItem: (id) => ({
    type: stores.CHECK_STORE_ITEM_REQUEST,
    id,
  }),
  approveStoreItem: (id) => ({
    type: stores.APPROVE_STORE_ITEM_REQUEST,
    id,
  }),
  getStoreItemsMetaData: () => ({
    type: stores.GET_STORE_ITEMS_META_DATA_REQUEST,
  }),
  addStoreIssue: (data, id) => ({
    type: stores.ADD_STORE_ISSUE_REQUEST,
    data,
    id,
  }),
  setAddStoreIssueData: (data) => ({
    type: stores.SET_ADD_STORE_ISSUE_DATA,
    data,
  }),
  deleteStoreIssue: (id) => ({
    type: stores.DELETE_STORE_ISSUE_REQUEST,
    id,
  }),
  updateStoreIssue: (id, data) => ({
    type: stores.UPDATE_STORE_ISSUE_REQUEST,
    data,
    id,
  }),
  setUpdateStoreIssueDetails: (data) => ({
    type: stores.SET_UPDATE_STORE_ISSUE_DETAILS,
    data,
  }),
  getStoreIssueDetails: (id) => ({
    type: stores.GET_STORE_ISSUE_DETAILS_REQUEST,
    id,
  }),
  checkStoreIssue: (id) => ({
    type: stores.CHECK_STORE_ISSUE_REQUEST,
    id,
  }),
  approveStoreIssue: (id) => ({
    type: stores.APPROVE_STORE_ISSUE_REQUEST,
    id,
  }),
  getStoreIssuesMetaData: () => ({
    type: stores.GET_STORE_ISSUES_META_DATA_REQUEST,
  }),
};

export default stores;
