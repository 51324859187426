const rolesInitialState = {
  roles: {
    rolesLoading: false,
    rolesSuccess: {},
    rolesError: {},

    addRoleLoading: false,
    addRolesSuccess: {},
    addRoleError: {},

    deleteRolesLoading: false,
    deleteRolesSuccess: {},
    deleteRolesError: {},

    updateRolesData: {},
    updateRolesLoading: false,
    updateRolesSuccess: {},
    updateRolesError: {},

    rolesDetailsLoading: false,
    rolesDetailsSuccess: {},
    rolesDetailsError: {},
  },
};

module.exports = rolesInitialState;
