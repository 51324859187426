const dashboard = {
  GET_MAIN_DASHBOARD_REQUEST: "GET_MAIN_DASHBOARD_REQUEST",
  GET_MAIN_DASHBOARD_SUCCESS: "GET_MAIN_DASHBOARD_SUCCESS",
  GET_MAIN_DASHBOARD_ERROR: "GET_MAIN_DASHBOARD_ERROR",

  GET_FEES_SUMMARY_COLLECTIONS_REQUEST: "GET_FEES_SUMMARY_COLLECTIONS_REQUEST",
  GET_FEES_SUMMARY_COLLECTIONS_SUCCESS: "GET_FEES_SUMMARY_COLLECTIONS_SUCCESS",
  GET_FEES_SUMMARY_COLLECTIONS_ERROR: "GET_FEES_SUMMARY_COLLECTIONS_ERROR",

  GET_DASHBOARD_META_DATA_REQUEST: "GET_DASHBOARD_META_DATA_REQUEST",
  GET_DASHBOARD_META_DATA_SUCCESS: "GET_DASHBOARD_META_DATA_SUCCESS",
  GET_DASHBOARD_META_DATA_ERROR: "GET_DASHBOARD_META_DATA_ERROR",

  GET_BUDGET_SUMMARY_REQUEST: "GET_BUDGET_SUMMARY_REQUEST",
  GET_BUDGET_SUMMARY_SUCCESS: "GET_BUDGET_SUMMARY_SUCCESS",
  GET_BUDGET_SUMMARY_ERROR: "GET_BUDGET_SUMMARY_ERROR",

  GET_INCOME_SUMMARY_REQUEST: "GET_INCOME_SUMMARY_REQUEST",
  GET_INCOME_SUMMARY_SUCCESS: "GET_INCOME_SUMMARY_SUCCESS",
  GET_INCOME_SUMMARY_ERROR: "GET_INCOME_SUMMARY_ERROR",

  GET_SCHOOL_FINANCIAL_SUMMARY_REQUEST: "GET_SCHOOL_FINANCIAL_SUMMARY_REQUEST",
  GET_SCHOOL_FINANCIAL_SUMMARY_SUCCESS: "GET_SCHOOL_FINANCIAL_SUMMARY_SUCCESS",
  GET_SCHOOL_FINANCIAL_SUMMARY_ERROR: "GET_SCHOOL_FINANCIAL_SUMMARY_ERROR",

  GET_PROFIT_AND_LOSS_SUMMARY_REQUEST: "GET_PROFIT_AND_LOSS_SUMMARY_REQUEST",
  GET_PROFIT_AND_LOSS_SUMMARY_SUCCESS: "GET_PROFIT_AND_LOSS_SUMMARY_SUCCESS",
  GET_PROFIT_AND_LOSS_SUMMARY_ERROR: "GET_PROFIT_AND_LOSS_SUMMARY_ERROR",

  GET_STORES_ITEMS_REQUEST: "DASHBOARD_GET_STORES_ITEMS_REQUEST",
  GET_STORES_ITEMS_SUCCESS: "DASHBOARD_GET_STORES_ITEMS_SUCCESS",
  GET_STORES_ITEMS_ERROR: "DASHBOARD_GET_STORES_ITEMS_ERROR",

  GET_BUDGET_EXPENSES_SUMMARY_REQUEST: "GET_BUDGET_EXPENSES_SUMMARY_REQUEST",
  GET_BUDGET_EXPENSES_SUMMARY_SUCCESS: "GET_BUDGET_EXPENSES_SUMMARY_SUCCESS",
  GET_BUDGET_EXPENSES_SUMMARY_ERROR: "GET_BUDGET_EXPENSES_SUMMARY_ERROR",

  GET_ATTENDANCE_SUMMARY_REQUEST: "GET_ATTENDANCE_SUMMARY_REQUEST",
  GET_ATTENDANCE_SUMMARY_SUCCESS: "GET_ATTENDANCE_SUMMARY_SUCCESS",
  GET_ATTENDANCE_SUMMARY_ERROR: "GET_ATTENDANCE_SUMMARY_ERROR",

  GET_LEARNER_ATTENDANCE_SUMMARY_REQUEST:
    "GET_LEARNER_ATTENDANCE_SUMMARY_REQUEST",
  GET_LEARNER_ATTENDANCE_SUMMARY_SUCCESS:
    "GET_LEARNER_ATTENDANCE_SUMMARY_SUCCESS",
  GET_LEARNER_ATTENDANCE_SUMMARY_ERROR: "GET_LEARNER_ATTENDANCE_SUMMARY_ERROR",

  getIncomeSummary: (data) => ({
    type: dashboard.GET_INCOME_SUMMARY_REQUEST,
    data,
  }),
  getLearnerAttendanceSummary: (data) => ({
    type: dashboard.GET_LEARNER_ATTENDANCE_SUMMARY_REQUEST,
    data,
  }),
  getAttendanceSummary: (data) => ({
    type: dashboard.GET_ATTENDANCE_SUMMARY_REQUEST,
    data,
  }),
  getBudgetExpensesSummary: (data) => ({
    type: dashboard.GET_BUDGET_EXPENSES_SUMMARY_REQUEST,
    data,
  }),
  getStoresItems: (data) => ({
    type: dashboard.GET_STORES_ITEMS_REQUEST,
    data,
  }),
  getProfitAndLossSummary: (data) => ({
    type: dashboard.GET_PROFIT_AND_LOSS_SUMMARY_REQUEST,
    data,
  }),
  getSchoolFinancialSummary: (data) => ({
    type: dashboard.GET_SCHOOL_FINANCIAL_SUMMARY_REQUEST,
    data,
  }),
  getMainDashboard: (data) => ({
    type: dashboard.GET_MAIN_DASHBOARD_REQUEST,
    data,
  }),
  getFeesCollectionsSummary: (data) => ({
    type: dashboard.GET_FEES_SUMMARY_COLLECTIONS_REQUEST,
    data,
  }),
  getDashboardMetaData: () => ({
    type: dashboard.GET_DASHBOARD_META_DATA_REQUEST,
  }),
  getBudgetSummary: (data) => ({
    type: dashboard.GET_BUDGET_SUMMARY_REQUEST,
    data,
  }),
};

export default dashboard;
