import { takeLatest, put, fork, takeEvery } from "@redux-saga/core/effects";
import axios from "axios";
import {
  appUiActions,
  financeStreamsActions,
  incomeReceiptActions,
} from "../../../actions";

function* getFinanceStreams() {
  try {
    const response = yield axios({
      url: "/business-development-and-partnerships/finance-streams",
    });

    yield put({
      type: financeStreamsActions.GET_FINANCE_STREAMS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: financeStreamsActions.GET_FINANCE_STREAMS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetFinanceStreams() {
  yield takeLatest(
    financeStreamsActions.GET_FINANCE_STREAMS_REQUEST,
    getFinanceStreams
  );
}

function* getMetaData() {
  try {
    const response = yield axios({
      url: "/business-development-and-partnerships/finance-streams/meta-data",
    });

    yield put({
      type: financeStreamsActions.GET_FINANCE_STREAMS_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: financeStreamsActions.GET_FINANCE_STREAMS_META_DATA_SUCCESS,
      data: {},
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(
    financeStreamsActions.GET_FINANCE_STREAMS_META_DATA_REQUEST,
    getMetaData
  );
}

function* addFinanceStream({ data }) {
  try {
    const response = yield axios({
      url: "/business-development-and-partnerships/finance-streams",
      method: "POST",
      data,
    });

    yield put({
      type: financeStreamsActions.ADD_FINANCE_STREAM_SUCCESS,
      data: response?.financeStream,
    });
    yield put(incomeReceiptActions.getMetaData());
  } catch (error) {
    yield put({
      type: financeStreamsActions.ADD_FINANCE_STREAM_ERROR,
      error: error.data,
    });
  }
}

function* watchAddFinanceStream() {
  yield takeLatest(
    financeStreamsActions.ADD_FINANCE_STREAM_REQUEST,
    addFinanceStream
  );
}

function* deleteFinanceStream({ data }) {
  try {
    const response = yield axios({
      url: `/business-development-and-partnerships/finance-streams/${data.id}`,
      method: "DELETE",
    });

    yield put({
      type: financeStreamsActions.DELETE_FINANCE_STREAM_SUCCESS,
      streams: data.streams,
      data: response,
    });
  } catch (error) {
    yield put({
      type: financeStreamsActions.DELETE_FINANCE_STREAM_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteFinanceStream() {
  yield takeEvery(
    financeStreamsActions.DELETE_FINANCE_STREAM_REQUEST,
    deleteFinanceStream
  );
}

function* updateFinanceStream({ id, streams, data }) {
  try {
    const response = yield axios({
      url: `/business-development-and-partnerships/finance-streams/${id}`,
      data,
      method: "PUT",
    });

    yield put({
      type: financeStreamsActions.UPDATE_FINANCE_STREAM_SUCCESS,
      data: response,
      streams,
    });

    yield put(appUiActions.toggleUpdateFinanceStreamModal(false));
  } catch (error) {
    yield put({
      type: financeStreamsActions.UPDATE_FINANCE_STREAM_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateFinanceStream() {
  yield takeLatest(
    financeStreamsActions.UPDATE_FINANCE_STREAM_REQUEST,
    updateFinanceStream
  );
}

function* getFinanceStreamDetails({ data }) {
  try {
    const response = yield axios({
      url: `/business-development-and-partnerships/finance-streams/${data}`,
    });

    yield put({
      type: financeStreamsActions.GET_FINANCE_STREAM_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: financeStreamsActions.GET_FINANCE_STREAM_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetFinanceStreamDetails() {
  yield takeLatest(
    financeStreamsActions.GET_FINANCE_STREAM_DETAILS_REQUEST,
    getFinanceStreamDetails
  );
}

function* checkFinanceStream({ data }) {
  try {
    const response = yield axios({
      url: `/business-development-and-partnerships/finance-streams/check/${data}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({
      type: financeStreamsActions.CHECK_FINANCE_STREAM_SUCCESS,
      data: response,
    });

    yield put(financeStreamsActions.getFinanceStreamDetails(data));
  } catch (error) {
    yield put({
      type: financeStreamsActions.CHECK_FINANCE_STREAM_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckFinanceStream() {
  yield takeLatest(
    financeStreamsActions.CHECK_FINANCE_STREAM_REQUEST,
    checkFinanceStream
  );
}

function* approveFinanceStream({ data }) {
  try {
    const response = yield axios({
      url: `/business-development-and-partnerships/finance-streams/approve/${data}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: financeStreamsActions.APPROVE_FINANCE_STREAM_SUCCESS,
      data: response,
    });

    yield put(financeStreamsActions.getFinanceStreamDetails(data));
  } catch (error) {
    yield put({
      type: financeStreamsActions.APPROVE_FINANCE_STREAM_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveFinaceStream() {
  yield takeLatest(
    financeStreamsActions.APPROVE_FINANCE_STREAM_REQUEST,
    approveFinanceStream
  );
}

export default [
  fork(watchGetFinanceStreams),
  fork(watchGetMetaData),
  fork(watchAddFinanceStream),
  fork(watchDeleteFinanceStream),
  fork(watchUpdateFinanceStream),
  fork(watchApproveFinaceStream),
  fork(watchCheckFinanceStream),
  fork(watchGetFinanceStreamDetails),
];
