import React from "react";
import PropTypes from "prop-types";
import { Modal, Divider } from "antd";

const AntdModal = ({
  title,
  closeModal,
  open,
  footer,
  zIndex = 500,
  size,
  children,
  customSize,
  ...props
}) => {
  const Msize = size === "large" ? 800 : size === "extraLarge" ? 1000 : 520; // "large" || "extraLarge" || "default
  const modalSize = customSize ? customSize : Msize;
  return (
    <Modal
      {...props}
      width={modalSize}
      zIndex={zIndex}
      title={
        <div className="text-uppercase text-sm fw-bold">
          {title}
        </div>
      }
      onCancel={closeModal}
      open={open}
      footer={footer ? footer : null}
      maskClosable={false}
    >
      <Divider className="m-1" />
      {children}
    </Modal>
  );
};

AntdModal.propTypes = {
  title: PropTypes.string,
  closeModal: PropTypes.func,
  open: PropTypes.bool,
  footer: PropTypes.array,
  zIndex: PropTypes.number,
  size: PropTypes.string,
  children: PropTypes.any,
  customSize: PropTypes.number,
};

export default AntdModal;
