const learners = {
  GET_LEARNER_DETAILS_REQUEST: "GET_LEARNER_DETAILS_REQUEST",
  GET_LEARNER_DETAILS_SUCCESS: "GET_LEARNER_DETAILS_SUCCESS",
  GET_LEARNER_DETAILS_ERROR: "GET_LEARNER_DETAILS_ERROR",

  ADD_LEARNER_REQUEST: "ADD_LEARNER_REQUEST",
  ADD_LEARNER_SUCCESS: "ADD_LEARNER_SUCCESS",
  ADD_LEARNER_ERROR: "ADD_LEARNER_ERROR",

  GET_LEARNERS_REQUEST: "GET_LEARNERS_REQUEST",
  GET_LEARNERS_SUCCESS: "GET_LEARNERS_SUCCESS",
  GET_LEARNERS_ERROR: "GET_LEARNERS_ERROR",

  DELETE_LEARNER_REQUEST: "DELETE_LEARNER_REQUEST",
  DELETE_LEARNER_SUCCESS: "DELETE_LEARNER_SUCCESS",
  DELETE_LEARNER_ERROR: "DELETE_LEARNER_ERROR",

  UPDATE_LEARNER_DETAILS_REQUEST: "UPDATE_LEARNER_DETAILS_REQUEST",
  UPDATE_LEARNER_DETAILS_SUCCESS: "UPDATE_LEARNER_DETAILS_SUCCESS",
  UPDATE_LEARNER_DETAILS_ERROR: "UPDATE_LEARNER_DETAILS_ERROR",
  SET_UPDATE_LEARNER_DETAILS_DATA: "SET_UPDATE_LEARNER_DETAILS",

  GET_LEARNERS_META_DATA_REQUEST: "GET_LEARNERS_META_DATA_REQUEST",
  GET_LEARNERS_META_DATA_SUCCESS: "GET_LEARNERS_META_DATA_SUCCESS",

  ADD_LEARNER_PROFILE_REQUEST: "ADD_LEARNER_PROFILE_REQUEST",
  ADD_LEARNER_PROFILE_SUCCESS: "ADD_LEARNER_PROFILE_SUCCESS",
  ADD_LEARNER_PROFILE_ERROR: "ADD_LEARNER_PROFILE_ERROR",

  UPDATE_LEARNER_PROFILE_REQUEST: "UPDATE_LEARNER_PROFILE_REQUEST",
  UPDATE_LEARNER_PROFILE_SUCCESS: "UPDATE_LEARNER_PROFILE_SUCCESS",
  UPDATE_LEARNER_PROFILE_ERROR: "UPDATE_LEARNER_PROFILE_ERROR",

  LEARNER_PROFILE_DATA: "LEARNER_PROFILE_DATA",

  UPLOAD_LEARNER_DOCUMENTS_REQUEST: "UPLOAD_LEARNER_DOCUMENTS_REQUEST",
  UPLOAD_LEARNER_DOCUMENTS_SUCCESS: "UPLOAD_LEARNER_DOCUMENTS_SUCCESS",
  UPLOAD_LEARNER_DOCUMENTS_ERROR: "UPLOAD_LEARNER_DOCUMENTS_ERROR",

  UPLOAD_LEARNER_IMAGE_REQUEST: "UPLOAD_LEARNER_IMAGE_REQUEST",
  UPLOAD_LEARNER_IMAGE_SUCCESS: "UPLOAD_LEARNER_IMAGE_SUCCESS",
  UPLOAD_LEARNER_IMAGE_ERROR: "UPLOAD_LEARNER_IMAGE_ERROR",

  DELETE_LEARNER_DOCUMENT_REQUEST: "DELETE_LEARNER_DOCUMENT_REQUEST",
  DELETE_LEARNER_DOCUMENT_SUCCESS: "DELETE_LEARNER_DOCUMENT_SUCCESS",
  DELETE_LEARNER_DOCUMENT_ERROR: "DELETE_LEARNER_DOCUMENT_ERROR",

  getMetaData: () => ({
    type: learners.GET_LEARNERS_META_DATA_REQUEST,
  }),
  getLearners: () => ({
    type: learners.GET_LEARNERS_REQUEST,
  }),
  addLearners: (data) => ({
    type: learners.ADD_LEARNER_REQUEST,
    data,
  }),
  getLearnerDetails: (id) => ({
    type: learners.GET_LEARNER_DETAILS_REQUEST,
    id,
  }),
  deleteLearner: (id) => ({
    type: learners.DELETE_LEARNER_REQUEST,
    id,
  }),
  updateLearnerDetails: (id, data, enrollment_id) => ({
    type: learners.UPDATE_LEARNER_DETAILS_REQUEST,
    id,
    data,
    enrollment_id,
  }),
  setUpdateLearnerDetailsData: (data) => ({
    type: learners.SET_UPDATE_LEARNER_DETAILS_DATA,
    data,
  }),
  addLearnerProfile: (data) => ({
    type: learners.ADD_LEARNER_PROFILE_REQUEST,
    data,
  }),
  updateLearnerProfile: (id, data) => ({
    type: learners.UPDATE_LEARNER_PROFILE_REQUEST,
    id,
    data,
  }),
  setLearnerProfileData: (data) => ({
    type: learners.LEARNER_PROFILE_DATA,
    data,
  }),
  uploadLearnerDocuments: (id, data) => ({
    type: learners.UPLOAD_LEARNER_DOCUMENTS_REQUEST,
    id,
    data,
  }),
  uploadLearnerImage: (id, data) => ({
    type: learners.UPLOAD_LEARNER_IMAGE_REQUEST,
    id,
    data,
  }),
  deleteLearnerDocument: (id, studentId) => ({
    type: learners.DELETE_LEARNER_DOCUMENT_REQUEST,
    id,
    studentId,
  }),
};

export default learners;
