import { takeLatest, put, fork } from "redux-saga/effects";
import axios from "axios";
import { receivablesActions } from "../../../actions";

function* getReceivables() {
  try {
    const response = yield axios({
      url: "/finance/financial-reports/receivables",
    });

    yield put({
      type: receivablesActions.GET_RECEIVABLES_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: receivablesActions.GET_RECEIVABLES_ERROR,
      data: error,
    });
  }
}

function* watchGetReceivables() {
  yield takeLatest(receivablesActions.GET_RECEIVABLES_REQUEST, getReceivables);
}

export default [fork(watchGetReceivables)];
