import { filter, find } from "lodash";
import { enrollmentActions } from "../../../actions";
import enrollmentInitialState from "../../../initialState/administration/enrollment/enrollment.initialState";

const enrollment = (state = enrollmentInitialState, action) => {
  switch (action.type) {
    case enrollmentActions.DE_ENROLL_LEARNER_REQUEST:
      return {
        ...state,
        deEnrollLearnerLoading: true,
        deEnrollLearnerError: {},
      };
    case enrollmentActions.DE_ENROLL_LEARNER_SUCCESS: {
      const learner = find(state.getEnrollmentDetailsSuccess.students, {
        id: action.studentId,
      });

      learner.de_enrolled = action.updatedData.de_enrolled;

      state.getEnrollmentDetailsSuccess.students = [
        learner,
        ...filter(
          state.getEnrollmentDetailsSuccess.students,
          (l) => l.id !== action.studentId
        ),
      ];
      return {
        ...state,
        deEnrollLearnerLoading: false,
      };
    }
    case enrollmentActions.DOWNLOAD_LEARNER_DETAILS_EXCEL_REQUEST:
      return {
        ...state,
        downloadLearnerDetailsExcelLoading: true,
        downloadLearnerDetailsExcelError: {},
      };
    case enrollmentActions.DOWNLOAD_LEARNER_DETAILS_EXCEL_SUCCESS:
      return {
        ...state,
        downloadLearnerDetailsExcelSuccess: action?.data,
        downloadLearnerDetailsExcelLoading: false,
      };
    case enrollmentActions.DOWNLOAD_LEARNER_DETAILS_EXCEL_ERROR:
      return {
        ...state,
        downloadLearnerDetailsExcelError: action.error,
        downloadLearnerDetailsExcelLoading: false,
      };
    case enrollmentActions.DE_ENROLL_LEARNER_ERROR:
      return {
        ...state,
        deEnrollLearnerError: action.error,
        deEnrollLearnerLoading: false,
      };
    case enrollmentActions.GET_ENROLLMENTS_REQUEST:
      return {
        ...state,
        enrollmentsLoading: true,
      };
    case enrollmentActions.GET_ENROLLMENTS_SUCCESS:
      return {
        ...state,
        enrollmentsSuccess: action.data,
        enrollmentsError: {},
        enrollmentsLoading: false,
      };
    case enrollmentActions.GET_ENROLLMENTS_ERROR:
      return {
        ...state,
        enrollmentsError: action.error,
        enrollmentsLoading: false,
      };
    case enrollmentActions.CREATE_STUDENT_BILLING_REQUEST:
      return {
        ...state,
        billStudentsLoading: true,
        billStudentsError: {},
      };
    case enrollmentActions.CREATE_STUDENT_BILLING_SUCCESS:
      return {
        ...state,
        billStudentsSuccess: action.data,
        billStudentsLoading: false,
      };
    case enrollmentActions.CREATE_STUDENT_BILLING_ERROR:
      return {
        ...state,
        billStudentsError: action.error,
        billStudentsLoading: false,
      };
    case enrollmentActions.DELETE_STUDENT_BILLING_REQUEST:
      return {
        ...state,
        deleteBillingLoading: true,
        deleteBillingError: {},
      };
    case enrollmentActions.DELETE_STUDENT_BILLING_SUCCESS:
      return {
        ...state,
        deleteBillingSuccess: action.data,
        deleteBillingLoading: false,
      };
    case enrollmentActions.DELETE_STUDENT_BILLING_ERROR:
      return {
        ...state,
        deleteBillingError: action.error,
        deleteBillingLoading: false,
      };
    case enrollmentActions.GET_ENROLLMENT_DETAILS_REQUEST:
      return {
        ...state,
        getEnrollmentDetailsLoading: true,
      };
    case enrollmentActions.GET_ENROLLMENT_DETAILS_SUCCESS:
      return {
        ...state,
        getEnrollmentDetailsSuccess: action.data,
        getEnrollmentDetailsError: {},
        getEnrollmentDetailsLoading: false,
      };
    case enrollmentActions.GET_ENROLLMENT_DETAILS_ERROR:
      return {
        ...state,
        getEnrollmentDetailsError: action.error,
        getEnrollmentDetailsLoading: false,
      };
    case enrollmentActions.GET_ENROLLMENT_META_DATA_REQUEST:
      return {
        ...state,
        getEnrollmentMetaDataLoading: true,
      };
    case enrollmentActions.GET_ENROLLMENT_META_DATA_SUCCESS:
      return {
        ...state,
        getEnrollmentMetaDataSuccess: action.data,
        getEnrollmentMetaDataError: {},
        getEnrollmentMetaDataLoading: false,
      };
    case enrollmentActions.GET_ENROLLMENT_META_DATA_ERROR:
      return {
        ...state,
        getEnrollmentMetaDataError: action.error,
        getEnrollmentMetaDataLoading: false,
      };
    case enrollmentActions.ADD_ENROLLMENT_REQUEST:
      return {
        ...state,
        addEnrollmentLoading: true,
      };
    case enrollmentActions.ADD_ENROLLMENT_SUCCESS:
      return {
        ...state,
        addEnrollmentSuccess: action.data,
        addEnrollmentError: {},
        addEnrollmentLoading: false,
      };
    case enrollmentActions.ADD_ENROLLMENT_ERROR:
      return {
        ...state,
        addEnrollmentError: action.error,
        addEnrollmentLoading: false,
      };
    case enrollmentActions.ENROLL_STUDENTS_REQUEST:
      return {
        ...state,
        enrollStudentsLoading: true,
      };
    case enrollmentActions.ENROLL_STUDENTS_SUCCESS:
      return {
        ...state,
        enrollStudentsSuccess: action.data,
        enrollStudentsError: {},
        enrollStudentsLoading: false,
      };
    case enrollmentActions.ENROLL_STUDENTS_ERROR:
      return {
        ...state,
        enrollStudentsError: action.error,
        enrollStudentsLoading: false,
      };
    case enrollmentActions.DELETE_ENROLLMENT_REQUEST:
      return {
        ...state,
        deleteEnrollmentLoading: true,
      };
    case enrollmentActions.DELETE_ENROLLMENT_SUCCESS:
      return {
        ...state,
        deleteEnrollmentSuccess: action.data,
        deleteEnrollmentError: {},
        deleteEnrollmentLoading: false,
      };
    case enrollmentActions.DELETE_ENROLLMENT_ERROR:
      return {
        ...state,
        deleteEnrollmentError: action.error,
        deleteEnrollmentLoading: false,
      };
    case enrollmentActions.UNENROLL_STUDENT_REQUEST:
      return {
        ...state,
        unenrollStudentLoading: true,
      };
    case enrollmentActions.UNENROLL_STUDENT_SUCCESS:
      return {
        ...state,
        unenrollStudentSuccess: action.data,
        unenrollStudentError: {},
        unenrollStudentLoading: false,
      };
    case enrollmentActions.UNENROLL_STUDENT_ERROR:
      return {
        ...state,
        unenrollStudentError: action.error,
        unenrollStudentLoading: false,
      };
    case enrollmentActions.UPLOAD_ENROLLMENT_DOCUMENT_REQUEST:
      return {
        ...state,
        uploadEnrollmentDocumentLoading: true,
      };
    case enrollmentActions.UPLOAD_ENROLLMENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        uploadEnrollmentDocumentSuccess: action.data,
        uploadEnrollmentDocumentError: {},
        uploadEnrollmentDocumentLoading: false,
      };
    case enrollmentActions.UPLOAD_ENROLLMENT_DOCUMENT_ERROR:
      return {
        ...state,
        uploadEnrollmentDocumentError: action.error,
        uploadEnrollmentDocumentLoading: false,
      };
    case enrollmentActions.UPDATE_ENROLLMENT_REQUEST:
      return {
        ...state,
        updateEnrollmentLoading: true,
      };
    case enrollmentActions.UPDATE_ENROLLMENT_SUCCESS:
      return {
        ...state,
        updateEnrollmentSuccess: action.data,
        updateEnrollmentError: {},
        updateEnrollmentLoading: false,
      };
    case enrollmentActions.UPDATE_ENROLLMENT_ERROR:
      return {
        ...state,
        updateEnrollmentError: action.error,
        updateEnrollmentLoading: false,
      };
    case enrollmentActions.SET_UPDATE_ENROLLMENT_DATA:
      return {
        ...state,
        updateEnrollmentData: action.data,
      };
    case enrollmentActions.ENROLL_SINGLE_STUDENT_REQUEST:
      return {
        ...state,
        enrollSingleStudentLoading: true,
      };
    case enrollmentActions.ENROLL_SINGLE_STUDENT_SUCCESS:
      return {
        ...state,
        enrollSingleStudentSuccess: action.data,
        enrollSingleStudentError: {},
        enrollSingleStudentLoading: false,
      };
    case enrollmentActions.ENROLL_SINGLE_STUDENT_ERROR:
      return {
        ...state,
        enrollSingleStudentError: action.error,
        enrollSingleStudentLoading: false,
      };
    case enrollmentActions.SEARCH_STUDENTS_REQUEST:
      return {
        ...state,
        searchStudentsLoading: true,
      };
    case enrollmentActions.SEARCH_STUDENTS_SUCCESS:
      return {
        ...state,
        searchStudentsSuccess: action.data,
        searchStudentsError: {},
        searchStudentsLoading: false,
      };
    case enrollmentActions.SEARCH_STUDENTS_ERROR:
      return {
        ...state,
        searchStudentsError: action.error,
        searchStudentsLoading: false,
      };
    case enrollmentActions.JOIN_CLASSES_REQUEST:
      return {
        ...state,
        joiningClasses: true,
        joiningClassesError: {},
      };
    case enrollmentActions.JOIN_CLASSES_SUCCESS:
      return {
        ...state,
        joiningClassesSuccess: action.data,
        joiningClasses: false,
      };
    case enrollmentActions.JOIN_CLASSES_ERROR:
      return {
        ...state,
        joiningClassesError: action.error,
        joiningClasses: false,
      };
    case enrollmentActions.SET_ENROLLMENT_ACADEMIC_YEAR:
      return {
        ...state,
        enrollmentAcademicYear: action.academicYear,
      };
    case enrollmentActions.GET_LEARNER_PROFILE_REQUEST:
      return {
        ...state,
        learnerProfileLoading: true,
      };
    case enrollmentActions.GET_LEARNER_PROFILE_SUCCESS:
      return {
        ...state,
        learnerProfile: action.data,
        learnerProfileError: {},
        learnerProfileLoading: false,
      };
    case enrollmentActions.GET_LEARNER_PROFILE_ERROR:
      return {
        ...state,
        learnerProfileError: action.error,
        learnerProfileLoading: false,
      };
    default:
      return state;
  }
};

export default enrollment;
