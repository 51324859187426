const rejections = {
  ADD_REJECTIONS_REQUEST: "ADD_REJECTIONS_REQUEST",
  ADD_REJECTIONS_SUCCESS: "ADD_REJECTIONS_SUCCESS",
  ADD_REJECTIONS_ERROR: "ADD_REJECTIONS_ERROR",
  SET_REJECTIONS_DATA: "SET_REJECTIONS_DATA",

  addRejection: (data) => ({ type: rejections.ADD_REJECTIONS_REQUEST, data }),
  setRejectionsData: (data) => ({ type: rejections.SET_REJECTIONS_DATA, data }),
};

export default rejections;
