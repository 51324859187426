const assets = {
  GET_ASSETS_REQUEST: "GET_ASSETS_REQUEST",
  GET_ASSETS_SUCCESS: "GET_ASSETS_SUCCESS",
  GET_ASSETS_ERROR: "GET_ASSETS_ERROR",

  GET_ASSETS_META_DATA_REQUEST: "GET_ASSETS_META_DATA_REQUEST",
  GET_ASSETS_META_DATA_SUCCESS: "GET_ASSETS_META_DATA_SUCCESS",

  SET_ADD_ASSET_DATA: "SET_ADD_ASSET_DATA",
  ADD_ASSET_REQUEST: "ADD_ASSET_REQUEST",
  ADD_ASSET_SUCCESS: "ADD_ASSET_SUCCESS",
  ADD_ASSET_ERROR: "ADD_ASSET_ERROR",

  DELETE_ASSET_REQUEST: "DELETE_ASSET_REQUEST",
  DELETE_ASSET_SUCCESS: "DELETE_ASSET_SUCCESS",
  DELETE_ASSET_ERROR: "DELETE_ASSET_ERROR",

  SET_UPDATE_ASSET_UPDATE_DATA: "SET_UPDATE_ASSET_UPDATE_DATA",
  UPDATE_ASSET_REQUEST: "UPDATE_ASSET_REQUEST",
  UPDATE_ASSET_SUCCESS: "UPDATE_ASSET_SUCCESS",
  UPDATE_ASSET_ERROR: "UPDATE_ASSET_ERROR",

  GET_ASSET_DETAILS_REQUEST: "GET_ASSET_DETAILS_REQUEST",
  GET_ASSET_DETAILS_SUCCESS: "GET_ASSET_DETAILS_SUCCESS",
  GET_ASSET_DETAILS_ERROR: "GET_ASSET_DETAILS_ERROR",

  CHECK_ASSET_REQUEST: "CHECK_ASSET_REQUEST",
  CHECK_ASSET_SUCCESS: "CHECK_ASSET_SUCCESS",
  CHECK_ASSET_ERROR: "CHECK_ASSET_ERROR",

  APPROVE_ASSET_REQUEST: "APPROVE_ASSET_REQUEST",
  APPROVE_ASSET_SUCCESS: "APPROVE_ASSET_SUCCESS",
  APPROVE_ASSET_ERROR: "APPROVE_ASSET_ERROR",

  getAssets: () => ({
    type: assets.GET_ASSETS_REQUEST,
  }),
  getMetaData: () => ({
    type: assets.GET_ASSETS_META_DATA_REQUEST,
  }),
  setAddAssetData: (data) => ({
    type: assets.SET_ADD_ASSET_DATA,
    data,
  }),
  addAsset: (data) => ({
    type: assets.ADD_ASSET_REQUEST,
    data,
  }),
  deleteAsset: (data) => ({
    type: assets.DELETE_ASSET_REQUEST,
    data,
  }),
  updateAsset: (data, id) => ({
    type: assets.UPDATE_ASSET_REQUEST,
    data,
    id,
  }),
  setUpdateAssetData: (data) => ({
    type: assets.SET_UPDATE_ASSET_UPDATE_DATA,
    data,
  }),
  getAssetDetails: (data) => ({
    type: assets.GET_ASSET_DETAILS_REQUEST,
    data,
  }),
  checkAsset: (data) => ({
    type: assets.CHECK_ASSET_REQUEST,
    data,
  }),
  approveAsset: (data) => ({
    type: assets.APPROVE_ASSET_REQUEST,
    data,
  }),
};

export default assets;
