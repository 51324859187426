import { recordOfBiddersActions } from "../../../actions";
import recordOfBiddersInitialState from "../../../initialState/procurement/recordOfBidders/recordOfBidders.initialState";
const recordOfBidders = (state = recordOfBiddersInitialState, action) => {
  switch (action.type) {
    case recordOfBiddersActions.GET_RECORD_OF_BIDDERS_REQUEST:
      return {
        ...state,
        recordOfBiddersLoading: true,
      };
    case recordOfBiddersActions.GET_RECORD_OF_BIDDERS_SUCCESS:
      return {
        ...state,
        recordOfBiddersSuccess: action.data,
        recordOfBiddersError: {},
        recordOfBiddersLoading: false,
      };
    case recordOfBiddersActions.GET_RECORD_OF_BIDDERS_ERROR:
      return {
        ...state,
        recordOfBiddersError: action.error,
        recordOfBiddersLoading: false,
      };
    case recordOfBiddersActions.ADD_RECORD_OF_BIDDERS_REQUEST:
      return {
        ...state,
        addRecordOfBiddersLoading: true,
      };
    case recordOfBiddersActions.ADD_RECORD_OF_BIDDERS_SUCCESS:
      return {
        ...state,
        addRecordOfBiddersSuccess: action.data,
        addRecordOfBiddersError: {},
        addRecordOfBiddersLoading: false,
      };
    case recordOfBiddersActions.ADD_RECORD_OF_BIDDERS_ERROR:
      return {
        ...state,
        addRecordOfBiddersError: action.error,
        addRecordOfBiddersLoading: false,
      };
    case recordOfBiddersActions.GET_RECORD_OF_BIDDERS_META_DATA_REQUEST:
      return {
        ...state,
        recordOfBiddersMetaDataLoading: true,
      };
    case recordOfBiddersActions.GET_RECORD_OF_BIDDERS_META_DATA_SUCCESS:
      return {
        ...state,
        recordOfBiddersMetaDataSuccess: action.data,
        recordOfBiddersMetaDataError: {},
        recordOfBiddersMetaDataLoading: false,
      };
    case recordOfBiddersActions.GET_RECORD_OF_BIDDERS_META_DATA_ERROR:
      return {
        ...state,
        recordOfBiddersMetaDataError: action.error,
        recordOfBiddersMetaDataLoading: false,
      };
    case recordOfBiddersActions.GET_RECORD_OF_BIDDERS_DETAILS_REQUEST:
      return {
        ...state,
        recordOfBiddersDetailsLoading: true,
      };
    case recordOfBiddersActions.GET_RECORD_OF_BIDDERS_DETAILS_SUCCESS:
      return {
        ...state,
        recordOfBiddersDetailsSuccess: action.data,
        recordOfBiddersDetailsError: {},
        recordOfBiddersDetailsLoading: false,
      };
    case recordOfBiddersActions.GET_RECORD_OF_BIDDERS_DETAILS_ERROR:
      return {
        ...state,
        recordOfBiddersDetailsError: action.error,
        recordOfBiddersDetailsLoading: false,
      };
    case recordOfBiddersActions.UPDATE_RECORD_OF_BIDDERS_DETAILS_REQUEST:
      return {
        ...state,
        updateRecordOfBiddersDetailsLoading: true,
      };
    case recordOfBiddersActions.UPDATE_RECORD_OF_BIDDERS_DETAILS_SUCCESS:
      return {
        ...state,
        updateRecordOfBiddersDetailsSuccess: action.data,
        updateRecordOfBiddersDetailsError: {},
        updateRecordOfBiddersDetailsLoading: false,
      };
    case recordOfBiddersActions.UPDATE_RECORD_OF_BIDDERS_DETAILS_ERROR:
      return {
        ...state,
        updateRecordOfBiddersDetailsError: action.error,
        updateRecordOfBiddersDetailsLoading: false,
      };
    case recordOfBiddersActions.SET_UPDATE_RECORD_OF_BIDDERS_DETAILS_DATA:
      return {
        ...state,
        updateRecordOfBiddersDetailsData: action.data,
      };
    case recordOfBiddersActions.DELETE_RECORD_OF_BIDDERS_REQUEST:
      return {
        ...state,
        deleteRecordOfBiddersLoading: true,
      };
    case recordOfBiddersActions.DELETE_RECORD_OF_BIDDERS_SUCCESS:
      return {
        ...state,
        deleteRecordOfBiddersSuccess: action.data,
        deleteRecordOfBiddersError: {},
        deleteRecordOfBiddersLoading: false,
      };
    case recordOfBiddersActions.DELETE_RECORD_OF_BIDDERS_ERROR:
      return {
        ...state,
        deleteRecordOfBiddersError: action.error,
        deleteRecordOfBiddersLoading: false,
      };
    case recordOfBiddersActions.CHECK_RECORD_OF_BIDDERS_REQUEST:
      return {
        ...state,
        checkRecordOfBiddersLoading: true,
      };
    case recordOfBiddersActions.CHECK_RECORD_OF_BIDDERS_SUCCESS:
      return {
        ...state,
        checkRecordOfBiddersSuccess: action.data,
        checkRecordOfBiddersError: {},
        checkRecordOfBiddersLoading: false,
      };
    case recordOfBiddersActions.CHECK_RECORD_OF_BIDDERS_ERROR:
      return {
        ...state,
        checkRecordOfBiddersError: action.error,
        checkRecordOfBiddersLoading: false,
      };
    case recordOfBiddersActions.APPROVE_RECORD_OF_BIDDERS_REQUEST:
      return {
        ...state,
        approveRecordOfBiddersLoading: true,
      };
    case recordOfBiddersActions.APPROVE_RECORD_OF_BIDDERS_SUCCESS:
      return {
        ...state,
        approveRecordOfBiddersSuccess: action.data,
        approveRecordOfBiddersError: {},
        approveRecordOfBiddersLoading: false,
      };
    case recordOfBiddersActions.APPROVE_RECORD_OF_BIDDERS_ERROR:
      return {
        ...state,
        approveRecordOfBiddersError: action.error,
        approveRecordOfBiddersLoading: false,
      };

    //Bidders
    case recordOfBiddersActions.GET_BIDDERS_REQUEST:
      return {
        ...state,
        biddersLoading: true,
      };
    case recordOfBiddersActions.GET_BIDDERS_SUCCESS:
      return {
        ...state,
        biddersSuccess: action.data,
        biddersError: {},
        biddersLoading: false,
      };
    case recordOfBiddersActions.GET_BIDDERS_ERROR:
      return {
        ...state,
        biddersError: action.error,
        biddersLoading: false,
      };
    case recordOfBiddersActions.GET_BIDDERS_META_DATA_REQUEST:
      return {
        ...state,
        biddersMetaDataLoading: true,
      };
    case recordOfBiddersActions.GET_BIDDERS_META_DATA_SUCCESS:
      return {
        ...state,
        biddersMetaDataSuccess: action.data,
        biddersMetaDataError: {},
        biddersMetaDataLoading: false,
      };
    case recordOfBiddersActions.GET_BIDDERS_META_DATA_ERROR:
      return {
        ...state,
        biddersMetaDataError: action.error,
        biddersMetaDataLoading: false,
      };
    case recordOfBiddersActions.GET_BIDDER_DETAILS_REQUEST:
      return {
        ...state,
        bidderDetailsLoading: true,
      };
    case recordOfBiddersActions.GET_BIDDER_DETAILS_SUCCESS:
      return {
        ...state,
        bidderDetailsSuccess: action.data,
        bidderDetailsError: {},
        bidderDetailsLoading: false,
      };
    case recordOfBiddersActions.GET_BIDDER_DETAILS_ERROR:
      return {
        ...state,
        bidderDetailsError: action.error,
        bidderDetailsLoading: false,
      };
    case recordOfBiddersActions.ADD_BIDDER_REQUEST:
      return {
        ...state,
        addBidderLoading: true,
      };
    case recordOfBiddersActions.ADD_BIDDER_SUCCESS:
      return {
        ...state,
        addBidderSuccess: action.data,
        addBidderError: {},
        addBidderLoading: false,
      };
    case recordOfBiddersActions.ADD_BIDDER_ERROR:
      return {
        ...state,
        addBidderError: action.error,
        addBidderLoading: false,
      };
    case recordOfBiddersActions.DELETE_BIDDER_REQUEST:
      return {
        ...state,
        deleteBidderLoading: true,
      };
    case recordOfBiddersActions.DELETE_BIDDER_SUCCESS:
      return {
        ...state,
        deleteBidderSuccess: action.data,
        deleteBidderError: {},
        deleteBidderLoading: false,
      };
    case recordOfBiddersActions.DELETE_BIDDER_ERROR:
      return {
        ...state,
        deleteBidderError: action.error,
        deleteBidderLoading: false,
      };

    case recordOfBiddersActions.UPDATE_BIDDER_DETAILS_REQUEST:
      return {
        ...state,
        updateBidderDetailsLoading: true,
      };
    case recordOfBiddersActions.UPDATE_BIDDER_DETAILS_SUCCESS:
      return {
        ...state,
        updateBidderDetailsSuccess: action.data,
        updateBidderDetailsError: {},
        updateBidderDetailsLoading: false,
      };
    case recordOfBiddersActions.UPDATE_BIDDER_DETAILS_ERROR:
      return {
        ...state,
        updateBidderDetailsError: action.error,
        updateBidderDetailsLoading: false,
      };
    case recordOfBiddersActions.SET_UPDATE_BIDDER_DETAILS_DATA:
      return {
        ...state,
        updateBidderDetailsData: action.data,
      };
    default:
      return state;
  }
};

export default recordOfBidders;
