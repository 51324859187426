import { takeLatest, put, fork, takeEvery } from "@redux-saga/core/effects";
import axios from "axios";
import {
  appUiActions,
  chartOfAccountsActions,
  clientInvoicesActions,
  budgetActions,
  financeStreamsActions,
  incomeReceiptActions,
  banksActions,
} from "../../../actions";

function* getChartOfAccounts() {
  try {
    const data = yield axios({
      url: "/finance/chart-of-accounts/",
    });

    yield put({
      type: chartOfAccountsActions.GET_CHART_OF_ACCOUNTS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: chartOfAccountsActions.GET_CHART_OF_ACCOUNTS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetChartOfAccounts() {
  yield takeLatest(
    chartOfAccountsActions.GET_CHART_OF_ACCOUNTS_REQUEST,
    getChartOfAccounts
  );
}

function* addAccountType({ data }) {
  try {
    const response = yield axios({
      url: "/finance/chart-of-accounts/account-types",
      method: "POST",
      data,
    });
    yield put({
      type: chartOfAccountsActions.ADD_ACCOUNT_TYPE_SUCCESS,
      data: response,
    });

    yield put({ type: chartOfAccountsActions.GET_CHART_OF_ACCOUNTS_REQUEST });
  } catch (error) {
    yield put({
      type: chartOfAccountsActions.ADD_ACCOUNT_TYPE_ERROR,
      error: error.data,
    });
  }
}

function* watchAddAccountType() {
  yield takeLatest(
    chartOfAccountsActions.ADD_ACCOUNT_TYPE_REQUEST,
    addAccountType
  );
}

function* deleteAccountType({ data }) {
  try {
    const response = yield axios({
      url: `/finance/chart-of-accounts/account-types/${data}`,
      method: "DELETE",
    });
    yield put({
      type: chartOfAccountsActions.DELETE_ACCOUNT_TYPE_SUCCESS,
      data: response,
    });

    yield put({ type: chartOfAccountsActions.GET_CHART_OF_ACCOUNTS_REQUEST });
  } catch (error) {
    yield put({
      type: chartOfAccountsActions.DELETE_ACCOUNT_TYPE_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteAccountType() {
  yield takeEvery(
    chartOfAccountsActions.DELETE_ACCOUNT_TYPE_REQUEST,
    deleteAccountType
  );
}

function* updateAccountType({ data, id }) {
  try {
    const response = yield axios({
      url: `/finance/chart-of-accounts/account-types/${id}`,
      data,
      method: "PUT",
    });

    yield put({
      type: chartOfAccountsActions.UPDATE_ACCOUNT_TYPE_SUCCESS,
      data: response,
    });
    yield put({ type: chartOfAccountsActions.GET_CHART_OF_ACCOUNTS_REQUEST });
    yield put({
      type: appUiActions.TOGGLE_UPDATE_ACCOUNT_TYPES_MODAL,
      data: false,
    });
  } catch (error) {
    yield put({
      type: chartOfAccountsActions.UPDATE_ACCOUNT_TYPE_REQUEST,
      error: error.data,
    });
  }
}

function* watchUpdateAccountType() {
  yield takeLatest(
    chartOfAccountsActions.UPDATE_ACCOUNT_TYPE_REQUEST,
    updateAccountType
  );
}

function* getAccountTypeDetails({ data }) {
  try {
    const response = yield axios({
      url: `/finance/chart-of-accounts/account-types/${data}`,
    });

    yield put({
      type: chartOfAccountsActions.GET_ACCOUNT_TYPE_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: chartOfAccountsActions.GET_ACCOUNT_TYPE_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAccountTypeDetails() {
  yield takeLatest(
    chartOfAccountsActions.GET_ACCOUNT_TYPE_DETAILS_REQUEST,
    getAccountTypeDetails
  );
}

function* checkAccountType({ data }) {
  try {
    const response = yield axios({
      url: `/finance/chart-of-accounts/account-types/check/${data}`,
      data: { checked_at: new Date() },
      method: "PATCH",
    });

    yield put({
      type: chartOfAccountsActions.CHECK_ACCOUNT_TYPE_SUCCESS,
      data: response,
    });

    yield put({
      type: chartOfAccountsActions.GET_ACCOUNT_TYPE_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: chartOfAccountsActions.CHECK_ACCOUNT_TYPE_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckAccountType() {
  yield takeLatest(
    chartOfAccountsActions.CHECK_ACCOUNT_TYPE_REQUEST,
    checkAccountType
  );
}

function* approveAccountType({ data }) {
  try {
    const response = yield axios({
      url: `/finance/chart-of-accounts/account-types/approve/${data}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: chartOfAccountsActions.APPROVE_ACCOUNT_TYPE_SUCCESS,
      data: response,
    });

    yield put({
      type: chartOfAccountsActions.GET_ACCOUNT_TYPE_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: chartOfAccountsActions.APPROVE_ACCOUNT_TYPE_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveAccountType() {
  yield takeLatest(
    chartOfAccountsActions.APPROVE_ACCOUNT_TYPE_REQUEST,
    approveAccountType
  );
}

function* addAccounts({ data }) {
  try {
    const response = yield axios({
      url: "/finance/chart-of-accounts/accounts",
      method: "POST",
      data,
    });

    yield put({
      type: chartOfAccountsActions.ADD_ACCOUNT_SUCCESS,
      data: response,
    });

    yield put({ type: chartOfAccountsActions.GET_CHART_OF_ACCOUNTS_REQUEST });
    yield put(clientInvoicesActions.getClientInvoicesMetaData());
    yield put(incomeReceiptActions.getMetaData());
    yield put(budgetActions.getMetaData());
    yield put(financeStreamsActions.getMetaData());
    yield put(banksActions.getMetaData());
  } catch (error) {
    yield put({
      type: chartOfAccountsActions.ADD_ACCOUNT_ERROR,
      error: error.data,
    });
  }
}

function* watchAddAccounts() {
  yield takeLatest(chartOfAccountsActions.ADD_ACCOUNT_REQUEST, addAccounts);
}

function* deleteAccount({ data }) {
  try {
    const response = yield axios({
      url: `/finance/chart-of-accounts/accounts/${data}`,
      method: "DELETE",
    });

    yield put({
      type: chartOfAccountsActions.DELETE_ACCOUNT_SUCCESS,
      data: response,
    });

    yield put({ type: chartOfAccountsActions.GET_CHART_OF_ACCOUNTS_REQUEST });
    yield put(clientInvoicesActions.getClientInvoicesMetaData());
  } catch (error) {
    yield put({
      type: chartOfAccountsActions.DELETE_ACCOUNT_ERROR,
      error: error.data,
    });
  }
}

function* watchDeleteAccount() {
  yield takeLatest(
    chartOfAccountsActions.DELETE_ACCOUNT_REQUEST,
    deleteAccount
  );
}

function* updateAccount({ data, id }) {
  try {
    const response = yield axios({
      url: `/finance/chart-of-accounts/accounts/${id}`,
      data,
      method: "PUT",
    });

    yield put({
      type: chartOfAccountsActions.UPDATE_ACCOUNT_SUCCESS,
      data: response,
    });

    yield put({ type: chartOfAccountsActions.GET_CHART_OF_ACCOUNTS_REQUEST });
    yield put({ type: appUiActions.TOGGLE_UPDATE_ACCOUNT_MODAL, data: false });
    yield put(clientInvoicesActions.getClientInvoicesMetaData());
  } catch (error) {
    yield put({
      type: chartOfAccountsActions.UPDATE_ACCOUNT_ERROR,
      error: error.data,
    });
  }
}

function* watchUpdateAccount() {
  yield takeLatest(
    chartOfAccountsActions.UPDATE_ACCOUNT_REQUEST,
    updateAccount
  );
}

function* getAccountDetails({ data }) {
  try {
    const response = yield axios({
      url: `/finance/chart-of-accounts/accounts/${data}`,
    });

    yield put({
      type: chartOfAccountsActions.GET_ACCOUNT_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: chartOfAccountsActions.GET_ACCOUNT_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAccountDetails() {
  yield takeLatest(
    chartOfAccountsActions.GET_ACCOUNT_DETAILS_REQUEST,
    getAccountDetails
  );
}

function* checkAccount({ data }) {
  try {
    const response = yield axios({
      url: `/finance/chart-of-accounts/accounts/check/${data}`,
      method: "PATCH",
      data: { checked_at: new Date() },
    });

    yield put({
      type: chartOfAccountsActions.CHECK_ACCOUNT_SUCCESS,
      data: response,
    });

    yield put({
      type: chartOfAccountsActions.GET_ACCOUNT_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: chartOfAccountsActions.CHECK_ACCOUNT_ERROR,
      error: error.data,
    });
  }
}

function* watchCheckAccount() {
  yield takeLatest(chartOfAccountsActions.CHECK_ACCOUNT_REQUEST, checkAccount);
}

function* approveAccount({ data }) {
  try {
    const response = yield axios({
      url: `/finance/chart-of-accounts/accounts/approve/${data}`,
      method: "PATCH",
      data: { approved_at: new Date() },
    });

    yield put({
      type: chartOfAccountsActions.APPROVE_ACCOUNT_SUCCESS,
      data: response,
    });

    yield put({
      type: chartOfAccountsActions.GET_ACCOUNT_DETAILS_REQUEST,
      data,
    });
  } catch (error) {
    yield put({
      type: chartOfAccountsActions.APPROVE_ACCOUNT_ERROR,
      error: error.data,
    });
  }
}

function* watchApproveAccount() {
  yield takeLatest(
    chartOfAccountsActions.APPROVE_ACCOUNT_REQUEST,
    approveAccount
  );
}

function* createChartOfAccounts() {
  try {
    const response = yield axios({
      url: "/finance/chart-of-accounts",
      method: "POST",
    });

    yield put({
      type: chartOfAccountsActions.CREATE_COA_SUCCESS,
      data: response,
    });

    yield put({ type: chartOfAccountsActions.GET_CHART_OF_ACCOUNTS_REQUEST });
  } catch (error) {
    yield put({
      type: chartOfAccountsActions.CREATE_COA_ERROR,
      error: error.data,
    });
  }
}

function* watchCreateChartOfAccounts() {
  yield takeLatest(
    chartOfAccountsActions.CREATE_COA_REQUEST,
    createChartOfAccounts
  );
}

export default [
  fork(watchGetChartOfAccounts),
  fork(watchAddAccountType),
  fork(watchDeleteAccountType),
  fork(watchUpdateAccountType),
  fork(watchGetAccountTypeDetails),
  fork(watchCheckAccountType),
  fork(watchApproveAccountType),
  fork(watchAddAccounts),
  fork(watchDeleteAccount),
  fork(watchUpdateAccount),
  fork(watchGetAccountDetails),
  fork(watchCheckAccount),
  fork(watchApproveAccount),
  fork(watchCreateChartOfAccounts),
];
