const organisationDetails = {
  GET_ORGANISATION_DETAILS_REQUEST: "GET_ORGANISATION_DETAILS_REQUEST",
  GET_ORGANISATION_DETAILS_SUCCESS: "GET_ORGANISATION_DETAILS_SUCCESS",
  GET_ORGANISATION_DETAILS_ERROR: "GET_ORGANISATION_DETAILS_ERROR",

  ADD_ORGANISATION_DETAILS_REQUEST: "ADD_ORGANISATION_DETAILS_REQUEST",
  ADD_ORGANISATION_DETAILS_SUCCESS: "ADD_ORGANISATION_DETAILS_SUCCESS",
  ADD_ORGANISATION_DETAILS_ERROR: "ADD_ORGANISATION_DETAILS_ERROR",

  UPDATE_ORGANISATION_DETAILS_REQUEST: "UPDATE_ORGANISATION_DETAILS_REQUEST",
  UPDATE_ORGANISATION_DETAILS_SUCCESS: "UPDATE_ORGANISATION_DETAILS_SUCCESS",
  UPDATE_ORGANISATION_DETAILS_ERROR: "UPDATE_ORGANISATION_DETAILS_ERROR",

  UPLOAD_ORGANISATION_LOGO_REQUEST: "UPLOAD_ORGANISATION_LOGO_REQUEST",
  UPLOAD_ORGANISATION_LOGO_SUCCESS: "UPLOAD_ORGANISATION_LOGO_SUCCESS",
  UPLOAD_ORGANISATION_LOGO_ERROR: "UPLOAD_ORGANISATION_LOGO_ERROR",

  getOrganisationDetails: () => ({
    type: organisationDetails.GET_ORGANISATION_DETAILS_REQUEST,
  }),
  addOrganisationDetails: (data) => ({
    type: organisationDetails.ADD_ORGANISATION_DETAILS_REQUEST,
    data,
  }),
  uploadOrganisationLogo: (data, id) => ({
    type: organisationDetails.UPLOAD_ORGANISATION_LOGO_REQUEST,
    data,
    id,
  }),
  updateOrganisationDetails: (data, id) => ({
    type: organisationDetails.UPDATE_ORGANISATION_DETAILS_REQUEST,
    data,
    id,
  }),
};

export default organisationDetails;
