import { marksUploadActions } from "../../../actions";
import initialState from "../../../initialState";

const marksUpload = (state = initialState.marksUpload, actions) => {
  switch (actions.type) {
    case marksUploadActions.GET_MARKS_UPLOAD_REQUEST:
      return { ...state, marksUploadLoading: true, marksUploadError: {} };
    case marksUploadActions.GET_MARKS_UPLOAD_SUCCESS:
      return {
        ...state,
        marksUploadLoading: false,
        marksUploadSuccess: actions.data,
      };
    case marksUploadActions.GET_MARKS_UPLOAD_ERROR:
      return {
        ...state,
        marksUploadLoading: false,
        marksUploadError: actions.error,
      };
    case marksUploadActions.GET_META_DATA_REQUEST:
      return { ...state, metaDataLoading: true, metaDataError: {} };
    case marksUploadActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataLoading: false,
        metaData: actions.data,
      };
    case marksUploadActions.GET_META_DATA_ERROR:
      return {
        ...state,
        metaDataLoading: false,
        metaDataError: actions.error,
      };
    case marksUploadActions.GET_MARKS_UPLOAD_DETAILS_REQUEST:
      return {
        ...state,
        marksUploadDetailsLoading: true,
        marksUploadDetailsError: {},
      };
    case marksUploadActions.GET_MARKS_UPLOAD_DETAILS_SUCCESS:
      return {
        ...state,
        marksUploadDetailsLoading: false,
        marksUploadDetailsSuccess: actions.data,
      };
    case marksUploadActions.GET_MARKS_UPLOAD_DETAILS_ERROR:
      return {
        ...state,
        marksUploadDetailsLoading: false,
        marksUploadDetailsError: actions.error,
      };
    case marksUploadActions.ADD_MARKS_UPLOAD_REQUEST:
      return { ...state, addMarksUploadLoading: true, addMarksUploadError: {} };
    case marksUploadActions.ADD_MARKS_UPLOAD_SUCCESS:
      return {
        ...state,
        addMarksUploadLoading: false,
        addMarksUploadSuccess: actions.data,
      };
    case marksUploadActions.ADD_MARKS_UPLOAD_ERROR:
      return {
        ...state,
        addMarksUploadLoading: false,
        addMarksUploadError: actions.error,
      };
    case marksUploadActions.UPDATE_MARKS_UPLOAD_REQUEST:
      return {
        ...state,
        updateMarksUploadLoading: true,
        updateMarksUploadError: {},
      };
    case marksUploadActions.UPDATE_MARKS_UPLOAD_SUCCESS:
      return {
        ...state,
        updateMarksUploadLoading: false,
        updateMarksUploadSuccess: actions.data,
      };
    case marksUploadActions.UPDATE_MARKS_UPLOAD_ERROR:
      return {
        ...state,
        updateMarksUploadLoading: false,
        updateMarksUploadError: actions.error,
      };
    case marksUploadActions.UPDATE_MARKS_UPLOAD_DATA:
      return {
        ...state,
        updateMarksUploadData: actions.data,
      };
    case marksUploadActions.DELETE_MARKS_UPLOAD_REQUEST:
      return {
        ...state,
        deleteMarksUploadLoading: true,
        deleteMarksUploadError: {},
      };
    case marksUploadActions.DELETE_MARKS_UPLOAD_SUCCESS:
      return {
        ...state,
        deleteMarksUploadLoading: false,
        deleteMarksUploadSuccess: actions.data,
      };
    case marksUploadActions.DELETE_MARKS_UPLOAD_ERROR:
      return {
        ...state,
        deleteMarksUploadLoading: false,
        deleteMarksUploadError: actions.error,
      };
    case marksUploadActions.SUBMIT_MARKS_UPLOAD_REQUEST:
      return {
        ...state,
        submitMarksUploadLoading: true,
        submitMarksUploadError: {},
      };
    case marksUploadActions.SUBMIT_MARKS_UPLOAD_SUCCESS:
      return {
        ...state,
        submitMarksUploadLoading: false,
        submitMarksUploadSuccess: actions.data,
      };
    case marksUploadActions.SUBMIT_MARKS_UPLOAD_ERROR:
      return {
        ...state,
        submitMarksUploadLoading: false,
        submitMarksUploadError: actions.error,
      };
    case marksUploadActions.CHECK_MARKS_UPLOAD_REQUEST:
      return {
        ...state,
        checkMarksUploadLoading: true,
        checkMarksUploadError: {},
      };
    case marksUploadActions.CHECK_MARKS_UPLOAD_SUCCESS:
      return {
        ...state,
        checkMarksUploadLoading: false,
        checkMarksUploadSuccess: actions.data,
      };
    case marksUploadActions.CHECK_MARKS_UPLOAD_ERROR:
      return {
        ...state,
        checkMarksUploadLoading: false,
        checkMarksUploadError: actions.error,
      };
    case marksUploadActions.APPROVE_MARKS_UPLOAD_REQUEST:
      return {
        ...state,
        approveMarksUploadLoading: true,
        approveMarksUploadError: {},
      };
    case marksUploadActions.APPROVE_MARKS_UPLOAD_SUCCESS:
      return {
        ...state,
        approveMarksUploadLoading: false,
        approveMarksUploadSuccess: actions.data,
      };
    case marksUploadActions.APPROVE_MARKS_UPLOAD_ERROR:
      return {
        ...state,
        approveMarksUploadLoading: false,
        approveMarksUploadError: actions.error,
      };
    case marksUploadActions.ADD_STUDENT_MARKS_REQUEST:
      return {
        ...state,
        addStudentMarksLoading: true,
        addStudentMarksError: {},
      };
    case marksUploadActions.ADD_STUDENT_MARKS_SUCCESS:
      return {
        ...state,
        addStudentMarksLoading: false,
        addStudentMarksSuccess: actions.data,
      };
    case marksUploadActions.ADD_STUDENT_MARKS_ERROR:
      return {
        ...state,
        addStudentMarksLoading: false,
        addStudentMarksError: actions.error,
      };
    case marksUploadActions.UPDATE_STUDENT_MARKS_DATA:
      return {
        ...state,
        updateStudentMarksData: actions.data,
      };
    case marksUploadActions.UPDATE_STUDENT_MARKS_REQUEST:
      return {
        ...state,
        updateStudentMarksLoading: true,
        updateStudentMarksError: {},
      };
    case marksUploadActions.UPDATE_STUDENT_MARKS_SUCCESS:
      return {
        ...state,
        updateStudentMarksLoading: false,
        updateStudentMarksSuccess: actions.data,
      };
    case marksUploadActions.UPDATE_STUDENT_MARKS_ERROR:
      return {
        ...state,
        updateStudentMarksLoading: false,
        updateStudentMarksError: actions.error,
      };
    case marksUploadActions.DOWNLOAD_STUDENT_MARKS_EXCEL_REQUEST:
      return {
        ...state,
        downloadStudentMarksExcelLoading: true,
        downloadStudentMarksExcelError: {},
      };
    case marksUploadActions.DOWNLOAD_STUDENT_MARKS_EXCEL_SUCCESS:
      return {
        ...state,
        downloadStudentMarksExcelLoading: false,
        downloadStudentMarksExcelSuccess: actions.data,
      };
    case marksUploadActions.DOWNLOAD_STUDENT_MARKS_EXCEL_ERROR:
      return {
        ...state,
        downloadStudentMarksExcelLoading: false,
        downloadStudentMarksExcelError: actions.error,
      };
    case marksUploadActions.UPLOAD_STUDENT_MARKS_EXCEL_REQUEST:
      return {
        ...state,
        uploadStudentMarksExcelLoading: true,
        uploadStudentMarksExcelError: {},
      };
    case marksUploadActions.UPLOAD_STUDENT_MARKS_EXCEL_SUCCESS:
      return {
        ...state,
        uploadStudentMarksExcelLoading: false,
        uploadStudentMarksExcelSuccess: actions.data,
      };
    case marksUploadActions.UPLOAD_STUDENT_MARKS_EXCEL_ERROR:
      return {
        ...state,
        uploadStudentMarksExcelLoading: false,
        uploadStudentMarksExcelError: actions.error,
      };
    case marksUploadActions.MARKS_EXCEL_UPLOADED:
      return {
        ...state,
        marksExcelUploaded: actions.data,
      };
    case marksUploadActions.UPLOAD_STUDENT_MARKS_REQUEST:
      return {
        ...state,
        uploadStudentMarksLoading: true,
        uploadStudentMarksError: {},
      };
    case marksUploadActions.UPLOAD_STUDENT_MARKS_SUCCESS:
      return {
        ...state,
        uploadStudentMarksLoading: false,
        uploadStudentMarksSuccess: actions.data,
      };
    case marksUploadActions.UPLOAD_STUDENT_MARKS_ERROR:
      return {
        ...state,
        uploadStudentMarksLoading: false,
        uploadStudentMarksError: actions.error,
      };
    default:
      return state;
  }
};

export default marksUpload;
