const logDetails = {
  GET_ORGANISATION_LOG_DETAILS_REQUEST: "GET_ORGANISATION_LOG_DETAILS_REQUEST",
  GET_ORGANISATION_LOG_DETAILS_SUCCESS: "GET_ORGANISATION_LOG_DETAILS_SUCCESS",
  GET_ORGANISATION_LOG_DETAILS_ERROR: "GET_ORGANISATION_LOG_DETAILS_ERROR",

  getOrganisationLogDetails: (data) => ({
    type: logDetails.GET_ORGANISATION_LOG_DETAILS_REQUEST,
    data,
  }),
};

export default logDetails;
