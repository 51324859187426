import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
import { schoolCommunicationsActions } from "../../../actions";

function* getSchoolCommunications() {
  try {
    const response = yield axios({
      url: "/administration/school-communications/",
    });

    yield put({
      type: schoolCommunicationsActions.GET_SCHOOL_COMMUNICATIONS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: schoolCommunicationsActions.GET_SCHOOL_COMMUNICATIONS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSchoolCommunications() {
  yield takeLatest(
    schoolCommunicationsActions.GET_SCHOOL_COMMUNICATIONS_REQUEST,
    getSchoolCommunications
  );
}

function* getSchoolCommunicationDetails({ id }) {
  try {
    const response = yield axios({
      url: `/administration/school-communications/${id}`,
    });

    yield put({
      type: schoolCommunicationsActions.GET_SCHOOL_COMMUNICATION_DETAILS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: schoolCommunicationsActions.GET_SCHOOL_COMMUNICATION_DETAILS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetSchoolCommunicationDetails() {
  yield takeLatest(
    schoolCommunicationsActions.GET_SCHOOL_COMMUNICATION_DETAILS_REQUEST,
    getSchoolCommunicationDetails
  );
}

function* addGeneralCommunication({ data }) {
  try {
    const response = yield axios({
      url: `/administration/school-communications/general-communication`,
      method: "POST",
      data,
    });

    yield put({
      type: schoolCommunicationsActions.ADD_GENERAL_SCHOOL_COMMUNICATION_SUCCESS,
      data: response,
    });

    yield put(schoolCommunicationsActions.getSchoolCommunications());
  } catch (error) {
    yield put({
      type: schoolCommunicationsActions.ADD_GENERAL_SCHOOL_COMMUNICATION_ERROR,
      error: error.data,
    });
  }
}

function* watchAddGeneralCommunication() {
  yield takeLatest(
    schoolCommunicationsActions.ADD_GENERAL_SCHOOL_COMMUNICATION_REQUEST,
    addGeneralCommunication
  );
}

function* getMetaData() {
  try {
    const response = yield axios({
      url: `/administration/school-communications/meta-data`,
    });

    yield put({
      type: schoolCommunicationsActions.GET_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: schoolCommunicationsActions.GET_META_DATA_ERROR,
      error: error.data,
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(
    schoolCommunicationsActions.GET_META_DATA_REQUEST,
    getMetaData
  );
}

function* sendDefaulterCommunication({ data }) {
  try {
    const response = yield axios({
      url: `/administration/school-communications/send-defaulter-communication`,
      method: "POST",
      data,
    });

    yield put({
      type: schoolCommunicationsActions.SEND_DEFAULTER_COMMUNICATION_SUCCESS,
      data: response,
    });
    yield put(schoolCommunicationsActions.getSchoolCommunications());
  } catch (error) {
    yield put({
      type: schoolCommunicationsActions.SEND_DEFAULTER_COMMUNICATION_ERROR,
      error: error.data,
    });
  }
}

function* watchSendDefaulterCommunication() {
  yield takeLatest(
    schoolCommunicationsActions.SEND_DEFAULTER_COMMUNICATION_REQUEST,
    sendDefaulterCommunication
  );
}

export default [
  fork(watchGetSchoolCommunications),
  fork(watchGetSchoolCommunicationDetails),
  fork(watchAddGeneralCommunication),
  fork(watchGetMetaData),
  fork(watchSendDefaulterCommunication),
];
