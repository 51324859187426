import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
const FileDownload = require("js-file-download");
import { serverActions } from "../../actions";

function* downloadExcel({ data }) {
  try {
    const response = yield axios({
      url: "/administration/enrollment/download-new-learners-template",
      params: {
        excelTemplate: data,
      },
      method: "GET",
      responseType: "blob",
    });

    FileDownload(response.data, `${data}.xlsx`);

    yield put({
      type: serverActions.DOWNLOAD_EXCEL_SUCCESS,
    });
    yield put({
      type: serverActions.SERVER_SUCCESS,
      data: {
        server: { status: true, message: "Successfully downloaded excel" },
        message: "Successfully Downloaded template",
      },
    });
  } catch (error) {
    yield put({
      type: serverActions.SERVER_ERROR,
      data: {
        server: { status: false, message: "Unable to download excel" },
        message: "Unable to Download template",
      },
    });
    yield put({
      type: serverActions.DOWNLOAD_EXCEL_ERROR,
      error: {
        serverError: { message: "Error" },
        message: "Internal Server Error",
      },
    });
  }
}

function* watchDownloadExcel() {
  yield takeLatest(serverActions.DOWNLOAD_EXCEL_REQUEST, downloadExcel);
}

export default [fork(watchDownloadExcel)];
