const brmsCompliance = {
  GET_BRMS_COMPLIANCE_REQUEST: "GET_BRMS_COMPLIANCE_REQUEST",
  GET_BRMS_COMPLIANCE_SUCCESS: "GET_BRMS_COMPLIANCE_SUCCESS",
  GET_BRMS_COMPLIANCE_ERROR: "GET_BRMS_COMPLIANCE_ERROR",

  ADD_BRMS_COMPLIANCE_REQUEST: "ADD_BRMS_COMPLIANCE_REQUEST",
  ADD_BRMS_COMPLIANCE_SUCCESS: "ADD_BRMS_COMPLIANCE_SUCCESS",
  ADD_BRMS_COMPLIANCE_ERROR: "ADD_BRMS_COMPLIANCE_ERROR",

  UPDATE_BRMS_COMPLIANCE_REQUEST: "UPDATE_BRMS_COMPLIANCE_REQUEST",
  UPDATE_BRMS_COMPLIANCE_SUCCESS: "UPDATE_BRMS_COMPLIANCE_SUCCESS",
  UPDATE_BRMS_COMPLIANCE_ERROR: "UPDATE_BRMS_COMPLIANCE_ERROR",
  UPDATE_BRMS_COMPLIANCE_DATA: "UPDATE_BRMS_COMPLIANCE_DATA",

  DELETE_BRMS_COMPLIANCE_REQUEST: "DELETE_BRMS_COMPLIANCE_REQUEST",
  DELETE_BRMS_COMPLIANCE_SUCCESS: "DELETE_BRMS_COMPLIANCE_SUCCESS",
  DELETE_BRMS_COMPLIANCE_ERROR: "DELETE_BRMS_COMPLIANCE_ERROR",

  GET_BRMS_COMPLIANCE_DETAILS_REQUEST: "GET_BRMS_COMPLIANCE_DETAILS_REQUEST",
  GET_BRMS_COMPLIANCE_DETAILS_SUCCESS: "GET_BRMS_COMPLIANCE_DETAILS_SUCCESS",
  GET_BRMS_COMPLIANCE_DETAILS_ERROR: "GET_BRMS_COMPLIANCE_DETAILS_ERROR",

  GET_BRMS_COMPLIANCE_META_DATA_REQUEST:
    "GET_BRMS_COMPLIANCE_META_DATA_REQUEST",
  GET_BRMS_COMPLIANCE_META_DATA_SUCCESS:
    "GET_BRMS_COMPLIANCE_META_DATA_SUCCESS",
  GET_BRMS_COMPLIANCE_META_DATA_ERROR: "GET_BRMS_COMPLIANCE_META_DATA_ERROR",

  CHECK_BRMS_COMPLIANCE_REQUEST: "CHECK_BRMS_COMPLIANCE_REQUEST",
  CHECK_BRMS_COMPLIANCE_SUCCESS: "CHECK_BRMS_COMPLIANCE_SUCCESS",
  CHECK_BRMS_COMPLIANCE_ERROR: "CHECK_BRMS_COMPLIANCE_ERROR",

  APPROVE_BRMS_COMPLIANCE_REQUEST: "APPROVE_BRMS_COMPLIANCE_REQUEST",
  APPROVE_BRMS_COMPLIANCE_SUCCESS: "APPROVE_BRMS_COMPLIANCE_SUCCESS",
  APPROVE_BRMS_COMPLIANCE_ERROR: "APPROVE_BRMS_COMPLIANCE_ERROR",

  getBrmsCompliance: () => ({
    type: brmsCompliance.GET_BRMS_COMPLIANCE_REQUEST,
  }),
  getBrmsComplianceDetails: (id) => ({
    type: brmsCompliance.GET_BRMS_COMPLIANCE_DETAILS_REQUEST,
    id,
  }),
  getBrmsComplianceMetaData: () => ({
    type: brmsCompliance.GET_BRMS_COMPLIANCE_META_DATA_REQUEST,
  }),
  addBrmsCompliance: (data, indicatorCategoryId) => ({
    type: brmsCompliance.ADD_BRMS_COMPLIANCE_REQUEST,
    data,
    indicatorCategoryId,
  }),
  updateBrmsCompliance: (id, data) => ({
    type: brmsCompliance.UPDATE_BRMS_COMPLIANCE_REQUEST,
    data,
    id,
  }),
  deleteBrmsCompliance: (id) => ({
    type: brmsCompliance.DELETE_BRMS_COMPLIANCE_REQUEST,
    id,
  }),
  checkBrmsCompliance: (id) => ({
    type: brmsCompliance.CHECK_BRMS_COMPLIANCE_REQUEST,
    id,
  }),
  approveBrmsCompliance: (id) => ({
    type: brmsCompliance.APPROVE_BRMS_COMPLIANCE_REQUEST,
    id,
  }),
  setUpdateBrmsComplianceData: (data) => ({
    type: brmsCompliance.UPDATE_BRMS_COMPLIANCE_DATA,
    data,
  }),
};

export default brmsCompliance;
