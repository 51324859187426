import { takeLatest, put, fork } from "@redux-saga/core/effects";
import axios from "axios";
const FileDownload = require("js-file-download");
import { feesCollectionsActions, serverActions } from "../../../actions";

function* getMetaData() {
  try {
    const response = yield axios({
      url: "/administration/fees-collection-data/meta-data",
    });

    yield put({
      type: feesCollectionsActions.GET_META_DATA_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: feesCollectionsActions.GET_META_DATA_SUCCESS,
      data: error.data,
    });
  }
}

function* watchGetMetaData() {
  yield takeLatest(feesCollectionsActions.GET_META_DATA_REQUEST, getMetaData);
}

function* getFeesCollections({ data }) {
  try {
    const response = yield axios({
      url: "/administration/fees-collection-data/",
      method: "POST",
      data,
    });

    yield put({
      type: feesCollectionsActions.GET_FEES_COLLECTIONS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: feesCollectionsActions.GET_FEES_COLLECTIONS_ERROR,
      data: error.data,
    });
  }
}

function* watchGetFeesCollections() {
  yield takeLatest(
    feesCollectionsActions.GET_FEES_COLLECTIONS_REQUEST,
    getFeesCollections
  );
}

function* getFeesDefaulters() {
  try {
    const response = yield axios({
      url: "/administration/fees-collection-data/fees-balances",
    });

    yield put({
      type: feesCollectionsActions.GET_FEES_DEFAULTERS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: feesCollectionsActions.GET_FEES_DEFAULTERS_ERROR,
      data: error.data,
    });
  }
}

function* watchGetFeesDefaulters() {
  yield takeLatest(
    feesCollectionsActions.GET_FEES_DEFAULTERS_REQUEST,
    getFeesDefaulters
  );
}

function* getInstallmentReport({ data }) {
  try {
    const response = yield axios({
      url: "/administration/fees-collection-data/installment-reports",
      params: {
        start_date: data.start_date?.format("YYYY-MM-DD"),
        end_date: data.end_date?.format("YYYY-MM-DD"),
        account_id: data.account_id,
      },
      data: data.learners,
      method: "POST",
    });

    yield put({
      type: feesCollectionsActions.GET_INSTALLMENT_PAYMENT_REPORT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: feesCollectionsActions.GET_INSTALLMENT_PAYMENT_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* watchGetInstallmentReport() {
  yield takeLatest(
    feesCollectionsActions.GET_INSTALLMENT_PAYMENT_REPORT_REQUEST,
    getInstallmentReport
  );
}

function* downloadInstallmentReport({ data }) {
  try {
    const response = yield axios({
      url: "/administration/fees-collection-data/installment-reports-excel",
      params: {
        start_date: data.start_date?.format("YYYY-MM-DD"),
        end_date: data.end_date?.format("YYYY-MM-DD"),
        account_id: data.account_id,
      },
      responseType: "blob",
      data: data.learners,
      method: "POST",
    });

    FileDownload(
      response?.data,
      `Installment_Report_${data.start_date?.format(
        "YYYY-MM-DD"
      )}_${data.end_date?.format("YYYY-MM-DD")}.xlsx`
    );

    yield put({
      type: feesCollectionsActions.DOWNLOAD_INSTALLMENT_PAYMENT_REPORT_EXCEL_SUCCESS,
      data: response,
    });

    yield put({
      type: serverActions.SERVER_SUCCESS,
      data: {
        server: { status: true, message: "Successfully downloaded excel" },
        message: "Successfully Downloaded template",
      },
    });
  } catch (error) {
    yield put({
      type: serverActions.SERVER_ERROR,
      error: {
        data: {
          server: { status: false, message: "Unable to download excel" },
          message: "Unable to Download template",
        },
      },
    });
    yield put({
      type: feesCollectionsActions.DOWNLOAD_INSTALLMENT_PAYMENT_REPORT_EXCEL_ERROR,
      error: error.data,
    });
  }
}

function* watchDownloadInstallmentReport() {
  yield takeLatest(
    feesCollectionsActions.DOWNLOAD_INSTALLMENT_PAYMENT_REPORT_EXCEL_REQUEST,
    downloadInstallmentReport
  );
}

export default [
  fork(watchGetMetaData),
  fork(watchGetFeesCollections),
  fork(watchGetFeesDefaulters),
  fork(watchGetInstallmentReport),
  fork(watchDownloadInstallmentReport),
];
