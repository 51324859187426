const staffAttendance = {
  GET_STAFF_ATTENDANCE_REQUEST: "GET_STAFF_ATTENDANCE_REQUEST",
  GET_STAFF_ATTENDANCE_SUCCESS: "GET_STAFF_ATTENDANCE_SUCCESS",
  GET_STAFF_ATTENDANCE_ERROR: "GET_STAFF_ATTENDANCE_ERROR",

  GET_STAFF_ATTENDANCE_META_DATA_REQUEST:
    "GET_STAFF_ATTENDANCE_META_DATA_REQUEST",
  GET_STAFF_ATTENDANCE_META_DATA_SUCCESS:
    "GET_STAFF_ATTENDANCE_META_DATA_SUCCESS",
  GET_STAFF_ATTENDANCE_META_DATA_ERROR: "GET_STAFF_ATTENDANCE_META_DATA_ERROR",

  GET_STAFF_ATTENDANCE_DETAILS_REQUEST: "GET_STAFF_ATTENDANCE_DETAILS_REQUEST",
  GET_STAFF_ATTENDANCE_DETAILS_SUCCESS: "GET_STAFF_ATTENDANCE_DETAILS_SUCCESS",
  GET_STAFF_ATTENDANCE_DETAILS_ERROR: "GET_STAFF_ATTENDANCE_DETAILS_ERROR",

  ADD_STAFF_ATTENDANCE_REQUEST: "ADD_STAFF_ATTENDANCE_REQUEST",
  ADD_STAFF_ATTENDANCE_SUCCESS: "ADD_STAFF_ATTENDANCE_SUCCESS",
  ADD_STAFF_ATTENDANCE_ERROR: "ADD_STAFF_ATTENDANCE_ERROR",

  UPDATE_STAFF_ATTENDANCE_REQUEST: "UPDATE_STAFF_ATTENDANCE_REQUEST",
  UPDATE_STAFF_ATTENDANCE_SUCCESS: "UPDATE_STAFF_ATTENDANCE_SUCCESS",
  UPDATE_STAFF_ATTENDANCE_ERROR: "UPDATE_STAFF_ATTENDANCE_ERROR",

  DELETE_STAFF_ATTENDANCE_REQUEST: "DELETE_STAFF_ATTENDANCE_REQUEST",
  DELETE_STAFF_ATTENDANCE_SUCCESS: "DELETE_STAFF_ATTENDANCE_SUCCESS",
  DELETE_STAFF_ATTENDANCE_ERROR: "DELETE_STAFF_ATTENDANCE_ERROR",

  CHECK_STAFF_ATTENDANCE_REQUEST: "CHECK_STAFF_ATTENDANCE_REQUEST",
  CHECK_STAFF_ATTENDANCE_SUCCESS: "CHECK_STAFF_ATTENDANCE_SUCCESS",
  CHECK_STAFF_ATTENDANCE_ERROR: "CHECK_STAFF_ATTENDANCE_ERROR",

  APPROVE_STAFF_ATTENDANCE_REQUEST: "APPROVE_STAFF_ATTENDANCE_REQUEST",
  APPROVE_STAFF_ATTENDANCE_SUCCESS: "APPROVE_STAFF_ATTENDANCE_SUCCESS",
  APPROVE_STAFF_ATTENDANCE_ERROR: "APPROVE_STAFF_ATTENDANCE_ERROR",

  SET_STAFF_ROLL_CALL_DATA: "SET_STAFF_ROLL_CALL_DATA",
  SET_UPDATE_STAFF_ROLL_CALL_DATA: "SET_UPDATE_STAFF_ROLL_CALL_DATA",

  getStaffAttendance: () => ({
    type: staffAttendance.GET_STAFF_ATTENDANCE_REQUEST,
  }),

  getStaffAttendanceMetaData: () => ({
    type: staffAttendance.GET_STAFF_ATTENDANCE_META_DATA_REQUEST,
  }),

  getStaffAttendanceDetails: (id) => ({
    type: staffAttendance.GET_STAFF_ATTENDANCE_DETAILS_REQUEST,
    id,
  }),

  addStaffAttendance: (data) => ({
    type: staffAttendance.ADD_STAFF_ATTENDANCE_REQUEST,
    data,
  }),

  updateStaffAttendance: (data, id) => ({
    type: staffAttendance.UPDATE_STAFF_ATTENDANCE_REQUEST,
    data,
    id,
  }),

  deleteStaffAttendance: (id) => ({
    type: staffAttendance.DELETE_STAFF_ATTENDANCE_REQUEST,
    id,
  }),

  checkStaffAttendance: (id) => ({
    type: staffAttendance.CHECK_STAFF_ATTENDANCE_REQUEST,
    id,
  }),

  approveStaffAttendance: (id) => ({
    type: staffAttendance.APPROVE_STAFF_ATTENDANCE_REQUEST,
    id,
  }),

  setRollCallData: (data) => ({
    type: staffAttendance.SET_STAFF_ROLL_CALL_DATA,
    data,
  }),

  setUpdateRollCallData: (data) => ({
    type: staffAttendance.SET_UPDATE_STAFF_ROLL_CALL_DATA,
    data,
  }),
};

export default staffAttendance;
