import { loanRepaymentScheduleActions } from "../../actions";
import initialState from "../../initialState";

export default (state = initialState.loansRepaymentSchedule, actions) => {
  switch (actions.type) {
    case loanRepaymentScheduleActions.GET_LOAN_REPAYMENT_SCHEDULE_DETAILS_REQUEST:
      return {
        ...state,
        loanScheduleDetailsLoading: true,
        loanScheduleDetailsError: {},
      };
    case loanRepaymentScheduleActions.GET_LOAN_REPAYMENT_SCHEDULE_DETAILS_SUCCESS:
      return {
        ...state,
        loanScheduleDetailsLoading: false,
        loanScheduleDetails: actions.data,
      };
    case loanRepaymentScheduleActions.GET_LOAN_REPAYMENT_SCHEDULE_DETAILS_ERROR:
      return {
        ...state,
        loanScheduleDetailsLoading: false,
        loanScheduleDetailsError: actions.data,
      };
    case loanRepaymentScheduleActions.GET_LOAN_REPAYMENT_SCHEDULE_REQUEST:
      return {
        ...state,
        loansRepaymentSchedulesLoading: true,
        loansRepaymentSchedulesError: {},
      };
    case loanRepaymentScheduleActions.GET_LOAN_REPAYMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        loansRepaymentSchedulesLoading: false,
        loansRepaymentSchedules: actions.data,
      };
    case loanRepaymentScheduleActions.GET_LOAN_REPAYMENT_SCHEDULE_ERROR:
      return {
        ...state,
        loansRepaymentSchedulesLoading: false,
        loansRepaymentSchedulesError: actions.data,
      };
    case loanRepaymentScheduleActions.ADD_LOAN_PAYMENT_REQUEST:
      return {
        ...state,
        addLoanPaymentLoading: true,
        addLoanPaymentError: {},
      };
    case loanRepaymentScheduleActions.ADD_LOAN_PAYMENT_SUCCESS:
      return {
        ...state,
        addLoanPaymentLoading: false,
        addLoanPayment: actions.data,
      };
    case loanRepaymentScheduleActions.ADD_LOAN_PAYMENT_ERROR:
      return {
        ...state,
        addLoanPaymentLoading: false,
        addLoanPaymentError: actions.data,
      };
    case loanRepaymentScheduleActions.ADD_LOAN_REPAYMENT_SCHEDULE_REQUEST:
      return {
        ...state,
        addLoanRepaymentScheduleLoading: true,
        addLoanRepaymentScheduleError: {},
      };
    case loanRepaymentScheduleActions.ADD_LOAN_REPAYMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        addLoanRepaymentScheduleLoading: false,
        addLoanRepaymentSchedule: actions.data,
      };
    case loanRepaymentScheduleActions.ADD_LOAN_REPAYMENT_SCHEDULE_ERROR:
      return {
        ...state,
        addLoanRepaymentScheduleLoading: false,
        addLoanRepaymentScheduleError: actions.data,
      };
    case loanRepaymentScheduleActions.UPDATE_LOAN_PAYMENT_DATA:
      return {
        updateLoanPayment: actions.data,
      };
    case loanRepaymentScheduleActions.SET_UPDATE_LOAN_REPAYMENT_SCHEDULE:
      return {
        ...state,
        updateLoanRepaymentSchedule: actions.data,
      };
    case loanRepaymentScheduleActions.UPDATE_LOAN_REPAYMENT_SCHEDULE_REQUEST:
      return {
        ...state,
        updateLoanRepaymentScheduleLoading: true,
        updateLoanRepaymentScheduleError: {},
      };
    case loanRepaymentScheduleActions.UPDATE_LOAN_REPAYMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        updateLoanRepaymentScheduleLoading: false,
        updateLoanRepaymentSchedule: actions.data,
      };
    case loanRepaymentScheduleActions.UPDATE_LOAN_REPAYMENT_SCHEDULE_ERROR:
      return {
        ...state,
        updateLoanRepaymentScheduleLoading: false,
        updateLoanRepaymentScheduleError: actions.data,
      };
    case loanRepaymentScheduleActions.DELETE_LOAN_REPAYMENT_SCHEDULE_REQUEST:
      return {
        ...state,
        deleteLoanRepaymentScheduleLoading: true,
        deleteLoanRepaymentScheduleError: {},
      };
    case loanRepaymentScheduleActions.DELETE_LOAN_REPAYMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        deleteLoanRepaymentScheduleLoading: false,
        deleteLoanRepaymentSchedule: actions.data,
      };
    case loanRepaymentScheduleActions.DELETE_LOAN_REPAYMENT_SCHEDULE_ERROR:
      return {
        ...state,
        deleteLoanRepaymentScheduleLoading: false,
        deleteLoanRepaymentScheduleError: actions.data,
      };
    case loanRepaymentScheduleActions.DELETE_LOAN_PAYMENT_REQUEST:
      return {
        ...state,
        deleteLoanPaymentLoading: true,
        deleteLoanPaymentError: {},
      };
    case loanRepaymentScheduleActions.DELETE_LOAN_PAYMENT_SUCCESS:
      return {
        ...state,
        deleteLoanPaymentLoading: false,
        deleteLoanPayment: actions.data,
      };
    case loanRepaymentScheduleActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
        metaDataError: {},
      };
    case loanRepaymentScheduleActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataLoading: false,
        metaData: actions.data,
      };
    case loanRepaymentScheduleActions.GET_META_DATA_ERROR:
      return {
        ...state,
        metaDataLoading: false,
        metaDataError: actions.data,
      };

    default:
      return state;
  }
};
