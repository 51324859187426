import React from "react";
import PropTypes, { object } from "prop-types";
import { Button } from "antd";

const AppButton = ({
  loading = false,
  disabled = false,
  text = "Save",
  type = "submit",
  loadingText = "Saving...",
  iconBefore = null,
  iconAfter = null,
  block = false,
  className = "",
  variant = null,
  danger = false,
  ...props
}) => {
  const htmlType = type === "submit" ? "submit" : "button"; // Set htmlType to "submit" only if type is "submit"
  return (
    <Button
      htmlType={htmlType}
      disabled={disabled}
      type={type !== "submit" ? type : "primary"}
      block={block}
      className={`text-sm ${className}`}
      loading={loading}
      icon={iconBefore}
      danger={variant === "danger" || danger}
      {...props}
    >
      {loading ? loadingText : ` ${text}`}
      {!loading ? iconAfter : null}
    </Button>
  );
};

AppButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  danger: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  loadingText: PropTypes.string,
  variant: PropTypes.string,
  iconBefore: PropTypes.oneOfType([object]),
  iconAfter: PropTypes.oneOfType([object]),
};

export default AppButton;
