import { Avatar, Modal, Input, Form, Card, Button, Alert } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppLogo from "../../../assets/AppLogo.png";
import { useNavigate, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import routePaths from "../../../config/routes/routePaths.routes";
import { authActions } from "../../../config/actions";
import usePrevious from "../../../components/hooks/usePrevious";
import "../../../index.css";

const AccountconfirmPage = () => {
  const { confirmAccount, confirmAccountError, confirmAccountSuccess } =
    useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const prevState = usePrevious({ confirmAccountSuccess, confirmAccountError });
  const goToLanding = () => navigate(`../${routePaths.landingPage.path}`);

  const location = useLocation();
  let token = location.pathname.split("/");
  token = token[token.length - 1];

  const onFinish = (values) => {
    dispatch(authActions.resetUserPassword(values, token));
  };

  // use effects
  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      confirmAccountError &&
      prevState.confirmAccountError !== confirmAccountError
    ) {
      setError(
        confirmAccountError?.message || confirmAccountError?.server?.message
      );
    }
  }, [confirmAccountError]);

  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      !isEmpty(confirmAccountSuccess) &&
      prevState.confirmAccountSuccess !== confirmAccountSuccess
    ) {
      if (confirmAccountSuccess.server?.status === true) {
        navigate("/auth");
        Modal.success({
          title: "SUCCESSFULLY VERIFIED YOUR ACCOUNT!",
          content: "You can now sign into SCHOOLBOOK.",
        });
      }
    }
  }, [confirmAccountSuccess]);

  return (
    <div className="container-fluid ">
      <div className="d-flex flex-column jusify-content-center align-items-center py-5">
        <div onClick={goToLanding}>
          <Avatar shape="square" src={AppLogo} size={100} />
        </div>
        <h2 className="mb-3 text-sm text-primary">SCHOOLBOOK</h2>
        <div className="rounded w-100">
          <div className="d-flex justify-content-center ">
            <div className="input-lenght shadow mt-1">
              <Card title="VERIFY YOUR ACCOUNT" bordered={false}>
                <Form
                  onFinish={onFinish}
                  name="Confirm account Page"
                  autoComplete="off"
                  layout="vertical"
                >
                  {error && <Alert type="error" message={error} showIcon />}
                  <Form.Item
                    name="otp"
                    label="OTP code"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please input the OTP code sent to your email!",
                      },
                      {
                        min: 8,
                        message: "Your otp can't be less than 8 characters",
                      },
                      {
                        max: 8,
                        message: "Your otp can't be more than 8 characters",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item className="mt-4 w-100">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={confirmAccount}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountconfirmPage;
