const constants = {
  studentDocumentTypes: {
    images: "images",
    pdf: "pdf",
  },
  partnerTypes: {
    lender: "Lender",
    donor: "Donor",
    supplier: "Supplier",
    client: "Client",
  },
  days: [
    {
      value: "MONDAY",
      label: "Monday",
      index: 1,
    },
    {
      value: "TUESDAY",
      label: "Tuesday",
      index: 2,
    },
    {
      value: "WEDNESDAY",
      label: "Wednesday",
      index: 3,
    },
    {
      value: "THURSDAY",
      label: "Thursday",
      index: 4,
    },
    {
      value: "FRIDAY",
      label: "Friday",
      index: 5,
    },
    {
      value: "SATURDAY",
      label: "Saturday",
      index: 6,
    },
    {
      value: "SUNDAY",
      label: "Sunday",
      index: 7,
    },
  ],
  promotedBy: {
    average: "Average",
    aggregates: "Aggregates",
    totalMarks: "Total Marks",
    points: "Points",
    none: "None",
  },
  communicationTypes: {
    generalCommunication: "General communication",
    schoolDefaulters: "School fees defaulters",
    results: "Results",
  },
  communicationTargetAudience: {
    class: "Class",
    individualLearners: "Individual learner(s)",
    all: "All",
  },
  bankTypes: {
    bank: "Bank",
    cash: "Cash",
  },
  budgetLineType: {
    income: "Income Budget Line",
    expense: "Expense Budget Line",
  },
  nurseryColors: [
    {
      label: "Yellow",
      value: "yellow",
      comment: "Excellent",
    },
    {
      label: "Red",
      value: "red",
      comment: "Very good",
    },
    {
      label: "Blue",
      value: "blue",
      comment: "Good",
    },
    {
      label: "Orange",
      value: "orange",
      comment: "Needs improvement",
    },
    {
      label: "Green",
      value: "green",
      comment: "Tried",
    },
  ],
  colors: [
    "#FF6347",
    "#FFA500",
    "#108ee9",
    "#32CD32",
    "#00FFFF",
    "#00BFFF",
    "#4169E1",
    "#800080",
    "#FF00FF",
    "#FF1493",
    "#FF69B4",
    "#FFC0CB",
    "#FA8072",
    "#FF8C00",
    "#FFFFE0",
    "#7CFC00",
    "#00FA9A",
    "#00FF7F",
    "#00CED1",
    "#1E90FF",
    "#48D1CC",
    "#FF4500",
    "#FFDAB9",
    "#FFB6C1",
    "#40E0D0",
    "#DA70D6",
    "#FF00FF",
    "#9370DB",
    "#00BFFF",
    "#00FF00",
  ],
  institutionAdmin: "Institution Admin",
  countries: [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas (the)",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory (the)",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands (the)",
    "Central African Republic (the)",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands (the)",
    "Colombia",
    "Comoros (the)",
    "Congo (the Democratic Republic of the)",
    "Congo (the)",
    "Cook Islands (the)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic (the)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories (the)",
    "Gabon",
    "Gambia (the)",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (the)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic (the)",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands (the)",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (the Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands (the)",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger (the)",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands (the)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines (the)",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of North Macedonia",
    "Romania",
    "Russian Federation (the)",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan (the)",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands (the)",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (the)",
    "United Kingdom of Great Britain and Northern Ireland (the)",
    "United States Minor Outlying Islands (the)",
    "United States of America (the)",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "Åland Islands",
  ],
  organisationAdminRole: "Organisation Admin",
  systemSubApplications: [
    {
      code: "permissions",
      name: "Permissions",
      permissions: [
        {
          name: "CAN ADD PERMISSIONS",
          id: "canAddPermissions",
        },
        {
          name: "CAN VIEW PERMISSIONS",
          id: "canViewPermissions",
        },
        {
          name: "CAN REMOVE PERMISSIONS",
          id: "canRemovePermissions",
        },
      ],
    },
    {
      code: "roles",
      name: "Organisation Roles",
      permissions: [
        {
          name: "CAN ADD ROLES",
          id: "canAddRoles",
        },
        {
          name: "CAN VIEW ROLES",
          id: "canViewRoles",
        },
        {
          name: "CAN DELETE ROLES",
          id: "canDeleteRoles",
        },
        {
          name: "CAN UPDATE ROLES",
          id: "canUpdateRoles",
        },
      ],
    },
  ],
  countryCodes: [
    {
      name: "Afghanistan",
      dial_code: "+93",
      code: "AF",
    },
    {
      name: "Aland Islands",
      dial_code: "+358",
      code: "AX",
    },
    {
      name: "Albania",
      dial_code: "+355",
      code: "AL",
    },
    {
      name: "Algeria",
      dial_code: "+213",
      code: "DZ",
    },
    {
      name: "AmericanSamoa",
      dial_code: "+1684",
      code: "AS",
    },
    {
      name: "Andorra",
      dial_code: "+376",
      code: "AD",
    },
    {
      name: "Angola",
      dial_code: "+244",
      code: "AO",
    },
    {
      name: "Anguilla",
      dial_code: "+1264",
      code: "AI",
    },
    {
      name: "Antarctica",
      dial_code: "+672",
      code: "AQ",
    },
    {
      name: "Antigua and Barbuda",
      dial_code: "+1268",
      code: "AG",
    },
    {
      name: "Argentina",
      dial_code: "+54",
      code: "AR",
    },
    {
      name: "Armenia",
      dial_code: "+374",
      code: "AM",
    },
    {
      name: "Aruba",
      dial_code: "+297",
      code: "AW",
    },
    {
      name: "Australia",
      dial_code: "+61",
      code: "AU",
    },
    {
      name: "Austria",
      dial_code: "+43",
      code: "AT",
    },
    {
      name: "Azerbaijan",
      dial_code: "+994",
      code: "AZ",
    },
    {
      name: "Bahamas",
      dial_code: "+1242",
      code: "BS",
    },
    {
      name: "Bahrain",
      dial_code: "+973",
      code: "BH",
    },
    {
      name: "Bangladesh",
      dial_code: "+880",
      code: "BD",
    },
    {
      name: "Barbados",
      dial_code: "+1246",
      code: "BB",
    },
    {
      name: "Belarus",
      dial_code: "+375",
      code: "BY",
    },
    {
      name: "Belgium",
      dial_code: "+32",
      code: "BE",
    },
    {
      name: "Belize",
      dial_code: "+501",
      code: "BZ",
    },
    {
      name: "Benin",
      dial_code: "+229",
      code: "BJ",
    },
    {
      name: "Bermuda",
      dial_code: "+1441",
      code: "BM",
    },
    {
      name: "Bhutan",
      dial_code: "+975",
      code: "BT",
    },
    {
      name: "Bolivia, Plurinational State of",
      dial_code: "+591",
      code: "BO",
    },
    {
      name: "Bosnia and Herzegovina",
      dial_code: "+387",
      code: "BA",
    },
    {
      name: "Botswana",
      dial_code: "+267",
      code: "BW",
    },
    {
      name: "Brazil",
      dial_code: "+55",
      code: "BR",
    },
    {
      name: "British Indian Ocean Territory",
      dial_code: "+246",
      code: "IO",
    },
    {
      name: "Brunei Darussalam",
      dial_code: "+673",
      code: "BN",
    },
    {
      name: "Bulgaria",
      dial_code: "+359",
      code: "BG",
    },
    {
      name: "Burkina Faso",
      dial_code: "+226",
      code: "BF",
    },
    {
      name: "Burundi",
      dial_code: "+257",
      code: "BI",
    },
    {
      name: "Cambodia",
      dial_code: "+855",
      code: "KH",
    },
    {
      name: "Cameroon",
      dial_code: "+237",
      code: "CM",
    },
    {
      name: "Canada",
      dial_code: "+1",
      code: "CA",
    },
    {
      name: "Cape Verde",
      dial_code: "+238",
      code: "CV",
    },
    {
      name: "Cayman Islands",
      dial_code: "+ 345",
      code: "KY",
    },
    {
      name: "Central African Republic",
      dial_code: "+236",
      code: "CF",
    },
    {
      name: "Chad",
      dial_code: "+235",
      code: "TD",
    },
    {
      name: "Chile",
      dial_code: "+56",
      code: "CL",
    },
    {
      name: "China",
      dial_code: "+86",
      code: "CN",
    },
    {
      name: "Christmas Island",
      dial_code: "+61",
      code: "CX",
    },
    {
      name: "Cocos (Keeling) Islands",
      dial_code: "+61",
      code: "CC",
    },
    {
      name: "Colombia",
      dial_code: "+57",
      code: "CO",
    },
    {
      name: "Comoros",
      dial_code: "+269",
      code: "KM",
    },
    {
      name: "Congo",
      dial_code: "+242",
      code: "CG",
    },
    {
      name: "Congo, The Democratic Republic of the Congo",
      dial_code: "+243",
      code: "CD",
    },
    {
      name: "Cook Islands",
      dial_code: "+682",
      code: "CK",
    },
    {
      name: "Costa Rica",
      dial_code: "+506",
      code: "CR",
    },
    {
      name: "Cote d'Ivoire",
      dial_code: "+225",
      code: "CI",
    },
    {
      name: "Croatia",
      dial_code: "+385",
      code: "HR",
    },
    {
      name: "Cuba",
      dial_code: "+53",
      code: "CU",
    },
    {
      name: "Cyprus",
      dial_code: "+357",
      code: "CY",
    },
    {
      name: "Czech Republic",
      dial_code: "+420",
      code: "CZ",
    },
    {
      name: "Denmark",
      dial_code: "+45",
      code: "DK",
    },
    {
      name: "Djibouti",
      dial_code: "+253",
      code: "DJ",
    },
    {
      name: "Dominica",
      dial_code: "+1767",
      code: "DM",
    },
    {
      name: "Dominican Republic",
      dial_code: "+1849",
      code: "DO",
    },
    {
      name: "Ecuador",
      dial_code: "+593",
      code: "EC",
    },
    {
      name: "Egypt",
      dial_code: "+20",
      code: "EG",
    },
    {
      name: "El Salvador",
      dial_code: "+503",
      code: "SV",
    },
    {
      name: "Equatorial Guinea",
      dial_code: "+240",
      code: "GQ",
    },
    {
      name: "Eritrea",
      dial_code: "+291",
      code: "ER",
    },
    {
      name: "Estonia",
      dial_code: "+372",
      code: "EE",
    },
    {
      name: "Ethiopia",
      dial_code: "+251",
      code: "ET",
    },
    {
      name: "Falkland Islands (Malvinas)",
      dial_code: "+500",
      code: "FK",
    },
    {
      name: "Faroe Islands",
      dial_code: "+298",
      code: "FO",
    },
    {
      name: "Fiji",
      dial_code: "+679",
      code: "FJ",
    },
    {
      name: "Finland",
      dial_code: "+358",
      code: "FI",
    },
    {
      name: "France",
      dial_code: "+33",
      code: "FR",
    },
    {
      name: "French Guiana",
      dial_code: "+594",
      code: "GF",
    },
    {
      name: "French Polynesia",
      dial_code: "+689",
      code: "PF",
    },
    {
      name: "Gabon",
      dial_code: "+241",
      code: "GA",
    },
    {
      name: "Gambia",
      dial_code: "+220",
      code: "GM",
    },
    {
      name: "Georgia",
      dial_code: "+995",
      code: "GE",
    },
    {
      name: "Germany",
      dial_code: "+49",
      code: "DE",
    },
    {
      name: "Ghana",
      dial_code: "+233",
      code: "GH",
    },
    {
      name: "Gibraltar",
      dial_code: "+350",
      code: "GI",
    },
    {
      name: "Greece",
      dial_code: "+30",
      code: "GR",
    },
    {
      name: "Greenland",
      dial_code: "+299",
      code: "GL",
    },
    {
      name: "Grenada",
      dial_code: "+1473",
      code: "GD",
    },
    {
      name: "Guadeloupe",
      dial_code: "+590",
      code: "GP",
    },
    {
      name: "Guam",
      dial_code: "+1671",
      code: "GU",
    },
    {
      name: "Guatemala",
      dial_code: "+502",
      code: "GT",
    },
    {
      name: "Guernsey",
      dial_code: "+44",
      code: "GG",
    },
    {
      name: "Guinea",
      dial_code: "+224",
      code: "GN",
    },
    {
      name: "Guinea-Bissau",
      dial_code: "+245",
      code: "GW",
    },
    {
      name: "Guyana",
      dial_code: "+595",
      code: "GY",
    },
    {
      name: "Haiti",
      dial_code: "+509",
      code: "HT",
    },
    {
      name: "Holy See (Vatican City State)",
      dial_code: "+379",
      code: "VA",
    },
    {
      name: "Honduras",
      dial_code: "+504",
      code: "HN",
    },
    {
      name: "Hong Kong",
      dial_code: "+852",
      code: "HK",
    },
    {
      name: "Hungary",
      dial_code: "+36",
      code: "HU",
    },
    {
      name: "Iceland",
      dial_code: "+354",
      code: "IS",
    },
    {
      name: "India",
      dial_code: "+91",
      code: "IN",
    },
    {
      name: "Indonesia",
      dial_code: "+62",
      code: "ID",
    },
    {
      name: "Iran, Islamic Republic of Persian Gulf",
      dial_code: "+98",
      code: "IR",
    },
    {
      name: "Iraq",
      dial_code: "+964",
      code: "IQ",
    },
    {
      name: "Ireland",
      dial_code: "+353",
      code: "IE",
    },
    {
      name: "Isle of Man",
      dial_code: "+44",
      code: "IM",
    },
    {
      name: "Israel",
      dial_code: "+972",
      code: "IL",
    },
    {
      name: "Italy",
      dial_code: "+39",
      code: "IT",
    },
    {
      name: "Jamaica",
      dial_code: "+1876",
      code: "JM",
    },
    {
      name: "Japan",
      dial_code: "+81",
      code: "JP",
    },
    {
      name: "Jersey",
      dial_code: "+44",
      code: "JE",
    },
    {
      name: "Jordan",
      dial_code: "+962",
      code: "JO",
    },
    {
      name: "Kazakhstan",
      dial_code: "+77",
      code: "KZ",
    },
    {
      name: "Kenya",
      dial_code: "+254",
      code: "KE",
    },
    {
      name: "Kiribati",
      dial_code: "+686",
      code: "KI",
    },
    {
      name: "Korea, Democratic People's Republic of Korea",
      dial_code: "+850",
      code: "KP",
    },
    {
      name: "Korea, Republic of South Korea",
      dial_code: "+82",
      code: "KR",
    },
    {
      name: "Kuwait",
      dial_code: "+965",
      code: "KW",
    },
    {
      name: "Kyrgyzstan",
      dial_code: "+996",
      code: "KG",
    },
    {
      name: "Laos",
      dial_code: "+856",
      code: "LA",
    },
    {
      name: "Latvia",
      dial_code: "+371",
      code: "LV",
    },
    {
      name: "Lebanon",
      dial_code: "+961",
      code: "LB",
    },
    {
      name: "Lesotho",
      dial_code: "+266",
      code: "LS",
    },
    {
      name: "Liberia",
      dial_code: "+231",
      code: "LR",
    },
    {
      name: "Libyan Arab Jamahiriya",
      dial_code: "+218",
      code: "LY",
    },
    {
      name: "Liechtenstein",
      dial_code: "+423",
      code: "LI",
    },
    {
      name: "Lithuania",
      dial_code: "+370",
      code: "LT",
    },
    {
      name: "Luxembourg",
      dial_code: "+352",
      code: "LU",
    },
    {
      name: "Macao",
      dial_code: "+853",
      code: "MO",
    },
    {
      name: "Macedonia",
      dial_code: "+389",
      code: "MK",
    },
    {
      name: "Madagascar",
      dial_code: "+261",
      code: "MG",
    },
    {
      name: "Malawi",
      dial_code: "+265",
      code: "MW",
    },
    {
      name: "Malaysia",
      dial_code: "+60",
      code: "MY",
    },
    {
      name: "Maldives",
      dial_code: "+960",
      code: "MV",
    },
    {
      name: "Mali",
      dial_code: "+223",
      code: "ML",
    },
    {
      name: "Malta",
      dial_code: "+356",
      code: "MT",
    },
    {
      name: "Marshall Islands",
      dial_code: "+692",
      code: "MH",
    },
    {
      name: "Martinique",
      dial_code: "+596",
      code: "MQ",
    },
    {
      name: "Mauritania",
      dial_code: "+222",
      code: "MR",
    },
    {
      name: "Mauritius",
      dial_code: "+230",
      code: "MU",
    },
    {
      name: "Mayotte",
      dial_code: "+262",
      code: "YT",
    },
    {
      name: "Mexico",
      dial_code: "+52",
      code: "MX",
    },
    {
      name: "Micronesia, Federated States of Micronesia",
      dial_code: "+691",
      code: "FM",
    },
    {
      name: "Moldova",
      dial_code: "+373",
      code: "MD",
    },
    {
      name: "Monaco",
      dial_code: "+377",
      code: "MC",
    },
    {
      name: "Mongolia",
      dial_code: "+976",
      code: "MN",
    },
    {
      name: "Montenegro",
      dial_code: "+382",
      code: "ME",
    },
    {
      name: "Montserrat",
      dial_code: "+1664",
      code: "MS",
    },
    {
      name: "Morocco",
      dial_code: "+212",
      code: "MA",
    },
    {
      name: "Mozambique",
      dial_code: "+258",
      code: "MZ",
    },
    {
      name: "Myanmar",
      dial_code: "+95",
      code: "MM",
    },
    {
      name: "Namibia",
      dial_code: "+264",
      code: "NA",
    },
    {
      name: "Nauru",
      dial_code: "+674",
      code: "NR",
    },
    {
      name: "Nepal",
      dial_code: "+977",
      code: "NP",
    },
    {
      name: "Netherlands",
      dial_code: "+31",
      code: "NL",
    },
    {
      name: "Netherlands Antilles",
      dial_code: "+599",
      code: "AN",
    },
    {
      name: "New Caledonia",
      dial_code: "+687",
      code: "NC",
    },
    {
      name: "New Zealand",
      dial_code: "+64",
      code: "NZ",
    },
    {
      name: "Nicaragua",
      dial_code: "+505",
      code: "NI",
    },
    {
      name: "Niger",
      dial_code: "+227",
      code: "NE",
    },
    {
      name: "Nigeria",
      dial_code: "+234",
      code: "NG",
    },
    {
      name: "Niue",
      dial_code: "+683",
      code: "NU",
    },
    {
      name: "Norfolk Island",
      dial_code: "+672",
      code: "NF",
    },
    {
      name: "Northern Mariana Islands",
      dial_code: "+1670",
      code: "MP",
    },
    {
      name: "Norway",
      dial_code: "+47",
      code: "NO",
    },
    {
      name: "Oman",
      dial_code: "+968",
      code: "OM",
    },
    {
      name: "Pakistan",
      dial_code: "+92",
      code: "PK",
    },
    {
      name: "Palau",
      dial_code: "+680",
      code: "PW",
    },
    {
      name: "Palestinian Territory, Occupied",
      dial_code: "+970",
      code: "PS",
    },
    {
      name: "Panama",
      dial_code: "+507",
      code: "PA",
    },
    {
      name: "Papua New Guinea",
      dial_code: "+675",
      code: "PG",
    },
    {
      name: "Paraguay",
      dial_code: "+595",
      code: "PY",
    },
    {
      name: "Peru",
      dial_code: "+51",
      code: "PE",
    },
    {
      name: "Philippines",
      dial_code: "+63",
      code: "PH",
    },
    {
      name: "Pitcairn",
      dial_code: "+872",
      code: "PN",
    },
    {
      name: "Poland",
      dial_code: "+48",
      code: "PL",
    },
    {
      name: "Portugal",
      dial_code: "+351",
      code: "PT",
    },
    {
      name: "Puerto Rico",
      dial_code: "+1939",
      code: "PR",
    },
    {
      name: "Qatar",
      dial_code: "+974",
      code: "QA",
    },
    {
      name: "Romania",
      dial_code: "+40",
      code: "RO",
    },
    {
      name: "Russia",
      dial_code: "+7",
      code: "RU",
    },
    {
      name: "Rwanda",
      dial_code: "+250",
      code: "RW",
    },
    {
      name: "Reunion",
      dial_code: "+262",
      code: "RE",
    },
    {
      name: "Saint Barthelemy",
      dial_code: "+590",
      code: "BL",
    },
    {
      name: "Saint Helena, Ascension and Tristan Da Cunha",
      dial_code: "+290",
      code: "SH",
    },
    {
      name: "Saint Kitts and Nevis",
      dial_code: "+1869",
      code: "KN",
    },
    {
      name: "Saint Lucia",
      dial_code: "+1758",
      code: "LC",
    },
    {
      name: "Saint Martin",
      dial_code: "+590",
      code: "MF",
    },
    {
      name: "Saint Pierre and Miquelon",
      dial_code: "+508",
      code: "PM",
    },
    {
      name: "Saint Vincent and the Grenadines",
      dial_code: "+1784",
      code: "VC",
    },
    {
      name: "Samoa",
      dial_code: "+685",
      code: "WS",
    },
    {
      name: "San Marino",
      dial_code: "+378",
      code: "SM",
    },
    {
      name: "Sao Tome and Principe",
      dial_code: "+239",
      code: "ST",
    },
    {
      name: "Saudi Arabia",
      dial_code: "+966",
      code: "SA",
    },
    {
      name: "Senegal",
      dial_code: "+221",
      code: "SN",
    },
    {
      name: "Serbia",
      dial_code: "+381",
      code: "RS",
    },
    {
      name: "Seychelles",
      dial_code: "+248",
      code: "SC",
    },
    {
      name: "Sierra Leone",
      dial_code: "+232",
      code: "SL",
    },
    {
      name: "Singapore",
      dial_code: "+65",
      code: "SG",
    },
    {
      name: "Slovakia",
      dial_code: "+421",
      code: "SK",
    },
    {
      name: "Slovenia",
      dial_code: "+386",
      code: "SI",
    },
    {
      name: "Solomon Islands",
      dial_code: "+677",
      code: "SB",
    },
    {
      name: "Somalia",
      dial_code: "+252",
      code: "SO",
    },
    {
      name: "South Africa",
      dial_code: "+27",
      code: "ZA",
    },
    {
      name: "South Sudan",
      dial_code: "+211",
      code: "SS",
    },
    {
      name: "South Georgia and the South Sandwich Islands",
      dial_code: "+500",
      code: "GS",
    },
    {
      name: "Spain",
      dial_code: "+34",
      code: "ES",
    },
    {
      name: "Sri Lanka",
      dial_code: "+94",
      code: "LK",
    },
    {
      name: "Sudan",
      dial_code: "+249",
      code: "SD",
    },
    {
      name: "Suriname",
      dial_code: "+597",
      code: "SR",
    },
    {
      name: "Svalbard and Jan Mayen",
      dial_code: "+47",
      code: "SJ",
    },
    {
      name: "Swaziland",
      dial_code: "+268",
      code: "SZ",
    },
    {
      name: "Sweden",
      dial_code: "+46",
      code: "SE",
    },
    {
      name: "Switzerland",
      dial_code: "+41",
      code: "CH",
    },
    {
      name: "Syrian Arab Republic",
      dial_code: "+963",
      code: "SY",
    },
    {
      name: "Taiwan",
      dial_code: "+886",
      code: "TW",
    },
    {
      name: "Tajikistan",
      dial_code: "+992",
      code: "TJ",
    },
    {
      name: "Tanzania, United Republic of Tanzania",
      dial_code: "+255",
      code: "TZ",
    },
    {
      name: "Thailand",
      dial_code: "+66",
      code: "TH",
    },
    {
      name: "Timor-Leste",
      dial_code: "+670",
      code: "TL",
    },
    {
      name: "Togo",
      dial_code: "+228",
      code: "TG",
    },
    {
      name: "Tokelau",
      dial_code: "+690",
      code: "TK",
    },
    {
      name: "Tonga",
      dial_code: "+676",
      code: "TO",
    },
    {
      name: "Trinidad and Tobago",
      dial_code: "+1868",
      code: "TT",
    },
    {
      name: "Tunisia",
      dial_code: "+216",
      code: "TN",
    },
    {
      name: "Turkey",
      dial_code: "+90",
      code: "TR",
    },
    {
      name: "Turkmenistan",
      dial_code: "+993",
      code: "TM",
    },
    {
      name: "Turks and Caicos Islands",
      dial_code: "+1649",
      code: "TC",
    },
    {
      name: "Tuvalu",
      dial_code: "+688",
      code: "TV",
    },
    {
      name: "Uganda",
      dial_code: "+256",
      code: "UG",
    },
    {
      name: "Ukraine",
      dial_code: "+380",
      code: "UA",
    },
    {
      name: "United Arab Emirates",
      dial_code: "+971",
      code: "AE",
    },
    {
      name: "United Kingdom",
      dial_code: "+44",
      code: "GB",
    },
    {
      name: "United States",
      dial_code: "+1",
      code: "US",
    },
    {
      name: "Uruguay",
      dial_code: "+598",
      code: "UY",
    },
    {
      name: "Uzbekistan",
      dial_code: "+998",
      code: "UZ",
    },
    {
      name: "Vanuatu",
      dial_code: "+678",
      code: "VU",
    },
    {
      name: "Venezuela, Bolivarian Republic of Venezuela",
      dial_code: "+58",
      code: "VE",
    },
    {
      name: "Vietnam",
      dial_code: "+84",
      code: "VN",
    },
    {
      name: "Virgin Islands, British",
      dial_code: "+1284",
      code: "VG",
    },
    {
      name: "Virgin Islands, U.S.",
      dial_code: "+1340",
      code: "VI",
    },
    {
      name: "Wallis and Futuna",
      dial_code: "+681",
      code: "WF",
    },
    {
      name: "Yemen",
      dial_code: "+967",
      code: "YE",
    },
    {
      name: "Zambia",
      dial_code: "+260",
      code: "ZM",
    },
    {
      name: "Zimbabwe",
      dial_code: "+263",
      code: "ZW",
    },
  ],
  appFilesUrl: process.env.REACT_APP_API_FILES,
  recordStatus: {
    pending: "Pending",
    checked: "Checked",
    approved: "Approved",
    rejected: "Rejected",
  },
  gender: ["Male", "Female"],
  accountStatuses: { active: "Active", inActive: "Inactive" },
  balanceSheetTypes: ["Assets", "Liabilities", "Equity"],
  chartOfAccountCategories: {
    profitAndLoss: ["Expense", "Income"],
    balanceSheet: [
      "Current assets",
      "Fixed assets",
      "Current liabilities",
      "Long term liabilities",
      "Owners equity",
    ],
    accountTypes: ["Profit And Loss", "Balance Sheet"],
  },
  classLevels: {
    nursary: "Nursery",
    lowerPrimary: "Lower primary",
    upperPrimary: "Upper primary",
    oLevel: "Ordinary level",
    alevel: "Advanced level",
  },

  institutionTypes: [
    {
      name: "Kindergarten",
      value: "Kindergarten",
    },
    { name: "Nursery School", value: "Nursery School" },
    { name: "Primary School", value: "Primary School" },
    { name: "Nursery and Primary School", value: "Nursey and Primary School" },
    { name: "Secondary School", value: "Secondary School" },
    {
      name: "Primary and Secondary School",
      value: "Primary and Secondary School",
    },
    {
      name: "Nursery, Primary and Secondary School",
      value: "Nursey, Primary and Secondary School",
    },
    { name: "High School", value: "High School" },
    { name: "Institute", value: "Institute" },
    { name: "International School", value: "International School" },
    {
      name: "International and Local School",
      value: "International and Local School",
    },
    { name: "Vocational Institute", value: "Vocational Institute" },
    { name: "Language School", value: "Language School" },
    { name: "Art School", value: "Art School" },
    { name: "Music School", value: "Music School" },
    { name: "Sports Academy", value: "Sports Academy" },
    { name: "Technical Institute", value: "Technical Institute" },
    { name: "Medical Institute", value: "Medical Institute" },
    { name: "Law Institute", value: "Law Institute" },
    { name: "Business Institute", value: "Business Institute" },
    { name: "Engineering Institute", value: "Engineering Institute" },
    { name: "College", value: "College" },
    { name: "University", value: "University" },
  ],

  requisitionMemosSortBy: [
    { name: "All", value: "All" },
    { name: "Bank", value: "Bank" },
    { name: "Budget", value: "Budget" },
    { name: "Budget Line", value: "Budget Line" },
    { name: "Date Paid", value: "Date Paid" },
  ],

  assetConditions: [
    {
      name: "Good",
      value: "Good - Asset is in good condition",
      color: "green",
    },
    {
      name: "Fair",
      value: "Fair - Asset has noticeable wear and tear",
      color: "blue",
    },
    {
      name: "Poor",
      value: "Poor - Asset needs repairs or replacement",
      color: "orange",
    },
    {
      name: "Disposed",
      value: "Disposed - Asset has been discarded",
      color: "red",
    },
    {
      name: "Lost",
      value: "Lost -  Asset is missing and cannot be located",
      color: "purple",
    },
  ],

  commentCategories: [
    {
      category: "Improvement",
      comments: [
        {
          text: "Shows improvement and progress.",
          positive: true,
        },
        {
          text: "Has made notable strides in performance.",
          positive: true,
        },
        {
          text: "Demonstrates growth over time.",
          positive: true,
        },
        {
          text: "Consistently strives to improve.",
          positive: true,
        },
        {
          text: "Needs to work on improvement.",
          positive: false,
        },
        {
          text: "Should focus more on making progress.",
          positive: false,
        },
        {
          text: "Has not shown significant growth recently.",
          positive: false,
        },
        {
          text: "Could make better efforts to improve.",
          positive: false,
        },
        // ... other improvement-related comments
      ],
    },
    {
      category: "Effort",
      comments: [
        {
          text: "Puts in consistent effort in their studies.",
          positive: true,
        },
        {
          text: "Exhibits strong dedication to learning.",
          positive: true,
        },
        {
          text: "Shows a commendable work ethic.",
          positive: true,
        },
        {
          text: "Demonstrates a strong commitment to their studies.",
          positive: true,
        },
        {
          text: "Needs to put in more effort in their studies.",
          positive: false,
        },
        {
          text: "Could be more dedicated to learning.",
          positive: false,
        },
        {
          text: "Should work on improving their work ethic.",
          positive: false,
        },
        {
          text: "Demonstrates a lack of commitment to their studies.",
          positive: false,
        },
        // ... other effort-related comments
      ],
    },
    {
      category: "Activity",
      comments: [
        {
          text: "Participates actively in classroom discussions.",
          positive: true,
        },
        {
          text: "Engages enthusiastically in extracurricular activities.",
          positive: true,
        },
        {
          text: "Demonstrates involvement in various school events.",
          positive: true,
        },
        {
          text: "Actively contributes to a positive class atmosphere.",
          positive: true,
        },
        {
          text: "Could participate more in classroom discussions.",
          positive: false,
        },
        {
          text: "Should show more enthusiasm in extracurricular activities.",
          positive: false,
        },
        {
          text: "Lacks involvement in school events.",
          positive: false,
        },
        {
          text: "Needs to contribute more to a positive class atmosphere.",
          positive: false,
        },
      ],
    },
  ],
  gradingTypes: {
    primary: "PRIMARY",
    olevelNewCurriculum: "O LEVEL (NEW CURRICULUM)",
    olevelOldCurriculum: "O LEVEL (OLD CURRICULUM)",
    alevel: "A LEVEL",
  },
  currencies: [
    {
      code: "AED",
      numeric: "784",
      name: "UAE Dirham",
      symbol: "Ø¯.Ø¥",
      decimal: 2,
    },
    {
      code: "AFN",
      numeric: "971",
      name: "Afghani",
      symbol: "Ø‹",
      decimal: 2,
    },
    {
      code: "ALL",
      numeric: "008",
      name: "Lek",
      symbol: "L",
      decimal: 2,
    },
    {
      code: "AMD",
      numeric: "051",
      name: "Armenian Dram",
      symbol: "Õ¤Ö€.",
      decimal: 2,
    },
    {
      code: "ANG",
      numeric: "532",
      name: "Netherlands Antillean Guilder",
      symbol: "ƒ",
      decimal: 2,
    },
    {
      code: "AOA",
      numeric: "973",
      name: "Kwanza",
      symbol: "Kz",
      decimal: 2,
    },
    {
      code: "ARS",
      numeric: "032",
      name: "Argentine Peso",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "AUD",
      numeric: "036",
      name: "Australian Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "AWG",
      numeric: "533",
      name: "Aruban Florin",
      symbol: "ƒ",
      decimal: 2,
    },
    {
      code: "AZN",
      numeric: "944",
      name: "Azerbaijan Manat",
      symbol: "m",
      decimal: 2,
    },
    {
      code: "BAM",
      numeric: "977",
      name: "Convertible Mark",
      symbol: "ÐšÐœ",
      decimal: 2,
    },
    {
      code: "BBD",
      numeric: "052",
      name: "Barbados Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "BDT",
      numeric: "050",
      name: "Taka",
      symbol: "à§³",
      decimal: 2,
    },
    {
      code: "BGN",
      numeric: "975",
      name: "Bulgarian Lev",
      symbol: "Ð»Ð²",
      decimal: 2,
    },
    {
      code: "BHD",
      numeric: "048",
      name: "Bahraini Dinar",
      symbol: ".Ø¯.Ø¨",
      decimal: 3,
    },
    {
      code: "BIF",
      numeric: "108",
      name: "Burundi Franc",
      symbol: "Fr",
      decimal: 0,
    },
    {
      code: "BMD",
      numeric: "060",
      name: "Bermudian Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "BND",
      numeric: "096",
      name: "Brunei Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "BOB",
      numeric: "068",
      name: "Boliviano",
      symbol: "Bs.",
      decimal: 2,
    },
    {
      code: "BOV",
      numeric: "984",
      name: "Mvdol",
      symbol: "",
      decimal: 2,
    },
    {
      code: "BRL",
      numeric: "986",
      name: "Brazilian Real",
      symbol: "R$",
      decimal: 2,
    },
    {
      code: "BSD",
      numeric: "044",
      name: "Bahamian Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "BTN",
      numeric: "064",
      name: "Ngultrum",
      symbol: "Nu.",
      decimal: 2,
    },
    {
      code: "BWP",
      numeric: "072",
      name: "Pula",
      symbol: "P",
      decimal: 2,
    },
    {
      code: "BYN",
      numeric: "933",
      name: "Belarusian Ruble",
      symbol: "Br",
      decimal: 2,
    },
    {
      code: "BZD",
      numeric: "084",
      name: "Belize Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "CAD",
      numeric: "124",
      name: "Canadian Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "CDF",
      numeric: "976",
      name: "Congolese Franc",
      symbol: "Fr",
      decimal: 2,
    },
    {
      code: "CHE",
      numeric: "947",
      name: "WIR Euro",
      symbol: "",
      decimal: 2,
    },
    {
      code: "CHF",
      numeric: "756",
      name: "Swiss Franc",
      symbol: "Fr",
      decimal: 2,
    },
    {
      code: "CHW",
      numeric: "948",
      name: "WIR Franc",
      symbol: "",
      decimal: 2,
    },
    {
      code: "CLF",
      numeric: "990",
      name: "Unidad de Fomento",
      symbol: "",
      decimal: 4,
    },
    {
      code: "CLP",
      numeric: "152",
      name: "Chilean Peso",
      symbol: "$",
      decimal: 0,
    },
    {
      code: "CNY",
      numeric: "156",
      name: "Yuan Renminbi",
      symbol: "¥",
      decimal: 2,
    },
    {
      code: "COP",
      numeric: "170",
      name: "Colombian Peso",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "COU",
      numeric: "970",
      name: "Unidad de Valor Real",
      symbol: "",
      decimal: 2,
    },
    {
      code: "CRC",
      numeric: "188",
      name: "Costa Rican Colon",
      symbol: "â‚¡",
      decimal: 2,
    },
    {
      code: "CUC",
      numeric: "931",
      name: "Peso Convertible",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "CUP",
      numeric: "192",
      name: "Cuban Peso",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "CVE",
      numeric: "132",
      name: "Cabo Verde Escudo",
      symbol: "Esc or $",
      decimal: 2,
    },
    {
      code: "CZK",
      numeric: "203",
      name: "Czech Koruna",
      symbol: "KÄ  ",
      decimal: 2,
    },
    {
      code: "DJF",
      numeric: "262",
      name: "Djibouti Franc",
      symbol: "Fr",
      decimal: 0,
    },
    {
      code: "DKK",
      numeric: "208",
      name: "Danish Krone",
      symbol: "kr",
      decimal: 2,
    },
    {
      code: "DOP",
      numeric: "214",
      name: "Dominican Peso",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "DZD",
      numeric: "012",
      name: "Algerian Dinar",
      symbol: "Ø¯.Ø¬",
      decimal: 2,
    },
    {
      code: "EGP",
      numeric: "818",
      name: "Egyptian Pound",
      symbol: "Ø¬.Ù…",
      decimal: 2,
    },
    {
      code: "ERN",
      numeric: "232",
      name: "Nakfa",
      symbol: "Nfk",
      decimal: 2,
    },
    {
      code: "ETB",
      numeric: "230",
      name: "Ethiopian Birr",
      symbol: "Br",
      decimal: 2,
    },
    {
      code: "EUR",
      numeric: "978",
      name: "Euro",
      symbol: "€",
      decimal: 2,
    },
    {
      code: "FJD",
      numeric: "242",
      name: "Fiji Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "FKP",
      numeric: "238",
      name: "Falkland Islands Pound",
      symbol: "£",
      decimal: 2,
    },
    {
      code: "GBP",
      numeric: "826",
      name: "Pound Sterling",
      symbol: "£",
      decimal: 2,
    },
    {
      code: "GEL",
      numeric: "981",
      name: "Lari",
      symbol: "áƒš",
      decimal: 2,
    },
    {
      code: "GHS",
      numeric: "936",
      name: "Ghana Cedi",
      symbol: "â‚µ",
      decimal: 2,
    },
    {
      code: "GIP",
      numeric: "292",
      name: "Gibraltar Pound",
      symbol: "£",
      decimal: 2,
    },
    {
      code: "GMD",
      numeric: "270",
      name: "Dalasi",
      symbol: "D",
      decimal: 2,
    },
    {
      code: "GNF",
      numeric: "324",
      name: "Guinean Franc",
      symbol: "Fr",
      decimal: 0,
    },
    {
      code: "GTQ",
      numeric: "320",
      name: "Quetzal",
      symbol: "Q",
      decimal: 2,
    },
    {
      code: "GYD",
      numeric: "328",
      name: "Guyana Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "HKD",
      numeric: "344",
      name: "Hong Kong Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "HNL",
      numeric: "340",
      name: "Lempira",
      symbol: "L",
      decimal: 2,
    },
    {
      code: "HRK",
      numeric: "191",
      name: "Kuna",
      symbol: "kn",
      decimal: 2,
    },
    {
      code: "HTG",
      numeric: "332",
      name: "Gourde",
      symbol: "G",
      decimal: 2,
    },
    {
      code: "HUF",
      numeric: "348",
      name: "Forint",
      symbol: "Ft",
      decimal: 2,
    },
    {
      code: "IDR",
      numeric: "360",
      name: "Rupiah",
      symbol: "Rp",
      decimal: 2,
    },
    {
      code: "ILS",
      numeric: "376",
      name: "New Israeli Sheqel",
      symbol: "â‚ª",
      decimal: 2,
    },
    {
      code: "INR",
      numeric: "356",
      name: "Indian Rupee",
      symbol: "â‚¹",
      decimal: 2,
    },
    {
      code: "IQD",
      numeric: "368",
      name: "Iraqi Dinar",
      symbol: "Ø¹.Ø¯",
      decimal: 3,
    },
    {
      code: "IRR",
      numeric: "364",
      name: "Iranian Rial",
      symbol: "ï·¼",
      decimal: 2,
    },
    {
      code: "ISK",
      numeric: "352",
      name: "Iceland Krona",
      symbol: "kr",
      decimal: 0,
    },
    {
      code: "JMD",
      numeric: "388",
      name: "Jamaican Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "JOD",
      numeric: "400",
      name: "Jordanian Dinar",
      symbol: "Ø¯.Ø§",
      decimal: 3,
    },
    {
      code: "JPY",
      numeric: "392",
      name: "Yen",
      symbol: "¥",
      decimal: 0,
    },
    {
      code: "KES",
      numeric: "404",
      name: "Kenyan Shilling",
      symbol: "Sh",
      decimal: 2,
    },
    {
      code: "KGS",
      numeric: "417",
      name: "Som",
      symbol: "Ð»Ð²",
      decimal: 2,
    },
    {
      code: "KHR",
      numeric: "116",
      name: "Riel",
      symbol: "áŸ›",
      decimal: 2,
    },
    {
      code: "KMF",
      numeric: "174",
      name: "Comorian Franc ",
      symbol: "Fr",
      decimal: 0,
    },
    {
      code: "KPW",
      numeric: "408",
      name: "North Korean Won",
      symbol: "â‚©",
      decimal: 2,
    },
    {
      code: "KRW",
      numeric: "410",
      name: "Won",
      symbol: "â‚©",
      decimal: 0,
    },
    {
      code: "KWD",
      numeric: "414",
      name: "Kuwaiti Dinar",
      symbol: "Ø¯.Ùƒ",
      decimal: 3,
    },
    {
      code: "KYD",
      numeric: "136",
      name: "Cayman Islands Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "KZT",
      numeric: "398",
      name: "Tenge",
      symbol: "â‚¸",
      decimal: 2,
    },
    {
      code: "LAK",
      numeric: "418",
      name: "Lao Kip",
      symbol: "â‚",
      decimal: 2,
    },
    {
      code: "LBP",
      numeric: "422",
      name: "Lebanese Pound",
      symbol: "Ù„.Ù„",
      decimal: 2,
    },
    {
      code: "LKR",
      numeric: "144",
      name: "Sri Lanka Rupee",
      symbol: "Rs",
      decimal: 2,
    },
    {
      code: "LRD",
      numeric: "430",
      name: "Liberian Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "LSL",
      numeric: "426",
      name: "Loti",
      symbol: "L",
      decimal: 2,
    },
    {
      code: "LYD",
      numeric: "434",
      name: "Libyan Dinar",
      symbol: "Ù„.Ø¯",
      decimal: 3,
    },
    {
      code: "MAD",
      numeric: "504",
      name: "Moroccan Dirham",
      symbol: "Ø¯.Ù….",
      decimal: 2,
    },
    {
      code: "MDL",
      numeric: "498",
      name: "Moldovan Leu",
      symbol: "L",
      decimal: 2,
    },
    {
      code: "MGA",
      numeric: "969",
      name: "Malagasy Ariary",
      symbol: "Ar",
      decimal: 2,
    },
    {
      code: "MKD",
      numeric: "807",
      name: "Denar",
      symbol: "Ð´ÐµÐ½",
      decimal: 2,
    },
    {
      code: "MMK",
      numeric: "104",
      name: "Kyat",
      symbol: "Ks",
      decimal: 2,
    },
    {
      code: "MNT",
      numeric: "496",
      name: "Tugrik",
      symbol: "â‚®",
      decimal: 2,
    },
    {
      code: "MOP",
      numeric: "446",
      name: "Pataca",
      symbol: "P",
      decimal: 2,
    },
    {
      code: "MRU",
      numeric: "929",
      name: "Ouguiya",
      symbol: "UM",
      decimal: 2,
    },
    {
      code: "MUR",
      numeric: "480",
      name: "Mauritius Rupee",
      symbol: "â‚¨",
      decimal: 2,
    },
    {
      code: "MVR",
      numeric: "462",
      name: "Rufiyaa",
      symbol: ".Þƒ",
      decimal: 2,
    },
    {
      code: "MWK",
      numeric: "454",
      name: "Malawi Kwacha",
      symbol: "MK",
      decimal: 2,
    },
    {
      code: "MXN",
      numeric: "484",
      name: "Mexican Peso",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "MXV",
      numeric: "979",
      name: "Mexican Unidad de Inversion (UDI)",
      symbol: "",
      decimal: 2,
    },
    {
      code: "MYR",
      numeric: "458",
      name: "Malaysian Ringgit",
      symbol: "RM",
      decimal: 2,
    },
    {
      code: "MZN",
      numeric: "943",
      name: "Mozambique Metical",
      symbol: "MT",
      decimal: 2,
    },
    {
      code: "NAD",
      numeric: "516",
      name: "Namibia Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "NGN",
      numeric: "566",
      name: "Naira",
      symbol: "â‚¦",
      decimal: 2,
    },
    {
      code: "NIO",
      numeric: "558",
      name: "Cordoba Oro",
      symbol: "C$",
      decimal: 2,
    },
    {
      code: "NOK",
      numeric: "578",
      name: "Norwegian Krone",
      symbol: "kr",
      decimal: 2,
    },
    {
      code: "NPR",
      numeric: "524",
      name: "Nepalese Rupee",
      symbol: "â‚¨",
      decimal: 2,
    },
    {
      code: "NZD",
      numeric: "554",
      name: "New Zealand Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "OMR",
      numeric: "512",
      name: "Rial Omani",
      symbol: "Ø±.Ø¹.",
      decimal: 3,
    },
    {
      code: "PAB",
      numeric: "590",
      name: "Balboa",
      symbol: "B/.",
      decimal: 2,
    },
    {
      code: "PEN",
      numeric: "604",
      name: "Sol",
      symbol: "S/.",
      decimal: 2,
    },
    {
      code: "PGK",
      numeric: "598",
      name: "Kina",
      symbol: "K",
      decimal: 2,
    },
    {
      code: "PHP",
      numeric: "608",
      name: "Philippine Peso",
      symbol: "â‚±",
      decimal: 2,
    },
    {
      code: "PKR",
      numeric: "586",
      name: "Pakistan Rupee",
      symbol: "â‚¨",
      decimal: 2,
    },
    {
      code: "PLN",
      numeric: "985",
      name: "Zloty",
      symbol: "zÅ‚",
      decimal: 2,
    },
    {
      code: "PYG",
      numeric: "600",
      name: "Guarani",
      symbol: "â‚²",
      decimal: 0,
    },
    {
      code: "QAR",
      numeric: "634",
      name: "Qatari Rial",
      symbol: "Ø±.Ù‚",
      decimal: 2,
    },
    {
      code: "RON",
      numeric: "946",
      name: "Romanian Leu",
      symbol: "L",
      decimal: 2,
    },
    {
      code: "RSD",
      numeric: "941",
      name: "Serbian Dinar",
      symbol: "Ð´Ð¸Ð½.",
      decimal: 2,
    },
    {
      code: "RUB",
      numeric: "643",
      name: "Russian Ruble",
      symbol: "Ñ€ÑƒÐ±.",
      decimal: 2,
    },
    {
      code: "RWF",
      numeric: "646",
      name: "Rwanda Franc",
      symbol: "Fr",
      decimal: 0,
    },
    {
      code: "SAR",
      numeric: "682",
      name: "Saudi Riyal",
      symbol: "Ø±.Ø³",
      decimal: 2,
    },
    {
      code: "SBD",
      numeric: "090",
      name: "Solomon Islands Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "SCR",
      numeric: "690",
      name: "Seychelles Rupee",
      symbol: "â‚¨",
      decimal: 2,
    },
    {
      code: "SDG",
      numeric: "938",
      name: "Sudanese Pound",
      symbol: "£",
      decimal: 2,
    },
    {
      code: "SEK",
      numeric: "752",
      name: "Swedish Krona",
      symbol: "kr",
      decimal: 2,
    },
    {
      code: "SGD",
      numeric: "702",
      name: "Singapore Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "SHP",
      numeric: "654",
      name: "Saint Helena Pound",
      symbol: "£",
      decimal: 2,
    },
    {
      code: "SLL",
      numeric: "694",
      name: "Leone",
      symbol: "Le",
      decimal: 2,
    },
    {
      code: "SOS",
      numeric: "706",
      name: "Somali Shilling",
      symbol: "Sh",
      decimal: 2,
    },
    {
      code: "SRD",
      numeric: "968",
      name: "Surinam Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "SSP",
      numeric: "728",
      name: "South Sudanese Pound",
      symbol: "£",
      decimal: 2,
    },
    {
      code: "STN",
      numeric: "930",
      name: "Dobra",
      symbol: "",
      decimal: 2,
    },
    {
      code: "SVC",
      numeric: "222",
      name: "El Salvador Colon",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "SYP",
      numeric: "760",
      name: "Syrian Pound",
      symbol: "Ù„.Ø³",
      decimal: 2,
    },
    {
      code: "SZL",
      numeric: "748",
      name: "Lilangeni",
      symbol: "L",
      decimal: 2,
    },
    {
      code: "THB",
      numeric: "764",
      name: "Baht",
      symbol: "à¸¿",
      decimal: 2,
    },
    {
      code: "TJS",
      numeric: "972",
      name: "Somoni",
      symbol: "Ð…Ðœ",
      decimal: 2,
    },
    {
      code: "TMT",
      numeric: "934",
      name: "Turkmenistan New Manat",
      symbol: "m",
      decimal: 2,
    },
    {
      code: "TND",
      numeric: "788",
      name: "Tunisian Dinar",
      symbol: "Ø¯.Øª",
      decimal: 3,
    },
    {
      code: "TOP",
      numeric: "776",
      name: "Pa’anga",
      symbol: "T$",
      decimal: 2,
    },
    {
      code: "TRY",
      numeric: "949",
      name: "Turkish Lira",
      symbol: "â‚º",
      decimal: 2,
    },
    {
      code: "TTD",
      numeric: "780",
      name: "Trinidad and Tobago Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "TWD",
      numeric: "901",
      name: "New Taiwan Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "TZS",
      numeric: "834",
      name: "Tanzanian Shilling",
      symbol: "Sh",
      decimal: 2,
    },
    {
      code: "UAH",
      numeric: "980",
      name: "Hryvnia",
      symbol: "â‚´",
      decimal: 2,
    },
    {
      code: "UGX",
      numeric: "800",
      name: "Uganda Shilling",
      symbol: "Sh",
      decimal: 0,
    },
    {
      code: "USD",
      numeric: "840",
      name: "US Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "USN",
      numeric: "997",
      name: "US Dollar (Next day)",
      symbol: "",
      decimal: 2,
    },
    {
      code: "UYI",
      numeric: "940",
      name: "Uruguay Peso en Unidades Indexadas (UI)",
      symbol: "",
      decimal: 0,
    },
    {
      code: "UYU",
      numeric: "858",
      name: "Peso Uruguayo",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "UYW",
      numeric: "927",
      name: "Unidad Previsional",
      symbol: "",
      decimal: 4,
    },
    {
      code: "UZS",
      numeric: "860",
      name: "Uzbekistan Sum",
      symbol: "Ð»Ð²",
      decimal: 2,
    },
    {
      code: "VED",
      numeric: "926",
      name: "Bolívar Soberano",
      symbol: "",
      decimal: 2,
    },
    {
      code: "VES",
      numeric: "928",
      name: "Bolívar Soberano",
      symbol: "",
      decimal: 2,
    },
    {
      code: "VND",
      numeric: "704",
      name: "Dong",
      symbol: "â‚«",
      decimal: 0,
    },
    {
      code: "VUV",
      numeric: "548",
      name: "Vatu",
      symbol: "Vt",
      decimal: 0,
    },
    {
      code: "WST",
      numeric: "882",
      name: "Tala",
      symbol: "T",
      decimal: 2,
    },
    {
      code: "XAF",
      numeric: "950",
      name: "CFA Franc BEAC",
      symbol: "Fr",
      decimal: 0,
    },
    {
      code: "XCD",
      numeric: "951",
      name: "East Caribbean Dollar",
      symbol: "$",
      decimal: 2,
    },
    {
      code: "XOF",
      numeric: "952",
      name: "CFA Franc BCEAO",
      symbol: "Fr",
      decimal: 0,
    },
    {
      code: "XPF",
      numeric: "953",
      name: "CFP Franc",
      symbol: "Fr",
      decimal: 0,
    },
    {
      code: "YER",
      numeric: "886",
      name: "Yemeni Rial",
      symbol: "ï·¼",
      decimal: 2,
    },
    {
      code: "ZAR",
      numeric: "710",
      name: "Rand",
      symbol: "R",
      decimal: 2,
    },
    {
      code: "ZMW",
      numeric: "967",
      name: "Zambian Kwacha",
      symbol: "ZK",
      decimal: 2,
    },
    {
      code: "ZWL",
      numeric: "932",
      name: "Zimbabwe Dollar",
      symbol: "Z$",
      decimal: 2,
    },
  ],
};

export default constants;
