const requisitionMemoInitialState = {
  requisitionMemos: {
    requisitionMemosLoading: false,
    requisitionMemosSuccess: {},
    requisitionMemosError: {},

    metaDataLoading: false,
    metaData: {},

    addRequisitionMemoLoading: false,
    addRequisitionMemoSuccess: {},
    addRequisitionMemoError: {},

    searchRequisitionMemoLoading: false,
    searchRequisitionMemoSuccess: {},
    searchRequisitionMemoError: {},

    deleteRequisitionMemoLoading: false,
    deleteRequisitionMemoSuccess: false,
    deleteRequisitionMemoError: {},

    updateRequisitionMemoLoading: false,
    updateRequisitionMemoSuccess: {},
    updateRequisitionMemoError: {},
    updateRequisitionMemoData: {},

    requisitionMemoDetailsLoading: false,
    requisitionMemoDetailsSuccess: {},
    requisitionMemoDetailsError: {},

    addRequisitionMemoCostLoading: false,
    addRequisitionMemoCostSuccess: {},
    addRequisitionMemoCostError: {},

    deleteRequisitionMemoCostLoading: false,
    deleteRequisitionMemoCostSuccess: {},
    deleteRequisitionMemoCostError: {},

    updateRequisitionMemoCostData: {},
    updateRequisitionMemoCostLoading: false,
    updateRequisitionMemoCostSuccess: {},
    updateRequisitionMemoCostError: {},

    requisitionMemoCostDetailsLoading: false,
    requisitionMemoCostDetailsSuccess: {},
    requisitionMemoCostDetailsError: {},

    submitRequisitionMemoLoading: false,
    submitRequisitionMemoSuccess: {},
    submitRequisitionMemoError: {},

    checkRequisitionMemoLoading: false,
    checkRequisitionMemoSuccess: {},
    checkRequisitionMemoError: {},

    approveRequisitionMemoLoading: false,
    approveRequisitionMemoSuccess: {},
    approveRequisitionMemoError: {},

    sortRequisitionMemosLoading: false,
    sortRequisitionMemosSuccess: {},
    sortRequisitionMemosError: {},

    downloadRequisitionMemosLoading: false,
    downloadRequisitionMemosSuccess: {},
    downloadRequisitionMemosError: {},

    downloadQuerry: {},
  },
};

export default requisitionMemoInitialState;
