const initialState = {
  server: {
    serverSuccess: {},
    serverError: {},

    downloadExcelLoading: false,
    downloadExcelError: {},
    downloadExcelSuccess: {},
  },
};

export default initialState;
