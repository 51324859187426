import { requestForQuotationsActions } from "../../../actions";
import initialState from "../../../initialState";

const requestForQuotations = (
  state = initialState.requestForQuotations,
  actions
) => {
  switch (actions.type) {
    case requestForQuotationsActions.GET_REQUEST_FOR_QUOTATIONS_REQUEST:
      return {
        ...state,
        requestForQuotationsLoading: true,
      };
    case requestForQuotationsActions.GET_REQUEST_FOR_QUOTATIONS_SUCCESS:
      return {
        ...state,
        requestForQuotationsLoading: false,
        requestForQuotationsSuccess: actions.data,
      };
    case requestForQuotationsActions.GET_REQUEST_FOR_QUOTATIONS_ERROR:
      return {
        ...state,
        requestForQuotationsLoading: false,
        requestForQuotationsError: actions.error,
      };
    case requestForQuotationsActions.GET_REQUEST_FOR_QUOTATIONS_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case requestForQuotationsActions.GET_REQUEST_FOR_QUOTATIONS_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataLoading: false,
        metaDataSuccess: actions.data,
      };
    case requestForQuotationsActions.ADD_REQUEST_FOR_QUOTATION_REQUEST:
      return {
        ...state,
        addRequestForQuotationLoading: true,
      };
    case requestForQuotationsActions.ADD_REQUEST_FOR_QUOTATION_SUCCESS:
      return {
        ...state,
        addRequestForQuotationLoading: false,
        addRequestForQuotationSuccess: actions.data,
      };
    case requestForQuotationsActions.ADD_REQUEST_FOR_QUOTATION_ERROR:
      return {
        ...state,
        addRequestForQuotationLoading: false,
        addRequestForQuotationError: actions.error,
      };
    case requestForQuotationsActions.SET_ADD_REQUEST_FOR_QUOTATION_DATA:
      return {
        ...state,
        addRequestForQuotationData: actions.data,
      };
    case requestForQuotationsActions.DELETE_REQUEST_FOR_QUOTATION_REQUEST:
      return {
        ...state,
        deleteRequestForQuotationLoading: true,
      };
    case requestForQuotationsActions.DELETE_REQUEST_FOR_QUOTATION_SUCCESS:
      return {
        ...state,
        deleteRequestForQuotationLoading: false,
        deleteRequestForQuotationSuccess: actions.data,
      };
    case requestForQuotationsActions.DELETE_REQUEST_FOR_QUOTATION_ERROR:
      return {
        ...state,
        deleteRequestForQuotationLoading: false,
        deleteRequestForQuotationError: actions.error,
      };
    case requestForQuotationsActions.SET_UPDATE_REQUEST_FOR_QUOTATION_UPDATE_DATA:
      return {
        ...state,
        updateRequestForQuotationData: actions.data,
      };
    case requestForQuotationsActions.UPDATE_REQUEST_FOR_QUOTATION_REQUEST:
      return {
        ...state,
        updateRequestForQuotationLoading: true,
      };
    case requestForQuotationsActions.UPDATE_REQUEST_FOR_QUOTATION_SUCCESS:
      return {
        ...state,
        updateRequestForQuotationLoading: false,
        updateRequestForQuotationSuccess: actions.data,
      };
    case requestForQuotationsActions.UPDATE_REQUEST_FOR_QUOTATION_ERROR:
      return {
        ...state,
        updateRequestForQuotationLoading: false,
        updateRequestForQuotationError: actions.error,
      };
    case requestForQuotationsActions.GET_REQUEST_FOR_QUOTATION_DETAILS_REQUEST:
      return {
        ...state,
        requestForQuotationDetailsLoading: true,
      };
    case requestForQuotationsActions.GET_REQUEST_FOR_QUOTATION_DETAILS_SUCCESS:
      return {
        ...state,
        requestForQuotationDetailsLoading: false,
        requestForQuotationDetailsSuccess: actions.data,
      };
    case requestForQuotationsActions.GET_REQUEST_FOR_QUOTATION_DETAILS_ERROR:
      return {
        ...state,
        requestForQuotationDetailsLoading: false,
        requestForQuotationDetailsError: actions.error,
      };
    case requestForQuotationsActions.CHECK_REQUEST_FOR_QUOTATION_REQUEST:
      return {
        ...state,
        checkRequestForQuotationLoading: true,
      };
    case requestForQuotationsActions.CHECK_REQUEST_FOR_QUOTATION_SUCCESS:
      return {
        ...state,
        checkRequestForQuotationLoading: false,
        checkRequestForQuotationSuccess: actions.data,
      };
    case requestForQuotationsActions.CHECK_REQUEST_FOR_QUOTATION_ERROR:
      return {
        ...state,
        checkRequestForQuotationLoading: false,
        checkRequestForQuotationError: actions.error,
      };
    case requestForQuotationsActions.APPROVE_REQUEST_FOR_QUOTATION_REQUEST:
      return {
        ...state,
        approveRequestForQuotationLoading: true,
      };
    case requestForQuotationsActions.APPROVE_REQUEST_FOR_QUOTATION_SUCCESS:
      return {
        ...state,
        approveRequestForQuotationLoading: false,
        approveRequestForQuotationSuccess: actions.data,
      };
    case requestForQuotationsActions.APPROVE_REQUEST_FOR_QUOTATION_ERROR:
      return {
        ...state,
        approveRequestForQuotationLoading: false,
        approveRequestForQuotationError: actions.error,
      };
    default:
      return state;
  }
};

export default requestForQuotations;
