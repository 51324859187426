import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers";
import logger from "redux-logger";
import createSagaMiddleware from "@redux-saga/core";

const store = () => {
  const sagaMiddleware = createSagaMiddleware();
  if (process.env.NODE_ENV === "production") {
    return {
      ...createStore(rootReducer, applyMiddleware(sagaMiddleware)),
      runSaga: sagaMiddleware.run,
      devTools: false,
    };
  }
  return {
    ...createStore(rootReducer, applyMiddleware(sagaMiddleware, logger)),
    runSaga: sagaMiddleware.run,
    devTools: false,
  };
};

export default store;
