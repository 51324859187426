import { chartOfAccountsActions } from "../../../actions";
import initialState from "../../../initialState";

const chartOfAccounts = (state = initialState.chartOfAccounts, actions) => {
  switch (actions.type) {
    case chartOfAccountsActions.GET_CHART_OF_ACCOUNTS_REQUEST:
      return {
        ...state,
        chartOfAccountsLoading: true,
      };
    case chartOfAccountsActions.GET_CHART_OF_ACCOUNTS_SUCCESS:
      return {
        ...state,
        chartOfAccountsSuccess: actions.data,
        chartOfAccountsError: {},
        chartOfAccountsLoading: false,
      };
    case chartOfAccountsActions.GET_CHART_OF_ACCOUNTS_ERROR:
      return {
        ...state,
        chartOfAccountsError: actions.error,
        chartOfAccountsLoading: false,
      };
    case chartOfAccountsActions.SET_ADD_ACCOUNT_TYPE_DATA:
      return {
        ...state,
        addAccountTypeData: actions.data,
      };
    case chartOfAccountsActions.ADD_ACCOUNT_TYPE_REQUEST:
      return {
        ...state,
        addAccountTypeLoading: true,
        addAccountTypeError: {},
      };
    case chartOfAccountsActions.ADD_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        addAccountTypeLoading: false,
        addAccountTypeSuccess: actions.data,
        addAccountTypeError: {},
      };
    case chartOfAccountsActions.ADD_ACCOUNT_TYPE_ERROR:
      return {
        ...state,
        addAccountTypeError: actions.error,
        addAccountTypeLoading: false,
      };
    case chartOfAccountsActions.DELETE_ACCOUNT_TYPE_REQUEST:
      return {
        ...state,
        deleteAccountTypeLoading: true,
        deleteAccountTypeSuccess: {},
        deleteAccountTypeError: {},
      };
    case chartOfAccountsActions.DELETE_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        deleteAccountTypeLoading: false,
        deleteAccountTypeSuccess: actions.data,
        deleteAccountTypeError: {},
      };
    case chartOfAccountsActions.DELETE_ACCOUNT_TYPE_ERROR:
      return {
        ...state,
        deleteAccountTypeError: actions.error,
        deleteAccountTypeLoading: false,
      };
    case chartOfAccountsActions.SET_UPDATE_ACCOUNT_TYPE_UPDATE_DATA:
      return {
        ...state,
        updateAccountTypeData: actions.data,
      };
    case chartOfAccountsActions.UPDATE_ACCOUNT_TYPE_REQUEST:
      return {
        ...state,
        updateAccountTypeLoading: true,
        updateAccountTypeSuccess: {},
        updateAccountTypeError: {},
      };
    case chartOfAccountsActions.UPDATE_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        updateAccountTypeLoading: false,
        updateAccountTypeSuccess: actions.data,
        updateAccountTypeError: {},
      };
    case chartOfAccountsActions.UPDATE_ACCOUNT_TYPE_ERROR:
      return {
        ...state,
        updateAccountTypeError: actions.error,
        updateAccountTypeLoading: false,
      };
    case chartOfAccountsActions.GET_ACCOUNT_TYPE_DETAILS_REQUEST:
      return {
        ...state,
        accountTypeDetailsLoading: true,
      };
    case chartOfAccountsActions.GET_ACCOUNT_TYPE_DETAILS_SUCCESS:
      return {
        ...state,
        accountTypeDetailsLoading: false,
        accountTypeDetailsSuccess: actions.data,
        accountTypeDetailsError: {},
      };
    case chartOfAccountsActions.GET_ACCOUNT_TYPE_DETAILS_ERROR:
      return {
        ...state,
        accountTypeDetailsLoading: false,
        accountTypeDetailsError: actions.error,
      };
    case chartOfAccountsActions.APPROVE_ACCOUNT_TYPE_REQUEST:
      return {
        ...state,
        approveAccountTypeLoading: true,
        approveAccountTypeSuccess: {},
        approveAccountTypeError: {},
      };
    case chartOfAccountsActions.APPROVE_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        approveAccountTypeLoading: false,
        approveAccountTypeSuccess: actions.data,
      };
    case chartOfAccountsActions.APPROVE_ACCOUNT_TYPE_ERROR:
      return {
        ...state,
        approveAccountTypeError: actions.error,
        approveAccountTypeLoading: false,
      };
    case chartOfAccountsActions.CHECK_ACCOUNT_TYPE_REQUEST:
      return {
        ...state,
        checkAccountTypeLoading: true,
        checkAccountTypeSuccess: {},
        checkAccountTypeError: {},
      };
    case chartOfAccountsActions.CHECK_ACCOUNT_TYPE_ERROR:
      return {
        ...state,
        checkAccountTypeLoading: false,
        checkAccountTypeError: actions.error,
      };
    case chartOfAccountsActions.CHECK_ACCOUNT_TYPE_SUCCESS:
      return {
        ...state,
        checkAccountTypeSuccess: actions.data,
        checkAccountTypeLoading: false,
      };
    case chartOfAccountsActions.ADD_ACCOUNT_REQUEST:
      return {
        ...state,
        addAccountLoading: true,
        addAccountSuccess: {},
        addAccountError: {},
      };
    case chartOfAccountsActions.ADD_ACCOUNT_SUCCESS:
      return {
        ...state,
        addAccountLoading: false,
        addAccountSuccess: actions.data,
        addAccountError: {},
      };
    case chartOfAccountsActions.ADD_ACCOUNT_ERROR:
      return {
        ...state,
        addAccountError: actions.error,
        addAccountLoading: false,
      };
    case chartOfAccountsActions.SET_ADD_ACCOUNT_DATA:
      return {
        ...state,
        addAccountData: actions.data,
      };
    case chartOfAccountsActions.DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        deleteAccountLoading: true,
        deleteAccountSuccess: {},
        deleteAccountError: {},
      };
    case chartOfAccountsActions.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteAccountSuccess: actions.data,
        deleteAccountLoading: false,
      };
    case chartOfAccountsActions.DELETE_ACCOUNT_ERROR:
      return {
        ...state,
        deleteAccountError: actions.error,
        deleteAccountLoading: false,
      };
    case chartOfAccountsActions.SET_UPDATE_ACCOUNT_UPDATE_DATA:
      return {
        ...state,
        updateAccountData: actions.data,
      };
    case chartOfAccountsActions.UPDATE_ACCOUNT_REQUEST:
      return {
        ...state,
        updateAccountLoading: true,
      };
    case chartOfAccountsActions.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        updateAccountSuccess: actions.data,
        updateAccountLoading: false,
        updateAccountError: {},
      };
    case chartOfAccountsActions.UPDATE_ACCOUNT_ERROR:
      return {
        ...state,
        updateAccountError: actions.error,
        updateAccountLoading: false,
      };
    case chartOfAccountsActions.GET_ACCOUNT_DETAILS_REQUEST:
      return {
        ...state,
        accountDetailsLoading: true,
      };
    case chartOfAccountsActions.GET_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        accountDetailsSuccess: actions.data,
        accountDetailsError: {},
        accountDetailsLoading: false,
      };
    case chartOfAccountsActions.GET_ACCOUNT_DETAILS_ERROR:
      return {
        ...state,
        accountDetailsLoading: false,
        accountDetailsError: actions.error,
      };
    case chartOfAccountsActions.CHECK_ACCOUNT_REQUEST:
      return {
        ...state,
        checkAccountLoading: true,
        checkAccountError: {},
        checkAccountSuccess: {},
      };
    case chartOfAccountsActions.CHECK_ACCOUNT_SUCCESS:
      return {
        ...state,
        checkAccountLoading: false,
        checkAccountSuccess: actions.data,
      };
    case chartOfAccountsActions.CHECK_ACCOUNT_ERROR:
      return {
        ...state,
        checkAccountError: actions.error,
        checkAccountLoading: false,
      };
    case chartOfAccountsActions.APPROVE_ACCOUNT_REQUEST:
      return {
        ...state,
        approveAccountLoading: true,
        approveAccountError: {},
        approveAccountSuccess: {},
      };
    case chartOfAccountsActions.APPROVE_ACCOUNT_SUCCESS:
      return {
        ...state,
        approveAccountLoading: false,
        approveAccountSuccess: actions.data,
      };
    case chartOfAccountsActions.APPROVE_ACCOUNT_ERROR:
      return {
        ...state,
        approveAccountLoading: false,
        approveAccountError: actions.error,
      };
    case chartOfAccountsActions.CREATE_COA_REQUEST:
      return {
        ...state,
        creatingChartOfAccounts: true,
        createChartOfAccountsError: {},
      };
    case chartOfAccountsActions.CREATE_COA_SUCCESS:
      return {
        ...state,
        creatingChartOfAccounts: false,
      };
    case chartOfAccountsActions.CREATE_COA_ERROR:
      return {
        ...state,
        createChartOfAccountsError: {},
        creatingChartOfAccounts: false,
      };
    default:
      return state;
  }
};

export default chartOfAccounts;
