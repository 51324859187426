const manualsAndPoliciesInitialState = {
  manualsAndPolicies: {
    manualsAndPoliciesLoading: false,
    manualsAndPoliciesSuccess: {},
    manualsAndPoliciesError: {},

    addManualsAndPoliciesLoading: false,
    addManualsAndPoliciesSuccess: {},
    addManualsAndPoliciesError: {},

    deleteManualsAndPoliciesLoading: false,
    deleteManualsAndPoliciesSuccess: {},
    deleteManualsAndPoliciesError: {},

    updateManualsAndPoliciesData: {},
    updateManualsAndPoliciesLoading: false,
    updateManualsAndPoliciesSuccess: {},
    updateManualsAndPoliciesError: {},

    manualsAndPoliciesDetailsLoading: false,
    manualsAndPoliciesDetailsSuccess: {},
    manualsAndPoliciesDetailsError: {},

    checkManualsAndPoliciesLoading: false,
    checkManualsAndPoliciesSuccess: {},
    checkManualsAndPoliciesError: {},

    approveManualsAndPoliciesLoading: false,
    approveManualsAndPoliciesSuccess: {},
    approveManualsAndPoliciesError: {},
  },
};

export default manualsAndPoliciesInitialState;
