const schemeOfWorkInititalState = {
  schemeOfWork: {
    schemesOfWork: {},
    schemesOfWorkLoading: false,
    schemesOfWorkError: {},

    metaData: {},
    metaDataLoading: false,
    metaDataError: {},

    addSchemeOfWork: {},
    addSchemeOfWorkLoading: false,
    addSchemeOfWorkError: {},

    updateSchemeOfWork: {},
    updateSchemeOfWorkLoading: false,
    updateSchemeOfWorkError: {},
    updateSchemeOfWorkData: {},

    deleteSchemeOfWork: {},
    deleteSchemeOfWorkLoading: false,
    deleteSchemeOfWorkError: {},

    schemeOfWorkDetails: {},
    schemeOfWorkDetailsLoading: false,
    schemeOfWorkDetailsError: {},

    addSchemeOfWorkItem: {},
    addSchemeOfWorkItemLoading: false,
    addSchemeOfWorkItemError: {},

    updateSchemeOfWorkItem: {},
    updateSchemeOfWorkItemLoading: false,
    updateSchemeOfWorkItemError: {},
    updateSchemeOfWorkItemData: {},

    deleteSchemeOfWorkItem: {},
    deleteSchemeOfWorkItemLoading: false,
    deleteSchemeOfWorkItemError: {},

    checkSchemeOfWork: {},
    checkSchemeOfWorkLoading: false,
    checkSchemeOfWorkError: {},

    approveSchemeOfWork: {},
    approveSchemeOfWorkLoading: false,
    approveSchemeOfWorkError: {},
  },
};

export default schemeOfWorkInititalState;
