import { Form, Alert, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../config/actions";
import usePrevious from "../../../components/hooks/usePrevious";
import { isEmpty, map } from "lodash";
import constants from "../../../config/constants";
import "../../../index.css";
import AppInput from "../../../components/common/AppInput";
import AppButton from "../../../components/common/AppButton";

const Signup = () => {
  const { signingUp, signupError, signupSuccess } = useSelector(
    (state) => state.auth
  );
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const prevState = usePrevious({ signupSuccess, signupError });
  const [form] = Form.useForm();

  // all functions
  const onFinish = (values) => {
    dispatch(authActions.signupUser(values));
  };

  // use effects
  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      signupError &&
      prevState.signupError !== signupError
    ) {
      setError(signupError?.message || signupError?.server?.message);
    }
  }, [signupError]);

  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      !isEmpty(signupSuccess) &&
      prevState.signupSuccess !== signupSuccess
    ) {
      if (signupSuccess.server?.status === true) {
        Modal.success({
          title: "SUCCESSFULLY SAVED YOUR INFORMATION!",
          content: signupSuccess.server?.message,
        });
        form.resetFields();
      }
    }
  }, [signupSuccess]);

  return (
    <div>
      <Form
        form={form}
        onFinish={onFinish}
        name="Sign up Page"
        autoComplete="off"
        layout="vertical"
        className="w-100"
      >
        {error && <Alert type="error" message={error} showIcon />}
        <AppInput
          name="surname"
          label="Surname"
          rules={[{ required: true, message: "This field is required" }]}
        />
        <AppInput
          name="other_names"
          label="Other names"
          rules={[{ required: true, message: "This field is required" }]}
        />
        <AppInput name="institution_name" label="School / Institution name" />
        <AppInput
          name="relationship_officer"
          label="Relationship officer (optional)"
        />
        <AppInput
          name="country"
          label="Country"
          type="select"
          options={map(constants.countries, (country) => ({
            value: country,
            label: country,
          }))}
        />
        <AppInput
          name="location"
          label="Location"
          rules={[{ required: true, message: "This field is required" }]}
        />
        <AppInput
          name="phone"
          label="Phone"
          rules={[{ required: true, message: "This field is required" }]}
        />
        <AppInput name="contact_email" label="Contact email" />
        <AppInput
          type="textarea"
          label="How did you hear about us ?"
          name="how_did_you_hear_about_us"
        />
        <AppButton
          text="Sign up"
          loading={signingUp}
          className={"w-100"}
          loadingText="Signing up..."
        />
      </Form>
    </div>
  );
};

export default Signup;
