const paymentStructures = {
  GET_PAYMENT_STRUCTURES_REQUEST: "GET_PAYMENT_STRUCTURES_REQUEST",
  GET_PAYMENT_STRUCTURES_SUCCESS: "GET_PAYMENT_STRUCTURES_SUCCESS",
  GET_PAYMENT_STRUCTURES_ERROR: "GET_PAYMENT_STRUCTURES_ERROR",

  ADD_PAYMENT_STRUCUTURES_REQUEST: "ADD_PAYMENT_STRUCUTURES_REQUEST",
  ADD_PAYMENT_STRUCUTURES_SUCCESS: "ADD_PAYMENT_STRUCUTURES_SUCCESS",
  ADD_PAYMENT_STRUCUTURES_ERROR: "ADD_PAYMENT_STRUCUTURES_ERROR",

  UPDATE_PAYMENT_STRUCTURES_REQUEST: "UPDATE_PAYMENT_STRUCTURES_REQUEST",
  UPDATE_PAYMENT_STRUCTURES_SUCCESS: "UPDATE_PAYMENT_STRUCTURES_SUCCESS",
  UPDATE_PAYMENT_STRUCTURES_ERROR: "UPDATE_PAYMENT_STRUCTURES_ERROR",
  SET_UPDATE_PAYMENT_STRUCTURES_DATA: "SET_UPDATE_PAYMENT_STRUCTURES_DATA",

  GET_PAYMENT_STRUCTURE_DETAILS_REQUEST:
    "GET_PAYMENT_STRUCTURE_DETAILS_REQUEST",
  GET_PAYMENT_STRUCTURE_DETAILS_SUCCESS:
    "GET_PAYMENT_STRUCTURE_DETAILS_SUCCESS",
  GET_PAYMENT_STRUCTURE_DETAILS_ERROR: "GET_PAYMENT_STRUCTURE_DETAILS_ERROR",

  DELETE_PAYMENT_STRUCTURES_REQUEST: "DELETE_PAYMENT_STRUCTURES_REQUEST",
  DELETE_PAYMENT_STRUCTURES_SUCCESS: "DELETE_PAYMENT_STRUCTURES_SUCCESS",
  DELETE_PAYMENT_STRUCTURES_ERROR: "DELETE_PAYMENT_STRUCTURES_ERROR",

  GET_COST_DETAILS_REQUEST: "GET_COST_DETAILS_REQUEST",
  GET_COST_DETAILS_ERROR: "GET_COST_DETAILS_ERROR",
  GET_COST_DETAILS_SUCCESS: "GET_COST_DETAILS_SUCCESS",

  SET_UPDATE_COST_DATA: "SET_UPDATE_COST_DATA",
  UPDATE_COST_REQUEST: "UPDATE_COST_REQUEST",
  UPDATE_COST_SUCCESS: "UPDATE_COST_SUCCESS",
  UPDATE_COST_ERROR: "UPDATE_COST_ERROR",

  DELETE_COST_REQUEST: "DELETE_COST_REQUEST",
  DELETE_COST_SUCCESS: "DELETE_COST_SUCCESS",
  DELETE_COST_ERROR: "DELETE_COST_ERROR",

  CHECK_PAYMENT_STRUCTURE_REQUEST: "CHECK_PAYMENT_STRUCTURE_REQUEST",
  CHECK_PAYMENT_STRUCTURE_SUCCESS: "CHECK_PAYMENT_STRUCTURE_SUCCESS",
  CHECK_PAYMENT_STRUCTURE_ERROR: "CHECK_PAYMENT_STRUCTURE_ERROR",

  APPROVE_PAYMENT_STRUCTURE_REQUEST: "APPROVE_PAYMENT_STRUCTURE_REQUEST",
  APPROVE_PAYMENT_STRUCTURE_SUCCESS: "APPROVE_PAYMENT_STRUCTURE_SUCCESS",
  APPROVE_PAYMENT_STRUCTURE_ERROR: "APPROVE_PAYMENT_STRUCTURE_ERROR",

  GET_META_DATA_REQUEST: "GET_PAYMENT_STRUCTURES_META_DATA_REQUEST",
  GET_META_DATA_SUCCESS: "GET_PAYMENT_STRUCTURES_META_DATA_SUCCESS",

  SET_PAYMENT_STRUCTURE_COST_STATUS_REQUEST:
    "SET_PAYMENT_STRUCTURE_COST_STATUS_REQUEST",
  SET_PAYMENT_STRUCTURE_COST_STATUS_SUCCESS:
    "SET_PAYMENT_STRUCTURE_COST_STATUS_SUCCESS",
  SET_PAYMENT_STRUCTURE_COST_STATUS_ERROR:
    "SET_PAYMENT_STRUCTURE_COST_STATUS_ERROR",

  getMetaData: () => ({
    type: paymentStructures.GET_META_DATA_REQUEST,
  }),
  setPaymentStructureCostStatus: (data) => ({
    type: paymentStructures.SET_PAYMENT_STRUCTURE_COST_STATUS_REQUEST,
    data,
  }),
  getPaymentStructures: () => ({
    type: paymentStructures.GET_PAYMENT_STRUCTURES_REQUEST,
  }),
  getPaymentStructureDetails: (id) => ({
    type: paymentStructures.GET_PAYMENT_STRUCTURE_DETAILS_REQUEST,
    id,
  }),
  addPaymentStructure: (data, newArray) => ({
    type: paymentStructures.ADD_PAYMENT_STRUCUTURES_REQUEST,
    data,
    paymentStructures: newArray,
  }),
  setUpdatePaymentStructuresData: (data) => ({
    type: paymentStructures.SET_UPDATE_PAYMENT_STRUCTURES_DATA,
    data,
  }),
  updatePaymentStructures: (data, id, newArray) => ({
    type: paymentStructures.UPDATE_PAYMENT_STRUCTURES_REQUEST,
    data,
    paymentStructures: newArray,
    id,
  }),
  deletePaymentStructure: (id, newArray) => ({
    type: paymentStructures.DELETE_PAYMENT_STRUCTURES_REQUEST,
    paymentStructures: newArray,
    id,
  }),
  getCostDetails: (id) => ({
    type: paymentStructures.GET_COST_DETAILS_REQUEST,
    id,
  }),
  setUpdateCostData: (data) => ({
    type: paymentStructures.SET_UPDATE_COST_DATA,
    data,
  }),
  updateCost: (data, id) => ({
    type: paymentStructures.UPDATE_COST_REQUEST,
    data,
    id,
  }),
  deleteCost: (params, id) => ({
    type: paymentStructures.DELETE_COST_REQUEST,
    id,
    params,
  }),
  checkPaymentStructure: (id) => ({
    type: paymentStructures.CHECK_PAYMENT_STRUCTURE_REQUEST,
    id,
  }),
  approvePaymentStructure: (id) => ({
    type: paymentStructures.APPROVE_PAYMENT_STRUCTURE_REQUEST,
    id,
  }),
};

export default paymentStructures;
