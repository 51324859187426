import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { getAccessToken } from "../../config/services/storage.service";
import AuthPage from "../../containers/auth";

const AuthLayout = () => {
  const accessToken = getAccessToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (accessToken) {
      navigate("/");
    }
  }, [accessToken]);
  return (
    <div>
      <AuthPage />
    </div>
  );
};

export default AuthLayout;
