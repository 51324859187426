import { noticesOfBestBiddersActions } from "../../../actions";
import initialState from "../../../initialState";

const noticesOfBestBidders = (
  state = initialState.noticesOfBestBidders,
  actions
) => {
  switch (actions.type) {
    case noticesOfBestBiddersActions.GET_NOTICES_OF_BEST_BIDDERS_REQUEST:
      return {
        ...state,
        noticeOfBestBiddersLoading: true,
      };
    case noticesOfBestBiddersActions.GET_NOTICES_OF_BEST_BIDDERS_SUCCESS:
      return {
        ...state,
        noticeOfBestBiddersLoading: false,
        noticeOfBestBiddersSuccess: actions.data,
      };
    case noticesOfBestBiddersActions.GET_NOTICES_OF_BEST_BIDDERS_ERROR:
      return {
        ...state,
        noticeOfBestBiddersLoading: false,
        noticeOfBestBiddersError: actions.error,
      };
    case noticesOfBestBiddersActions.GET_NOTICES_OF_BEST_BIDDERS_META_DATA_REQUEST:
      return {
        ...state,
        metaDataLoading: true,
      };
    case noticesOfBestBiddersActions.GET_NOTICES_OF_BEST_BIDDERS_META_DATA_SUCCESS:
      return {
        ...state,
        metaDataLoading: false,
        metaDataSuccess: actions.data,
      };
    case noticesOfBestBiddersActions.ADD_NOTICE_OF_BEST_BIDDER_REQUEST:
      return {
        ...state,
        addNoticeOfBestBidderLoading: true,
      };
    case noticesOfBestBiddersActions.ADD_NOTICE_OF_BEST_BIDDER_SUCCESS:
      return {
        ...state,
        addNoticeOfBestBidderLoading: false,
        addNoticeOfBestBidderSuccess: actions.data,
      };
    case noticesOfBestBiddersActions.ADD_NOTICE_OF_BEST_BIDDER_ERROR:
      return {
        ...state,
        addNoticeOfBestBidderLoading: false,
        addNoticeOfBestBidderError: actions.error,
      };
    case noticesOfBestBiddersActions.SET_ADD_NOTICE_OF_BEST_BIDDER_DATA:
      return {
        ...state,
        addNoticeOfBestBidderData: actions.data,
      };
    case noticesOfBestBiddersActions.DELETE_NOTICE_OF_BEST_BIDDER_REQUEST:
      return {
        ...state,
        deleteNoticeOfBestBidderLoading: true,
      };
    case noticesOfBestBiddersActions.DELETE_NOTICE_OF_BEST_BIDDER_SUCCESS:
      return {
        ...state,
        deleteNoticeOfBestBidderLoading: false,
        deleteNoticeOfBestBidderSuccess: actions.data,
      };
    case noticesOfBestBiddersActions.DELETE_NOTICE_OF_BEST_BIDDER_ERROR:
      return {
        ...state,
        deleteNoticeOfBestBidderLoading: false,
        deleteNoticeOfBestBidderError: actions.error,
      };
    case noticesOfBestBiddersActions.SET_UPDATE_NOTICE_OF_BEST_BIDDER_UPDATE_DATA:
      return {
        ...state,
        updateNoticeOfBestBidderData: actions.data,
      };
    case noticesOfBestBiddersActions.UPDATE_NOTICE_OF_BEST_BIDDER_REQUEST:
      return {
        ...state,
        updateNoticeOfBestBidderLoading: true,
      };
    case noticesOfBestBiddersActions.UPDATE_NOTICE_OF_BEST_BIDDER_SUCCESS:
      return {
        ...state,
        updateNoticeOfBestBidderLoading: false,
        updateNoticeOfBestBidderSuccess: actions.data,
      };
    case noticesOfBestBiddersActions.UPDATE_NOTICE_OF_BEST_BIDDER_ERROR:
      return {
        ...state,
        updateNoticeOfBestBidderLoading: false,
        updateNoticeOfBestBidderError: actions.error,
      };
    case noticesOfBestBiddersActions.GET_NOTICE_OF_BEST_BIDDER_DETAILS_REQUEST:
      return {
        ...state,
        noticeOfBestBidderDetailsLoading: true,
      };
    case noticesOfBestBiddersActions.GET_NOTICE_OF_BEST_BIDDER_DETAILS_SUCCESS:
      return {
        ...state,
        noticeOfBestBidderDetailsLoading: false,
        noticeOfBestBidderDetailsSuccess: actions.data,
      };
    case noticesOfBestBiddersActions.GET_NOTICE_OF_BEST_BIDDER_DETAILS_ERROR:
      return {
        ...state,
        noticeOfBestBidderDetailsLoading: false,
        noticeOfBestBidderDetailsError: actions.error,
      };
    case noticesOfBestBiddersActions.CHECK_NOTICE_OF_BEST_BIDDER_REQUEST:
      return {
        ...state,
        checkNoticeOfBestBidderLoading: true,
      };
    case noticesOfBestBiddersActions.CHECK_NOTICE_OF_BEST_BIDDER_SUCCESS:
      return {
        ...state,
        checkNoticeOfBestBidderLoading: false,
        checkNoticeOfBestBidderSuccess: actions.data,
      };
    case noticesOfBestBiddersActions.CHECK_NOTICE_OF_BEST_BIDDER_ERROR:
      return {
        ...state,
        checkNoticeOfBestBidderLoading: false,
        checkNoticeOfBestBidderError: actions.error,
      };
    case noticesOfBestBiddersActions.APPROVE_NOTICE_OF_BEST_BIDDER_REQUEST:
      return {
        ...state,
        approveNoticeOfBestBidderLoading: true,
      };
    case noticesOfBestBiddersActions.APPROVE_NOTICE_OF_BEST_BIDDER_SUCCESS:
      return {
        ...state,
        approveNoticeOfBestBidderLoading: false,
        approveNoticeOfBestBidderSuccess: actions.data,
      };
    case noticesOfBestBiddersActions.APPROVE_NOTICE_OF_BEST_BIDDER_ERROR:
      return {
        ...state,
        approveNoticeOfBestBidderLoading: false,
        approveNoticeOfBestBidderError: actions.error,
      };
    default:
      return state;
  }
};

export default noticesOfBestBidders;
