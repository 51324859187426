const cashBookInititalState = {
  cashBook: {
    cashBookLoading: false,
    cashBookSuccess: {},
    cashBookError: {},

    metaDataLoading: false,
    metaData: {},

    generateExcelLoading: false,
    generateExcelSuccess: {},
    generateExcelError: {},
  },
};

export default cashBookInititalState;
